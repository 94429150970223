import React, { Component } from "react";
import { toJS } from "mobx";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import UserStore from "../../UserStore";
import axios from "axios";

const API_Endpoint = process.env.REACT_APP_API_ENDPOINT;

class SelectEditChange extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        fields: [],
      };
    }

  fetchIsometricFields = async () => {
    await axios.get(API_Endpoint + `/GetIsometricFields`).then((res) => {
      if (res.status === 200) {
        this.setState({
          fields: res.data,
        });
      }
    });
  };

  componentDidMount() {
    this.fetchIsometricFields();
  }

  render() {
    const isRegExp = toJS(UserStore.isRegExp);
    if (this.props.editProducts.Change === "No Change") {
      this.props.editProducts.Change = false;
    } else if (this.props.editProducts.Change === "Change") {
      this.props.editProducts.Change = true;
    }
    return (
      isRegExp ? (
        <Select
        style={{
          display: "flex",
          marginLeft: "1vw",
          width: "95%",
          fontSize: "1.04vw",
        }}
        margin="dense"
        labelId="demo-simple-select-label"
        id={this.props.idValue}
        label={this.props.idValue}
        name="Key"
        fullWidth
        defaultValue={this.props.editProducts.key}
        onChange={(e) => {
          this.props.handleDropdownchangerowEdit(e, this.props.idValue);
        }}
      >
        {this.state.fields.map((field) => {
          return (
            <MenuItem key={field.fieldId} value={field.fieldName}>
              {field.fieldName}
            </MenuItem>
          );
        })}
      </Select>
      ) : (
      <Select
        style={{
          display: "flex",
          marginLeft: "1vw",
          width: "95%",
          fontSize: "1.04vw",
        }}
        margin="dense"
        labelId="demo-simple-select-label"
        id={this.props.idValue}
        label={this.props.idValue}
        name="changeOutcome"
        fullWidth
        value={this.props.editProducts.Change ? 10 : 20}
        onChange={(e) => {
          this.props.handleDropdownchangerowEdit(e, this.props.idValue);
        }}
      >
        <MenuItem value={10} className={this.props.modalId}>
          Change
        </MenuItem>
        <MenuItem value={20} className={this.props.modalId}>
          No Change
        </MenuItem>
      </Select>
    )
    )
  }
}

export default SelectEditChange;
