import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import cellEditFactory from "react-bootstrap-table2-editor";
import DialogContent from "@material-ui/core/DialogContent";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import filterFactory from "react-bootstrap-table2-filter";
import DialogTitle from "@material-ui/core/DialogTitle";
import axios from "axios";
import AppHeader from "../../../components/AppHeader/AppHeader";
import AppFooter from "../../../components/AppFooter/AppFooter";
import BreadCrumbs from "../../Upload/BreadCrumbs/BreadCrumbs";
import LoadingOverlay from "react-loading-overlay";
import UserForm from "../CreatenewWorkspace/UserForm";
import IconButton from "@material-ui/core/IconButton";
import Cancel from "@material-ui/icons/Cancel";
import CloseIcon from "@material-ui/icons/Close";
import UserStore from "../../UserStore";
import ExportExcel from "../../ExportExcel";
import TextFieldMui from "@material-ui/core/TextField";
import { withStyles} from "@material-ui/core/styles";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Tooltip from "@material-ui/core/Tooltip";
import CircleChecked from "@material-ui/icons/CheckCircleRounded";
import "./DataGrid.scss";
import Moment from "moment";
import "moment/locale/en-gb";

toast.configure();
const { BlockBlobClient } = require("@azure/storage-blob");
const API_Endpoint = process.env.REACT_APP_API_ENDPOINT;

// which user has logged in to application
const user = "Admin";

const customContentStyle = {
  width: "70%",
  maxWidth: "none",
};

const styles = (muiTheme) => ({
  label: {
    "&$erroredLabel": {
      color: "black",
    },
  },
  erroredLabel: {},
});

const TextField = withStyles(styles)(function TextField({ classes, ...props }) {
  return (
    <TextFieldMui
      InputLabelProps={{
        classes: {
          root: classes.label,
          error: classes.erroredLabel,
        },
      }}
      {...props}
    />
  );
});

var today = new Date();
export class DataGrid extends Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
      count: 0,
      result: {},
      myrow: [],
      myrowedit: [],
      setShow: false,
      showForm: false,
      // for user login 
      userFlag: true,
      sortcolumn: false,
      showUsers: false,
      showQuery: false,
      loading: true,
      users: [],
      filtereduserData: "",
      filterquery: "",
      editProducts: [],
      modalId: "",
      delete: false,
      columns: [        
        { dataField: "Wname", text: "Project Workspace", width: "200"},
        { dataField: "Wtype", text: "Workspace Type", width: "200" },
        { dataField: "Pname", text: "Client Name", width: "200" },
        { dataField: "users", text: "Users", width: "200" },
        { dataField: "detectionQuery", text: "Detection Query", width: "200"},
        { dataField: "Reports", text: "Export Report", width: "200" },        
        { dataField: "More", text: "More Actions", width: "200" },
      ],
      products: [],
      reportDataexcel: [],
      expandMenu: true,
      loggedInUser: UserStore.accountName,
      globalAdmin: false,
      loggedInuserEmail: UserStore.userEmail.toLowerCase(),
    };

    this.showMenu = this.showMenu.bind(this);
  }

  fetchIsometricFields = async () => {
    await axios.get(API_Endpoint + `/GetIsometricFields`).then((res) => {
      if (res.status === 200) {
        UserStore.setRegExpFields(res.data)
      }
    });
  };

  async componentDidMount() {
    let uuid = localStorage.getItem("uuid");
    UserStore.setIsTickBasedCreateWS(false);
    localStorage.setItem("perPageData",10);
    UserStore.SetPerPageData(10);
    this.checkGlobalAdmin();
    UserStore.setEditworkspace(false);
    UserStore.getWorkspaceId('');
    UserStore.setworkspaceuploadID('');
    UserStore.getworkspaceName('');    
    UserStore.isreadOnly(false);
    UserStore.emptyRejectedFiles();
    UserStore.emptySuccededFiles();    
    UserStore.setInbusinessrule(false);
    UserStore.setStampFilterRetain(false);
    UserStore.setUploadDateForProcess("");
    UserStore.setUploadFromDateForProcess("");
    UserStore.setSkipData(0);
    UserStore.setProcessGridData([]);
    localStorage.clear();
    localStorage.setItem("uuid",uuid);
    let columnsortval = UserStore.columnsortFlag;
    let createworkspace = UserStore.createworkspaceflag;
    UserStore.NewProjectName = "";
    UserStore.NewworkspaceName = "";
    UserStore.NewModelName = "";
    UserStore.addUserforWorkspace = [];
    UserStore.setDetectionQuery = "";     
    UserStore.newWorkspaceId = "";
    this.fetchIsometricFields();

    if (columnsortval == true) {
      this.state.sortcolumn = true;
    }

    if (user == "read/write" && columnsortval == false) {
      this.setState({
        columns: [          
          { dataField: "Wname", text: "Project Workspace" },
          { dataField: "Wtype", text: "Workspace Type" },
          { dataField: "Pname", text: "Client Name" },
          { dataField: "users", text: "Users" },
          { dataField: "detectionQuery", text: "Detection Query" },
          { dataField: "Reports", text: "Export Report" },          
          { dataField: "More", text: "More Actions" },
        ],
      });
    } else if (user == "read/write" && columnsortval == true) {
      this.setState({
         columns: [          
          { dataField: "Wname", text: "Project Workspace" },
          { dataField: "Wtype", text: "Workspace Type" },
          { dataField: "Pname", text: "Client Name" },
          { dataField: "users", text: "Users" },
          { dataField: "detectionQuery", text: "Detection Query" },
          { dataField: "Reports", text: "Export Report" },          
          { dataField: "More", text: "More Actions" },
        ],
      });
    } else if (user == "read") {
      this.setState({
        columns: [          
          { dataField: "Wname", text: "Project Workspace" },
          { dataField: "Wtype", text: "Workspace Type" },
          { dataField: "Pname", text: "Client Name" },
          { dataField: "users", text: "Users" },
          { dataField: "detectionQuery", text: "Detection Query" },
          { dataField: "Reports", text: "Export Report" },          
          { dataField: "More", text: "More Actions" },
        ],
      });
    }

    try {
      // ----------------------API: GET is logged in user global admin details------------------------
      const res = await axios.get(API_Endpoint + `/IsUserGlobalAdmin`);
      this.setState({globalAdmin : res.data});
      // ------------------------API: GET logged in user workspaces---------------------
      axios.get(API_Endpoint + `/GetWorkSpaces`).then((res) => {
        if (res.data) {
          
          this.setState({
            loading: false,
          });
        }

        if(res.status != 200) {
          this.setState({
            loading: false,
          });
        }

        UserStore.setreportStamp(false);
        this.state.users = res.data;
        this.setState(
          {
            loggedInuserEmail: UserStore.userEmail.toLowerCase()
          }
        );
        if (res.data) {
          const rows1 = res.data.reverse();
          rows1.map((items, index) => {          
            const row2 = {
              id: items.workSpaceId,
              Wname: 
              <Tooltip title="Click to view the processed files" arrow>
              <div className="processFiles" onClick={() =>{
                
                this.workSpaceClick(items);
                UserStore.addRowdata(items);
                UserStore.setworkspaceuploadID(items.workSpaceId);
                UserStore.getWorkspaceId(items.workSpaceId);
              }}
              style={{cursor:"pointer",color: "#0C629B", wordBreak: 'break-all'}}
              >
                          <span >{items.workSpaceName}</span>
                    </div>
                    </Tooltip>,              
              Wtype:items.isIsometricTickBased === false?"Phrase based":"Tick based",
              Pname: items.projectName,
              Reports: (
                <div className="reports-wrapper">
                  <ExportExcel
                    id={items.workSpaceId}
                    fileName={items.workSpaceName}
                    flag={1}
                    fromPreview={false}
                    wType = {items.isIsometricTickBased}
                  >
                    Report
                  </ExportExcel>
                </div>
              ),

              users: (
                <div className="usersList">
                  <Tooltip title="Click to view the users list" arrow>
                    <span
                      id={items.workSpaceId}
                      name="userinfo"
                      onClick={this.showUsername}
                    >
                      {items.workSpaceUsers.length}
                    </span>
                  </Tooltip>
                </div>
              ),
              detectionQuery:(
                <div className="usersList" style={items.isIsometricTickBased ? {opacity: "0.5", cursor: "not-allowed", pointerEvents: "none"} : {fontFamily: 'Helvetica'  }}>
                  <Tooltip title="Click to view the detection query" arrow>
                    <span
                      id={items.workSpaceId}
                      name="userinfo"
                      onClick={ () => {
                        let id = items.workSpaceId;
                         const queryValue = this.state.users
                          .filter((item) => item.workSpaceId == id).map(({ detectionExpression }) => ({ detectionExpression }));                    
                          
                          let filterQuery = queryValue.length ? queryValue[0].detectionExpression : '';
                              this.setState({
                                showQuery: true,
                                filterquery: filterQuery,
                              });
                    
                      }}
                      style={items.isIsometricTickBased ? {opacity: "0.5", cursor: "not-allowed", pointerEvents: "none"} : {fontFamily: 'Helvetica'  }}
                    >
                      <svg id={items.workSpaceId} onClick={ () => {
                        let id = items.workSpaceId;
                         const queryValue = this.state.users
                          .filter((item) => item.workSpaceId == id).map(({ detectionExpression }) => ({ detectionExpression }));                    
                          
                          let filterQuery = queryValue.length ? queryValue[0].detectionExpression : '';
                              this.setState({
                                showQuery: true,
                                filterquery: filterQuery,
                              });
                    
                      }} className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc" focusable="false" viewBox="0 0 24 24" aria-hidden="true" data-testid="QueryStatsIcon"><path d="M19.88 18.47c.44-.7.7-1.51.7-2.39 0-2.49-2.01-4.5-4.5-4.5s-4.5 2.01-4.5 4.5 2.01 4.5 4.49 4.5c.88 0 1.7-.26 2.39-.7L21.58 23 23 21.58l-3.12-3.11zm-3.8.11c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5zm-.36-8.5c-.74.02-1.45.18-2.1.45l-.55-.83-3.8 6.18-3.01-3.52-3.63 5.81L1 17l5-8 3 3.5L13 6l2.72 4.08zm2.59.5c-.64-.28-1.33-.45-2.05-.49L21.38 2 23 3.18l-4.69 7.4z"></path></svg>
                    </span>
                  </Tooltip>
                </div>
              ),
              More: (
                <div className="workspace-menu">
                  <div
                    className="more-actions-wrapper"
                    style={
                      this.state.expandMenu
                        ? { display: "inline-flex", marginLeft: "8%" }
                        : { display: "none" }
                    }
                  >
                      <Tooltip title="Upload Files" arrow>
                        <div className="upload-files" style={!this.state.globalAdmin ? (items.workSpaceUsers.find(x => x.enterpriseId.toLowerCase() === this.state.loggedInuserEmail.toLowerCase()).roleId === 1 || items.workSpaceUsers.find(x => x.enterpriseId.toLowerCase() === this.state.loggedInuserEmail.toLowerCase()).roleId === 2 ? {marginLeft: "-17%"} : {marginLeft: "-17%", opacity: "0.5", cursor: "not-allowed", pointerEvents: "none"}) : {marginLeft: "-17%"}}>
                          <label
                            htmlFor="file-upload"
                            className="custom-file-upload"
                            id={items.workSpaceId}
                            onClick={() => {
                              this.props.history.push(
                                `${this.props.match.path}/UploadFiles`
                              );
                              UserStore.getWorkspaceId(items.workSpaceId);
                              UserStore.setworkspaceuploadID(items.workSpaceId);
                              UserStore.emptySuccededFiles();
                              UserStore.getprocessGrid(0);
                              UserStore.getworkspaceName(items.workSpaceName);
                              UserStore.setTickBasedIsometric(items.isIsometricTickBased);
                              this.setWorkspace(items);
                              localStorage.setItem('getprocessGrid', 0);
                              UserStore.addRowdata(items);
                              localStorage.setItem('EditWSData', JSON.stringify(items));
                              const createdate = Moment(items.createdOn.toString(), "DD/MM/YYYY")
                              localStorage.setItem("startdate",createdate);
                              const convertEndate = Moment(
                                today.getFullYear() +
                                  "-" +
                                  (today.getMonth() + 1) +
                                  "-" +
                                  today.getDate()
                              ).format("MM/DD/YYYY");
                             const stringEndDate = convertEndate.toString();
                             localStorage.setItem("enddate", Moment(stringEndDate).format("DD/MMM/YYYY"));
                            }}
                          >
                          <div
                            className="upload-icon"
                            style={{ marginTop: "-5%", marginLeft: "5%" }}
                          >
                            <img
                              src="/images/icon_upload.svg"
                              style={{ height: "1.7vw" }}
                            />
                          </div>
                        </label>
                      </div>
                    </Tooltip>
                    <Tooltip title="Edit Workspace" arrow>
                      <div
                        className="edit-icon"                        
                        onClick={() => { this.editClick(items);}}
                        style={!this.state.globalAdmin ? (items.workSpaceUsers.find(x => x.enterpriseId.toLowerCase() === this.state.loggedInuserEmail.toLowerCase()).roleId === (1) ? { marginLeft: "12%", cursor: "pointer" } : {marginLeft: "12%", cursor: "not-allowed", opacity: "0.5", pointerEvents: "none"}) : { marginLeft: "12%", cursor: "pointer" }}
                      >
                        <img
                          id={items.workSpaceId}
                          style={{ height: "1.5vw" }}
                          src="/images/icon_edit.svg"
                        />
                      </div>
                    </Tooltip>
                    <Tooltip title="Delete Workspace" arrow>
                        <div
                        className="delete-icon"
                        style={this.state.globalAdmin ? { marginLeft: "12%", cursor: "pointer" } : { marginLeft: "12%", cursor: "not-allowed", opacity: "0.5",pointerEvents: "none" }}
                        onClick={this.state.userFlag ? this.handleDelete : ""}
                      >
                        <img
                          id={items.workSpaceId}
                          style={{ height: "1.5vw" }}
                          src="/images/icon_delete.svg"
                        />
                      </div>
                    </Tooltip>
                  </div>
                </div>
              ),
            };
            this.state.myrow.push(row2);
          });
          this.setState({
            count: 1,
          });
        }

        this.setState({
          products: this.state.myrow,
        });
      });
    } catch (error) {
      if (error.response) {
      } else if (error.request) {
      } else {
      }
    }
  }

  // ----------------------Start:Function: Navigate to upload page-------------------
  workSpaceClick = (items) => {
    const findRole = !this.state.globalAdmin ? (items.workSpaceUsers.find((x) => x.enterpriseId.toLowerCase() === this.state.loggedInuserEmail.toLowerCase()).roleId) : 1;
    if (findRole === 3) {
      UserStore.isreadOnly(true);
      localStorage.setItem('isreadOnly', true);
    } else {
      UserStore.isreadOnly(false);
      localStorage.setItem('isreadOnly', false);
    }
    
    UserStore.getWorkspaceId(items.workSpaceId);
    UserStore.setworkspaceuploadID(items.workSpaceId);
    UserStore.getprocessGrid(1);
    localStorage.setItem('getprocessGrid', 1);
    UserStore.getworkspaceName(items.workSpaceName);
    UserStore.emptySuccededFiles();
    UserStore.setTickBasedIsometric(items.isIsometricTickBased);
    this.props.history.push(`/Workspace/UploadFiles`);
    this.setWorkspace(items);    
  }
  // ----------------------End:Function: Navigate to upload page-------------------

  setWorkspace = (items) => {
    localStorage.setItem('WorkspaceId', items.workSpaceId);
    localStorage.setItem('workspaceName', items.workSpaceName);
    localStorage.setItem('isIsometricTickBased', items.isIsometricTickBased);
  }

  // --------------------------Start:Funtion: check logged in user is global admin------------------
  checkGlobalAdmin = async () => {
    // ----------------------API: GET logged in user is global admin or not--------------
    const res = await axios.get(API_Endpoint + `/IsUserGlobalAdmin`);
    this.setState({globalAdmin : res.data});
     
  }
  // --------------------------End:Funtion: check logged in user is global admin------------------

  showUsername = (users) => {
    let id = users.target.id;
    this.state.filtereduserData = this.state.users
      .filter((item) => item.workSpaceId == id)
      .map(({ workSpaceUsers }) => ({ workSpaceUsers }));
    this.setState({
      showUsers: true,
    });

  };

  showMenu() {
    this.setState({ expandMenu: !this.state.expandMenu });
  }

  UserNameList = (users) => {
    return users
      .map((item) => {
        return item.enterpriseId;
      })
      .join(",");
  };

  // --------------------Start:Function: download workspace report-----------------------
  async getReportdata(fromDate, toDate, workSpaceId) {
    // -----------------------API: GET workspace report-------------------
    const res = await axios.get(
      API_Endpoint +
        `/GetProcessedIsometricsReport?workSpaceId=${workSpaceId}`
    );
    return res.data;
  }
  // --------------------End:Function: download workspace report-----------------------

  async FetchData() {
    this.setState({
      products: [],
      myrowedit: [],
    });
    
    UserStore.cleareReportdata();
    UserStore.setreportStamp(false);
    try {
      // ------------------API: GET logged in user workspaces----------------------
      axios.get(API_Endpoint + `/GetWorkSpaces`).then((res) => {
        const rows1 = res.data;
        
        if (rows1) {
          rows1.map((items, index) => {
            const row2 = {
              id: items.workSpaceId,
              Wname: items.workSpaceName,
              Pname: items.projectName,
              Reports: (
                <div className="reports-wrapper">
                  <ExportExcel
                    id={items.workSpaceId}
                    fileName={items.workSpaceName}
                    fromPreview={false}
                  >
                    Report
                  </ExportExcel>
                </div>
              ),

              users: (
                <div className="usersList">
                  <Tooltip title="Click to view the users list" arrow>
                    <span
                      id={items.workSpaceId}
                      name="userinfo"
                      onClick={this.showUsername}
                    >
                      {items.workSpaceUsers.length}
                    </span>
                  </Tooltip>
                </div>
              ),
              More: (
                <div className="workspace-menu">
                  <div
                    className="more-actions-wrapper"
                    style={
                      this.state.expandMenu
                        ? { display: "inline-flex", marginLeft: "8%" }
                        : { display: "none" }
                    }
                  >
                  <Tooltip title="Upload Files" arrow>
                    <div className="upload-files" style={!this.state.globalAdmin ?(items.workSpaceUsers.find(x => x.enterpriseId.toLowerCase() === this.state.loggedInuserEmail.toLowerCase()).roleId === 1 || items.workSpaceUsers.find(x => x.enterpriseId.toLowerCase() === this.state.loggedInuserEmail.toLowerCase()).roleId === 2 ? { marginLeft: "-17%" } : { marginLeft: "-17%", opacity: "0.5", cursor: "not-allowed", pointerEvents: "none" }) : { marginLeft: "-17%" } }>
                      <label
                        for="file-upload"
                        className="custom-file-upload"
                        id={items.workSpaceId}  
                        onClick={() => {
                          this.workSpaceClick(items);
                          UserStore.addRowdata(items);
                          localStorage.setItem('EditWSData', JSON.stringify(items));
                        }}
                      >
                        <div
                          className="upload-icon"
                          style={{ marginTop: "-5%", marginLeft: "5%" }}
                        >
                          <img
                            src="/images/icon_upload.svg"
                            style={{ height: "1.7vw" }}
                          />
                        </div>
                      </label>
                    </div>
                  </Tooltip>
                    <Tooltip title="Edit Workspace" arrow>
                    <div
                      className="edit-icon"                     
                     onClick={() => { this.editClick(items);}}
                      style={!this.state.globalAdmin ? (items.workSpaceUsers.find(x => x.enterpriseId.toLowerCase() === this.state.loggedInuserEmail.toLowerCase()).roleId === 1 ? { marginLeft: "12%", cursor: "pointer" } : { marginLeft: "12%", cursor: "not-allowed", opacity: "0.5", pointerEvents: "none" }) : { marginLeft: "12%", cursor: "pointer" }}
                    >
                    <img
                        id={items.workSpaceId}
                        style={{ height: "1.5vw" }}
                        src="/images/icon_edit.svg"
                      />
                    </div>
                    </Tooltip>
                    <Tooltip title="Delete Workspace" arrow>
                    <div
                      className="delete-icon"
                      style={this.state.globalAdmin ? { marginLeft: "12%", cursor: "pointer" }: { marginLeft: "12%", cursor: "pointer", opacity: "0.5", pointerEvents: "none"}}
                      onClick={
                        this.state.userFlag
                          ? (e) => {
                              if (
                                window.confirm(
                                  "Are you sure you wish to delete this item?"
                                )
                              )
                                this.handlePurge(e);
                            }
                          : ""
                      }
                    >
                      <img
                        id={items.workSpaceId}
                        style={{ height: "1.5vw" }}
                        src="/images/icon_delete.svg"
                      />
                    </div>
                    </Tooltip>
                  </div>
                </div>
              ),
            };

            this.state.myrowedit.push(row2);
          });
        }
        this.setState({
          products: this.state.myrowedit,
        });
      });
    } catch (error) {
      if (error.response) {
      } else if (error.request) {
      } else {
      }
    }
  }

  handleEdit = (items) => {
    let data = items;
  };

  handleDelete = (e) => {
    this.setState({ delete: true });
  };

  handleCloseDialog = () => {
    this.setState({ delete: false });
  };

  handleClose = () => {
    this.setState({
      setShow: false,
      showUsers: false,
      showQuery:false,
    });
  };

  handleShow = () => {
    this.setState({ setShow: true });
  };

  handlerole = (role) => {
    switch (role) {
      case 1:
        return "Admin";

      case 2:
        return "Read / Write";

      case 3:
        return "Read Only";
    }
  };

  handleSave = () => {
    // send the output data to the service
    const result = this.state.products.filter(
      (row) => row.id == this.state.modalId
    );
    const data = {
      workSpaceId: result[0].id,
      workSpaceName: result[0].Wname,
      projectName: result[0].Pname,
      areaId: 1,
    };

    let value = 0;
    // -----------------input validations----------------------
    if (data.projectName === "") {
      value = value + 1;
      this.setState({
        projectNameError: true,
        projectNameMessage: "Project Name should not be empty",
      });
    }
    if (data.workSpaceName === "") {
      value = value + 1;
      this.setState({
        workSpaceError: true,
        workSpaceMessage: "Work Space should not be empty",
      });
    }
    if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(data.workSpaceName)) {
      value = value + 1;
      this.setState({
        workSpaceError: true,
        workSpaceMessage:
          "work space name should not contain special characters",
      });
    }
    if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(data.projectName)) {
      value = value + 1;
      this.setState({
        projectNameError: true,
        projectNameMessage:
          "project name should not contain special characters",
      });
    }
    if (value === 0) {
      axios.post(API_Endpoint + `/EditWorkSpace`, data).then((res) => {
        if (res.statusText === "OK") {
          this.FetchData();
        }
      });
      this.setState({
        products: [],
        setShow: false,
      });
    }
  };

  // open the new Create Workspace page
  Openworkspace = () => {
    this.setState({ showForm: true });
  };

  handleChange1 = (event) => {
    let id = event.target.id;
    const rowneeded = this.state.products.filter((row) => row.id === id);
    if (event.target.name === "Wname") {
      rowneeded[0].Wname = event.target.value;
    } else if (event.target.name === "users") {
      rowneeded[0].users = event.target.value;
    } else {
      rowneeded[0].Pname = event.target.value;
    }
    const rowneeded2 = this.state.products.filter((row) => row.id != id);
    rowneeded2.push(rowneeded[0]);
    this.setState({ editProducts: rowneeded });
  };

  // ---------------------Start:Function: Delete workspace-------------------
  handlePurge = (e) => {
    let id = this.state.deleteId;
    const rowneeded = this.state.products.filter((row) => row.id != id);
    const data = { workSpaceId: id };
    this.setState({ products: rowneeded, delete: false });
    // API: DELETE workspace
    axios.post(API_Endpoint + `/DeleteWorkSpace`, data);
  };
  // ---------------------End:Function: Delete workspace-------------------

  handleDelete = (e) => {
    this.setState({
      delete: true,
      deleteId: e.target.id,
    });
  };

  handleOnSelect = (row, isSelect) => {
    this.props.history.push(`${this.props.match.path}/UploadFiles`);
  };

  handleChange = async (event) => {
    const file = event.target.files[0];
    const filename = file.name;
    const data = {
      workSpaceId: 1,
      fileName: filename,
      areaId: 1,
      tagIds: [1, 2],
    };

    await axios.post(API_Endpoint + `/GetUploadPath`, data).then((res) => {
      let uri = res.data.sasUri;
      const blobServiceClient1 = new BlockBlobClient(uri);
      const uploadBlobResponse = blobServiceClient1.uploadData(file);
    });
  };

  // --------------------Start:Function: Edit workspace---------------
  editClick = (items) => {
    UserStore.addRowdata(items); 
    this.setWorkspace(items);
    UserStore.getWorkspaceId(items.workSpaceId);
    UserStore.setworkspaceuploadID(items.workSpaceId);
    UserStore.getworkspaceName(items.workSpaceName);
    this.props.history.push(`/Workspace/EditWorkSpaceStamp`);
    localStorage.setItem('EditWSData', JSON.stringify(items));
    UserStore.setEditworkspace(true);
    localStorage.setItem("Editworkspace", true);
  }
  // --------------------End:Function: Edit workspace---------------

  render() {
    const selectRow = {
      mode: "radio",
      clickToSelect: true,
      onSelect: this.handleOnSelect,
    };

    const cellEdit = cellEditFactory({
      mode: "click",
      blurToSave: true,
    });

    if (!this.state.showForm) {
      return (
        <div className="workspace-container">
          {this.showForm && <UserForm />}
          <AppHeader />
          <BreadCrumbs />

          <LoadingOverlay
            active={this.state.loading}
            spinner
            text="Loading your content..."
          ></LoadingOverlay>
          <div className="workspace-wrapper">
            <div className="worspace-titlebar">
              <div className="workspace-title">PROJECT WORKSPACE</div>
              <div className="create-new-workspace-wrapper">
                {this.state.globalAdmin && (
                  <div
                    className="create-new-workspace-btn"
                    onClick={
                      this.state.userFlag
                        ? () => {
                            this.props.history.push(
                              `${this.props.match.path}/CreateNewWorkspace`
                            );
                          }
                        : ""
                    }
                    style={{ fontSize: "1vw", width: "15vw" }}
                  >
                    <img
                      className="icon-add"
                      src="/images/icon_add.svg"
                      style={{ marginRight: "3%", height: "1.7vw" }}
                    />
                    Create Project Workspace
                  </div>
                )}
              </div>
            </div>

            {this.state.userFlag && (
              <div className="workspace-data-table-wrapper">
                <BootstrapTable
                  className="workspace-data-table"
                  keyField="id"
                  data={this.state.products}
                  columns={this.state.columns}
                  pagination={paginationFactory()}
                  filter={filterFactory()}
                  sort={
                    this.state.sortcolumn
                      ? { dataField: "id", order: "dasc" }
                      : { dataField: "id", order: "asc" }
                  }
                />
              </div>
            )}
            {!this.state.userFlag && (
              <div className="workspace-data-table-wrapper">
                <BootstrapTable
                  className="workspace-data-table"
                  keyField="id"
                  data={this.state.products}
                  columns={this.state.columns}
                  pagination={paginationFactory()}
                  filter={filterFactory()}
                  sort={
                    this.state.sortcolumn
                      ? { dataField: "id", order: "asc" }
                      : { dataField: "id", order: "asc" }
                  }
                  selectRow={selectRow}
                />
              </div>
            )}
            {this.state.setShow && this.state.editProducts && (
              <Dialog
                open={this.state.setShow}
                onClose={this.handleClose}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle id="form-dialog-title">Edit Workspace</DialogTitle>
                <DialogContent>
                  <TextField
                    error={this.state.workSpaceError}
                    autoFocus
                    margin="dense"
                    id={this.state.modalId}
                    name="Wname"
                    label="Workspace name"
                    type="text"
                    value={this.state.editProducts[0].Wname}
                    onChange={(e) => this.handleChange1(e)}
                    fullWidth
                    helperText={this.state.workSpaceMessage}
                  />
                  <TextField
                    error={this.state.projectNameError}
                    margin="dense"
                    id={this.state.modalId}
                    name="Pname"
                    label="Project name"
                    value={this.state.editProducts[0].Pname}
                    onChange={(e) => this.handleChange1(e)}
                    fullWidth
                    helperText={this.state.projectNameMessage}
                  />
                  <TextField
                    margin="dense"
                    id={this.state.modalId}
                    name="users"
                    label="Add Users"
                    value={this.state.editProducts[0].users}
                    onChange={(e) => this.handleChange1(e)}
                    fullWidth
                  />
                </DialogContent>
                <DialogActions>
                  <Button
                    style={{
                      border: "1px solid #4B4A55",
                      background: "#0C629B",
                      color: "#fff",
                      width: "6em",
                    }}
                    onClick={this.handleSave}
                    color="primary"
                  >
                    Save
                  </Button>
                  <Button
                    style={{ border: "1px solid #4B4A55" }}
                    onClick={this.handleClose}
                    color="primary"
                  >
                    Cancel
                  </Button>
                </DialogActions>
                {/* </Grid> */}
              </Dialog>
            )}
            {this.state.showUsers && (
              <Dialog
                className="users-dialog userListDialog"
                fullWidth
                maxWidth="md"
                contentStyle={customContentStyle}
                open={this.state.showUsers}
                onClose={this.handleClose}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle id="form-dialog-title">
                  <span style={{color: "#fff"}}>User Details</span>
                  <IconButton
                    onClick={this.handleClose}
                    style={{
                      padding: "0",
                      float: "right",
                      background: "#0C629B",
                      borderRadius: "0",
                      color: "#fff",
                    }}
                  >
                    {" "}
                    <CloseIcon />{" "}
                  </IconButton>
                </DialogTitle>
                <DialogContent style={{ height: "auto", overflow: "hidden" }}>
                  {
                    <table bordered className="table1" border="0">
                      <thead align="center" valign="bottom" char="M">
                        <tr>
                          <th align="left">Users</th>
                          <th>User Group</th>
                        </tr>
                      </thead>
                      <tbody>
                      {this.state.filtereduserData[0].workSpaceUsers.map(
                        (items) => (
                          <tr>
                            <td>{items.enterpriseId}</td>
                            <td>{this.handlerole(items.roleId)}</td>
                          </tr>
                        )
                      )}
                      </tbody>
                    </table>
                  }
                </DialogContent>
              </Dialog>
            )}
            {this.state.showQuery && (
              <Dialog
                className="users-dialog userListDialog"
                fullWidth
                maxWidth="md"
                contentStyle={customContentStyle}
                open={this.state.showQuery}
                onClose={this.handleClose}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle id="form-dialog-title">
                  <span style={{color: "#fff"}}>Detection Query</span>
                  <IconButton
                    onClick={this.handleClose}
                    style={{
                      padding: "0",
                      float: "right",
                      background: "#0C629B",
                      borderRadius: "0",
                      color: "#fff",
                    }}
                  >
                    {" "}
                    <CloseIcon />{" "}
                  </IconButton>
                </DialogTitle>
                <DialogContent style={{ height: "auto", overflow: "hidden", padding: "8px 11px" }}>
                  {
                    this.state.filterquery
                  }
                </DialogContent>
              </Dialog>
            )}
            <Dialog
              className="delete-confirm"
              open={this.state.delete}
              onClose={this.handleCloseDialog}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle
                id="form-dialog-title"
                style={{ height: "0.625em" }}
              ></DialogTitle>
              <DialogContent>
                <div style={{ fontSize: "1vw" }}>Are you sure to delete?</div>
                <IconButton
                  onClick={this.handleCloseDialog}
                  style={{
                    padding: "0",
                    float: "right",
                    background: "#0C629B",
                    color: "#fff",
                    position: "absolute",
                    right: "15%",
                  }}
                >
                  {" "}
                  <Cancel />{" "}
                </IconButton>
                <IconButton
                  onClick={(e) => this.handlePurge(e)}
                  style={{
                    padding: "0",
                    background: "#0C629B",
                    color: "white",
                    position: "absolute",
                    right: "2%",
                  }}
                >
                  {" "}
                  <CircleChecked />{" "}
                </IconButton>
              </DialogContent>
            </Dialog>
            
          </div>
          <AppFooter />
        </div>
      );
    } else {
      return <UserForm />;
    }
  }
}
export default DataGrid;


