import React, { useRef } from 'react';
import "./Suggestions.scss";
import 'bootstrap/dist/css/bootstrap.min.css';

export default function Suggestions(props) {

    const suggestionClickHandler = (e) => {
        props.suggestionClickHandler(e.currentTarget.id);
    }

    const myRef = useRef(null)

    const borders = {
        boxShadow: "0 2px 3px #ccc",
        boxSizing: "border-box",
    }

    let suggestions = props.suggestions.map((s, index) => {
        return (<div className="suggestion-item" key={index} id={s.mail} onMouseDown={suggestionClickHandler}><b>{s.displayName}</b> {s.mail}</div>);
      });

    return (
        <div                
        className="suggestions-box" style={borders}>
            {suggestions}
        </div>
    );
};
