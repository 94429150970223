import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import {TextField } from "@material-ui/core";
import { Button } from '@material-ui/core';
import './AddLine.scss';
import { useDispatch, useSelector } from "react-redux";
import {
    editLineData,
    deleteLine
  } from "../../../redux/actions/documentActions";

export default function AddLine(props) {
    const [ addnew, setAddnew ] = useState(false);
    const lineGroups = useSelector(state => state.documentData.lineGroupSpec);
    const dispatch = useDispatch();
    const edit = props.assetProperties.edit;
    const assetProps = props.assetProperties;
    const handleAdd = (e) => {
        props.onHide();
        setAddnew(false)
    }
    const handleEdit = (e) => {
        const allProperties = {
            oldSpecification: assetProps.lineNumber,
            lineNumber: document.getElementById("add-new").value !== "" ? document.getElementById("add-new").value : document.getElementById("line-number").value,
            id: assetProps.other.id,
            assetType: "lines",
            Coords: assetProps.coords,
            // orientation: assetProps.orientation,
            LineSegmentId: assetProps.LineSegmentId,
            orientation: 0,
            specification: document.getElementById("add-new").value !== "" ? document.getElementById("add-new").value : document.getElementById("line-number").value,
        }
        setAddnew(false);
        dispatch(editLineData(allProperties));
        props.onHide();
    }

    const handleClose = () => {
        setAddnew(false);
            props.onClose();
    }

    
    const handleDelete = () => {
        if(edit == true) {
            assetProps.LineNumber = assetProps.lineNumber;
        assetProps.assetId = assetProps.id;
        props.onClose();
        dispatch(deleteLine(assetProps));
        setAddnew(false);
        props.delete();
        }
        else {
            setAddnew(false);
            props.onClose();
        }
        
    }

    const handleLineChange = () => {
        const lineno = document.getElementById("line-number").value;
        props.onSelectChange(lineno);
    }

    return (
        <Modal className="add-line-popup" show={props.show} onHide={props.onHide} backdrop="static" keyboard={false} dialogClassName="fullmodal-add-line" centered>
            <Modal.Body className="line-modal-body">
                <label for="line-number">
                    <span className="label-text-line">Line Number</span>
                </label>
                <select id="line-number" name="line-number" className="input-field-line" 
                onChange={handleLineChange}
                 defaultValue={edit === true ? props.assetProperties.lineNumber : ""}
                 >
                        {
                             lineGroups.map((value) =>
                                 <option value={value}> {value === "UNKNOWN" ? "Not Detected" : value} </option>
                             )
                        }
                </select>
                <span className="add-line-text" onClick={() => setAddnew(!addnew)}>+Add New</span>
                <TextField id="add-new" className="add-new-line" type="text" style={addnew ? {display: "block"} : {display: "none"}} InputLabelProps={{ shrink: true }}
                    onChange={edit === true ? "" : props.onChange}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" 
                onClick={edit === true ? handleEdit : handleAdd}>
                    Add</Button>
                <Button variant="primary" onClick={handleClose}>Cancel</Button>
                <Button variant="primary" onClick={handleDelete}>Delete</Button>
            </Modal.Footer>
        </Modal>
        
    );
}