import React from "react";
import PropTypes from "prop-types";
import { isUndefined } from "./lib/util";

/**
 * SearchBar search input component
 * @extends React
 */
class SearchBar extends React.Component {
  /**
   * constructor
   * @param {Object} props
   */
  constructor(props) {
    super(props);
  }

  /**
   * _searchInputChanged
   * @param  {Event} e
   */
  _searchInputChanged = (e) => {
    const newValue = document.getElementById("inputTag").value;
    this._callSearchChanged(newValue);
  };

  _searchKeyEntered = (e) => {
    if (e.charCode === 13 || e.keyCode === 13) {
      const newValue = document.getElementById("inputTag").value;
      this._callSearchChanged(newValue);
    }
  };

  /**
   * _callSearchChanged function called to update the filters according to search
   * @param  {String} val
   */
  _callSearchChanged = (val) => {
    this.props.searchChanged && this.props.searchChanged(val);
  };

  /**
   * render
   * @return {JSX}
   */
  render() {
    const sortClass = !isUndefined(this.props.sortType)
      ? " " + this.props.sortType
      : "";
    return (
      <div className="search-parent filter-list-item">
        <input
          id="inputTag"
          className="search-input"
          type="text"
          placeholder="search"
          onKeyPress={this._searchKeyEntered}
        />
        <div className="search-button" onClick={this._searchInputChanged}>
          <img
            src="/images/Search-Icon.svg"
            class="search-icon-dropdown"
            title="Search"
          />
        </div>
      </div>
    );
  }
}

SearchBar.propTypes = {
  searchChanged: PropTypes.func.isRequired,
};

export default SearchBar;
