import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import axios from "axios";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

function createData(name, calories, fat, carbs, protein, price) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
    price,
    history: [
      {
        date: "2020-01-05",
        customerId: "11091700",
        amount: 3,
      },
      {
        date: "2020-01-02",
        customerId: "Anonymous",
        amount: 1,
      },
    ],
  };
}

function Row(props) {
  const items = props.row;
  const [open, setOpen] = React.useState(false);

  if(props.frompid !== "pid")
  {

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          {items.batchScheduleDateTime !== ""
            ? moment
                .utc(items.batchScheduleDateTime, "DD/MM/YYYY hh:mm:ss a")
                .local()
                .format("DD/MM/YYYY hh:mm:ss a")
            : ""}
        </TableCell>
        <TableCell align="right">{items.batchCreatedBy}</TableCell>
        <TableCell align="right">
          {items.batchProcessingDateTime !== ""
            ? moment
                .utc(items.batchProcessingDateTime, "DD/MM/YYYY hh:mm:ss a")
                .local()
                .format("DD/MM/YYYY hh:mm:ss a")
            : ""}
        </TableCell>
        <TableCell align="right">{items.totalFilesUploaded}</TableCell>
        <TableCell align="right">{items.filesProcessedSuccessfully}</TableCell>
        <TableCell align="right">{items.fileWaitingForProcessing}</TableCell>
        <TableCell align="right">{items.filesProcessedFailure}</TableCell>
        <TableCell align="right">{items.totalIsometricUploaded}</TableCell>
        <TableCell align="right">
          {items.isometricProcessedSuccessfully}
        </TableCell>
        <TableCell align="right">
          {items.isometricWaitingForProcessing}
        </TableCell>
        <TableCell align="right">{items.isometricProcessedFailure}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases" className="subTable">
                <TableHead>
                  <TableRow>
                    <TableCell>Binder File Name</TableCell>
                    <TableCell align="right">Total Isometrics</TableCell>
                    <TableCell align="right">
                      Isometric Processed Successfully
                    </TableCell>
                    <TableCell align="right">
                      Isometric Waiting For Processing
                    </TableCell>
                    <TableCell>Isometric Processed Failure</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items.binderFilesInformation.map((items) => (
                    <TableRow>
                      <TableCell align="right">
                        {items.binderFileName}
                      </TableCell>
                      <TableCell align="right">
                        {items.totalIsometrics}
                      </TableCell>
                      <TableCell align="right">
                        {items.isometricProcessedSuccessfully}
                      </TableCell>
                      <TableCell align="right">
                        {items.isometricWaitingForProcessing}
                      </TableCell>
                      <TableCell align="right">
                        {items.isometricProcessedFailure}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
                  }
                  else{
                    return (
                      <React.Fragment>
                        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                          <TableCell>
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={() => setOpen(!open)}
                            >
                              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                          </TableCell>
                          <TableCell>
                            {items.batchScheduleDateTime !== ""
                              ? moment
                                  .utc(items.batchScheduleDateTime, "DD/MM/YYYY hh:mm:ss a")
                                  .local()
                                  .format("DD/MM/YYYY hh:mm:ss a")
                              : ""}
                          </TableCell>
                          <TableCell align="right">{items.batchCreatedBy}</TableCell>
                          <TableCell align="right">
                            {items.batchProcessingDateTime !== ""
                              ? moment
                                  .utc(items.batchProcessingDateTime, "DD/MM/YYYY hh:mm:ss a")
                                  .local()
                                  .format("DD/MM/YYYY hh:mm:ss a")
                              : ""}
                          </TableCell>
                          <TableCell align="right">{items.totalFilesUploaded}</TableCell>
                          <TableCell align="right">{items.filesProcessedSuccessfully}</TableCell>
                          <TableCell align="right">{items.fileWaitingForProcessing}</TableCell>
                          <TableCell align="right">{items.filesProcessedFailure}</TableCell>
                          <TableCell align="right">{items.totalSheetUploaded}</TableCell>
                          <TableCell align="right">
                            {items.sheetsProcessedSuccessfully}
                          </TableCell>
                          <TableCell align="right">
                            {items.sheetsWaitingForProcessing}
                          </TableCell>
                          <TableCell align="right">{items.sheetsProcessedFailure}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                            <Collapse in={open} timeout="auto" unmountOnExit>
                              <Box sx={{ margin: 1 }}>
                                <Table size="small" aria-label="purchases" className="subTable">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>Binder File Name</TableCell>
                                      <TableCell align="right">Total Sheets</TableCell>
                                      <TableCell align="right">
                                      Sheets Processed Successfully
                                      </TableCell>
                                      <TableCell align="right">
                                      Sheets Waiting For Processing
                                      </TableCell>
                                      <TableCell>Sheets Processed Failure</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {items.binderFilesInformation.map((items) => (
                                      <TableRow>
                                        <TableCell align="right">
                                          {items.binderFileName}
                                        </TableCell>
                                        <TableCell align="right">
                                          {items.totalPids}
                                        </TableCell>
                                        <TableCell align="right">
                                          {items.pidProcessedSuccessfully}
                                        </TableCell>
                                        <TableCell align="right">
                                          {items.pidWaitingForProcessing}
                                        </TableCell>
                                        <TableCell align="right">
                                          {items.pidProcessedFailure}
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    );
                  }
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      })
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

export default function BatchTable(props) {
  const [rows1, Setrows] = useState([]);
  let pid = props.frompid;
  

  useEffect(() => {
    // Update the document title using the browser API
    const APIEndpoint = process.env.REACT_APP_API_ENDPOINT;
    if (props.frompid !== "pid") {
      const res = axios
        .get(APIEndpoint + `/GetBatchStatus?workSpaceId=${props.wsId}`)
        .then((res) => {
          const myrows = res.data;
          let rowget = [];
          if (myrows) {
            myrows.map((items, index) => {
              const row2 = {
                id: items.batchId,
                batchScheduleDateTime: items.batchScheduleDateTime,
                batchCreationDateTime: items.batchCreationDateTime,
                batchProcessingDateTime: items.batchProcessingDateTime,
                batchCreatedBy: items.batchCreatedBy,
                filesProcessedSuccessfully: items.filesProcessedSuccessfully,
                filesProcessedFailure: items.filesProcessedFailure,
                binderFilesInformation: items.binderFilesInformation,
                fileWaitingForProcessing: items.fileWaitingForProcessing,
                totalFilesUploaded: items.totalFilesUploaded,
                totalIsometricUploaded: items.totalIsometricUploaded,
                isometricProcessedSuccessfully:
                  items.isometricProcessedSuccessfully,
                isometricProcessedFailure: items.isometricProcessedFailure,
                isometricWaitingForProcessing:
                  items.isometricWaitingForProcessing,
              };

              rowget.push(row2);
            });
          }
          Setrows(rowget);
        });
    } else {
      const res = axios
        .get(APIEndpoint + `/GetPidBatchStatus?workSpaceId=${props.wsId}`)
        .then((res) => {
          const myrows = res.data;
          let rowget = [];
          if (myrows) {
            myrows.map((items, index) => {
              const row2 = {
                id: items.batchId,
                batchScheduleDateTime: items.batchScheduleDateTime,
                batchCreationDateTime: items.batchCreationDateTime,
                batchProcessingDateTime: items.batchProcessingDateTime,
                batchCreatedBy: items.batchCreatedBy,
                filesProcessedSuccessfully: items.filesProcessedSuccessfully,
                filesProcessedFailure: items.filesProcessedFailure,
                binderFilesInformation: items.binderFilesInformation,
                fileWaitingForProcessing: items.fileWaitingForProcessing,
                totalFilesUploaded: items.totalFilesUploaded,
                totalSheetUploaded: items.totalSheetUploaded,
                sheetsProcessedSuccessfully:items.sheetsProcessedSuccessfully,
                sheetsProcessedFailure: items.sheetsProcessedFailure,
                sheetsWaitingForProcessing:items.sheetsWaitingForProcessing,
              };

              rowget.push(row2);
            });
          }
          Setrows(rowget);
        });
    }
  }, []);

  if (props.frompid !== "pid") {
    return (
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table" className="mainTable">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Batch Schedule DateTime</TableCell>
              <TableCell align="right">Batch Created By</TableCell>
              <TableCell align="right">Batch Processing DateTime</TableCell>
              <TableCell align="right">Total Files Uploaded</TableCell>
              <TableCell align="right">Files Processed Successfully</TableCell>
              <TableCell align="right">File Waiting For Processing</TableCell>
              <TableCell align="right">Files Processed Failure</TableCell>
              <TableCell align="right">Total Isometric Uploaded</TableCell>
              <TableCell align="right">
                Isometric Processed Successfully
              </TableCell>
              <TableCell align="right">
                Isometric Waiting For Processing
              </TableCell>
              <TableCell align="right">Isometric Processed Failure</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows1.map((row) => (
              <Row key={row.name} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  } else {
    return(
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table" className="mainTable">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Batch Schedule DateTime</TableCell>
            <TableCell align="right">Batch Created By</TableCell>
            <TableCell align="right">Batch Processing DateTime</TableCell>
            <TableCell align="right">Total Files Uploaded</TableCell>
            <TableCell align="right">Files Processed Successfully</TableCell>
            <TableCell align="right">File Waiting For Processing</TableCell>
            <TableCell align="right">Files Processed Failure</TableCell>
            <TableCell align="right">Total Sheets Uploaded</TableCell>
            <TableCell align="right">Sheets Processed Successfully</TableCell>
            <TableCell align="right">Sheets Waiting For Processing</TableCell>
            <TableCell align="right">Sheets Processed Failure</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows1.map((row) => (
            <Row key={row.name} row={row} frompid={"pid"}/>
          ))}
        </TableBody>
      </Table>
    </TableContainer>);
  }
}
