import React,  { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { callMsGraph } from "../../graph";
import { loginRequest } from "../../authConfig";
import { profilePic } from "../../profilePic";
import UserStore from "../UserStore";
import Tooltip from "@material-ui/core/Tooltip";
import { toast } from "react-toastify";
import axios from "axios";
import "./AppHeader.scss";
import { toJS } from "mobx";
import { useIdleTimer } from 'react-idle-timer';
import Modal from "react-bootstrap/Modal";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {v4 as uuidv4} from 'uuid';



export default function AppHeader() {
  const history = useHistory();
  const { instance, accounts } = useMsal();
  let [accountsValue] = accounts;
  let graphData, graphDataforUsers, theImage;
  const timeout = parseInt(process.env.REACT_APP_SESSION_TIMEOUT); /* 5 minutes in milli seconds - 300000, 1800000 for 30 minutes  */
  const [remaining, setRemaining] = useState(timeout)
  const [elapsed, setElapsed] = useState(0)
  const [lastActive, setLastActive] = useState(+new Date())
  const [isIdle, setIsIdle] = useState(false);
  const [show,setShow] = useState(false);
   /* logic for session time out */
  const handleOnIdle = async() =>{
     // calling api for concurrent session management;
     const options = {
    "UserId": localStorage.getItem("userEmail"),
      "UniqueSessionCode": localStorage.getItem("uuid"),
    }
      
       const res = await axios.post(
          APIEndpoint + `/LogoutSessionTable`,
          options
        );
        localStorage.removeItem("uuid");
      
    instance.logoutRedirect({ postLogoutRedirectUri: "/" });
  }

  const {
    reset,
    pause,
    resume,
    getRemainingTime,
    getLastActiveTime,
    getElapsedTime
  } = useIdleTimer({
    timeout,
    onIdle: handleOnIdle
  })

  useEffect(() => {
    setRemaining(getRemainingTime())
    setLastActive(getLastActiveTime())
    setElapsed(getElapsedTime())

    setInterval(() => {
      setRemaining(getRemainingTime())
      setLastActive(getLastActiveTime())
      setElapsed(getElapsedTime())
    }, 1000)
  }, [])

  const handleClose = () => {
    setShow(false);
  };
  const sessionTimeoutNotify = parseInt(process.env.REACT_APP_SESSION_TIMEOUT_NOTIFY);
  useEffect(() => {
    if(remaining <= sessionTimeoutNotify && remaining > 1) {
      setShow(true);
    }
    if(remaining <= 1 || remaining > sessionTimeoutNotify) {
      setShow(false);
    }
  },[remaining]);

  useEffect(() => {
    if(localStorage.getItem("apicalls") === "active") {
      pause();
    }
    if(localStorage.getItem("apicalls") === "inactive") {
      resume();
      setRemaining(timeout);
      localStorage.setItem("apicalls","")
    }
  },[localStorage.getItem("apicalls")])

  const handleLogout = async() => {
     // calling api for concurrent session management;
     const options = {
    "UserId": localStorage.getItem("userEmail"),
      "UniqueSessionCode": localStorage.getItem("uuid"),
    }
      
       const res = await axios.post(
          APIEndpoint + `/LogoutSessionTable`,
          options
        );
        localStorage.removeItem("uuid");

        instance.logoutRedirect({ postLogoutRedirectUri: "/" });
    
  };

  const navigateToHome = () => {
    history.push("/");
  };

  const APIEndpoint = process.env.REACT_APP_API_ENDPOINT;
  useEffect(() => {
    if (accountsValue) {
      apigraph();
      getToken();
      UserStore.setaccountName(accountsValue.name);
      UserStore.setUserEmail(accountsValue.username.toLowerCase());
      localStorage.setItem('userEmail', accountsValue.username.toLowerCase());
      UserStore.setAccountInfo(accountsValue); 
    }
  }, [accountsValue]);


  let sessionValidateTime = parseInt(process.env.REACT_APP_VALIDATE_SESSION_TIME);
  const validateSession = async(options) => {
    const res = await axios.post(
      APIEndpoint + `/ValidateSessionTable`,
      options
    );
  
  if(res.data === false) {
    handleLogout();
  }
  else
    setTime(sessionValidateTime);

  }
  /* validate if user session is active or not every 20 secs */

  useEffect(() => {
    const interval = setInterval(() => {
        const options = {
      "UserId": localStorage.getItem("userEmail"),
      "UniqueSessionCode": localStorage.getItem("uuid"),
    }

      validateSession(options);
    }, sessionValidateTime);
    return () => clearInterval(interval);
  }, []);
  const [time,setTime] = useState(sessionValidateTime);
  
  async function getToken() {
    const response = await instance.acquireTokenSilent({
      ...loginRequest,
      scopes: [process.env.REACT_APP_API_SCOPE],
      account: accountsValue ?  accountsValue : toJS(UserStore.AccountInfoValue),
    });
    UserStore.setToken(response.accessToken);
    if(localStorage.getItem("uuid") === null)  {
      let myuuid = uuidv4();
      localStorage.setItem("uuid",myuuid);
      const options = {
        "UserId": localStorage.getItem("userEmail"),
        "UniqueSessionCode": localStorage.getItem("uuid"),
      }

    // calling api for concurrent session management;
   
     const res =  axios.post(
        APIEndpoint + `/LoginSessionTable`,
        options
      );
     }
  }
  axios.interceptors.request.use(
    (config) => {
      let token = UserStore.accesstoken;
      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );
  
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async function (error) {
      const originalRequest = error.config;
      if (error.response.status === 200) {
        toast.success("successful", { autoClose: 3000 });
      } else if (error.response.status === 400) {
        toast.error(
          "Request error:" + error.response.data.map((row) => row.error),
          {
            // Set to 5sec
            position: toast.POSITION.TOP_LEFT,
            autoClose: 5000,
          }
        );
      } else if (error.response.status === 401) {
        // toast.error("Unauthorized user", {
        //   // Set to 5sec
        //   position: toast.POSITION.BOTTOM_LEFT,
        //   autoClose: 5000,
        // });
  
        try {
          getToken();
          return axios(originalRequest);
        } catch (_error) {
          return Promise.reject(_error);
        }
      } else if (error.response.status === 404) {
        toast.error("Service Not found", {
          // Set to 5sec
          position: toast.POSITION.BOTTOM_LEFT,
          autoClose: 5000,
        });
      } else if (error.response.status === 408) {
        toast.error("Request timeout", {
          // Set to 5sec
          position: toast.POSITION.BOTTOM_LEFT,
          autoClose: 5000,
        });
      } else if (error.response.status === 500) {
        toast.error("Internal Server Error", {
          // Set to 5sec
          position: toast.POSITION.BOTTOM_LEFT,
          autoClose: 5000,
        });
      } else if (error.response.status === 501) {
        toast.error("Not Implemented", {
          // Set to 5sec
          position: toast.POSITION.BOTTOM_LEFT,
          autoClose: 5000,
        });
      } else if (error.response.status === 502) {
        toast.error("Bad Gateway", {
          // Set to 5sec
          position: toast.POSITION.BOTTOM_LEFT,
          autoClose: 5000,
        });
      } else if (error.response.status === 503) {
        toast.error("Service Unavailable", {
          // Set to 5sec
          position: toast.POSITION.BOTTOM_LEFT,
          autoClose: 5000,
        });
      } else {
      }
      return error.response;
    },
    (err) => {}
  );
  async function apigraph() {
    // Silently acquires an access token which is then attached to a request for MS Graph data
    const res = await instance.acquireTokenSilent({
      ...loginRequest,
      account: accountsValue,
    });
    theImage = await profilePic(res.accessToken);
    const profilepic = "picture";
    const userData = "userdata";
    graphData = await callMsGraph(res.accessToken, profilepic);
    graphDataforUsers = await callMsGraph(res.accessToken, userData);
    UserStore.addUser(graphDataforUsers.value);

    const reader = new FileReader();
    const preview = document.querySelector(".navbar-logo");
    if (theImage.ok === true) {
      reader.addEventListener(
        "load",
        function () {
          // convert image file to base64 string
          preview.src = reader.result;
        },
        false
      );

      if (graphData) {
        reader.readAsDataURL(graphData);
      }
    }
  }

  return (
    <div>
    <header className="header">
      <nav className="navbar">
        <Tooltip title="Home Page" arrow>
          <div className="petrofac-logo-wrapper" onClick={navigateToHome}>
            <img src="/images/Petrofac_Logo_RGB.svg" className="petrofac-logo-header" alt=""/>
          </div>
        </Tooltip>
         <div className="navbar-list-item" id="navbarSupportedContent">
            <div className="navbar-nav" style={{ "paddingTop": "-1%" }}>
              <ul className="navbar-nav" style={{ "paddingTop": "-1%" }}>
                <li className="nav-item nav-user">
                  <img className="navbar-logo" alt="user" src='images/profile_icon.svg'/>
                  {accountsValue ? <p className="userName">{accountsValue.name}</p> : ""}
                </li>
                <li className="nav-item logout-wrapper">
                  <div className="nav-link nav-logout" onClick={handleLogout}>
                    <img src="/images/logout.svg" className="logout-icon" alt="Logout"/>
                    <p className="logout-text">Logout</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
      </nav>
    </header>
    <Modal
            className="user-select-popup"
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            dialogClassName="fullmodal"
            size="l"
            centered
          >
            <Modal.Body>
              <label
                style={{
                  font: "normal normal bold 1vw Helvetica",
                  textAlign: "left",
                }}
              >
                {"session will be expired in 60 seconds."}
                <IconButton
                  onClick={handleClose}
                  style={{
                    padding: "3px",
                    position: "absolute",
                    top: "0",
                    right: "0",
                    background: "",
                    borderRadius: "0",
                    color: "#0C629B",
                  }}
                >
                  {" "}
                  <CloseIcon style={{ width: "1.5vw", height: "1.5vw" }} />{" "}
                </IconButton>
              </label>
            </Modal.Body>
          </Modal>

    </div>
  );
}
