import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import AppHeader from "../../AppHeader/AppHeader";
import AppFooter from "../../AppFooter/AppFooter";
import BreadCrumbs from "../../Upload/BreadCrumbs/BreadCrumbs";
import { useReactToPrint } from "react-to-print";
import ReactToPrint from "react-to-print";
import "./OverallMTO.scss";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import FilterMTO from "./FilterMTO";
import UserStore from "../../UserStore";
export default function OverallMTO() {
  const history = useHistory();
  const componentRef = useRef();
  const jsonData = JSON.parse(localStorage.getItem("MTOData"));
  let rows = [];
  const [newRows, setNewRows] = useState([]);
  const [filterRows, setFilterRows] = useState([]);
  const [assetsData, setAssetsData] = useState(Object.entries(jsonData));

  let extra = 0;
  
  const fileName = "MTO";
  useEffect(() => {
    const length = assetsData.length;
    let key = 1;
    rows = [];
    if (rows.length === 0) {
      let assetData = [];
      extra = extra + 1;
      for (let index = 0; index < length; index++) {
        assetData = assetsData[index][1];
        let assetss = assetData.symbols;
        if (assetData.hasOwnProperty("Symbols")) {
          let symbols = Object.entries(assetData.Symbols);
          for (let newIndex = 0; newIndex < symbols.length; newIndex++) {
            let data = {
              Key: key,
              LineNumber: assetData.specification,
              assetId: symbols[newIndex][0],
              id: symbols[newIndex][1].SymbolId,
              shortCode:
                symbols[newIndex][1].ShortCode === null ||
                symbols[newIndex][1].ShortCode === undefined
                  ? ""
                  : symbols[newIndex][1].ShortCode,
              assetCompType:
                symbols[newIndex][1].assetComponentType === null ||
                symbols[newIndex][1].assetComponentType === undefined
                  ? ""
                  : symbols[newIndex][1].assetComponentType.toUpperCase(),
              Seq:
                symbols[newIndex][1].SequenceNumber === null ||
                symbols[newIndex][1].SequenceNumber === undefined
                  ? ""
                  : symbols[newIndex][1].SequenceNumber,
              Spec:
                symbols[newIndex][1].Spec === null ||
                symbols[newIndex][1].Spec === undefined
                  ? ""
                  : symbols[newIndex][1].Spec,
              isJacketed:
                symbols[newIndex][1].IsJacketedLine !== true ? "N" : "Y",
              hdr:
                symbols[newIndex][1].HdrSize === null ||
                symbols[newIndex][1].HdrSize === undefined
                  ? ""
                  : symbols[newIndex][1].HdrSize.split(/[" ']+/)[0],
              size1:
                symbols[newIndex][1].Size1 === null ||
                symbols[newIndex][1].Size1 === "" ||
                symbols[newIndex][1].Size1 === undefined
                  ? ""
                  : (symbols[newIndex][1].Size1),
              size2:
                symbols[newIndex][1].Size2 === null ||
                symbols[newIndex][1].Size2 === "" ||
                symbols[newIndex][1].Size2 === undefined
                  ? ""
                  : (symbols[newIndex][1].Size2),
              quantity:
                symbols[newIndex][1].Quantity === null ||
                symbols[newIndex][1].Quantity === undefined
                  ? ""
                  : symbols[newIndex][1].Quantity,
              insulationType:
                symbols[newIndex][1].InsulationType === null ||
                symbols[newIndex][1].InsulationType === undefined
                  ? ""
                  : symbols[newIndex][1].InsulationType,
              ugag:
                symbols[newIndex][1].AgUg === null ||
                symbols[newIndex][1].AgUg === undefined
                  ? ""
                  : symbols[newIndex][1].AgUg,
              fluid:
                symbols[newIndex][1].Fluid === null ||
                symbols[newIndex][1].Fluid === undefined
                  ? ""
                  : symbols[newIndex][1].Fluid,
              seat:
                symbols[newIndex][1].Seat === null ||
                symbols[newIndex][1].Seat === undefined
                  ? ""
                  : symbols[newIndex][1].Seat,
              valveBody:
                symbols[newIndex][1].ValveBody === null ||
                symbols[newIndex][1].ValveBody === undefined
                  ? ""
                  : symbols[newIndex][1].ValveBody,
              extendedBonet:
                symbols[newIndex][1].ExtendedBonet === null ||
                symbols[newIndex][1].ExtendedBonet === undefined
                  ? ""
                  : symbols[newIndex][1].ExtendedBonet,
              limitSwich:
                symbols[newIndex][1].LimitSwich === null ||
                symbols[newIndex][1].LimitSwich === undefined
                  ? ""
                  : symbols[newIndex][1].LimitSwich,
              hold:
                symbols[newIndex][1].Hold === null ||
                symbols[newIndex][1].Hold === undefined
                  ? ""
                  : symbols[newIndex][1].Hold === true
                  ? "Y"
                  : "N",
              fromLine:
                symbols[newIndex][1].FromLine === null ||
                symbols[newIndex][1].FromLine === undefined
                  ? ""
                  : symbols[newIndex][1].FromLine,
              toLine:
                symbols[newIndex][1].ToLine === null ||
                symbols[newIndex][1].ToLine === undefined
                  ? ""
                  : symbols[newIndex][1].ToLine,
              remarks:
                symbols[newIndex][1].Remarks === null ||
                symbols[newIndex][1].Remarks === undefined
                  ? ""
                  : symbols[newIndex][1].Remarks,
              itemOrder:
                symbols[newIndex][1].ItemOrder === null ||
                symbols[newIndex][1].ItemOrder === undefined
                  ? ""
                  : symbols[newIndex][1].ItemOrder,
              rating:
                symbols[newIndex][1].Rating === null ||
                symbols[newIndex][1].Rating === undefined
                  ? ""
                  : symbols[newIndex][1].Rating,
              pid: localStorage.getItem("pidnumber"),
              supply: "",
              SymbolRank: symbols[newIndex][1].SymbolRank !== undefined ? symbols[newIndex][1].SymbolRank : 0,
              // CorrectedRow: symbols[newIndex][1].CorrectedRow,
            };
            if(data.SymbolRank === 0 ) {
              rows.push(data);
            }
            key++;
          }
        }
      }
      setNewRows(rows);
      setFilterRows(rows);
    }
  }, [assetsData]);
  const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";


  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    copyStyles: false,
  });


 

  const changeDataFormat = (mtoResult) => {
    let k = [];
    mtoResult.map((data) => {
      let mtoData = {
        "LINE NUMBER": data.LineNumber === "UNKNOWN" ? "Not Detected" :data.LineNumber ,
        SEQ: data.Seq.length === 0 || data.Seq === "NaN" ? "Not Detected" : data.Seq,
        PID: data.pid.length === 0 ? "Not Detected" : data.pid,
        SPEC: data.Spec.length === 0 ? "Not Detected" : data.Spec,
        "SHORT CODE": data.shortCode.length === 0 ? "Not Detected" : data.shortCode,
        "IS JACKETED LINE": data.isJacketed.length === 0 ? "Not Detected" : data.isJacketed,
        "HDR SIZE":
          data.hdr === null ||
          data.hdr === undefined ||
          data.hdr === ""
            ? "Not Detected"
            : 
            data.hdr.split(/[" ']+/)[0],
        "SIZE-1":
          data.size1 === null || data.size1 === undefined || data.size1 === ""
            ? "Not Detected"
            : 
            (data.size1),
        "SIZE-2":
          (data.shortCode === "T" || data.shortCode === "RX") && (data.size2 === null || data.size2 === undefined || data.size2 === "")
            ? "Not Detected"
            : (data.size2 === null || data.size2 === undefined || data.size2 === "") ? "" : (data.size2) ,
        QTY: data.quantity,
        "INSUL TYPE": data.insulationType.length === 0 ? "Not Detected" : data.insulationType,
        "AG/UG": data.ugag.length === 0 ? "Not Detected" : data.ugag,
        FLUID: data.fluid.length === 0 ? "Not Detected" : data.fluid,
        PIPINGCOMPTYPE:
          data.assetCompType === null ||
          data.assetCompType === undefined ||
          data.assetCompType === ""
            ? "Not Detected"
            : data.assetCompType.toUpperCase(),
        SEAT: data.seat.length === 0 ? "Not Detected" : data.seat,
        VALVEBODY: data.valveBody.length === 0 ? "Not Detected" : data.valveBody,
        "EXTENDED BONNET": data.extendedBonet.length === 0 ? "Not Detected" : data.extendedBonet,
        "LIMIT SWITCH": data.limitSwich.length === 0 ? "Not Detected" : data.limitSwich,
        HOLD: data.hold.length === 0 ? "Not Detected" : data.hold,
        SUPPLY: data.supply,
        FROM: data.fromLine.length === 0 ? "Not Detected" : data.fromLine,
        TO: data.toLine.length === 0 ? "Not Detected" : data.toLine,
        "ITEM ORDER": data.itemOrder,
        REMARKS: (data.shortCode === "I" || data.shortCode === "SC" || data.shortCode === "SI" || data.assetCompType === "Control Valve") && data.remarks.length === 0 ? "Not Detected" : data.remarks,
        RATING: data.rating.length === 0 ? "Not Detected" : data.rating,
      };
      k.push(mtoData);
    });
    return k;
  };

  const exportToCSV = async (fileName) => {
    const mtoResult = changeDataFormat(newRows);
      const ws = XLSX.utils.json_to_sheet(mtoResult);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
  };

  const navigateBack = () => {
    localStorage.setItem("prevRoute", "overallMTO");
    // UserStore.setAccuracyPID(["low"])
    history.goBack();
  }

  return (
    <div className="overallMTO-container">
      <AppHeader />
      <BreadCrumbs />
      <div className="overallMTO-wrapper">
        <div className="toolbar">
          <div className="back-btn-pid" onClick={navigateBack}>
            <img src="/images/back.svg" className="back-icon" />
            <span className="toolbar-text">Back</span>
          </div>
          <div className="download-MTO" >
        <img src="/images/icon_download_blue.svg" className="download-icon" />
        <span className="toolbar-text" onClick={(e) => exportToCSV(fileName)}>Download MTO Report</span>
      </div>
          <ReactToPrint
            trigger={() => (
              <div className="print-MTO" onClick={handlePrint}>
                <img src="/images/print.svg" className="print-mto-icon" />
                <span className="toolbar-text">Print PID MTO</span>
              </div>
            )}
            content={() => componentRef.current}
          />
        </div>
      
        <div className="MTO-table-wrapper" ref={componentRef}>
        
          
        {newRows.length > 0 ?
          <FilterMTO rowsData={newRows} />
           : '' }
        </div>
       
      </div>
      <AppFooter />
    </div>
  );
}
