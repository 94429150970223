import React, { Component } from 'react';
import BreadCrumbsStamp from '../../Upload/BreadCrumbs/BreadCrumbs';
import AppHeader from '../../AppHeader/AppHeader';
import StampUpload from '../StampUpload/StampUpload';

export default function UploadFiles() {
  return (
    <div className="upload-stamp-main-container">
      <AppHeader />
      <BreadCrumbsStamp />
      <div>
        <StampUpload />
      </div>
    </div>
  );
}





