import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import Button from "@material-ui/core/Button";
import Modal from "react-bootstrap/Modal";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import Cancel from "@material-ui/icons/Cancel";
import CircleChecked from "@material-ui/icons/CheckCircleRounded";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import DialogTitle from "@material-ui/core/DialogTitle";
import LoadingOverlay from "react-loading-overlay";
import axios from "axios";
import AppHeader from "../../../components/AppHeader/AppHeader";
import Tooltip from "@material-ui/core/Tooltip";
import AppFooter from "../../../components/AppFooter/AppFooter";
import BreadCrumbs from "../../Upload/BreadCrumbs/BreadCrumbs";
import UserForm from "../CreatenewWorkspace/UserForm";
import SelectEditChange from "./SelectEditChange";
import SelectChange from "./SelectChange";
import "./DataGrid.scss";
import UserStore from "../../UserStore";
import { toJS } from "mobx";
import TextFieldMui from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
// Importing toastify module
import { toast } from "react-toastify";

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";
import "./RegularExp.scss";
import Item from "antd/lib/list/Item";
import { IdTokenEntity } from "@azure/msal-common";
import Papa from "papaparse";
import { TextareaAutosize } from "@material-ui/core";

// Allowed extensions for input file
const allowedExtensions = ["csv"];
// toast-configuration method,
// it is compulsory method.
toast.configure();
const API_Endpoint = process.env.REACT_APP_API_ENDPOINT;

const styles = () => ({
  label: {
    "&$erroredLabel": {
      color: "black",
    },
  },
  erroredLabel: {},
});

const TextField = withStyles(styles)(function TextField({ classes, ...props }) {
  return (
    <TextFieldMui
      InputLabelProps={{
        classes: {
          root: classes.label,
          error: classes.erroredLabel,
        },
      }}
      {...props}
    />
  );
});

const isTickBased = toJS(UserStore.editRow).length
  ? toJS(UserStore.editRow)[0].isIsometricTickBased
  : toJS(UserStore.isTickBasedCreateWS);

export class RegularExp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
      loading:false,
      result: {},
      myrow: [],
      listofcodes: [],
      myrowedit: [],
      delete: false,
      setShow: false,
      setShow1: false,
      uploadShow: false,
      uploadShow1: false,
      showmessage: "",
      deleteid: "",
      showForm: false,
      exclude: false,
      include: true,
      globalCode: false,
      projectCode: true,
      files: [],
      droppedFiles: [],
      globalData: [],
      showfiletypedailog: false,
      weightageMessage: false,
      editWeightageMessage: false,
      submitted: false,
      userFlag: true,
      editProducts: [],
      editLineGroups: [],
      phraseMessage: "",
      phraseError: false,
      modalId: "",
      columns: [
        { dataField: "key", text: "Key" },
        { dataField: "expression", text: "Expression" },
        { dataField: "weightage", text: "Weightage" },
        { dataField: "type", text: "Type" },
        { dataField: "More", text: "More" },
      ],
      columns1: [
        { dataField: "listName", text: "List Name" },
        { dataField: "listScope", text: "Scope" },
        { dataField: "listValue", text: "Value" },
        { dataField: "More", text: "More" },
      ],
      newrow: { key: "", expression: "", weightage: "", type: "" },
      fields: [],
      products: [],
      inclusionArray: [],
      exclusionArray: [],
      listOfCodesforAPI: [],
      lineGroups: [],
      listName: "",
      listValue: "",
      validFileType: false,
      Duplicatekey: false,
      saveRegexValidation: false,
      saveListValidation: false,
      uploadedCSVList: [],
      reportDataexcel: [],
      isEditWorkspaceFlag: localStorage.getItem("Editworkspace")
        ? JSON.parse(localStorage.getItem("Editworkspace"))
        : UserStore.editworkspace,
      newWorkspaceId:
        UserStore.newWorkspaceId !== 0
          ? UserStore.newWorkspaceId
          : localStorage.getItem("newWorkspaceId")
          ? JSON.parse(localStorage.getItem("newWorkspaceId"))
          : 0,
      editworkspace: localStorage.getItem("Editworkspace")
        ? JSON.parse(localStorage.getItem("Editworkspace"))
        : UserStore.editworkspace,
      globaldataCount: 0,
    };
    if (!toJS(UserStore.editRow).length && localStorage.getItem("EditWSData")) {
      UserStore.addRowdata(JSON.parse(localStorage.getItem("EditWSData")));
    }
    if (
      UserStore.NewworkspaceName === "" &&
      localStorage.getItem("SetNewworkspaceName")
    ) {
      UserStore.SetNewworkspaceName(
        localStorage.getItem("SetNewworkspaceName")
      );
    }
    if (
      UserStore.NewProjectName === "" &&
      localStorage.getItem("SetNewProjectName")
    ) {
      UserStore.SetNewProjectName(localStorage.getItem("SetNewProjectName"));
    }
  }

  async componentDidMount() {
    // this.fetchIsometricFields();
    if (this.state.newWorkspaceId == 0 && this.state.editworkspace == false) {
      axios.get(API_Endpoint + `/GetFieldRuleGlobalKeyValues`).then((res) => {
        if (res.data) {
          const GlobalData = res.data.map((items, index) => ({
            listName: `SRCODES_${items.key}`,
            listScope: "Global",
            listValue: items.value,
            More: (
              <div
                classname="more-actions-wrapper"
                style={{ display: "inline-flex" }}
              >
                <Tooltip title="Edit List Of Codes" arrow>
                  <div
                    classname="edit-icon"
                    onClick={
                      this.state.userFlag ? this.handleListofCodesEdit : ""
                    }
                    style={
                      this.state.editworkspace
                        ? {
                            marginLeft: "-40%",
                            cursor: "not-allowed",
                            opacity: "0.5",
                            pointerEvents: "none",
                          }
                        : {
                            marginLeft: "-40%",
                          }
                    }
                  >
                    <img id={index + 1} src="/images/icon_edit.svg" />
                  </div>
                </Tooltip>
                <Tooltip title="Delete List Of Codes" arrow>
                  <div
                    classname="delete-icon"
                    style={
                      this.state.editworkspace
                        ? {
                            marginLeft: "70%",
                            cursor: "not-allowed",
                            opacity: "0.5",
                            pointerEvents: "none",
                          }
                        : {
                            marginLeft: "70%",
                          }
                    }
                    onClick={
                      this.state.userFlag
                        ? (e) => {
                            this.setState({
                              deleteLOC: true,
                              deleteidLOC: index + 1,
                            });
                          }
                        : ""
                    }
                  >
                    <img id={index + 1} src="/images/icon_delete.svg" />
                  </div>
                </Tooltip>
              </div>
            ),
          }));

          let globaleCodesforAPI = res.data.map((items, index) => ({
            key: items.key,
            value: items.value,
            isGlobal: true,
          }));

          this.setState({
            lineGroups: GlobalData,
            globaldataCount: GlobalData.length,
            globalData: res.data,
            listOfCodesforAPI: globaleCodesforAPI,
          });
        }
      });
    } else {
      let fieldRules = [];
      axios
        .get(
          API_Endpoint +
            `/GetIsometricFieldRules?workSpaceId=${
              UserStore.newWorkspaceId
                ? UserStore.newWorkspaceId
                : toJS(UserStore.editRow[0].workSpaceId)
            }`
        )
        .then((res) => {
          const rows1 = res.data;
          fieldRules = [
            ...rows1.isometricFieldInclusionRules,
            ...rows1.isometricFieldExclusionRules,
          ];
          if (fieldRules) {
            fieldRules.map((items, index) => {
              const row2 = {
                key: this.getValueByKey(items.fieldId),
                type:
                  rows1.isometricFieldInclusionRules.length <= index
                    ? "Exclusion"
                    : "Inclusion",
                expression: items.regularExpression,
                weightage: items.weightage,
                More: (
                  <div
                    classname="more-actions-wrapper"
                    style={{ display: "inline-flex" }}
                  >
                    <Tooltip title="Edit Regular Expression" arrow>
                      <div
                        classname="edit-icon"
                        onClick={this.state.userFlag ? this.handleEdit : ""}
                        style={
                          this.state.isEditWorkspaceFlag
                            ? {
                                marginLeft: "-40%",
                                cursor: "not-allowed",
                                opacity: "0.5",
                                pointerEvents: "none",
                              }
                            : { marginLeft: "-40%", cursor: "pointer" }
                        }
                      >
                        <img id={index + 1} src="/images/icon_edit.svg" />
                      </div>
                    </Tooltip>
                    <Tooltip title="Delete Regular Expression" arrow>
                      <div
                        classname="delete-icon"
                        style={
                          this.state.isEditWorkspaceFlag
                            ? {
                                marginLeft: "70%",
                                cursor: "not-allowed",
                                opacity: "0.5",
                                pointerEvents: "none",
                              }
                            : { marginLeft: "70%", cursor: "pointer" }
                        }
                        onClick={
                          this.state.userFlag
                            ? (e) => {
                                this.setState({
                                  delete: true,
                                  deleteid: index + 1,
                                });
                              }
                            : ""
                        }
                      >
                        <img id={index + 1} src="/images/icon_delete.svg" />
                      </div>
                    </Tooltip>
                  </div>
                ),
              };
              if (row2.type === "Exclusion") {
                this.setState({ exclude: true });
              } else {
                this.setState({ exclude: false });
              }
              this.state.myrow.push(row2);
            });

            rows1.fileldKeyValues.map((items, index) => {
              const row4 = {
                listName: `SRCODES_${items.key}`,
                listScope: items.isGlobal == true ? "Global" : "Project",
                listValue: items.value,
                More: (
                  <div
                    classname="more-actions-wrapper"
                    style={{ display: "inline-flex" }}
                  >
                    <Tooltip title="Edit List Of Codes" arrow>
                      <div
                        classname="edit-icon"
                        onClick={
                          this.state.userFlag ? this.handleListofCodesEdit : ""
                        }
                        style={
                          this.state.isEditWorkspaceFlag
                            ? {
                                marginLeft: "-40%",
                                cursor: "not-allowed",
                                opacity: "0.5",
                                pointerEvents: "none",
                              }
                            : { marginLeft: "-40%", cursor: "pointer" }
                        }
                      >
                        <img id={index + 1} src="/images/icon_edit.svg" />
                      </div>
                    </Tooltip>
                    <Tooltip title="Delete List Of Codes" arrow>
                      <div
                        classname="delete-icon"
                        style={
                          this.state.isEditWorkspaceFlag
                            ? {
                                marginLeft: "70%",
                                cursor: "not-allowed",
                                opacity: "0.5",
                                pointerEvents: "none",
                              }
                            : { marginLeft: "70%", cursor: "pointer" }
                        }
                        onClick={
                          this.state.userFlag
                            ? (e) => {
                                this.setState({
                                  deleteLOC: true,
                                  deleteidLOC: index + 1,
                                });
                              }
                            : ""
                        }
                      >
                        <img id={index + 1} src="/images/icon_delete.svg" />
                      </div>
                    </Tooltip>
                  </div>
                ),
              };
              this.state.listofcodes.push(row4);
            });
            this.setState({
              lineGroups: this.state.listofcodes,
            });
          }

          this.setState({
            products: this.state.myrow,
          });
        });
    }
  }

  handleEdit = (e) => {
    const id = parseInt(e.target.id) - 1;
    UserStore.setRegExpEditID(id);
    let data = this.state.products[id];
    let editData;
    if (data.type === "Inclusion") {
      editData = {
        expression: data.expression,
        key: data.key,
        type: data.type,
        weightage: data.weightage,
      };
    } else {
      editData = {
        expression: data.expression,
        key: data.key,
        type: data.type,
      };
    }

    this.state.editProducts = editData;
    this.setState({
      setShow: true,
      modalId: id,
      newrow: { key: data.key, expression: "", weightage: "", type: "" },
    });
  };

  handleListofCodesEdit = (e) => {
    let id = parseInt(e.target.id) - 1;
    UserStore.setListOfCodesEditID(id);
    let data = this.state.lineGroups[id];
    let editData;
    editData = {
      listName: data.listName.split("SRCODES_")[1],
      listValue: data.listValue,
      listScope: data.listScope,
    };
    this.state.editLineGroups = editData;

    this.setState({
      uploadShow1: true,
      validFileType: false,
      Duplicatekey: false,
      modalId: id,
    });
  };

  handleDropdownchangerowEdit = (e) => {
    this.setState({
      newrow: {
        key: e.target.value,
      },
      outcomeError: false,
      outcomeErrorMessage: "",
    });
  };

  handleDropdownchangerow = (e) => {
    this.setState(() => ({
      outcomeError: false,
      outcomeErrorMessage: "",
      newrow: {
        key: e.target.value,
      },
    }));
  };

  addRow1 = (e) => {
    this.setState({
      setShow1: true,
      exclude: false,
      newrow: "",
      saveRegexValidation: false,
    });
    UserStore.setIsExclusionRule(false);
  };

  addRow2 = () => {
    this.setState({
      uploadShow: true,
      saveListValidation: false,
      listName: "",
      listValue: "",
      validFileType: false,
      Duplicatekey: false,
    });
    UserStore.setIsGlobalListofCodes(true);
    if (this.state.uploadedCSVList.length != 0) {
      this.state.uploadedCSVList = [];
    }
  };

  getKeyByValue = (field) => {
    for (var k in toJS(UserStore.regExpFields)) {
      if (toJS(UserStore.regExpFields)[k].fieldName === field) {
        return toJS(UserStore.regExpFields)[k].fieldId;
      }
    }
  };

  getValueByKey = (field) => {
    for (var k in toJS(UserStore.regExpFields)) {
      if (toJS(UserStore.regExpFields)[k].fieldId === field) {
        return toJS(UserStore.regExpFields)[k].fieldName;
      }
    }
  };

  dataforAPI = (getData) => {
    this.state.inclusionArray = [];
    this.state.exclusionArray = [];
    getData.map((item) => {
      if (item.type == "Inclusion") {
        this.state.inclusionArray.push({
          fieldId: this.getKeyByValue(item.key),
          regularExpression: item.expression,
          weightage: Number(item.weightage).toFixed(1),
        });
      } else {
        this.state.exclusionArray.push({
          fieldId: this.getKeyByValue(item.key),
          regularExpression: item.expression,
        });
      }
    });
  };

  rowSave = () => {
    let savedData = {
      type: "",
      key: "",
      regId: "",
      expression: "",
      weightage: "",
      More: (
        <div
          classname="more-actions-wrapper"
          style={{ display: "inline-flex" }}
        >
          <Tooltip title="Edit Regular Expression" arrow>
            <div
              classname="edit-icon"
              onClick={this.state.userFlag ? this.handleEdit : ""}
              style={{ marginLeft: "-40%", cursor: "pointer" }}
            >
              <img
                id={this.state.products.length + 1}
                src="/images/icon_edit.svg"
              />
            </div>
          </Tooltip>
          <Tooltip title="Delete Regular Expression" arrow>
            <div
              classname="delete-icon"
              style={{ marginLeft: "70%", cursor: "pointer" }}
              onClick={
                this.state.userFlag
                  ? (e) => {
                      this.setState({
                        delete: true,
                        deleteid: e.target.id,
                      });
                    }
                  : ""
              }
            >
              <img
                id={this.state.products.length + 1}
                src="/images/icon_delete.svg"
              />
            </div>
          </Tooltip>
        </div>
      ),
    };

    if (toJS(UserStore.isExclusionRule) == true) {
      savedData.type = "Exclusion";
    } else {
      savedData.type = "Inclusion";
    }

    savedData.regId = this.state.products.length + 1;
    savedData.key = this.state.newrow.key;
    const exp = document.getElementById("Expression").value;
    savedData.expression = exp;

    let weigh;

    if (toJS(UserStore.isExclusionRule) == false) {
      weigh = document.getElementById("Weightage").value;
      savedData.weightage = weigh;
    }

    if (
      (!toJS(UserStore.isExclusionRule) &&
        document.getElementById("Expression").value === "") ||
      this.state.newrow.key == undefined ||
      weigh === ""
    ) {
      this.handleSaveRegexValidation();
    } else if (
      (toJS(UserStore.isExclusionRule) &&
        document.getElementById("Expression").value === "") ||
      this.state.newrow.key == undefined
    ) {
      this.handleSaveRegexValidation();
    } else {
      if (this.state.editworkspace === false) {
        this.state.products.push(savedData);
        this.setState({
          setShow1: false,
        });

        this.dataforAPI(this.state.products);
      } else {
        if (toJS(UserStore.isExclusionRule) == true) {
          savedData.type = "Exclusion";
        } else {
          savedData.type = "Inclusion";
        }

        savedData.regId = this.state.products.length + 1;
        savedData.key = this.state.newrow.key;
        const exp = document.getElementById("Expression").value;
        savedData.expression = exp;

        if (toJS(UserStore.isExclusionRule) == false) {
          const weigh = document.getElementById("Weightage").value;
          savedData.weightage = weigh;
        }

        this.setState({ products: [...this.state.products, savedData] });

        this.setState({
          setShow1: false,
        });
      }
    }
  };

  handleSaveRegexValidation = () => {
    this.setState({ saveRegexValidation: true });
  };

  handleSaveListValidation = () => {
    this.setState({ saveListValidation: true });
  };

  saveListofCodes = () => {
    if (this.state.uploadedCSVList.length != 0) {
      const uploadeValue = this.state.uploadedCSVList;
      const mynewrows = this.state.listOfCodesforAPI.filter(function (o1) {
        return uploadeValue.some(function (o2) {
          return o1.key.toLowerCase() == o2.Key.toLowerCase(); // id is unnique both array object
        });
      });

      if (!mynewrows.length) {
        this.setState({
          Duplicatekey: false,
        });
        this.state.uploadedCSVList.map((u, index) => {
          let uploadListOfCodesData = {
            listScope: "",
            listName: `SRCODES_${u.Key}`,
            listId: "",
            listValue: u.Value,
            More: (
              <div
                classname="more-actions-wrapper"
                style={{ display: "inline-flex" }}
              >
                <Tooltip title="Edit List of Codes" arrow>
                  <div
                    classname="edit-icon"
                    onClick={
                      this.state.userFlag ? this.handleListofCodesEdit : ""
                    }
                    style={{ marginLeft: "-40%", cursor: "pointer" }}
                  >
                    <img
                      id={this.state.lineGroups.length + 1}
                      src="/images/icon_edit.svg"
                    />
                  </div>
                </Tooltip>
                <Tooltip title="Delete List of Codes" arrow>
                  <div
                    classname="delete-icon"
                    style={{ marginLeft: "70%", cursor: "pointer" }}
                    onClick={
                      this.state.userFlag
                        ? (e) => {
                            this.setState({
                              deleteLOC: true,
                              deleteidLOC: e.target.id,
                            });
                          }
                        : ""
                    }
                  >
                    <img
                      id={this.state.lineGroups.length + 1}
                      src="/images/icon_delete.svg"
                    />
                  </div>
                </Tooltip>
              </div>
            ),
          };

          if (toJS(UserStore.isGlobalListofCodes) == true) {
            uploadListOfCodesData.listScope = "Global";
          } else {
            uploadListOfCodesData.listScope = "Project";
          }

          if (this.state.editworkspace === false) {
            this.state.lineGroups.push(uploadListOfCodesData);
            this.setState({
              uploadShow: false,
            });

            let k = {
              key: uploadListOfCodesData.listName.split("SRCODES_")[1],
              value: uploadListOfCodesData.listValue,
              isGlobal: toJS(UserStore.isGlobalListofCodes),
            };

            this.state.listOfCodesforAPI.push(k);
          }
        });
      } else {
        this.setState({
          Duplicatekey: true,
        });
      }
    } else {
      let duplicateFromProject;
      let duplicateFromNewList;
      let savedListOfCodesData = {
        listScope: "",
        listName: "",
        listId: "",
        listValue: "",
        More: (
          <div
            classname="more-actions-wrapper"
            style={{ display: "inline-flex" }}
          >
            <Tooltip title="Edit List of Codes" arrow>
              <div
                classname="edit-icon"
                onClick={this.state.userFlag ? this.handleListofCodesEdit : ""}
                style={{ marginLeft: "-40%", cursor: "pointer" }}
              >
                <img
                  id={this.state.lineGroups.length + 1}
                  src="/images/icon_edit.svg"
                />
              </div>
            </Tooltip>
            <Tooltip title="Delete List of Codes" arrow>
              <div
                classname="delete-icon"
                style={{ marginLeft: "70%", cursor: "pointer" }}
                onClick={
                  this.state.userFlag
                    ? (e) => {
                        this.setState({
                          deleteLOC: true,
                          deleteidLOC: e.target.id,
                        });
                      }
                    : ""
                }
              >
                <img
                  id={this.state.lineGroups.length + 1}
                  src="/images/icon_delete.svg"
                />
              </div>
            </Tooltip>
          </div>
        ),
      };
      if (toJS(UserStore.isGlobalListofCodes) == true) {
        savedListOfCodesData.listScope = "Global";
      } else {
        savedListOfCodesData.listScope = "Project";
      }

      if (
        document.getElementById("listName").value === "" ||
        document.getElementById("listValue").value === ""
      ) {
        this.handleSaveListValidation();
      } else {
        this.setState({
          saveListValidation: false,
        });
        const newGlobalList = this.state.listOfCodesforAPI.filter((item) => {
          return item.isGlobal === true;
        });

        duplicateFromNewList = newGlobalList.find(
          (o) =>
            o.key.toLowerCase() ===
            document.getElementById("listName").value.toLowerCase()
        );

        const newProjectlList = this.state.listOfCodesforAPI.filter((item) => {
          return item.isGlobal === false;
        });
        duplicateFromProject = newProjectlList.find(
          (o) =>
            o.key.toLowerCase() ===
            document.getElementById("listName").value.toLowerCase()
        );

        if (!duplicateFromProject && !duplicateFromNewList) {
          savedListOfCodesData.listId = this.state.lineGroups.length + 1;
          const listName = `SRCODES_${
            document.getElementById("listName").value
          }`;
          savedListOfCodesData.listName = listName;
          const listValue = document.getElementById("listValue").value;
          savedListOfCodesData.listValue = listValue;
          if (this.state.editworkspace === false) {
            this.state.lineGroups.push(savedListOfCodesData);
            this.setState({
              uploadShow: false,
              Duplicatekey: false,
            });

            let k = {
              key: savedListOfCodesData.listName.split("SRCODES_")[1],
              value: savedListOfCodesData.listValue,
              isGlobal: toJS(UserStore.isGlobalListofCodes),
            };

            this.state.listOfCodesforAPI.push(k);
          } else {
            if (toJS(UserStore.isGlobalListofCodes) == true) {
              savedListOfCodesData.listScope = "Global";
            } else {
              savedListOfCodesData.listScope = "Project";
            }

            savedListOfCodesData.listId = this.state.lineGroups.length + 1;
            const listName = `SRCODES_${
              document.getElementById("listName").value
            }`;
            savedListOfCodesData.listName = listName;
            const listValue = document.getElementById("listValue").value;
            savedListOfCodesData.listValue = listValue;

            this.setState({
              lineGroups: [...this.state.lineGroups, savedListOfCodesData],
            });
            this.setState({
              uploadShow: false,
              Duplicatekey: false,
            });

            let k = {
              key: savedListOfCodesData.listName.split("SRCODES_")[1],
              value: savedListOfCodesData.listValue,
              isGlobal: toJS(UserStore.isGlobalListofCodes),
            };

            this.state.listOfCodesforAPI.push(k);
          }
        } else {
          this.setState({
            Duplicatekey: true,
          });
        }
      }
    }
  };

  handleDelete = (e) => {
    let id = e.target.id;
  };

  handleClose = () => {
    this.setState({
      setShow: false,
      uploadShow: false,
      uploadShow1: false,
      showfiletypedailog: false,
    });
  };

  handleCloserow = () => {
    this.setState({
      setShow: false,
      setShow1: false,
      uploadShow: false,
      uploadShow1: false,
      exclude: false,
      newrow: {},
      listName: "",
      listValue: "",
    });
  };

  handleEditSave = () => {
    if (this.state.editworkspace === false) {
      let editProducts = [...this.state.products];
      editProducts[toJS(UserStore.regExpEditID)] = "";
      if (toJS(UserStore.isExclusionRule) == true) {
        editProducts[toJS(UserStore.regExpEditID)] = {
          ...editProducts[toJS(UserStore.regExpEditID)],
          type: "Exclusion",
        };
      } else {
        editProducts[toJS(UserStore.regExpEditID)] = {
          ...editProducts[toJS(UserStore.regExpEditID)],
          type: "Inclusion",
        };
      }
      editProducts[toJS(UserStore.regExpEditID)] = {
        ...editProducts[toJS(UserStore.regExpEditID)],
        regId: toJS(UserStore.regExpEditID),
      };
      editProducts[toJS(UserStore.regExpEditID)] = {
        ...editProducts[toJS(UserStore.regExpEditID)],
        key: this.state.newrow.key,
      };
      editProducts[toJS(UserStore.regExpEditID)] = {
        ...editProducts[toJS(UserStore.regExpEditID)],
        expression: document.getElementById("editExpression").value,
      };
      if (toJS(UserStore.isExclusionRule) == false) {
        editProducts[toJS(UserStore.regExpEditID)] = {
          ...editProducts[toJS(UserStore.regExpEditID)],
          weightage: document.getElementById("editWeightage").value,
        };
      }
      editProducts[toJS(UserStore.regExpEditID)] = {
        ...editProducts[toJS(UserStore.regExpEditID)],
        More: (
          <div
            classname="more-actions-wrapper"
            style={{ display: "inline-flex" }}
          >
            <Tooltip title="Edit Regular Expression" arrow>
              <div
                classname="edit-icon"
                onClick={this.state.userFlag ? this.handleEdit : ""}
                style={{ marginLeft: "-40%", cursor: "pointer" }}
              >
                <img
                  id={toJS(UserStore.regExpEditID) + 1}
                  src="/images/icon_edit.svg"
                />
              </div>
            </Tooltip>
            <Tooltip title="Delete Regular Expression" arrow>
              <div
                classname="delete-icon"
                style={{ marginLeft: "70%", cursor: "pointer" }}
                onClick={
                  this.state.userFlag
                    ? (e) => {
                        this.setState({
                          delete: true,
                          deleteid: e.target.id,
                        });
                      }
                    : ""
                }
              >
                <img
                  id={toJS(UserStore.regExpEditID) + 1}
                  src="/images/icon_delete.svg"
                />
              </div>
            </Tooltip>
          </div>
        ),
      };
      this.setState({ products: editProducts, setShow: false });

      this.dataforAPI(editProducts);
    } else {
      let editProducts = [...this.state.products];
      editProducts[toJS(UserStore.regExpEditID)] = "";
      if (toJS(UserStore.isExclusionRule) == true) {
        editProducts[toJS(UserStore.regExpEditID)] = {
          ...editProducts[toJS(UserStore.regExpEditID)],
          type: "Exclusion",
        };
      } else {
        editProducts[toJS(UserStore.regExpEditID)] = {
          ...editProducts[toJS(UserStore.regExpEditID)],
          type: "Inclusion",
        };
      }
      editProducts[toJS(UserStore.regExpEditID)] = {
        ...editProducts[toJS(UserStore.regExpEditID)],
        regId: toJS(UserStore.regExpEditID),
      };
      editProducts[toJS(UserStore.regExpEditID)] = {
        ...editProducts[toJS(UserStore.regExpEditID)],
        key: this.state.newrow.key,
      };
      editProducts[toJS(UserStore.regExpEditID)] = {
        ...editProducts[toJS(UserStore.regExpEditID)],
        expression: document.getElementById("editExpression").value,
      };
      if (toJS(UserStore.isExclusionRule) == false) {
        editProducts[toJS(UserStore.regExpEditID)] = {
          ...editProducts[toJS(UserStore.regExpEditID)],
          weightage: document.getElementById("editWeightage").value,
        };
      }
      editProducts[toJS(UserStore.regExpEditID)] = {
        ...editProducts[toJS(UserStore.regExpEditID)],
        More: (
          <div
            classname="more-actions-wrapper"
            style={{ display: "inline-flex" }}
          >
            <Tooltip title="Edit Regular Expression" arrow>
              <div
                classname="edit-icon"
                onClick={this.state.userFlag ? this.handleEdit : ""}
                style={{ marginLeft: "-40%", cursor: "pointer" }}
              >
                <img
                  id={toJS(UserStore.regExpEditID) + 1}
                  src="/images/icon_edit.svg"
                />
              </div>
            </Tooltip>
            <Tooltip title="Delete Regular Expression" arrow>
              <div
                classname="delete-icon"
                style={{ marginLeft: "70%", cursor: "pointer" }}
                onClick={
                  this.state.userFlag
                    ? (e) => {
                        this.setState({
                          delete: true,
                          deleteid: e.target.id,
                        });
                      }
                    : ""
                }
              >
                <img
                  id={toJS(UserStore.regExpEditID) + 1}
                  src="/images/icon_delete.svg"
                />
              </div>
            </Tooltip>
          </div>
        ),
      };

      this.setState({ products: editProducts, setShow: false });

      this.dataforAPI(editProducts);
    }
  };

  handleEditListOfCodesSave = () => {
    let duplicateFromNewList;
    let duplicateFromProject;
    const listOfvalue = this.state.listOfCodesforAPI.filter((value, index) => {
      return index != toJS(UserStore.listOfCodesEditID);
    });

    const newGlobalList = listOfvalue.filter((item) => {
      return item.isGlobal === true;
    });

    duplicateFromNewList = newGlobalList.find(
      (o) =>
        o.key.toLowerCase() ===
        document.getElementById("listName").value.toLowerCase()
    );

    const newProjectlList = listOfvalue.filter((item) => {
      return item.isGlobal === false;
    });

    duplicateFromProject = newProjectlList.find(
      (o) =>
        o.key.toLowerCase() ===
        document.getElementById("listName").value.toLowerCase()
    );

    if (!duplicateFromProject && !duplicateFromNewList) {
      this.setState({
        Duplicatekey: false,
      });
      let editProducts = [...this.state.lineGroups];
      editProducts[toJS(UserStore.listOfCodesEditID)] = "";

      if (toJS(UserStore.isGlobalListofCodes) == true) {
        editProducts[toJS(UserStore.listOfCodesEditID)] = {
          ...editProducts[toJS(UserStore.listOfCodesEditID)],
          listScope: "Global",
        };
      } else {
        editProducts[toJS(UserStore.listOfCodesEditID)] = {
          ...editProducts[toJS(UserStore.listOfCodesEditID)],
          listScope: "Project",
        };
      }

      editProducts[toJS(UserStore.listOfCodesEditID)] = {
        ...editProducts[toJS(UserStore.listOfCodesEditID)],
        listId: toJS(UserStore.regExpEditID),
      };
      editProducts[toJS(UserStore.listOfCodesEditID)] = {
        ...editProducts[toJS(UserStore.listOfCodesEditID)],
        listName: `SRCODES_${document.getElementById("listName").value}`,
      };
      editProducts[toJS(UserStore.listOfCodesEditID)] = {
        ...editProducts[toJS(UserStore.listOfCodesEditID)],
        listValue: document.getElementById("listValue").value,
      };

      editProducts[toJS(UserStore.listOfCodesEditID)] = {
        ...editProducts[toJS(UserStore.listOfCodesEditID)],
        More: (
          <div
            classname="more-actions-wrapper"
            style={{ display: "inline-flex" }}
          >
            <Tooltip title="Edit List Of Codes" arrow>
              <div
                classname="edit-icon"
                onClick={this.state.userFlag ? this.handleListofCodesEdit : ""}
                style={{ marginLeft: "-40%", cursor: "pointer" }}
              >
                <img
                  id={toJS(UserStore.listOfCodesEditID) + 1}
                  src="/images/icon_edit.svg"
                />
              </div>
            </Tooltip>
            <Tooltip title="Delete Regular Expression" arrow>
              <div
                classname="delete-icon"
                style={{ marginLeft: "70%", cursor: "pointer" }}
                onClick={
                  this.state.userFlag
                    ? (e) => {
                        this.setState({
                          deleteLOC: true,
                          deleteidLOC: toJS(UserStore.listOfCodesEditID) + 1,
                        });
                      }
                    : ""
                }
              >
                <img
                  id={toJS(UserStore.listOfCodesEditID) + 1}
                  src="/images/icon_delete.svg"
                />
              </div>
            </Tooltip>
          </div>
        ),
      };

      this.setState({
        lineGroups: editProducts,
        uploadShow1: false,
      });

      let k = {
        key: editProducts[toJS(UserStore.listOfCodesEditID)].listName.split(
          "SRCODES_"
        )[1],
        value: editProducts[toJS(UserStore.listOfCodesEditID)].listValue,
        isGlobal:
          editProducts[toJS(UserStore.listOfCodesEditID)].listScope ===
          "Project"
            ? false
            : true,
      };

      this.state.listOfCodesforAPI[toJS(UserStore.listOfCodesEditID)] = k;
    } else {
      this.setState({
        Duplicatekey: true,
      });
    }
  };
  // -----------------------End:Function: Save newly edited business rule------------------

  // open the new Create Workspace page
  Openworkspace = () => {
    this.setState({ showForm: true });
  };

  handleCloseDialog = () => {
    this.setState({
      delete: false,
      deleteid: "",
      deleteLOC: false,
      deleteidLOC: "",
    });
  };

  handlePurge = (e) => {
    const rowID = this.state.deleteid - 1;
    const alldata = this.state.products;

    alldata.splice(rowID, 1);
    this.dataforAPI(alldata);
    const changedValue = alldata.map((items, index) => ({
      key: items.key,
      type: items.type,
      expression: items.expression,
      weightage: items.weightage,
      More: (
        <div
          classname="more-actions-wrapper"
          style={{ display: "inline-flex" }}
        >
          <Tooltip title="Edit Regular Expression" arrow>
            <div
              classname="edit-icon"
              onClick={this.state.userFlag ? this.handleEdit : ""}
              style={{ marginLeft: "-40%", cursor: "pointer" }}
            >
              <img id={index + 1} src="/images/icon_edit.svg" />
            </div>
          </Tooltip>
          <Tooltip title="Delete Regular Expression" arrow>
            <div
              classname="delete-icon"
              style={{ marginLeft: "70%", cursor: "pointer" }}
              onClick={
                this.state.userFlag
                  ? (e) => {
                      this.setState({
                        delete: true,
                        deleteid: index + 1,
                      });
                    }
                  : ""
              }
            >
              <img id={index + 1} src="/images/icon_delete.svg" />
            </div>
          </Tooltip>
        </div>
      ),
    }));
    this.setState({
      products: changedValue,
      deleteid: "",
      delete: false,
      deleteidLOC: "",
    });
  };

  handleLOCPurge = (e) => {
    const rowID = this.state.deleteidLOC - 1;
    const alldata = this.state.lineGroups;
    alldata.splice(rowID, 1);
    const changedValue = alldata.map((items, index) => ({
      listName: items.listName,
      listValue: items.listValue,
      listScope: items.listScope,
      More: (
        <div
          classname="more-actions-wrapper"
          style={{ display: "inline-flex" }}
        >
          <Tooltip title="Edit List of Codes" arrow>
            <div
              classname="edit-icon"
              onClick={this.state.userFlag ? this.handleListofCodesEdit : ""}
              style={{ marginLeft: "-40%", cursor: "pointer" }}
            >
              <img id={index + 1} src="/images/icon_edit.svg" />
            </div>
          </Tooltip>
          <Tooltip title="Delete List of Codes" arrow>
            <div
              classname="delete-icon"
              // style={{ marginLeft: "70%", cursor: "pointer" }}
              style={{ marginLeft: "70%", cursor: "pointer" }}
              onClick={
                this.state.userFlag
                  ? (e) => {
                      this.setState({
                        deleteLOC: true,
                        deleteidLOC: index + 1,
                      });
                    }
                  : ""
              }
            >
              <img id={index + 1} src="/images/icon_delete.svg" />
            </div>
          </Tooltip>
        </div>
      ),
    }));
    this.setState({
      lineGroups: changedValue,
      deleteidLOC: "",
      deleteLOC: false,
    });
    
    const listOfCodesforAPIData = this.state.listOfCodesforAPI;
    listOfCodesforAPIData.splice(rowID, 1);
    this.setState({
      listOfCodesforAPI: listOfCodesforAPIData,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const isEditWorkspaceFlag = localStorage.getItem("Editworkspace")
      ? JSON.parse(localStorage.getItem("Editworkspace"))
      : UserStore.editworkspace;

    if (isEditWorkspaceFlag === false) {
      if (
        this.state.lineGroups.length == 0 &&
        this.state.products.length == 0
      ) {
        this.setState({
          phraseError: true,
          phraseMessage:
            "Please add atleast one List of Code and Regular Expression to proceed",
        });
      } else if (this.state.products.length == 0) {
        this.setState({
          phraseError: true,
          phraseMessage: "Please add atleast one Regular Expression to proceed",
        });
      } else if (this.state.lineGroups.length == 0) {
        this.setState({
          phraseError: true,
          phraseMessage: "Please add atleast one List of Code to proceed",
        });
      }

      const datatofeed = Object.values(toJS(UserStore.createWorkspacedata))
        .length
        ? toJS(UserStore.createWorkspacedata)
        : localStorage.getItem("createWorkspacedata")
        ? JSON.parse(localStorage.getItem("createWorkspacedata"))
        : {};
      datatofeed["fieldInclusionRules"] = this.state.inclusionArray;
      datatofeed["fieldExclusionRules"] = this.state.exclusionArray;
      datatofeed["filedKeyValues"] = this.state.listOfCodesforAPI;

      const fieldId1 = [];
      const fieldId2 = [];
      const fieldId3 = [];
      const fieldId4 = [];

      datatofeed["fieldThreshold"].map((items) => {
        if (items.fieldId == 1) {
          fieldId1.push(parseFloat(items.OCRWeightage));
        } else if (items.fieldId == 2) {
          fieldId2.push(parseFloat(items.OCRWeightage));
        } else if (items.fieldId == 3) {
          fieldId3.push(parseFloat(items.OCRWeightage));
        } else if (items.fieldId == 4) {
          fieldId4.push(parseFloat(items.OCRWeightage));
        }
      });

      this.state.inclusionArray.map((items) => {
        if (items.fieldId == 1) {
          fieldId1.push(parseFloat(items.weightage));
        } else if (items.fieldId == 2) {
          fieldId2.push(parseFloat(items.weightage));
        } else if (items.fieldId == 3) {
          fieldId3.push(parseFloat(items.weightage));
        } else if (items.fieldId == 4) {
          fieldId4.push(parseFloat(items.weightage));
        }
      });
      let weightageValidateion = false;
      if (fieldId1.reduce((a, b) => a + b, 0) > 1) {
        weightageValidateion = true;
        this.setState({
          showfiletypedailog: true,
          showmessage:
            "Line Number and OCR sum of the weightage should be less than are equal to 1",
        });
      }
      if (fieldId2.reduce((a, b) => a + b, 0) > 1) {
        weightageValidateion = true;
        this.setState({
          showfiletypedailog: true,
          showmessage:
            "Document Number and OCR sum of the weightage should be less than are equal to 1",
        });
      }
      if (fieldId3.reduce((a, b) => a + b, 0) > 1) {
        weightageValidateion = true;
        this.setState({
          showfiletypedailog: true,
          showmessage:
            "Sheet Number and OCR sum of theweightage should be less than are equal to 1",
        });
      }
      if (fieldId4.reduce((a, b) => a + b, 0) > 1) {
        weightageValidateion = true;
        this.setState({
          showfiletypedailog: true,
          showmessage:
            "Revision Number and OCR sum of the weightage should be less than are equal to 1",
        });
      }

      if (
        (fieldId1.reduce((a, b) => a + b, 0) <= 1 &&
          fieldId2.reduce((a, b) => a + b, 0) <= 1 &&
          fieldId3.reduce((a, b) => a + b, 0) <= 1 &&
          fieldId4.reduce((a, b) => a + b, 0) <= 1) === true
      ) {
        if (
          this.state.lineGroups.length != 0 &&
          this.state.products.length != 0
        ) {
          this.setState({loading: true});
          await axios
            .post(API_Endpoint + `/CreateWorkSpace`, datatofeed)
            .then((response) => {
              if (response.status === 200) {
                let id = response.data.workspaceId;
                UserStore.setnewWorkspaceId(id);
                localStorage.setItem("newWorkspaceId", id);
                UserStore.doColumnsort(true);
                this.setState({loading: false});
                if (
                  this.state.lineGroups.length != 0 &&
                  this.state.products.length != 0
                ) {
                  this.navigatetoWorkSpace();
                }
              }
              
            });
        }
        
      }
    } else {
      const datatofeed = Object.values(toJS(UserStore.editWorkspacedata)).length
        ? toJS(UserStore.editWorkspacedata)
        : localStorage.getItem("setEditWorkspacedata")
        ? JSON.parse(localStorage.getItem("setEditWorkspacedata"))
        : {};

      UserStore.setEditWorkspacedata(datatofeed);
      localStorage.setItem("setEditWorkspacedata", JSON.stringify(datatofeed));
      this.setState({loading: true});
      await axios
        .post(API_Endpoint + `/EditWorkSpace`, datatofeed)
        .then((res) => {
          if (res.status === 200) {
            this.setState({loading: false});
            this.navigatetoWorkSpace();
          }
        });      
    }
  };
  // ---------------End: Function: Submit detection logic --------------------

  navigatetoWorkSpace = () => {
    if (this.state.isEditWorkspaceFlag === true) {
      if (toJS(UserStore.editRow[0].isIsometricTickBased)) {
        this.props.history.push(`/Workspace`);
      } else {
        this.props.history.push(
          `/Workspace/EditWorkSpaceStamp/SetDetectionlogic/RegularExpression/Businessrulessetup`
        );
      }
    } else if (toJS(UserStore.isTickBasedCreateWS)) {
      this.props.history.push(`/Workspace`);
    } else {
      this.props.history.push(
        `/Workspace/CreateNewWorkspace/SetDetectionlogic/RegularExpression/Businessrulessetup`
      );
    }
  };

  handleClose = () => {
    this.setState({
      showfiletypedailog: false,
      showmessage: "",
    });
  };

  radioInput = (e) => {
    let inputValue = e.target.value;
    if (inputValue === "Exclusion") {
      UserStore.setIsExclusionRule(true);
      this.setState({ exclude: true, include: false });
    } else {
      UserStore.setIsExclusionRule(false);
      this.setState({ include: true, exclude: false });
    }
  };

  radioListofCodes = (e) => {
    let inputValue = e.target.value;
    if (inputValue === "Global") {
      UserStore.setIsGlobalListofCodes(true);
    } else {
      UserStore.setIsGlobalListofCodes(false);
    }
  };

  handleFileUpload = (event) => {
    if (event.target.files[0].type !== "text/csv") {
      this.setState({ validFileType: true, listName: "", listValue: "" });
    } else {
      Papa.parse(event.target.files[0], {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          this.setState({
            uploadedCSVList: results.data,
            listName: `SRCODES_${results.data[0].Key}`,
            listValue: results.data[0].Value,
            validFileType: false,
          });
        },
      });
    }
  };

  handleListCodeChangeKey = (e) => {
    const { name, value } = e.target;
    this.setState({ listName: value });
  };

  handleListCodeChangeValue = (e) => {
    const { name, value } = e.target;
    this.setState({ listValue: value });
  };

  handleModalClose = () => {
    this.setState({ phraseError: false });
  };

  render() {
    UserStore.setIsRegExp(true);
    const isTickBased = toJS(UserStore.editRow).length
      ? toJS(UserStore.editRow)[0].isIsometricTickBased
      : toJS(UserStore.isTickBasedCreateWS);

    if (!this.state.showForm) {
      return (
        <div className="regexp-container">
          {this.showForm && <UserForm />}
          <AppHeader />
          <BreadCrumbs />
          <LoadingOverlay
        className="process-page-loader"
        active={this.state.loading}
        spinner
        text="Loading your content..."
      ></LoadingOverlay>
          <div className="workspace-wrapper">
            <form onSubmit={this.handleSubmit}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div className="regexp-worspace-titlebar">
                  <div
                    className="create-new-workspace-wrapper"
                    style={
                      ({ display: "flex" },
                      { marginRight: "-2vw" },
                      { marginLeft: "51.5%" })
                    }
                  >
                    <Button
                      variant="contained"
                      align="right"
                      onClick={this.addRow1}
                      style={
                        this.state.isEditWorkspaceFlag
                          ? {
                              width: "14vw",
                              height: "2.29vw",
                              fontFamily: "Helvetica",
                              fontSize: "0.9vw",
                              textTransform: "none",
                              color: "white",
                              background: "#0c629b",
                              marginLeft: "-50vw",
                              marginRight: "72vw",
                              cursor: "not-allowed",
                              opacity: "0.5",
                              pointerEvents: "none",
                            }
                          : {
                              width: "14vw",
                              height: "2.29vw",
                              fontFamily: "Helvetica",
                              fontSize: "0.9vw",
                              textTransform: "none",
                              color: "white",
                              background: "#0c629b",
                              marginLeft: "-50vw",
                              marginRight: "72vw",
                            }
                      }
                    >
                      Add Regular Expression
                    </Button>

                    <Button
                      style={
                        this.state.isEditWorkspaceFlag
                          ? {
                              background: "#0C629B",
                              color: "#fff",
                              width: "11vw",
                              height: "2.29vw",
                              fontSize: "0.9vw",
                              textTransform: "none",
                              marginRight: "-1vw",
                              cursor: "not-allowed",
                              opacity: "0.5",
                              pointerEvents: "none",
                            }
                          : {
                              background: "#0C629B",
                              color: "#fff",
                              width: "11vw",
                              height: "2.29vw",
                              fontSize: "0.9vw",
                              textTransform: "none",
                              marginRight: "-1vw",
                            }
                      }
                      onClick={this.addRow2}
                      color="primary"
                    >
                      Add List of Codes
                    </Button>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    maxHeight: "58vh",
                  }}
                >
                  <div className="regexp-workspace-data-table-wrapper">
                    <BootstrapTable
                      keyField="id"
                      data={this.state.products}
                      columns={this.state.columns}
                    />
                  </div>
                  <div className="regexp-workspace-data-table-wrapper1">
                    <BootstrapTable
                      keyField="listid"
                      data={this.state.lineGroups}
                      columns={this.state.columns1}
                    />
                  </div>
                </div>
                <div className="regexp-btn-fields" Align="center">
                  <Button
                    type="submit"
                    variant="contained"
                    class="next-btn"
                    style={{
                      margin: "0 1.9vw 0 1vw",
                      width: "8.4vw",
                      height: "2.29vw",
                      fontSize: "0.9vw",
                      background: "#0c629b",
                      color: "#ffffff",
                      textTransform: "none",
                    }}
                  >
                    {isTickBased ? "Save" : "Next"}
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      background: "#ffffff",
                      border: "0.05vw solid #0c629b",
                      borderRadius: "0.15vw",
                      width: "8.4vw",
                      height: "2.29vw",
                      fontSize: "0.9vw",
                      color: "#0c629b",
                      textTransform: "none",
                    }}
                    onClick={() => {
                      this.props.history.push(`/Workspace`);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
              {this.state.setShow && this.state.editProducts && (
                <Dialog
                  open={this.state.setShow}
                  onClose={this.handleClose}
                  aria-labelledby="regexp-form-dialog-title"
                >
                  <DialogTitle id="regexp-form-dialog-title">
                    <span style={{ fontSize: "1.2vw", color: "#fff" }}>
                      Edit Regular Expression
                    </span>
                  </DialogTitle>
                  <DialogContent>
                    <div
                      style={{
                        font: "normal normal normal 1vw Helvetica",
                        marginLeft: "3%",
                        paddingTop: "0.5vw",
                      }}
                    >
                      <input
                        type="radio"
                        name="inclusion"
                        id="inclusion"
                        value="Inclusion"
                        defaultChecked={
                          this.state.editProducts.type === "Inclusion"
                        }
                        onChange={this.radioInput}
                      />
                      <label
                        for="inclusion"
                        style={{ padding: "0 1.5vw 0 0.3vw" }}
                      >
                        Inclusion Rules
                      </label>
                      <input
                        type="radio"
                        name="inclusion"
                        id="exclusion"
                        value="Exclusion"
                        defaultChecked={
                          this.state.editProducts.type === "Exclusion"
                        }
                        onChange={this.radioInput}
                      />
                      <label
                        for="exclusion"
                        style={{ padding: "0 0.5vw 0 0.3vw" }}
                      >
                        Exclusion Rules
                      </label>
                    </div>
                    <InputLabel id="demo-simple-select-label"></InputLabel>

                    <InputLabel
                      id="demo-simple-select-label"
                      style={{ marginLeft: "1vw" }}
                    >
                      Key
                    </InputLabel>

                    <SelectEditChange
                      idValue={this.state.modalId}
                      handleDropdownchangerowEdit={
                        this.handleDropdownchangerowEdit
                      }
                      editProducts={this.state.editProducts}
                    ></SelectEditChange>

                    {this.state.exclude != true ? (
                      <TextField
                        type="number"
                        margin="dense"
                        name="editWeightage"
                        id="editWeightage"
                        label="Weightage"
                        inputProps={{
                          min: 0,
                          max: 1,
                          step: "0.1",
                        }}
                        fullWidth
                        defaultValue={this.state.editProducts.weightage}
                        style={{
                          font: "normal normal Helvetica 1.04vw",
                          width: "95%",
                        }}
                        onChange={(e) => {
                          if (e.target.value > 0.9) {
                            e.target.value = "";
                            this.setState({ editWeightageMessage: true });
                          } else if (e.target.value === 1) {
                            this.setState({ editWeightageMessage: false });
                          } else {
                            this.setState({ editWeightageMessage: false });
                          }
                        }}
                        helperText={
                          this.state.editWeightageMessage ? (
                            <p style={{ color: "red" }}>
                              Enter value less than 1
                            </p>
                          ) : (
                            ""
                          )
                        }
                      />
                    ) : (
                      ""
                    )}

                    <TextareaAutosize
                      margin="dense"
                      id="editExpression"
                      name="editExpression"
                      placeholder="Expression"
                      fullWidth
                      defaultValue={this.state.editProducts.expression}
                      minRows={10}
                      style={
                        toJS(UserStore.isExclusionRule) == false
                          ? this.state.editWeightageMessage
                            ? {
                                font: "normal normal Helvetica 1.04vw",
                                width: "95%",
                                height: "5vw",
                                marginLeft: "1.2vw",
                                marginTop: "1vw",
                              }
                            : {
                                font: "normal normal Helvetica 1.04vw",
                                width: "95%",
                                height: "5vw",
                                marginLeft: "1.2vw",
                              }
                          : {
                              font: "normal normal Helvetica 1.04vw",
                              width: "95%",
                              height: "5vw",
                              marginLeft: "1.1vw",
                              marginTop: "1vw",
                            }
                      }
                    />

                    {/* {this.state.editRegexValidation ? <p style={{color:"red"}}>Please fill all the fields to save Regular Expression</p>:""} */}
                  </DialogContent>
                  <DialogActions style={{ padding: "0 12vw" }}>
                    <Button
                      style={{
                        background: "#0C629B",
                        color: "#fff",
                        width: "8.4vw",
                        height: "2.29vw",
                        fontSize: "0.9vw",
                        textTransform: "none",
                      }}
                      onClick={this.handleEditSave}
                      color="primary"
                    >
                      Save
                    </Button>
                    <Button
                      onClick={this.handleCloserow}
                      color="primary"
                      style={{
                        background: "#ffffff",
                        border: "0.05vw solid #0c629b",
                        borderRadius: "0.15vw",
                        width: "8.4vw",
                        height: "2.29vw",
                        fontSize: "0.9vw",
                        color: "#0c629b",
                        textTransform: "none",
                      }}
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
              )}

              {this.state.uploadShow1 && this.state.editLineGroups && (
                <Dialog
                  open={this.state.uploadShow1}
                  onClose={this.handleClose}
                  aria-labelledby="regexp-form-dialog-title"
                >
                  <DialogTitle id="regexp-form-dialog-title">
                    <span style={{ fontSize: "1.2vw", color: "#fff" }}>
                      Edit List of Codes
                    </span>
                  </DialogTitle>
                  <DialogContent>
                    <TextField
                      margin="dense"
                      id="listName"
                      name="listName"
                      label="List Name"
                      defaultValue={this.state.editLineGroups.listName}
                      fullWidth
                      style={{
                        font: "normal normal Helvetica 1.04vw",
                        width: "95%",
                      }}
                    />

                    <TextField
                      margin="dense"
                      id="listValue"
                      name="listValue"
                      label="List Value"
                      defaultValue={this.state.editLineGroups.listValue}
                      fullWidth
                      style={{
                        font: "normal normal Helvetica 1.04vw",
                        width: "95%",
                      }}
                    />

                    <div
                      style={{
                        font: "normal normal normal 1vw Helvetica",
                        marginLeft: "3%",
                        paddingTop: "0.5vw",
                      }}
                    >
                      <input
                        type="radio"
                        name="global"
                        id="global"
                        value="Global"
                        defaultChecked={
                          this.state.editLineGroups.listScope === "Global"
                        }
                        onChange={this.radioListofCodes}
                      />
                      <label
                        for="global"
                        style={{ padding: "0 1.5vw 0 0.3vw" }}
                      >
                        Global
                      </label>
                      <input
                        type="radio"
                        name="global"
                        id="project"
                        value="Project"
                        defaultChecked={
                          this.state.editLineGroups.listScope === "Project"
                        }
                        onChange={this.radioListofCodes}
                      />
                      <label
                        for="exclusion"
                        style={{ padding: "0 0.5vw 0 0.3vw" }}
                      >
                        Project
                      </label>
                    </div>
                    {this.state.validFileType ? (
                      <p style={{ color: "red", marginLeft: "1vw" }}>
                        Please Upload only CSV files to add list of codes
                      </p>
                    ) : (
                      ""
                    )}
                    {this.state.Duplicatekey ? (
                      <p style={{ color: "red", marginLeft: "1vw" }}>
                        The List Name already exists. Please add unique name
                      </p>
                    ) : (
                      ""
                    )}
                  </DialogContent>
                  <DialogActions style={{ padding: "0 12vw" }}>
                    <InputLabel htmlFor="lg-files">
                      <Input
                        type="file"
                        id="lg-files"
                        name="file"
                        accept=".csv"
                        onChange={this.handleFileUpload}
                        style={{ display: "none" }}
                      />
                      <Button
                        style={{
                          background: "#0C629B",
                          color: "#fff",
                          width: "8vw",
                          height: "2.29vw",
                          fontSize: "0.9vw",
                          marginTop: "0.6vw",
                          textTransform: "none",
                        }}
                        color="primary"
                        variant="contained"
                        component="span"
                      >
                        Upload CSV
                      </Button>
                    </InputLabel>

                    <Button
                      style={{
                        background: "#0C629B",
                        color: "#fff",
                        width: "8.4vw",
                        height: "2.29vw",
                        fontSize: "0.9vw",
                        textTransform: "none",
                      }}
                      onClick={this.handleEditListOfCodesSave}
                      color="primary"
                    >
                      Save
                    </Button>
                    <Button
                      onClick={this.handleCloserow}
                      color="primary"
                      style={{
                        background: "#ffffff",
                        border: "0.05vw solid #0c629b",
                        borderRadius: "0.15vw",
                        width: "8.4vw",
                        height: "2.29vw",
                        fontSize: "0.9vw",
                        color: "#0c629b",
                        textTransform: "none",
                      }}
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
              )}

              <Dialog
                className="delete-confirm"
                open={this.state.delete}
                onClose={this.handleCloseDialog}
                aria-labelledby="regexp-form-dialog-title"
              >
                <DialogTitle
                  id="regexp-form-dialog-title"
                  style={{ height: "0.625em" }}
                ></DialogTitle>
                <DialogContent>
                  <div style={{ fontSize: "1vw" }}>Are you sure to delete?</div>
                  <IconButton
                    onClick={this.handleCloseDialog}
                    style={{
                      padding: "0",
                      float: "right",
                      background: "#0C629B",
                      color: "#fff",
                      position: "absolute",
                      right: "15%",
                    }}
                  >
                    {" "}
                    <Cancel />{" "}
                  </IconButton>
                  <IconButton
                    onClick={(e) => this.handlePurge(e)}
                    style={{
                      padding: "0",
                      background: "#0C629B",
                      color: "white",
                      position: "absolute",
                      right: "2%",
                    }}
                  >
                    {" "}
                    <CircleChecked />{" "}
                  </IconButton>
                </DialogContent>
              </Dialog>

              <Dialog
                className="delete-confirm"
                open={this.state.deleteLOC}
                onClose={this.handleCloseDialog}
                aria-labelledby="regexp-form-dialog-title"
              >
                <DialogTitle
                  id="regexp-form-dialog-title"
                  style={{ height: "0.625em" }}
                ></DialogTitle>
                <DialogContent>
                  <div style={{ fontSize: "1vw" }}>Are you sure to delete?</div>
                  <IconButton
                    onClick={this.handleCloseDialog}
                    style={{
                      padding: "0",
                      float: "right",
                      background: "#0C629B",
                      color: "#fff",
                      position: "absolute",
                      right: "15%",
                    }}
                  >
                    {" "}
                    <Cancel />{" "}
                  </IconButton>
                  <IconButton
                    onClick={(e) => this.handleLOCPurge(e)}
                    style={{
                      padding: "0",
                      background: "#0C629B",
                      color: "white",
                      position: "absolute",
                      right: "2%",
                    }}
                  >
                    {" "}
                    <CircleChecked />{" "}
                  </IconButton>
                </DialogContent>
              </Dialog>

              <Dialog
                open={this.state.setShow1}
                onClose={this.handleClose}
                aria-labelledby="regexp-form-dialog-title"
                className="regexp-popup"
              >
                <DialogTitle
                  id="regexp-form-dialog-title"
                  style={{ height: "4vw" }}
                >
                  <span style={{ fontSize: "1.2vw", color: "#fff" }}>
                    Add Regular Expression
                  </span>
                </DialogTitle>
                <DialogContent>
                  <div
                    style={{
                      font: "normal normal normal 1vw Helvetica",
                      marginLeft: "3%",
                      paddingTop: "0.5vw",
                    }}
                  >
                    <input
                      type="radio"
                      name="inclusion"
                      id="inclusion"
                      value="Inclusion"
                      defaultChecked
                      onChange={this.radioInput}
                    />
                    <label
                      for="inclusion"
                      style={{ padding: "0 1.5vw 0 0.3vw" }}
                    >
                      Inclusion Rules
                    </label>
                    <input
                      type="radio"
                      name="inclusion"
                      id="exclusion"
                      value="Exclusion"
                      onChange={this.radioInput}
                    />
                    <label
                      for="exclusion"
                      style={{ padding: "0 0.5vw 0 0.3vw" }}
                    >
                      Exclusion Rules
                    </label>
                  </div>

                  <InputLabel
                    id="demo-simple-select-label"
                    style={{ marginLeft: "1vw", fontSize: "1.05vw" }}
                  ></InputLabel>

                  <InputLabel
                    id="demo-simple-select-label"
                    style={{
                      marginLeft: "3%",
                      font: "normal normal Helvetica 1.04vw",
                    }}
                  >
                    Key
                  </InputLabel>
                  <SelectChange
                    idValue={this.state.modalId}
                    handleDropdownchangerowEdit={this.handleDropdownchangerow}
                    editProducts={this.state.newrow}
                  ></SelectChange>

                  {this.state.exclude != true ? (
                    <TextField
                      type="number"
                      margin="dense"
                      id="Weightage"
                      name="Weightage"
                      label="Weightage"
                      inputProps={{
                        min: 0,
                        max: 1,
                        step: "0.1",
                      }}
                      fullWidth
                      style={{
                        font: "normal normal Helvetica 1.04vw",
                        width: "95%",
                      }}
                      onChange={(e) => {
                        if (e.target.value > 0.9) {
                          e.target.value = "";
                          this.setState({ weightageMessage: true });
                        } else if (e.target.value === 1) {
                          this.setState({ weightageMessage: false });
                        } else {
                          this.setState({ weightageMessage: false });
                        }
                      }}
                      helperText={
                        this.state.weightageMessage ? (
                          <p style={{ color: "red" }}>
                            Enter value less than 1
                          </p>
                        ) : (
                          ""
                        )
                      }
                    />
                  ) : (
                    ""
                  )}

                  <TextareaAutosize
                    margin="dense"
                    id="Expression"
                    name="Expression"
                    placeholder="Expression"
                    fullWidth
                    required
                    minRows={10}
                    style={
                      toJS(UserStore.isExclusionRule) == false
                        ? this.state.weightageMessage
                          ? {
                              font: "normal normal Helvetica 1.04vw",
                              width: "95%",
                              height: "5vw",
                              marginLeft: "1.2vw",
                              marginTop: "1vw",
                            }
                          : {
                              font: "normal normal Helvetica 1.04vw",
                              width: "95%",
                              height: "5vw",
                              marginLeft: "1.2vw",
                            }
                        : {
                            font: "normal normal Helvetica 1.04vw",
                            width: "95%",
                            height: "5vw",
                            marginLeft: "1.1vw",
                            marginTop: "1vw",
                          }
                    }
                  />
                  {this.state.saveRegexValidation ? (
                    <p style={{ color: "red", marginLeft: "1vw" }}>
                      Please fill all the fields to add Regular Expression
                    </p>
                  ) : (
                    ""
                  )}
                </DialogContent>

                <DialogActions style={{ padding: "0 12vw" }}>
                  <Button
                    style={{
                      background: "#0C629B",
                      color: "#fff",
                      width: "14vw",
                      height: "2.29vw",
                      fontSize: "0.9vw",
                      textTransform: "none",
                    }}
                    onClick={this.rowSave}
                    color="primary"
                  >
                    Add Regular Expression
                  </Button>
                  <Button
                    //   style={{ border: "1px solid #4B4A55" }}
                    onClick={this.handleCloserow}
                    color="primary"
                    style={{
                      background: "#ffffff",
                      border: "0.05vw solid #0c629b",
                      borderRadius: "0.15vw",
                      width: "8.4vw",
                      height: "2.29vw",
                      fontSize: "0.9vw",
                      color: "#0c629b",
                      textTransform: "none",
                    }}
                  >
                    Cancel
                  </Button>
                </DialogActions>
                {/* </Grid> */}
              </Dialog>

              <Dialog
                open={this.state.uploadShow}
                onClose={this.handleClose}
                aria-labelledby="regexp-form-dialog-title"
                className="regexp-popup"
              >
                <DialogTitle
                  id="regexp-form-dialog-title"
                  style={{ height: "4vw" }}
                >
                  <span style={{ fontSize: "1.2vw", color: "#fff" }}>
                    Add List of Codes
                  </span>
                </DialogTitle>
                <DialogContent>
                  <TextField
                    margin="dense"
                    id="listName"
                    name="listName"
                    label="List Name"
                    value={this.state.listName}
                    onChange={this.handleListCodeChangeKey}
                    fullWidth
                    style={{
                      font: "normal normal Helvetica 1.04vw",
                      width: "95%",
                    }}
                  />

                  <TextField
                    margin="dense"
                    id="listValue"
                    name="listValue"
                    label="List Value"
                    value={this.state.listValue}
                    onChange={this.handleListCodeChangeValue}
                    fullWidth
                    style={{
                      font: "normal normal Helvetica 1.04vw",
                      width: "95%",
                    }}
                  />

                  <div
                    style={{
                      font: "normal normal normal 1vw Helvetica",
                      marginLeft: "3%",
                      paddingTop: "0.5vw",
                    }}
                  >
                    <input
                      type="radio"
                      name="listGlobal"
                      id="global"
                      value="Global"
                      defaultChecked
                      onChange={this.radioListofCodes}
                    />
                    <label for="global" style={{ padding: "0 1.5vw 0 0.3vw" }}>
                      Global
                    </label>
                    <input
                      type="radio"
                      name="listGlobal"
                      id="project"
                      value="Project"
                      onChange={this.radioListofCodes}
                    />
                    <label for="project" style={{ padding: "0 0.5vw 0 0.3vw" }}>
                      Project
                    </label>
                  </div>

                  {this.state.saveListValidation ? (
                    <p style={{ color: "red", marginLeft: "1vw" }}>
                      Please fill all the fields to add List of Codes
                    </p>
                  ) : (
                    ""
                  )}
                  {this.state.validFileType ? (
                    <p style={{ color: "red", marginLeft: "1vw" }}>
                      Please Upload only CSV files to add list of codes
                    </p>
                  ) : (
                    ""
                  )}
                  {this.state.Duplicatekey ? (
                    <p style={{ color: "red", marginLeft: "1vw" }}>
                      The List Name already exists. Please add unique name.
                    </p>
                  ) : (
                    ""
                  )}
                </DialogContent>

                <DialogActions style={{ padding: "0 12vw" }}>
                  <InputLabel htmlFor="csvInput">
                    <Input
                      type="file"
                      id="csvInput"
                      name="file"
                      accept=".csv"
                      onChange={this.handleFileUpload}
                      style={{ display: "none" }}
                    />
                    <Button
                      style={{
                        background: "#0C629B",
                        color: "#fff",
                        width: "8vw",
                        height: "2.29vw",
                        fontSize: "0.9vw",
                        marginTop: "0.6vw",
                        textTransform: "none",
                      }}
                      color="primary"
                      variant="contained"
                      component="span"
                    >
                      Upload CSV
                    </Button>
                  </InputLabel>

                  <Button
                    style={{
                      background: "#0C629B",
                      color: "#fff",
                      width: "8.4vw",
                      height: "2.29vw",
                      fontSize: "0.9vw",
                      textTransform: "none",
                    }}
                    onClick={this.saveListofCodes}
                    color="primary"
                  >
                    Add
                  </Button>

                  <Button
                    //   style={{ border: "1px solid #4B4A55" }}
                    onClick={this.handleCloserow}
                    color="primary"
                    style={{
                      background: "#ffffff",
                      border: "0.05vw solid #0c629b",
                      borderRadius: "0.15vw",
                      width: "8.4vw",
                      height: "2.29vw",
                      fontSize: "0.9vw",
                      color: "#0c629b",
                      textTransform: "none",
                    }}
                  >
                    Cancel
                  </Button>
                </DialogActions>
                {/* </Grid> */}
              </Dialog>
            </form>
          </div>

          <Modal
            className="user-select-popup"
            show={this.state.showfiletypedailog}
            onHide={this.handleClose}
            backdrop="static"
            keyboard={false}
            dialogClassName="fullmodal"
            size="l"
            centered
          >
            <Modal.Body>
              <label
                style={{
                  font: "normal normal bold 1vw Helvetica",
                  textAlign: "left",
                  padding: "1vw",
                }}
              >
                {this.state.showmessage}

                <IconButton
                  onClick={this.handleClose}
                  style={{
                    padding: "0.156vw",
                    position: "absolute",
                    top: "0",
                    right: "0",
                    background: "",
                    borderRadius: "0",
                    color: "#0C629B",
                  }}
                >
                  {" "}
                  <CloseIcon style={{ width: "1.5vw", height: "1.5vw" }} />{" "}
                </IconButton>
              </label>
            </Modal.Body>
          </Modal>
          <Modal
            show={this.state.phraseError}
            onHide={this.handleModalClose}
            backdrop="static"
            keyboard={false}
            dialogClassName="fullmodal"
            size="l"
            centered
          >
            <Modal.Body>
              <label>
                {this.state.phraseMessage}
                <IconButton
                  onClick={this.handleModalClose}
                  style={{
                    padding: "0.156vw",
                    position: "absolute",
                    top: "0",
                    right: "0",
                    background: "",
                    borderRadius: "0",
                    color: "#0C629B",
                  }}
                >
                  {" "}
                  <CloseIcon style={{ width: "1.5vw", height: "1.5vw" }} />{" "}
                </IconButton>
              </label>
            </Modal.Body>
          </Modal>
          <AppFooter />
        </div>
      );
    } else {
      return <UserForm />;
    }
  }
}
export default RegularExp;
