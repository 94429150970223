import React, { useState, useEffect} from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
// !IMPORTANT do not delete these 2 lines !IMPORTANT
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import FileViewer from "react-file-viewer";
// !IMPORTANT do not delete these 2 lines !IMPORTANT
import { Document, Page } from "react-pdf";
import { Button } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Modal from "react-bootstrap/Modal";
import DialogActions from "@material-ui/core/DialogActions";
import AppHeader from "../../components/AppHeader/AppHeader";
import AppFooter from "../../components/AppFooter/AppFooter";
import Breadcrumbs from "../Upload/BreadCrumbs/BreadCrumbs";
import UserStore from "../UserStore";
import { observer } from "mobx-react-lite";
import ExportExcel from "../../components/ExportExcel";
import { toJS } from "mobx";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import "./Preview.scss";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import Moment from "moment";

const useStyles = makeStyles((theme) => ({
  input: {
    border: " 0.8px solid #0C629B",
    borderRadius: "2px",
    padding: "1%",
    height: "38px",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
    width: "20ch",
    backgroundColor: "white",
  },
}));

export function Preview(props) {
  let { id } = useParams();
  const [show, setShow] = useState(false);
  const [accept, setAccept] = useState(false);
  const [acceptit, setAcceptit] = useState(false);
  const [message, setMessage] = useState("");
  const [message1, setMessage1] = useState("");
  const [phrase1BB, setphrase1BB] = useState([]);
  const [phrase2BB, setphrase2BB] = useState([]);
  const [reportdata, setreportdata] = useState([]);
  const [docNoBB, setdocNoBB] = useState([]);
  const [sheetNoBB, setsheetNoBB] = useState([]);
  const [revNoBB, setrevNoBB] = useState([]);
  const [lineNoBB, setlineNoBB] = useState([]);
  const [phrase1text, setphrase1text] = useState("");
  const [phrase2text, setphrase2text] = useState("");
  const [docNotext, setdocNotext] = useState("");
  const [sheetNotext, setsheetNotext] = useState("");
  const [lineNotext, setlineNotext] = useState("");
  const [revNotext, setrevNotext] = useState("");
  const [modifieddoctext, setmodifieddoctext] = useState("");
  const [filepath, setfilepath] = useState("");
  const [response, setresponse] = useState([]);
  const [stampType, setStampType] = useState(false);
  const fileID = UserStore.splitedFileId !== 0 ? UserStore.splitedFileId : (localStorage.getItem('splitedFileId') ? localStorage.getItem('splitedFileId') : 0);
  const [p1score, setp1score] = useState("");
  const [p2score, setp2score] = useState("");
  const [docnumscore, setdocnumscore] = useState("");
  const [sheetnumscore, setsheetnumscore] = useState("");
  const [linenumscore, setlinenumscore] = useState("");
  const [revnumscore, setrevnumscore] = useState("");
  const [showUser, setshowUser] = useState(true);
  const APIEndpoint = process.env.REACT_APP_API_ENDPOINT;
  const scoreCutoff = process.env.REACT_APP_CONFIDENCE_SCORE_CUTOFF;
  const [errorMsgIsometricNumber, setErrorMsgIsometricNumber] = useState("");
  const [errorMsgLineNumber, setErrorMsgLineNumber] = useState("");
  const [errorMsgSheetNumber, setErrorMsgSheetNumber] = useState("");
  const [errorMsgRevisioNumber, setErrorMsgRevisionNumber] = useState("");
  const [errorMsgModifiedDoc, setErrorMsgModifiedDoc] = useState("");
  const [errorIsometricNumber, setErrorIsometricNumber] = useState(false);
  const [errorLineNumber, setErrorLineNumber] = useState(false);
  const [errorSheetNumber, setErrorSheetNumber] = useState(false);
  const [errorRevisioNumber, setErrorRevisionNumber] = useState(false);
  const [errorModifiedDoc, setErrorModifiedDoc] = useState(false);
  const [revLabel, setrevLabel] = useState(false);
  const [pdfWidth, setPdfWidth] = useState("");
  const [pdfHeight, setPdfHeight] = useState("");
  const [DocViewerWidth, setDocViewerWidth] = useState("");
  const [DocViewerHeight, setDocViewerHeight] = useState("");
  const [checkbox1, setCheckbox1] = useState([]);
  const [checkbox2, setCheckbox2] = useState([]);
  const [checkbox3, setCheckbox3] = useState([]);
  const [checkbox4, setCheckbox4] = useState([]);
  const [labelcheckbox1, setLabelCheckbox1] = useState([]);
  const [labelcheckbox2, setLabelCheckbox2] = useState([]);
  const [labelcheckbox3, setLabelCheckbox3] = useState([]);
  const [labelcheckbox4, setLabelCheckbox4] = useState([]);
  const [labelcheckboxtext1, setLabelCheckboxText1] = useState([]);
  const [labelcheckboxtext2, setLabelCheckboxText2] = useState([]);
  const [labelcheckboxtext3, setLabelCheckboxText3] = useState([]);
  const [labelcheckboxtext4, setLabelCheckboxText4] = useState([]);
  const [c1score, setc1score] = useState("");
  const [c2score, setc2score] = useState("");
  const [c3score, setc3score] = useState("");
  const [c4score, setc4score] = useState("");
  const [l1score, setl1score] = useState("");
  const [l2score, setl2score] = useState("");
  const [l3score, setl3score] = useState("");
  const [l4score, setl4score] = useState("");
  const [changeheight, setchangeheight] = useState("");
  const [changewidth, setchangewidth] = useState("");
  const [isPixels,setIsPixels] = useState("");
  const readonly = localStorage.getItem("isreadOnly") ? JSON.parse(localStorage.getItem("isreadOnly")): UserStore.readOnly;
  const acceptanceStatus = localStorage.getItem("accepteddoc") ? JSON.parse(localStorage.getItem("accepteddoc")) : UserStore.accepteddoc;
  const classes = useStyles();

  // -----------------Start: Function: Fetch the isometric details ---------------------
  async function previewAPIcall() {
    const user = "Admin";
    if (user === "read") {
      setshowUser(false);
    }
    // API: GET Isometric details
    const res = await axios.get(
      APIEndpoint + `/PreviewProcessedIsometric?splitedFileId=${fileID}`
    );
    if (res.data) {
      setIsPixels(res.data.extractedJson[0].IsPDFConvertedToImage);
      if (res.data.extractedJson[0].Fields.hasOwnProperty("Phrase1")) {
        setphrase1BB(
          res.data.extractedJson[0].Fields.Phrase1.ValueData.BoundingBox
        );
        setphrase1text(res.data.extractedJson[0].Fields.Phrase1.ValueData.Text);
        setp1score(res.data.extractedJson[0].Fields.Phrase1.Confidence);
      }
      if (res.data.extractedJson[0].Fields.hasOwnProperty("Checkbox01")) {
        setCheckbox1(
          res.data.extractedJson[0].Fields.Checkbox01.ValueData.BoundingBox
        );
        setc1score(res.data.extractedJson[0].Fields.Checkbox01.Confidence);
      }
      if (res.data.extractedJson[0].Fields.hasOwnProperty("Checkbox02")) {
        setCheckbox2(
          res.data.extractedJson[0].Fields.Checkbox02.ValueData.BoundingBox
        );
        setc2score(res.data.extractedJson[0].Fields.Checkbox02.Confidence);
      }
      if (res.data.extractedJson[0].Fields.hasOwnProperty("Checkbox03")) {
        setCheckbox3(
          res.data.extractedJson[0].Fields.Checkbox03.ValueData.BoundingBox
        );
        setc3score(res.data.extractedJson[0].Fields.Checkbox03.Confidence);
      }
      if (res.data.extractedJson[0].Fields.hasOwnProperty("Checkbox04")) {
        setCheckbox4(
          res.data.extractedJson[0].Fields.Checkbox04.ValueData.BoundingBox
        );
        setc4score(res.data.extractedJson[0].Fields.Checkbox04.Confidence);
      }
      if (res.data.extractedJson[0].Fields.hasOwnProperty("LabelCheckbox01")) {
        setLabelCheckbox1(
          res.data.extractedJson[0].Fields.LabelCheckbox01.ValueData.BoundingBox
        );
        setLabelCheckboxText1(
          res.data.extractedJson[0].Fields.LabelCheckbox01.ValueData.Text
        );
        setl1score(res.data.extractedJson[0].Fields.LabelCheckbox01.Confidence);
      }
      if (res.data.extractedJson[0].Fields.hasOwnProperty("LabelCheckbox02")) {
        setLabelCheckbox2(
          res.data.extractedJson[0].Fields.LabelCheckbox02.ValueData.BoundingBox
        );
        setLabelCheckboxText2(
          res.data.extractedJson[0].Fields.LabelCheckbox02.ValueData.Text
        );
        setl2score(res.data.extractedJson[0].Fields.LabelCheckbox02.Confidence);
      }
      if (res.data.extractedJson[0].Fields.hasOwnProperty("LabelCheckbox03")) {
        setLabelCheckbox3(
          res.data.extractedJson[0].Fields.LabelCheckbox03.ValueData.BoundingBox
        );
        setLabelCheckboxText3(
          res.data.extractedJson[0].Fields.LabelCheckbox03.ValueData.Text
        );
        setl3score(res.data.extractedJson[0].Fields.LabelCheckbox03.Confidence);
      }
      if (res.data.extractedJson[0].Fields.hasOwnProperty("LabelCheckbox04")) {
        setLabelCheckbox4(
          res.data.extractedJson[0].Fields.LabelCheckbox04.ValueData.BoundingBox
        );
        setLabelCheckboxText4(
          res.data.extractedJson[0].Fields.LabelCheckbox04.ValueData.Text
        );
        setl4score(res.data.extractedJson[0].Fields.LabelCheckbox04.Confidence);
      }
      if (res.data.extractedJson[0].Fields.hasOwnProperty("Phrase2")) {
        setphrase2BB(
          res.data.extractedJson[0].Fields.Phrase2.ValueData.BoundingBox
        );
        setphrase2text(res.data.extractedJson[0].Fields.Phrase2.ValueData.Text);
        setp2score(res.data.extractedJson[0].Fields.Phrase2.Confidence);
      }
      if(res.data.hasOwnProperty("isometricNumber")){
          if (res.data.extractedJson[0].Fields.hasOwnProperty("DocumentNumber")) {
            setdocNoBB(
              res.data.extractedJson[0].Fields.DocumentNumber.ValueData.BoundingBox
            );            
            setdocnumscore(
              res.data.extractedJson[0].Fields.DocumentNumber.Confidence
            );
          }
          setdocNotext(res.data.isometricNumber);
      }
      if(res.data.hasOwnProperty("lineNumber")){
        if (res.data.extractedJson[0].Fields.hasOwnProperty("LineNumber")) {
          setlineNoBB(
            res.data.extractedJson[0].Fields.LineNumber.ValueData.BoundingBox
          );          
          setlinenumscore(res.data.extractedJson[0].Fields.LineNumber.Confidence);
        }
        setlineNotext(res.data.lineNumber);
      }
      if (res.data.hasOwnProperty("revisionNumber")) {
        if (res.data.extractedJson[0].Fields.hasOwnProperty("RevisionNumber")) {
          setrevNoBB(
            res.data.extractedJson[0].Fields.RevisionNumber.ValueData.BoundingBox
          );
          setrevnumscore(
            res.data.extractedJson[0].Fields.RevisionNumber.Confidence
          );
        }else if (
          res.data.extractedJson[0].Fields.hasOwnProperty("RevisionNumberLabel")
        ) {
          setrevLabel(true);
          setrevNoBB(
            res.data.extractedJson[0].Fields.RevisionNumberLabel.ValueData.BoundingBox
          );
          setrevnumscore(
            res.data.extractedJson[0].Fields.RevisionNumberLabel.Confidence
          );
        }        
        setrevNotext(res.data.revisionNumber);        
      }else {
        if (
          res.data.extractedJson[0].Fields.hasOwnProperty("RevisionNumberLabel")
        ) {
          setrevLabel(true);
          setrevNoBB(
            res.data.extractedJson[0].Fields.RevisionNumberLabel.ValueData.BoundingBox
          );
          setrevNotext(res.data.revisionNumber);
          setrevnumscore(
            res.data.extractedJson[0].Fields.RevisionNumberLabel.Confidence
          );
        }
      }
      // if (res.data.extractedJson[0].Fields.hasOwnProperty("RevisionNumber")) {
        // setrevNoBB(
        //   res.data.extractedJson[0].Fields.RevisionNumber.ValueData.BoundingBox
        // );
        setmodifieddoctext(res.data.modifiedDoc);
        // setrevnumscore(
        //   res.data.extractedJson[0].Fields.RevisionNumber.Confidence
        // );
      //}
      if (res.data.hasOwnProperty("sheetNumber")) {
        if (res.data.extractedJson[0].Fields.hasOwnProperty("SheetNumber")) {
          setsheetNoBB(
            res.data.extractedJson[0].Fields.SheetNumber.ValueData.BoundingBox
          );
          
          setsheetnumscore(
            res.data.extractedJson[0].Fields.SheetNumber.Confidence
          );
        }
        setsheetNotext(res.data.sheetNumber);
      }
      setfilepath(res.data.isometricUri);
      setresponse(res.data);
      if(!toJS(UserStore.reportData).length && localStorage.getItem('itemChange')) {
        UserStore.addReportdata(JSON.parse(localStorage.getItem('itemChange')));
      }
      const data = toJS(UserStore.reportData);
      reportdata.push(data);
      setreportdata(reportdata);
      setStampType(res.data.isChange);
      setPdfWidth(res.data.extractedJson[0].Pages[0].Width);
      setPdfHeight(res.data.extractedJson[0].Pages[0].Height);
    }
  }

  function base64ToArrayBuffer(data) {
    let binaryString = window.atob(data);
    // var binaryString = window.atob(window.btoa(data));
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
};
  // -----------------End: Function: Fetch the isometric details ---------------------

  useEffect(() => {
    UserStore.getprocessGrid(1);
    UserStore.PIDselectedRowID = [];
    localStorage.setItem('getprocessGrid', 1);
    previewAPIcall();
  }, []);

  // --------------Start: Function: Validate empty fields---------------
  function validateEmpty() {
    if (docNotext === "") {
      setErrorIsometricNumber(true);
      setErrorMsgIsometricNumber("Field is not identified");
    }
    if (lineNotext === "") {
      setErrorLineNumber(true);
      setErrorMsgLineNumber("Field is not identified");
    }
    if (revNotext === "") {
      setErrorRevisionNumber(true);
      setErrorMsgRevisionNumber("Field is not identified");
    }
    if (sheetNotext === "") {
      setErrorSheetNumber(true);
      setErrorMsgSheetNumber("Field is not identified");
    }
  }
  // --------------End: Function: Validate empty fields---------------
  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }
  function formatDate(date) {
    return (
      [
        date.getFullYear(),
        padTo2Digits(date.getUTCMonth() + 1),
        padTo2Digits(date.getUTCDate()),
      ].join('/') +
      ' ' +
      [
        padTo2Digits(date.getUTCHours()),
        padTo2Digits(date.getUTCMinutes()),
        padTo2Digits(date.getUTCSeconds())
      ].join(':') +
      ' +00:00'
    );
  }

  // ---------------Start: Function: Accept / Modify isometric ------------------
  const acceptMsg = async(nameforbutton) => {
    const newdate = formatDate(new Date());
    localStorage.setItem("modifiedtime", newdate);
    setMessage("");
    setMessage1("");
    setAccept(false);
    setAcceptit(false);  
    let chooseApi = nameforbutton;
    let value = 0;
    let body = {
      splitedFileId: fileID,
      isChange: stampType,
      isometricNumber: docNotext,
      lineNumber: lineNotext,
      sheetNumber: sheetNotext,
      revisionNumber: revNotext,
      modifiedDoc: modifieddoctext,
    };
    if (body.isometricNumber === "") {
      value = value + 1;
      setErrorIsometricNumber(true);
      setErrorMsgIsometricNumber("Field should not be empty");
    }
    if (body.lineNumber === "") {
      value = value + 1;
      setErrorLineNumber(true);
      setErrorMsgLineNumber("Field should not be empty");
    }
    if (body.sheetNumber === "") {
      value = value + 1;
      setErrorSheetNumber(true);
      setErrorMsgSheetNumber("Field should not be empty");
    }
    if (body.modifiedDoc !== null && body.modifiedDoc.length > 5) {
      value = value + 1;
      setErrorModifiedDoc(true);
      setErrorMsgModifiedDoc("Field size exceeded");
    } else if ( /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(body.modifiedDoc)) {
      value = value + 1;
      setErrorModifiedDoc(true);
      setErrorMsgModifiedDoc(
        "special characters are not allowed"
      );
    }
    if (body.revisionNumber === "") {
      value = value + 1;
      setErrorRevisionNumber(true);
      setErrorMsgRevisionNumber("Field should not be empty");
    } else if ( /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(body.revisionNumber)) {
      value = value + 1;
      setErrorRevisionNumber(true);
      setErrorMsgRevisionNumber(
        "Revision Number should not contain special characters"
      );
    } else {
      setErrorRevisionNumber(false);
      setErrorMsgRevisionNumber("");
    }
    if (value === 0) {     
      setErrorIsometricNumber(false);
      setErrorMsgIsometricNumber("");
      setErrorLineNumber(false);
      setErrorMsgLineNumber("");
      setErrorSheetNumber(false);
      setErrorMsgSheetNumber("");
      setErrorRevisionNumber(false);
      setErrorMsgRevisionNumber("");
      setErrorMsgModifiedDoc("");
      setErrorModifiedDoc(false);
      if(chooseApi === "save")
      {
        // API: Modify isometric with edited fields
        const res = await axios.post(
          APIEndpoint + `/ModifyProcessedIsometric`,
          body
        );
        if(res.status === 200) {
          setAcceptit(true);
        setMessage1("Document is saved successfully.");
        handleGetProcessedIsometrics(body,chooseApi);
        }
      } else {
        // API: Accept isometric with same or edited fields
      const res = await axios.post(
        APIEndpoint + `/AcceptProcessedIsometric`,
        body
      );
      if(res.status === 200) {
      UserStore.isaccepteddoc(true);
      localStorage.setItem('accepteddoc', true);
      setAccept(true);
      setMessage("Document is accepted successfully.");
      handleGetProcessedIsometrics(body,chooseApi);
      }
    }
    }
  }
  // ------------------End: Function: Accept / Modify isometric ------------------

  const  handleGetProcessedIsometrics = async(body,chooseApi) =>{
      const processedData = toJS(UserStore.processGridData);
      const data = processedData.map((val) => {
        if(val.splitedFileId === fileID) {
          val.isChange = body.isChange != null ? body.isChange === "true" ? "Yes" : "No" : null;
          val.isometricNumber = body.isometricNumber;
          val.lineNumber = body.lineNumber;
          val.sheetNumber = body.sheetNumber;
          val.revisionNumber = body.revisionNumber;
          val.modifiedDoc = body.modifiedDoc;
          val.modifiedBy = localStorage.getItem("userEmail");
          val.modiffiedDate = localStorage.getItem("modifiedtime");
          if(chooseApi !== "save") {
            val.isAccepted = true;
            val.acceptanceStatus = "Accepted";
          }
          localStorage.setItem("processingTime",val.processingDate);
          let processingDate = val.processingDate.includes("+") ? val.processingDate.split("+") : val.processingDate;
          val.processingDate = processingDate[0].trim().replaceAll("-","/")
          val.processingDate = Moment.utc(val.processingDate, "YYYY/MM/DD hh:mm:ss a").local().format("DD/MM/YYYY hh:mm:ss a")
          val.modiffiedDate =  Moment.utc(val.modiffiedDate, "YYYY/MM/DD hh:mm:ss a").local().format("DD/MM/YYYY hh:mm:ss a")
          UserStore.addReportdata([val])
          val.modiffiedDate = localStorage.getItem("modifiedtime");
          val.processingDate = localStorage.getItem("processingTime");
        }
        return val;
      })
      
      UserStore.setProcessGridData(data)
  }

  function handleShow() {
    setShow(true);
  }

  const handleClose = () => {
    setShow(false);
  };

  // -------------------Start: Function: Close the remarks dialog box --------------------
  async function handleCloseAPI() {
    let remarks = document.getElementById("remarks-field").value;
    if(remarks != "") {
      let body = {
        SplitedFileId: fileID,
        Remarks: remarks,
      };
      // API: Reject the isometric
      const res = await axios.post(
        APIEndpoint + `/RejectProcessedIsometric`,
        body
      );
      setShow(false);
      if(res.status === 200) {
      setAcceptit(true);
      setMessage1("Document is rejected successfully.")
      handleRejectData(remarks);
      }
    }
  }

  const handleRejectData = (remarks) => {
    const processedData = toJS(UserStore.processGridData);
      const data = processedData.map((val) => {
        if(val.splitedFileId === fileID) {
          val.isAccepted = false;
          val.acceptanceStatus = "Rejected";
          val.rejectionRemark = remarks;
          localStorage.setItem("processingTime",val.processingDate);
          let processingDate = val.processingDate.includes("+") ? val.processingDate.split("+") : val.processingDate;
          val.processingDate = processingDate[0].trim().replaceAll("-","/")
          val.processingDate = Moment.utc(val.processingDate, "YYYY/MM/DD hh:mm:ss a").local().format("DD/MM/YYYY hh:mm:ss a");
          UserStore.addReportdata([val]);
          val.processingDate = localStorage.getItem("processingTime")
        }
        return val;
      })
      UserStore.setProcessGridData(data);
  }
  // -------------------End: Function: Close the remarks dialog box --------------------

  // ----------------------Start: Tooltips for highlighted boundary boxes on the isometric---------------------
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [open6, setOpen6] = useState(false);
  const [open7, setOpen7] = useState(false);
  const [open8, setOpen8] = useState(false);
  const [open9, setOpen9] = useState(false);
  const [open10, setOpen10] = useState(false);
  const [open11, setOpen11] = useState(false);
  const [open12, setOpen12] = useState(false);
  const [open13, setOpen13] = useState(false);
  const [open14, setOpen14] = useState(false);
  const [tooltipTitle, settooltipTitle] = useState("");

  const handleTooltipClose1 = () => {
    setOpen1(false);
  };

  const handleTooltipOpen1 = () => {
    setOpen1(true);
    let title = `${phrase1text}, ${p1score * 100}%`;
    settooltipTitle(`Text: ${title}`);
  };

  const handleTooltipClose2 = () => {
    setOpen2(false);
  };

  const handleTooltipOpen2 = () => {
    setOpen2(true);
    let title = `${phrase2text}, ${p2score * 100}%`;
    settooltipTitle(`Text: ${title}`);
  };

  const handleTooltipClose3 = () => {
    setOpen3(false);
  };

  const handleTooltipOpen3 = () => {
    setOpen3(true);
    let title = `${docNotext}, ${docnumscore * 100}%`;
    settooltipTitle(`Text: ${title}`);
  };

  const handleTooltipClose4 = () => {
    setOpen4(false);
  };

  const handleTooltipOpen4 = () => {
    setOpen4(true);
    let title = `${lineNotext}, ${linenumscore * 100}%`;
    settooltipTitle(`Text: ${title}`);
  };

  const handleTooltipClose5 = () => {
    setOpen5(false);
  };

  const navigatetoProcessgrid = () => {
    UserStore.cleareReportdata();
    UserStore.setreportStamp(false);
    localStorage.setItem('setreportStamp', false);
    props.history.push(`/Workspace/UploadFiles`);
  };

  const handleTooltipOpen5 = () => {
    setOpen5(true);
    let title = `${revNotext}, ${revnumscore * 100}%`;
    settooltipTitle(`Text: ${title}`);
  };

  const handleTooltipClose6 = () => {
    setOpen6(false);
  };

  const handleTooltipOpen6 = () => {
    setOpen6(true);
    let title = `${sheetNotext}, ${sheetnumscore * 100}%`;
    settooltipTitle(`Text: ${title}`);
  };

  const handleTooltipOpen7 = () => {
    setOpen7(true);
    let title = `${c1score * 100}%`;
    settooltipTitle(`${title}`);
  };
  const handleTooltipOpen8 = () => {
    setOpen8(true);
    let title = `${c2score * 100}%`;
    settooltipTitle(`${title}`);
  };
  const handleTooltipOpen9 = () => {
    setOpen9(true);
    let title = `${c3score * 100}%`;
    settooltipTitle(`${title}`);
  };
  const handleTooltipOpen10 = () => {
    setOpen10(true);
    let title = `${c4score * 100}%`;
    settooltipTitle(`${title}`);
  };
  const handleTooltipClose7 = () => {
    setOpen7(false);
  };
  const handleTooltipClose8 = () => {
    setOpen8(false);
  };
  const handleTooltipClose9 = () => {
    setOpen9(false);
  };
  const handleTooltipClose10 = () => {
    setOpen10(false);
  };

  const handleTooltipOpen11 = () => {
    setOpen11(true);
    let title = `${labelcheckboxtext1}, ${l1score * 100}%`;
    settooltipTitle(`Text: ${title}`);
  };
  const handleTooltipOpen12 = () => {
    setOpen12(true);
    let title = `${labelcheckboxtext2}, ${l2score * 100}%`;
    settooltipTitle(`Text: ${title}`);
  };
  const handleTooltipOpen13 = () => {
    setOpen13(true);
    let title = `${labelcheckboxtext3}, ${l3score * 100}%`;
    settooltipTitle(`Text: ${title}`);
  };
  const handleTooltipOpen14 = () => {
    setOpen14(true);
    let title = `${labelcheckboxtext4}, ${l4score * 100}%`;
    settooltipTitle(`Text: ${title}`);
  };

  const handleTooltipClose11 = () => {
    setOpen11(false);
  };

  const handleTooltipClose13 = () => {
    setOpen13(false);
  };

  const handleTooltipClose12 = () => {
    setOpen12(false);
  };

  const handleTooltipClose14 = () => {
    setOpen14(false);
  };
  // ----------------------End: Tooltips for highlighted boundary boxes on the isometric---------------------

  // ------------------------Start: Calculations to display bounding boxes over the isomteric ---------------------
  let inchToPxConverter = isPixels ? 1 : 96;
  setTimeout(() => {
    if (document.querySelector(".doc-viewer")) {
      setDocViewerWidth(document.querySelector(".doc-viewer").clientWidth);
      setDocViewerHeight(
        document.querySelector(".doc-viewer").clientHeight - 17
      );
    }
  }, 3000);
  
  let PDFwidth = pdfWidth * inchToPxConverter;
  let PDFheight = pdfHeight * inchToPxConverter;
  let top2 = ((phrase1BB[1] * inchToPxConverter) / PDFheight) * 100;
  if (DocViewerHeight > 500) {
    PDFheight = PDFheight + 10;
  }
  top2 = ((phrase1BB[1] * inchToPxConverter) / PDFheight) * 100;
  let top3 = ((phrase2BB[1] * inchToPxConverter) / PDFheight) * 100;
  let top4 = ((docNoBB[1] * inchToPxConverter) / PDFheight) * 100;
  let top5 = ((lineNoBB[1] * inchToPxConverter) / PDFheight) * 100;
  let top6;
  if (revLabel) {
    top6 = ((revNoBB[1] * inchToPxConverter) / PDFheight) * 100 + 1;
  } else {
    top6 = ((revNoBB[1] * inchToPxConverter) / PDFheight) * 100;
  }
  let top7 = ((sheetNoBB[1] * inchToPxConverter) / PDFheight) * 100;
  let top8 = ((checkbox1[1] * inchToPxConverter) / PDFheight) * 100;
  let top9 = ((checkbox2[1] * inchToPxConverter) / PDFheight) * 100;
  let top10 = ((checkbox3[1] * inchToPxConverter) / PDFheight) * 100;
  let top11 = ((checkbox4[1] * inchToPxConverter) / PDFheight) * 100;
  let top12 = ((labelcheckbox1[1] * inchToPxConverter) / PDFheight) * 100;
  let top13 = ((labelcheckbox2[1] * inchToPxConverter) / PDFheight) * 100;
  let top14 = ((labelcheckbox3[1] * inchToPxConverter) / PDFheight) * 100;
  let top15 = ((labelcheckbox4[1] * inchToPxConverter) / PDFheight) * 100;
  let left2 = ((phrase1BB[0] * inchToPxConverter) / PDFwidth) * 100;
  let left3 = ((phrase2BB[0] * inchToPxConverter) / PDFwidth) * 100;
  let left4 = ((docNoBB[0] * inchToPxConverter) / PDFwidth) * 100;
  let left5 = ((lineNoBB[0] * inchToPxConverter) / PDFwidth) * 100;
  let left6 = ((revNoBB[0] * inchToPxConverter) / PDFwidth) * 100;
  let left7 = ((sheetNoBB[0] * inchToPxConverter) / PDFwidth) * 100;
  let left8 = ((checkbox1[0] * inchToPxConverter) / PDFwidth) * 100;
  let left9 = ((checkbox2[0] * inchToPxConverter) / PDFwidth) * 100;
  let left10 = ((checkbox3[0] * inchToPxConverter) / PDFwidth) * 100;
  let left11 = ((checkbox4[0] * inchToPxConverter) / PDFwidth) * 100;
  let left12 = ((labelcheckbox1[0] * inchToPxConverter) / PDFwidth) * 100;
  let left13 = ((labelcheckbox2[0] * inchToPxConverter) / PDFwidth) * 100;
  let left14 = ((labelcheckbox3[0] * inchToPxConverter) / PDFwidth) * 100;
  let left15 = ((labelcheckbox4[0] * inchToPxConverter) / PDFwidth) * 100;
  let length2 = phrase1BB[2] - phrase1BB[0];
  let breadth2 = phrase1BB[5] - phrase1BB[3];
  let height2 = (((breadth2 * inchToPxConverter) / PDFheight) * 100);
  let width2 = ((length2 * inchToPxConverter) / PDFwidth) * 100;
  let length3 = phrase2BB[2] - phrase2BB[0];
  let breadth3 = phrase2BB[5] - phrase2BB[3];
  let height3 = (((breadth3 * inchToPxConverter) / PDFheight) * 100);
  let width3 = ((length3 * inchToPxConverter) / PDFwidth) * 100;
  let length4 = docNoBB[2] - docNoBB[0];
  let breadth4 = docNoBB[5] - docNoBB[3];
  let height4 = (((breadth4 * inchToPxConverter) / PDFheight) * 100);
  let width4 = ((length4 * inchToPxConverter) / PDFwidth) * 100;
  let length5 = lineNoBB[2] - lineNoBB[0];
  let breadth5 = lineNoBB[5] - lineNoBB[3];
  let height5 = (((breadth5 * inchToPxConverter) / PDFheight) * 100);
  let width5 = ((length5 * inchToPxConverter) / PDFwidth) * 100;
  let length6 = revNoBB[2] - revNoBB[0];
  let breadth6 = revNoBB[5] - revNoBB[3];
  let height6 = (((breadth6 * inchToPxConverter) / PDFheight) * 100);
  let width6 = ((length6 * inchToPxConverter) / PDFwidth) * 100;
  let length7 = sheetNoBB[2] - sheetNoBB[0];
  let breadth7 = sheetNoBB[5] - sheetNoBB[3];
  let height7 = (((breadth7 * inchToPxConverter) / PDFheight) * 100);
  let width7 = ((length7 * inchToPxConverter) / PDFwidth) * 100;
  let length8 = checkbox1[2] - checkbox1[0];
  let breadth8 = checkbox1[5] - checkbox1[3];
  let height8 = (((breadth8 * inchToPxConverter) / PDFheight) * 100);
  let width8 = ((length8 * inchToPxConverter) / PDFwidth) * 100;
  let length9 = checkbox2[2] - checkbox2[0];
  let breadth9 = checkbox2[5] - checkbox2[3];
  let height9 = (((breadth9 * inchToPxConverter) / PDFheight) * 100);
  let width9 = ((length9 * inchToPxConverter) / PDFwidth) * 100;
  let length10 = checkbox3[2] - checkbox3[0];
  let breadth10 = checkbox3[5] - checkbox3[3];
  let height10 = (((breadth10 * inchToPxConverter) / PDFheight) * 100);
  let width10 = ((length10 * inchToPxConverter) / PDFwidth) * 100;
  let length11 = checkbox4[2] - checkbox4[0];
  let breadth11 = checkbox4[5] - checkbox4[3];
  let height11 = (((breadth11 * inchToPxConverter) / PDFheight) * 100);
  let width11 = ((length11 * inchToPxConverter) / PDFwidth) * 100;
  let length12 = labelcheckbox1[2] - labelcheckbox1[0];
  let breadth12 = labelcheckbox1[5] - labelcheckbox1[3];
  let height12 = (((breadth12 * inchToPxConverter) / PDFheight) * 100);
  let width12 = ((length12 * inchToPxConverter) / PDFwidth) * 100;
  let length13 = labelcheckbox2[2] - labelcheckbox2[0];
  let breadth13 = labelcheckbox2[5] - labelcheckbox2[3];
  let height13 = (((breadth13 * inchToPxConverter) / PDFheight) * 100);
  let width13 = ((length13 * inchToPxConverter) / PDFwidth) * 100;
  let length14 = labelcheckbox3[2] - labelcheckbox3[0];
  let breadth14 = labelcheckbox3[5] - labelcheckbox3[3];
  let height14 = (((breadth14 * inchToPxConverter) / PDFheight) * 100);
  let width14 = ((length14 * inchToPxConverter) / PDFwidth) * 100;
  let length15 = labelcheckbox4[2] - labelcheckbox4[0];
  let breadth15 = labelcheckbox4[5] - labelcheckbox4[3];
  let height15 = (((breadth15 * inchToPxConverter) / PDFheight) * 100);
  let width15 = ((length15 * inchToPxConverter) / PDFwidth) * 100;

  let overlayStyle1 = {
    position: "absolute",
    top: (top2 / 100) * DocViewerHeight + 1 + "px",
    left: (left2 / 100) * DocViewerWidth + "px",
    backgroundColor: p1score > scoreCutoff ? "#F7E454" : "red",
    height: (height2 / 100) * DocViewerHeight + 3 + "px",
    width: (width2 / 100) * DocViewerWidth + "px",
    opacity: "0.35",
    zIndex: "9",
    cursor: "pointer",
  };

  let offset1 = (top2 / 100) * DocViewerHeight + 1 + "px";
  let offset2 = (left2 / 100) * DocViewerWidth + "px"
  // ------------------------End: Calculations to display bounding boxes over the isomteric ---------------------

  // ---------------------------Start: Overlay box css styles--------------------------
  let overlayStyle2 = {
    position: "absolute",
    top: (top3 / 100) * DocViewerHeight  + 1 + "px",
    left: (left3 / 100) * DocViewerWidth + "px",
    backgroundColor: p2score > scoreCutoff ? "#F7E454" : "red",
    height: (height3 / 100) * DocViewerHeight + 3 + "px",
    width: (width3 / 100) * DocViewerWidth + "px",
    opacity: "0.35",
    zIndex: "9",
    cursor: "pointer",
  };

  let overlayStyle3 = {
    position: "absolute",
    top: (top4 / 100) * DocViewerHeight + 1 + "px",
    left: (left4 / 100) * DocViewerWidth + "px",
    backgroundColor: docnumscore > scoreCutoff ? "#F7E454" : "red",
    height: (height4 / 100) * DocViewerHeight + 3 + "px",
    width: (width4 / 100) * DocViewerWidth + "px",
    opacity: "0.35",
    zIndex: "9",
    cursor: "pointer",
  };

  let overlayStyle4 = {
    position: "absolute",
    top: (top5 / 100) * DocViewerHeight + 1 + "px",
    left: (left5 / 100) * DocViewerWidth + "px",
    backgroundColor: linenumscore > scoreCutoff ? "#F7E454" : "red",
    height: (height5 / 100) * DocViewerHeight + 3 + "px",
    width: (width5 / 100) * DocViewerWidth + "px",
    opacity: "0.35",
    zIndex: "9",
    cursor: "pointer",
  };

  let overlayStyle5 = {
    position: "absolute",
    top: (top6 / 100) * DocViewerHeight + 1 + "px",
    left: (left6 / 100) * DocViewerWidth + "px",
    backgroundColor: revnumscore > scoreCutoff ? "#F7E454" : "red",
    height: (height6 / 100) * DocViewerHeight + 3 + "px",
    width: (width6 / 100) * DocViewerWidth + "px",
    opacity: "0.35",
    zIndex: "9",
    cursor: "pointer",
  };

  let overlayStyle6 = {
    position: "absolute",
    top: (top7 / 100) * DocViewerHeight + 1 + "px",
    left: (left7 / 100) * DocViewerWidth + "px",
    backgroundColor: sheetnumscore > scoreCutoff ? "#F7E454" : "red",
    height: (height7 / 100) * DocViewerHeight + 3 + "px",
    width: (width7 / 100) * DocViewerWidth + "px",
    opacity: "0.35",
    zIndex: "9",
    cursor: "pointer",
  };

  let overlayStyle7 = {
    position: "absolute",
    top: (top8 / 100) * DocViewerHeight + 1 + "px",
    left: (left8 / 100) * DocViewerWidth + "px",
    backgroundColor: c1score > scoreCutoff ? "#F7E454" : "red",
    height: (height8 / 100) * DocViewerHeight + 3 + "px",
    width: (width8 / 100) * DocViewerWidth + "px",
    opacity: "0.35",
    zIndex: "9",
    cursor: "pointer",
  };
  let overlayStyle8 = {
    position: "absolute",
    top: (top9 / 100) * DocViewerHeight + 1 + "px",
    left: (left9 / 100) * DocViewerWidth + "px",
    backgroundColor: c2score > scoreCutoff ? "#F7E454" : "red",
    height: (height9 / 100) * DocViewerHeight + 3 + "px",
    width: (width9 / 100) * DocViewerWidth + "px",
    opacity: "0.35",
    zIndex: "9",
    cursor: "pointer",
  };
  let overlayStyle9 = {
    position: "absolute",
    top: (top10 / 100) * DocViewerHeight + 1 + "px",
    left: (left10 / 100) * DocViewerWidth + "px",
    backgroundColor: c3score > scoreCutoff ? "#F7E454" : "red",
    height: (height10 / 100) * DocViewerHeight + 3 + "px",
    width: (width10 / 100) * DocViewerWidth + "px",
    opacity: "0.35",
    zIndex: "9",
    cursor: "pointer",
  };
  let overlayStyle10 = {
    position: "absolute",
    top: (top11 / 100) * DocViewerHeight + 1 + "px",
    left: (left11 / 100) * DocViewerWidth + "px",
    backgroundColor: c4score > scoreCutoff ? "#F7E454" : "red",
    height: (height11 / 100) * DocViewerHeight + 3 + "px",
    width: (width11 / 100) * DocViewerWidth + "px",
    opacity: "0.35",
    zIndex: "9",
    cursor: "pointer",
  };

  let overlayStyle11 = {
    position: "absolute",
    top: (top12 / 100) * DocViewerHeight + 1 + "px",
    left: (left12 / 100) * DocViewerWidth + "px",
    backgroundColor: l1score > scoreCutoff ? "#F7E454" : "red",
    height: (height12 / 100) * DocViewerHeight + 3 + "px",
    width: (width12 / 100) * DocViewerWidth + "px",
    opacity: "0.35",
    zIndex: "9",
    cursor: "pointer",
  };

  let overlayStyle12 = {
    position: "absolute",
    top: (top13 / 100) * DocViewerHeight + 1 + "px",
    left: (left13 / 100) * DocViewerWidth + "px",
    backgroundColor: l2score > scoreCutoff ? "#F7E454" : "red",
    height: (height13 / 100) * DocViewerHeight + 3 + "px",
    width: (width13 / 100) * DocViewerWidth + "px",
    opacity: "0.35",
    zIndex: "9",
    cursor: "pointer",
  };
  let overlayStyle13 = {
    position: "absolute",
    top: (top14 / 100) * DocViewerHeight + 1 + "px",
    left: (left14 / 100) * DocViewerWidth + "px",
    backgroundColor: l3score > scoreCutoff ? "#F7E454" : "red",
    height: (height14 / 100) * DocViewerHeight + 3 + "px",
    width: (width14 / 100) * DocViewerWidth + "px",
    opacity: "0.35",
    zIndex: "9",
    cursor: "pointer",
  };
  let overlayStyle14 = {
    position: "absolute",
    top: (top15 / 100) * DocViewerHeight + 1 + "px",
    left: (left15 / 100) * DocViewerWidth + "px",
    backgroundColor: l4score > scoreCutoff ? "#F7E454" : "red",
    height: (height15 / 100) * DocViewerHeight + 3 + "px",
    width: (width15 / 100) * DocViewerWidth + "px",
    opacity: "0.35",
    zIndex: "9",
    cursor: "pointer",
  };
  // ---------------------------End: Overlay box css styles--------------------------

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    validateEmpty();
  }

  function handleStampTypeChange() {
    setStampType(document.getElementById("stamp-type").value);
  }

  function handleFormValueChange() {
    setdocNotext(document.getElementById("doc-no").value);
    setlineNotext(document.getElementById("line-no").value);
    setsheetNotext(document.getElementById("sheet-no").value);
    setrevNotext(document.getElementById("revision-no").value);
    let modifiedDoc = document.getElementById("modified-doc").value;
    
    if (modifiedDoc.length > 5) {
      // value = value + 1;
      setErrorModifiedDoc(true);
      setErrorMsgModifiedDoc("Field size exceeded");
    } else if (
      /[!@#$%^&*()_+\-=\[\]{};':"\\|/\s/,.<>\/?]+/.test(modifiedDoc)
    ) {
      setErrorModifiedDoc(true);
      setErrorMsgModifiedDoc(
        "Special characters are not allowed"
      );
    }
    else {
      setmodifieddoctext(modifiedDoc);
      setErrorModifiedDoc(false);
      setErrorMsgModifiedDoc("");
    }
  }
  return (
    <div>
      <AppHeader />
      <Breadcrumbs />
      <div
        className="accepted-message-wrapper"
        style={accept === true ? { display: "flex" } : { display: "none" }}
      >
        <span className="accepted-message">
          {message}          
        </span>
      </div>
      <div
        className="accepted-message-wrapper"
        style={acceptit === true ? { display: "flex" } : { display: "none" }}
      >
        <span className="accepted-message">
          {message1}          
        </span>
      </div>
      <div className="btn-bar">
        <div className="back" onClick={navigatetoProcessgrid}>
          <img src="/images/backBlack.svg" className="back-icon" />
          <span className="back-text">Back</span>
        </div>
        <div className="download-isometric-report">
          <ExportExcel
            className="preview-text"
            id={reportdata}
            fileName={"ProcessedIsometricReport"}
            flag={2}
            fromPreview={true}
          >
            Download Report
          </ExportExcel>
        </div>
      </div>
      <div className="preview-container">
      <TransformWrapper defaultScale={1} defaultPositionX={100} defaultPositionY={100} panning={{excluded: ['Tooltip','ClickAwayListener' ]}}>
        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
          <React.Fragment>
          <div className="zoom">
            <span onClick={() => zoomIn()} className="zoomIn" title="Zoom In"><img src="/images/zoomin.svg" className="tool-icon"/></span>
            <span onClick={() => zoomOut()} className="zoomOut" title= "Zoom Out"><img src="/images/zoomout.svg" className="tool-icon" /></span>
            <span onClick={() => resetTransform()} className="resetZoom" title="Reset Zoom"><img src="/images/expandBlack.svg" /></span>
          </div>
        <div className="preivew-wrapper">
                <div className="doc-viewer">
                  <TransformComponent>
                    <Document file={filepath} onLoadSuccess={onDocumentLoadSuccess} >
                      <Page pageNumber={pageNumber} height={changeheight} width={changewidth} scale={10} />
                    </Document>
                    <ClickAwayListener onClickAway={handleTooltipClose1}>
                        <div>
                          <Tooltip
                            // PopperProps={{ disablePortal: true }}
                            onClose={handleTooltipClose1}
                            open={open1}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            arrow
                            placement="top"
                            title={tooltipTitle}
                            PopperProps={{
                              disablePortal: false,
                              popperOptions: {
                                modifiers: {
                                  offset: {
                                    enabled: true,
                                  },
                                },
                              },
                            }}
                          >
                          <div
                            className="overlay-container"
                            style={overlayStyle1}
                            onClick={handleTooltipOpen1}
                          ></div>
                        </Tooltip>
                      </div>
                    </ClickAwayListener>
                    <ClickAwayListener onClickAway={handleTooltipClose2}>
                      <div>
                        <Tooltip
                          // PopperProps={{ disablePortal: true }}
                          onClose={handleTooltipClose2}
                          open={open2}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          arrow
                          placement="top"
                          title={tooltipTitle}
                          PopperProps={{
                            disablePortal: false,
                            popperOptions: {
                              modifiers: {
                                offset: {
                                  enabled: true,
                                },
                              },
                            },
                          }}
                        >
                          <div
                            className="overlay-container"
                            style={overlayStyle2}
                            onClick={handleTooltipOpen2}
                          ></div>
                        </Tooltip>
                      </div>
                    </ClickAwayListener>
                    <ClickAwayListener onClickAway={handleTooltipClose3}>
                      <div>
                        <Tooltip
                          // PopperProps={{ disablePortal: true }}
                          onClose={handleTooltipClose3}
                          open={open3}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          arrow
                          placement="top"
                          title={tooltipTitle}
                          PopperProps={{
                            disablePortal: false,
                            popperOptions: {
                              modifiers: {
                                offset: {
                                  enabled: true,
                                },
                              },
                            },
                          }}
                        >
                          <div
                            className="overlay-container"
                            style={overlayStyle3}
                            onClick={handleTooltipOpen3}
                          ></div>
                        </Tooltip>
                      </div>
                    </ClickAwayListener>
                    <ClickAwayListener onClickAway={handleTooltipClose4}>
                      <div>
                        <Tooltip
                          // PopperProps={{ disablePortal: true }}
                          onClose={handleTooltipClose4}
                          open={open4}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          arrow
                          placement="top"
                          title={tooltipTitle}
                          PopperProps={{
                            disablePortal: false,
                            popperOptions: {
                              modifiers: {
                                offset: {
                                  enabled: true,
                                },
                              },
                            },
                          }}
                        >
                          <div
                            className="overlay-container"
                            style={overlayStyle4}
                            onClick={handleTooltipOpen4}
                          ></div>
                        </Tooltip>
                      </div>
                    </ClickAwayListener>
                    <ClickAwayListener onClickAway={handleTooltipClose5}>
                      <div>
                        <Tooltip
                          // PopperProps={{ disablePortal: true }}
                          onClose={handleTooltipClose5}
                          open={open5}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          arrow
                          placement="top"
                          title={tooltipTitle}
                          PopperProps={{
                            disablePortal: false,
                            popperOptions: {
                              modifiers: {
                                offset: {
                                  enabled: true,
                                },
                              },
                            },
                          }}
                        >
                          <div
                            className="overlay-container"
                            style={overlayStyle5}
                            onClick={handleTooltipOpen5}
                          ></div>
                        </Tooltip>
                      </div>
                    </ClickAwayListener>
                    <ClickAwayListener onClickAway={handleTooltipClose6}>
                      <div>
                        <Tooltip
                          // PopperProps={{ disablePortal: true }}
                          onClose={handleTooltipClose6}
                          open={open6}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          arrow
                          placement="top"
                          title={tooltipTitle}
                          PopperProps={{
                            disablePortal: false,
                            popperOptions: {
                              modifiers: {
                                offset: {
                                  enabled: true,
                                },
                              },
                            },
                          }}
                        >
                          <div
                            className="overlay-container"
                            style={overlayStyle6}
                            onClick={handleTooltipOpen6}
                          ></div>
                        </Tooltip>
                      </div>
                    </ClickAwayListener>
                    <ClickAwayListener onClickAway={handleTooltipClose7}>
                      <div>
                        <Tooltip
                          // PopperProps={{ disablePortal: true }}
                          onClose={handleTooltipClose7}
                          open={open7}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          arrow
                          placement="top"
                          title={tooltipTitle}
                          PopperProps={{
                            disablePortal: false,
                            popperOptions: {
                              modifiers: {
                                offset: {
                                  enabled: true,
                                },
                              },
                            },
                          }}
                        >
                          <div
                            className="overlay-container"
                            style={overlayStyle7}
                            onClick={handleTooltipOpen7}
                          ></div>
                        </Tooltip>
                      </div>
                    </ClickAwayListener>
                    <ClickAwayListener onClickAway={handleTooltipClose8}>
                      <div>
                        <Tooltip
                          // PopperProps={{ disablePortal: true }}
                          onClose={handleTooltipClose8}
                          open={open8}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          arrow
                          placement="top"
                          title={tooltipTitle}
                          PopperProps={{
                            disablePortal: false,
                            popperOptions: {
                              modifiers: {
                                offset: {
                                  enabled: true,
                                },
                              },
                            },
                          }}
                        >
                          <div
                            className="overlay-container"
                            style={overlayStyle8}
                            onClick={handleTooltipOpen8}
                          ></div>
                        </Tooltip>
                      </div>
                    </ClickAwayListener>
                    <ClickAwayListener onClickAway={handleTooltipClose9}>
                      <div>
                        <Tooltip
                          // PopperProps={{ disablePortal: true }}
                          onClose={handleTooltipClose9}
                          open={open9}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          arrow
                          placement="top"
                          title={tooltipTitle}
                          PopperProps={{
                            disablePortal: false,
                            popperOptions: {
                              modifiers: {
                                offset: {
                                  enabled: true,
                                },
                              },
                            },
                          }}
                        >
                          <div
                            className="overlay-container"
                            style={overlayStyle9}
                            onClick={handleTooltipOpen9}
                          ></div>
                        </Tooltip>
                      </div>
                    </ClickAwayListener>
                    <ClickAwayListener onClickAway={handleTooltipClose10}>
                      <div>
                        <Tooltip
                          // PopperProps={{ disablePortal: true }}
                          onClose={handleTooltipClose10}
                          open={open10}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          arrow
                          placement="top"
                          title={tooltipTitle}
                          PopperProps={{
                            disablePortal: false,
                            popperOptions: {
                              modifiers: {
                                offset: {
                                  enabled: true,
                                },
                              },
                            },
                          }}
                        >
                          <div
                            className="overlay-container"
                            style={overlayStyle10}
                            onClick={handleTooltipOpen10}
                          ></div>
                        </Tooltip>
                      </div>
                    </ClickAwayListener>
                    <ClickAwayListener onClickAway={handleTooltipClose11}>
                      <div>
                        <Tooltip
                          // PopperProps={{ disablePortal: true }}
                          onClose={handleTooltipClose11}
                          open={open11}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          arrow
                          placement="top"
                          title={tooltipTitle}
                          PopperProps={{
                            disablePortal: false,
                            popperOptions: {
                              modifiers: {
                                offset: {
                                  enabled: true,
                                },
                              },
                            },
                          }}
                        >
                          <div
                            className="overlay-container"
                            style={overlayStyle11}
                            onClick={handleTooltipOpen11}
                          ></div>
                        </Tooltip>
                      </div>
                    </ClickAwayListener>
                    <ClickAwayListener onClickAway={handleTooltipClose12}>
                      <div>
                        <Tooltip
                          // PopperProps={{ disablePortal: true }}
                          onClose={handleTooltipClose12}
                          open={open12}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          arrow
                          placement="top"
                          title={tooltipTitle}
                          PopperProps={{
                            disablePortal: false,
                            popperOptions: {
                              modifiers: {
                                offset: {
                                  enabled: true,
                                },
                              },
                            },
                          }}
                        >
                          <div
                            className="overlay-container"
                            style={overlayStyle12}
                            onClick={handleTooltipOpen12}
                          ></div>
                        </Tooltip>
                      </div>
                    </ClickAwayListener>
                    <ClickAwayListener onClickAway={handleTooltipClose13}>
                      <div>
                        <Tooltip
                          // PopperProps={{ disablePortal: true }}
                          onClose={handleTooltipClose13}
                          open={open13}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          arrow
                          placement="top"
                          title={tooltipTitle}
                          PopperProps={{
                            disablePortal: false,
                            popperOptions: {
                              modifiers: {
                                offset: {
                                  enabled: true,
                                },
                              },
                            },
                          }}
                        >
                          <div
                            className="overlay-container"
                            style={overlayStyle13}
                            onClick={handleTooltipOpen13}
                          ></div>
                        </Tooltip>
                      </div>
                    </ClickAwayListener>
                    <ClickAwayListener onClickAway={handleTooltipClose14}>
                      <div>
                        <Tooltip
                          // PopperProps={{ disablePortal: true }}
                          onClose={handleTooltipClose14}
                          open={open14}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          arrow
                          placement="top"
                          title={tooltipTitle}
                          PopperProps={{
                            disablePortal: false,
                            popperOptions: {
                              modifiers: {
                                offset: {
                                  enabled: true,
                                },
                              },
                            },
                          }}
                        >
                          <div
                            className="overlay-container"
                            style={overlayStyle14}
                            onClick={handleTooltipOpen14}
                          ></div>
                        </Tooltip>
                      </div>
                    </ClickAwayListener>
                  </TransformComponent>
                </div>
        </div>
        </React.Fragment>
            )}
          </TransformWrapper>
        <div className="details-container">
          <div className="details-wrapper">
            <span className="details-text">
              Please validate the extracted details
            </span>
            <br />
            <div className="field-row1">
            <div class="stamp-type-dropdown-wrapper">
              <label for="stamp-type" style={{ marginBottom: "0.5vw", width: '100%' }}>
                <span className="field-text">ISOMETRIC DETECTION</span>
              </label>
              {showUser ? (
                <select
                  id="stamp-type"
                  name="stamp-type"
                  className="input-field"
                  value={stampType}
                  onChange={handleStampTypeChange}
                  style={{ fontSize: "0.83vw", width: '100%' }}
                  disabled={(readonly || acceptanceStatus) ? true : false}
                >
                  <option value="false">No Change</option>
                  <option value="true">Change</option>
                </select>
              ) : (
                <select
                  id="stamp-type"
                  name="stamp-type"
                  className="input-field"
                  value={stampType}
                  onChange={handleStampTypeChange}
                  disabled={(readonly || acceptanceStatus) ? true : false}
                  style={{ fontSize: "0.83vw", width: '100%' }}
                >
                  <option value="false">No Change</option>
                  <option value="true">Change</option>
                </select>
              )}
            </div>
            <div className="modified-doc-wrapper">
                <label for="modified-doc" style={{ marginBottom: "0.5vw", width: '100%' }}>
                  <span className="field-text">MODIFIED DOCUMENT</span>
                </label>
                <TextField
                  style={{
                    margin: "0.15vw",
                    height: "2.08vw",
                    fontSize: "0.833vw",
                    width: '100%'
                  }}
                  type="text"
                  id="modified-doc"
                  className={classes.input}
                  value={modifieddoctext}
                  onChange={handleFormValueChange}
                  error={errorModifiedDoc}
                  helperText={errorMsgModifiedDoc}
                  placeholder="Type upto 5 characters"
                  InputProps={{
                    disableUnderline: true,
                    maxLength: 5
                  }}
                  disabled={(readonly || acceptanceStatus) ? true : false}
                  // style={{ height: "2.08vw", margin: "0", width: '100%',fontSize: "0.833vw", }}
                  onInput = {(e) =>{
                    e.target.value = e.target.value.slice(0,5)
                }}
                />
            </div>
            </div>
            <div className="first-row">
              <div className="doc-no-wrapper">
                <label for="doc-no" style={{ marginBottom: "0.3vw", width: '100%' }}>
                  <span className="field-text">DOC. NO./ ISOMETRIC NUMBER</span>
                </label>
                {showUser ? (
                  <TextField
                    type="text"
                    id="doc-no"
                    className={classes.input}
                    value={docNotext}
                    onChange={handleFormValueChange}
                    error={errorIsometricNumber}
                    helperText={errorMsgIsometricNumber}
                    placeholder="Type Doc No./Isometric No."
                    InputProps={{
                      disableUnderline: true,
                    }}
                    disabled={(readonly || acceptanceStatus) ? true : false}
                    style={
                      docNotext === ""
                        ? {
                            border: "2px solid yellow",
                            height: "2.08vw",
                            padding: "1%",
                            margin: "0.15vw",
                            width: '100%',
                            fontSize: "0.83vw",
                          }
                        : { height: "2.08vw", padding: "1%", margin: "0.15vw", width: '100%',  fontSize: "0.83vw", }
                    }
                  />
                ) : (
                  <TextField
                    type="text"
                    id="doc-no"
                    className={classes.input}
                    value={docNotext}
                    onChange={handleFormValueChange}
                    error={errorIsometricNumber}
                    helperText={errorMsgIsometricNumber}
                    placeholder="Type Doc No./Isometric No."
                    InputProps={{
                      disableUnderline: true,
                    }}
                    disabled={(readonly || acceptanceStatus) ? true : false}
                    style={
                      docNotext === ""
                        ? {
                            border: "2px solid yellow",
                            height: "2.08vw",
                            padding: "1%",
                            margin: "0.15vw",
                            fontSize: "0.83vw",
                          }
                        : { height: "2.08vw", padding: "1%", margin: "0.15vw", width: '100%', fontSize: "0.83vw", }
                    }
                  />
                )}
              </div>
              <div className="line-no-wrapper">
                <label for="line-no" style={{ marginBottom: "0.3vw", width: "100%", marginLeft: '0.15vw' }}>
                  <span className="field-text">LINE NUMBER</span>
                </label>
                <TextField
                  type="text"
                  id="line-no"
                  className={classes.input}
                  value={lineNotext}
                  onChange={handleFormValueChange}
                  error={errorLineNumber}
                  helperText={errorMsgLineNumber}
                  placeholder="Type Line No."
                  InputProps={{
                    disableUnderline: true,
                  }}
                  disabled={(readonly || acceptanceStatus) ? true : false}
                  style={
                    lineNotext === ""
                      ? {
                          border: "2px solid yellow",
                          height: "2.08vw",
                          padding: "1%",
                          margin: "0.15vw",
                          width: "100%",
                          fontSize: "0.83vw",
                        }
                      : { height: "2.08vw", padding: "1%", margin: "0.15vw",width: "100%", fontSize: "0.83vw", }
                  }
                />
              </div>
            </div>
            <div className="second-row">
              <div className="sheet-no-wrapper">
                <label for="sheet-no" style={{ marginBottom: "0.3vw", width: "100%" }}>
                  <span className="field-text">SHEET NUMBER</span>
                </label>
                <TextField
                  type="text"
                  id="sheet-no"
                  className={classes.input}
                  value={sheetNotext}
                  onChange={handleFormValueChange}
                  error={errorSheetNumber}
                  helperText={errorMsgSheetNumber}
                  placeholder="Type Sheet No."
                  InputProps={{
                    disableUnderline: true,
                  }}
                  disabled={(readonly || acceptanceStatus) ? true : false}
                  style={
                    sheetNotext === ""
                      ? {
                          border: "2px solid yellow",
                          height: "2.08vw",
                          padding: "1%",
                          margin: "0.15vw",
                          width: "100%",
                          fontSize: "0.83vw",
                        }
                      : { height: "2.08vw", padding: "1%", margin: "0.15vw",width: "100%", fontSize: "0.83vw", }
                  }
                />
              </div>
              <div className="revision-no-wrapper">
                <label for="revision-no" style={{ marginBottom: "0.5vw",width: "100%", marginLeft: "0.15vw" }}>
                  <span className="field-text">REVISION NUMBER</span>
                </label>
                <TextField
                  type="text"
                  id="revision-no"
                  className={classes.input}
                  value={revNotext}
                  onChange={handleFormValueChange}
                  error={errorRevisioNumber}
                  helperText={errorMsgRevisioNumber}
                  placeholder="Type Revision No."
                  InputProps={{
                    disableUnderline: true,
                  }}
                  disabled={(readonly || acceptanceStatus) ? true : false}
                  style={
                    revNotext === ""
                      ? {
                          border: "2px solid yellow",
                          height: "2.08vw",
                          padding: "1%",
                          margin: "0.15vw",
                          width: "100%",
                          fontSize: "0.833vw",
                        }
                      : { height: "2.08vw", margin: "0",width: "100%", marginLeft: "0.15vw",fontSize: "0.833vw",}
                  }
                />
              </div>
            </div>
            <br></br>
            {(!readonly) && (
              <div className="third-row">
                <DialogActions>
                  <Button
                    color="primary"
                    name="save"
                    className="accept-btn"
                    onClick={(e) => {acceptMsg("save");}}                    
                    style={
                      (accept || acceptanceStatus || readonly) ? { display: "none" } : { display: "block",background: "#0C629B 0% 0% no-repeat padding-box",textTransform: "capitalize",
                      background: "#0C629B 0% 0% no-repeat padding-box",
                      border: "1px solid #0C629B",
                      borderRadius: "4px",
                      color: "#FFFFFF",
                      width: "8.4vw",
                      height: "2.29vw",
                      marginRight: "1.3vw",
                      font: "normal normal normal 0.78vw Helvetica",                      
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    color="primary"
                    className="accept-btn"
                    name="Accept"
                    onClick={(e) => {acceptMsg("Accept");}}                    
                    style={
                      (accept || acceptanceStatus || readonly) ? { display: "none" } : { display: "block",background: "#0C629B 0% 0% no-repeat padding-box",textTransform: "capitalize",
                      border: "1px solid #0C629B",
                      borderRadius: "4px",
                      color: "#FFFFFF",
                      width: "8.4vw",
                      height: "2.29vw",
                      marginRight: "1.3vw",
                      font: "normal normal normal 0.78vw Helvetica", }
                    }
                  >
                    Accept
                  </Button>
                  <Button
                    color="primary"
                    className="reject-btn"
                    onClick={handleShow}
                    style={
                      (accept || acceptanceStatus || readonly) ? { display: "none" } : { display: "block",background: "#0C629B 0% 0% no-repeat padding-box",textTransform: "capitalize",
                      border: "1px solid #0C629B",
                      borderRadius: "4px",
                      width: "8.4vw",
                      height: "2.29vw",
                      color: "#FFFFFF",
                      font: "normal normal normal 0.78vw Helvetica",
                    }}
                  >
                    Reject
                  </Button>
                </DialogActions>
              </div>
            )}
          </div>
        </div>
        <Modal
          className="rejectRemarks"
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          dialogClassName="fullmodal"
          size="l"
          centered
        >
          <Modal.Body>
            <label>Enter Remarks</label>
            <input type="textarea" name="textValue" id="remarks-field" />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={handleCloseAPI}
              className="next-btn"
              style={{font: "normal normal normal 0.78vw Helvetica",textTransform: "capitalize"}}
            >
              Submit
            </Button>
            <Button
              variant="secondary"
              className="cancel-btn"
              onClick={handleClose}
              style={{
                width: "6em !important",
                background: "#FFFFFF !important",
                border: "1px solid #0C629B !important",
                borderRadius: "4px",
                color: "#0C629B !important",
                font: "normal normal normal 0.78vw Helvetica",
                textTransform: "capitalize"
              }}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        <AppFooter />
      </div>
    </div>
  );
}

export default observer(Preview);
