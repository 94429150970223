import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import Cancel from "@material-ui/icons/Cancel";
import CircleChecked from "@material-ui/icons/CheckCircleRounded";
import DialogContent from "@material-ui/core/DialogContent";
import InputLabel from "@material-ui/core/InputLabel";
import ProcessGridTable from "../../UploadPID/Process/ProcessGrid/ProcessGridTable";
import DialogTitle from "@material-ui/core/DialogTitle";
import axios from "axios";
import AppHeader from "../../AppHeader/AppHeader";
import Tooltip from "@material-ui/core/Tooltip";
import AppFooter from "../../AppFooter/AppFooter";
import BreadCrumbs from "../../Upload/BreadCrumbs/BreadCrumbs";
import UserStore from "../../UserStore";
import { toJS, observer } from "mobx";
import Moment from "moment";
import TextFieldMui from "@material-ui/core/TextField";
import { withStyles, createStyles } from "@material-ui/core/styles";
import FormHelperText from "@material-ui/core/FormHelperText";
import "./EditConfiguration.scss"
// Importing toastify module
import { toast } from "react-toastify";

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";

// toast-configuration method,
// it is compulsory method.
toast.configure();

const { BlockBlobClient } = require("@azure/storage-blob");
const API_Endpoint = process.env.REACT_APP_API_ENDPOINT;

// which user has logged in to application
const styles = (muiTheme) => ({
  label: {
    "&$erroredLabel": {
      color: "black",
    },
  },
  erroredLabel: {},
});

const TextField = withStyles(styles)(function TextField({ classes, ...props }) {
  return (
    <TextFieldMui
      InputLabelProps={{
        classes: {
          root: classes.label,
          error: classes.erroredLabel,
        },
      }}
      {...props}
    />
  );
});
export class EditConfiguration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
      result: {},
      configtype: [],
      myrow: [],
      myrowedit: [],
      delete: false,
      setShow: false,
      setShow1: false,
      deleteid: "",
      showForm: false,
      userFlag: true,
      editProducts: [],
      phraseMessage: "",
      phraseError: false,
      confidId:1,
      modalId: "",
      newrow: {        
        key1: "",
        key2: "",
        value: "",
        remarks: "",
        
      },
      products: [],
      reportDataexcel: [],
      newWorkspaceId:
        UserStore.newWorkspaceId !== 0
          ? UserStore.newWorkspaceId
          : localStorage.getItem("newWorkspaceId")
          ? JSON.parse(localStorage.getItem("newWorkspaceId"))
          : 0,
      editworkspace: localStorage.getItem("Editworkspace")
        ? JSON.parse(localStorage.getItem("Editworkspace"))
        : UserStore.editworkspace,
    };
    UserStore.setConfigId(1);
    if (!toJS(UserStore.editRow).length && localStorage.getItem("EditWSData")) {
      UserStore.addRowdata(JSON.parse(localStorage.getItem("EditWSData")));
    }
    if (
      UserStore.NewworkspaceName === "" &&
      localStorage.getItem("SetNewworkspaceName")
    ) {
      UserStore.SetNewworkspaceName(
        localStorage.getItem("SetNewworkspaceName")
      );
    }
    if (
      UserStore.NewProjectName === "" &&
      localStorage.getItem("SetNewProjectName")
    ) {
      UserStore.SetNewProjectName(localStorage.getItem("SetNewProjectName"));
    }
    UserStore.setEditConfigData({});
  }

  async componentDidMount() {
    try {
      const workspaceId = UserStore.PIDworkspaceID;
      const dropDownValuesproposal = await axios
        .get(`${API_Endpoint}/GetPidConfigTypes`)
        .then((res) => {
          if (res.status === 200) {
            this.state.configtype.push(res.data.result);
            this.setState({
              configType: res.data.result,
            });
          } else if (
            res.status === 400 ||
            res.status === 401 ||
            res.status === 404 ||
            res.status === 408
          ) {
            toast.error("service error", {
              // Set to 5sec
              position: toast.POSITION.TOP_LEFT,
              autoClose: 5000,
            });
          }
        });
      UserStore.inBusinessrule = true;
      axios
        .get(
          //(API_Endpoint + `/GetWorkSpaces`)
          API_Endpoint +
            `/GetPidConfiguration?projectWorkSpaceId=${workspaceId}&configTypeId=${toJS(UserStore.confidId)}`
        )
        .then((res) => {
          const rows1 = res.data;
          if (res.status === 200) {
            //toast.success("not successful", { autoClose: 3000 });
          }

          if (rows1) {
            rows1.map((items, index) => {
              const row2 = {
                configId: items.configId,
                key1: items.key1,
                key2: items.key2,
                value: items.value,
                remarks: items.remarks,
                More: (
                  <div
                    classname="more-actions-wrapper"
                    style={{ display: "inline-flex" }}
                  >
                    <Tooltip title=" Edit Business Rule" arrow>
                      <div
                        classname="edit-icon"
                        onClick={ this.handleEdit}
                        style={{ marginLeft: "-40%", cursor: "pointer" }}
                      >
                        <img
                          id={items.businessRuleId}
                          src="/images/icon_edit.svg"
                        />
                      </div>
                    </Tooltip>
                    <Tooltip title=" Delete Business Rule" arrow>
                      <div
                        classname="delete-icon"
                        style={{ marginLeft: "70%", cursor: "pointer" }}
                        onClick={
                          this.state.userFlag
                            ? (e) => {
                                this.setState({
                                  delete: true,
                                  deleteid: items.businessRuleId,
                                });
                              }
                            : ""
                        }
                      >
                        <img
                          id={items.businessRuleId}
                          src="/images/icon_delete.svg"
                        />
                      </div>
                    </Tooltip>
                  </div>
                ),
              };
              this.state.myrow.push(row2);
            });
            this.setState({
              count: 1,
            });
          }

          this.setState({
            products: this.state.myrow,
          });
          UserStore.setEditConfigData(this.state.myrow);
        });
    } catch (error) {
      // Error 😨
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */
        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        // console.log(error.request);
      } else {
        // Something happened in setting up the request and triggered an Error
        // console.log("Error", error.message);
      }
    }
  }

  handleRowSelection = ()=>
  {
    this.refreshdata();
  }

  refreshdata = async() => {
    this.setState({
      products : [],
      myrow: []
    });
    UserStore.setEditConfigData({});
    const workspaceId2 = UserStore.PIDworkspaceID;
    axios
        .get(
          API_Endpoint +
            `/GetPidConfiguration?projectWorkSpaceId=${workspaceId2}&configTypeId=${toJS(UserStore.confidId)}`
        )
        .then((res) => {
          const rows1 = res.data;
          if (res.status === 200) {
          }

          if (rows1) {
            rows1.map((items, index) => {
              const row2 = {
                configId: items.configId,
                key1: items.key1,
                key2: items.key2,
                value: items.value,
                remarks: items.remarks,
                More: (
                  <div
                    classname="more-actions-wrapper"
                    style={{ display: "inline-flex" }}
                  >
                    <Tooltip title=" Edit Business Rule" arrow>
                      <div
                        classname="edit-icon"
                        onClick={this.state.userFlag ? this.handleEdit : ""}
                        style={{ marginLeft: "-40%", cursor: "pointer" }}
                      >
                        <img
                          id={items.confidId}
                          src="/images/icon_edit.svg"
                        />
                      </div>
                    </Tooltip>
                    <Tooltip title=" Delete Business Rule" arrow>
                      <div
                        classname="delete-icon"
                        style={{ marginLeft: "70%", cursor: "pointer" }}
                        onClick={
                          this.state.userFlag
                            ? (e) => {
                                this.setState({
                                  delete: true,
                                  deleteid: items.confidId,
                                });
                              }
                            : ""
                        }
                      >
                        <img
                          id={items.confidId}
                          src="/images/icon_delete.svg"
                        />
                      </div>
                    </Tooltip>
                  </div>
                ),
              };
              this.state.myrow.push(row2);
            });
            this.setState({
              count: 1,
            });
          }

          this.setState({
            products: this.state.myrow,
          });
          UserStore.setEditConfigData(this.state.myrow);
        });
  }

  handleEdit = (e) => {
    let id = e.target.id;
    this.setState({
      setShow: true,
      modalId: id,
    });
    const data = this.state.products
      .filter((item) => item.businessRuleId == id)
      .map(
        ({
          id,
          businessRuleId,
          phrase1,
          phrase2,
          phrase3,
          phrase4,
          Change,
        }) => ({
          id,
          businessRuleId,
          phrase1,
          phrase2,
          phrase3,
          phrase4,
          Change,
        })
      );
    data[0]["Change"] = data[0].Change === "Change" ? true : false;
    this.setState({
      editProducts: data,
    });
    this.refreshdata();
  };

  addRow1 = (e) => {
    this.setState({
      newrow: {
        id: "",
        workSpaceName: "",
        projectName: "",
        phrase1: "",
        phrase2: "",
        phrase3: "",
        phrase4: "",
        ChangeOutcome: "",
      },
    });
    this.setState({
      setShow1: true,
      //modalId: id
    });
  };

  addRow = (event) => {
    if (event.target.name === "key1") {
      this.state.newrow.key1 = event.target.value;
    } else if (event.target.name == "key2") {
      this.state.newrow.key2 = event.target.value;
    } else if (event.target.name == "value") {
      this.state.newrow.value = event.target.value;
    } else if (event.target.name == "remarks"){
      this.state.newrow.remarks = event.target.value;
    }
    this.setState({ phraseError: false, phraseMessage: "" });
    
  };

  rowSave = async () => {
    const workspaceId1 = UserStore.PIDworkspaceID;
   
      var data = {
        projectWorkSpaceId:workspaceId1,
        configTypeId:toJS(UserStore.confidId),
        key1: this.state.newrow.key1,
        key2: this.state.newrow.key2,
        value: this.state.newrow.value,
        remarks: this.state.newrow.remarks,        
      };
    
     const res = await axios.post(
        API_Endpoint + `/AddPidConfiguration`,
        data
      );
      this.setState({
        newrow: {},
        setShow1: false,
      });
      this.refreshdata();
     
  };
  handleClose = () => {
    this.setState({ setShow: false });
  };

  handleCloserow = () => {
    this.setState({
      setShow1: false,
      newrow: {
        id: "",
        workSpaceName: "",
        projectName: "",
        phrase1: "",
        phrase2: "",
        phrase3: "",
        phrase4: "",
        ChangeOutcome: "",
      },
    });
  };

  handleShow = () => {
    this.setState({ setShow: true });
  };

  handleEditSave = async () => {
    // send the output data to the service
    const productsList = this.state.products;
    
    if (this.state.editworkspace === false) {
      var data = {
        ProjectName: UserStore.NewProjectName,
        WorkSpaceName: UserStore.NewworkspaceName,
        WorkSpaceId: this.state.newWorkspaceId,
        BusinessRuleId: this.state.editProducts[0].businessRuleId,
        phrase1: this.state.editProducts[0].phrase1,
        phrase2: this.state.editProducts[0].phrase2,
        phrase3: this.state.editProducts[0].phrase3,
        phrase4: this.state.editProducts[0].phrase4,

        Change: this.state.editProducts[0].Change,
      };
    } else {
      var data = {
        WorkSpaceName: toJS(UserStore.editRow[0].workSpaceName),
        projectName: toJS(UserStore.editRow[0].projectName),
        WorkSpaceId: toJS(UserStore.editRow[0].workSpaceId),
        BusinessRuleId: this.state.editProducts[0].businessRuleId,
        phrase1: this.state.editProducts[0].phrase1,
        phrase2: this.state.editProducts[0].phrase2,
        phrase3: this.state.editProducts[0].phrase3,
        phrase4: this.state.editProducts[0].phrase4,
        Change: this.state.editProducts[0].Change,
      };
    }

    if (data.phrase1 === "") {
      if (data.phrase1 === "")
        this.setState({
          phraseError: true,
          phraseMessage: "Phrase1 should not be Empty",
        });
    } else {
      this.setState({
        products: [],
        phraseError: false,
        phraseMessage: "",
      });
      UserStore.setEditConfigData({});
      let res = await axios.post(
        API_Endpoint + `/UpdateBusinessRule`,
        data
      );
      res.data.businessRules.map((items, index) => {
        const row2 = {
          businessRuleId: items.businessRuleId,
          id: items.workSpaceId,
          workSpaceName: items.workSpaceName,
          projectName: items.projectName,
          phrase1: items.phrase1,
          phrase2: items.phrase2,
          phrase3: items.phrase3,
          phrase4: items.phrase4,
          Change: items.change ? "Change" : "No Change",
          More: (
            <div
              classname="more-actions-wrapper"
              style={{ display: "inline-flex" }}
            >
              <Tooltip title="Edit Business Rule" arrow>
                <div
                  classname="edit-icon"
                  onClick={this.handleEdit}
                  style={{ marginLeft: "-40%", cursor: "pointer" }}
                >
                  <img id={items.businessRuleId} src="/images/icon_edit.svg" />
                </div>
              </Tooltip>
              <Tooltip title="Delete Business Rule" arrow>
                <div
                  classname="delete-icon"
                  style={{ marginLeft: "70%", cursor: "pointer" }}
                  onClick={
                    this.state.userFlag
                      ? (e) => {
                          this.setState({
                            delete: true,
                            deleteid: items.businessRuleId,
                          });
                        }
                      : ""
                  }
                >
                  <img
                    id={items.businessRuleId}
                    src="/images/icon_delete.svg"
                  />
                </div>
              </Tooltip>
            </div>
          ),
        };
        this.state.products.push(row2);
      });

      this.setState({
        newrow: {},
        setShow: false,
      });
    }
  };

  // open the new Create Workspace page
  Openworkspace = () => {
    this.setState({ showForm: true });
  };

  handleChange1 = (event) => {
    let id = event.target.id;
    const initArray = this.state.products;
    const rowneeded = initArray.findIndex((obj) => obj.businessRuleId == id);
    if (event.target.name === "Phrase1") {
      initArray[rowneeded].phrase1 = event.target.value;
    } else if (event.target.name === "Phrase2") {
      initArray[rowneeded].phrase2 = event.target.value;
    } else if (event.target.name === "Phrase3") {
      initArray[rowneeded].phrase3 = event.target.value;
    } else if (event.target.name === "Phrase4") {
      initArray[rowneeded].phrase4 = event.target.value;
    } else {
      initArray[rowneeded].Change = event.target.value;
    }
    this.setState({
      editProducts: [initArray[rowneeded]],
      products: initArray,
      phraseError: false,
      phraseMessage: "",
    });
  };

  handleCloseDialog = () => {
    this.setState({ delete: false, deleteid: "" });
  };

  handlePurge = (e) => {
    //let id = e.target.id;
    let id = this.state.deleteid;

    const rowneeded = this.state.products.filter(
      (row) => row.businessRuleId != id
    );

    var feedWorkSpaceId = 0;
    if (this.state.editworkspace === false) {
      feedWorkSpaceId = this.state.newWorkspaceId;
    } else {
      feedWorkSpaceId = toJS(UserStore.editRow[0].workSpaceId);
    }
    const data = {
      BusinessRuleId: id,
      WorkSpaceId: feedWorkSpaceId,
    };
    axios.post(API_Endpoint + `/DeleteBusinessRule`, data);
    this.setState({ products: rowneeded, deleteid: "", delete: false });
    UserStore.setEditConfigData(rowneeded);
  };

  handleOnSelect = (row, isSelect) => {
    this.props.history.push(`${this.props.match.path}/Upload`);
  };

  handleDropdownChange = (e) => {


    this.setState({
      confidId: e.target.value
    })
    UserStore.setConfigId(e.target.value);
    this.refreshdata();
  };

  handleDropdownchangerowEdit = (event, id) => {
    let outcome = "";
    if (event.target.value === 10) {
      outcome = true;
    } else {
      outcome = false;
    }
    const initArray = this.state.products;
    const rowneeded = initArray.findIndex((obj) => obj.businessRuleId == id);
    initArray[rowneeded].Change = outcome;
    this.setState({
      editProducts: [initArray[rowneeded]],      
      outcomeError: false,
      outcomeErrorMessage: "",
    });
  };

  handleDropdownchangerow = (event, id) => {
    let outcome = "";
    if (event.target.value === 10) {
      outcome = true;
    } else {
      outcome = false;
    }

    this.setState((prevState) => ({
      outcomeError: false,
      outcomeErrorMessage: "",
      newrow: {
        ...prevState.newrow,
        ChangeOutcome: outcome,
      },
    }));
  };

  render() {
    UserStore.setEditConfigData(this.state.products);
    return (
      <div className="businessrule-container">
        <AppHeader />
        <BreadCrumbs />
        <div className="workspace-wrapper">
          <div className="worspace-titlebar">
            <div className="workspace-title">
              <div className="Link">
                <TextField
                  margin="dense"
                  name="proposalno"
                  label="Proposal No"
                  id = "proposal-no"
                  fullWidth
                  style={{
                    font: "normal normal Helvetica 1.04vw",
                    width: "95%",
                  }}
                  value={UserStore.proposalno}
                  disabled
                />
               
              </div>
              {this.state.configtype.length > 0 ? (
                <TextField
                  autoFocus
                  margin="dense"
                  id="configtype"
                  select
                  defaultValue={this.state.configtype[0][0].configTypeId}
                  className="assign-role-dropdown"
                  label="Config Type"
                  style={{ marginRight: "2% !important", width: "15vw" }}
                  onChange={this.handleDropdownChange}>
                  {this.state.configtype.length > 0 &&
                    this.state.configtype[0].map((option) => (
                      <option
                        key={option.configTypeId}
                        value={option.configTypeId}
                      >
                        {option.configType}
                      </option>
                    ))}
                </TextField>
              
              ) : (
                ""
              )}
            </div>
            <div
              className="create-new-workspace-wrapper"
              style={{ display: "flex" }}
            >
              <Button
                variant="contained"
                align="right"
                class="next-btn"
                onClick={this.addRow1}
                style = {{
                   margin: "0 1.9vw 0 0",
                  width: "8.4vw",
                  height: "2.29vw",
                  fontSize: "0.9vw",
                  background: "#0c629b",
                  border: "0.05vw solid #0c629b",
                  borderRadius: "0.104vw",
                  color: "#ffffff",
                  textTransform:"none",
                }}
              >
              Add row
              </Button>
            </div>
          </div>
          <div className="workspace-data-table-wrapper">
            <ProcessGridTable
              editConfigData={this.state.products}
              onRowSelected={this.handleRowSelection}
              fromeditconfig={"Yes"}
            >
              {" "}
            </ProcessGridTable>
          </div>
          <div className="btn-fields" Align="center">
            <Button
              onClick={() => {
                this.props.history.push(`/WorkspacePID`);
              }}
              variant="contained"
              class="save-btn"
              style = {{
                margin: "0 1.9vw 0 0",
                width: "8.4vw",
                height: "2.29vw",
                fontSize: "0.9vw",
                background: "#0c629b",
                border: "0.05vw solid #0c629b",
                borderRadius: "0.104vw",
                color: "#ffffff",
                textTransform:"none",
              }}
            >
              Save
            </Button>
            <Button
              variant="contained"
              class="cancel-btn"
              onClick={() => {
                this.props.history.push(`/WorkspacePID`);
              }}
              style = {{
                background: "#ffffff",
                  border: "0.05vw solid #0c629b",
                  borderRadius: "0.15vw",
                  width: "8.4vw",
                  height: "2.29vw",
                  fontSize: "0.9vw",
                  color: "#0c629b",
                  textTransform: "none",
              }}
            >
              Cancel
            </Button>
          </div>

          <Dialog
            className="delete-confirm"
            open={this.state.delete}
            onClose={this.handleCloseDialog}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle
              id="form-dialog-title"
              style={{ height: "0.625em" }}
            ></DialogTitle>
            <DialogContent>
              <div style={{ fontSize: "1vw" }}>Are you sure to delete?</div>
              <IconButton
                onClick={this.handleCloseDialog}
                style={{
                  padding: "0",
                  float: "right",
                  background: "#0C629B",
                  color: "#fff",
                  position: "absolute",
                  right: "15%",
                }}
              >
                {" "}
                <Cancel />{" "}
              </IconButton>
              <IconButton
                onClick={(e) => this.handlePurge(e)}
                style={{
                  padding: "0",
                  background: "#0C629B",
                  color: "white",
                  position: "absolute",
                  right: "2%",
                }}
              >
                {" "}
                <CircleChecked />{" "}
              </IconButton>
            </DialogContent>
          </Dialog>

          <Dialog
            open={this.state.setShow1}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
            className="businessrule-popup"
          >
            <DialogTitle id="form-dialog-title" style={{ height: "4vw" }}>
              <span style={{ fontSize: "1.2vw", color: "#fff" }}>
                Add/Edit Configuration
              </span>
            </DialogTitle>
            <DialogContent>
              <InputLabel
                id="demo-simple-select-label"
                style={{ marginLeft: "1vw", fontSize: "1.05vw" }}
              ></InputLabel>

              <TextField
                margin="dense"
                id={this.state.modalId}
                name="key1"
                label="Key1"
                onChange={(e) => this.addRow(e)}
                fullWidth
                style={{ font: "normal normal Helvetica 1.04vw", width: "95%" }}
              />
              <TextField
                margin="dense"
                id={this.state.modalId}
                name="key2"
                label="Key2"
                onChange={(e) => this.addRow(e)}
                fullWidth
                style={{ font: "normal normal Helvetica 1.04vw", width: "95%" }}
              />
              <TextField
                margin="dense"
                id={this.state.modalId}
                name="value"
                label="Value"
                onChange={(e) => this.addRow(e)}
                fullWidth
                style={{ font: "normal normal Helvetica 1.04vw", width: "95%" }}
              />
              <TextField
                margin="dense"
                id={this.state.modalId}
                name="remarks"
                label="Remarks"
                onChange={(e) => this.addRow(e)}
                fullWidth
                style={{ font: "normal normal Helvetica 1.04vw", width: "95%" }}
              />
              <FormHelperText
                error={this.state.outcomeError}
                style={{
                  marginLeft: "1.2vw",
                  font: "normal normal Helvetica 1.04vw",
                }}
              >
                {this.state.outcomeErrorMessage}{" "}
              </FormHelperText>
            </DialogContent>
            <DialogActions style={{ padding: "0 12vw" }}>
              <Button
                style={{
                  background: "#0C629B",
                  color: "#fff",
                  width: "8.4vw",
                  height: "2.29vw",
                  fontSize: "0.9vw",
                  textTransform: "none",
                }}
                onClick={this.rowSave}
                color="primary"
              >
                Add
              </Button>
              <Button
                onClick={this.handleCloserow}
                color="primary"
                style={{
                  background: "#ffffff",
                  border: "0.05vw solid #0c629b",
                  borderRadius: "0.15vw",
                  width: "8.4vw",
                  height: "2.29vw",
                  fontSize: "0.9vw",
                  color: "#0c629b",
                  textTransform: "none",
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <AppFooter />
      </div>
    );
  }
}
export default EditConfiguration;
