import React, { Component } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Modal from "react-bootstrap/Modal";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import CloseIcon from "@material-ui/icons/Close";
import { TextField, Button } from "@material-ui/core";
import axios from "axios";
import DragAndDrop from "../DragDrop/DragDrop";
import IconButton from "@material-ui/core/IconButton";
import Cancel from "@material-ui/icons/Cancel";
import CircleChecked from "@material-ui/icons/CheckCircleRounded";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import createBrowserHistory from "history/createBrowserHistory";
import UserStore from "../../UserStore";
import { toJS } from "mobx";
import "./FileList.scss";
import DatePicker from "react-datepicker";
import AccessTimeIcon from "@material-ui/icons/AccessTime";

const { BlockBlobClient } = require("@azure/storage-blob");
const API_Endpoint = process.env.REACT_APP_API_ENDPOINT;
const history = createBrowserHistory();
export default class FileList extends Component {
  constructor() {
    super();
    this.state = {
      files: [],
      file: [],
      submitted: false,
      obj: {},
      Check: false,
      isSelectAll: false,
      uploadPercentage: 0,
      selectedFiles: [],
      droppedFiles: [],
      uploadedFile: [],
      taggedFiles: [],
      batchProcess: false,
      fileFailed: [],
      countmsg: "",
      checkFiles: [],
      apifileName: [],
      taggeddropdown: [],
      uploadblob: false,
      show: false,
      selectedDate: new Date("2021-06-18T21:11:54"),
      showSetTimings: false,
      tempFiles: [],
      tFiles: [],
      tagValue: "",
      count: 0,
      showfiletypedailog: false,
      openDatePicker: false,
      batchTime: "",
    };
    this.openModal = this.openModal.bind(this);
  }

  handlePIDDrop = async (files) => {
    let fileList = this.state.files;
    for (var i = 0; i < files.length; i++) {
      if (files[i].name && (files[i].name.slice(-4).toLowerCase() === ".pdf" || files[i].name.slice(-4).toLowerCase() === ".zip") ) {
        
        fileList.push(files[i].name);
        this.state.droppedFiles.push(files[i]);
        
      } else {
        this.setState({
          showfiletypedailog: true,
          showmessage: "Please upload only pdf or Zip file",
        });
      }
    }
    UserStore.dropPIDfiles(this.state.droppedFiles);
    this.setState({
      files: fileList,
      submitted: true,
    });
  };

  handleCheckboxCheck = (e) => {
    var ele = document.getElementsByClassName("file-checkbox");

    if (e.target.checked === true) {
      const fname = e.target.name;
      this.state.selectedFiles.push(fname);
    } else {
      const index = this.state.selectedFiles.indexOf(e.target.name);
      this.state.selectedFiles.splice(index, 1);
      
    }
    if(this.state.selectedFiles.length > 1)
      {
        this.setState({
          countmsg:"Files selected"
        })
      }
      else if(this.state.selectedFiles.length === 1)
      {
        this.setState({
          countmsg:"File selected"
        })
      }else {
        this.setState({
          countmsg:""
        })
      }

      if(ele.length > this.state.selectedFiles.length) {
        this.setState({ isSelectAll: false });
      }

      if(ele.length === this.state.selectedFiles.length) {
        this.setState({ isSelectAll: true });
      }
  };

  selects() {
    var ele = document.getElementsByClassName("file-checkbox");
    var allFiles = [];
    this.setState({ isSelectAll: !this.state.isSelectAll });

    if (!this.state.isSelectAll) {
      for (var i = 0; i < ele.length; i++) {
        if (ele[i].type === "checkbox") ele[i].checked = true;
        allFiles.push(ele[i].name);
      }
    }

    if (this.state.isSelectAll) {
      for (var i = 0; i < ele.length; i++) {
        if (ele[i].type === "checkbox") ele[i].checked = false;
      }
    }
    let msg = "";
    if(allFiles.length === 1)
    {
      msg = "File selected";
    }
    else{
      msg = "Files selected";
    }
    this.setState({ 
      selectedFiles: allFiles,
      countmsg: msg,
     });
  }

  removeSelectAll = () => {
    this.setState({ 
      isSelectAll: false,
     });
    };

  handleDelete = () => {
    if (this.state.selectedFiles.length === 0) {
      this.setState({
        fileSelectionError: true,
        fileSelectionErrorMsg: "Please select at least one file to delete",
      });
    } else {
      this.setState({ deletePopup: true });
    }
  };

  openModal = () => {
    if (this.state.selectedFiles.length === 0) {
      this.setState({
        fileSelectionError: true,
        fileSelectionErrorMsg: "Please select at least one file to upload",
      });
    } 
     else {
      this.setState({ show: true, showSetTimings: false });
    }
  };

  deletePID() {
    const updatedFiles = [...this.state.files];
    const filesToDelete = [];

    var ele = document.getElementsByClassName("file-checkbox");
    for (var i = 0; i < ele.length; i++) {
      if (ele[i].type === "checkbox" && ele[i].checked === true) {
        filesToDelete.push(ele[i].name);
      }
    }

    filesToDelete.map((file) => {
      const index = updatedFiles.indexOf(file);
      updatedFiles.splice(index, 1);
    });

    this.setState({ files: updatedFiles });
    this.setState({ selectedFiles: [] });

    for (var i = 0; i < ele.length; i++) {
      if (ele[i].type === "checkbox") ele[i].checked = false;
    }
    this.setState({ deletePopup: false });
    UserStore.dropPIDfiles(updatedFiles);
    this.removeSelectAll();
  }

  handleDropdownChange = (e) => {
    this.state.tagValue = e.target.value;
    var ele = document.getElementsByClassName("file-checkbox");

    for (var i = 0; i < ele.length; i++) {
      if (ele[i].type === "checkbox" && ele[i].checked === true) {
        let val = this.state.tagValue;
        var obj = { filename: ele[i].name, tag: val };
        this.state.taggedFiles.push(obj);
        this.state.tFiles.push(ele[i].name);
        this.setState({ tempFiles: this.state.tFiles });
      }
    }
  };

  handleClose = () => {
    this.setState({
      show: false,
      showfiletypedailog: false,
      selectedTime: "",
      batchProcess: false,
      batchTime: "",
    });
  };

  handleFileSelection = () => {
    this.setState({ fileSelectionError: false, fileSelectionErrorMsg: "" });
  };
 
  handleUploadSubmit = () => {
    this.handleChange();
    this.setState({ show: false });
    this.removeSelectAll();
  };

  handleDateChange = (date) => {
    this.setState({ selectedDate: date });
  };

  showTimings = (e) => {
    if (e.target.value === "batchProcess") {
      this.setState({ showSetTimings: true, batchProcess: true });
    } else {
      this.setState({
        showSetTimings: false,
        batchProcess: false,
        batchTime: "",
        selectedTime: "",
      });
    }
  };

  handleDeleteClose = () => {
    this.setState({ deletePopup: false });
  };

  handleChange = async (event) => {
    let data = {};
    if (this.state.batchProcess) {
      let scheduleTime = {
        batchScheduledDateTime: this.state.batchTime,
      };
      await axios
        .post(API_Endpoint + `/CreatePidBatch`, scheduleTime)
        .then((res) => {
          if (res.status === 200) {
            data = {
              // ...data,
              batchId: res.data.batchId,
              isBatchFileProcessing: true,
            };
          }
        });
      this.setState({
        selectedTime: "",
        batchTime: "",
        batchProcess: false,
      });
    }else {
      data = {
        isBatchFileProcessing: false,
      };
    }
    UserStore.selectedfiles(this.state.selectedFiles);
    var ele = document.getElementsByClassName("file-checkbox");
    const updatedDropFile = toJS(UserStore.droppedPIDFiles);
    const updatedSelectedList = this.state.selectedFiles;
    const count = UserStore.countforProgress;
    this.setState({
      countSeletedItem: this.state.selectedFiles.length,
    });
    UserStore.setCount(count + 1);
    const progessFile = toJS(UserStore.selectedfilesforupload);
    const allFile = [...progessFile, ...this.state.selectedFiles];
    UserStore.selectedFilesforupload(allFile);

    for (let t in this.state.selectedFiles) {
      var index = this.state.files.indexOf(this.state.selectedFiles[t]);
      if (index > -1) {
        this.state.files.splice(index, 1);
      }
    }

    for (let i in this.state.selectedFiles) {
      var idx = this.state.droppedFiles.findIndex(
        (p) => p.name == this.state.selectedFiles[i]
      );
      this.state.droppedFiles.splice(idx, 1);
    }

    this.setState({
      files: this.state.files,
      droppedFiles: this.state.droppedFiles,
      selectedFiles: [],
    });

    for (var i = 0; i < ele.length; i++) {
      if (ele[i].type === "checkbox") ele[i].checked = false;
    }

    for (let x = 0; x < updatedDropFile.length; x++) {
      let fileCount = 0;
      for (let z = 0; z < updatedSelectedList.length; z++) {
        if (updatedDropFile[x].name === updatedSelectedList[z]) {
          fileCount = fileCount + 1;
          updatedSelectedList[z] = "";

          const filename = updatedDropFile[x].name;
          const workspaceid = UserStore.PIDworkspaceID;
          
          data = {
            ...data,
            ProjectWorkSpaceId: workspaceid,
            fileName: filename,
            fileTags: '',
          };
          const file = updatedDropFile[x];

          const options = {
            onUploadProgress: (ProgressEvent) => {
              const { loaded, total } = ProgressEvent;
              let percent = Math.floor((loaded * 100) / total);
              UserStore.getPercentage(percent);
              if (percent < 100) {
                this.setState({
                  uploadPercentage: percent,
                });
              } else if (percent === 100) {
                this.state.uploadedFile.push(filename);
              }
            },
          };

          if (fileCount == 1) {
            await axios
              .post(API_Endpoint + `/GetPidUploadPath`, data, options)

              .then((res) => {
                const filename = res.data.fileName;
                let uri = res.data.sasUri;
                if (res.status === 200) {
                  this.setState({
                    uploadblob: true,
                    fileSucceded: [file.name],
                  });
                  const resid = this.handleblobLoad(uri, file, filename);
                } else {
                  UserStore.getPercentage(0);
                  this.setState({
                    fileFailed: [file.name],
                  });
                  this.handleFailed(uri, res.data.message, filename);
                }
              });
          }
        }
      }
    }
    UserStore.dropfiles(this.state.droppedFiles);
  };

  handleblobLoad = async (uri, file, filename) => {
    try{
    const blobServiceClient1 = new BlockBlobClient(uri);
    const uploadBlobResponse = await blobServiceClient1.uploadData(file);
    if(!uploadBlobResponse.requestId)
     {
      let res = await axios.post(API_Endpoint + `/PidFileUploadComplete`, {
        "fileUploadCompletedList":[
          {
            "fileName": filename,
            "fileUploadedSuccessfully": false,
            "status": "File upload failed"
        }
        ]
      });  
        
        let files = toJS(UserStore.selectedfilesforupload);
        let splittedfilenames = filename.split("_")[0];
        let filenameresults = filename.split(`${splittedfilenames}_`)[1];
        UserStore.selectedFilesforupload(
          files.filter((item) => item !== filenameresults)
        );
      
      
     } 
     else
     {
      let res = await axios.post(API_Endpoint + `/PidFileUploadComplete`, {
        "fileUploadCompletedList":[
          {
            "fileName": filename,
            "fileUploadedSuccessfully": true,
            "status": "File upload successful"
        }
        ]  
      });
     let filesnew = toJS(UserStore.selectedfilesforupload);
     let splittedfilenamesnew = filename.split("_")[0];
     let filenameresultsnew = filename.split(`${splittedfilenamesnew}_`)[1];
     UserStore.selectedFilesforupload(
       filesnew.filter((item) => item !== filenameresultsnew)
     );
     }   
     

  }
  catch (error) {
    let files = toJS(UserStore.selectedfilesforupload);
    let splittedfilename = filename.split("_")[0];
    let filenameresult = filename.split(`${splittedfilename}_`)[1];
  
    UserStore.selectedFilesforupload(
      files.filter((item) => item !== filenameresult)
    );
    let errstr = error.message.split(".\n")[0];
    // console.log(error, errstr);

    this.logMyErrors(errstr, filename);    
    throw new Error('Error Uploading the selfie to blob');    
    
    
}     

UserStore.getprocessGrid(1);
    localStorage.setItem("getprocessGrid", 1);
    const data = this.state.apifileName;
    this.state.apifileName.push(filename);
    this.state.count = this.state.count + 1;
    UserStore.addFiles(file.name);
    if (this.state.count === this.state.countSeletedItem) {      
      // history.push("WorkspacePID/Upload");
      this.setState({
        count: 0,
        batchProcess: false,
      });
    }  
  };

  logMyErrors = async(error,filename)=>
  {

    let splittedfilename = filename.split("_")[0];
    let filenameresult = filename.split(`${splittedfilename}_`)[1];
    
    let delfiles = [...UserStore.rejectedfilesforupload,filenameresult];

    UserStore.rejectedFilesforupload(delfiles);
    
    let res = await axios.post(API_Endpoint + `/PidFileUploadComplete`, {
      "fileUploadCompletedList":[
      {
        "fileName": filename,
        "fileUploadedSuccessfully": false,
        "status": "File upload Failure"
    }
    ]});
    
  }

  handleFailed = async (uri, data, filename) => {
    let files = toJS(UserStore.selectedfilesforupload);
    let splittedfilename1 = filename.split("_")[0];
    let filenameresult1 = filename.split(`${splittedfilename1}_`)[1];
  
    UserStore.selectedFilesforupload(
      files.filter((item) => item !== filenameresult1)
    );
    let delfiles = [...UserStore.rejectedfilesforupload,filename];
    UserStore.rejectedFilesforupload(delfiles);
  }

  handleTimeDateChange = (e) => {
    this.setState({ openDatePicker: false });
    let dateTime = e + "";
    let time = dateTime.split(" ");
    time = time[4] + "";
    let hh = time.split(":");
    let aa = "";

    if (time === "12:00:00" || time === "12:30:00") {
      hh[0] = "12";
      aa = "PM";
    } else if (time === "00:00:00" || time === "00:30:00") {
      hh[0] = "12";
      aa = "AM";
    } else if (parseInt(hh[0]) > 12) {
      hh[0] = parseInt(hh[0]) - 12;
      aa = "PM";
    }
    else {
      hh[0] = parseInt(hh[0]);
      aa = "AM";
    }
    dateTime = hh[0] + ":" + hh[1] + " " + aa;

    this.setState({ selectedTime: dateTime });
    let standardTime = new Date(e);
    let batchtime = standardTime.toISOString().slice(0, 19);
    this.setState({ batchTime: batchtime });
  };

  filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  changeStatus = () => {
    this.setState({ openDatePicker: false });
  };

  openTimePicker = () => {
    this.setState({ openDatePicker: true });
  };

  render() {
    return (
      <div className="upload-box">
        <div
          className="options-bar-wrapper"
          style={
            this.state.files.length === 0
              ? { display: "none" }
              : { display: "flex", justifyContent: "space-between" }
          }
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              checked={this.state.isSelectAll}
              onClick={this.selects.bind(this)}
              className="selectAll1"
              name="checkedB"
              color="primary"
            />
            <span
              className="selectAll"
              style={{
                font: "normal normal normal 1vw Helvetica",
                marginTop: "0.5vw",
              }}
            >
              Select All
            </span>
          </div>
          <div>
            {this.state.selectedFiles.length > 0 && (
              <span
                className="selectAll"
                style={
                  this.state.selectedFiles.length > 0
                    ? {
                        font: "normal normal normal 1vw Helvetica",
                      }
                    : {
                        font: "normal normal normal 1vw Helvetica",
                        marginLeft: "3vw",
                      }
                }
              >
                {this.state.selectedFiles.length} {this.state.countmsg}
              </span>
            )}
            <Button
              className="upload"
              onClick={this.openModal}
              style={
                this.state.selectedFiles.length > 0
                  ? {
                      width: "8.4vw",
                      height: "2.29vw",
                      fontSize: "0.9vw",
                      background: "#0c629b",
                      color: "#ffffff",
                      textTransform: "none",
                      borderRadius: "0.3vw",
                      border: "0",
                      marginLeft: "1vw",
                    }
                  : {
                      width: "8.4vw",
                      height: "2.29vw",
                      fontSize: "0.9vw",
                      background: "#0c629b",
                      color: "#ffffff",
                      textTransform: "none",
                      borderRadius: "0.3vw",
                      border: "0",
                    }
              }
            >
              <img
                src="/images/icon_upload_grey.svg"
                style={{
                  marginRight: "0.5vw",
                  width: "1.5vw",
                  height: "1.3vw",
                }}
              />
              <span style={{ font: "normal normal normal 1vw Helvetica" }}>
                Upload
              </span>
            </Button>
            <Button
              className="delete"
              onClick={this.handleDelete}
              style={{
                background: "#ffffff",
                border: "0.05vw solid #0c629b",
                borderRadius: "0.15vw",
                width: "8.4vw",
                height: "2.29vw",
                padding: "0.15vw 0",
                color: "#0c629b",
                margin: "0 1vw",
              }}
            >
              <img
                src="/images/icon_delete.svg"
                style={{
                  marginRight: "0.5vw",
                  height: "1.2vw",
                  width: "1.4vw",
                }}
              />
              <span
                style={{
                  font: "normal normal normal 1vw Helvetica",
                  textTransform: "capitalize",
                }}
              >
                Delete
              </span>
            </Button>
          </div>
        </div>

        <Dialog
          className="delete-confirm"
          open={this.state.deletePopup}
          onClose={this.handleDeleteClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle
            id="form-dialog-title"
            style={{ height: "0.625em" }}
          ></DialogTitle>
          <DialogContent>
            <div style={{ fontSize: "1vw" }}>Are you sure to delete?</div>
            <IconButton
              onClick={this.handleDeleteClose}
              style={{
                padding: "0",
                float: "right",
                background: "#0C629B",
                color: "#fff",
                position: "absolute",
                right: "15%",
              }}
            >
              {" "}
              <Cancel />{" "}
            </IconButton>
            <IconButton
              onClick={this.deletePID.bind(this)}
              style={{
                padding: "0",
                background: "#0C629B",
                color: "white",
                position: "absolute",
                right: "2%",
              }}
            >
              {" "}
              <CircleChecked />{" "}
            </IconButton>
          </DialogContent>
        </Dialog>
        <div>
          <DragAndDrop
            handleDropPID={this.handlePIDDrop}
            files={this.state.files}
            succeded={this.state.fileSucceded}
            tempFiles={this.state.tempFiles}
            handleCheckboxCheck={this.handleCheckboxCheck}
          ></DragAndDrop>
        </div>
        <Modal
            className="user-select-popup"
            show={this.state.fileSelectionError}
            onHide={this.handleFileSelection}
            backdrop="static"
            keyboard={false}
            dialogClassName="fullmodal"
            size="l"
            centered
          >
            <Modal.Body>
              <label
                style={{
                  font: "normal normal bold 1vw Helvetica",
                  textAlign: "left",
                }}
              >
                {this.state.fileSelectionErrorMsg}
                <IconButton
                  onClick={this.handleFileSelection}
                  style={{
                    padding: "3px",
                    position: "absolute",
                    top: "0",
                    right: "0",
                    background: "",
                    borderRadius: "0",
                    color: "#0C629B",
                  }}
                >
                  {" "}
                  <CloseIcon style={{ width: "1.5vw", height: "1.5vw" }} />{" "}
                </IconButton>
              </label>
            </Modal.Body>
          </Modal>
        <Modal
          className="batch-process-modal"
          show={this.state.show}
          onHide={this.handleClose}
          backdrop="static"
          keyboard={false}
          dialogClassName="fullmodal"
          size="l"
          centered
        >
          <Modal.Body>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="upload"
                name="upload"
                defaultValue="processNow"
                onChange={this.showTimings}
              >
                <FormControlLabel
                  value="processNow"
                  control={<Radio color="primary" />}
                  label="Upload files for processing now"
                />
                <FormControlLabel
                  id="batchProcess"
                  value="batchProcess"
                  control={<Radio color="primary" />}
                  label="Upload files for batch processing"
                />
                {this.state.showSetTimings ? (
                    <label
                      style={{
                        width: "10vw",
                        padding: "0",
                        margin: "0",
                        height: "0",
                      }}
                    >
                      <div style={{display: "flex",flexDirection: "row"}}>
                        <DatePicker
                          required
                          showTimeSelect
                          dateFormat="h:mm aa"
                          showTimeSelectOnly
                          timeIntervals={30}
                          onChange={this.handleTimeDateChange}
                          filterTime={this.filterPassedTime}
                          open={this.state.openDatePicker}
                          placeholderText="Select an interval"
                          onClickOutside={this.changeStatus}
                          customInput={
                            <div className="selected-time" style={{position: "relative",bottom:"0.5vw", width: "7vw", marginLeft: "2.4vw"}}>
                          at {this.state.selectedTime === "" ? "____" : this.state.selectedTime} 
                          <AccessTimeIcon onClick={this.openTimePicker} style={{position: "relative",left:"0.5vw"}}/>
                        </div>
                          }
                        />
                      </div>
                    </label>
                  ) : (
                    ""
                  )}
              </RadioGroup>
            </FormControl>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.handleUploadSubmit}>
              Submit
            </Button>
            <Button
              variant="primary"
              onClick={this.handleClose}
              style={{
                backgroundColor: "#fff !important",
                color: "#0C629B !important",
              }}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.showfiletypedailog}
          onHide={this.handleClose}
          backdrop="static"
          keyboard={false}
          dialogClassName="fullmodal"
          size="l"
          centered
        >
          <Modal.Body>
            <label>{this.state.showmessage}</label>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.handleClose}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
