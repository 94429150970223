import axios from "axios";
import React from "react";
import moment from "moment";

class DataGridPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
    };
  }

  componentDidMount() {
    const APIEndpoint = process.env.REACT_APP_API_ENDPOINT;
    // alert(this.props.wsId);
    const res = axios
      .get(APIEndpoint + `/GetBatchStatus?workSpaceId=${this.props.wsId}`)
      .then((res) => {
        const myrows = res.data;
        let rowget = [];
        if (myrows) {
          myrows.map((items, index) => {
            const row2 = {
              id: items.batchId,
              batchScheduleDateTime: items.batchScheduleDateTime,
              batchCreationDateTime: items.batchCreationDateTime,
              batchProcessingDateTime: items.batchProcessingDateTime,
              batchCreatedBy: items.batchCreatedBy,
              filesProcessedSuccessfully: items.filesProcessedSuccessfully,
              filesProcessedFailure: items.filesProcessedFailure,
              fileWaitingForProcessing: items.fileWaitingForProcessing,
              totalFilesUploaded: items.totalFilesUploaded,
              totalIsometricUploaded: items.totalIsometricUploaded,
              isometricProcessedSuccessfully:
                items.isometricProcessedSuccessfully,
              isometricProcessedFailure: items.isometricProcessedFailure,
              isometricWaitingForProcessing:
                items.isometricWaitingForProcessing,
            };
            rowget.push(row2);
          });
        }
        this.setState({ rows: rowget });
      });
  }
  render() {
    return (
      <div>
        <table
          bordered
          style={{
            overflowY: "auto",
            display: "block",
            padding: "0vw 1vw 1vw 1vw",
          }}
          className="table1"
        >
          <thead
            style={{
              fontSize: "0.8vw",
              backgroundColor: "#707070",
              textAlign: "left",
            }}
            align="center"
            valign="bottom"
            char="M"
          >
            <tr>
              {/* <th>Batch Id</th> */}
              <th>Batch Schedule DateTime</th>
              {/* <th>Batch Creation DateTime (UTC)</th> */}
              <th>Batch Created By</th>
              <th>Batch Processing DateTime</th>
              <th>Total Files Uploaded</th>
              <th>Files Processed Successfully</th>
              <th>File Waiting For Processing</th>
              <th>Files Processed Failure</th>
              <th>Total Isometric Uploaded</th>
              <th>Isometric Processed Successfully</th>
              <th>Isometric Waiting For Processing</th>
              <th>Isometric Processed Failure</th>
            </tr>
          </thead>
          <tbody style={{ fontSize: "0.8vw", textAlign: "left" }}>
            {this.state.rows.map((items) => (
              <tr>
                {/* <td>{items.id}</td> */}
                <td>{items.batchScheduleDateTime != "" ? moment.utc(items.batchScheduleDateTime, 'DD/MM/YYYY hh:mm:ss a').local().format('DD/MM/YYYY hh:mm:ss a') : ""}</td>
                <td>{items.batchCreatedBy}</td>
                <td>{items.batchProcessingDateTime != "" ? moment.utc(items.batchProcessingDateTime, 'DD/MM/YYYY hh:mm:ss a').local().format('DD/MM/YYYY hh:mm:ss a') : ""}</td>
                <td>{items.totalFilesUploaded}</td>
                <td>{items.filesProcessedSuccessfully}</td>
                <td>{items.fileWaitingForProcessing}</td>
                <td>{items.filesProcessedFailure}</td>
                <td>{items.totalIsometricUploaded}</td>
                <td>{items.isometricProcessedSuccessfully}</td>
                <td>{items.isometricWaitingForProcessing}</td>
                <td>{items.isometricProcessedFailure}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default DataGridPopup;
