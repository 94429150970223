import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { MuiThemeProvider, Button } from "@material-ui/core";
import AppHeader from "../../AppHeader/AppHeader";
import AppFooter from "../../AppFooter/AppFooter";
import ErrorBoundary from "../../Errorlog/ErrorBoundary";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Suggestions from "../ProjectDetails/Suggestions";
import Modal from "react-bootstrap/Modal";
import "./ProjectDetails.scss";
import axios from "axios";
import UserStore from "../../UserStore";
import BreadCrumbs from "../../Upload/BreadCrumbs/BreadCrumbs";
import { withStyles } from "@material-ui/core/styles";
import TextFieldMui from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";
import CircleChecked from "@material-ui/icons/CheckCircleRounded";
import Cancel from "@material-ui/icons/Cancel";
// Importing toastify module
import { toast } from "react-toastify";
import { toJS } from "mobx";
// Import toastify css file
import "react-toastify/dist/ReactToastify.css";

// toast-configuration method, it is compulsory method.
toast.configure();

const styles = (muiTheme) => ({
  label: {
    "&$erroredLabel": {
      color: "black",
    },
  },
  erroredLabel: {},
});
const API_Endpoint = process.env.REACT_APP_API_ENDPOINT;
const TextField = withStyles(styles)(function TextField({ classes, ...props }) {
  return (
    <TextFieldMui
      InputLabelProps={{
        classes: {
          root: classes.label,
          error: classes.erroredLabel,
        },
      }}
      {...props}
    />
  );
});
export default class ProjectDetails extends Component {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.suggestionOutClickHandler = this.suggestionOutClickHandler.bind(this);
    if (
      UserStore.NewworkspaceName === "" &&
      localStorage.getItem("SetNewworkspaceName")
    ) {
      UserStore.SetNewworkspaceName(
        localStorage.getItem("SetNewworkspaceName")
      );
    }
    if (
      UserStore.NewProjectName === "" &&
      localStorage.getItem("SetNewProjectName")
    ) {
      UserStore.SetNewProjectName(localStorage.getItem("SetNewProjectName"));
    }
    if (
      UserStore.NewModelName === "" &&
      localStorage.getItem("SetNewModelName")
    ) {
      UserStore.SetNewModelName(localStorage.getItem("SetNewModelName"));
    }
  }
  handleCloseEmptyUser = () => {
    this.setState({ workSpacePopupError: false });
    this.setState({ clientPopupError: false });
    this.setState({ modelPopupError: false });
    this.setState({ userEmpty: false, duplicateData: false, emptyUser: false });
  };

  componentDidMount() {
    this.fetchUserTypes();
    document.addEventListener("mousedown", this.suggestionOutClickHandler);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.suggestionOutClickHandler);
  }

  fetchUserTypes = async () => {
    const addedUser = toJS(UserStore.addUserforWorkspace).length
      ? toJS(UserStore.addUserforWorkspace)
      : localStorage.getItem("setUserforWorkspace")
      ? JSON.parse(localStorage.getItem("setUserforWorkspace"))
      : [];
    this.setState({
      useradd: addedUser,
    });

    const dropDownValues = await axios
      .get(`${API_Endpoint}/GetApplicationRoles`)
      .then((res) => {
        if (res.status === 200) {
          this.state.roles = res.data;
          this.setState({
            roles: res.data,
          });
        } else if (
          res.status === 400 ||
          res.status === 401 ||
          res.status === 404 ||
          res.status === 408
        ) {
          toast.error("service error", {
            // Set to 5sec
            position: toast.POSITION.TOP_LEFT,
            autoClose: 5000,
          });
        }
      });
  };

  state = {
    list: [],
    open: false,
    workspace: false,
    projectname: false,
    workSpaceMessage: "",
    projectNameMessage: "",
    usersMessage: "",
    workSpaceError: "",
    projectNameError: false,
    usersError: false,
    count: -1,
    notification: [],
    role: "Admin",
    roles: [],
    rolearray: [],
    dropdownvalue: "Admin",
    suggestions: [],
    message: "",
    modifysuggestion: [],
    showSuggestions: false,
    q: "",
    personName: [],
    useradd: [],
    ocrweightage:[],
    OCRmessage: false,
    show: false,
    OCRshow: false,
    lineNum: '',
    lineNumThreshold: '',
    lineNumMessage:false,
    lineNumThresholdMessage:false,
    isoNum: '',
    isoNumMessage:"",
    isoNumThreshold: '',
    isoNumThresholdMessage:"",
    sheetNum: '',
    sheetNumMessage:"",
    sheetNumThreshold: '',
    sheetNumThresholdMessage:"",
    revNum: '',
    revNumMessage:"",
    revNumThreshold: '',
    revNumThresholdMessage:"",
    tootlTipErrorMessage: "",
    tooltipError: false,
    userEmpty: false,
    delete: false,
    count: 0,
    deleteUsers: "",
    workSpacePopupError: false,
    modelPopupError: false,
    clientPopupError: false,
    duplicateData: false,
    emptyUser: false,
    tick: false,
    phrase: true,
    customVision: "",
  };

  handleusers = (e) => {
    this.setState({
      list: this.state.list.push(e.target.value),
    });
  };

  createUseroptions = (e) => {
    return this.state.roles.map((option) => (
      <option key={option.roleId} value={option.roleName}>
        {option.roleName}
      </option>
    ));
  };

  continue = async (e) => {
    e.preventDefault();
    const responsecheck = await axios.get(API_Endpoint + `/GetWorkSpaces`);
    if (
      UserStore.NewworkspaceName === "" &&
      localStorage.getItem("SetNewworkspaceName")
    ) {
      UserStore.SetNewworkspaceName(
        localStorage.getItem("SetNewworkspaceName")
      );
    }
    if (
      UserStore.NewProjectName === "" &&
      localStorage.getItem("SetNewProjectName")
    ) {
      UserStore.SetNewProjectName(localStorage.getItem("SetNewProjectName"));
    }
    if (
      UserStore.NewModelName === "" &&
      localStorage.getItem("SetNewModelName")
    ) {
      UserStore.SetNewModelName(localStorage.getItem("SetNewModelName"));
    }
    const checkworkspacename = UserStore.NewworkspaceName.toLowerCase();
    const checkprojectname = UserStore.NewProjectName;
    const data1 = responsecheck.data.filter(
      (item) => item.workSpaceName.toLowerCase() == checkworkspacename
    );

    let value = 0;
    // input validations
    if (data1.length > 0) {
      this.setState({
        userEmpty: true,
        duplicateData: true,
      });
    } else if (
      UserStore.NewProjectName === "" &&
      UserStore.NewworkspaceName === "" &&
      this.state.useradd.length === 0 &&
      this.state.ocrweightage.length === 0 &&
      value === 0
    ) {
      value = value + 1;
      this.setState({
        userEmpty: true,
        message:
          "Workspace Name and Client name are required. Please provide the details and select at least one user and add OCR Weightage to create new Project Workspace",
      });
    }  else if (
      UserStore.NewworkspaceName === "" &&
      UserStore.NewProjectName !== "" &&
      this.state.ocrweightage.length !== 0 &&
      this.state.useradd.length !== 0
    ) {
      value = value + 1;
      this.setState({
        userEmpty: true,
        message: "Workspace Name is required.",
      });
    } else if (
      UserStore.NewworkspaceName !== "" &&
      UserStore.NewProjectName === "" &&
      this.state.useradd.length !== 0 &&
      this.state.ocrweightage.length !== 0
    ) {
      value = value + 1;
      this.setState({
        userEmpty: true,
        message: "Client Name is required.",
      });
    } else if (
      UserStore.NewworkspaceName !== "" &&
      UserStore.NewProjectName !== "" &&
      this.state.useradd.length === 0 &&
      this.state.ocrweightage.length !== 0
    ) {
      value = value + 1;
      this.setState({
        userEmpty: true,
        message: "select at least one user to create new Project Workspace",
      });
    
    }else if (
      UserStore.NewworkspaceName !== "" &&
      UserStore.NewProjectName !== "" &&
      this.state.useradd.length !== 0 &&
      this.state.ocrweightage.length === 0
    ) {
      value = value + 1;
      this.setState({
        userEmpty: true,
        message: "Add OCR Weightage to create new Project Workspace",
      });
    }
    else if (
      UserStore.NewworkspaceName !== "" &&
      UserStore.NewProjectName === "" &&
      this.state.useradd.length === 0 &&
      this.state.ocrweightage.length !== 0
    ) {
      value = value + 1;
      this.setState({
        userEmpty: true,
        message:
          "Client name and one user is required to create new Project Workspace",
      });
    } else if (
      UserStore.NewworkspaceName === "" &&
      UserStore.NewProjectName !== "" &&
      this.state.useradd.length === 0 &&
      this.state.ocrweightage.length !== 0
    ) {
      value = value + 1;
      this.setState({
        userEmpty: true,
        message:
          "Project Workspace name and one user is required to create new Project Workspace",
      });
    } 
    // else if (
    //   UserStore.NewworkspaceName !== "" &&
    //   UserStore.NewProjectName !== "" &&
    //   this.state.ocrweightage.length !== 0 &&
    //   this.state.useradd.length === 0
    // ) {
    //   value = value + 1;
    //   this.setState({
    //     userEmpty: true,
    //     message:
    //       "Model name and one user is required to create new Project Workspace",
    //   });
    // }
    else if (
      UserStore.NewworkspaceName !== "" &&
      UserStore.NewProjectName === "" &&
      this.state.useradd.length !== 0 &&
      this.state.ocrweightage.length === 0
    ) {
      value = value + 1;
      this.setState({
        userEmpty: true,
        message:
          "Client name and OCR Weightage is required to create new Project Workspace",
      });
    } else if (
      UserStore.NewworkspaceName === "" &&
      UserStore.NewProjectName !== "" &&
      this.state.useradd.length !== 0 &&
      this.state.ocrweightage.length === 0
    ) {
      value = value + 1;
      this.setState({
        userEmpty: true,
        message:
          "Project Workspace name and OCR Weightage is required to create new Project Workspace",
      });
    } 
    else {
      if (this.state.useradd.length === 0 && value === 0) {
        this.setState({ userEmpty: true, emptyUser: true });
      } else {
        for (var i in this.state.useradd) {
          if (this.state.useradd[i].roleId == "Admin") {
            this.state.useradd[i].roleId = 1;
          } else if (this.state.useradd[i].roleId == "Read / Write") {
            this.state.useradd[i].roleId = 2;
          } else {
            this.state.useradd[i].roleId = 3;
          }
        }

        const data = {
          projectName: UserStore.NewProjectName,
          workSpaceName: UserStore.NewworkspaceName,
          aiModelId: UserStore.NewModelName,
          detectionExpression: UserStore.setDetectionQuery,
          workSpaceUsers: this.state.useradd,
          isIsometricTickBased: this.state.tick,
          fieldThreshold: this.state.ocrweightage,
          CustomVisionProcessingRequired : this.state.tick ? this.state.customVision : null,
          CustomVisionPredictionKey : this.state.tick ? this.state.customVision ? document.getElementById("modelNameCV").value: null : null,
          CustomVisionModelName :this.state.tick ? this.state.customVision ? document.getElementById("modelIdCV").value: null : null
        };

        UserStore.setCreateWorkspacedata(data);
        localStorage.setItem("createWorkspacedata", JSON.stringify(data));
        if (value === 0) {
          if (this.state.phrase) {
            this.props.history.push(
              `${this.props.match.path}/SetDetectionlogic`
            );
          } else {
            this.props.history.push(
              `${this.props.match.path}/RegularExpression`
            );
          }
        }
      }
    }
  };

  handleClose = () => {
    this.setState({ open: false, projectname: false, workspace: false });
  };

  // Funcrion: Add new user to the workspace
  AddUser = () => {
    this.setState({ tooltipError: false, tootlTipErrorMessage: "" });
    const searchTerm = document
      .getElementById("search-box1")
      .value.toLowerCase();
    if (
      this.state.suggestions.length == 1 &&
      this.state.suggestions.some((e) => e.mail === searchTerm)
    ) {
      document.getElementById("search-box1").focus();
      this.state.personName[0] = searchTerm;
    }
    if (this.state.personName.length === 0) {
      this.setState({
        tooltipError: true,
        tootlTipErrorMessage: "Kindly add a valid email address",
      });
    } else {
      const personNameList = this.state.personName;
      for (var i = 0; i < personNameList.length; i++) {
        const userdata = {
          enterpriseId: personNameList[i],
          roleId: this.state.role,
        };
        this.state.useradd.push(userdata);
        this.setState({
          role: this.state.dropdownvalue,
          personName: [],
          suggestions: [],
        });
      }

      function getUnique(arr, index) {
        const unique = arr
          .map((e) => e[index])
          // store the keys of the unique objects
          .map((e, i, final) => final.indexOf(e) === i && i)

          // eliminate the dead keys & store unique objects
          .filter((e) => arr[e])
          .map((e) => arr[e]);
        return unique;
      }
      this.state.useradd.reverse();
      this.state.useradd = getUnique(this.state.useradd, "enterpriseId");
      UserStore.setUserforWorkspace(this.state.useradd);
      localStorage.setItem(
        "setUserforWorkspace",
        JSON.stringify(this.state.useradd)
      );
      document.getElementById("search-box1").value = "";
      this.setState({
        show: true,
        q: "",
      });
    }
  };

  //-----------------------------------Dropdown for role change------------------------------

  handleRoleChange = (e) => {
    if (e.target.value === "Admin") {
      this.state.role = "Admin";
      this.setState({
        dropdownvalue: e.target.value,
        role: "Admin",
      });
      this.state.dropdownvalue = e.target.value;
    } else if (e.target.value === "Read / Write") {
      this.state.role = "Read / Write";
      this.setState({
        dropdownvalue: e.target.value,
        role: "Read / Write",
      });
    } else if (e.target.value === "Read Only") {
      this.state.role = "Read Only";
      this.setState({
        dropdownvalue: e.target.value,
        role: "Read Only",
      });
    }
    this.state.rolearray.push(e.target.value);
  };

  //---------------------get the suggestions----------------------------------------

  suggestionsCall = async (q) => {
    const res = await axios.get(
      `${process.env.REACT_APP_API_GETUSERLIST}/GetUsersList?username=${q}`
    );
    this.setState({
      suggestions: res.data,
    });

    this.state.modifysuggestion = this.state.suggestions;
    this.filterSearchText();
  };

  onSearchHandler = () => {
    this.setState({ showSuggestions: false });
  };
  pasteFunction = (e) => {
    let searchTerm = "";
    searchTerm = e.clipboardData.getData("text").toLowerCase();
    this.setState({
      q: searchTerm,
      personName: [],
    });
    let substring = searchTerm.substring(0, 3);
    this.suggestionsCall(substring);
    // this.onChangeHandler1();
    this.filterSearchText();
  };

  onChangeHandler1 = async () => {
    let searchTerm = "";
    searchTerm = document.getElementById("search-box1").value.toLowerCase();

    this.setState({
      q: searchTerm,
      personName: [],
    });
    if (searchTerm.length === 3) {
      this.suggestionsCall(searchTerm);
      this.setState({
        showSuggestions: true,
      });
    }
    this.filterSearchText();
  };

  filterSearchText = () => {
    let searchTerm = "";
    searchTerm = document.getElementById("search-box1").value.toLowerCase();
    const updatedRowsforsearch = this.state.suggestions.filter(
      (row) =>
        row.mail.toLowerCase().includes(searchTerm) ||
        row.displayName.toLowerCase().includes(searchTerm)
    );
    this.setState({
      suggestions: updatedRowsforsearch,
      showSuggestions: true,
    });
    for (var i = 0; i < this.state.useradd.length; i++) {
      // alert(this.state.useradd[i])
      if (this.state.useradd[i].enterpriseId.toLowerCase() === searchTerm) {
        this.setState({
          tooltipError: true,
          tootlTipErrorMessage: "override the user role",
        });
      }
    }
    this.state.suggestions = this.state.modifysuggestion;
    const updatedRows = this.state.suggestions.filter(
      (row) =>
        row.mail.toLowerCase().includes(searchTerm) ||
        row.displayName.toLowerCase().includes(searchTerm)
    );
    this.setState({ suggestions: updatedRows });
    if (updatedRows.length == 1 && searchTerm.includes("@petrofac.com")) {
      if (Object.values(updatedRows[0]).includes(searchTerm)) {
        document.getElementById("search-box1").focus();
        this.state.personName[0] = searchTerm;
        return true;
      }
    }
  };

  onEnterButton = (event) => {
    if (event.keyCode === 13) {
      this.onSearchHandler();
    }
  };
  //--------------------------close dialog----------------------------
  handleClose = () => {
    this.setState({
      show: false,
      OCRshow: false,
    });
  };

  radioInput = (e) => {
    let inputValue = e.target.value;
    if (inputValue === "TickRegular" || inputValue === "TickStandard") {
      UserStore.setIsTickBasedCreateWS(true);
      this.setState({ tick: true, phrase: false });
    } else {
      UserStore.setIsTickBasedCreateWS(false);
      this.setState({ phrase: true, tick: false });
    }
  };

  customVisionInput = (e) => {
    let inputValue = e.target.value;
    if (inputValue === "yes") {
      this.setState({ customVision: true});
    } else {
      this.setState({customVision: false });
    }
  };

  handleChange = (e) => {
    if (e.target.name === "WorkspaceName") {
      UserStore.SetNewworkspaceName(e.target.value);
      localStorage.setItem("SetNewworkspaceName", e.target.value);
      this.props.handleChange(e.target.value);
      if (e.target.value !== "") {
        this.setState({
          message: "",
        });
      }
    } else if (e.target.name === "projectName") {
      UserStore.SetNewProjectName(e.target.value);
      localStorage.setItem("SetNewProjectName", e.target.value);
      this.props.handleChange(e.target.value);
      if (e.target.value !== "") {
        this.setState({
          message: "",
        });
      }
    } else if (e.target.name === "modelName") {
      UserStore.SetNewModelName(e.target.value);
      localStorage.setItem("SetNewModelName", e.target.value);
      this.props.handleChange(e.target.value);
      if (e.target.value !== "") {
        this.setState({
          message: "",
        });
      }
    }
  };

  // ------------------Start:Function: Suggestions select------------------
  suggestionClickHandler = (s) => {
    document.getElementById("search-box1").value = s;
    for (var i = 0; i < this.state.useradd.length; i++) {
      if (
        this.state.useradd[i].enterpriseId.toLowerCase() === s.toLowerCase()
      ) {
        this.setState({
          tooltipError: true,
          tootlTipErrorMessage: "override the user role",
        });
      }
    }
    this.state.personName[0] = s;
    this.setState({
      showSuggestions: false,
    });
  };
  // ------------------End:Function: Suggestions select------------------

  suggestionOutClickHandler = (event) => {
    if (this.wrapperRef.current) {
      if (!this.wrapperRef.current.contains(event.target)) {
        this.setState({
          showSuggestions: false,
        });
      }
    }
  };

  // ---------------Start:Function: Delete user
  handlePurge = (e, user) => {
    const updatedRows = this.state.useradd.filter(
      (row) =>
        row.enterpriseId.toLowerCase() !== user.enterpriseId.toLowerCase()
    );
    this.setState({
      useradd: updatedRows,
      delete: false,
    });
  };
  // ---------------End:Function: Delete user

  handleCloseDialog = () => {
    this.setState({ delete: false });
  };

  handleDelete = (e, user) => {
    this.setState({ delete: true, deleteUsers: user });
  };

  handleSave = (e) => {      
      const allValue = [
        this.state.lineNum,
      this.state.lineNumThreshold,
      this.state.isoNum,
      this.state.isoNumThreshold,
      this.state.sheetNum,
      this.state.sheetNumThreshold,
      this.state.revNum,
      this.state.revNumThreshold
      ];
      if(allValue.every(value => { return value !== '' })) {
        const OCRapiData = []
        this.setState({
          OCRshow: false,
          OCRmessage: false,
          ocrweightage : [
            {
              "fieldId": 1,
              "ConfidenceThreshold": Number(this.state.lineNumThreshold).toFixed(1),
              "OCRWeightage": Number(this.state.lineNum).toFixed(1)
            },{
              "fieldId": 2,
              "ConfidenceThreshold": Number(this.state.isoNumThreshold).toFixed(1),
              "OCRWeightage": Number(this.state.isoNum).toFixed(1)
            },{
              "fieldId": 3,
              "ConfidenceThreshold": Number(this.state.sheetNumThreshold).toFixed(1),
              "OCRWeightage": Number(this.state.sheetNum).toFixed(1)
            },{
              "fieldId": 4,
              "ConfidenceThreshold": Number(this.state.revNumThreshold).toFixed(1),
              "OCRWeightage": Number(this.state.revNum).toFixed(1)
            },
          ]
        });
      }else {
        this.setState({
          OCRmessage: true          
        });        
      }
  };

  render() {
    let suggestionDiv;
    if (this.state.showSuggestions) {
      suggestionDiv = (
        <Suggestions
          suggestions={this.state.suggestions}
          suggestionClickHandler={(s) => this.suggestionClickHandler(s)}
        ></Suggestions>
      );
    } else {
      suggestionDiv = <div></div>;
    }

    return (
      <ErrorBoundary>
        <div class="create-workspace-container">
          <AppHeader />
          <BreadCrumbs />
          <MuiThemeProvider>
            <div class="user-form-wrapper">
              <div class="user-formblank">
                <p class="create-text">Create Project Workspace</p>
                <img src="/images/icon_workspace.svg" class="icon-workspace" />
              </div>
              <div className="userform-wrapper">
                <div class="user-form">
                  <TextField
                    error={this.state.workSpaceError}
                    autoFocus
                    margin="dense"
                    name="WorkspaceName"
                    id="1"
                    label="Project Workspace Name"
                    type="text"
                    onChange={this.handleChange}
                    defaultValue={UserStore.NewworkspaceName}
                    fullWidth
                    helperText={this.state.workSpaceMessage}
                  />

                  <TextField
                    error={this.state.projectNameError}
                    margin="dense"
                    id="name2"
                    name="projectName"
                    label="Client Name"
                    onChange={this.handleChange}
                    defaultValue={UserStore.NewProjectName}
                    fullWidth
                    helperText={this.state.projectNameMessage}
                  />

                  <TextField
                    error={this.state.ModelNameError}
                    margin="dense"
                    id="name3"
                    name="modelName"
                    label="Model Name"
                    onChange={this.handleChange}
                    defaultValue={UserStore.NewModelName}
                    fullWidth
                    helperText={this.state.ModelNameMessage}
                  />

                  <div
                    className="tick-phrase"
                    style={{
                      font: "normal normal normal 1vw Helvetica",
                      marginLeft: "3%",
                      paddingTop: "0.5vw",
                    }}
                  >
                    <input
                      type="radio"
                      name="phrase"
                      id="phrase"
                      value="Phrase"
                      defaultChecked
                      onChange={this.radioInput}
                    />
                    <label for="phrase" style={{ padding: "0 1.5vw 0 0.3vw" }}>
                      Phrase Based
                    </label>
                    <input
                      type="radio"
                      name="phrase"
                      id="tickregular"
                      value="TickRegular"
                      onChange={this.radioInput}
                    />
                    <label
                      for="tickregular"
                      style={{ padding: "0 0.5vw 0 0.3vw" }}
                    >
                      Tick Based{" "}
                    </label>
                  </div>
                  {this.state.tick ? 
                  <div
                    className="custom"
                    style={{
                      font: "normal normal normal 1vw Helvetica",
                      marginLeft: "3%",
                      paddingTop: "0.5vw",
                      color: "#0c629b",
                    }} 
                  >
                    <span style={{marginTop:"1vw"}}>Custom Vision processing required ?</span>
                    <span style ={{paddingLeft:"1vw"}}>
                    <input
                      type="radio"
                      name="customVisionData"
                      id="no"
                      value="no"
                      defaultChecked
                      onChange={this.customVisionInput}
                    />
                    <label for="no" style={{ padding: "0 1.5vw 0 0.3vw" }}>
                     No
                    </label>
                    <input
                      type="radio"
                      name="customVisionData"
                      id="yes"
                      value="yes"
                      onChange={this.customVisionInput}
                    />
                    <label
                      for="yes"
                      style={{ padding: "0 0.5vw 0 0.3vw" }}
                    >
                      Yes{" "}
                    </label>
                    </span>
                  </div>
                  : <></>}

                  {this.state.customVision ? 
                  <div style={!this.state.tick ? {display : "none"} : {} }>
                    <TextField className="modelNameCV" id="modelNameCV" label = "Custom Vision Prediction Key" style = {{paddingLeft: "1.5vw",width:"15vw"}}></TextField>
                    <TextField className="modelIdCV" id="modelIdCV"  label = "Custom Vision Model Name" style = {{paddingLeft: "4vw",width:"17vw"}}></TextField>
                  </div> : <></>}

                  <div className="users-bar">
                    <div
                      className="input-group"
                      onKeyDown={(e) => this.onEnterButton(e)}
                    ></div>
                  </div>
                  <br></br>
                  <div className="Link" >
                    <span
                      tabIndex={0}
                      onClick={() => {
                        this.setState({ show: true });
                      }}
                    >
                      Add User
                    </span>
                  </div>

                  <div className="Link">
                    <span
                      tabIndex={0}
                      onClick={() => {
                        this.setState({ OCRshow: true,
                          OCRmessage: false,
                          ocrweightage: []
                        });
                      }}
                    >
                      Add OCR Weightage
                    </span>
                  </div>

                  <div className="users-list">
                    {this.state.list.join(",")}
                    {this.val}
                  </div>

                  <div className="action-btns">
                    <Button
                      variant="contained"
                      class="next-btn"
                      onClick={this.continue}
                      style={{
                        margin: "-2vw 1.9vw 0 1vw",
                        width: "8.4vw",
                        height: "2.29vw",
                        fontSize: "0.9vw",
                        background: "#0c629b",
                        color: "#ffffff",
                        textTransform: "none",
                      }}
                    >
                      Next
                    </Button>

                    <Button
                      variant="contained"
                      class="cancel-btn"
                      onClick={() => this.props.history.push("/workspace")}
                      style={{
                        background: "#ffffff",
                        border: "0.05vw solid #0c629b",
                        borderRadius: "0.15vw",
                        width: "8.4vw",
                        height: "2.29vw",
                        marginTop: "-2vw",
                        padding: "0.15vw 0",
                        fontSize: "0.9vw",
                        color: "#0c629b",
                        textTransform: "none",
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                  <Modal
                    className="user-select-popup"
                    show={this.state.userEmpty}
                    onHide={this.handleCloseEmptyUser}
                    backdrop="static"
                    keyboard={false}
                    dialogClassName="fullmodal"
                    size="l"
                    centered
                  >
                    <Modal.Body>
                      <label
                        style={{
                          font: "normal normal bold 1vw Helvetica",
                          textAlign: "left",
                          padding: "1vw",
                        }}
                      >
                        {this.state.message}
                        {this.state.duplicateData ? (
                          <p>Project workspace name already exists.</p>
                        ) : (
                          ""
                        )}
                        {this.state.workSpacePopupError ||
                        this.state.clientPopupError ||
                        this.state.modelPopupError ? (
                          <p>
                            select at least one user to create new Project
                            Workspace
                          </p>
                        ) : (
                          ""
                        )}
                        {this.state.emptyUser &&
                        !(
                          this.state.workSpacePopupError ||
                          this.state.clientPopupError ||
                          this.state.modelPopupError
                        ) ? (
                          <p>
                            Please select at least one user to create new
                            Project Workspace{" "}
                          </p>
                        ) : (
                          ""
                        )}
                        <IconButton
                          onClick={this.handleCloseEmptyUser}
                          style={{
                            padding: "0.156vw",
                            position: "absolute",
                            top: "0",
                            right: "0",
                            background: "",
                            borderRadius: "0",
                            color: "#0C629B",
                          }}
                        >
                          {" "}
                          <CloseIcon
                            style={{ width: "1.5vw", height: "1.5vw" }}
                          />{" "}
                        </IconButton>
                      </label>
                    </Modal.Body>
                  </Modal>
                </div>
              </div>

              <Dialog
                className="addUserTable"
                open={this.state.show}
                onClose={this.handleClose}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle id="form-dialog-title">
                  User Details
                  <IconButton onClick={this.handleClose}>
                    {" "}
                    <CloseIcon />{" "}
                  </IconButton>
                </DialogTitle>
                <DialogContent>
                  {
                    <table
                      width="5.208vw"
                      onClick={(e) => {
                        this.setState({ showSuggestions: false });
                      }}
                    >
                      <tr>
                        <td>
                          <div
                            className="input-group"
                            onKeyDown={(e) => this.onEnterButton(e)}
                            style={{ padding: "0.95vw 0 0 0" }}
                          >
                            <div className="suggestions" ref={this.wrapperRef}>
                              <Tooltip
                                title="Please type 3 characters to search for user"
                                arrow
                              >
                                <TextField
                                  autoComplete="off" // setting for browsers; not the app
                                  type="text"
                                  id="search-box1"
                                  className="form-control"
                                  placeholder="Add users"
                                  title="Please type 3 characters to search for user"
                                  onChange={this.onChangeHandler1}
                                  onPaste={(e) => {
                                    this.pasteFunction(e);
                                  }}
                                  onBlur={(e) => {}}
                                  onClick={() => {
                                    if (this.state.suggestions.length > 0)
                                      this.setState({ showSuggestions: true });
                                  }}
                                  error={this.state.tooltipError}
                                  helperText={this.state.tootlTipErrorMessage}
                                />
                              </Tooltip>
                              {suggestionDiv}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div
                            className="assign-role"
                            style={{
                              top: "4.22vw",
                              right: "6vw",
                            }}
                          >
                            <TextField
                              autoFocus
                              margin="dense"
                              id="assignRole"
                              select
                              className="assign-role-dropdown"
                              label="Assign User Group"
                              onChange={this.handleRoleChange}
                              value={this.state.dropdownvalue}
                              SelectProps={{ native: true }}
                            >
                              {this.state.roles.map((option) => (
                                <option
                                  key={option.roleId}
                                  value={option.roleName}
                                >
                                  {option.roleName}
                                </option>
                              ))}
                            </TextField>
                          </div>
                        </td>
                        <td>
                          <p
                            class="add-users-icon"
                            onClick={this.AddUser}
                            style={{
                              top: "6.3vw",
                              left: "40vw",
                            }}
                          >
                            <Tooltip title="Add users to workspace" arrow>
                              <img
                                src="/images/icon_add_blue.svg"
                                style={{
                                  color: "#0C629B",
                                  textDecoration: "underline",
                                  height: "1.5vw",
                                  width: "1.5vw",
                                }}
                                alt="addButton"
                              />
                            </Tooltip>
                          </p>
                        </td>
                      </tr>
                      {this.state.useradd.map((items) => (
                        <tr style={{ marginTop: "1%" }}>
                          <td>
                            <div
                              style={{
                                overflow: "hidden",
                                width: "18vw",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {items.enterpriseId}
                            </div>
                          </td>
                          <td style={{ left: "20.7vw" }}>{items.roleId}</td>
                          <td style={{ textAlign: "right" }}>
                            <Tooltip title="Delete User" arrow>
                              <img
                                id={items.enterpriseId}
                                onClick={(e) => {
                                  this.handleDelete(e, items);
                                }}
                                src="/images/icon_delete.svg"
                                style={{ height: "1.3vw" }}
                              />
                            </Tooltip>
                          </td>
                        </tr>
                      ))}
                    </table>
                  }
                </DialogContent>
                <DialogActions style={{ marginRight: "1vw" }}>
                  <Button
                    variant="contained"
                    class="next-btn"
                    onClick={this.handleClose}
                    style={{ height: "4vh", width: "8vw" }}
                  >
                    Ok
                  </Button>
                </DialogActions>
              </Dialog>

              <Dialog
                className="addUserTable OCRWeightage"
                open={this.state.OCRshow}
                onClose={this.handleClose}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle id="form-dialog-title">
                  OCR Weightage
                  <IconButton onClick={this.handleClose}>
                    {" "}
                    <CloseIcon />{" "}
                  </IconButton>
                </DialogTitle>
                <DialogContent>
                  <div>
                    <label
                      for="linenum"
                      style={{ padding: "1vw 10.1vw 0 0.3vw" }}
                    >
                      Line No
                    </label>
                    <TextField
                      type="number"
                      name="linenum"
                      label="Linenum Weightage"      
                      inputProps={{
                        min: 0,
                        max: 1,
                        step: "0.1",
                      }}
                      id="linenum"
                      value={this.state.lineNum}
                      onChange= {(e) => {if(e.target.value > 0.9){this.setState({lineNumMessage: true})}
                      else if(e.target.value === 1){ this.setState({lineNum: e.target.value,lineNumMessage: false})}
                      else { this.setState({lineNum: e.target.value,lineNumMessage: false})}
                    }
                    }
                      style={{ paddingRight: "1vw" }}
                      helperText={this.state.lineNumMessage ? <p style={{color:"red"}}>Enter value less than 1</p> : ""}
                    />
                    <TextField
                      type="number"
                      name="linenumThreshold"
                      label="Linenum Threshold"
                      inputProps={{
                        min: 0,
                        max: 1,
                        step: "0.1",
                      }}
                      id="linenumThreshold"
                      value={this.state.lineNumThreshold}
                      onChange= {(e) => {if(e.target.value > 0.9){this.setState({lineNumThresholdMessage: true})}
                      else if(e.target.value === 1){ this.setState({lineNumThreshold: e.target.value,lineNumThresholdMessage: false})}
                      else { this.setState({lineNumThreshold: e.target.value,lineNumThresholdMessage: false})}
                    }
                    }
                      helperText={this.state.lineNumThresholdMessage ? <p style={{color:"red"}}>Enter value less than 1</p> : ""}
                    />
                  </div>
                  <div>
                    <label
                      for="isonum"
                      style={{ padding: "1vw 7.6vw 0 0.3vw" }}
                    >
                      Isometric No
                    </label>
                    <TextField
                      type="number"
                      name="isonum"
                      label="Isonum Weightage"
                      inputProps={{
                        min: 0,
                        max: 1,
                        step: "0.1",
                      }}
                      id="isonum"
                      value={this.state.isoNum}

                      onChange= {(e) => {if(e.target.value > 0.9){this.setState({isoNumMessage: true})}
                      else if(e.target.value === 1){ this.setState({isoNum: e.target.value,isoNumMessage: false})}
                      else { this.setState({isoNum: e.target.value,isoNumMessage: false})}
                    }
                    }
                      style={{ paddingRight: "1vw" }}
                      helperText={this.state.isoNumMessage ? <p style={{color:"red"}}>Enter value less than 1</p> : ""}
                    />
                    <TextField
                      type="number"
                      name="isonumThreshold"
                      label="Isonum Threshold"
                      inputProps={{
                        min: 0,
                        max: 1,
                        step: "0.1",
                      }}
                      id="isonum"
                      value={this.state.isoNumThreshold}
                      onChange= {(e) => {if(e.target.value > 0.9){this.setState({isoNumThresholdMessage: true})}
                      else if(e.target.value === 1){ this.setState({isoNumThreshold: e.target.value,isoNumThresholdMessage: false})}
                      else { this.setState({isoNumThreshold: e.target.value,isoNumThresholdMessage: false})}
                    }
                    }
                      helperText={this.state.isoNumThresholdMessage ? <p style={{color:"red"}}>Enter value less than 1</p> : ""}
                    />
                  </div>

                  <div>
                    <label
                      for="sheetnum"
                      style={{ padding: "1vw 9.1vw 0 0.3vw" }}
                    >
                      Sheet No
                    </label>
                    <TextField
                      type="number"
                      name="sheetnum"
                      label="Sheetnum Weightage"
                      inputProps={{
                        min: 0,
                        max: 1,
                        step: "0.1",
                      }}
                      id="sheetnum"
                      value={this.state.sheetNum}
                      onChange= {(e) => {if(e.target.value > 0.9){this.setState({sheetNumMessage: true})}
                      else if(e.target.value === 1){ this.setState({sheetNum: e.target.value,sheetNumMessage: false})}
                      else { this.setState({sheetNum: e.target.value,sheetNumMessage: false})}
                    }
                    }
                      helperText={this.state.sheetNumMessage ? <p style={{color:"red"}}>Enter value less than 1</p> : ""}
                      style={{ paddingRight: "1vw" }}
                    />
                    <TextField
                      type="number"
                      name="sheetnumThreshold"
                      label="Sheetnum Threshold"
                      inputProps={{
                        min: 0,
                        max: 1,
                        step: "0.1",
                      }}
                      id="sheetnum"
                      value={this.state.sheetNumThreshold}
                      onChange= {(e) => {if(e.target.value > 0.9){this.setState({sheetNumThresholdMessage: true})}
                      else if(e.target.value === 1){ this.setState({sheetNumThreshold: e.target.value,sheetNumThresholdMessage: false})}
                      else { this.setState({sheetNumThreshold: e.target.value,sheetNumThresholdMessage: false})}
                    }
                    }
                      helperText={this.state.sheetNumThresholdMessage ? <p style={{color:"red"}}>Enter value less than 1</p> : ""}
                    />
                  </div>
                  <div>
                    <label
                      for="revnum"
                      style={{ padding: "1vw 10.1vw 0 0.3vw" }}
                    >
                      Rev No
                    </label>
                    <TextField
                      type="number"
                      name="revnum"
                      label="Revnum Weightage"
                      inputProps={{
                        min: 0,
                        max: 1,
                        step: "0.1",
                      }}
                      id="revnum"
                      value={this.state.revNum}
                      onChange= {(e) => {if(e.target.value > 0.9){this.setState({revNumMessage: true})}
                      else if(e.target.value === 1){ this.setState({revNum: e.target.value,revNumMessage: false})}
                      else { this.setState({revNum: e.target.value,revNumMessage: false})}
                    }
                    }
                      helperText={this.state.revNumMessage ? <p style={{color:"red"}}>Enter value less than 1</p> : ""}
                      style={{ paddingRight: "1vw" }}
                    />
                    <TextField
                      type="number"
                      name="revnumThreshold"
                      label="Revnum Threshold"
                      inputProps={{
                        min: 0,
                        max: 1,
                        step: "0.1",
                      }}
                      id="revnum"
                      value={this.state.revNumThreshold}
                      onChange= {(e) => {if(e.target.value > 0.9){this.setState({revNumThresholdMessage: true})}
                      else if(e.target.value === 1){ this.setState({revNumThreshold: e.target.value,revNumThresholdMessage: false})}
                      else { this.setState({revNumThreshold: e.target.value,revNumThresholdMessage: false})}
                    }
                    }
                      helperText={this.state.revNumThresholdMessage ? <p style={{color:"red"}}>Enter value less than 1</p> : ""}
                    />
                  </div>   
                  <div style={this.state.OCRmessage ? {display: 'block', color: 'red' } : {display: 'none'}}>Add OCR Weightage and Threshold before Save</div>               
                </DialogContent>
                <DialogActions style={{ padding: "0 13vw 0 0" }}>
                  <Button
                    variant="contained"
                    onClick={this.handleSave}
                    style={{ background: "#0c629b",
                      border: "0.05vw solid #0c629b",
                      borderRadius: "0.104vw",
                      color: "#ffffff",
                      width: "8.4vw",
                      height: "2.29vw",
                      marginRight: "0.104vw",fontSize: "0.9vw",textTransform:"none" }}
                  >
                    Save
                  </Button>
                  <Button
                    variant="contained"
                    style={{background: "#ffffff",
                      border: "0.05vw solid #0c629b",
                      borderRadius: "0.15vw",
                      width: "8.4vw",
                      height: "2.29vw",
                      padding: "0.15vw 0",
                      fontSize: "0.9vw",
                      color: "#0c629b",textTransform:"none"}}
                    onClick={this.handleClose}
                  >
                    Cancel
                  </Button>
                </DialogActions>
              </Dialog>

              <Dialog
                className="delete-confirm"
                open={this.state.delete}
                onClose={this.handleCloseDialog}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle
                  id="form-dialog-title"
                  style={{ height: "0.625em" }}
                ></DialogTitle>
                <DialogContent>
                  <div style={{ fontSize: "1vw" }}>
                    Do you want to delete the user?
                  </div>
                  <IconButton
                    onClick={this.handleCloseDialog}
                    style={{
                      padding: "0",
                      float: "right",
                      background: "#0C629B",
                      color: "#fff",
                      position: "absolute",
                      right: "15%",
                    }}
                  >
                    {" "}
                    <Cancel />{" "}
                  </IconButton>
                  <IconButton
                    onClick={(e) => this.handlePurge(e, this.state.deleteUsers)}
                    style={{
                      padding: "0",
                      background: "#0C629B",
                      color: "white",
                      position: "absolute",
                      right: "2%",
                    }}
                  >
                    {" "}
                    <CircleChecked />{" "}
                  </IconButton>
                </DialogContent>
              </Dialog>

              <Dialog
                className="addUserTable"
                onClose={this.handleClose}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle id="form-dialog-title">
                  Add Users
                  <IconButton onClick={this.handleClose}>
                    {" "}
                    <CloseIcon />{" "}
                  </IconButton>
                </DialogTitle>
                <DialogContent>
                  {
                    <table border="1">
                      <thead align="center" valign="bottom" char="M">
                        <tr>
                          <th align="center">User</th>
                          <th align="center">Role</th>
                          <th align="center">Delete</th>
                        </tr>
                      </thead>
                      {this.state.useradd.map((items) => (
                        <tr>
                          <td>{items.enterpriseId}</td>
                          <td>{items.roleId}</td>
                          <td>
                            <img
                              id={items.enterpriseId}
                              onClick={(e) => {
                                if (
                                  window.confirm(
                                    "Are you sure you wish to remove this user"
                                  )
                                )
                                  this.handlePurge(e, items);
                              }}
                              src="/images/icon_delete.svg"
                              style={{ height: "1.3vw" }}
                            />
                          </td>
                        </tr>
                      ))}
                    </table>
                  }
                </DialogContent>
              </Dialog>
            </div>
          </MuiThemeProvider>
          <AppFooter />
        </div>
      </ErrorBoundary>
    );
  }
}

const ShowTheLocationWithRouter = withRouter(ProjectDetails);
