import React, { Component } from 'react'
import ProjectDetails from '../ProjectDetails/ProjectDetails';
import ProjectSetupdetails from '../ProjectSetupdetails/ProjectSetupdetails';

export class UserForm extends Component {

    state = {
        step: 1,
        WorkspaceName: '',
        PetrofacProjectName: '',
        Users: '',
        userlist:[]
    }

    // proceed to next step
    nextStep = () => {
        const { step } = this.state;
        this.setState({
            step: step + 1
        });
    }

    // proceed to previous step
    prevStep = () => {
        const { step } = this.state;
        this.setState({
            step: step - 1
        });
    }

    // handle field change
    handleChange = input => e => {
        if(e.target.name === "WorkspaceName") {
            this.setState({ WorkspaceName: e.target.value })
        }
        else {
            this.setState({ [input]: e.target.value })
        }
    }

    render() {
        const { step } = this.state;
        const { WorkspaceName, PetrofacProjectName, Users, userlist } = this.state;
        const values = { WorkspaceName, PetrofacProjectName, Users, userlist }
        const { history, location, match } = this.props;

        switch (step) {
            case 1:
                return (
                    <ProjectDetails nextStep={this.nextStep} handleChange={this.handleChange} values={values} history={history} location={location} match={match}/>
                )

            case 2:
                return (
                    <ProjectSetupdetails nextStep={this.nextStep} prevStep={this.prevStep} handleChange={this.handleChange} values={values} history={history} location={location} match={match}/>
                )
            default : return
        }
    }
}

export default UserForm;