import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import FileList from '../FileList/FileList';
import Progress from '../Progress/Progress';
import Process from '../Process/Process';
import AppFooter from '../../AppFooter/AppFooter';
import UserStore from '../../UserStore';
import './Upload.scss';
import FileLog from '../../Upload/StampGrid/FileLog';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 0,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function StampUpload() {

  const readonly = localStorage.getItem("isreadOnlyPID") ? JSON.parse(localStorage.getItem("isreadOnlyPID")): UserStore.readOnlyPID;
  const processgridvalue = localStorage.getItem("getprocessGridPID") ? Number(localStorage.getItem("getprocessGridPID")): UserStore.showprocessgridPID;
  const [value, setValue] = useState(processgridvalue);
  UserStore.selectedRowID = [];

  const handleChange = (event, newValue) => {
    setValue(newValue);    
    UserStore.getprocessGridPID(newValue);
    localStorage.setItem('getprocessGridPID', newValue);
  };

  if(localStorage.getItem("getprocessGridPID") && document.getElementsByClassName('breadcrumbBR')[0]) {
    if(localStorage.getItem("getprocessGridPID") == 0){
      document.getElementsByClassName('breadcrumbBR')[0].innerHTML = "Upload Files";
    }else if(localStorage.getItem("getprocessGridPID") == 1){
      document.getElementsByClassName('breadcrumbBR')[0].innerHTML = "Process Files";
    }else if(localStorage.getItem("getprocessGridPID") == 2){
      document.getElementsByClassName('breadcrumbBR')[0].innerHTML = "File Logs";
    }
  }

    return (
      <paper className="upload-process-container-pid">
        <div class="uploadpage-wrapper">
          <AppBar class="tabs-bar" position="static">
            <Tabs className="tab-upload" value={value} onChange={handleChange} aria-label="simple tabs example" centered>
              <Tab label="Upload Files" className="dragdrop-tab" {...a11yProps(0)} style={readonly ? {textTransform:"none", pointerEvents: "none", opacity: "0.3"} : {textTransform:"none"}}/>
              <Tab label="Process Files" className="process-tab" {...a11yProps(1)} style={{textTransform:"none"}}/>
              <Tab label="File Logs" className="process-tab" {...a11yProps(2)} style={{textTransform:"none"}}/>
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
          <div><div className='uploadNote'><span>Note:</span> Please click on Process tab to view the files after the documents are uploaded</div></div>
            <div>
              <Grid container spacing={3} justify="center" alignItems="center">
                <Grid item xs={9}>
                  <Paper class="draganddrop" style={{width: "100%", marginLeft: "0", overflow: "hidden", height: "63vh"}}>
                    <FileList />
                  </Paper>
                </Grid>
              </Grid>
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div>
              <Grid container spacing={3} justify="center" alignItems="center">
                {/* <Grid item xs={3}></Grid> */}
                <Grid item xs={12}>
                  <Paper class="draganddrop" style={{ width: "100%", marginLeft: "0" }}>
                    <Process />
                  </Paper>
                </Grid>
              </Grid>
            </div>
          </TabPanel>
          <TabPanel className="process-page" value={value} index={2} >
          <div>
          <Grid container spacing={3} justify="center" alignItems="center">
              <Grid item xs={12}>   
                <Paper class="fileLogTab" style={{width: "100%", marginLeft: "0",  marginTop: '0.52vw', height:"31.9vw"}}>
                <FileLog frompid="pid" wsId={UserStore.PIDworkspaceID}/>
                </Paper>        
              </Grid>
            </Grid>
          </div>
        </TabPanel>  
        </div>
        <AppFooter />
      </paper>
    );
}
