import React from 'react';
import './AppFooter.scss';

export default function AppFooter() {
    return (
        <div className="footer">
            <div className="footer-wrapper">
                <p>© Petrofac Limited 2021</p>
            </div>
        </div>
    )
}
