import * as React from "react";
import { withRouter } from "react-router-dom";
import {
  columnReorderDragColSelector,
  GridOverlay,
  GridToolbarContainer,
  GridToolbarExport,
} from "@material-ui/data-grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";
import { ExportToExcel } from "./ExportToExcel";
import FormControl from "@material-ui/core/FormControl";
import Modal from "react-bootstrap/Modal";
import Select from "@material-ui/core/Select";
import "./Process.scss";
import axios from "axios";
import "antd/dist/antd.css";
import ExportExcel from "../../ExportExcel";
import BootstrapTable from "react-bootstrap-table-next";
import UserStore from "../../UserStore";
import Moment from "moment";
import IconButton from "@material-ui/core/IconButton";
import BatchTable from "../../Upload/BatchTable";
import CloseIcon from "@material-ui/icons/Close";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingOverlay from "react-loading-overlay";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import "react-day-picker/lib/style.css";
import "moment/locale/en-gb";
import Cancel from "@material-ui/icons/Cancel";
import { toJS } from "mobx";
import { DatePicker } from "antd";
import CircleChecked from "@material-ui/icons/CheckCircleRounded";
import Assessment from "@material-ui/icons/Assessment";
import ProcessGridPopup from "./ProcessGrid/ProcessGridPopup";
import ProcessGridTable from "./ProcessGrid/ProcessGridTable";
import Tooltip from "@material-ui/core/Tooltip";
import { isArray } from "@microsoft/applicationinsights-core-js";
import {
  selectAsset,
} from "../../redux/actions/documentActions";
import { useDispatch } from "react-redux";
var rowget = [];

const dateFormat = "DD/MMM/YYYY";
var startdate = "";
var enddate = "";
var enddate1 = "";
let searchrows = false;
const fileName = "MTO";

const API_Endpoint = process.env.REACT_APP_API_ENDPOINT;

var today = new Date();

class Process extends React.Component {
  constructor(props) {
    const editWorkSpaceData1 = toJS(UserStore.editRowPID)[0];
    if (!editWorkSpaceData1) {
      startdate = localStorage.getItem("startdateforProcess");
      enddate = localStorage.getItem("enddate");
    } else {
      const createdOn1 = editWorkSpaceData1.startDate.toString();
      var stdate = Moment(createdOn1, "DD/MM/YYYY");
      stdate = Moment(stdate).format("DD/MMM/YYYY");
      startdate = stdate;
      enddate1 = Moment(
        today.getFullYear() +
          "-" +
          (today.getMonth() + 1) +
          "-" +
          today.getDate()
      ).format("DD/MMM/YYYY");
      enddate = enddate1.toString();
      Moment(enddate).format("YYYY-MM-DD");
    }

    super(props);
    this.state = {
      initvalue: "All",
      // UserStore.filesFilterPID,
      deleteshow: false,
      dateValidation: false,
      dateValidationValue: "",
      pdfUri: "",
      selectedrowforDeletion: [],
      noOfRowSelected: 0,
      rows1: [], 
      showFilter: false,
      loading: true,
      batchData: [],
      columns: [
        { accessor: "serialNumber", Header: "Sr No." },
        {
          accessor: "fileName",
          Header: "File Name",
        },
        { accessor: "pidNumber", Header: "PID No." },
        {
          accessor: "pidRevisionNumber",
          Header: "PID Rev No.",
        },
        { accessor: "sheetNumber", Header: "Sheet No." },
        {
          accessor: "pidDescription",
          Header: "PID Description",
        },
        {
          accessor: "fileUploadDttm",
          Header: "Upload Date & Time Stamp",
        },
        {
          accessor: "fileUploadedBy",
          Header: "Upload User Name",
        },
        {
          accessor: "isProcessed",
          Header: "Processed",
        },
        {
          accessor: "processedPercentage",
          Header: "Processed Percentage",
        },
        { accessor: "isApproved", Header: "Approved" },
        {
          accessor: "approvedDttm",
          Header: "Approved Date & Time Stamp",
        },
        {
          accessor: "totalLineCount",
          Header: "Total Line Count",
        },
        {
          accessor: "totalValveCount",
          Header: "Total Valve Count",
        },
        {
          accessor: "isDuplicateFile",
          Header: "Duplicate File",
        },
        {
          accessor: "isSuperseded",
          Header: "Superseded",
        },
      ],
      deletedRows: [],
      selectedRows: [],
      CountAll: 0,
      CountProcessfailed: 0,
      searchvalue: "Search by document name",
      id: "",
      show: false,
      initarr: [],
      fillarr: [],
      startDate: "",
      endDate: localStorage.getItem("enddate") ? localStorage.getItem("enddate") : Moment(new Date()).format('MM/DD/YYYY'),
      showuser: true,
      showDownload: false,
      previewValue: false,
      showBatchStatusDialog: false,
      wsId: UserStore.PIDworkspaceID,
      showBatchStatus: false,
      disablePreview: true,
      disableExportPID: true,
      disableExportMTO: true,
      responseIsometrics: {},
      fileprocessedStatus: true,
      exportPIDValue: false,
      exportMTOValue: false,
      PIDShow: false,
      MTOShow: false,
      superseded: "",
      accepteddocpid: false,
      assetsData: [],
      dropChange: false,
      dropArr: [],
      saveHeaders: [],
      editSheetID: 0,
      selected: [],
    };
    localStorage.removeItem("setreportStamp");
    UserStore.setreportStamp(false);
    UserStore.setAccuracyPID([]);
    // handleFilterChange(UserStore.filesFilterPID);
  }


  //Used to close the batch status popup
  handleCloseBatchStatus = () => {
    this.setState({ showBatchStatusDialog: false });
  };

  //Used to display the batch status popup
  showBatchStatus = async () => {
    const res = axios
      .get(API_Endpoint + `/GetPidBatchStatus?workSpaceId=${this.state.wsId}`)
      .then((res) => {
        if (res.data.length !== 0) {
          this.setState({ showBatchStatusDialog: true, batchData: res.data });
        } else {
          this.setState({ showBatchStatus: true });
        }
      });
  };

  //Used to close the popup if no batches are created
  handleCloseBatch = () => {
    this.setState({ showBatchStatus: false });
  };

  //Used to start the processing of a file
  handleRun = () => {
    if (toJS(UserStore.selectedRowID).length) {
      const selectedIdList = toJS(UserStore.selectedRowID).map((item) => {
        return item.sheetId;
      });
      axios.post(API_Endpoint + `/RunProcessPid`, { sheetIds: selectedIdList });
      // refreshProcessedStatus();
      this.setState({
        runShow: true,
        runValue: true,
      });
    } else {
      this.setState({
        runShow: true,
        runValue: false,
      });
    }
  };

  //Used to download the PID images in ZIP format
  handleExportPID = () => {
    const selectedIdList = toJS(UserStore.selectedRowID).map((item) => {
      return item.sheetId;
    });

    if (selectedIdList.length >= 1) {
      fetch(API_Endpoint + `/DownloadProcessedPidsZip`, {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${UserStore.accesstoken}`,
        },
        body: JSON.stringify({ sheetIds: selectedIdList }),
      }).then((response) => {
        response.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = `PID_${UserStore.PIDworkspaceID}`;
          a.click();
        });
      });
      this.setState({
        PIDShow: false,
        exportPIDValue: false,
      });
    } else {
      this.setState({
        PIDShow: true,
        exportPIDValue: true,
      });
    }
  };

  //Used to change the Upload Date
  handleUploadDateChange = (day) => {
    let date1 = Moment(day).format("DD-MMM-YYYY");
    localStorage.setItem("startdateforProcess", date1);
    let date = Moment(day).format("YYYY-MM-DD");
    this.state.startDate = date;
    this.handleDatechange();
    if (
      Moment(this.state.startDate).format("YYYY/MM/DD") >
      Moment(this.state.endDate).format("YYYY/MM/DD")
    ) {
      this.setState({
        dateValidation: true,
        dateValidationValue: "Upload Date cannot be beyond To Date",
      });
    }
  };

  //Used to change the To Date
  handleToDateChange = (day) => {
    let date1 = Moment(day).format("DD-MMM-YYYY");
    localStorage.setItem("enddateforProcess", date1);
    localStorage.setItem("enddate", date1);
    
    let date = Moment(day).format("YYYY-MM-DD");
    UserStore.setPIDToDate(date);
    this.state.endDate = date;
    this.handleDatechange();
    if (
      Moment(this.state.endDate).format("YYYY/MM/DD") <
      Moment(this.state.startDate).format("YYYY/MM/DD")
    ) {
      this.setState({
        dateValidation: true,
        dateValidationValue: "To Date cannot be earlier than Upload Date",
      });
    }
  };

  //Used to close the validation popups
  handleClose = () => {
    this.setState({
      show: false,
      previewValue: false,
      runShow: false,
      PIDShow: false,
    });
  };

  //Used to close Delete validation message popup
  handledeleteClose = () => {
    this.setState({ deleteshow: false });
  };

  //Used to close Export PID validation message popup
  handleDownloadClose = () => {
    this.setState({ showDownload: false });
  };

  //Used to close the Date validation message popups
  handleDateClose = () => {
    this.setState({ dateValidation: false });
  };

  //Used to display the Preview validation message popup
  handleShow = () => {
    let selectedrowstodelete = [];
    selectedrowstodelete = toJS(UserStore.selectedRowID);
    this.state.selectedRows = selectedrowstodelete;
    if (
      this.state.selectedRows[0] === undefined ||
      this.state.selectedRows.length > 1
    ) {
      const messageToSet =
        selectedrowstodelete.length > 1
          ? "Please select only one file to preview."
          : "Please select a file to preview.";
      this.setState({
        show: true,
        previewValue: true,
        errorMEssage: messageToSet,
      });
    }
    if (this.state.selectedRows.length === 1) {
      this.navigateToPreview();
    }
  };

  lineNumber(params) {
    return (
      <Tooltip title={params.row.lineNumber} arrow>
        <div className="linenumber">{params.row.lineNumber}</div>
      </Tooltip>
    );
  }

  //Used to display data for the first time after hitting the api
  async componentDidMount() {
    localStorage.setItem("startdateforProcess", this.state.startDate);
    localStorage.setItem("enddateforProcess", this.state.endDate);
    const user = "Admin";
    if (user === "read") {
      this.setState({
        showuser: false,
      });
    }
    localStorage.removeItem("itemChange");

    const workspaceId = UserStore.PIDworkspaceID;
    const editWorkSpaceData = toJS(UserStore.editRowPID);

    if (editWorkSpaceData.length) {
      const convertEndate = Moment(
        today.getFullYear() +
          "-" +
          (today.getMonth() + 1) +
          "-" +
          today.getDate()
      ).format("MM/DD/YYYY");
     const stringEndDate = convertEndate.toString();
      localStorage.setItem("startdate", editWorkSpaceData[0].startDate);
      localStorage.setItem("enddate", Moment(stringEndDate).format("DD/MMM/YYYY"));
      startdate = editWorkSpaceData[0].startDate;
      enddate = Moment(stringEndDate).format("DD/MM/YYYY");

      this.setState({
        startDate: editWorkSpaceData[0].startDate,
        endDate: Moment(stringEndDate).format("DD/MMM/YYYY"),
      });
    } else {
      startdate = localStorage.getItem("startdate");
      // enddate=  Moment(localStorage.getItem("enddate")).format("MM/DD/YYYY")
      enddate =  this.state.endDate
      let date = Moment(startdate).format("YYYY-MM-DD");
      let date2 = Moment(enddate).format("YYYY-MM-DD");
      var stdate = date;
      var etdate = date2;
      this.setState({
        startDate: stdate,
        endDate: etdate,
      });
    }
    let res = await axios.get(
      API_Endpoint +
        `/GetProcessedPids?projectWorkSpaceId=${workspaceId}&fromDate=${startdate}&toDate=${this.state.endDate}`
    );

    var myrows;
    if (UserStore.PIDFilterRetain === false) {
      this.setState({ responseIsometrics: res.data });
      this.setState({ loading: false });
      myrows = this.state.responseIsometrics;
    } else {
      this.setState({ responseIsometrics: res.data });
      this.setState({ loading: false });
      myrows = this.state.responseIsometrics;
      let mynewrows = [];
      mynewrows = toJS(UserStore.processData);
      // mynewrows = res.data.filter(
      //   (sr) => sr.splitedFileId === mynewrows[0].splitedFileId
      // );
      mynewrows = res.data.filter((o1) =>
        mynewrows.some((o2) => o1.sheetId === o2.sheetId)
      );
      myrows = mynewrows;

      this.setState({ loading: false });
      this.setState({ responseIsometrics: myrows });
    }

    rowget = [];
    if (myrows.length) {
      myrows.map((items, index) => {
        const row2 = this.dataRows(items);

        // const flagPreview = UserStore.filtered
        // const latFilterData = toJS(UserStore.PIDProcessFilterData)

        // if(flagPreview){
        //   this.state.rows1.push(latFilterData);
        // }
        this.state.rows1.push(row2);
        rowget.push(row2);
      });
      UserStore.setProcessData(rowget);
    }

    this.setState({
      initarr: rowget,
      fillarr: this.state.rows1,
    });
  }

  //Used to refresh the table data
  async refreshProcessedStatus() {
    let res;
    //var stdate = Moment(startdate).format("YYYY-MM-DD");
    var stdate = Moment(this.state.startDate).format("YYYY-MM-DD");
    var etdate = Moment(this.state.endDate).format("YYYY-MM-DD");
    const workspaceId = UserStore.PIDworkspaceID;
    this.setState({ rows1: [], loading: true });
    UserStore.setPIDFilterRetain(false);
    UserStore.selectedRowID = [];
    UserStore.reportData = [];
    res = await axios.get(
      API_Endpoint +
        `/GetProcessedPids?projectWorkSpaceId=${workspaceId}&fromDate=${stdate}&toDate=${etdate}`
    );

    this.setState({ responseIsometrics: res.data });

    if (res.status === 400) {
      toast.error("Request error:" + res.data.map((row) => row.error), {
        // Set to 5sec
        position: toast.POSITION.TOP_LEFT,
        autoClose: 5000,
      });
      this.setState({ loading: false });
    } else {
      this.setState({
        loading: false,
      });
      const myrows = this.state.responseIsometrics;
      rowget = [];
      if (myrows.length) {
        myrows.map((items, index) => {
          const row2 = this.dataRows(items);
          this.state.rows1.push(row2);
          rowget.push(row2);
        });
      }

      this.setState({
        initarr: rowget,
        fillarr: this.state.rows1,
        deletedRows: [],
        selectedRows: [],
        disableExportMTO: true,
        disableExportPID: true,
        initvalue: "All",
      });

      UserStore.setreportStamp(false);
      localStorage.setItem("setreportStamp", false);
    }
  }

  CustomNoRowsOverlay() {
    return (
      <GridOverlay>
        <div>No Rows</div>
      </GridOverlay>
    );
  }

  //Used to display the data depending on the selection in File Status Ta`b
  handleDropdownChange = (e) => {
    const fillarr = [...this.state.rows1];
    this.state.initvalue = e.target.value;
    UserStore.setFilesFilterPID(e.target.value);
    UserStore.setSkipData(0);
    localStorage.setItem("filterChange","change");
    if (e.target.value === "All") {
      this.setState({ dropChange: true });
      this.setState({
        rows1: this.state.fillarr,
        dropArr: this.state.fillarr,
      });
      UserStore.setProcessData(this.state.fillarr);
    } else if (e.target.value === "Processed") {
      this.setState({ dropChange: true });
      const rowsneeded = this.state.fillarr.filter((row) => {
        return row.isProcessed === "Yes";
      });
      this.setState({
        rows1: rowsneeded,
        dropArr: rowsneeded,
      });
      UserStore.setProcessData(rowsneeded);
    } else if (e.target.value === "Processing Failed") {
      this.setState({ dropChange: true });
      const rowsneeded = this.state.fillarr.filter((row) => {
        return row.isProcessed === "No";
      });
      this.setState({
        rows1: rowsneeded,
        dropArr: rowsneeded,
      });
      UserStore.setProcessData(rowsneeded);
    }
  };

  //Used to specify the initial data
  dataRows = (items) => {
    const row = {
      sheetId: items.sheetId != null ? items.sheetId : "",
      serialNumber: items.serialNumber,
      fileName: items.fileName != null ? items.fileName : "",
      pidNumber:
        items.pidNumber != null
          ? items.pidNumber
          : items.isProcessed === false && items.processedPercentage == 0
          ? "Not Detected"
          : "",
      pidRevisionNumber:
        items.pidRevisionNumber != null
          ? items.pidRevisionNumber
          : items.isProcessed === false && items.processedPercentage == 0
          ? "Not Detected"
          : "",
      sheetNumber: items.sheetNumber != null ? items.sheetNumber : "",
      pidDescription:
        items.pidDescription != null
          ? items.pidDescription
          : items.isProcessed === false && items.processedPercentage == 0
          ? "Not Detected"
          : "",
      fileUploadDttm:
        items.fileUploadDttm != ""
          ? Moment.utc(items.fileUploadDttm, "DD/MM/YYYY hh:mm:ss a")
              .local()
              .format("DD/MM/YYYY hh:mm:ss a")
          : "",
      fileUploadedBy: items.fileUploadedBy != null ? items.fileUploadedBy : "",
      isProcessed:
        items.isProcessed != null ? (items.isProcessed ? "Yes" : "No") : "",
      processedPercentage:
        items.processedPercentage != null
          ? items.processedPercentage + "%"
          : "",
      isApproved:
        items.isApproved != null ? (items.isApproved ? "Yes" : "No") : "",
      approvedDttm:
        items.approvedDttm != ""
          ? Moment.utc(items.approvedDttm, "DD/MM/YYYY hh:mm:ss a")
              .local()
              .format("DD/MM/YYYY hh:mm:ss a")
          : "",
      totalLineCount: items.totalLineCount != null ? items.totalLineCount : "",
      totalValveCount:
        items.totalValveCount != null ? items.totalValveCount : "",
      isDuplicateFile:
        items.isDuplicateFile != null
          ? items.isDuplicateFile
            ? "Yes"
            : "No"
          : "",
      isSuperseded:
        items.isSuperseded != null ? (items.isSuperseded ? "Yes" : "No") : "",
      rejectionRemark:
        items.rejectionRemark !== null ? items.rejectionRemark : "",
    };
    return row;
  };

  //Used to get the data with modified date values
  handleDatechange = async () => {
    this.setState({
      fillarr: [...this.state.rows1],
    });
    if (
      this.state.startDate === "Invalid date" ||
      this.state.endDate === "Invalid date"
    ) {
      this.setState({
        dateValidation: true,
        dateValidationValue: "Upload Date or To Date cannot be empty",
      });
    } else {
      var fromDate = this.state.startDate;
      fromDate = Moment(fromDate).format("YYYY-MM-DD");

      var toDate = this.state.endDate;
      toDate = Moment(toDate).format("YYYY-MM-DD");
      this.setState({
        endDate: toDate,
      });

      rowget = [];
      const workspaceIdfordropdown = UserStore.PIDworkspaceID;
      this.setState({ loading: true });
      let res;
      res = await axios.get(
        API_Endpoint +
          `/GetProcessedPids?projectWorkSpaceId=${workspaceIdfordropdown}&fromDate=${fromDate}&toDate=${toDate}`
      );

      if (res.status === 200) {
        this.setState({
          rows1: [],
          loading: false,
        });
      }

      if (res.data.length != 0) {
        const myrows = res.data;
        rowget = [];
        myrows.map((items, index) => {
          const row2 = this.dataRows(items);
          rowget.push(row2);
        });

        this.setState({
          rows1: rowget,
          fillarr: rowget,
        });
      } else {
        const feed = [];
        this.setState({
          rows1: feed,
          fillarr: rowget,
        });
      }
    }
  };

  //Used to handle the change in text in search box
  OnChangeHandler = () => {
    const searchTerm1 = document.getElementById("searchbox").value;
    
    if (this.state.dropArr.length > 0 && searchTerm1 === "") {
      this.setState({
        rows1: this.state.dropArr,
      });
    } else if (
      toJS(UserStore.PIDFilterData).length !== 0 &&
      searchTerm1 === ""
    ) {
      this.state.rows1 = this.state.initarr;
      this.doSomethingWith();
    } else if (searchTerm1 === "") {
      this.state.rows1 = this.state.initarr;
      this.doSomethingWith();
      this.refreshProcessedStatus();
    }
    if(UserStore.afterSearch && searchTerm1 != toJS(UserStore.afterSearch)){
      UserStore.setAfterSearch(searchTerm1);
      this.setState({rows1: this.state.initarr})
    }
  };

  //Able to use Enter keyword for searching purpose
  keyDownSearch = (e) => {
    if (e.charCode === 13 || e.keyCode === 13) {
      this.doSomethingWith();
    }
  };

  handleCallback = (childData) => {
    this.setState({ selectedRows: childData });
  };

  //Used to display searched values after clicking on Enter button
  doSomethingWith = () => {
    let searchTerm = document.getElementById("searchbox").value;
    UserStore.setAfterSearch(searchTerm);
    if (this.state.dropChange === true) {
      let searchData1 = [];
      const dataFromDropdown =
        this.state.dropArr.length > 0 ? this.state.dropArr : this.state.initarr;
      if (
        searchTerm === "Search by document name" ||
        searchTerm === "" ||
        searchTerm.length === 0
      ) {
        searchData1 = dataFromDropdown;
        searchrows = false;
        this.setState({
          rows1: searchData1,
        });
        UserStore.setProcessData(searchData1);
      } else {
        searchrows = true;
        searchData1 = dataFromDropdown.filter((row) => {
          return row.fileName.toLowerCase().includes(searchTerm.toLowerCase());
        });
        this.setState({
          rows1: searchData1,
        });
        UserStore.setPIDSearchData(searchData1);
        UserStore.setProcessData(searchData1);
      }
      setTimeout(() => {
        searchrows = false;
      }, 1000);
    }

    let searchData = [];
    const dataFromFiltering =
      toJS(UserStore.PIDFilterData).length > 0
        ? toJS(UserStore.PIDFilterData)
        : this.state.initarr;
    if (
      searchTerm === "Search by document name" ||
      searchTerm === "" ||
      searchTerm.length === 0
    ) {
      searchData = dataFromFiltering;
      searchrows = false;
      this.setState({
        rows1: searchData,
      });
      UserStore.setProcessData(searchData);
    } else {
      searchrows = true;
      searchData = dataFromFiltering.filter((row) => {
        return row.fileName.toLowerCase().includes(searchTerm.toLowerCase());
      });
      this.setState({
        rows1: searchData,
      });
      UserStore.setPIDSearchData(searchData);
      UserStore.setProcessData(searchData);
    }
    setTimeout(() => {
      searchrows = false;
    }, 1000);
  };

  //Used to Enable or Disable the functionalities depending on processing
  handleRowSelection = async (item,selection="") => {
    if(item.length === 0) {
      this.setState({ disablePreview: true });
      this.setState({ disableExportPID: true });
      this.setState({ disableExportMTO: true });
      this.setState({selected: [...item]})
      return;
    }
    else if(isArray(item) && selection !== "all") {
      this.setState({selected: [...item]})
    }
    else if(!isArray(item)) {
      this.state.selected.push(item);
    }
    let deselectedItem = [];
    if (isArray(item) ) {
      deselectedItem = item;
    }

    UserStore.setreportStamp(true);
    let rows = [];
    if(this.state.selected.length > 1) {
      rows = this.state.selected;
    }
    if(deselectedItem.length > 0) {
      rows = deselectedItem;
    }
    
    // else if()
    if(rows.length > 0) {
      this.setState({ disablePreview: true });
      this.setState({ disableExportPID: true });
      this.setState({ disableExportMTO: true });
      for(let index in rows) {
        if (rows[index].isProcessed === "No") {
          this.setState({ disablePreview: false });
          this.setState({ disableExportPID: false });
          this.setState({ disableExportMTO: false });
          return;
        } 
      }
      
    }
    else {
    if (item) {
      if (item.isProcessed === "No") {
        this.setState({ disablePreview: false });
        this.setState({ disableExportPID: false });
        this.setState({ disableExportMTO: false });
      } else {
        this.setState({ disablePreview: true });
        this.setState({ disableExportPID: true });
        this.setState({ disableExportMTO: true });
        UserStore.setreportStamp(true);
        localStorage.setItem("setreportStamp", true);
      }
    }
  }

    if (
      deselectedItem &&
      deselectedItem.length >= 1 &&
      deselectedItem[0].isProcessed === "No"
    ) {
      this.setState({ disablePreview: false });
      this.setState({ disableExportPID: false });
      this.setState({ disableExportMTO: false });
    }
  };

 

  //Used to display the Delete confirmation popup after clicking on Delete button
  handleDeleteFiles = () => {
    this.state.selectedRows = toJS(UserStore.selectedRowID);
    if (this.state.selectedRows.length === 0) {
      this.setState({
        deleteshow: true,
      });
    } else {
      this.setState({ deletePopup: true });
    }
  };

  //Used to close the Delete confirmation popup
  handleDeleteFilesClose = () => {
    this.setState({ deletePopup: false });
  };

  //Used to delete the selected rows
  handlePurge = async () => {
    this.setState({ deletePopup: false });
    this.state.selectedRows = toJS(UserStore.selectedRowID);
    let rowId = this.state.selectedRows.map((i) => i.sheetId);
    if (rowId.length >= 1) {
      var res = await axios.post(
        API_Endpoint + `//DeleteProcessedPID`,
        JSON.stringify({ sheetIds: rowId })
      );
      UserStore.selectedRowID = [];
      if (res.status === 200) {
        this.refreshProcessedStatus();
      }
    } else {
      this.setState({
        deleteshow: true,
      });
    }
  };

  handleBlurEdit = (e, items) => {
    const editClass = e.target.className;
    const header = e.target.id.split("_")[1];
    const editableEle = document.getElementsByClassName(editClass);
    let editObj = { sheetId: parseInt(header) };
    let editValue;
    let editableID;
    for (let i = 0; i < editableEle.length; i++) {
      editValue = editableEle[i].innerHTML;
      editableID = editableEle[i].id.split("_")[0];
      editObj = {
        ...editObj,
        [editableID]: editValue === "Not Detected" ? null : editValue,
      };
    }
    if (!editObj.hasOwnProperty("pidNumber")) {
      editObj = { ...editObj, ["pidNumber"]: items.pidNumber };
    } else if (!editObj.hasOwnProperty("pidRevisionNumber")) {
      editObj = { ...editObj, ["pidRevisionNumber"]: items.pidRevisionNumber };
    } else if (!editObj.hasOwnProperty("pidDescription")) {
      editObj = { ...editObj, ["pidDescription"]: items.pidDescription };
    }
    this.handleEditedValue(editObj, header);
  };

  handleEditedValue = (editObj, id) => {
    if (!this.state.saveHeaders.length) {
      this.state.saveHeaders.push(editObj);
    } else {
      const found = this.state.saveHeaders.filter((el) => el.sheetId == id);
      if (found.length < 1) {
        this.state.saveHeaders.push(editObj);
      } else {
        const slicedEditData = this.state.saveHeaders.filter(
          (x) => x.sheetId != id
        );
        slicedEditData.push(editObj);
        this.state.saveHeaders = slicedEditData;
      }
    }
  };

  handleSave = async () => {
    if (this.state.saveHeaders.length === 0) {
      this.setState({
        show: true,
        errorMEssage:
          "Please edit atleast one field to perform the Save Operation",
      });
    }
    let res;
    if (this.state.saveHeaders.length > 0) {
      res = await axios.post(API_Endpoint + `//UpdatePidHeader`, {
        pidHeaders: this.state.saveHeaders,
      });
      if (res.status === 200) {
        toast.success("Saved Successfully");
      }
    }
  };

  //Used to move to the preview page after user clicks Preview button
  navigateToPreview = () => {
    UserStore.setPIDToDate(this.state.endDate);
    UserStore.setSheetId(this.state.selectedRows[0].sheetId);
    localStorage.setItem("pidnumber", this.state.selectedRows[0].pidNumber);
    localStorage.setItem("sheetId", this.state.selectedRows[0].sheetId);
    localStorage.setItem("prevRoute", "process");
    localStorage.setItem("height", "");
    localStorage.setItem("width", "");
    localStorage.setItem("imageData", "");
  //   const selectedItem = {
  //     id : undefined
  //   }
  //   const dispatch = useDispatch();
  // dispatch(selectAsset(selectedItem))
    if (this.state.selectedRows[0].isProcessed === "Yes") {
      localStorage.setItem("processedpid", true);
    } else {
      localStorage.setItem("processedpid", false);
    }
    if (this.state.selectedRows[0].isApproved === "Yes") {
      UserStore.isaccepteddocpid(true);
      localStorage.setItem("accepteddocpid", true);
    } else {
      UserStore.isaccepteddocpid(false);
      localStorage.setItem("accepteddocpid", false);
    }
    this.props.history.push(
      `/WorkspacePID/Upload/${this.state.selectedRows[0].fileName}`
    );
  };

  render() {
    if (UserStore.PIDToDate) {
      this.state.endDate = Moment(UserStore.PIDToDate).format("DD/MMM/YYYY");
    }
    
    const readonly = localStorage.getItem("isreadOnlyPID")
      ? JSON.parse(localStorage.getItem("isreadOnlyPID"))
      : UserStore.readOnlyPID;

    const returnData =
      UserStore.PIDFilterRetain === true
        ? toJS(UserStore.processData)
        : this.state.rows1;
    if (
      localStorage.getItem("getprocessGridPID") &&
      document.getElementsByClassName("breadcrumbBR")[0]
    ) {
      if (localStorage.getItem("getprocessGridPID") == 0) {
        document.getElementsByClassName("breadcrumbBR")[0].innerHTML =
          "Upload Files";
      } else if (localStorage.getItem("getprocessGridPID") == 1) {
        document.getElementsByClassName("breadcrumbBR")[0].innerHTML =
          "Process Files";
      } else if (localStorage.getItem("getprocessGridPID") == 2) {
        document.getElementsByClassName("breadcrumbBR")[0].innerHTML =
          "File Logs";
      }
    }

    UserStore.setProcessData(returnData);
    return (
      <LoadingOverlay
        className="process-page-loader"
        active={this.state.loading}
        spinner
        text="Loading your content..."
      >
        <div style={{ width: "100%" }} className="StampGrid">
          <div className="searchbar-modal">
            <input
              autoComplete="off"
              type="text"
              className="search-text-bar"
              id="searchbox"
              placeholder="Search by Document Name"
              style={{ fontSize: "1vw", marginLeft: "1vw" }}
              onChange={this.OnChangeHandler}
              onKeyPress={this.keyDownSearch}
            ></input>
            <div className="">
              <button
                className="search-button"
                type="submit"
                onClick={this.doSomethingWith}
              >
                <Tooltip title="Search" arrow>
                  <img src="/images/Search-Icon.svg" className="search-icon" />
                </Tooltip>
              </button>
            </div>

            <span class="label-text">Upload Date:</span>
            {this.state.startDate ? (
              <DatePicker
                defaultValue={Moment(this.state.startDate)}
                allowClear={false}
                id="from"
                format={dateFormat}
                onChange={this.handleUploadDateChange}
              />
            ) : (
              ""
            )}
            <span class="label-text">To:</span>
            {this.state.endDate ? (
              <DatePicker
                allowClear={false}
                defaultValue={Moment(this.state.endDate)}
                id="to"
                format={dateFormat}
                onChange={this.handleToDateChange}
              />
            ) : (
              ""
            )}
            <span class="label-text" style={{ marginLeft: "8vw" }}>
              File Status:
            </span>
            <FormControl style={{ marginRight: "1vw" }}>
              <InputLabel id="demo-simple-select-filled-label"></InputLabel>
              <Select
                value={this.state.initvalue}
                labelId="demo-simple-select-filled-label"
                defaultValue={"All"}
                id="dropdown"
                onChange={this.handleDropdownChange}
              >
                <MenuItem value={"All"}>
                  All({this.state.fillarr.length})
                </MenuItem>
                <MenuItem value={"Processed"}>
                  Processed(
                  {
                    this.state.fillarr.filter(
                      (item) => item.isProcessed === "Yes"
                    ).length
                  }
                  ) &nbsp;<span class="dot"></span>
                </MenuItem>
                <MenuItem value={"Processing Failed"}>
                  Processing Failed(
                  {
                    this.state.fillarr.filter(
                      (item) => item.isProcessed === "No"
                    ).length
                  }
                  ) &nbsp;<span class="dot1"></span>
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          <div class="grid-toolbar-wrapper">
            {toJS(UserStore.selectedRowID).length === 1 ? (
              <Tooltip title="Click to Preview Files" arrow>
                <span
                  onClick={this.handleShow}
                  style={
                    this.state.disablePreview
                      ? { opacity: "1" }
                      : {
                          opacity: "0.5",
                          cursor: "not-allowed",
                          pointerEvents: "none",
                        }
                  }
                >
                  <img src="/images/icon_preview.svg" class="toolbar-icon" />
                  <span class="preview-text">Preview</span>
                </span>
              </Tooltip>
            ) : (
              <Tooltip title="Click to Preview Files" arrow>
                <span onClick={this.handleShow}>
                  <img src="/images/icon_preview.svg" class="toolbar-icon" />
                  <span class="preview-text">Preview</span>
                </span>
              </Tooltip>
            )}
            <Tooltip title="Click to Delete Files" arrow>
              <span
                style={
                  readonly
                    ? { opacity: "0.3", pointerEvents: "none" }
                    : { opacity: "1" }
                }
                onClick={this.handleDeleteFiles}
              >
                <img
                  src="/images/icon_delete_black.svg"
                  class="toolbar-icon"
                  onClick={this.handleDeleteFiles}
                />
                <span class="preview-text" onClick={this.handleDeleteFiles}>
                  Delete
                </span>
              </span>
            </Tooltip>

            <Tooltip title="Click to Run Files" arrow>
              <span
                style={
                  readonly
                    ? { opacity: "0.3", pointerEvents: "none" }
                    : { opacity: "1" }
                }
                onClick={this.handleRun}
              >
                <img src="/images/run.svg" class="toolbar-icon" />
                <span className="preview-text">Run</span>
              </span>
            </Tooltip>

            <Tooltip title="Click to view the Batch Status" arrow>
              <span
                className="preview-text"
                onClick={this.showBatchStatus}
                //onClick={this.ShowbatchTable}
              >
                <img
                  class="toolbar-icon"
                  style={{ paddingRight: "10px", marginLeft: "-3vw" }}
                />
                <span
                  className="preview-text"
                  style={{
                    color: "#0C629B",
                    textDecoration: "none",
                    fontSize: "1vw",
                  }}
                >
                  <Assessment
                    style={{
                      color: "#454545",
                      marginRight: "0.5vw",
                      height: "1.2vw",
                    }}
                  />
                  Batch Status
                </span>
              </span>
            </Tooltip>

            <Tooltip title="Click to Export Report" arrow>
              <ExportExcel
                className="preview-text"
                id={UserStore.PIDworkspaceID}
                fileName={"PIDReport"}
                flag={1}
                frompid={"pid"}
                fromPreview={false}
              >
                <span
                  className="upload_text"
                  style={{
                    color: "#0C629B",
                    textDecoration: "none",
                    fontSize: "1vw",
                    marginRight: "2vw",
                  }}
                >
                  Export Report
                </span>
              </ExportExcel>
            </Tooltip>

            <Tooltip title="Click to Export MTO" arrow>
              <span
                style={
                  this.state.disableExportMTO
                    ? { opacity: "1" }
                    : {
                        opacity: "0.5",
                        cursor: "not-allowed",
                        pointerEvents: "none",
                      }
                }
              >
                <ExportToExcel
                  style={{ marginLeft: "10vw" }}
                  fileName={fileName}
                />
              </span>
            </Tooltip>

            <Tooltip title="Click to Export PID" arrow>
              <span
                style={
                  this.state.disableExportPID
                    ? { opacity: "1" }
                    : {
                        opacity: "0.5",
                        cursor: "not-allowed",
                        pointerEvents: "none",
                      }
                }
                onClick={this.handleExportPID}
              >
                <img src="/images/export.svg" class="toolbar-icon" />
                <span className="preview-text">Export PID</span>
              </span>
            </Tooltip>

            <Tooltip title="Click to Save" arrow>
              <span onClick={this.handleSave}>
                <img src="/images/icon_save.svg" class="toolbar-icon" />
                <span className="preview-text">Save</span>
              </span>
            </Tooltip>

            <Tooltip title="Click to refresh the Processed Status" arrow>
              <span
                className="preview-text"
                onClick={this.refreshProcessedStatus.bind(this)}
                style={{ marginLeft: "-2.5vw" }}
              >
                <svg
                  style={{
                    height: "1vw",
                    marginLeft: "1.5vw",
                    marginRight: "0.5vw",
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="24.515"
                  height="23.902"
                  viewBox="0 0 24.515 23.902"
                >
                  <g
                    id="noun_reset_3324862_1_"
                    data-name="noun_reset_3324862 (1)"
                    transform="translate(-140.708 -276.75)"
                  >
                    <g
                      id="Group_5865"
                      data-name="Group 5865"
                      transform="translate(140.973 277)"
                    >
                      <path
                        id="Path_10989"
                        data-name="Path 10989"
                        d="M31.972,12.166a.726.726,0,0,1-.721.633h-.1l-2.266-.3A11.693,11.693,0,1,1,19.68,8a.731.731,0,1,1,0,1.462,10.232,10.232,0,1,0,7.942,3.786L27.5,16.073a.731.731,0,0,1-.726.7h-.034a.721.721,0,0,1-.7-.76l.195-4.536a.073.073,0,0,1,0-.034.375.375,0,0,1,0-.073v-.1a.068.068,0,0,1,0-.029.736.736,0,0,1,.044-.088h0a.648.648,0,0,1,.068-.093h0a.706.706,0,0,1,.151-.132h0a.7.7,0,0,1,.1-.058h0l.1-.034h.287l4.283.57a.731.731,0,0,1,.7.765Z"
                        transform="translate(-7.973 -8)"
                        fill="#000"
                        stroke="#000"
                        stroke-width="0.5"
                      />
                    </g>
                  </g>
                </svg>
                Refresh Grid
              </span>
            </Tooltip>
          </div>
          <Dialog
            className="batchStatusList"
            fullWidth={true}
            maxWidth={"lg"}
            open={this.state.showBatchStatusDialog}
            onClose={this.handleCloseBatchStatus}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              Batch Status
              <IconButton
                onClick={this.handleCloseBatchStatus}
                style={{
                  padding: "0",
                  float: "right",
                  background: "#0C629B",
                  borderRadius: "0",
                  color: "#fff",
                }}
              >
                {" "}
                <CloseIcon />{" "}
              </IconButton>
            </DialogTitle>
            <DialogContent style={{ height: "25vw", width: "100%" }}>
              <div>
                <BatchTable wsId={this.state.wsId} frompid={"pid"} />
              </div>
            </DialogContent>
          </Dialog>

          <Modal
            className="user-select-popup"
            show={this.state.show}
            onHide={this.handleClose}
            backdrop="static"
            keyboard={false}
            dialogClassName="fullmodal"
            size="l"
            centered
          >
            <Modal.Body>
              <label
                style={{
                  font: "normal normal bold 1vw Helvetica",
                  textAlign: "left",
                }}
              >
                {this.state.previewValue
                  ? this.state.errorMEssage
                  : "Please select only one file to preview."}
                <IconButton
                  onClick={this.handleClose}
                  style={{
                    padding: "3px",
                    position: "absolute",
                    top: "0",
                    right: "0",
                    background: "",
                    borderRadius: "0",
                    color: "#0C629B",
                  }}
                >
                  {" "}
                  <CloseIcon style={{ width: "1.5vw", height: "1.5vw" }} />{" "}
                </IconButton>
              </label>
            </Modal.Body>
          </Modal>

          <Modal
            className="user-select-popup"
            show={this.state.show}
            onHide={this.handleClose}
            backdrop="static"
            keyboard={false}
            dialogClassName="fullmodal"
            size="l"
            centered
          >
            <Modal.Body>
              <label
                style={{
                  font: "normal normal bold 1vw Helvetica",
                  textAlign: "left",
                }}
              >
                {this.state.errorMEssage}
                <IconButton
                  onClick={this.handleClose}
                  style={{
                    padding: "3px",
                    position: "absolute",
                    top: "0",
                    right: "0",
                    background: "",
                    borderRadius: "0",
                    color: "#0C629B",
                  }}
                >
                  {" "}
                  <CloseIcon style={{ width: "1.5vw", height: "1.5vw" }} />{" "}
                </IconButton>
              </label>
            </Modal.Body>
          </Modal>

          <Modal
            className="user-select-popup"
            show={this.state.runShow}
            onHide={this.handleClose}
            backdrop="static"
            keyboard={false}
            dialogClassName="fullmodal"
            size="l"
            centered
          >
            <Modal.Body>
              <label
                style={{
                  font: "normal normal bold 1vw Helvetica",
                  textAlign: "left",
                }}
              >
                {this.state.runValue
                  ? "File processing has started."
                  : "Please select atleast one file to start processing"}
                <IconButton
                  onClick={this.handleClose}
                  style={{
                    padding: "3px",
                    position: "absolute",
                    top: "0",
                    right: "0",
                    background: "",
                    borderRadius: "0",
                    color: "#0C629B",
                  }}
                >
                  {" "}
                  <CloseIcon style={{ width: "1.5vw", height: "1.5vw" }} />{" "}
                </IconButton>
              </label>
            </Modal.Body>
          </Modal>

          <Modal
            className="user-select-popup"
            show={this.state.PIDShow}
            onHide={this.handleClose}
            backdrop="static"
            keyboard={false}
            dialogClassName="fullmodal"
            size="l"
            centered
          >
            <Modal.Body>
              <label
                style={{
                  font: "normal normal bold 1vw Helvetica",
                  textAlign: "left",
                }}
              >
                {this.state.exportPIDValue &&
                  "Please select atleast one processed row to export the PID"}
                <IconButton
                  onClick={this.handleClose}
                  style={{
                    padding: "3px",
                    position: "absolute",
                    top: "0",
                    right: "0",
                    background: "",
                    borderRadius: "0",
                    color: "#0C629B",
                  }}
                >
                  {" "}
                  <CloseIcon style={{ width: "1.5vw", height: "1.5vw" }} />{" "}
                </IconButton>
              </label>
            </Modal.Body>
          </Modal>

          <Dialog
            className="delete-confirm"
            open={this.state.deletePopup}
            onClose={this.handleDeleteFilesClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle
              id="form-dialog-title"
              style={{ height: "0.625em" }}
            ></DialogTitle>
            <DialogContent>
              <div style={{ fontSize: "1vw" }}>Are you sure to delete?</div>
              <IconButton
                onClick={this.handleDeleteFilesClose}
                style={{
                  padding: "0",
                  float: "right",
                  background: "#0C629B",
                  color: "#fff",
                  position: "absolute",
                  right: "15%",
                }}
              >
                {" "}
                <Cancel />{" "}
              </IconButton>
              <IconButton
                onClick={(e) => this.handlePurge(e)}
                style={{
                  padding: "0",
                  background: "#0C629B",
                  color: "white",
                  position: "absolute",
                  right: "2%",
                }}
              >
                {" "}
                <CircleChecked />{" "}
              </IconButton>
            </DialogContent>
          </Dialog>

          <Modal
            className="user-select-popup"
            show={this.state.showBatchStatus}
            onHide={this.handleCloseBatch}
            backdrop="static"
            keyboard={false}
            dialogClassName="fullmodal"
            size="l"
            centered
          >
            <Modal.Body>
              <label
                style={{
                  font: "normal normal bold 1vw Helvetica",
                  textAlign: "left",
                }}
              >
                No batches are created for this workspace.
                <IconButton
                  onClick={this.handleCloseBatch}
                  style={{
                    padding: "3px",
                    position: "absolute",
                    top: "0",
                    right: "0",
                    background: "",
                    borderRadius: "0",
                    color: "#0C629B",
                  }}
                >
                  {" "}
                  <CloseIcon style={{ width: "1.5vw", height: "1.5vw" }} />{" "}
                </IconButton>
              </label>
            </Modal.Body>
          </Modal>

          <Modal
            className="user-select-popup"
            show={this.state.deleteshow}
            onHide={this.handledeleteClose}
            backdrop="static"
            keyboard={false}
            dialogClassName="fullmodal"
            size="l"
            centered
          >
            <Modal.Body>
              <label
                style={{
                  font: "normal normal bold 1vw Helvetica",
                  textAlign: "left",
                }}
              >
                Please select atleast one file to delete.
                <IconButton
                  onClick={this.handledeleteClose}
                  style={{
                    padding: "3px",
                    position: "absolute",
                    top: "0",
                    right: "0",
                    background: "",
                    borderRadius: "0",
                    color: "#0C629B",
                  }}
                >
                  {" "}
                  <CloseIcon style={{ width: "1.5vw", height: "1.5vw" }} />{" "}
                </IconButton>
              </label>
            </Modal.Body>
          </Modal>

          <Modal
            className="user-select-popup"
            show={this.state.showDownload}
            onHide={this.handleDownloadClose}
            backdrop="static"
            keyboard={false}
            dialogClassName="fullmodal"
            size="l"
            centered
          >
            <Modal.Body>
              <label
                style={{
                  font: "normal normal bold 1vw Helvetica",
                  textAlign: "left",
                }}
              >
                Please select atleast one file to download PID
                <IconButton
                  onClick={this.handleDownloadClose}
                  style={{
                    padding: "3px",
                    position: "absolute",
                    top: "0",
                    right: "0",
                    background: "",
                    borderRadius: "0",
                    color: "#0C629B",
                  }}
                >
                  {" "}
                  <CloseIcon style={{ width: "1.5vw", height: "1.5vw" }} />{" "}
                </IconButton>
              </label>
            </Modal.Body>
          </Modal>

          <Modal
            className="user-select-popup"
            show={this.state.dateValidation}
            onHide={this.handleDateClose}
            backdrop="static"
            keyboard={false}
            dialogClassName="fullmodal"
            size="l"
            centered
          >
            <Modal.Body>
              <label
                style={{
                  font: "normal normal bold 1vw Helvetica",
                  textAlign: "left",
                }}
              >
                {" "}
                {this.state.dateValidationValue}
                <IconButton
                  onClick={this.handleDateClose}
                  style={{
                    padding: "3px",
                    position: "absolute",
                    top: "0",
                    right: "0",
                    background: "",
                    borderRadius: "0",
                    color: "#0C629B",
                  }}
                >
                  {" "}
                  <CloseIcon style={{ width: "1.5vw", height: "1.5vw" }} />{" "}
                </IconButton>
              </label>
            </Modal.Body>
          </Modal>

          <div style={{ width: "100%", height: "54.8vh" }}>
            {this.state.rows1.length > 0 && (
              <ProcessGridTable
                loading={this.state.rows1 === 0}
                editConfigData={this.state.rows1}
                onRowSelected={this.handleRowSelection}
                columns={this.state.columns}
                searchrows={searchrows}
                dropdownChange={this.state.initvalue}
                onEditBlur={this.handleBlurEdit}
                fromeditconfig={"No"}
              >
                {" "}
              </ProcessGridTable>
            )}
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}

export default withRouter(Process);
