import React, { useState,useRef,useEffect } from 'react';
import TableFilter from "react-table-filter";
import {
  EditAsset,
  EditAssetLineGroup,
} from "../../redux/actions/documentActions";
import { useDispatch, useSelector } from "react-redux";
import "react-table-filter/lib/styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {} from "./lib/styles.css";
import "./FilterMTO.scss";
export default function FilterRenderer(props) {
  const rows = props.rowsData;
  const changeDataFormat = (mtoResult) => {
    let k = [];
    if(mtoResult !== undefined) {
    mtoResult.map((data) => {
      let mtoData = {
        LineNumber: data.LineNumber === "UNKNOWN" ? "Not Detected" :data.LineNumber ,
        Seq: data.Seq.length === 0 || data.Seq === "NaN" ? "Not Detected" : data.Seq,
        pid: data.pid.length === 0 ? "Not Detected" : data.pid,
        Spec: data.Spec.length === 0 ? "Not Detected" : data.Spec,
        shortCode: data.shortCode.length === 0 ? "Not Detected" : data.shortCode,
        isJacketed: data.isJacketed.length === 0 ? "Not Detected" : data.isJacketed,
        hdr:
          data.hdr === null ||
          data.hdr === undefined ||
          data.hdr === ""
            ? "Not Detected"
            : 
            data.hdr.split(/[" ']+/)[0],
            size1:
          data.size1 === null || data.size1 === undefined || data.size1 === ""
            ? "Not Detected"
            : 
            (data.size1),
            size2:
          (data.shortCode === "T" || data.shortCode === "RX") && (data.size2 === null || data.size2 === undefined || data.size2 === "")
            ? "Not Detected"
            : (data.size2 === null || data.size2 === undefined || data.size2 === "") ? "" : (data.size2) ,
            quantity: data.quantity,
            insulationType: data.insulationType.length === 0 ? "Not Detected" : data.insulationType,
            ugag: data.ugag.length === 0 ? "Not Detected" : data.ugag,
            fluid: data.fluid.length === 0 ? "Not Detected" : data.fluid,
            assetCompType:
          data.assetCompType === null ||
          data.assetCompType === undefined ||
          data.assetCompType === ""
            ? "Not Detected"
            : data.assetCompType.toUpperCase(),
            seat: data.seat.length === 0 ? "Not Detected" : data.seat,
            valveBody: data.valveBody.length === 0 ? "Not Detected" : data.valveBody,
            extendedBonet: data.extendedBonet.length === 0 ? "Not Detected" : data.extendedBonet,
            limitSwich: data.limitSwich.length === 0 ? "Not Detected" : data.limitSwich,
        hold: data.hold.length === 0 ? "Not Detected" : data.hold,
        supply: data.supply,
        fromLine: data.fromLine.length === 0 ? "Not Detected" : data.fromLine,
        toLine: data.toLine.length === 0 ? "Not Detected" : data.toLine,
        itemOrder: data.itemOrder,
        remarks: (data.shortCode === "I" || data.shortCode === "SC" || data.shortCode === "SI" || data.assetCompType === "Control Valve") && data.remarks.length === 0 ? "Not Detected" : data.remarks,
        rating: data.rating.length === 0 ? "Not Detected" : data.rating,
      };
      k.push(mtoData);
    });
  }
    return k;
  };
  const [newRows,setNewRows] = useState(changeDataFormat(rows));
  useEffect(() => {
    if(rows !== undefined) {
      let mtoResult = changeDataFormat(rows);
      setNewRows(mtoResult);
    }
  },[rows])
  const dispatch = useDispatch();
  const selectedAsset = useSelector(
    (state) => state.documentData.selectedAsset
  );
  let tableRef = useRef();
  const handleMouseLeave =(e,oldValue,index) => {
    const header = e.target.id.split("_")[0];
    const value = e.target.innerHTML;
    var newValue = JSON.parse(JSON.stringify(oldValue));
    newValue[header] = value;
    if(header === "LineNumber") {
      dispatch(EditAssetLineGroup(oldValue.LineNumber, newValue));
    }
    else {
      dispatch(EditAsset(newValue,oldValue));
    }
    if(value !== oldValue[header])
      document.getElementById(e.target.id).style.backgroundColor = "yellow";
  }

  const filterUpdated = (newData, filterConfiguration) => {
    setNewRows(newData);
  }

  const filterReset = () => {
    tableRef.reset(rows, true);
    setNewRows(rows)
  }

    return (
      <div>

<div className="clear-filter">
            <img src="/images/Reset.svg" className="clear-filter-icon" />
            <span className="toolbar-text"  onClick={filterReset}>Clear Filter</span>
          </div>

          <table className="table-pid-mto" id="resize-pid" ref={tableRef}>
        <thead className="table-head-pid" style={{ height: "3vw" }}>
          <TableFilter
            rows={newRows}
            onFilterUpdate={filterUpdated}
            rowClass={"table-filter"}
            ref={ (node) => tableRef = node}
          >
            
            <th
              key="LineNumber"
              filterkey="LineNumber"
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div  className="resize-div">LINE NUMBER</div>
            </th>
            <th
              key="Seq"
              filterkey="Seq"
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">SEQ</div>
            </th>
            <th
              key="pid"
              filterkey="pid"
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">PID</div>
            </th>
            <th
              key="Spec"
              filterkey="Spec"
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">SPEC</div>
            </th>
            <th
              key="shortCode"
              filterkey="shortCode"
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">SHORT CODE</div>
            </th>
            <th
              key="isJacketed"
              filterkey="isJacketed"
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">IS JACKETED LINE</div>
            </th>
            <th
              key="hdr"
              filterkey="hdr"
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">HDR</div>
            </th>
            <th
              key="size1"
              filterkey="size1"
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">SIZE - 1</div>
            </th>
            <th
              key="size2"
              filterkey="size2"
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">SIZE - 2</div>
            </th>
            <th
              key="quantity"
              filterkey="quantity"
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">QTY</div>
            </th>
            <th
              key="insulationType"
              filterkey="insulationType"
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">INSUL TYPE</div>
            </th>
            <th
              key="ugag"
              filterkey="ugag"
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">AG/UG</div>
            </th>
            <th
              key="fluid"
              filterkey="fluid"
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">FLUID</div>
            </th>
            <th
              key="assetCompType"
              filterkey="assetCompType"
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">PIPINGCOMPTYPE</div>
            </th>
            <th
              key="seat"
              filterkey="seat"
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">SEAT</div>
            </th>
            <th
              key="valveBody"
              filterkey="valveBody"
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">VALVE BODY</div>
            </th>
            <th
              key="extendedBonet"
              filterkey="extendedBonet"
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">EXTENDED BONNET</div>
            </th>
            <th
              key="limitSwich"
              filterkey="limitSwich"
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">LIMIT SWITCH</div>
            </th>
            <th
              key="hold"
              filterkey="hold"
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">HOLD</div>
            </th>
            <th
              key="supply"
              filterkey="supply"
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">SUPPLY</div>
            </th>
            <th
              key="fromLine"
              filterkey="fromLine"
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">FROM</div>
            </th>
            <th
              key="toLine"
              filterkey="toLine"
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">TO</div>
            </th>
            <th
              key="itemOrder"
              filterkey="itemOrder"
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">ITEM ORDER</div>
            </th>
            <th
              key="remarks"
              filterkey="remarks"
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">REMARKS</div>
            </th>
            <th
              key="rating"
              filterkey="rating"
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">RATING</div>
            </th>
          </TableFilter>
        </thead>
        <tbody > {
              newRows.map((item,index) => (
                   <tr key={item.key} >
                      <td   style={item.LineNumber === "Not Detected" ? {color : "deeppink",textAlign: "center"} : {textAlign: "center"}}  id={"LineNumber_"+item.Key}  onBlur={(e) => handleMouseLeave(e,item)}>{item.LineNumber === "UNKNOWN" ? "Not Detected" : item.LineNumber}</td>
                  <td   style={item.Seq === "Not Detected"  ? {color : "deeppink",textAlign: "center"} : {textAlign: "center"}} id={"Seq_"+item.Key} className={"Seq_"+item.Key}  onBlur={(e) => handleMouseLeave(e,item)}>{item.Seq === "NaN" || item.Seq.length === 0 ? "Not Detected" : item.Seq}</td>
                  <td   style={item.pid === "Not Detected" ? {color : "deeppink",textAlign: "center"} : {textAlign: "center"}}  id={"pid_"+item.Key} onBlur={(e) => handleMouseLeave(e,item)}>{item.pid.length === 0 ? "Not Detected" : item.pid}</td>
                  <td   style={item.Spec === "Not Detected" ? {color : "deeppink",textAlign: "center"} : {textAlign: "center"}}  id={"Spec_"+item.Key} onBlur={(e) => handleMouseLeave(e,item)}>{item.Spec.length === 0 ? "Not Detected" : item.Spec}</td>
                  <td style={item.shortCode === "Not Detected" ? {color : "deeppink",textAlign: "center"} : {textAlign: "center"}}  id={"shortCode_"+item.Key} onBlur={(e) => handleMouseLeave(e,item)}>{item.shortCode.length === 0 ? "Not Detected" : item.shortCode}</td>
                  <td   style={item.isJacketed === "Not Detected" ? {color : "deeppink",textAlign: "center"} : {textAlign: "center"}}  id={"isJacketed_"+item.Key} onBlur={(e) => handleMouseLeave(e,item)}>{item.isJacketed.length === 0 ? "Not Detected" : item.isJacketed}</td>
                  <td  style={item.hdr === "Not Detected"  ? {color : "deeppink",textAlign: "center"} : {textAlign: "center"}}  id={"hdr_"+item.Key} onBlur={(e) => handleMouseLeave(e,item)}>{item.hdr === "NaN"|| item.hdr.length === 0 ? "Not Detected" : item.hdr}</td>
                  <td   style={item.size1 === "Not Detected"  ? {color : "deeppink",textAlign: "center"} : {textAlign: "center"}}  id={"size1_"+item.Key} onBlur={(e) => handleMouseLeave(e,item)}>{item.size1 === "NaN" || item.size1.length === 0 ? "Not Detected" : item.size1}</td>
                  <td  style={item.size2 === "Not Detected" ? {color : "deeppink",textAlign: "center"} : {textAlign: "center"}} id={"size2_"+item.Key} onBlur={(e) => handleMouseLeave(e,item)}>{item.size2 === "NaN" ? "Not Detected" : (item.shortCode === "T" || item.shortCode === "RX") && item.size2.length === 0 ? "Not Detected" : item.size2 }</td>
                  <td   style={{textAlign:"center"}} id={"quantity_"+item.Key} onBlur={(e) => handleMouseLeave(e,item)}>{item.quantity}</td>
                  <td  style={item.insulationType === "Not Detected" ? {color : "deeppink",textAlign: "center"} : {textAlign: "center"}}  id={"insulationType_"+item.Key} onBlur={(e) => handleMouseLeave(e,item)}>{item.insulationType.length === 0 ? "Not Detected" : item.insulationType}</td>
                  <td   style={item.ugag === "Not Detected" ? {color : "deeppink",textAlign: "center"} : {textAlign: "center"}}  id={"ugag_"+item.Key} onBlur={(e) => handleMouseLeave(e,item)}>{item.ugag.length === 0 ? "Not Detected" : item.ugag}</td>
                  <td  style={item.fluid === "Not Detected" ? {color : "deeppink",textAlign: "center"} : {textAlign: "center"}} id={"fluid_"+item.Key} onBlur={(e) => handleMouseLeave(e,item)}>{item.fluid.length === 0 ? "Not Detected" : item.fluid}</td>
                  <td   style={item.assetCompType === "Not Detected" ? {color : "deeppink",textAlign: "center"} : {textAlign: "center"}}  id={"assetCompType_"+item.Key} onBlur={(e) => handleMouseLeave(e,item)}>{item.assetCompType.length === 0 ? "Not Detected" : item.assetCompType}</td>
                  <td  style={item.seat === "Not Detected" ? {color : "deeppink",textAlign: "center"} : {textAlign: "center"}}  id={"seat_"+item.Key} onBlur={(e) => handleMouseLeave(e,item)}>{item.seat.length === 0 ? "Not Detected" : item.seat}</td>
                  <td   style={item.valveBody === "Not Detected" ? {color : "deeppink",textAlign: "center"} : {textAlign: "center"}}  id={"valveBody_"+item.Key} onBlur={(e) => handleMouseLeave(e,item)}>{item.valveBody.length === 0 ? "Not Detected" : item.valveBody}</td>
                  <td   style={item.limitSwich === "Not Detected" ? {color : "deeppink",textAlign: "center"} : {textAlign: "center"}}  id={"limitSwich_"+item.Key} onBlur={(e) => handleMouseLeave(e,item)}>{item.limitSwich.length === 0 ? "Not Detected" : item.limitSwich}</td>
                  <td    style={item.extendedBonet === "Not Detected" ? {color : "deeppink",textAlign: "center"} : {textAlign: "center"}}  id={"extendedBonet_"+item.Key} onBlur={(e) => handleMouseLeave(e,item)}>{item.extendedBonet.length === 0 ? "Not Detected" : item.extendedBonet}</td>
                  <td  style={item.hold === "Not Detected" ? {color : "deeppink",textAlign: "center"} : {textAlign: "center"}}  id={"hold_"+item.Key} onBlur={(e) => handleMouseLeave(e,item)}>{item.hold.length === 0 ? "Not Detected" : item.hold}</td>
                  <td  style={{textAlign:"center"}} id={"supply_"+item.Key} onBlur={(e) => handleMouseLeave(e,item)}>{item.supply}</td>
                  <td  style={item.fromLine === "Not Detected" ? {color : "deeppink",textAlign: "center"} : {textAlign: "center"}}  id={"fromLine_"+item.Key} onBlur={(e) => handleMouseLeave(e,item)}>{item.fromLine.length === 0 ? "Not Detected" : item.fromLine}</td>
                  <td   style={item.toLine === "Not Detected" ? {color : "deeppink",textAlign: "center"} : {textAlign: "center"}}  id={"toLine_"+item.Key} onBlur={(e) => handleMouseLeave(e,item)}>{item.toLine.length === 0 ? "Not Detected" : item.toLine}</td>
                  <td   style={{textAlign:"center"}} id={"itemOrder_"+item.Key} onBlur={(e) => handleMouseLeave(e,item)}>{item.itemOrder}</td>
                  <td  style={item.shortCode === "Not Detected"? {color : "deeppink",textAlign: "center"} : {textAlign: "center"}}  id={"remarks_"+item.Key} onBlur={(e) => handleMouseLeave(e,item)}>{(item.shortCode === "I" || item.shortCode === "SC" || item.shortCode === "SI" || item.assetCompType === "Control Valve") && item.remarks.length === 0 ? "Not Detected" : item.remarks}</td>
                  <td  style={item.rating === "Not Detected" ? {color : "deeppink",textAlign: "center"} : {textAlign: "center"}}  id={"rating_"+item.Key} onBlur={(e) => handleMouseLeave(e,item)}>{item.rating === "NaN" || item.rating.length === 0  ? "Not Detected" : item.rating}</td>
                </tr>
               
               
              ))
              }
            </tbody>
          </table>
      </div>
    );
}