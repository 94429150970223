import React from 'react';
import  { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import UserStore from '../../UserStore';
import './BreadCrumbs.scss';

const useStyles = makeStyles((theme) => ({
  root: {
  '& > * + *': {
  marginTop:theme.spacing(2),
      },
    },
  }));

 function BreadCrumbs(props) {  
  const classes = useStyles();
  const { history, location: { pathname }} = props;
  const pathnames = pathname.split("/").filter(x => x);

  var wname = "";

  // condition for SR

  if(pathnames.includes('Workspace')) {
    if(UserStore.workspaceName == '' && UserStore.workspaceId == 0) {
      if(localStorage.getItem('WorkspaceId')  && localStorage.getItem('workspaceName')) {
          UserStore.getWorkspaceId(localStorage.getItem('WorkspaceId') ? localStorage.getItem('WorkspaceId'): 0);
          UserStore.setworkspaceuploadID(localStorage.getItem('WorkspaceId') ? localStorage.getItem('WorkspaceId'): 0);
          UserStore.getworkspaceName(localStorage.getItem('workspaceName') ? localStorage.getItem('workspaceName'): '');
      }
    }
    wname = !pathnames.includes('CreateNewWorkspace') ? `${UserStore.workspaceName}` : ``;
  }
  // condition for pid
  if(pathnames.includes('WorkspacePID')) {
    if(UserStore.workspaceNamePID == '' && UserStore.PIDworkspaceID == 0) {
      if(localStorage.getItem('WorkspaceIdPID')  && localStorage.getItem('workspaceNamePID')) {
          UserStore.setPIDWorkspaceId(localStorage.getItem('WorkspaceIdPID') ? localStorage.getItem('WorkspaceIdPID'): 0);
          UserStore.getworkspaceNamePID(localStorage.getItem('workspaceNamePID') ? localStorage.getItem('workspaceNamePID'): '');
      }
    }
    wname = !pathnames.includes('CreateNewWorkspace') ? `${UserStore.workspaceNamePID}` : ``;
  }
  //  Logic for editing the breadcrumbs name
  return (
    <div className={`breadCrumbs ${classes.root}`}>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" style={{marginLeft: "1%"}}>
        <Link className="breadcrumbs-link" onClick={()=> history.push("/")}>Home</Link>
        { pathnames.map((name,index) => {
          if(name === 'Upload') {
            name = name.replace(/Upload/, `Upload Files`);
          }
          if(name === 'CreateNewWorkspace') {
            name = name.replace(/CreateNewWorkspace/, 'Create New Workspace');
          }
          if(name === 'UploadFiles') {
            name = name.replace(/UploadFiles/, 'Upload Files');
          }
          if(name === 'Workspace' || name === 'workspace') {
            name = name.replace(/Workspace/, `Project Workspace`);
          }
          if(name === 'SetDetectionlogic') {
            name = name.replace(/SetDetectionlogic/, `Set Detection Logic`);
          }
          if(name === 'RegularExpression') {
            name = name.replace(/RegularExpression/, `Regular Expression`);
          }
          if(name === 'Businessrulessetup') {
            name = name.replace(/Businessrulessetup/, `Business Rules Setup`);
          }
          if(name === 'EditWorkSpaceStamp') {
            name = name.replace(/EditWorkSpaceStamp/, `Edit Workspace`);
          }
          if(name === 'EditWorkspace') {
            name = name.replace(/EditWorkspace/, `Edit Workspace`);
          }
          if(name === 'WorkspacePID') {
            name = name.replace(/WorkspacePID/, `Project Workspace PID`);
          }
          if(name === 'WorkspacePid') {
            name = name.replace(/WorkspacePid/, `Project Workspace PID`);
          }
          if(name === 'OverallMTO') {
            name = name.replace(/OverallMTO/, `Overall MTO`);
          }
          if(name === 'EditConfiguration') {
            name = name.replace(/EditConfiguration/, `Edit Configuration`);
          }
          
          let routeTo = pathnames.slice(0, index+1).join('/');
          routeTo = "/" + routeTo;
          const isLast = index === pathnames.length -1;
          return isLast ? (
            <Typography key={index} className={`breadcrumbBR`}>{name}</Typography>
          ) : 
          (
            <Link className="breadcrumbs-link" onClick={()=> history.push(routeTo)} key={index}>{name}</Link>
          )          
        })}      
      </Breadcrumbs>
      {pathnames.length > 1 && <span className="workspace-name">{wname.length === 0 ? "" : "Workspace Name:"} <b>{wname}</b></span>}
    </div>
  );
};
export default withRouter(BreadCrumbs);
