import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import "react-table-filter/lib/styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Cancel from "@material-ui/icons/Cancel";
import CircleChecked from "@material-ui/icons/CheckCircleRounded";
import { toJS } from "mobx";
import TableFilter from "react-table-filter";
import { toast } from "react-toastify";
import UserStore from "../../../UserStore";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Tooltip from "@material-ui/core/Tooltip";
import Pager from "./PagerPID/PagerPID";
import {} from "./processGrid.scss";
import {} from "./lib/styles.css";
import TextFieldMui from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import { useDispatch } from "react-redux";
import { selectAsset } from "../../../redux/actions/documentActions";

const styles = () => ({
  label: {
    "&$erroredLabel": {
      color: "black",
    },
  },
  erroredLabel: {},
});

const TextField = withStyles(styles)(function TextField({ classes, ...props }) {
  return (
    <TextFieldMui
      InputLabelProps={{
        classes: {
          root: classes.label,
          error: classes.erroredLabel,
        },
      }}
      {...props}
    />
  );
});

const ProcessGridTable = ({
  editConfigData,
  onRowSelected,
  searchrows,
  dropdownChange,
  fromeditconfig,
  onEditBlur,
}) => {
  const tableRef = useRef();
  const location = useLocation();
  let [processData, setProcessData] = useState(editConfigData);
  const [processDataforConfig, setProcessDataforConfig] =
    useState(editConfigData);
  const [tabledata, setTabledata] = useState(editConfigData);
  let [isSelectAll, setIsSelectAll] = useState(false);
  const [fromeditconfigflag, setfromeditconfig] = useState(fromeditconfig);
  const [selectedRows, setSelectedRows] = useState([]);
  useEffect(() => {
    UserStore.SetPerPageData(localStorage.getItem("perPageData"));
  },[])
  const [currentPage, setCurrentPage] = useState(
    Number(toJS(UserStore.skipData)) / Number(toJS(UserStore.perPageData)) + 1
  );
  useEffect(() => {
    if(localStorage.getItem("filterChange") === "change") {
      setCurrentPage(1);
      setSkip((1 - 1) * top);
      UserStore.setSkipData((1 - 1) * top);
    }
  },[UserStore.filesFilterPID])
  let [excelreportrow, setexcelreportrow] = useState([]);
  let [configIdforedelete, setconfigIdforedelete] = useState(0);
  let [filterConfig, setFilterConfig] = useState({});
  let [rowneeded, setrowneeded] = useState(0);
  const [firstLoad, setfirstLoad] = useState(false);
  const [pageChange, setPageChange] = useState(false);
  const [deleteconfig, setDeleteconfig] = useState(false);
  const [editconfig, seteditconfig] = useState([]);
  const [editconfigdata, seteditconfigdata] = useState([]);
  const [show, setShow] = useState(false);
  const [modalId, setmodalId] = useState(false);
  const dataLength = editConfigData.length;

  const [skip, setSkip] = useState(
    new URLSearchParams(location.search).get("skip") ?? toJS(UserStore.skipData)
  );
  const [top, topValueUpdate] = useState(
    new URLSearchParams(location.search).get("top") ??
      toJS(UserStore.perPageData)
  );
  const selectedItem = {
    id : undefined
  }
  const dispatch = useDispatch();
  dispatch(selectAsset(selectedItem))
  let resultsPerPage = top;
  let storeData = toJS(UserStore.processData);
  const API_Endpoint = process.env.REACT_APP_API_ENDPOINT;

  const filterFn = () => {
    const countOfData = Number(skip) + Number(top);
    const slicedData = storeData.slice(skip, countOfData);

    setProcessData(slicedData);
    if (searchrows && !firstLoad) {
      setDataPerPage(searchrows);
      setfirstLoad(true);
    }

    if (pageChange) {
      const items = document.getElementsByClassName("file-checkbox");
      for (let i = 0; i < items.length; i++) {
        if (items[i].type == "checkbox" && items[i].checked === true)
          items[i].checked = false;
      }
      setSelectedRows([]);
    }
  };

  useEffect(() => {
    setTabledata(editConfigData);
    setProcessDataforConfig(editConfigData);
    const skipValue =
      searchrows 
      // ||
      // dropdownChange === "Processing Failed" ||
      // dropdownChange === "Processed"
        ? 0
        : skip;
    const countOfData = Number(skipValue) + Number(top);
    const slicedData = storeData.slice(skipValue, countOfData);
    setProcessData(slicedData);
    if (
      dropdownChange === "Processing Failed" ||
      dropdownChange === "Processed"
    ) {
      UserStore.selectedRowID = [];
    }
    if (pageChange) {
      const items = document.getElementsByClassName("file-checkbox");
      for (let i = 0; i < items.length; i++) {
        if (items[i].type == "checkbox" && items[i].checked === true)
          items[i].checked = false;
      }

      setSelectedRows([]);
    }
  }, [skip, currentPage, filterConfig, pageChange, searchrows, dropdownChange]);

  if (searchrows && !firstLoad) {
    setCurrentPage(1);
    setSkip((1 - 1) * top);
    UserStore.setSkipData((1 - 1) * top);
    setfirstLoad(true);
    excelreportrow = [];
    excelreportrow.push(storeData);
    UserStore.addReportdata(storeData);
    UserStore.selectedRowID = [];
  }

//Used to arrange the data based on filter functionality
  const filterUpdated = (newData, filterConfiguration) => {
    setCurrentPage(1);
    setSkip((1 - 1) * top);
    UserStore.setSkipData((1 - 1) * top);
    setFilterConfig(filterConfiguration);
    UserStore.setPIDFilterRetain(true);
    setDataPerPage(newData);
    UserStore.setPIDFilterProcessConfiguration(filterConfiguration);
    UserStore.setProcessData(newData);
    storeData = toJS(UserStore.processData);
    UserStore.setPIDFilterData(newData);
    filterFn();
    UserStore.selectedRowID = [];
  };

  const handleCloseDialog = () => {
    setDeleteconfig(false);
  };

  const handleClose = () => {
    seteditconfigdata(toJS(UserStore.editConfigData));
    setShow(false);
  };

//Used to slice the data per page based on dropdown adjustment
  const setDataPerPage = () => {
    const countOfData = Number(skip) + Number(top);
    const slicedData = storeData.slice(skip, countOfData);
    setProcessData(slicedData);
    UserStore.setreportStamp(true);
    localStorage.setItem("setreportStamp", true);
  };

//Used to update the page number after changing the pagination
  const updatePagination = (newPageNumber) => {
    if (newPageNumber > 1) setPageChange(true);
    setSkip((newPageNumber - 1) * top);
    UserStore.setSkipData((newPageNumber - 1) * top);
    setCurrentPage(newPageNumber);
  };

//Used to save the modified configuration values
  const handleEditSave = async () => {
    const workspaceId1 = UserStore.PIDworkspaceID;
    UserStore.setEditConfigData(editconfigdata);
    let data = {
      projectWorkSpaceId: workspaceId1,
      configId: editconfigdata[rowneeded].configId,
      key1: editconfigdata[rowneeded].key1,
      key2: editconfigdata[rowneeded].key2,
      value: editconfigdata[rowneeded].value,
      remarks: editconfigdata[rowneeded].remarks,
    };
    const res = await axios.post(API_Endpoint + `/EditPidConfiguration`, data);
    setShow(false);
  };

//Used to change the configuration values
  const handleChange1 = (event) => {
    let id = event.target.id;
    const initArray = editConfigData;
    rowneeded = initArray.findIndex((obj) => obj.configId == id);
    if (event.target.name === "key1") {
      initArray[rowneeded].key1 = event.target.value;
    } else if (event.target.name === "key2") {
      initArray[rowneeded].key2 = event.target.value;
    } else if (event.target.name === "value") {
      initArray[rowneeded].value = event.target.value;
    } else if (event.target.name === "remarks") {
      initArray[rowneeded].remarks = event.target.value;
    }
    seteditconfig([initArray[rowneeded]]);
    setrowneeded(rowneeded);
    seteditconfigdata(initArray);
  };

//Used to save the deleted configuration id in a state value
  const handledelete = (e) => {
    setDeleteconfig(true);
    setconfigIdforedelete(e.target.id);
  };

//Used to save the edited data in a state value
  const handleEdit = (e) => {
    let id = e.target.id;

    setmodalId(id);
    const data1 = toJS(UserStore.editConfigData)
      .filter((item) => item.configId == id)
      .map(({ configId, key1, key2, value, remarks }) => ({
        configId,
        key1,
        key2,
        value,
        remarks,
      }));

    seteditconfig(data1);
    setShow(true);
  };

//Used to delete the configuration values after confirmation
  const handlePurge = (e) => {
    const rowneeded = processData.filter(
      (row) => row.configId != configIdforedelete
    );

    const data = {
      configId: configIdforedelete,
    };
    setProcessData(rowneeded);

    const postDataToDelete = axios
      .post(API_Endpoint + `/DeletePidConfiguration`, data)
      .then((res) => {
        if (res.status === 200) {
          onRowSelected();
        }
      });
    setDeleteconfig(false);
  };

//Used to display specific rows per page based on dropdown value change
  const customPagination = (e, dat) => {
    setCurrentPage(1);
    setSkip((1 - 1) * top);
    UserStore.setSkipData((1 - 1) * top);
    topValueUpdate(e.target.selectedOptions[0].value);

    if (e.target.selectedOptions[0].value === "30") {
      const slicedData2 = dat.slice(skip, 3 * 10);

      setProcessData(slicedData2);
    } else if (e.target.selectedOptions[0].value === "50") {
      const slicedData3 = dat.slice(skip, 5 * 10);

      setProcessData(slicedData3);
    } else if (e.target.selectedOptions[0].value === "100") {
      const slicedData4 = dat.slice(skip, 10 * 10);

      setProcessData(slicedData4);
    } else {
      const slicedData1 = dat.slice(skip, 1 * 10);
      setProcessData(slicedData1);
    }
    UserStore.SetPerPageData(e.target.selectedOptions[0].value);
    localStorage.setItem("perPageData",e.target.selectedOptions[0].value)
  };

//Used to select/deselect each checkbox
  const handleCheckbox = (e, item) => {
    if (e.target.checked === true) {
      let updatedRows = [];
      updatedRows.push(item);
      onRowSelected(item);

      UserStore.setSelectedRowID(item);
      excelreportrow.push(item);
      UserStore.addReportdata(excelreportrow);

      setSelectedRows(toJS(UserStore.selectedRowID));
      if (toJS(UserStore.selectedRowID).length === dataLength)
        setIsSelectAll(true);
    } else {
      let newRows = UserStore.reportData.filter(
        (sr) => sr.sheetId !== item.sheetId
      );
      const deselectedItem = toJS(UserStore.selectedRowID).filter(
        (s) => s.sheetId !== item.sheetId
      );

      onRowSelected(deselectedItem);

      deselectedItem.length > 0 && setIsSelectAll(false);
      storeData.length === 1 && setIsSelectAll(false);
      toJS(UserStore.PIDSearchData).length !== 0 && setIsSelectAll(false);

      if (newRows.length === 0) {
        UserStore.reportData = editConfigData;
      } else {
        UserStore.addReportdata(newRows);
      }
      setSelectedRows(newRows);
      excelreportrow = [];
      setexcelreportrow(newRows);
      UserStore.selectedRowID = [];
      UserStore.selectedRowID = newRows;
    }
  };

//Used to select/unselect all the checkboxes
  const selects = () => {
    let ele = document.getElementsByClassName("file-checkbox");
    let allRows = [];
    const sel = !isSelectAll;
    setIsSelectAll(sel);
    if (sel) {
      for (let i = 0; i < ele.length; i++) {
        if (ele[i].type === "checkbox") ele[i].checked = true;
        allRows.push(ele[i].name);
      }
      setSelectedRows(editConfigData);
      onRowSelected(editConfigData,"all");
      UserStore.addReportdata(editConfigData);
      if (!toJS(UserStore.processData.length)) {
        UserStore.selectedRowID = editConfigData;
      } else {
        UserStore.selectedRowID = UserStore.processData;
      }
    } else {
      for (let i = 0; i < ele.length; i++) {
        if (ele[i].type === "checkbox") ele[i].checked = false;
        setSelectedRows([]);
        onRowSelected([],"all");
      }

      UserStore.selectedRowID = [];
      excelreportrow = [];
      setexcelreportrow([]);
      UserStore.addReportdata(editConfigData);
    }
  };

//Used to supersede the duplicate rows
  const supersededCheck = async (e) => {
    const value = e.target.value;
    if (value === "Yes") {
      const res = await axios.post(API_Endpoint + `/PidMarkSheetSuperceded`, {
        sheetId: e.target.id,
      });
      res.status === 200 &&
        toast.success("Superseded successfully", { autoClose: 3000 });
    }
  };

  const elementsHtml = processData.map((item, index) => {
    const selectedRowID = toJS(UserStore.selectedRowID);
    const arr = selectedRowID.some((e) => e.sheetId === item.sheetId);
    return (
      <tr key={"row_" + index}>
        <td className="cell">
          <input
            className="file-checkbox"
            name={item.sheetId}
            checked={arr}
            id={`_checkbox${item.sheetId}`}
            onClick={(e) => handleCheckbox(e, item)}
            type="checkbox"
          />
        </td>
        <td className="cell">{item.serialNumber}</td>
        <td className="cell">{item.fileName}</td>
        <td className="cell">
          {item.pidNumber !== "Not Detected" ? (
            item.pidNumber
          ) : item.isProcessed === "No" && item.processedPercentage == "0%" ? (
            <p
              contenteditable="true"
              spellCheck="false"
              id={"pidNumber_" + item.sheetId}
              className={"pid_" + item.sheetId}
              onBlur={(e) => onEditBlur(e, item)}
              style={{ color: "red" }}
            >
              Not Detected
            </p>
          ) : (
            ""
          )}
        </td>
        <td className="cell">
          {item.pidRevisionNumber !== "Not Detected" ? (
            item.pidRevisionNumber
          ) : item.isProcessed === "No" && item.processedPercentage == "0%" ? (
            <p
              contenteditable="true"
              spellCheck="false"
              id={"pidRevisionNumber_" + item.sheetId}
              className={"pid_" + item.sheetId}
              onBlur={(e) => onEditBlur(e, item)}
              style={{ color: "red" }}
            >
              Not Detected
            </p>
          ) : (
            ""
          )}
        </td>
        <td className="cell">{item.sheetNumber}</td>
        <td className="cell">
          {item.pidDescription !== "Not Detected" ? (
            item.pidDescription
          ) : item.isProcessed === "No" && item.processedPercentage == "0%" ? (
            <p
              contenteditable="true"
              spellCheck="false"
              id={"pidDescription_" + item.sheetId}
              className={"pid_" + item.sheetId}
              onBlur={(e) => onEditBlur(e, item)}
              style={{ color: "red" }}
            >
              Not Detected
            </p>
          ) : (
            ""
          )}
        </td>
        <td className="cell">{item.fileUploadDttm}</td>
        <td className="cell">{item.fileUploadedBy}</td>
        <td className="cell">
          {item.isProcessed === "Yes" ? (
            <p>
              <span class="dot"></span> Yes{" "}
            </p>
          ) : (
            <p>
              <span class="dot1"></span> No{" "}
            </p>
          )}{" "}
        </td>
        <td className="cell">{item.processedPercentage}</td>
        <td className="cell">
          {item.isApproved === "Yes" ? (
            <p>
              <span class="dot"></span> Yes{" "}
            </p>
          ) : (
            <p>
              <span class="dot1"></span> No{" "}
            </p>
          )}{" "}
        </td>
        <td className="cell">{item.approvedDttm}</td>
        <td className="cell">{item.totalLineCount}</td>
        <td className="cell">{item.totalValveCount}</td>
        <td className="cell">
          {item.isDuplicateFile === "Yes" ? (
            <p>
              <span class="dot"></span> Yes{" "}
            </p>
          ) : (
            <p>
              <span class="dot1"></span> No{" "}
            </p>
          )}{" "}
        </td>
        <td className="cell">
          {item.isDuplicateFile === "Yes" ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <input
                type="radio"
                value="Yes"
                id={item.sheetId}
                checked={item.isSuperseded === "Yes"}
                onChange={supersededCheck}
              />
              <label
                for="html"
                style={{ marginBottom: "0", padding: "0 0.3vw" }}
              >
                Yes{" "}
              </label>
              <input
                type="radio"
                value="No"
                id={item.sheetId}
                checked={item.isSuperseded === "No"}
                onChange={supersededCheck}
              />
              <label
                for="html"
                style={{ marginBottom: "0", padding: "0 0.3vw" }}
              >
                No
              </label>
            </div>
          ) : (
            <div style={{ display: "flex", alignItems: "center" }}>
              <input
                type="radio"
                label="Yes"
                value="Yes"
                checked={item.isSuperseded === "Yes"}
                disabled
              />
              <label
                for="html"
                style={{ marginBottom: "0", padding: "0 0.3vw" }}
              >
                Yes
              </label>
              <input
                type="radio"
                label="No"
                value="No"
                checked={item.isSuperseded === "No"}
                disabled
              />
              <label
                for="html"
                style={{ marginBottom: "0", padding: "0 0.3vw" }}
              >
                No
              </label>
            </div>
          )}
        </td>
        <td className="cell">{item.rejectionRemark}</td>
      </tr>
    );
  });
  const elementsHtmlforEditConfig = toJS(UserStore.editConfigData).map(
    (item, index) => {
      const selectedRowID = toJS(UserStore.selectedRowID);

      const arr = selectedRowID.some((e) => e.sheetId === item.sheetId);
      return (
        <tr key={"row_" + index}>
          <td className="cell">{item.configId}</td>
          <td className="cell">{item.key1}</td>
          <td className="cell">{item.key2}</td>
          <td className="cell">{item.value}</td>
          <td className="cell">{item.remarks}</td>
          <td className="cell">
            {
              <div
                classname="more-actions-wrapper"
                style={{ display: "inline-flex" }}
              >
                <Tooltip title="Edit Configuration" arrow>
                  <div
                    classname="edit-icon"
                    onClick={(e) => handleEdit(e)}
                    style={{ paddingRight: "1vw", cursor: "pointer" }}
                  >
                    <img id={item.configId} src="/images/icon_edit.svg" />
                  </div>
                </Tooltip>
                <Tooltip title="Delete Configuration" arrow>
                  <div
                    classname="delete-icon"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => handledelete(e)}
                  >
                    <img id={item.configId} src="/images/icon_delete.svg" />
                  </div>
                </Tooltip>
              </div>
            }
          </td>
        </tr>
      );
    }
  );

  if (fromeditconfigflag === "No") {
    return (
      <div className="processgrid-container">
        <table className="table-pid" id="resize-pid" ref={tableRef}>
          <thead className="table-head-pid" style={{ height: "3vw" }}>
            <TableFilter
              rows={processData}
              onFilterUpdate={filterUpdated}
              rowClass={"table-filter"}
            >
              <th>
                <div>
                  <Checkbox
                    checked={isSelectAll}
                    onClick={selects}
                    name="checkedB"
                    className="checkbox-pid"
                  />
                </div>
              </th>
              <th
                key="serialNumber"
                filterkey="serialNumber"
                casesensitive={"true"}
                showsearch={"true"}
              >
                <div
                  style={{
                    fontSize: "1vw",
                    fontWeight: "500",
                    paddingLeft: "0.5vw",
                    whiteSpace: "noWrap",
                  }}
                >
                  Sr No.
                </div>
              </th>
              <th
                key="fileName"
                filterkey="fileName"
                casesensitive={"true"}
                showsearch={"true"}
              >
                <div className="resize-div">File Name</div>
              </th>
              <th
                key="pidNumber"
                filterkey="pidNumber"
                casesensitive={"true"}
                showsearch={"true"}
              >
                <div className="resize-div">PID No.</div>
              </th>
              <th
                key="pidRevisionNumber"
                filterkey="pidRevisionNumber"
                casesensitive={"true"}
                showsearch={"true"}
              >
                <div className="resize-div">PID Rev. No.</div>
              </th>
              <th
                key="sheetNumber"
                filterkey="sheetNumber"
                casesensitive={"true"}
                showsearch={"true"}
              >
                <div className="resize-div">Sheet No.</div>
              </th>
              <th
                key="pidDescription"
                filterkey="pidDescription"
                casesensitive={"true"}
                showsearch={"true"}
              >
                <div className="resize-div">PID Description</div>
              </th>
              <th
                key="fileUploadDttm"
                filterkey="fileUploadDttm"
                casesensitive={"true"}
                showsearch={"true"}
              >
                <div className="resize-div">Upload Date {"&"} Time Stamp</div>
              </th>
              <th
                key="fileUploadedBy"
                filterkey="fileUploadedBy"
                casesensitive={"true"}
                showsearch={"true"}
              >
                <div className="resize-div">Upload User Name</div>
              </th>
              <th
                key="isProcessed"
                filterkey="isProcessed"
                casesensitive={"true"}
                showsearch={"true"}
              >
                <div className="resize-div">Processed</div>
              </th>
              <th
                key="processedPercentage"
                filterkey="processedPercentage"
                casesensitive={"true"}
                showsearch={"true"}
              >
                <div className="resize-div">Processed %</div>
              </th>
              <th
                key="isApproved"
                filterkey="isApproved"
                casesensitive={"true"}
                showsearch={"true"}
              >
                <div className="resize-div">Approved</div>
              </th>
              <th
                key="approvedDttm"
                filterkey="approvedDttm"
                casesensitive={"true"}
                showsearch={"true"}
              >
                <div className="resize-div">Approved Date {"&"} Time</div>
              </th>
              <th
                key="totalLineCount"
                filterkey="totalLineCount"
                casesensitive={"true"}
                showsearch={"true"}
              >
                <div className="resize-div">Total Line Count</div>
              </th>
              <th
                key="totalValveCount"
                filterkey="totalValveCount"
                casesensitive={"true"}
                showsearch={"true"}
              >
                <div className="resize-div">Total Valve Count</div>
              </th>
              <th
                key="isDuplicateFile"
                filterkey="isDuplicateFile"
                casesensitive={"true"}
                showsearch={"true"}
              >
                <div className="resize-div">Duplicate File</div>
              </th>
              <th
                key="isSuperseded"
                filterkey="isSuperseded"
                casesensitive={"true"}
                showsearch={"true"}
              >
                <div className="resize-div">Superseded</div>
              </th>
              <th
                key="rejectionRemark"
                filterkey="rejectionRemark"
                casesensitive={"true"}
                showsearch={"true"}
              >
                <div className="resize-div">Rejection Remark</div>
              </th>
            </TableFilter>
          </thead>
          <tbody>{elementsHtml}</tbody>
        </table>

        <div className="pagerFooter">
          <div className="countdisplay">
            <p style={{ marginLeft: "1vw", marginRight: "5vw" }}>
              Total : {storeData.length}
            </p>
            &nbsp;
            <p style={{ marginRight: "5vw" }}>
              {toJS(UserStore.selectedRowID.length)} Row(s) Selected
            </p>
          </div>
          <div className="pagnaitioncont">
            <label for="data">Rows per page</label>
            <select
              name="rows-perpage"
              id="rows-perpage"
              onChange={(e) => customPagination(e, editConfigData)}
              defaultValue = {toJS(UserStore.perPageData)}
              defaultChecked = {toJS(UserStore.perPageData)}
            >
              <option
                id="ten-rows"
                value="10"
                // selected={toJS(UserStore.perPageData) === "10" ? true : false}
              >
                10
              </option>

              <option
                id="thirty-rows"
                value="30"
                // selected={toJS(UserStore.perPageData) === "30" ? true : false}
              >
                30
              </option>

              <option
                id="fifty-rows"
                value="50"
                // selected={toJS(UserStore.perPageData) === "50" ? true : false}
              >
                50
              </option>

              <option
                id="hundred-rows"
                value="100"
                // selected={toJS(UserStore.perPageData) === "100" ? true : false}
              >
                100
              </option>
            </select>
            <Pager
              className="pager-style-pid"
              currentPage={currentPage}
              resultCount={storeData.length}
              resultsPerPage={resultsPerPage}
              setCurrentPageValue={updatePagination}
            ></Pager>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="processgrid-container">
        <table className="table-pid" id="resize-pid" ref={tableRef}>
          <thead
            className="table-head-pid editConfigTable"
            style={{ height: "3vw", backgroundColor: "#E8EAEB" }}
          >
            <TableFilter
              rows={editConfigData}
              onFilterUpdate={filterUpdated}
              rowClass={"table-filter"}
            >
              <th
                key="configId"
                filterkey="configId"
                casesensitive={"true"}
                showsearch={"true"}
              >
                <div className="resize-div" style={{ minWidth: "15.6vw" }}>
                  Config Id
                </div>
              </th>
              <th
                key="key1"
                filterkey="key1"
                casesensitive={"true"}
                showsearch={"true"}
              >
                <div className="resize-div" style={{ minWidth: "15.6vw" }}>
                  Key 1
                </div>
              </th>
              <th
                key="key2"
                filterkey="key2"
                casesensitive={"true"}
                showsearch={"true"}
              >
                <div className="resize-div" style={{ minWidth: "15.6vw" }}>
                  Key 2
                </div>
              </th>
              <th
                key="value"
                filterkey="value"
                casesensitive={"true"}
                showsearch={"true"}
              >
                <div className="resize-div" style={{ minWidth: "15.6vw" }}>
                  Value
                </div>
              </th>
              <th
                key="remarks"
                filterkey="remarks"
                casesensitive={"true"}
                showsearch={"true"}
              >
                <div className="resize-div" style={{ minWidth: "15.6vw" }}>
                  Remarks
                </div>
              </th>
              <th
                key=""
                casesensitive={"true"}
                showsearch={"true"}
                style={{ width: "5vw" }}
              ></th>
            </TableFilter>
          </thead>
          <tbody>{elementsHtmlforEditConfig}</tbody>
        </table>
        {show && (
          <Dialog
            open={show}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              <span style={{ fontSize: "1.2vw", color: "#fff" }}>
                Edit Configuration
              </span>
            </DialogTitle>
            <DialogContent>
              <InputLabel id="demo-simple-select-label"></InputLabel>

              <TextField
                margin="dense"
                id={modalId}
                name="key1"
                label="Key1"
                onChange={(e) => handleChange1(e)}
                fullWidth
                value={editconfig[0].key1}
                style={{ fontSize: "1.04vw", width: "95%" }}
              />
              <TextField
                margin="dense"
                id={modalId}
                name="key2"
                label="Key2"
                onChange={(e) => handleChange1(e)}
                fullWidth
                value={editconfig[0].key2}
                style={{ fontSize: "1.04vw", width: "95%" }}
              />
              <TextField
                margin="dense"
                id={modalId}
                name="value"
                label="Value"
                onChange={(e) => handleChange1(e)}
                fullWidth
                value={editconfig[0].value}
                style={{ fontSize: "1.04vw", width: "95%" }}
              />
              <TextField
                margin="dense"
                id={modalId}
                name="remarks"
                label="Remarks"
                onChange={(e) => handleChange1(e)}
                fullWidth
                value={editconfig[0].remarks}
                style={{ fontSize: "1.04vw", width: "95%" }}
              />
            </DialogContent>
            <DialogActions style={{ padding: "0 12vw" }}>
              <Button
                style={{
                  background: "#0C629B",
                  color: "#fff",
                  width: "8.4vw",
                  height: "2.29vw",
                  fontSize: "0.9vw",
                  textTransform: "none",
                }}
                onClick={(e) => handleEditSave(e)}
                color="primary"
                id={modalId}
              >
                Save
              </Button>
              <Button
                onClick={handleClose}
                color="primary"
                style={{
                  background: "#ffffff",
                  border: "0.05vw solid #0c629b",
                  borderRadius: "0.15vw",
                  width: "8.4vw",
                  height: "2.29vw",
                  fontSize: "0.9vw",
                  color: "#0c629b",
                  textTransform: "none",
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        )}
        <Dialog
          className="delete-confirm"
          open={deleteconfig}
          onClose={handleCloseDialog}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle
            id="form-dialog-title"
            style={{ height: "0.625em" }}
          ></DialogTitle>
          <DialogContent>
            <div style={{ fontSize: "1vw" }}>Are you sure to delete?</div>
            <IconButton
              onClick={handleCloseDialog}
              style={{
                padding: "0",
                float: "right",
                background: "#0C629B",
                color: "#fff",
                position: "absolute",
                right: "15%",
              }}
            >
              {" "}
              <Cancel />{" "}
            </IconButton>
            <IconButton
              onClick={(e) => handlePurge(e)}
              style={{
                padding: "0",
                background: "#0C629B",
                color: "white",
                position: "absolute",
                right: "2%",
              }}
            >
              {" "}
              <CircleChecked />{" "}
            </IconButton>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
};
export default ProcessGridTable;
