import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  memo,
  Profiler,
} from "react";
import { useLocation } from "react-router-dom";
import "react-table-filter/lib/styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Checkbox from "@material-ui/core/Checkbox";
import { toJS } from "mobx";
//import TableFilter from "react-table-filter";
import TableFilter from "./ReactFilter/index";
import UserStore from "../../UserStore";
import Pager from "./PagerPID/PagerSR";
import {} from "./StampGrid.scss";
import {} from "./lib/styles.css";
import { isUndefined } from "@microsoft/applicationinsights-core-js";
import Moment from "moment";

const StampGridTable = ({
  data,
  onRowSelected,
  searchrows,
  dropdownChange,
}) => {
  const tableRef = useRef(null);
  const location = useLocation();
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [firstLoad, setfirstLoad] = useState(false);
  let [excelreportrow, setexcelreportrow] = useState([]);
  const [pageChange, setPageChange] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("perPageData") === null) {
      localStorage.setItem("perPageData", 10);
    }
    UserStore.SetPerPageData(localStorage.getItem("perPageData"));
  }, []);
  const [currentPage, setCurrentPage] = useState(
    Number(toJS(UserStore.skipData)) / Number(toJS(UserStore.perPageData)) + 1
  );
  let [filterConfig, setFilterConfig] = useState({});
  const [skip, setSkip] = useState(
    new URLSearchParams(location.search).get("skip") ?? 0
  );
  let storeData = toJS(UserStore.PIDProcessData);

  const [tableRows, setTableRows] = useState(data);
  const [top, topValueUpdate] = useState(
    new URLSearchParams(location.search).get("top") ??
      toJS(UserStore.perPageData)
  );
  const [processData, setProcessData] = useState(
    storeData.slice(skip, Number(skip) + Number(top))
  );

  let isTickBased = localStorage.getItem("isIsometricTickBased")
    ? JSON.parse(localStorage.getItem("isIsometricTickBased"))
    : UserStore.isTickBasedIsometric;
  let resultsPerPage = top;

  const filterFn = () => {
    const countOfData = Number(skip) + Number(top);
    const slicedData = storeData.slice(skip, countOfData);
    setProcessData(slicedData);
    if (searchrows && !firstLoad) {
      setDataPerPage(searchrows);
      setfirstLoad(true);
    }

    if (pageChange) {
      const items = document.getElementsByClassName("file-checkbox");
      for (var i = 0; i < items.length; i++) {
        if (items[i].type == "checkbox" && items[i].checked === true)
          items[i].checked = false;
      }
      // setSelectedRows([])
    }
  };

  useEffect(() => {
    if (Number(toJS(UserStore.skipData)) === 0) {
      setCurrentPage(1);
      setSkip((1 - 1) * top);
    }
  }, [UserStore.skipData]);
  useEffect(() => {
    const skipValue =
      searchrows ||
      dropdownChange === "Processing Failed" ||
      dropdownChange === "Processed"
        ? 0
        : skip;
    const countOfData = Number(skipValue) + Number(top);
    const slicedData = storeData.slice(skipValue, countOfData);
    setProcessData(slicedData);
    if (
      dropdownChange === "Processing Failed" ||
      dropdownChange === "Processed"
    ) {
      UserStore.PIDselectedRowID = [];
    }
    if (pageChange) {
      const items = document.getElementsByClassName("file-checkbox");
      for (var i = 0; i < items.length; i++) {
        if (items[i].type == "checkbox" && items[i].checked === true)
          items[i].checked = false;
      }
      // setSelectedRows([])
    }
  }, [skip, currentPage, filterConfig, pageChange, searchrows, dropdownChange]);

  if (searchrows && !firstLoad) {
    setCurrentPage(1);
    setSkip((1 - 1) * top);
    UserStore.setSkipData((1 - 1) * top);
    setfirstLoad(true);
    excelreportrow = [];
    excelreportrow.push(storeData);
    UserStore.addReportdata([]);
    UserStore.PIDselectedRowID = [];
  }

  const setDataPerPage = () => {
    const countOfData = Number(skip) + Number(top);
    const slicedData = storeData.slice(skip, countOfData);
    setProcessData(slicedData);
    UserStore.setreportStamp(true);
    localStorage.setItem("setreportStamp", true);
  };

  const filterUpdated = (newData, filterConfiguration) => {
    setCurrentPage(1);
    setSkip((1 - 1) * top);
    UserStore.setSkipData((1 - 1) * top);
    setFilterConfig(filterConfiguration);
    // setProcessData(newData);
    setDataPerPage(newData);
    UserStore.setFilterProcessConfiguration(filterConfiguration);
    UserStore.setPIDProcessData(newData);
    storeData = toJS(UserStore.PIDProcessData);
    UserStore.setStampFilterData(newData);
    filterFn();
    UserStore.PIDselectedRowID = [];
    setexcelreportrow([]);
    UserStore.addReportdata([]);
    document.getElementById("row_count").innerHTML = "0";
    setIsSelectAll(false);
  };

  const updatePagination = (newPageNumber, count) => {
    if (count !== 0) {
      UserStore.setLoader(true);
    }
    setCurrentPage(newPageNumber);
    setSkip((newPageNumber - 1) * top);
    if (newPageNumber > 1) setPageChange(true);
    UserStore.setSkipData((newPageNumber - 1) * top);
  };

  const customPagination = (e, dat, s) => {
    setCurrentPage(1);
    setSkip((1 - 1) * top);
    UserStore.setSkipData((1 - 1) * top);
    topValueUpdate(e.target.selectedOptions[0].value);
    if (e.target.selectedOptions[0].value === "30") {
      const slicedData2 = dat.slice(skip, 3 * 10);

      setProcessData(slicedData2);
    } else if (e.target.selectedOptions[0].value === "50") {
      const slicedData3 = dat.slice(skip, 5 * 10);

      setProcessData(slicedData3);
    } else if (e.target.selectedOptions[0].value === "100") {
      const slicedData4 = dat.slice(skip, 10 * 10);

      setProcessData(slicedData4);
    } else {
      const slicedData1 = dat.slice(skip, 1 * 10);

      setProcessData(slicedData1);
    }
    UserStore.SetPerPageData(e.target.selectedOptions[0].value);
    localStorage.setItem("perPageData", e.target.selectedOptions[0].value);
  };

  const handleCheckbox = (e, item) => {
    if (e.target.checked === true) {
      if (toJS(UserStore.reportData).length === 0) {
        excelreportrow = [];
      } else {
        excelreportrow = toJS(UserStore.reportData);
      }
      UserStore.setPIDselectedRowID(item);
      excelreportrow.push(item);
      UserStore.addReportdata(excelreportrow);
      UserStore.setreportStamp(true);
      localStorage.setItem("setreportStamp", true);
      document.getElementById("row_count").innerHTML =
        toJS(UserStore.PIDselectedRowID).length + "";
    } else {
      // let newRows = [];
      const givefilter = toJS(UserStore.PIDselectedRowID);
      let newRows = givefilter.filter(
        (sr) => sr.splitedFileId !== item.splitedFileId
      );
      excelreportrow = newRows;
      if (newRows.length === 0) {
        UserStore.addReportdata([]);
      } else {
        UserStore.addReportdata(newRows);
      }
      UserStore.PIDselectedRowID = [];
      UserStore.PIDselectedRowID = newRows;
      document.getElementById("row_count").innerHTML =
        toJS(UserStore.PIDselectedRowID).length + "";
    }

    if (storeData.length > toJS(UserStore.PIDselectedRowID).length) {
      setIsSelectAll(false);
    }

    if (storeData.length === toJS(UserStore.PIDselectedRowID).length) {
      setIsSelectAll(true);
    }
  };
  useEffect(() => {
    if (isSelectAll) {
      for (var i = 0; i < processData.length; i++) {
        document.getElementById(
          `_checkbox${processData[i].splitedFileId}`
        ).checked = true;
      }
    } else if (isSelectAll === false) {
      const selectedRowsList = toJS(UserStore.PIDselectedRowID);
      for (var i = 0; i < processData.length; i++) {
        const retainSelectedRow = selectedRowsList.filter(
          (row) => row.splitedFileId === processData[i].splitedFileId
        );
        if (retainSelectedRow.length === 1) {
          document.getElementById(
            `_checkbox${retainSelectedRow[0].splitedFileId}`
          ).checked = true;
        } else {
          document.getElementById(
            `_checkbox${processData[i].splitedFileId}`
          ).checked = false;
        }
      }
    }
  }, [processData]);

  const selects = (e) => {
    const sel = e.target.checked;
    setIsSelectAll(!isSelectAll);
    if (sel) {
      for (var i = 0; i < processData.length; i++) {
        document.getElementById(
          `_checkbox${processData[i].splitedFileId}`
        ).checked = true;
      }
      document.getElementById("row_count").innerHTML = storeData.length + "";
      //   allRows.push(ele[i].name);
      // setSelectedRows(processData);
      // UserStore.addReportdata(data);
      // UserStore.PIDselectedRowID = data;
      // if(!toJS(UserStore.PIDProcessData.length)) {
      UserStore.PIDselectedRowID = toJS(UserStore.PIDProcessData);
      excelreportrow = toJS(UserStore.PIDProcessData);
      UserStore.addReportdata(excelreportrow);
      // }
      // else {
      //   UserStore.PIDselectedRowID = UserStore.PIDProcessData;
      // }
    } else {
      for (var i = 0; i < processData.length; i++) {
        document.getElementById(
          `_checkbox${processData[i].splitedFileId}`
        ).checked = false;
      }
      document.getElementById("row_count").innerHTML = "0";
      // setSelectedRows([])
      UserStore.PIDselectedRowID = [];
      excelreportrow = [];
      UserStore.addReportdata([]);
      setexcelreportrow([]);
      // UserStore.addReportdata(data);
    }
  };

  useEffect(() => {
    const checkedRow = document.getElementById(
      `_checkbox${UserStore.PIDselectedRowID}`
    );
    if (checkedRow) checkedRow.checked = true;
  }, [UserStore.PIDselectedRowID]);

  let elementsHtml = processData.map((item, index) => {
    // const selectedRowID = toJS(UserStore.PIDselectedRowID);
    // const arr = selectedRowID.some(e => e.splitedFileId === item.splitedFileId);
    return (
      <tr key={"row_" + index}>
        <td className="cell">
          <input
            className="file-checkbox"
            name={item.splitedFileId}
            // checked={arr}
            id={`_checkbox${item.splitedFileId}`}
            onClick={(e) => handleCheckbox(e, item)}
            type="checkbox"
          />
        </td>
        <td className="cell">{item.transmittalNumber}</td>
        <td className="cell">{item.documentName}</td>
        <td className="cell">
          {item.isProcessed === "Yes" ? (
            <p>
              <span class="dot"></span> Yes{" "}
            </p>
          ) : (
            <p>
              <span class="dot1"></span> No{" "}
            </p>
          )}{" "}
        </td>
        <td className="cell">{item.processedPercentage}</td>{" "}
        {!isTickBased && (
          <td className="cell">
            {item.isDetected === "Yes" ? (
              <p>
                <span class="dot"></span> Yes{" "}
              </p>
            ) : (
              <p>
                <span class="dot1"></span> No{" "}
              </p>
            )}
          </td>
        )}
        <td className="cell">{item.lineNumber}</td>
        <td className="cell">{item.isometricNumber}</td>
        <td className="cell">{item.sheetNumber}</td>
        <td className="cell">{item.revisionNumber}</td>
        <td className="cell">
          {isTickBased ? (
            item.labelCheckbox01
          ) : item.phrase1 == "Found" ? (
            "Found"
          ) : (
            <font color="red">Not Found</font>
          )}
        </td>
        <td className="cell">
          {isTickBased ? (
            item.checkbox01
          ) : item.phrase2 == "Found" ? (
            "Found"
          ) : (
            <font color="red">Not Found</font>
          )}
        </td>
        <td className="cell">
          {isTickBased ? (
            item.labelCheckbox02
          ) : item.phrase3 == "Found" ? (
            "Found"
          ) : (
            <font color="red">Not Found</font>
          )}
        </td>
        <td className="cell">
          {isTickBased ? (
            item.checkbox02
          ) : item.phrase4 == "Found" ? (
            "Found"
          ) : (
            <font color="red">Not Found</font>
          )}
        </td>
        <td className="cell">
          {isTickBased ? (
            item.labelCheckbox03
          ) : item.isChange === "Yes" ? (
            <p>
              <span class="dot"></span> Yes
            </p>
          ) : item.isChange === "No" ? (
            <p>
              <span class="dot1"></span> No
            </p>
          ) : (
            <p></p>
          )}
        </td>
        <td className="cell">
          {isTickBased ? (
            item.checkbox03
          ) : item.isDuplicate === "Yes" ? (
            <p>
              <span class="dot"></span> Yes
            </p>
          ) : (
            <p>
              <span class="dot1"></span> No
            </p>
          )}
        </td>
        <td className="cell">
          {isTickBased ? item.labelCheckbox04 : item.modifiedDoc}
        </td>
        <td className="cell">
          {isTickBased ? item.checkbox04 : !isUndefined(item.processingDate) ? Moment.utc(item.processingDate.includes("+") ? item.processingDate.split("+")[0].trim().replaceAll("-", "/") : item.processingDate, "YYYY/MM/DD hh:mm:ss a").local().format("DD/MM/YYYY hh:mm:ss a"): ""}
        </td>
        {isTickBased && (
          <td className="cell">
            {item.isChange === "Yes" ? (
              <p>
                <span class="dot"></span> Yes
              </p>
            ) : item.isChange === "No" ? (
              <p>
                <span class="dot1"></span> No
              </p>
            ) : (
              <p></p>
            )}
          </td>
        )}
        {isTickBased && (
          <td className="cell">
            {item.isDuplicate === "Yes" ? (
              <p>
                <span class="dot"></span> Yes
              </p>
            ) : (
              <p>
                <span class="dot1"></span> No
              </p>
            )}
          </td>
        )}
        {isTickBased && <td className="cell">{item.modifiedDoc}</td>}
        {isTickBased && <td className="cell">{!isUndefined(item.processingDate) ? Moment.utc(item.processingDate.includes("+") ? item.processingDate.split("+")[0].trim().replaceAll("-", "/") : item.processingDate, "YYYY/MM/DD hh:mm:ss a").local().format("DD/MM/YYYY hh:mm:ss a"): ""}</td>}
        {isTickBased && <td className="cell">{!isUndefined(item.modiffiedDate) ? Moment.utc(item.modiffiedDate.includes("+") ? item.modiffiedDate.split("+")[0].trim().replaceAll("-", "/") : item.modiffiedDate, "YYYY/MM/DD hh:mm:ss a").local().format("DD/MM/YYYY hh:mm:ss a"): ""}</td>}
        {isTickBased && <td className="cell">{item.modifiedBy}</td>}
        {isTickBased && <td className="cell">{item.acceptanceStatus}</td>}
        {isTickBased && <td className="cell">{item.confidenceLevel}</td>}
        {isTickBased && <td className="cell">{item.rejectionRemark}</td>}
        {!isTickBased && <td className="cell">{!isUndefined(item.modiffiedDate) ? Moment.utc(item.modiffiedDate.includes("+") ? item.modiffiedDate.split("+")[0].trim().replaceAll("-", "/") : item.modiffiedDate, "YYYY/MM/DD hh:mm:ss a").local().format("DD/MM/YYYY hh:mm:ss a"): ""}</td>}
        {!isTickBased && <td className="cell">{item.modifiedBy}</td>}
        {!isTickBased && <td className="cell">{item.acceptanceStatus}</td>}
        {!isTickBased && <td className="cell">{item.confidenceLevel}</td>}
        {!isTickBased && <td className="cell">{item.rejectionRemark}</td>}
      </tr>
    );
  });

  // const xlRepotdata = toJS(UserStore.PIDselectedRowID).length > 0 ? toJS(UserStore.PIDselectedRowID) : storeData;
  // UserStore.addReportdata(xlRepotdata);
  useEffect(() => {
    UserStore.setLoader(false);
  });

  return (
    <div className="processgrid-container" id="data-loader">
      {/* <Profiler
                      id="filter"
                      onRender={
                          (id,
                          phase, // either "mount" (if the tree just mounted) or "update" (if it re-rendered)
                          actualDuration, // time spent rendering the committed update
                          baseDuration, // estimated time to render the entire subtree without memoization
                          startTime, // when React began rendering this update
                          commitTime, // when React committed this update
                          interactions) => {
                          console.log({id, phase, actualDuration, baseDuration,startTime,commitTime,interactions})
                          }
                      }> */}
      <div
        class="overlay__inner"
        id="overlay_inner"
        style={UserStore.loader === false ? { display: "none" } : {}}
      >
        <div class="overlay__content">
          <span class="spinner"> </span>
        </div>
      </div>
      <table className="table-pid" id="resize-pid" ref={tableRef}>
        <thead className="table-head-pid" on>
          <TableFilter
            rows={tableRows}
            onFilterUpdate={filterUpdated}
            rowClass={"table-filter"}
            initialFilters={filterConfig}
          >
            <th>
              <div>
                <Checkbox
                  // type="checkbox"
                  checked={isSelectAll}
                  onChange={selects}
                  name="checkedB"
                  className="checkbox-pid"
                />
              </div>
            </th>
            <th
              key="transmittalNumber"
              filterkey="transmittalNumber"
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">Transmittal No.</div>
            </th>
            <th
              key="documentName"
              //filterkey="documentName"
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">Document Name</div>
            </th>
            <th
              key="isProcessed"
              filterkey="isProcessed"
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">Processed</div>
            </th>
            <th
              key="processedPercentage"
              filterkey="processedPercentage"
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">Percentage</div>
            </th>
            {!isTickBased && (
              <th
                key="isDetected"
                filterkey="isDetected"
                casesensitive={"true"}
                showsearch={"true"}
              >
                <div className="resize-div">Detected</div>
              </th>
            )}
            <th
              key="lineNumber"
              //filterkey="lineNumber"
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">Line No.</div>
            </th>
            <th
              key="isometricNumber"
              //filterkey="isometricNumber"
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">Isometric No.</div>
            </th>
            <th
              key="sheetNumber"
              //filterkey="sheetNumber"
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">Sheet No.</div>
            </th>
            <th
              key="revisionNumber"
              filterkey="revisionNumber"
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">Rev. No.</div>
            </th>
            <th
              key={isTickBased ? "labelCheckbox01" : "phrase1"}
              filterkey={isTickBased ? "labelCheckbox01" : "phrase1"}
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">
                {isTickBased ? "Checkbox01 Label" : "Phrase 1"}
              </div>
            </th>
            <th
              key={isTickBased ? "checkbox01" : "phrase2"}
              filterkey={isTickBased ? "checkbox01" : "phrase2"}
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">
                {isTickBased ? "Checkbox01 Selection" : "Phrase 2"}
              </div>
            </th>
            <th
              key={isTickBased ? "labelCheckbox02" : "phrase3"}
              filterkey={isTickBased ? "labelCheckbox02" : "phrase3"}
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">
                {isTickBased ? "Checkbox02 Label" : "Phrase 3"}
              </div>
            </th>
            <th
              key={isTickBased ? "checkbox02" : "phrase4"}
              filterkey={isTickBased ? "checkbox02" : "phrase4"}
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">
                {isTickBased ? "Checkbox02 Selection" : "Phrase 4"}
              </div>
            </th>
            <th
              key={isTickBased ? "labelCheckbox03" : "isChange"}
              filterkey={isTickBased ? "labelCheckbox03" : "isChange"}
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">
                {isTickBased ? "Checkbox03 Label" : "Change"}
              </div>
            </th>
            <th
              key={isTickBased ? "checkbox03" : "isDuplicate"}
              filterkey={isTickBased ? "checkbox03" : "isDuplicate"}
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">
                {isTickBased ? "Checkbox03 Selection" : "Duplicate"}
              </div>
            </th>
            <th
              key={isTickBased ? "labelCheckbox04" : "modifiedDoc"}
              filterkey={isTickBased ? "labelCheckbox04" : "modifiedDoc"}
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">
                {isTickBased ? "Checkbox04 Label" : "Modified Doc."}
              </div>
            </th>
            <th
              key={isTickBased ? "checkbox04" : "processingDate"}
              filterkey={isTickBased ? "checkbox04" : "processingDate"}
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">
                {isTickBased ? "Checkbox04 Selection" : "Processing Date Time"}
              </div>
            </th>
            {isTickBased && (
              <th
                key="isChange"
                filterkey="isChange"
                casesensitive={"true"}
                showsearch={"true"}
              >
                <div className="resize-div">Change</div>
              </th>
            )}
            {isTickBased && (
              <th
                key="isDuplicate"
                filterkey="isDuplicate"
                casesensitive={"true"}
                showsearch={"true"}
              >
                <div className="resize-div">Duplicate</div>
              </th>
            )}
            {isTickBased && (
              <th
                key="modifiedDoc"
                filterkey="modifiedDoc"
                casesensitive={"true"}
                showsearch={"true"}
              >
                <div className="resize-div">Modified Doc.</div>
              </th>
            )}
            {isTickBased && (
              <th
                key="processingDate"
                //filterkey="processingDate"
                casesensitive={"true"}
                showsearch={"true"}
              >
                <div className="resize-div">Processing Date</div>
              </th>
            )}
            {isTickBased && (
              <th
                key="modiffiedDate"
                //filterkey="modiffiedDate"
                casesensitive={"true"}
                showsearch={"true"}
              >
                <div className="resize-div">Modified Date Time</div>
              </th>
            )}
            {isTickBased && (
              <th
                key="modifiedBy"
                filterkey="modifiedBy"
                casesensitive={"true"}
                showsearch={"true"}
              >
                <div className="resize-div">Modified By</div>
              </th>
            )}
            {isTickBased && (
              <th
                key="acceptanceStatus"
                filterkey="acceptanceStatus"
                casesensitive={"true"}
                showsearch={"true"}
              >
                <div className="resize-div">Acceptance Status</div>
              </th>
            )}
            {isTickBased && (
              <th
                key="confidenceLevel"
                filterkey="confidenceLevel"
                casesensitive={"true"}
                showsearch={"true"}
              >
                <div className="resize-div">Confidence Level</div>
              </th>
            )}
            {isTickBased && (
              <th
                key="rejectionRemark"
                filterkey="rejectionRemark"
                casesensitive={"true"}
                showsearch={"true"}
              >
                <div className="resize-div">Rejection Remark</div>
              </th>
            )}
            {!isTickBased && (
              <th
                key="modiffiedDate"
                //filterkey="modiffiedDate"
                casesensitive={"true"}
                showsearch={"true"}
              >
                <div className="resize-div">Modified Date Time</div>
              </th>
            )}
            {!isTickBased && (
              <th
                key="modifiedBy"
                filterkey="modifiedBy"
                casesensitive={"true"}
                showsearch={"true"}
              >
                <div className="resize-div">Modified By</div>
              </th>
            )}
            {!isTickBased && (
              <th
                key="acceptanceStatus"
                filterkey="acceptanceStatus"
                casesensitive={"true"}
                showsearch={"true"}
              >
                <div className="resize-div">Acceptance Status</div>
              </th>
            )}
            {!isTickBased && (
              <th
                key="confidenceLevel"
                filterkey="confidenceLevel"
                casesensitive={"true"}
                showsearch={"true"}
              >
                <div className="resize-div">Confidence Level</div>
              </th>
            )}
            {!isTickBased && (
              <th
                key="rejectionRemark"
                filterkey="rejectionRemark"
                casesensitive={"true"}
                showsearch={"true"}
              >
                <div className="resize-div">Rejection Remark</div>
              </th>
            )}
          </TableFilter>
        </thead>
        <tbody>{elementsHtml}</tbody>
      </table>
      <div className="pagerFooter">
        <div className="countdisplay">
          <p style={{ marginLeft: "1vw", marginRight: "5vw" }}>
            Total : {storeData.length}
          </p>
          &nbsp;
          <p style={{ marginRight: "5vw" }}>
            <span id="row_count">0</span> Row(s) Selected
          </p>
        </div>
        <div className="pagnaitioncont">
          <label for="data">Rows per page</label>
          <select
            name="rows-perpage"
            id="rows-perpage"
            onChange={(e) => customPagination(e, UserStore.PIDProcessData)}
            defaultValue={toJS(UserStore.perPageData)}
            defaultChecked={toJS(UserStore.perPageData)}
          >
            <option id="ten-rows" value="10" selected>
              10
            </option>

            <option id="thirty-rows" value="30">
              30
            </option>

            <option id="fifty-rows" value="50">
              50
            </option>

            <option id="hundred-rows" value="100">
              100
            </option>
          </select>
          <Pager
            className="pager-style-pid"
            currentPage={currentPage}
            resultCount={storeData.length}
            resultsPerPage={resultsPerPage}
            setCurrentPageValue={updatePagination}
          ></Pager>
        </div>
      </div>
      {/* </Profiler> */}
    </div>
  );
};

export default memo(StampGridTable);
