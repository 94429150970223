import React from "react";
import Button from "react-bootstrap/Button";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import axios from "axios";
import Moment from "moment";
import Modal from "react-bootstrap/Modal";
import { toJS } from "mobx";
import UserStore from "../components/UserStore";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";
var rowget = [];

const API_Endpoint = process.env.REACT_APP_API_ENDPOINT;

// Flags and data : id, fileName, flag, frompid depending on type of report

const ExportExcel = ({ id, fileName, flag, frompid, wType }) => {
  
  const fileType = "application/vnd.ms-excel";
  const fileExtension = ".xlsx";
  var today = new Date();
  var startdate = Moment(
    today.getFullYear() + "-" + today.getMonth() + "-" + (today.getDate() + 4)
  ).format("YYYY-MM-DD");
  var enddate = Moment(
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate()
  ).format("YYYY-MM-DD");

  const [show, setshow] = React.useState(false);
  const [message, setshowMessage] = React.useState("");
  const [count, setcount] = React.useState(0);
  const handleClose = () => {
    setshow(false);
  };

  // setting report data for pid

  const formatpid = (item, dateFormat, index) => {
    let columnspid = {
      "Sr No":item.serialNumber,
      "File Name":
        item.fileName != null ? item.fileName : "",
      "PID No": item.isProcessed==="No" && item.processedPercentage==="0%" || item.isProcessed===false && item.processedPercentage===0 ?"Not Detected":item.pidNumber,
      "PID Rev. No.": item.isProcessed==="No" && item.processedPercentage==="0%" || item.isProcessed===false && item.processedPercentage===0 ?"Not Detected":item.pidRevisionNumber,
      "Sheet No.": item.sheetNumber != null ? item.sheetNumber : "",
      "PID Description": item.isProcessed==="No" && item.processedPercentage==="0%" || item.isProcessed===false && item.processedPercentage===0 ?"Not Detected":item.pidDescription,
      "Upload Date & Time Stamp": 
      item.fileUploadDttm != ""
            ? Moment.utc(item.fileUploadDttm, "DD/MM/YYYY hh:mm:ss a")
                .local()
                .format("DD/MM/YYYY hh:mm:ss a")
            : "",
      "Upload User Name": item.fileUploadedBy != null ? item.fileUploadedBy : "",
      "Processed":
      item.isProcessed != null
          ? item.isProcessed === true || item.isProcessed === "Yes"
            ? "Yes"
            : "No"
          : "",
          "Processed %":
        item.processedPercentage != null ? item.processedPercentage : "",
        "Approved": item.isApproved != null ? (item.isApproved ? "Yes" : "No") : "",
        "Approved Date & Time": 
        item.approvedDttm != ""
              ? Moment.utc(item.approvedDttm, "DD/MM/YYYY hh:mm:ss a")
                  .local()
                  .format("DD/MM/YYYY hh:mm:ss a")
              : "",
      "Total Line Count": item.totalLineCount!= null ? item.totalLineCount : "",
      "Total Valve Count": item.totalValveCount!= null ? item.totalValveCount : "",
      "Duplicate File":
      item.isDuplicateFile != null
        ? item.isDuplicateFile === true || item.isDuplicateFile === "Yes"
          ? "Yes"
          : "No"
        : "",
        "Superseded":
        item.isSuperseded != null
          ? item.isSuperseded === true || item.isSuperseded === "Yes"
            ? "Yes"
            : "No"
          : "",
          "Rejection Remark":
        item.rejectionRemark != null ? item.rejectionRemark : "",
    };
    return columnspid;

  }
   // setting report data for SR
  const format = (items, tickBased, dateFormat) => {
    let processingDate;
    let modifiedDate;

    if(items.isChange === false || items.isChange === true)
    {
      items.isChange = items.Change === true ? "Yes" : "No";
    }

    if(items.processingDate)
    {
      if(items.processingDate.includes("+"))
      {
        processingDate = items.processingDate.includes("+") ? items.processingDate.split("+") : items.processingDate;
        processingDate = processingDate[0].trim().replaceAll("-","/");
        processingDate = Moment.utc(processingDate, "YYYY/MM/DD hh:mm:ss").local().format("DD/MM/YYYY hh:mm:ss a");
      }
      else
      {
        processingDate = items.processingDate;
      }      
    }else
    {
      processingDate = '';
    }

    if(items.modiffiedDate)
    {
      if(items.modiffiedDate.includes("+"))
      {
        modifiedDate = items.modiffiedDate.includes("+") ? items.modiffiedDate.split("+") : items.modiffiedDate;
        modifiedDate = modifiedDate[0].trim().replaceAll("-","/");
        modifiedDate = Moment.utc(modifiedDate, "YYYY/MM/DD hh:mm:ss").local().format("DD/MM/YYYY hh:mm:ss a");
      }
      else
      {
        modifiedDate = items.modiffiedDate;
      }      
    }else
    {
      modifiedDate = '';
    }
    
    let columns = {
      "Transmittal No.":
        items.transmittalNumber != null ? items.transmittalNumber : "",
      "Document Name": items.documentName,
      Processed:
        items.isProcessed != null
          ? items.isProcessed === true || items.isProcessed === "Yes"
            ? "Yes"
            : "No"
          : "",
      Percentage:
        items.processedPercentage != null ? items.processedPercentage : "",
      
      "Line No.": items.lineNumber != null ? items.lineNumber : "",
      "Isometric No.":
        items.isometricNumber != null ? items.isometricNumber : "",
      "Sheet No.": items.sheetNumber != null ? items.sheetNumber : "",
      "Rev. No.": items.revisionNumber != null ? items.revisionNumber : "",
    };
    let columns2 = {
      Change:
      items.isChange === null || items.isChange === "" ? "" : items.isChange ,
      Duplicate:
        items.isDuplicate != null
          ? items.isDuplicate === "Yes" || items.isDuplicate === true
            ? "Yes"
            : "No"
          : "",
      "Modified Doc.": items.modifiedDoc != null ? items.modifiedDoc : "",
      "Processing Date Time":
      processingDate != ""
          ? processingDate
          : "",
      "Modified Date Time":
      modifiedDate != ""
          ? modifiedDate
          : "",
      "Modified By": items.modifiedBy != null ? items.modifiedBy : "",
      "Acceptance Status":
        items.acceptanceStatus != null
          ? items.acceptanceStatus === "Accepted" ||
            items.acceptanceStatus === "Yes"
            ? "Yes"
            : items.acceptanceStatus === "Rejected" ||
              items.acceptanceStatus === "No"
            ? "No"
            : ""
          : "",
      "Confidence Level":
        items.confidenceLevel != null ? items.confidenceLevel : "",
      "Rejection Remarks":
        items.rejectionRemark != null ? items.rejectionRemark : "",
    };
    if (tickBased === "true" || wType) {
      columns = {
        ...columns,
        "Checkbox01 Label":
          items.labelCheckbox01,
        "Checkbox01 Selection":
          items.checkbox01,
        "Checkbox02 Label":          
          items.labelCheckbox02,
        "Checkbox02 Selection":
          items.checkbox02,
        "Checkbox03 Label":
          items.labelCheckbox03,
        "Checkbox03 Selection":
          items.checkbox03,
        "Checkbox04 Label":
          items.labelCheckbox04,
        "Checkbox04 Selection":
          items.checkbox04,
        ...columns2,
      };
    } else {
      columns = {
        ...columns,
        Detected:
        items.isDetected != null
          ? items.isDetected === "Yes" || items.isDetected === true
            ? "Yes"
            : "No"
          : "",
        "Phrase 1":
          items.phrase1 != null
            ? items.phrase1 === "Found" || items.phrase1 === true
              ? "Found"
              : "Not Found"
            : "",
        "Phrase 2":
          items.phrase2 != null
            ? items.phrase2 === "Found" || items.phrase2 === true
              ? "Found"
              : "Not Found"
            : "",
        "Phrase 3":
          items.phrase3 != null
            ? items.phrase3 === "Found" || items.phrase3 === true
              ? "Found"
              : "Not Found"
            : "",
        "Phrase 4":
          items.phrase4 != null
            ? items.phrase4 === "Found" || items.phrase4 === true
              ? "Found"
              : "Not Found"
            : "",
        ...columns2,
      };
    }
    return columns;
  };
  const exportToCSV = async (id, fileName, frompid) => {
    
    if (flag == 1) {
      rowget = [];
      let data = toJS(UserStore.reportData);
      if(data.length === 0) {
        data = toJS(UserStore.PIDProcessData);
      }
      let reportstamp = UserStore.reportstamp
        ? UserStore.reportstamp
        : localStorage.getItem("setreportStamp")
        ? localStorage.getItem("setreportStamp")
        : false;

        if(frompid === "pid")
        {
          var res = await axios.get(
            API_Endpoint + `/GetProcessedPidsReport?projectworkSpaceId=${id}`);
        }
        else
        {
        var res = await axios.get(
        API_Endpoint + `/GetProcessedIsometricsReport?workSpaceId=${id}`
      );
        }
      if (res.data.length != 0) {
        const myrows = res.data;
        myrows.map((items, index) => {
          if(frompid === "pid")
          {
            var row2 = formatpid(items, false, index);
          }
          else{
          var row2 = format(items, localStorage.getItem("isIsometricTickBased"), true);
          }
          rowget.push(row2);
        });
      }
     
      var resdata = [];
      if (reportstamp == true) {
        data.map((items, index) => {
         const row3 = (frompid === "pid") ?  formatpid(items, true, index) : format(items, localStorage.getItem("isIsometricTickBased"), false);
          resdata.push(row3);
        });
      } else {
        resdata = rowget;
        rowget = [];
      }
      
      if (resdata.length == 0 && count == 0) {
        setcount(count + 1);
        setshow(true);
        setshowMessage("Please upload files for report generation");
      } else if (resdata.length != 0) {
        const ws = XLSX.utils.json_to_sheet(resdata);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
      }
    } else {
      let res = [];
      resdata = toJS(UserStore.reportData)
        ? toJS(UserStore.reportData)
        : localStorage.getItem("itemChange")
        ? localStorage.getItem("itemChange")
        : [];
      
      if(frompid === "pid")
      {
        resdata = resdata.map((items,index) =>
        formatpid(items, true, index)
      );
      }
     else
     {
      resdata = resdata.map((items) =>
        format(items, localStorage.getItem("isIsometricTickBased"), true)
      );
     }
      const ws = XLSX.utils.json_to_sheet(resdata);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    }
  };

  return (
    <Tooltip title="Click to Download Report" arrow>
    <div
      className="reports-download-icon"
      onClick={(e) => {
        exportToCSV(id, fileName, frompid);
        setcount(0);
      }}
    >


      <img
        src={frompid === "pid"?"/images/export.svg":"/images/icon_download_report.svg"}
        className="toolbar-icon"
        alt="download-icon"
        style={frompid === "pid" ? {marginLeft: "-3vw"}:{ paddingRight: "10px" }}
      />
      <span
        className="upload_text"
        style={frompid === "pid" ?{marginLeft: "1vw",fontSize: "1vw"}: { color: "#0C629B", textDecoration: "none", fontSize: "1vw" }}
      >
        {frompid === "pid"?"Export Report":"Download Report"}
      </span>

      <Modal
        className="user-select-popup"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="fullmodal"
        size="l"
        centered
      >
        <Modal.Body>
          <label
            style={{
              font: "normal normal bold 1vw Helvetica",
              textAlign: "left",
            }}
          >
            {" "}
            Please process at least one document to download the report
            <IconButton
              onClick={handleClose}
              style={{
                padding: "2.5px",
                position: "absolute",
                top: "0",
                right: "0",
                background: "white",
                borderRadius: "0",
                color: "#0C629B",
              }}
            >
              {" "}
              <CloseIcon style={{ width: "1.5vw", height: "1.5vw" }} />{" "}
            </IconButton>
          </label>
        </Modal.Body>
      </Modal>
    </div>
    </Tooltip>
  );
};

export default ExportExcel;
