export function GetInitJSON(data) {
  return { type: "GET_JSON_DATA", data };
}

export function FilterLineGroup(symbolData,symbolsData={}) {
  const data = [symbolData,symbolsData]
  return { type: "FILTER_LINE_GROUP", data };
}

export function AddNewAsset(data) {
  return { type: "ADD_NEW_ASSET", data };
}

export function EditAsset(newValue, oldValue) {
  let data = [oldValue, newValue];
  return { type: "EDIT_ASSET", data };
}

export function EditAssetLineGroup(oldValue,row) {
  let data = [oldValue,row];
  return{ type: "EDIT_LINE_GROUP",data}
}

export function newLineGroup(data) {
  return { type: "NEW_LINE_GROUP", data };
}

export function newLineNumber(data) {
  return { type: "NEW_LINE_NUMBER", data };
}

export function Transform(data) {
  return { type: "TRANSFORM", data };
}

export function editDataWindow1(data) {
  return{type:"EDIT_DATA",data};
}

export function editSymbolData(data) {
  return{type:"EDIT_DATA",data};
}

export function editLineData(data) {
  return{type:"EDIT_LINE_DATA",data};
}

export function deleteSymbol(data) {
  return{type:"DELETE_SYMBOL",data};
}

export function deleteLine(data) {
  return{type:"DELETE_LINE",data};
}

export function selectAsset(data) {
  return{type:"SELECT_ASSET",data};
}

export function symbolsDetails(data) {
  return{type:"SYMBOLS_DETAILS",data};
}

export function symbolsRanking(data) {
  return{type:"SYMBOLS_RANKING",data};
}