import React, {useState, useEffect}  from 'react';
import { useLocation } from "react-router-dom";
import { browserRouter as Router, useHistory} from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "react-bootstrap/Modal";
import axios from 'axios';
import AppHeader from '../../AppHeader/AppHeader';
import AppFooter from '../../AppFooter/AppFooter';
import BreadCrumbs from '../../Upload/BreadCrumbs/BreadCrumbs';
//import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import { toJS } from "mobx";
import Pager from "../../UploadPID/Process/ProcessGrid/PagerPID/PagerPID";
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import UserStore from '../../UserStore';
import LoadingOverlay from "react-loading-overlay";
import TextFieldMui from "@material-ui/core/TextField";
import { withStyles} from "@material-ui/core/styles";
import { toast } from "react-toastify";
import Tooltip from "@material-ui/core/Tooltip";
import ConfirmationDialog from '../../ConfirmationDialog/ConfirmationDialog';
import ShowUsers from '../ShowUsers';
import './workspaceDashboard.scss';
import "react-toastify/dist/ReactToastify.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import Moment from "moment";
toast.configure();
const { BlockBlobClient } = require("@azure/storage-blob");

const customContentStyle = {
  width: "70%",
  maxWidth: "none",
};

const styles = (muiTheme) => ({
  label: {
    "&$erroredLabel": {
      color: "black",
    },
  },
  erroredLabel: {},
});

const TextField = withStyles(styles)(function TextField({ classes, ...props }) {
  return (
    <TextFieldMui
      InputLabelProps={{
        classes: {
          root: classes.label,
          error: classes.erroredLabel,
        },
      }}
      {...props}
    />
  );
});

export default function WorkspaceDashboard() {
  const API_Endpoint = process.env.REACT_APP_API_ENDPOINT;
  const [loggedInuserEmail, setloggedInuserEmail] = useState(localStorage.getItem('userEmail') ? localStorage.getItem('userEmail') : UserStore.userEmail);
  const { ExportCSVButton } = CSVExport;
  const history = useHistory();
  const location = useLocation();
  var reportData = [];
  const row = [];
  const [pageSize,setPageSize] = useState(toJS(UserStore.perwsdPageData) === '' ? UserStore.setPerwsdPageData("10") : localStorage.getItem("perwsdPageData"));
  const [rows,setrows] = useState([]);
  const [globalAdmin,setglobalAdmin] = useState(false);
  const [isdelete,setdelete] = useState(false);
  const [deleteworkspaceid,setdeleteworkspaceid] = useState("");
  const [loading, setloading] = useState(true);
  const [showUsers, setshowUsers] = useState(false);
  const [setShow, setSetShow] = useState(false);
  //const [firstLoad, setfirstLoad] = useState(false);
  const [filtereduserData, setfiltereduserData] = useState("");
  const [pageChange, setPageChange] = useState(false);
  const [currentPage, setCurrentPage] = useState(
    Number(toJS(UserStore.skipwsdData)) / Number(toJS(UserStore.perwsdPageData)) + 1
  );
  const [skip, setSkip] = useState(
    new URLSearchParams(location.search).get("skip") ?? toJS(UserStore.skipwsdData)
  );
  const [top, topValueUpdate] = useState(
    new URLSearchParams(location.search).get("top") ??
    toJS(UserStore.perwsdPageData)
  );
  let resultsPerPage = top;
  UserStore.setSkipData(0);
  
  let storeData = toJS(UserStore.PIDwsData);
  let columns = [
    // { dataField: 'projectWorkSpaceId', text: "Workspace ID", sort: true},
    { dataField: 'projNo', text: 'Proposal/Project No.', width: "200"},
    { dataField: 'projName',width: "200", text: 'Project Name',csvFormatter: (cell, row, rowIndex) => `${cell.props.children.props.children.props.children}`},
    { dataField: 'clientName', text: 'Client Name' ,width: "200"},
    { dataField: 'location', text: 'Location', width: "200" },
    { dataField: 'PlantDesc', text: 'Plant Description', width: "200" },
    { dataField: 'mainOffice', text: 'Main Office', width: "200" },
    { dataField: 'satteliteOffice',  text: 'Satellite Office', width: "200" },
    { dataField: 'startDate', width: "100",  text: 'Start Date',csvFormatter: (cell, row, rowIndex) => ` ${cell}`  },
    { dataField: 'finishDate',width: "100",   text: 'Finish Date',csvFormatter: (cell, row, rowIndex) => ` ${cell}`  },
    { dataField: 'users', width: "100",  text: 'Users',csvFormatter: (cell, row, rowIndex) => ` ${cell.props.children.props.children.props.children}`  },
    { dataField: 'moreActions', width: "200",  text: 'More Actions',csvExport: false},
  ];
  UserStore.setPIDFilterRetain(false);

  async function checkGlobalAdmin() {
    const res = await axios.get(API_Endpoint + `/IsUserPidGlobalAdmin`);
    setglobalAdmin(res.data);
    UserStore.changePIDGlobalAdmin(res.data);
    GetPidWorkSpaces(res.data);
    // setglobalAdmin(true);
  }
  const MyExportCSV = (props) => {
    const handleClick = () => {
      if (reportData.length === 0) setSetShow(true);
      else{
        props.onExport();
      }
    };
    return (
      
         <div className="exportReport">
                  <img src="/images/Download.svg" class="toolbar-icon" />
        <Tooltip
          title="Click to download workspace report"
          arrow
          placement="left-start"
        >
          <span onClick={handleClick} className="preview-text">
            Export Report
          </span>
        </Tooltip>
      </div>
    );
  };

  const handleClicktoshowmodel = () => {
    setSetShow(true);
  };

  const handleDownloadClose = () => {
    setSetShow(false);
  };


  async function GetPidWorkSpaces() {
    const globalAdminResult = UserStore.PIDGlobalAdmin;
    try {
      axios.get(API_Endpoint + `/GetPidWorkSpaces`)
       .then(res => {
         if(res.data || res.status != 200) {
           setloading(false);
         }
        UserStore.setPIDworkspaceData(res.data);
        setloggedInuserEmail(localStorage.getItem('userEmail') ? localStorage.getItem('userEmail') : UserStore.userEmail);
         if(res.data) {
           const rows1 = res.data.reverse();
         {
           rows1.map((items, index) => {            
             const findRole = items.workSpaceUsers.find(x => x.enterpriseId.toLowerCase() == localStorage.getItem('userEmail'));
             let roleIdentifier = 0;
            
            if(findRole){
              roleIdentifier = findRole.roleId;
            }
            const row2 = {
                 projectWorkSpaceId: items.projectWorkSpaceId,
                 projNameforedit: items.projectName,
                 projNo: items.projectNumber,
                 projName:  
                 <Tooltip title="Click to view the processed files" arrow>
                   <div className="projLink" onClick={() => {workSpaceClick(items, 1)}}>
                     <span>{items.projectName}</span>
                   </div>
                 </Tooltip>,
                 clientName: items.clientName,
                 location: items.location,
                 PlantDesc: items.plantDescription,
                 mainOffice:items.mainOffice,
                 satteliteOffice:items.satelliteOffice,
                 startDate: Moment(items.startDate).format("DD/MMM/YYYY"),
                 finishDate: Moment(items.finishDate).format("DD/MMM/YYYY"),
                 users: 
                 <div className="usersList">
                  <Tooltip title="Click to view the users list" arrow>
                    <span id={items.projectWorkSpaceId} name="userinfo" onClick={showUsername}>
                      {items.workSpaceUsers.length}
                    </span>
                  </Tooltip>
                 </div>,
                 moreActions:  
                 <div classname="more-actions-wrapperPID" style={{ display: "inline-flex" }}>
                   <Tooltip title="Upload Files" arrow>
                        <div className="upload-files" style={globalAdminResult ? {cursor: "pointer"} : roleIdentifier === 1 || roleIdentifier === 2 ? {marginLeft: "-20%", cursor: "pointer"} : {marginLeft: "-20%", opacity: "0.5", cursor: "not-allowed", pointerEvents: "none"}}>
                          <div className="upload-icon" onClick={() => {workSpaceClick(items, 0)}}
                            style={{ marginTop: "-5%", marginLeft: "5%" }} >
                            <img src="/images/icon_upload.svg" style={{ height: "1.7vw" }} />
                          </div>
                      </div>
                    </Tooltip>
                   <Tooltip title="Edit Workspace" arrow>
                     <div className="edit-icon-PID" onClick={() => {editClick(items)}} style={globalAdminResult ? {marginLeft: "10%", cursor: "pointer"} : roleIdentifier === 1 ? {marginLeft: "10%", cursor: "pointer"} : {marginLeft: "10%", opacity: "0.5", cursor: "not-allowed", pointerEvents: "none"}} >
                       <img id={items.projectWorkSpaceId} src="/images/icon_edit.svg" style={{height: '1.5vw'}} />
                     </div>
                   </Tooltip>
                   <Tooltip title="Delete Workspace" arrow>
                     <div classname="delete-icon-PID" id={items.projectWorkSpaceId} style={globalAdminResult ? { marginLeft: "10%", cursor: "pointer" } : { marginLeft: "10%", cursor: "not-allowed", opacity: "0.5",pointerEvents: "none" }}>
                       <img id={items.projectWorkSpaceId} style={{height: "1.5vw"}} src="/images/icon_delete.svg" onClick={(e) => {handleDelete(e)}} />
                     </div>
                   </Tooltip>
                   <Tooltip title="Setting Configuration" arrow>
                     <div id={items.projectNumber} onClick={() => {handleSetting(items)}} classname="delete-icon-PID" style={globalAdminResult ? { marginLeft: "10%", cursor: "pointer" } : { marginLeft: "10%", cursor: "not-allowed", opacity: "0.5",pointerEvents: "none" }}>
                      <svg id={items.projectNumber}  style={{color: '#0c629b', width: '1.8vw', height: '1.8vw'}} class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-lf3s84" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="SettingsSuggestIcon"><path id={items.projectWorkSpaceId} d="M17.41 6.59 15 5.5l2.41-1.09L18.5 2l1.09 2.41L22 5.5l-2.41 1.09L18.5 9l-1.09-2.41zm3.87 6.13L20.5 11l-.78 1.72-1.72.78 1.72.78.78 1.72.78-1.72L23 13.5l-1.72-.78zm-5.04 1.65 1.94 1.47-2.5 4.33-2.24-.94c-.2.13-.42.26-.64.37l-.3 2.4h-5l-.3-2.41c-.22-.11-.43-.23-.64-.37l-2.24.94-2.5-4.33 1.94-1.47c-.01-.11-.01-.24-.01-.36s0-.25.01-.37l-1.94-1.47 2.5-4.33 2.24.94c.2-.13.42-.26.64-.37L7.5 6h5l.3 2.41c.22.11.43.23.64.37l2.24-.94 2.5 4.33-1.94 1.47c.01.12.01.24.01.37s0 .24-.01.36zM13 14c0-1.66-1.34-3-3-3s-3 1.34-3 3 1.34 3 3 3 3-1.34 3-3z"></path></svg>
                     </div>
                   </Tooltip>
                 </div>
             }
             row.push(row2);
           })
         }
         }
         UserStore.setPIDwsData(row)
         setrows(row);
         reportData.push(row);
         const countOfData = Number(skip) + Number(top);
         const slicedData = row.slice(skip, countOfData);
         setrows(slicedData);
       })
   }
   catch (error) {
     if (error.response) {
     } else if (error.request) {
       
     } else {
       
     }
   }   
  }
  
  useEffect( () => {    
    let useremail = localStorage.getItem('userEmail');
    let perwsdPageData = localStorage.getItem("perwsdPageData");
    // console.log(localStorage.getItem(perwsdPageData));
    localStorage.clear();
    localStorage.setItem('userEmail', useremail);
    localStorage.setItem("perwsdPageData",perwsdPageData);
    localStorage.setItem("perPageData","10");
    UserStore.SetPerPageData("10");
    UserStore.setUserforWorkspacePID([]);
    UserStore.setStampFilterRetain(false);
    UserStore.isreadOnlyPID(false);
    checkGlobalAdmin();
    UserStore.PIDToDate = "";
    UserStore.afterSearch = "";
    UserStore.selectedRowID=[];
  }, []);

  useEffect(() => {
    const countOfData = Number(skip) + Number(top);
    const slicedData = storeData.slice(skip, countOfData);
    setrows(slicedData);
  },[skip, currentPage, pageChange])

  // if (!firstLoad) {
  //   setCurrentPage(1);
  //   setSkip((1 - 1) * top);
  //   UserStore.setSkipwsdData((1 - 1) * top);
  //   setfirstLoad(true);
  // }

  function workSpaceClick(items, tab) {
    UserStore.addRowdataPID(items); 
    let findRole = 1;
    if(localStorage.getItem('userEmail') != "") {
      const findRoleValue = items.workSpaceUsers.find((x) => x.enterpriseId.toLowerCase() === localStorage.getItem('userEmail'));
      if(findRoleValue){
        findRole = !UserStore.PIDGlobalAdmin ? findRoleValue.roleId : 1
      }
      // findRole = !globalAdmin ? (items.workSpaceUsers.find((x) => x.enterpriseId.toLowerCase() === localStorage.getItem('userEmail')).roleId) : 1;
    }
    if (findRole === 3) {
      UserStore.isreadOnlyPID(true);
      localStorage.setItem('isreadOnlyPID', true);
    } else {
      UserStore.isreadOnlyPID(false);
      localStorage.setItem('isreadOnlyPID', false);
    }
    UserStore.setPIDWorkspaceId(items.projectWorkSpaceId);
    UserStore.getprocessGridPID(tab);
    localStorage.setItem('getprocessGridPID', tab);
    UserStore.getworkspaceNamePID(items.projectName);
    UserStore.emptySuccededFilesPID();
    history.push(`/WorkspacePID/Upload`);
    setWorkspace(items);
    localStorage.setItem("startdate", items.startDate);
  }

  function setWorkspace(items) {
    localStorage.setItem('WorkspaceIdPID', items.projectWorkSpaceId);
    localStorage.setItem('workspaceNamePID', items.projectName);
  }

  const customPagination = (e, dat) => {
    setCurrentPage(1);
    setSkip((1 - 1) * top);
    UserStore.setSkipwsdData((1 - 1) * top);
    topValueUpdate(e.target.selectedOptions[0].value);

    if (e.target.selectedOptions[0].value === "30") {
      const slicedData2 = dat.slice(skip, 3 * 10);
      setrows(slicedData2);
    } else if (e.target.selectedOptions[0].value === "50") {
      const slicedData3 = dat.slice(skip, 5 * 10);
      setrows(slicedData3);
    } else if (e.target.selectedOptions[0].value === "100") {
      const slicedData4 = dat.slice(skip, 10 * 10);
      setrows(slicedData4);
    } else {
      const slicedData1 = dat.slice(skip, 1 * 10);
      setrows(slicedData1);
    }
    UserStore.setPerwsdPageData(e.target.selectedOptions[0].value);
    localStorage.setItem("perwsdPageData",e.target.selectedOptions[0].value)
  };

  function editClick(items) {
    UserStore.addRowdataPID(items); 
    setWorkspace(items);
    UserStore.setPIDWorkspaceId(items.projectWorkSpaceId);
    UserStore.getworkspaceNamePID(items.projectName);
    history.push(`/WorkspacePID/EditWorkspace`);
    localStorage.setItem('EditWSDataPID', JSON.stringify(items));
  }

  // function handleOnSelect (row, isSelect) {
  //   if(isSelect) {
  //     const selectedRow = rows.filter((r) => r.projectWorkSpaceId === row.projectWorkSpaceId);
  //     reportData.push(selectedRow);
  //   }
  // }

  function handleOnSelect(row, isSelect) {
    if (isSelect) {
      const selectedRow = rows.filter(
        (r) => r.projectWorkSpaceId === row.projectWorkSpaceId
      );
      reportData.push(row);
      //reportData = selectedRow;
      //rows = selectRow
      //setrows(selectedRow);
    }
    else{
      
      let newRows = reportData.filter(
        (r) =>   row.projectWorkSpaceId !==  r.projectWorkSpaceId 
      );
      reportData = newRows;
    }
  }

  const handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map(r => r.id);
    if (isSelect) {     
     reportData = rows;
    } else {
     reportData = [];
    }
  }
    
  function navigateToCreateNewWorkspace() {
      history.push('/WorkspacePID/CreateNewWorkspace');
  }

  function handleDelete(e) {
    setdeleteworkspaceid(e.target.id);
    setdelete(true);
  };

 const handleSetting = (items) => {
  UserStore.getworkspaceNamePID(items.projectName);
    UserStore.setPIDWorkspaceId(items.projectWorkSpaceId);
    UserStore.setProprsal(items.projectNumber)    
    history.push(`/WorkspacePID/EditConfiguration`);
  };

  function handleCloseDialog() {
    setdelete(false);
  };

  function handleClose() {
    setSetShow(false);
    setshowUsers(false);
  };

  function handleShow() {
    setSetShow(true)
  };

  function handlerole(role) {
    switch (role) {
      case 1:
        return "Admin";
      case 2:
        return "Read / Write";
      case 3:
        return "Read Only";
    }
  };

  function showUsername(e) {
    let id = e.target.id;    
    setfiltereduserData(UserStore.PIDworkspaceData
      .filter((item) => item.projectWorkSpaceId == id)
      .map(({ workSpaceUsers }) => ({ workSpaceUsers })));
    setshowUsers(true);
  };

  function handlePurge(e) {
      let id = deleteworkspaceid;
      const updatedRows = rows.filter((row) => row.projectWorkSpaceId != id);
        
      const data = {
        "projectWorkSpaceId": id
      }

      axios.post(API_Endpoint + `/DeletePidWorkSpace`, data);
      setrows(updatedRows);
      setdelete(false);
  };

  const selectRow = {
    mode: 'checkbox',
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll,
    onSelect: handleOnSelect
  };

//Used to update the page number after changing the pagination
  const updatePagination = (newPageNumber) => {
    if (newPageNumber > 1) setPageChange(true);
    setSkip((newPageNumber - 1) * top);
    UserStore.setSkipwsdData((newPageNumber - 1) * top);
    setCurrentPage(newPageNumber);
  };
  
    return (
        <div className="workspace-container-PID">
        <AppHeader />
        <BreadCrumbs />
        <LoadingOverlay active={loading} spinner text="Loading your content..." ></LoadingOverlay>
        <div className="workspace-wrapper-PID">
          <div className="worspace-titlebar-PID">
            <div className="workspace-title-PID">{`PROJECT WORKSPACE (${UserStore.PIDworkspaceData.length})`}</div>
            <div className="create-new-workspace-wrapper-PID">
              {UserStore.PIDGlobalAdmin && <div className="create-new-workspace-btn-PID" style={{ fontSize: "1vw", width: "15vw" }} onClick={navigateToCreateNewWorkspace}>
                <img className="icon-add-PID" style={{ marginRight: "3%", height: "1.7vw" }} src="/images/icon_add.svg" />
                Create New Workspace
              </div>}
            </div>
          </div>
          <div className="workspace-data-table-wrapper-PID">
          <ToolkitProvider
            keyField="projectWorkSpaceId"
            data={rows}
            columns={columns}
            exportCSV={{
              fileName: "Project Workspace.csv",
              onlyExportSelection: true,
              exportAll: false,
            }}
          >
            {(props) => (
              <div>
                {/* <div className="exportReport">
                  <img src="/images/Download.svg" class="toolbar-icon" /> */}
                  <MyExportCSV {...props.csvProps}>
                    <Tooltip
                      title="Click to download workspace report"
                      arrow
                      placement="left-start"
                    >
                      <span className="preview-text">Export Report</span>
                    </Tooltip>
                  </MyExportCSV>
                  {/* <ExportCSVButton className="preview-text" { ...props.csvProps }>
                            <Tooltip title="Click to download workspace report" arrow placement="left-start">
                             <span className='preview-text'>Export Report</span> 
                              </Tooltip>
                              </ExportCSVButton> */}
                {/* </div> */}
                <BootstrapTable
                  {...props.baseProps}
                  //keyField="id"
                  selectRow={selectRow}
                  sort={{ dataField: "projectWorkSpaceId", order: "desc" }}
                />
              </div>
                )}
            </ToolkitProvider>
            <div className="pagerFooter">
          <div className="pagnaitioncont" style={{display:"contents"}}>
            <select
            style={{marginLeft:"3vw"}}
              name="rows-perpage"
              id="rows-perpage"
              onChange={(e) => customPagination(e,storeData)}
              defaultValue = {toJS(UserStore.perwsdPageData)}
              defaultChecked = {toJS(UserStore.perwsdPageData)}
            >
              <option
                id="ten-rows"
                value="10"
                // selected={localStorage.getItem("perwsdPageData") === "10" ? true : false}
              >
                10
              </option>

              <option
                id="thirty-rows"
                value="30"
                // selected={localStorage.getItem("perwsdPageData") === "30" ? true : false}
              >
                30
              </option>

              <option
                id="fifty-rows"
                value="50"
                // selected={localStorage.getItem("perwsdPageData") === "50" ? true : false}
              >
                50
              </option>

              <option
                id="hundred-rows"
                value="100"
                // selected={localStorage.getItem("perwsdPageData") === "100" ? true : false}
              >
                100
              </option>
            </select>
            <Pager
              className="pager-style-pid"
              currentPage={currentPage}
              resultCount={storeData.length}
              resultsPerPage={resultsPerPage}
              setCurrentPageValue={updatePagination}
            ></Pager>
          </div>
        </div>
          </div>
          <Modal
        className="user-select-popup"
        show={setShow}
        onHide={handleDownloadClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="fullmodal"
        size="l"
        centered
      >
        <Modal.Body>
          <label
            style={{
              font: "normal normal bold 1vw Helvetica",
              textAlign: "left",
            }}
          >
            Please select atleast one row to Export report.
            <IconButton
              onClick={handleDownloadClose}
              style={{
                padding: "3px",
                position: "absolute",
                top: "0",
                right: "0",
                background: "",
                borderRadius: "0",
                color: "#0C629B",
              }}
            >
              {" "}
              <CloseIcon style={{ width: "1.5vw", height: "1.5vw" }} />{" "}
            </IconButton>
          </label>
        </Modal.Body>
      </Modal>
          <ConfirmationDialog delete={isdelete} handleCloseDialog={handleCloseDialog} handlePurge={handlePurge} />
          {showUsers && <ShowUsers showUsers={showUsers} handleClose={handleClose} filtereduserData={filtereduserData} handlerole={handlerole} />}
        </div>
        <AppFooter />
      </div>
    )
}