import React, { useState, useEffect, useReducer } from "react";
import { Router, useHistory, withRouter } from "react-router-dom";
import axios from "axios";
import { TextField, MuiThemeProvider, Button } from "@material-ui/core";
import AppHeader from "../../AppHeader/AppHeader";
import AppFooter from "../../AppFooter/AppFooter";
import BreadCrumbs from "../../Upload/BreadCrumbs/BreadCrumbs";
import AddUser from "../AddUser/AddUser";
import DeleteUser from "../AddUser/DeleteUser";
import ModalValidation from "../createNewWorkspace/ModalValidation";
import UserStore from "../../UserStore";
import { toJS, observer } from "mobx";
import Moment from "moment";
import "./EditWorkspace.scss";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure();

export default function EditWorkSpace() {
  const API_Endpoint = process.env.REACT_APP_API_ENDPOINT;
  var result;
  var wrapperRef = React.createRef();
  var addUserRef = React.createRef();

  const editWorkSpaceDataPID = toJS(UserStore.editRowPID).length
    ? toJS(UserStore.editRowPID)[0]
    : localStorage.getItem("EditWSDataPID")
    ? JSON.parse(localStorage.getItem("EditWSDataPID"))
    : [];
  if (
    !toJS(UserStore.editRowPID).length &&
    (localStorage.getItem("EditWSDataPID") !==null || localStorage.getItem("EditWSDataPID") !== undefined || localStorage.getItem("EditWSDataPID").length !== 0)
  ) {
   UserStore.addRowdataPID(JSON.parse(localStorage.getItem("EditWSDataPID")));
  }

  const [editRow, setEditrow] = useState(toJS(UserStore.editRowPID)[0]);
  const [editRowNew, setEditRowNew] = useState({
    projectNumber: "",
    projectName: "",
    clientName: "",
    location: "",
    plantDescription: "",
    mainOffice: "",
    satelliteOffice: "",
    startDate: "",
    finishDate: "",
    workSpaceUsers: [],
  });
  const history = useHistory();
  const [rolearray, setroleArray] = useState([]);
  const [personName, setPersonName] = useState([]);
  const [userEmpty, setUserEmpty] = useState(false);
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const [validate, setValidate] = useState(false);
  const [dropdownvalue, setdropdownvalue] = useState("");
  const [useradd, setuseradd] = useState([]);
  const [errorProjectNumber, setErrorProjectNumber] = useState(false);
  const [errorProjectName, setErrorProjectName] = useState(false);
  const [errorClientName, setErrorClientName] = useState(false);
  const [errorLocation, setErrorLocation] = useState(false);
  const [errorDescription, setErrorDescription] = useState(false);
  const [errorMainOffice, setErrorMainOffice] = useState(false);
  const [errorSatelliteOffcie, setErrorSatelliteOffice] = useState(false);
  const [errorFromDate, setErrorFromDate] = useState(false);
  const [errorToDate, setErrorToDate] = useState(false);
  const [errorUsers, setErrorUsers] = useState(false);
  const [errorMsgProjectNumber, setErrorMsgProjectNumber] = useState("");
  const [errorMsgProjectName, setErrorMsgProjectName] = useState("");
  const [errorMsgClientName, setErrorMsgClientName] = useState("");
  const [errorMsgLocation, setErrorMsgLocation] = useState("");
  const [errorMsgDescription, setErrorMsgDescription] = useState("");
  const [errorMsgMainOffice, setErrorMsgMainOffice] = useState("");
  const [errorMsgSatelliteOffcie, setErrorMsgSatelliteOffice] = useState("");
  const [errorMsgFromDate, setErrorMsgFromDate] = useState("");
  const [errorMsgToDate, setErrorMsgToDate] = useState("");
  const [errorMsgUsers, setErrorMsgUsers] = useState("");
  const [showSuggestions, setshowSuggestions] = useState(false);
  const [q, setq] = useState("");
  const [Delete, setdelete] = useState(false);
  const [deleteUsers, setdeleteUsers] = useState("");
  const [tooltipError, settooltipError] = useState(false);
  const [tootlTipErrorMessage, settootlTipErrorMessage] = useState("");
  const [suggestions, setsuggestions] = useState([]);
  const [modifysuggestion, setmodifysuggestion] = useState([]);
  const [roles, setroles] = useState([]);
  const [role, setrole] = useState("Admin");
  const [list, setlist] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    fetchUserTypes();
    setEditrow(toJS(UserStore.editRowPID)[0]);
    document.addEventListener("mousedown", suggestionOutClickHandler);
    UserStore.setEditworkspacePID(true);
    localStorage.setItem("EditworkspacePID", true);
    let rolesedit = [];
    rolesedit = toJS(editRow.workSpaceUsers);
    for (var i = 0; i < rolesedit.length; i++) {
      if (rolesedit[i].roleId === 1) {
        rolesedit[i].roleId = "Admin";
      } else if (rolesedit[i].roleId === 2) {
        rolesedit[i].roleId = "Read / Write";
      } else {
        rolesedit[i].roleId = "Read Only";
      }
    }
    setuseradd(rolesedit);

    return () => {
      document.removeEventListener("mousedown", suggestionOutClickHandler);
    };
  }, []);

  async function fetchUserTypes() {
    const responsecheck = await axios.get(API_Endpoint + `/GetPidWorkSpaces`);
    const wID = editRow.workSpaceId;
    result = responsecheck.data.filter(function (v, i) {
      return v.workSpaceId !== wID;
    });

    const dropDownValues = await axios
      .get(`${API_Endpoint}/GetApplicationRoles`)
      .then((res) => {
        if (res.status === 200) {
          //   roles = res.data;
          setroles(res.data);
        } else if (
          res.status === 400 ||
          res.status === 401 ||
          res.status === 404 ||
          res.status === 408
        ) {
          toast.error("service error", {
            position: toast.POSITION.TOP_LEFT,
            autoClose: 5000,
          });
        }
      });
  }

  function handleusers(e) {
    setlist(list.push(e.target.value));
  }

  function createUseroptions(e) {
    return roles.map((option) => (
      <option key={option.roleId} value={option.roleName}>
        {option.roleName}
      </option>
    ));
  }

  const handleCloseEmptyUser = () => {
    setUserEmpty(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let userArray = useradd;
    for (var i in userArray) {
      if (userArray[i].roleId == "Admin") {
        userArray[i].roleId = 1;
      } else if (userArray[i].roleId == "Read / Write") {
        userArray[i].roleId = 2;
      } else if (userArray[i].roleId == "Read Only") {
        userArray[i].roleId = 3;
      }
    }
    
    const data = {
      projectNumber: editRowNew.projectNumber
        ? editRowNew.projectNumber
        : editRow.projectNumber,
      projectName: editRowNew.projectName
        ? editRowNew.projectName
        : editRow.projectName,
      clientName: editRowNew.clientName
        ? editRowNew.clientName
        : editRow.clientName,
      location: editRowNew.location ? editRowNew.location : editRow.location,
      plantDescription: editRowNew.plantDescription
        ? editRowNew.plantDescription
        : editRow.plantDescription,
      mainOffice: editRowNew.mainOffice
        ? editRowNew.mainOffice
        : editRow.mainOffice,
      satelliteOffice: editRowNew.satelliteOffice
        ? editRowNew.satelliteOffice
        : editRow.satelliteOffice,
      startDate: editRowNew.startDate
        ? editRowNew.startDate
        : editRow.startDate,
      finishDate: editRowNew.finishDate
        ? editRowNew.finishDate
        : editRow.finishDate,
      projectWorkSpaceId: editRowNew.projectWorkSpaceId
        ? editRowNew.projectWorkSpaceId
        : editRow.projectWorkSpaceId,
      workSpaceUsers: userArray,
    };
    const FieldsName = ['Project Number', 'Project Name', 'Client Name', 'Location', 'Plant Description', 'Main Office', 'Satellite Office', 'From Date', 'To Date', 'Work Space Users']; 
    let keyCount = 0;
    const arrayOfKey = []; 
    for (var i in data) {      
      if(data[i] === ''){
        arrayOfKey.push(FieldsName[keyCount]);
      }
      keyCount++;      
    }
    var date1 = new Date(data.startDate);
    var date2 = new Date(data.finishDate);

    if(arrayOfKey.length > 0 ){
      setUserEmpty(true);
      setMessage(`Please fill the details in the ${arrayOfKey.join(", ")} field(s)`);
    }
    else if(data.startDate === 'Invalid date' ||  data.startDate === ''){
      setUserEmpty(true);
      setMessage("Please select From Date");
    }
    else if(data.finishDate === 'Invalid date' ||  data.finishDate === ''){
      setUserEmpty(true);
      setMessage("Please select To Date");
    }
    else if(date1 > date2) {
      setUserEmpty(true);
      setMessage("From Date cannot be beyond To Date");
    }
    else if (useradd.length !== 0) {
      await axios.post(API_Endpoint + "/EditPidWorkSpace", data);
      history.goBack();
    } 
  };

  function handleRoleChange(e) {
    if (e.target.value === "Admin") {
      setdropdownvalue(e.target.value);
      setrole("Admin");
    } else if (e.target.value === "Read / Write") {
      setdropdownvalue(e.target.value);
      setrole("Read / Write");
    } else if (e.target.value === "Read Only") {
      setdropdownvalue(e.target.value);
      setrole("Read Only");
    }
    rolearray.push(e.target.value);
  }

  function handleClose() {
    setOpen(false);
  }

  function addUser() {
    settooltipError(false);
    settootlTipErrorMessage("");
    if (personName.length === 0) {
      settooltipError(true);
      settootlTipErrorMessage("Kindly add a valid email address");
    } else {
      for (var i = 0; i < personName.length; i++) {
        const userdata = {
          enterpriseId: personName[i],
          roleId: role,
        };
        useradd.push(userdata);
        UserStore.editRowPID[0].workSpaceUsers.push(userdata);
        setPersonName([]);
        setsuggestions([]);
      }
      function getUnique(arr, index) {
        const unique = arr
          .map((e) => e[index])
          .map((e, i, final) => final.indexOf(e) === i && i)
          .filter((e) => arr[e])
          .map((e) => arr[e]);
        return unique;
      }
      useradd.reverse();
      setuseradd(getUnique(useradd, "enterpriseId"));
      document.getElementById("search-box1").value = "";
      setShow(true);
      setq("");
    }
  }

  async function suggestionsCall(q) {
        const res = await axios.get(
      `${process.env.REACT_APP_API_GETUSERLIST}/GetUsersList?username=${q}`
    );
    setsuggestions(res.data);
    setmodifysuggestion(res.data);
    filterSearchText(res.data, q);
  }

  function onSearchHandler() {
    setshowSuggestions(false);
  }

  function onChangeHandler1(e) {
    let searchTerm = "";
    searchTerm = e.target.value.toLowerCase();
    setq(searchTerm);
    if (searchTerm.length === 3) {
      suggestionsCall(searchTerm);
      setshowSuggestions(true);
    }
    if (searchTerm.length < 3) {
      setsuggestions([]);
      setshowSuggestions(false);
    }
    if (searchTerm.length > 3) {
      filterSearchText(suggestions, searchTerm);
    }
  }

  function handlePurge(e, user) {
    const updatedRows = useradd.filter(
      (row) => row.enterpriseId !== user.enterpriseId
    );
    setuseradd(updatedRows);
    setdelete(false);
  }

  function handleCloseDialog() {
    setdelete(false);
  }

  function handleDelete(e, user) {
    setdelete(true);
    setdeleteUsers(user);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    if(name === 'startDate' || name === 'finishDate') {
      setEditrow((prevState) => {
        return {
          ...prevState,
          [name]: Moment(value).format("MM/DD/YYYY"),
        };
      });
    } else {
      setEditrow((prevState) => {
        return {
          ...prevState,
          [name]: value,
        };
      });
    }
  };

  function pasteFunction(e) {
    let searchTerm = "";
    searchTerm = e.clipboardData.getData("text").toLowerCase();
    setq(searchTerm);
    setPersonName([]);
    let substring = searchTerm.substring(0, 3);
    suggestionsCall(searchTerm);
    setshowSuggestions(true);
    // filterSearchText();
  }


  function filterSearchText(suggestionData, q) {
    let searchTerm = "";
    searchTerm = q.toLowerCase();
    for (var i = 0; i < useradd.length; i++) {
      if (useradd[i].enterpriseId === searchTerm) {
        settooltipError(true);
        settootlTipErrorMessage("Override the user role");
      }
    }
    const updatedRows = suggestionData.filter(
      (row) =>
        row.mail.toLowerCase().includes(searchTerm) ||
        row.displayName.toLowerCase().includes(searchTerm)
    );
      

    setsuggestions(updatedRows);
    if (updatedRows.length == 1 && searchTerm.includes("@petrofac.com")) {
      if (Object.values(updatedRows[0]).includes(searchTerm)) {
        document.getElementById("search-box1").focus();
        personName[0] = searchTerm;
        return true;
      }
    }
  }

  function onEnterButton(event) {
    if (event.keyCode === 13) {
      onSearchHandler();
    }
  }

  function handleClose() {
    setShow(false);
  }

  function suggestionClickHandler(s) {
    document.getElementById("search-box1").value = s;
    for (var i = 0; i < useradd.length; i++) {
      if (useradd[i].enterpriseId === s) {
        settooltipError(true);
        settootlTipErrorMessage("Override the user role");
      }
    }
    personName[0] = s;
    setshowSuggestions(false);
  }

  function suggestionOutClickHandler(event) {
    if (wrapperRef.current) {
      if (!wrapperRef.current.contains(event.target)) {
        setshowSuggestions(false);
      }
    }
  }

  function handlePurge(e, user) {
    const updatedRows = useradd.filter(
      (row) => row.enterpriseId !== user.enterpriseId
    );
    setuseradd(updatedRows);
    setdelete(false);
  }

  return (
    <div className="editWorkspace-container">
      <AppHeader />
      <BreadCrumbs />
      <MuiThemeProvider>
        <div className="editWorkspace-form-wrapper">
          <div className="editWorkspace-formblank">
            <p class="create-text">Edit Project Workspace</p>
            <img src="/images/EditWK.svg" class="icon-workspace" />
          </div>
          <form className="editWorkspaceform-wrapper" onSubmit={handleSubmit}>
            <div className="editWorkspace-form">
              <TextField
                autoFocus
                margin="dense"
                name="projectNumber"
                defaultValue={editRow.projectNumber}
                id="projectNumber"
                label="Proposal/Project No."
                type="text"
                onChange={handleChange}
                error={errorProjectNumber}
                helperText={errorMsgProjectNumber}
              />
              <TextField
                margin="dense"
                name="projectName"
                defaultValue={editRow.projectName}
                id="projectName"
                label="Project Name"
                type="text"
                onChange={handleChange}
                error={errorProjectName}
                helperText={errorMsgProjectName}
              />
              <TextField
                margin="dense"
                name="clientName"
                defaultValue={editRow.clientName}
                id="clientName"
                label="Client name"
                type="text"
                onChange={handleChange}
                error={errorClientName}
                helperText={errorMsgClientName}
              />
              <TextField
                margin="dense"
                name="location"
                defaultValue={editRow.location}
                id="location"
                label="Location"
                type="text"
                onChange={handleChange}
                error={errorLocation}
                helperText={errorMsgLocation}
              />
              <TextField
                margin="dense"
                name="plantDescription"
                defaultValue={editRow.plantDescription}
                id="plantDescription"
                label="Plant Description"
                type="text"
                onChange={handleChange}
                error={errorDescription}
                helperText={errorMsgDescription}
              />
              <TextField
                margin="dense"
                name="mainOffice"
                defaultValue={editRow.mainOffice}
                id="mainOffice"
                label="Main Office"
                type="text"
                onChange={handleChange}
                error={errorMainOffice}
                helperText={errorMsgMainOffice}
              />
              <TextField
                margin="dense"
                name="satelliteOffice"
                defaultValue={editRow.satelliteOffice}
                id="satelliteOffice"
                label="Satellite Office"
                type="text"
                onChange={handleChange}
                error={errorSatelliteOffcie}
                helperText={errorMsgSatelliteOffcie}
              />
              <TextField
                margin="dense"
                name="startDate"
                defaultValue={Moment(editRow.startDate).format("YYYY-MM-DD")}
                className="date"
                id="fromDate"
                label="From"
                type="date"
                InputLabelProps={{ shrink: true }}
                onChange={handleChange}
                error={errorFromDate}
                helperText={errorMsgFromDate}
              />
              <TextField
                margin="dense"
                name="finishDate"
                defaultValue={Moment(editRow.finishDate).format("YYYY-MM-DD")}
                className="date"
                id="toDate"
                label="To"
                type="date"
                InputLabelProps={{ shrink: true }}
                onChange={handleChange}
                error={errorToDate}
                helperText={errorMsgToDate}
              />
              <div className="view-users" onClick={() => setShow(true)}>
                <span>Edit User *</span>
              </div>
              <div className="btn-fields" Align="center">
                <Button type="submit" variant="contained" class="save-btn"
                   style = {{
                    font: "normal normal 1vw Helvetica",
                    background: "#0c629b",
                    border: "1px solid #0c629b",
                    borderRadius: "4px",
                    color: "#ffffff",
                    width: "8vw",
                    height: "4.8vh",
                    marginRight: "2vw",
                    textTransform:"none",
                  }}
                >
                  Save
                </Button>
                <Button
                  variant="contained"
                  class="cancel-btn"
                  onClick={() => history.goBack(-1)}
                  style = {{
                    font: "normal normal 1vw Helvetica",
                    background: "#ffffff",
                    border: "1px solid #0c629b",
                    borderRadius: "4px",
                    color: "#0c629b",
                    width: "8vw",
                    height: "4.8vh",
                    textTransform:"none",
                    // marginRight: "2vw",
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </form>
        </div>
        <ModalValidation
          handleCloseEmptyUser={handleCloseEmptyUser}
          message={message}
          userEmpty={userEmpty}
        />
        <AddUser
          show={show}
          useradd={useradd}
          suggestions={suggestions}
          suggestionClickHandler={suggestionClickHandler}
          handleRoleChange={handleRoleChange}
          dropdownvalue={dropdownvalue}
          AddUser={addUser}
          showSuggestions={showSuggestions}
          handleClose={handleClose}
          onEnterButton={onEnterButton}
          wrapperRef={wrapperRef}
          addUserRef={addUserRef}
          pasteFunction={pasteFunction}
          tooltipError={tooltipError}
          tootlTipErrorMessage={tootlTipErrorMessage}
          handleDelete={handleDelete}
          setshowSuggestions={setshowSuggestions}
          roles={roles}
          onChangeHandler1={onChangeHandler1}
        />
        <DeleteUser
          delete={Delete}
          handleCloseDialog={handleCloseDialog}
          handlePurge={handlePurge}
          deleteUsers={deleteUsers}
        />
      </MuiThemeProvider>
      <AppFooter />
    </div>
  );
}
