import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import {TextField } from "@material-ui/core";
import { Button } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import './AddSymbol.scss';
import { useDispatch, useSelector } from "react-redux";
import {
    editSymbolData,
    deleteSymbol,
    FilterLineGroup,
  } from "../../../redux/actions/documentActions";
import {
    deleteData,
    filterData,
} from "../../../redux/CanvasActions";
import UserStore from '../../../UserStore';
import axios from 'axios';
import { toast } from "react-toastify";
export default function AddSymbol(props) {
    const [ addnew, setAddnew ] = useState(false);
    const [ addnewCode, setAddnewCode ] = useState(false);
    const [ addFromline, setaddFromline ] = useState(false);
    const [ addToline, setaddToline ] = useState(false);
    const [ isJacketedLine, setisJacketedLine ] = useState(false);
    const [ isagug, setisagug ] = useState(true);
    const [ isholdLine, setisholdLine ] = useState(true);
    const shortCodeList = useSelector((state) => state.documentData.shortCodeList);
    const lineList = useSelector((state) => state.documentData.documentList);
    let properties =  props.assetProperties;
    let size1 = properties.Size1 === null || properties.Size1 === "" ? "" : (properties.Size1);
    const size2 = properties.Size2 === null || properties.Size2 === "" ? "" : (properties.Size2);
    const [lineNumber, setLineNumber] = useState("");
    const edit = props.assetProperties.edit;
    const dispatch = useDispatch();
    let lineGroups = props.lineGroups;
    let maxRank = props.maxRank;
    let lineGroupData = useSelector(state => state.documentData.lineGroupSpec);
        
    const JacketedLine = (e) => {
        if (e.target.value === 'yes') {
          setisJacketedLine(true);
        } else {
          setisJacketedLine(false);
        }
    }

    const AGUG = (e) => {
        if (e.target.value === 'yes') {
          setisagug(true);
        } else {
          setisagug(false);
        }
    }

    const holdLine = (e) => {
        if (e.target.value === 'yes') {
          setisholdLine(true);
        } else {
          setisholdLine(false);
        }
    }
    let symbolDetail = {
        Spec: "",
        size1: "",
        Seq: "",
        hdr: "",
        insulationType: "",
        fluid: "",
    }
    if(props.show === true) {
        if(edit === undefined) {
            const jsonData = (props.mtoData);
                const symbolData = Object.entries(jsonData[0][1].Symbols);
                if(symbolData.length > 0) {
                const details = symbolData[symbolData.length - 1][1];
                symbolDetail = {
                    Spec: details.Spec,
                    size1: details.Size1,
                    Seq: details.SequenceNumber,
                    hdr: details.HdrSize.split('"')[0],
                    insulationType: details.InsulationType,
                    fluid: details.Fluid,
                }
            }
            else {
                symbolDetail = {
                    Spec: "",
                    size1: "",
                    Seq: "",
                    hdr: "",
                    insulationType: "",
                    fluid: "",
                }
            }
        }
        
    }
    

    /* Function for adding new symbol */
    const handleAdd = (e) => {
        let otherProperties = {
            SequenceNumber: parseInt(document.getElementById("seq").value),
            Spec: document.getElementById("spec").value,
            IsJacketedLine:isJacketedLine,
            Size1: document.getElementById("size1").value,
            Size2: document.getElementById("size2").value,
            ShortCode: document.getElementById("add-short-code").value === "" ? document.getElementById("short-code").value : document.getElementById("add-short-code").value,
            Quantity: 1,
            InsulationType:document.getElementById("insulation-type").value,
            AgUg:isagug === true ? "AG" : "UG",
            Seat: document.getElementById("seat").value,
            ValveBody: document.getElementById("valve").value,
            ExtendedBonet:document.getElementById("bonnet").value,
            LimitSwich:document.getElementById("switch").value,
            Hold: isholdLine,
            HdrSize: document.getElementById("hdr").value,
            assetComponentType: document.getElementById("assetCompType").value,
            ToLine: document.getElementById("to").value,
            FromLine: document.getElementById("from").value,
            Remarks: document.getElementById("remarks").value,
            ItemOrder: document.getElementById("item").value,
            Fluid: document.getElementById("fluid").value,
            SymbolRank: 0,
            Rating : "",
          };
          const lineNo = document.getElementById("add-new").value !== "" ? document.getElementById("add-new").value : document.getElementById("line-number").value;
         const lg =  filterData(
            lineNo,
            Object.entries(lineList)
          );
          const data = lineList[lg[0]]["Symbols"];
          const jsonData = Object.entries(data)
        //   console.log(jsonData[0][1].Rating);
          otherProperties.Rating = jsonData[0] !== undefined ? jsonData[0][1].Rating : "";
          props.properties(otherProperties);
          setAddnew(false);
          setAddnewCode(false);
          props.onHide();
          setAddnew(false);
    }

    

    /* Function for editing the existing symbol */

    const handleEdit = (e) => {
        // console.log(properties);
        // const lineNo = document.getElementById("add-new").value !== "" ? document.getElementById("add-new").value : document.getElementById("line-number").value;
        const allProperties = {
            IsJacketedLine:isJacketedLine,
            Size1: document.getElementById("size1").value === "Not Detected" ? properties.Size1: document.getElementById("size1").value,
            Size2: document.getElementById("size2").value === "Not Detected" || document.getElementById("size2").value.length === 0 ? null: document.getElementById("size2").value,
            ShortCode: document.getElementById("add-short-code").value === "" ? document.getElementById("short-code").value : document.getElementById("add-short-code").value,
            InsulationType: document.getElementById("insulation-type").value === "Not Detected" ? properties.InsulationType : document.getElementById("insulation-type").value,
            AgUg:isagug === true ? "AG" : "UG",
            Seat: document.getElementById("seat").value === "Not Detected" ? properties.Seat : document.getElementById("seat").value,
            ValveBody: document.getElementById("valve").value === "Not Detected" ? properties.ValveBody : document.getElementById("valve").value,
            ExtendedBonet:document.getElementById("bonnet").value === "Not Detected" ? properties.ExtendedBonet : document.getElementById("bonnet").value,
            LimitSwich: document.getElementById("switch").value === "Not Detected" ? properties.LimitSwich : document.getElementById("switch").value,
            Hold: isholdLine,
            lineNumber: document.getElementById("add-new").value !== "" ? document.getElementById("add-new").value : document.getElementById("line-number").value,
            specification: document.getElementById("add-new").value !== "" ? document.getElementById("add-new").value : document.getElementById("line-number").value,
            SymbolId: properties.assetID,
            Coords: properties.Coords,
            id: properties.id,
            assetID: properties.assetID,
            Fluid: document.getElementById("fluid").value === "Not Detected" ? properties.Fluid : document.getElementById("fluid").value,
            HdrSize: document.getElementById("hdr").value === "Not Detected" ? properties.HdrSize : document.getElementById("hdr").value,
            Quantity: properties.Quantity,
            assetComponentType: document.getElementById("assetCompType").value === "Not Detected" ? properties.assetComponentType : document.getElementById("assetCompType").value,
            SequenceNumber: document.getElementById("seq").value === "Not Detected" ? properties.SequenceNumber :parseInt(document.getElementById("seq").value),
            Spec: document.getElementById("spec").value === "Not Detected" ? properties.Spec : document.getElementById("spec").value,
            oldLineNumber: properties.lineNumber,
            assetType: "symbols",
            ToLine: document.getElementById("to").value === "Not Detected" ? properties.ToLine : document.getElementById("to").value,
            FromLine: document.getElementById("from").value === "Not Detected" ? properties.FromLine : document.getElementById("from").value,
            Remarks: document.getElementById("remarks").value === "Not Detected" ? properties.Remarks : document.getElementById("remarks").value,
            ItemOrder: document.getElementById("item").value,
            Rating: properties.Rating,
            SymbolRank: properties.SymbolRank,
            ConfidenceScore: properties.ConfidenceScore,
            CorrectedRow: properties.CorrectedRow,
        }
        if(allProperties.oldLineNumber != allProperties.lineNumber) {
            allProperties.ConfidenceScore = 100;
        }
        setAddnew(false);
        const tobeDeleted = deleteData();
        if(tobeDeleted.length > 0) {
            for(let index in tobeDeleted) {
                    dispatch(deleteSymbol(tobeDeleted[index]));
                if(tobeDeleted[index].LineNumber === allProperties.specification) {
                    allProperties.SymbolId = tobeDeleted[index].SymbolId;
                    allProperties.SymbolRank = 0;
                    allProperties.assetID = tobeDeleted[index].properties.id;
                }
            }
        }
            allProperties.SymbolRank = 0;
        dispatch(editSymbolData(allProperties));
        dispatch(FilterLineGroup(allProperties));
        setAddnew(false);
        setAddnewCode(false);
        props.onHide();
    }

    const handleClose = () => {
        setAddnew(false);
        setAddnewCode(false);
        props.onClose();
    }


    const handleDelete = () => {
        if(edit === true) {
            properties.LineNumber = properties.lineNumber;
            properties.assetId = properties.id;
           
            dispatch(deleteSymbol(properties));
            props.delete();
            setAddnew(false);
            setAddnewCode(false);
            dispatch(FilterLineGroup(properties));
            props.onClose();
            
        }
        else {
            setAddnew(false);
            setAddnewCode(false);
            props.onClose();
        }
        
       
    }

    const handleLineChange = () => {
        const lineno = document.getElementById("line-number").value;
        let variable = false;
        if(lineGroups.length > 0) {
            const tobeDeleted = deleteData();
            for(let index in tobeDeleted) {
                if(tobeDeleted[index].LineNumber === lineno) {
                    // properties  = tobeDeleted[index].properties;
                    variable = true;
                    document.getElementById("size1").value = tobeDeleted[index].properties.size1;
                    document.getElementById("size2").value = tobeDeleted[index].properties.size2;
                    document.getElementById("spec").value = tobeDeleted[index].properties.Spec;
                    document.getElementById("seq").value = tobeDeleted[index].properties.Seq;
                    document.getElementById("hdr").value = tobeDeleted[index].properties.hdr;
                    document.getElementById("assetCompType").value = tobeDeleted[index].properties.assetCompType;
                    document.getElementById("insulation-type").value = tobeDeleted[index].properties.insulationType;
                    document.getElementById("fluid").value = tobeDeleted[index].properties.fluid;
                    document.getElementById("seat").value = tobeDeleted[index].properties.seat;
                    document.getElementById("valve").value = tobeDeleted[index].properties.valveBody;
                    document.getElementById("bonnet").value = tobeDeleted[index].properties.extendedBonet;
                    document.getElementById("switch").value = tobeDeleted[index].properties.limitSwich;
                    document.getElementById("from").value = tobeDeleted[index].properties.fromLine;
                    document.getElementById("to").value = tobeDeleted[index].properties.toLine;
                    document.getElementById("item").value = tobeDeleted[index].properties.itemOrder;
                    document.getElementById("remarks").value = tobeDeleted[index].properties.remarks;
                    document.getElementById("IsJacketedLine").value = tobeDeleted[index].properties.isJacketed === "N" ? "no" : "yes";
                    document.getElementById("agug").value = tobeDeleted[index].properties.ugag === "UG" ? "no" : "yes";
                    document.getElementById("holdline").value = tobeDeleted[index].properties.hold === "N" ? "no" : "yes";
                    if(tobeDeleted[index].properties.isJacketed === "N") {
                        setisJacketedLine(false);
                    }
                    else {
                        setisJacketedLine(true);
                    }
                    if(tobeDeleted[index].properties.ugag === "UG") {
                        setisagug(false);
                    }
                    else {
                        setisagug(true);
                    }
                    if(tobeDeleted[index].properties.hold === "N") {
                        setisholdLine(false);
                    }
                    else {
                        setisholdLine(true);
                    }
                   
                }

                
            }
            
        }
        if(variable === false) {
            let updatedValues = {};
            const jsonData = (props.mtoData);
            const lineGroup = jsonData.filter((row) => row[1].specification === lineno);
            const symbolData = Object.entries(lineGroup[0][1].Symbols);
            const lineData = Object.entries(lineGroup[0][1].Lines);
            const lineId = lineData[0][1].LineSegmentId
            if(symbolData.length > 0) {
            const details = symbolData[symbolData.length - 1][1];
             updatedValues = {
                Spec: details.Spec,
                size1: details.Size1,
                Seq: details.SequenceNumber,
                hdr: details.HdrSize.split('"')[0],
                insulationType: details.InsulationType,
                fluid: details.Fluid,
            }
        }
        else if(lineId === "A0") {
            const size = lineno.split('"')[0];
            updatedValues = {
                Spec: "",
                size1: size,
                Seq: "",
                hdr: size,
                insulationType: "",
                fluid: "",
            }
        }
        else {
             updatedValues = {
                Spec: "",
                size1: "",
                Seq: "",
                hdr: "",
                insulationType: "",
                fluid: "",
            }
        }
                    document.getElementById("spec").value = updatedValues.Spec;
                    document.getElementById("size1").value = updatedValues.size1;
                    document.getElementById("seq").value = updatedValues.Seq;
                    document.getElementById("hdr").value = updatedValues.hdr;
                    document.getElementById("insulation-type").value = updatedValues.insulationType;
                    document.getElementById("fluid").value = updatedValues.fluid;
        }
        props.onSelectChange(lineno);
    }
    const [isValve,setIsValve] = useState(false);
    const valveList = ["VG","VGW","VGPS","VO","VC","VP","VBF","VDA","VDG","VDG","VDA","PRV","VF","VN","VD","Z3V","ZAV"];
    
    const handleShortCode = (e) => {
        const API_Endpoint = process.env.REACT_APP_API_ENDPOINT;
        const result = axios.get(API_Endpoint + `/GetPidAssetComponentType?projectWorkSpaceId=${UserStore.PIDworkspaceID}&shortCode=${e.target.value}`).then((result) => {
            document.getElementById("assetCompType").value = result.data.toUpperCase();
        });
        const shortCode = e.target.value;
        const isAValve = valveList.filter(row => row === shortCode);
        if(isAValve.length > 0) {
            setIsValve(true);
            document.getElementById("seat").value = "";
            document.getElementById("valve").value = "";
            document.getElementById("bonnet").value = "";
            document.getElementById("switch").value = "";
        }
        else {
            document.getElementById("seat").value = "N";
            document.getElementById("valve").value = "N";
            document.getElementById("bonnet").value = "N";
            document.getElementById("switch").value = "N";
        }
    }

    const validateShortCode = (e) => {
        const API_Endpoint = process.env.REACT_APP_API_ENDPOINT;
        // console.log(e.target.value);
        if(e.target.value != "" && e.target.value != null && e.target.value != undefined  ) {
        axios.get(API_Endpoint + `/GetPidAssetComponentType?projectWorkSpaceId=${UserStore.PIDworkspaceID}&shortCode=${e.target.value}`).then((result) => {
            document.getElementById("assetCompType").value = result.data.toUpperCase();
        });
        const shortCode = e.target.value;
        const isAValve = valveList.filter(row => row === shortCode);
        if(isAValve.length > 0) {
            setIsValve(true);
            document.getElementById("seat").value = "";
            document.getElementById("valve").value = "";
            document.getElementById("bonnet").value = "";
            document.getElementById("switch").value = "";
        }
        else {
            document.getElementById("seat").value = "N";
            document.getElementById("valve").value = "N";
            document.getElementById("bonnet").value = "N";
            document.getElementById("switch").value = "N";
        }
    }
    }
    const [copy,setCopy] = useState("");
    const copyLineNumber = () => {
        const lineNo = document.getElementById("add-new").value !== "" ? document.getElementById("add-new").value : document.getElementById("line-number").value;
        setCopy(lineNo);
        navigator.clipboard.writeText(lineNo);
        // alert("copied to clipboard");
        toast.success("copied to clipboard", { autoClose: 3000 });
    }

    const changeFromLine = () => {
        const lineNo = document.getElementById("add-new").value !== "" ? document.getElementById("add-new").value : document.getElementById("line-number").value;
        document.getElementById("from").value = lineNo;
        // setFromLine(lineNo);
    }
    // const [fromLine,setFromLine] = useState(edit === true ? properties.FromLine : lineNumber);

    // console.log(fromLine);
    const handleAddNew = () => {
        setAddnew(!addnew);
    }
    
    const setAssetComp = () => {
        const API_Endpoint = process.env.REACT_APP_API_ENDPOINT;
        const result = axios.get(API_Endpoint + `/GetPidAssetComponentType?projectWorkSpaceId=${UserStore.PIDworkspaceID}&shortCode=${shortCodeList[0]}`).then((result) => {
            document.getElementById("assetCompType").value = result.data.toUpperCase();
        });
    }
    return(
        <Modal className="add-symbol-popup" show={props.show} onHide={props.onHide} backdrop="static" keyboard={false} dialogClassName="fullmodal-add-symbol" centered>
                <Modal.Body>
                    <label for="line-number">
                        <span className="label-text">Line Number</span>
                    </label>
                    <select id="line-number" name="line-number" className="input-field-symbol" onChange={handleLineChange} defaultValue={edit === true ? properties.lineNumber : ""}>
                    {
                        lineGroups.length > 0 && edit == true?
                             lineGroups.map((value,index) =>
                                 <option value={value}> {value === "UNKNOWN" ? "Not Detected" : maxRank > index ? value + " - " + (index+1) : value } </option>
                             )
                        : lineGroupData.map((value) =>
                        <option value={value}> {value === "UNKNOWN" ? "Not Detected" : value } </option>
                    )
                        }
                    </select>
                    <div style = {{display:"flex"}}>
                    <span className="add-line" id="line-number-text" onClick={handleAddNew}>+Add New</span>
                    <span className="add-line" id="line-number-text" onClick={copyLineNumber} style={{marginLeft:"5vw"}}>Copy Line Number</span>
                    <span className="add-line" id="line-number-text" onClick={changeFromLine} style={{marginLeft:"5vw"}}>Keep as From Line</span>
                    </div>
                    <TextField id="add-new" type="text" className="add-new" style={addnew ? {display: "block"} : {display: "none"}} InputLabelProps={{ shrink: true }} onChange={edit === true ? "" : props.onChange}/>
                    <label for="short-code">
                        <span className="label-text">Short Code</span>
                    </label>
                    <select id="short-code" name="short-code" className="input-field-symbol" defaultValue={edit === true ? properties.shortCode : ""} onChange = {handleShortCode}>
                         {
                             shortCodeList.map((value) =>
                                 <option value={value}> {value.length === 0 ? "Not Detected" : value} </option>
                             )
                        }
                    </select>
                    <span className="add-line" onClick={() => setAddnewCode(!addnewCode)}>+Add New</span>
                    <TextField id="add-short-code" type="text" className="add-new" style={addnewCode ? {display: "block"} : {display: "none"}} InputLabelProps={{ shrink: true }} onBlur = {validateShortCode}/>
                    <div className="spec-label">
                        <label for="spec">
                            <span className="spec-label1">Spec</span>
                        </label>
                        <label for="seq">
                            <span className="seq-label">Sequence Number</span>
                        </label>
                    </div>
                    <div className="spec">
                        <TextField className="symbol-field spec" id="spec" type="text" InputLabelProps={{ shrink: true }} defaultValue={edit === true ? (properties.Spec.length === 0 ? "Not Detected" : properties.Spec ) : symbolDetail.Spec} />
                        <TextField className="symbol-field seq" id="seq" type="text" InputLabelProps={{ shrink: true }} defaultValue={edit === true ? properties.SequenceNumber !== null || properties.SequenceNumber !== undefined ? properties.SequenceNumber === "NaN" || properties.SequenceNumber.length === 0 ? "Not Detected" : properties.SequenceNumber : symbolDetail.Seq : symbolDetail.Seq} />
                    </div>
                    <div className="label-hdr" style={{paddingTop:"0.5vw"}}>
                        <label for="hdr">
                            <span className="hdr-label">HDR Size</span>
                        </label>
                        <label for="assetCompType">
                            <span className="assetCompType-label">Asset Component Type</span>
                        </label>
                    </div>
                    <div className="hdr-field">
                        <TextField className="symbol-field hdr" id="hdr" type="text" InputLabelProps={{ shrink: true }} defaultValue={edit === true ? properties.HdrSize.length === 0 ? "Not Detected" : properties.HdrSize.split(/[" ']+/)[0] : symbolDetail.hdr}/>
                        <TextField className="symbol-field assetCompType" id="assetCompType" type="text" InputLabelProps={{ shrink: true }} defaultValue={edit === true ? properties.assetComponentType.length === 0 ? "Not Detected" : properties.assetComponentType : props.assetComponent}/>
                    </div>
                    <div className="size-label" style={{paddingTop:"0.5vw"}}>
                        <label for="size1">
                            <span className="s1">Size 1</span>
                        </label>
                        <label for="size2">
                            <span className="s2">Size 2</span>
                        </label>
                    </div>
                    <div className="size">
                        <TextField className="symbol-field size1" id="size1" type="text" InputLabelProps={{ shrink: true }} defaultValue={edit === true ? size1.length === 0 ? "Not Detected" : size1 : symbolDetail.size1}/>
                        <TextField className="symbol-field size2" id="size2" type="text" InputLabelProps={{ shrink: true }} defaultValue={edit === true ? (properties.shortCode === "T" || properties.shortCode === "RX") && (size2.length === 0 || size2 === "NaN") ? "Not Detected" : size2 : ""}/>
                    </div>
                    <div className="size-label" style={{paddingTop:"0.5vw"}}>
                        <label for="insulation-type">
                            <span className="insulation-type">Insulation Type</span>
                        </label>
                        <label for="fluid">
                            <span className="fluidlabel">Fluid</span>
                        </label>
                    </div>
                    <div className="size">
                        <TextField className="insulation-type" id="insulation-type" type="text" InputLabelProps={{ shrink: true }} defaultValue={edit === true ? properties.InsulationType.length === 0 ? "Not Detected" :properties.InsulationType : symbolDetail.insulationType}/>
                        <TextField className="fluid" id="fluid" type="text" InputLabelProps={{ shrink: true }} defaultValue={edit === true ? properties.Fluid.length === 0 ? "Not Detected":properties.Fluid : symbolDetail.fluid}/>
                    </div>
                    <div className="size-label" style={{paddingTop:"0.5vw"}}>
                        <label for="seat">
                            <span className="v1">Seat</span>
                        </label>
                        <label for="valve">
                            <span className="v2">Valve Body</span>
                        </label>
                    </div>
                    <div className="size">
                        <TextField className="symbol-field size1" id="seat" type="text" InputLabelProps={{ shrink: true }} defaultValue={edit === true ? properties.Seat.length === 0 ? "" : properties.Seat : isValve ? "" : "N"}/>
                        <TextField className="symbol-field size2" id="valve" type="text" InputLabelProps={{ shrink: true }} defaultValue={edit === true ? properties.ValveBody.length === 0 ? "" : properties.ValveBody : isValve ? "" : "N"}/>
                    </div>
                    <div className="size-label" style={{paddingTop:"0.5vw"}}>
                        <label for="bonnet">
                            <span className="e1">Extended Bonnet</span>
                        </label>
                        <label for="switch">
                            <span className="e2">Limit Switch</span>
                        </label>
                    </div>
                    <div className="size">
                        <TextField className="symbol-field size1" id="bonnet" type="text" InputLabelProps={{ shrink: true }} defaultValue={edit === true ? properties.ExtendedBonet.length === 0 ? "":properties.ExtendedBonet : isValve ? "" : "N"}/>
                        <TextField className="symbol-field size2" id="switch" type="text" InputLabelProps={{ shrink: true }} defaultValue={edit === true ? properties.LimitSwich.length === 0 ? "" : properties.LimitSwich : isValve ? "" : "N"}/>
                    </div>
                    <div className="size-label" style={{paddingTop:"0.5vw"}}>
                        <label for="from">
                            <span className="l1">From Line</span>
                        </label>
                        <label for="to">
                            <span className="l2">To Line</span>
                        </label>
                    </div>
                    <div className="size">
                        <select id="from" className="symbol-field size1" 
                         defaultValue={edit === true ? properties.FromLine : lineNumber} 
                        style={{color:"black",width:"18vw",height: "2vw"}}
                        // onChange = {(e) => setFromLine(e.target.value) }
                        //     value = {fromLine}
                        >
                            
                        {
                             lineGroups.map((value) =>
                                 <option value={value}> {value === "UNKNOWN" ? "Not Detected" : value } </option>
                             )
                        }
                    </select>
                    <select id="to" name="line-number" className="symbol-field size2" 
                     defaultValue={edit === true ? properties.ToLine : lineNumber}
                      style={{color:"black",width:"18vw",height: "2vw"}}>
                        {
                             lineGroups.map((value) =>
                                 <option value={value}> {value === "UNKNOWN" ? "Not Detected" : value } </option>
                             )
                        }
                    </select>
                    </div>
                    <div className="add">
                        <span className="add-line add-from-line" onClick={() => setaddFromline(!addFromline)}>+Add New</span>
                        <span className="add-line add-to-line" onClick={() => setaddToline(!addToline)}>+Add New</span>
                    </div>
                    <div className="add">
                        <TextField className="newfromLine" id="add-from-line" type="text" style={addFromline ? {display: "block"} : {display: "none"}} InputLabelProps={{ shrink: true }} />
                        <TextField className="newtoLine" id="add-to-line" type="text" style={addToline ? addFromline ? {display: "block", marginLeft: '2vw'} : {display: "block", marginLeft: '20vw'} : {display: "none"}} InputLabelProps={{ shrink: true }} />
                    </div>
                    <div className="size-label" style={{paddingTop:"0.5vw"}}>
                        <label for="item">
                            <span className="r1">Item Order</span>
                        </label>
                        <label for="remarks">
                            <span className="r2">Remarks</span>
                        </label>
                    </div>
                    <div className="size">
                        <TextField className="symbol-field size1" id="item" type="text" InputLabelProps={{ shrink: true }} defaultValue={edit === true ? properties.ItemOrder : ""}/>
                        <TextField className="symbol-field size2" id="remarks" type="text" InputLabelProps={{ shrink: true }} defaultValue={edit === true ? (properties.shortCode === "I" || properties.shortCode === "SC" || properties.shortCode === "SI" || properties.assetComponentType === "Control Valve") && properties.Remarks.length === 0 ? "Not Detected" :  properties.Remarks : ""}/>
                    </div>
                    <div className="jacketedLine">
                        <span className="label-text" style={{marginTop: "1vw"}}>Is Jacketed Line</span>
                        <FormControl component="fieldset">
                            <RadioGroup className="radio radio1" aria-label="IsJacketedLine" id= "IsJacketedLine" name="IsJacketedLine" defaultValue={edit === true ? ( properties.IsJacketedLine ? "yes" : "no") : "no"} onChange={JacketedLine}>
                                <FormControlLabel id="yes" value="yes" control={<Radio color="#000" />} label="Yes" />
                                <FormControlLabel id="no" value="no" control={<Radio color="#000" sx={{'& .MuiSvgIcon-root': {fontSize: 28,},}} />} label="No" />
                            </RadioGroup>
                        </FormControl>
                    </div>
                    <div className="agug">
                        <span className="label-text" style={{marginTop: "0vw"}}>AG/UG</span>
                        <FormControl component="fieldset" style={{marginTop:"-0.9vw"}}>
                            <RadioGroup className="radio radio2" aria-label="agug" id="agug" name="agug" defaultValue={edit === true ? (properties.AgUg === "UG" ? "no" : "yes") : "yes"} onChange={AGUG} style={{marginLeft: "18vw"}}>
                                <FormControlLabel value="yes" control={<Radio color="#000" />} label="AG" />
                                <FormControlLabel id="no" value="no" control={<Radio color="#000" />} label="UG" />
                            </RadioGroup>
                        </FormControl>
                    </div>
                    <div className="holdLine">
                        <span className="label-text" style={{marginTop: "0vw"}}>Hold Line</span>
                        <FormControl component="fieldset" style={{marginTop:"-0.9vw"}}>
                            <RadioGroup className="radio radio3" aria-label="holdline" id="holdline" name="holdline" defaultValue={edit === true ? (properties.Hold ? "yes" : "no") : "yes"} onChange={holdLine}>
                                <FormControlLabel value="yes" control={<Radio color="#000" />} label="Yes" />
                                <FormControlLabel id="no" value="no" control={<Radio color="#000" />} label="No" />
                            </RadioGroup>
                        </FormControl>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={edit === true ? handleEdit : handleAdd}>Add</Button>
                    <Button variant="primary" onClick={handleClose}>Cancel</Button>
                    <Button variant="primary" onClick={handleDelete}>Delete</Button>
                </Modal.Footer>
            </Modal>
    );
}