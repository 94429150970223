import { graphConfig } from "./authConfig";

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken 
 */
export async function callMsGraph(accessToken, data) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");

    const options = {
        method: "GET",
        headers: headers
    };

    //graph me API
    // return fetch(graphConfig.graphMeEndpoint, options)
    //     .then(response => response.json())
    //     .catch(error => console.log(error));

    //graph profile pic API
    if (data === "picture") {
        return fetch(graphConfig.graphProfiePicEndpoint, options)
            .then(response => response.blob())
            .catch(error => console.log(error));
    }
    //graph get users API
    else
        return fetch(graphConfig.graphGetUsersEndpoint, options)
            .then(response => response.json())
            .catch(error => console.log(error));
}
