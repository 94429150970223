import React, { Component } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import axios from "axios";
import DragAndDrop from "../DragDrop/DragDrop";
import UserStore from "../../UserStore";
import { toJS } from "mobx";
import Modal from "react-bootstrap/Modal";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ErrorBoundary from "../../Errorlog/ErrorBoundary";
import FormControl from "@material-ui/core/FormControl";
import { TextField, Button } from "@material-ui/core";
import createBrowserHistory from "history/createBrowserHistory";
import "./FileList.scss";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Cancel from "@material-ui/icons/Cancel";
import CircleChecked from "@material-ui/icons/CheckCircleRounded";

const { BlockBlobClient } = require("@azure/storage-blob");
const history = createBrowserHistory();
const API_Endpoint = process.env.REACT_APP_API_ENDPOINT;

export default class FileList extends Component {
  constructor(props) {
    super(props);
    this.openModal = this.openModal.bind(this);
    this.state = {
      files: [],
      file: [],
      submitted: false,
      obj: {},
      Check: false,
      isSelectAll: false,
      uploadPercentage: 0,
      countSeletedItem: 0,
      selectedFiles: [],
      droppedFiles: [],
      uploadedFile: [],
      taggedFiles: [],
      checkFiles: [],
      apifileName: [],
      transmittalno: "",
      taggeddropdown: [],
      uploadblob: false,
      tagValue: "Tag Files",
      tempFiles: [],
      tFiles: [],
      count: 0,
      countmsg: "",
      showfiletypedailog: false,
      fileSucceded: [],
      fileFailed: [],
      showSetTimings: false,
      batchTime: "",
      batchProcess: false,
      selectedTime: "",
      openDatePicker: false,
      errorTransmittalNo: false,
      errorMsgTransmittalNo: false,
    };
  }

  async componentDidMount() {
    this.setState({
      submitted: true,
    });
    let valueofdropfiles = toJS(UserStore.droppedFiles);
    let valueofselectedfiles = toJS(UserStore.selectedFiles);
    let completeFiles = toJS(UserStore.fileSucceded);
    let differencearray = valueofdropfiles.filter(
      (val) => !valueofselectedfiles.includes(val.name)
    );
    UserStore.dropfiles(differencearray);
    let fileNamefordrop = differencearray.map((value) => {
      return value.name;
    });

    this.setState({
      files: fileNamefordrop,
      droppedFiles: differencearray,
      fileSucceded: completeFiles,
    });
  }

  Input = ({ onChange, placeholder, value, onClick }) => (
    <input
      onChange={onChange}
      placeholder={placeholder}
      value={value}
      onClick={onClick}
    />
  );

  // --------------------Start: Function: Date change ---------------------------
  handleTimeDateChange = (e) => {
    this.setState({ openDatePicker: false });
    let dateTime = e + "";
    let time = dateTime.split(" ");
    time = time[4] + "";
    let hh = time.split(":");
    let aa = "";

    if (time === "12:00:00" || time === "12:30:00") {
      hh[0] = "12";
      aa = "PM";
    } else if (time === "00:00:00" || time === "00:30:00") {
      hh[0] = "12";
      aa = "AM";
    } else if (parseInt(hh[0]) > 12) {
      hh[0] = parseInt(hh[0]) - 12;
      aa = "PM";
    }
    else {
      hh[0] = parseInt(hh[0]);
      aa = "AM";
    }
    dateTime = hh[0] + ":" + hh[1] + " " + aa;

    this.setState({ selectedTime: dateTime });
    let standardTime = new Date(e);
    let batchtime = standardTime.toISOString().slice(0, 19);
    this.setState({ batchTime: batchtime });
  };
  // --------------------End: Function: Date change ---------------------------

  openTimePicker = () => {
    this.setState({ openDatePicker: true });
  };

  handleDrop = async (files) => {
    let fileList = this.state.files;

    for (var i = 0; i < files.length; i++) {
      if (files[i].name && files[i].name.slice(-4).toLowerCase() === ".pdf") {
        if (files[i].size < 1073741825) {
          fileList.push(files[i].name);
          this.state.droppedFiles.push(files[i]);
        } else {
          this.setState({
            showfiletypedailog: true,
            showmessage: "Please upload Pdf file less than 1GB",
          });
        }
      } else {
        this.setState({
          showfiletypedailog: true,

          showmessage: "Please upload only pdf file",
        });
      }
    }

    UserStore.dropfiles(this.state.droppedFiles);

    this.setState({
      files: fileList,

      submitted: true,
    });
  };

  // -------------------------------Start: Function: Checkbox selections -----------------------
  handleCheckboxCheck = (e) => {
    var ele = document.getElementsByClassName("file-checkbox");

    if (e.target.checked === true) {
      const fname = e.target.name;
      this.state.selectedFiles.push(fname);
    } else {
      const index = this.state.selectedFiles.indexOf(e.target.name);
      this.state.selectedFiles.splice(index, 1);
      
    }
    if(this.state.selectedFiles.length > 1)
      {
        this.setState({
          countmsg:"Files selected"
        })
      }
      else if(this.state.selectedFiles.length === 1)
      {
        this.setState({
          countmsg:"File selected"
        })
      }else {
        this.setState({
          countmsg:""
        })
      }

      if(ele.length > this.state.selectedFiles.length) {
        this.setState({ isSelectAll: false });
      }

      if(ele.length === this.state.selectedFiles.length) {
        this.setState({ isSelectAll: true });
      }
  };

  selects() {
    var ele = document.getElementsByClassName("file-checkbox");
    var allFiles = [];
    this.setState({ isSelectAll: !this.state.isSelectAll });

    if (!this.state.isSelectAll) {
      for (var i = 0; i < ele.length; i++) {
        if (ele[i].type === "checkbox") ele[i].checked = true;
        allFiles.push(ele[i].name);
      }
    }

    if (this.state.isSelectAll) {
      for (var i = 0; i < ele.length; i++) {
        if (ele[i].type === "checkbox") ele[i].checked = false;
      }
    }
    let msg = "";
    if(allFiles.length === 1)
    {
      msg = "File selected";
    }
    else{
      msg = "Files selected";
    }
    this.setState({ 
      selectedFiles: allFiles,
      countmsg: msg,
     });
  }
  removeSelectAll = () => {
    this.setState({ 
      isSelectAll: false,
     });
  };
  // -------------------------------End: Function: Checkbox selections -----------------------

  // -------------------- Start: Function: Delete files selected ------------------
  handleDelete = () => {
    if (this.state.selectedFiles.length === 0) {
      this.setState({
        fileSelectionError: true,
        fileSelectionErrorMsg: "Please select at least one file to delete",
      });
    } else {
      this.setState({ deletePopup: true });
    }
  };

  handleDeleteClose = () => {
    this.setState({ deletePopup: false });
  };

  handleDelete = () => {
    if (this.state.selectedFiles.length === 0) {
      this.setState({
        fileSelectionError: true,
        fileSelectionErrorMsg: "Please select at least one file to delete",
      });
    } else {
      this.setState({ deletePopup: true });
    }
  };
  // -------------------- Start: Function: Delete files selected ------------------

  handleDeleteClose = () => {
    this.setState({ deletePopup: false });
  };

  handleDeleteClose = () => {
    this.setState({ deletePopup: false });
  };

  delete() {
    this.setState({ deletePopup: false });
    const updatedFiles = [...this.state.files];
    const filesToDelete = [];
    const removeTagedFile = this.state.tempFiles;
    const deleteDropFile = this.state.droppedFiles;
    var ele = document.getElementsByClassName("file-checkbox");
    for (var i = 0; i < ele.length; i++) {
      if (ele[i].type === "checkbox" && ele[i].checked === true) {
        filesToDelete.push(ele[i].name);
      }
    }

    filesToDelete.map((file) => {
      const index = updatedFiles.indexOf(file);
      updatedFiles.splice(index, 1);
    });
    let differencearrayremove = deleteDropFile.filter((val) =>
      updatedFiles.includes(val.name)
    );
    this.setState({
      files: updatedFiles,
      selectedFiles: [],
      droppedFiles: differencearrayremove,
      tempFiles: removeTagedFile.filter((val) => !filesToDelete.includes(val)),
      tFiles: removeTagedFile.filter((val) => !filesToDelete.includes(val)),
    });
    UserStore.dropfiles(updatedFiles);
    for (var i = 0; i < ele.length; i++) {
      if (ele[i].type === "checkbox") ele[i].checked = false;
    }
    this.removeSelectAll();
    if (!updatedFiles.length){
      this.setState({
        transmittalno: ''      
      })
    }
  }

  filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  // ----------------------------------Start: Function: Upload selected files ------------------------
  handleChange = async (event) => {
    let data = {};
    if (this.state.batchProcess) {
      let scheduleTime = {
        BatchScheduledDateTime: this.state.batchTime,
      };
      // API: Create a batch upload for the selected files
      await axios
        .post(API_Endpoint + `/CreateBatch`, scheduleTime)
        .then((res) => {
          if (res.status === 200) {
            data = {
              batchId: res.data.batchId,
              isBatchFileProcessing: true,
            };
          }
        });
      this.setState({
        selectedTime: "",
        batchTime: "",
        batchProcess: false,
      });
    }
    UserStore.selectedfiles(this.state.selectedFiles);
    var ele = document.getElementsByClassName("file-checkbox");
    const updatedDropFile = toJS(UserStore.droppedFiles);
    const updatedSelectedList = this.state.selectedFiles;
    const count = UserStore.countforProgress;
    this.setState({
      countSeletedItem: this.state.selectedFiles.length,
    });
    UserStore.setCount(count + 1);
    const progessFile = toJS(UserStore.selectedfilesforupload);
    const allFile = [...progessFile, ...this.state.selectedFiles];
    UserStore.selectedFilesforupload(allFile);
    
    for(let t in this.state.selectedFiles) {
      var index = this.state.files.indexOf(this.state.selectedFiles[t]);
      if (index > -1) {
        this.state.files.splice(index, 1);
      }
    }
    
    for (let i in this.state.selectedFiles){
      var idx = this.state.droppedFiles.findIndex(p => p.name==this.state.selectedFiles[i]);
      this.state.droppedFiles.splice(idx,1);      
    }
    
    this.setState({
      files: this.state.files,
      droppedFiles: this.state.droppedFiles,
      selectedFiles: [],
    });
    

    for (var i = 0; i < ele.length; i++) {
      if (ele[i].type === "checkbox") ele[i].checked = false;
    }
    
    for (let x = 0; x < updatedDropFile.length; x++) {
      let fileCount = 0;
      for (let z = 0; z < updatedSelectedList.length; z++) {
        if (updatedDropFile[x].name === updatedSelectedList[z]) {
          fileCount = fileCount + 1;
          updatedSelectedList[z]="";
          
          const filename = updatedDropFile[x].name;
          // const workspaceid = UserStore.workspaceId;
          const workspaceid = UserStore.workspaceuploadID;
          const tagid = this.state.taggedFiles.filter((tag) => {
            if (tag.filename === filename) {
              return tag.tag;
            }
          });
          const tagIds = tagid.map((tg) => tg.tag);
          data = {
            ...data,
            workSpaceId: workspaceid,
            fileName: filename,
            tagIds: tagIds,
            transmittalNumber: this.state.transmittalno,
          };

          const file = updatedDropFile[x];

          const options = {
            // headers: { 'Ocp-Apim-Subscription-Key': '3fcb6bbb0dce4ca38442284bdfc12df2' },
            onUploadProgress: (ProgressEvent) => {
              const { loaded, total } = ProgressEvent;
              let percent = Math.floor((loaded * 100) / total);
              UserStore.getPercentage(percent);
              if (percent < 100) {
                this.setState({
                  uploadPercentage: percent,
                });
              } else if (percent === 100) {
                this.state.uploadedFile.push(filename);
              }
            },
          };
          if (fileCount == 1) {
            // API: Upload path for individual files
            await axios
              .post(API_Endpoint + `/GetUploadPath`, data, options)

              .then((res) => {
                const filename = res.data.fileName;
                let uri = res.data.sasUri;                                            
                if (res.status === 200) {
                  
                  this.setState({
                    uploadblob: true,
                    fileSucceded: [file.name],
                  });
                   const  resid = this.handleblobLoad(uri, file, filename);
                  
                } else {
                  UserStore.getPercentage(0);               
                  this.setState({
                    fileFailed: [file.name],
                  });                  
                  this.handleFailed(uri, res.data.message, filename);
                }
              });
          }          
        }
      }
    }
    UserStore.dropfiles(this.state.droppedFiles);
    this.setState({
      transmittalno: ''      
    })
  };

  // -------------------------Start: Function: upload file to bobo storage -------------
  handleblobLoad = async (uri, file, filename) => {
    try{
      localStorage.setItem("apicalls","active");
    const blobServiceClient1 = new BlockBlobClient(uri);
    const uploadBlobResponse = await blobServiceClient1.uploadData(file);
    if(!uploadBlobResponse.requestId)
     {
       // API: Upload file to blob
      let res = await axios.post(API_Endpoint + `/FileUploadComplete`, {
           "fileUploadCompletedList":[
          {
             "fileName": filename,
             "fileUploadedSuccessfully": false,
             "status": "No response from blob"
         }
        ]}).then(() => {
          localStorage.setItem("apicalls","inactive");
        });  
        
        let files = toJS(UserStore.selectedfilesforupload);
        let splittedfilenames = filename.split("_")[0];
        let filenameresults = filename.split(`${splittedfilenames}_`)[1];
        UserStore.selectedFilesforupload(
          files.filter((item) => item !== filenameresults)
        );
      
      
     } 
     else
     {
      let res = await axios.post(API_Endpoint + `/FileUploadComplete`, {
        "fileUploadCompletedList":[
       {
          "fileName": filename,
          "fileUploadedSuccessfully": true,
          "status": "File Uploaded Successfully"
      }
      
     ]}).then(() => {
      localStorage.setItem("apicalls","inactive");
     });
     let filesnew = toJS(UserStore.selectedfilesforupload);
     let splittedfilenamesnew = filename.split("_")[0];
     let filenameresultsnew = filename.split(`${splittedfilenamesnew}_`)[1];
     UserStore.selectedFilesforupload(
       filesnew.filter((item) => item !== filenameresultsnew)
     );
     }   
     

  }
  
  catch (error) {
    let files = toJS(UserStore.selectedfilesforupload);
    let splittedfilename = filename.split("_")[0];
    let filenameresult = filename.split(`${splittedfilename}_`)[1];
  
    UserStore.selectedFilesforupload(
      files.filter((item) => item !== filenameresult)
    );
    let errstr = error.message.split(".\n")[0];

    this.logMyErrors(errstr, filename);    
    throw new Error('Error Uploading the selfie to blob');    
}     

UserStore.getprocessGrid(1);
    localStorage.setItem("getprocessGrid", 1);
    const data = this.state.apifileName;
    this.state.apifileName.push(filename);
    this.state.count = this.state.count + 1;
    UserStore.addFiles(file.name);
    if (this.state.count === this.state.countSeletedItem) {      
      history.push("/Workspace/UploadFiles");
      this.setState({
        count: 0,
        batchProcess: false,
      });
    }  
   
  };
  // -------------------------End: Function: upload file to bobo storage -------------
  // ----------------------------------End: Function: Upload selected files ------------------------

  // ------------------Start: Function: Log errors while uploading-----------------------
  logMyErrors = async(error,filename)=>
  {

    let splittedfilename = filename.split("_")[0];
    let filenameresult = filename.split(`${splittedfilename}_`)[1];
    
    let delfiles = [...UserStore.rejectedfilesforupload,filenameresult];

    UserStore.rejectedFilesforupload(delfiles);
    
    let res = await axios.post(API_Endpoint + `/FileUploadComplete`, {
      "fileUploadCompletedList":[
      {
        "fileName": filename,
        "fileUploadedSuccessfully": false,
        "status": error
    }
    ]});
    
  }
  // ------------------End: Function: Log errors while uploading-----------------------

  handleFailed = async (uri, data, filename) => {
    let files = toJS(UserStore.selectedfilesforupload);
    let splittedfilename1 = filename.split("_")[0];
    let filenameresult1 = filename.split(`${splittedfilename1}_`)[1];
  
    UserStore.selectedFilesforupload(
      files.filter((item) => item !== filenameresult1)
    );
    let delfiles = [...UserStore.rejectedfilesforupload,filename];
    UserStore.rejectedFilesforupload(delfiles);
    let res = await axios.post(API_Endpoint + `/FileUploadComplete`, {
      "fileUploadCompletedList":[
      {
        "fileName": filename,
        "fileUploadedSuccessfully": false,
        "status": data
    }
    ]});
  }

  openModal = () => {
    if (this.state.selectedFiles.length === 0) {
      this.setState({
        fileSelectionError: true,
        fileSelectionErrorMsg: "Please select at least one file to upload",
      });
    } else if (this.state.transmittalno === "") {
      this.setState({
        showfiletypedailog: true,
        showmessage: "Please type Transmittal Number to upload",
      });
    } else {
      this.setState({ show: true, showSetTimings: false });
    }
  };
  
  handleFileSelection = () => {
    this.setState({ fileSelectionError: false, fileSelectionErrorMsg: "" });
  };

  changeStatus = () => {
    this.setState({ openDatePicker: false });
  };

  handleClose = () => {
    this.setState({
      showfiletypedailog: false,
    });
  };

  handleTransmittalNoChange = (e) => {
    let Transno = e.target.value;
      this.setState({
        transmittalno: Transno,
        errorTransmittalNo: false,
        errorMsgTransmittalNo: "",
      });
  };

  handleClosePopup = () => {
    this.setState({
      show: false,
      selectedTime: "",
      batchProcess: false,
      batchTime: "",
    });
  };

  handleUploadSubmit = () => {
    this.handleChange();
    this.setState({ show: false });
    this.removeSelectAll();
  };

  showTimings = (e) => {
    if (e.target.value === "batchProcess") {
      this.setState({ showSetTimings: true, batchProcess: true });
    } else {
      this.setState({
        showSetTimings: false,
        batchProcess: false,
        batchTime: "",
        selectedTime: "",
      });
    }
  };

  showNextButton = () => {
    this.props.moveToUpload(1);
  };
  render() {
    const filesList = toJS(UserStore.droppedFiles);
    return (
      <ErrorBoundary>
      <div>
        <div
          class="options-bar-wrapper"
          style={
            filesList.length === 0 ? { display: "none" } : { display: "flex" }
          }
        >
          <Checkbox
            checked={this.state.isSelectAll}
            onClick={this.selects.bind(this)}
            className="selectAll1"
            name="checkedB"
            color="primary"
          />
          <span
            className="selectAll"
            style={{ font: "normal normal normal 1vw Helvetica", marginTop: "0.5vw" }}
          >
            Select All
          </span>
          <div className="transmittal-no">
            {/* <label for="transmittal-no" style={{ marginBottom: "0.3vw" }}>
              <span className="transmittal-number">Transmittal Number</span>
            </label> */}
            <TextField
            className="transmittal-number"
              style={{
                fontSize: "0.833vw",
              }}
              type="text"
              id="transmittl-no"
              value={this.state.transmittalno}
              onChange={(e) => this.handleTransmittalNoChange(e)}
              label="Transmittal Number"
              error={this.state.errorTransmittalNo}
              helperText={this.state.errorMsgTransmittalNo}
            />
          </div>
          {this.state.selectedFiles.length > 0 &&  <span
            className="selectAll"
            style={this.state.selectedFiles.length > 0 ? {
              font: "normal normal normal 1vw Helvetica",
              marginLeft: "23.4vw"
            } : {
              font: "normal normal normal 1vw Helvetica",
              marginLeft: "3vw"
            }}
          >
            {this.state.selectedFiles.length} {this.state.countmsg}
          </span>}
          <Button className="upload" onClick={this.openModal}
          style={this.state.selectedFiles.length > 0 ? {
            width: "8.4vw",
            height: "2.29vw",
            fontSize: "0.9vw",
            background: "#0c629b",
            color: "#ffffff",
            textTransform: "none",
            borderRadius: "0.3vw",
            border: "0",
            marginLeft: "1vw"
          } : {
            width: "8.4vw",
            height: "2.29vw",
            fontSize: "0.9vw",
            background: "#0c629b",
            color: "#ffffff",
            textTransform: "none",
            borderRadius: "0.3vw",
            border: "0",
            marginLeft: "32vw"
          }}>
            <img
              src="/images/icon_upload_grey.svg"
              style={{ marginRight: "0.5vw", width: "1.5vw", height: "1.3vw" }}
            />
            <span style={{ font: "normal normal normal 1vw Helvetica" }}>
              Upload
            </span>
          </Button>
          <Button className="delete" onClick={this.handleDelete} style={{
            background: "#ffffff",
            border: "0.05vw solid #0c629b",
            borderRadius: "0.15vw",
            width: "8.4vw",
            height: "2.29vw",
            padding: "0.15vw 0",
            color: "#0c629b",
            marginLeft: "1vw"
          }}>
            <img
              src="/images/icon_delete.svg"
              style={{
                marginRight: "0.5vw",
                height: "1.2vw",
                width: "1.4vw",
              }}
            />
            <span style={{ font: "normal normal normal 1vw Helvetica", textTransform: "capitalize" }}>
              Delete
            </span>
          </Button>
        </div>
        <Dialog
          className="delete-confirm"
          open={this.state.deletePopup}
          onClose={this.handleDeleteClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle
            id="form-dialog-title"
            style={{ height: "0.625em" }}
          ></DialogTitle>
          <DialogContent>
            <div style={{ fontSize: "1vw" }}>Are you sure to delete?</div>
            <IconButton
              onClick={this.handleDeleteClose}
              style={{
                padding: "0",
                float: "right",
                background: "#0C629B",
                color: "#fff",
                position: "absolute",
                right: "15%",
              }}
            >
              {" "}
              <Cancel />{" "}
            </IconButton>
            <IconButton
              onClick={this.delete.bind(this)}
              style={{
                padding: "0",
                background: "#0C629B",
                color: "white",
                position: "absolute",
                right: "2%",
              }}
            >
              {" "}
              <CircleChecked />{" "}
            </IconButton>
          </DialogContent>
        </Dialog>

        <div>
          <DragAndDrop
            handleDrop={this.handleDrop}
            files={this.state.files}
            succeded={this.state.fileSucceded}
            tempFiles={this.state.tempFiles}
            handleCheckboxCheck={this.handleCheckboxCheck}
          ></DragAndDrop>

          <Modal
            className="user-select-popup"
            show={this.state.fileSelectionError}
            onHide={this.handleFileSelection}
            backdrop="static"
            keyboard={false}
            dialogClassName="fullmodal"
            size="l"
            centered
          >
            <Modal.Body>
              <label
                style={{
                  font: "normal normal bold 1vw Helvetica",
                  textAlign: "left",
                }}
              >
                {this.state.fileSelectionErrorMsg}
                {/* <Button variant="primary" onClick={this.handleCloseEmptyUser} className="close-btn">Close</Button> */}
                <IconButton
                  onClick={this.handleFileSelection}
                  style={{
                    padding: "3px",
                    position: "absolute",
                    top: "0",
                    right: "0",
                    background: "",
                    borderRadius: "0",
                    color: "#0C629B",
                  }}
                >
                  {" "}
                  <CloseIcon style={{ width: "1.5vw", height: "1.5vw" }} />{" "}
                </IconButton>
              </label>
            </Modal.Body>
          </Modal>

          <Modal
            className="batch-process-modal"
            show={this.state.show}
            onHide={this.handleClose}
            backdrop="static"
            keyboard={false}
            dialogClassName="fullmodal"
            size="l"
            centered
          >
            <Modal.Body>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="upload"
                  name="upload"
                  defaultValue="processNow"
                  onChange={this.showTimings}
                >
                  <FormControlLabel
                    value="processNow"
                    control={<Radio color="primary" />}
                    label="Upload files for processing now"
                    style={{ fontSize: "0.9vw" }}
                  />
                  <FormControlLabel
                    id="batchProcess"
                    value="batchProcess"
                    control={<Radio color="primary" />}
                    label="Upload files for batch processing"
                    style={{ fontSize: "0.9vw" }}
                  />
                  {this.state.showSetTimings ? (
                    <label
                      style={{
                        width: "10vw",
                        padding: "0",
                        margin: "0",
                        height: "0",
                      }}
                    >
                      <div style={{display: "flex",flexDirection: "row"}}>
                        <DatePicker
                          required
                          showTimeSelect
                          dateFormat="h:mm aa"
                          showTimeSelectOnly
                          timeIntervals={30}
                          onChange={this.handleTimeDateChange}
                          filterTime={this.filterPassedTime}
                          open={this.state.openDatePicker}
                          placeholderText="Select an interval"
                          onClickOutside={this.changeStatus}
                          customInput={
                            <div className="selected-time" style={{position: "relative",bottom:"0.5vw", width: "7vw", marginLeft: "2.4vw"}}>
                          at {this.state.selectedTime === "" ? "____" : this.state.selectedTime} 
                          <AccessTimeIcon onClick={this.openTimePicker} style={{position: "relative",left:"0.5vw"}}/>
                        </div>
                          }
                        />
                      </div>
                    </label>
                  ) : (
                    ""
                  )}
                </RadioGroup>
              </FormControl>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                class="next-btn"
                onClick={
                  this.state.batchProcess && this.state.batchTime === ""
                    ? ""
                    : this.handleUploadSubmit
                }
                style={{ height: "2.134vw", width: "6.3vw", fontSize: "0.9vw" }}
              >
                Submit
              </Button>

              <Button
                variant="primary"
                class="cancel-btn"
                onClick={this.handleClosePopup}
                style={{ height: "2.134vw", width: "6.3vw" }}
              >
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={this.state.showfiletypedailog}
            onHide={this.handleClose}
            backdrop="static"
            keyboard={false}
            dialogClassName="fullmodal"
            size="l"
            centered
          >
            <Modal.Body>
              <label
                style={{
                  font: "normal normal bold 1vw Helvetica",
                  textAlign: "left",
                }}
              >
                {this.state.showmessage}
                <IconButton
                  onClick={this.handleClose}
                  style={{
                    padding: "0.156vw",
                    position: "absolute",
                    top: "0",
                    right: "0",
                    background: "",
                    borderRadius: "0",
                    color: "#0C629B",
                  }}
                >
                  {" "}
                  <CloseIcon style={{ width: "1.5vw", height: "1.5vw" }} />{" "}
                </IconButton>
              </label>
            </Modal.Body>
          </Modal>
        </div>
      </div>
      </ErrorBoundary>
    );
    
  }
}
