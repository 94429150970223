import React, { useState, useEffect, useRef } from "react";
import AppHeader from "../../AppHeader/AppHeader";
import AppFooter from "../../AppFooter/AppFooter";
import Breadcrumbs from "../../Upload/BreadCrumbs/BreadCrumbs";
import Modal from "react-bootstrap/Modal";
import PIDCanvas from "../PIDCanvas/PIDCanvas";
import "./Preview.scss";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { useDispatch, useSelector } from "react-redux";
import {
  GetInitJSON,
  deleteSymbol,
  selectAsset,
  symbolsDetails,
  FilterLineGroup
} from "../../redux/actions/documentActions";
import { Button } from "@material-ui/core";
import UserStore from "../../UserStore";
import CloseIcon from "@material-ui/icons/Close";
import axios from "axios";
import IconButton from "@material-ui/core/IconButton";
import FilterRenderer from "./FilterRenderer";
import { imageLoading } from "../PIDCanvas/PIDCanvas";
import { TextField } from '@material-ui/core';
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import _ from "lodash";
import SaveDetails from "./SaveDetails";

export default function Preview(props) {

  const API_Endpoint = process.env.REACT_APP_API_ENDPOINT;
  const [newRows, setNewRows] = useState([]);
  const [show, setShow] = useState(false);
  let rows = [];
  let rankRows = [];
  localStorage.setItem("imageLoad",true);
  const [imagePath, setImagePath] = useState("");
  const [imageData, setImageData] = useState({});
  const [newAssets, setAssets] = useState({});
  const [show1, setShow1] = useState(false);
  let ls = localStorage.getItem("imageLoad");
  let [loading, setLoading] = useState(imageLoading);
  localStorage.setItem("filterChange","nochange")
  
  useEffect(() => {
      setLoading(imageLoading);
  },[imageLoading])
  const dispatch = useDispatch();
  const [filterRows, setFilterRows] = useState([]);
  const duplicate = JSON.parse(localStorage.getItem("isDuplicatedpid"));
  const [duplicateMsg, setDuplicateMsg] = useState(
    duplicate === "Yes" ? true : false
  );
  const url = window.location.href;
  const splitUrl = url.split("Upload/");
  const sheetName = splitUrl[1];
  const sheetId = JSON.parse(localStorage.getItem("sheetId"));
  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const isAccepted = localStorage.getItem("accepteddocpid")
    ? JSON.parse(localStorage.getItem("accepteddocpid"))
    : UserStore.accepteddocpid;
  const readonly = localStorage.getItem("isreadOnlyPID")
    ? JSON.parse(localStorage.getItem("isreadOnlyPID"))
    : UserStore.readOnlyPID;
    const [assetsData, setAssetsData] = useState([]);
  const [save,setSave] = useState(false);
  const [apiData,setApiData] = useState([]);

  /* Function to call PreviewProcessedPid API */
  async function getAPICall() {
    const result = await axios.get(
      API_Endpoint +
        `/PreviewProcessedPid?sheetId=${JSON.parse(
          localStorage.getItem("sheetId")
        )}`
    );
    setAssets(result.data.extractedJson.linegroup);
    setApiData(JSON.parse(JSON.stringify(result.data.extractedJson.linegroup)));
    localStorage.setItem("assets",result.data.extractedJson.linegroup)
    setImageData(result.data.extractedJson.Size);
    localStorage.setItem("size",result.data.extractedJson.Size)
    setImagePath(result.data.pidUri);
    localStorage.setItem("imagePath",result.data.pidUri)
  }
  useEffect(() => {
    if(localStorage.getItem("prevRoute") === "process") {
      getAPICall();
    }
    else {
      // if(localStorage.getItem("prevRoute") === "overallMTO") {
      //   handleChange();
      //   // return;
      // }
      
        setAssets(JSON.parse(localStorage.getItem("MTOData")));
      dispatch(GetInitJSON(newAssets));
      setAssetsData(Object.entries(docList));
      
      // accurateSymbols();
    }
   
  }, []);

  dispatch(GetInitJSON(newAssets));
  useEffect(() => {
    if(localStorage.getItem("prevRoute") !== "overallMTO")
      dispatch(FilterLineGroup({}));
  },[])
  

  const assetsList = Object.entries(
    useSelector((state) => state.documentData.assetsList)
  );
  const jsonData = useSelector((state) => state.documentData.documentList);
  const initialData = useSelector((state) => state.documentData.initialData);
  const filteredList = useSelector((state) => state.documentData.filteredList);
  const [count,setCount] = useState(0);
  const selectedAsset = useSelector(
    (state) => state.documentData.selectedAsset
  );
  useEffect(() => {
    setAssetsData(Object.entries(jsonData));
    let jsondata = Object.entries(jsonData);
    let rows = [];
    let key = 1;
    rankRows = [...setRows(jsondata,rows,jsondata.length,key,1)];
    dispatch(symbolsDetails(rankRows));
  }, [jsonData]);
  useEffect(() => {
    if (filteredList !== undefined) {
      setAssetsData(Object.entries(filteredList));
    }
    else {
      setAssetsData(Object.entries(jsonData));
    }
  }, [filteredList]);
  let extra = 0;
  

/*Function for storing all the properties of symbols */
  const setRows = (assetsData,rows,length,key,ranking) => {
    let assetData = [];
      extra = extra + 1;
      for (let index = 0; index < length; index++) {
        assetData = assetsData[index][1];
        if (assetData.hasOwnProperty("Symbols")) {
          let symbols = Object.entries(assetData.Symbols);
          for (let newIndex = 0; newIndex < symbols.length; newIndex++) {
            let data = {
              id: symbols[newIndex][1].SymbolId,
              ConfidenceScore: symbols[newIndex][1].ConfidenceScore,
              CorrectedRow: symbols[newIndex][1].CorrectedRow,
              Key: key,
              LineNumber: assetData.specification ,
              assetId: symbols[newIndex][0],
              shortCode: symbols[newIndex][1].ShortCode === undefined || symbols[newIndex][1].ShortCode === null ? "" : symbols[newIndex][1].ShortCode,
              assetCompType:symbols[newIndex][1].assetComponentType === undefined || symbols[newIndex][1].assetComponentType === null ? "" :
                symbols[newIndex][1].assetComponentType.toUpperCase(),
                Seq: symbols[newIndex][1].SequenceNumber === undefined || symbols[newIndex][1].SequenceNumber === null ? "" : symbols[newIndex][1].SequenceNumber ,
              Spec: symbols[newIndex][1].Spec === undefined || symbols[newIndex][1].Spec === null ? "" : symbols[newIndex][1].Spec,
              isJacketed:
                symbols[newIndex][1].IsJacketedLine !== true ? "N" : "Y",
              hdr: symbols[newIndex][1].HdrSize === undefined || symbols[newIndex][1].HdrSize === null ? "": symbols[newIndex][1].HdrSize.split(/[" ']+/)[0],
              size1:
                symbols[newIndex][1].Size1 === null ||
                symbols[newIndex][1].Size1 === "" || symbols[newIndex][1].Size1 === undefined
                  ? ""
                  : (symbols[newIndex][1].Size1),
              size2:
                symbols[newIndex][1].Size2 === null ||
                symbols[newIndex][1].Size2 === "" || symbols[newIndex][1].Size2 === undefined
                  ? ""
                  : (symbols[newIndex][1].Size2),
              quantity: symbols[newIndex][1].Quantity === undefined || symbols[newIndex][1].Quantity === null ? "" : symbols[newIndex][1].Quantity,
              insulationType: symbols[newIndex][1].InsulationType === undefined || symbols[newIndex][1].InsulationType === null ? "" : symbols[newIndex][1].InsulationType,
              ugag: symbols[newIndex][1].AgUg === undefined || symbols[newIndex][1].AgUg === null ? "" : symbols[newIndex][1].AgUg,
              fluid: symbols[newIndex][1].Fluid === undefined || symbols[newIndex][1].Fluid === null ? "" : symbols[newIndex][1].Fluid,
              seat: symbols[newIndex][1].Seat === undefined || symbols[newIndex][1].Seat === null ? "" : symbols[newIndex][1].Seat,
              valveBody: symbols[newIndex][1].ValveBody === undefined || symbols[newIndex][1].ValveBody === null? "" : symbols[newIndex][1].ValveBody,
              extendedBonet: symbols[newIndex][1].ExtendedBonet === undefined || symbols[newIndex][1].ExtendedBonet === null? "" : symbols[newIndex][1].ExtendedBonet,
              limitSwich: symbols[newIndex][1].LimitSwich === undefined|| symbols[newIndex][1].LimitSwich === null ? "" : symbols[newIndex][1].LimitSwich,
              hold: symbols[newIndex][1].Hold === true ? "Y" : "N",
              fromLine: symbols[newIndex][1].FromLine === undefined|| symbols[newIndex][1].FromLine === null ? "" : symbols[newIndex][1].FromLine,
              toLine: symbols[newIndex][1].ToLine === undefined || symbols[newIndex][1].ToLine === null? "" : symbols[newIndex][1].ToLine,
              remarks: symbols[newIndex][1].Remarks === undefined || symbols[newIndex][1].Remarks === null? "" : symbols[newIndex][1].Remarks,
              itemOrder: symbols[newIndex][1].ItemOrder === undefined || symbols[newIndex][1].ItemOrder === null? "" : symbols[newIndex][1].ItemOrder,
              rating: symbols[newIndex][1].Rating === undefined || symbols[newIndex][1].Rating === null? "" : symbols[newIndex][1].Rating,
              coords: symbols[newIndex][1].Coords,
              pid: localStorage.getItem("pidnumber"),
              supply: "",
              SymbolRank: symbols[newIndex][1].SymbolRank !== undefined ? symbols[newIndex][1].SymbolRank : 0,
            };
            if(data.SymbolRank === 0 && ranking === 0) {
              rows.push(data);
            }
            if(ranking === 1) {
              rows.push(data);
            }
              
            key++;
          }
        }
        
      }
      setCount(count + 1);
      return rows;
  }
  
  useEffect(() => {
    const length = assetsData.length;
    let key = 1;
    rows = [];
    rankRows = [];
    if (rows.length === 0) {
      rows = [...setRows(assetsData,rows,length,key,0)];
      setNewRows([...rows]);
      setFilterRows([...rows]);
    }
  }, [assetsData]);

 
  
  const handleDelete = (row, data, index) => {
    dispatch(deleteSymbol(newRows[index]));
  };
 
 
  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => {
    setShow(true);
  };

  const [showElement,setShowElement] = useState(true)
  /*Function for calling save API */
  const saveBtn = () => {
    setShowElement(true)
    const extractedJson = {
      Size: imageData,
      linegroup: newAssets,
    };
    const body = {
      sheetId: sheetId,
      extractedJson: extractedJson,
    };
    axios.post(API_Endpoint + `/ModifyProcessedPid`, body);
    setMessage("Saved Successfully");
    setShowMessage(true);
    const selectedItem = {
      id : undefined
    }
    dispatch(selectAsset(selectedItem))
    setTimeout(function() {
      setShowElement(false)
         }, 3000);
  };

  const handleReject = () => {
    setShowElement(true)
    const selectedItem = {
      id : undefined
    }
    dispatch(selectAsset(selectedItem))
    setShow1(true);
  };

  /*Function for Aproving the pid */
  const handleAccept = () => {
    setShowElement(true)
    const extractedJson = {
      Size: imageData,
      linegroup: newAssets,
    };
    const body = {
      sheetId: sheetId,
      extractedJson: extractedJson,
    };
    axios.post(API_Endpoint + `/AcceptProcessedPid`, body);
    setMessage("Document is Approved");
    setShowMessage(true);
    const selectedItem = {
      id : undefined
    }
    dispatch(selectAsset(selectedItem))
    // setTimeout(function() {
    //   setShowElement(false)
    //      }, 3000);
  };

  /*Function for rejecting the pid */
  const handleCloseAPI = () => {
    const reject = {
      sheetId: sheetId,
      remark: document.getElementById("remarks-field").value,
    };
    axios.post(API_Endpoint + `/RejectProcessedPid`, reject);
    setShow1(false);
    setMessage("Document is Rejected");
    setShowMessage(true);
  };

  const handleCloseBtn = () => {
    setShow1(false);
  };
  const navigatetoProcessgrid = () => {
    if(!(_.isEqual(apiData,jsonData)))
      setSave(true);
    else 
      exitPage();
      // const selectedItem = {
    //   id : undefined
    // }
    // dispatch(selectAsset(selectedItem))
    // UserStore.getprocessGrid(1);
    // props.history.push(`/WorkspacePID/Upload`);
  };

  const handleDuplicate = () => {
    setDuplicateMsg(false);
  };

  /* Function for navigating to overallMTO screen*/
  const overallMTO = () => {
    const selectedItem = {
      id : undefined
    }
    dispatch(selectAsset(selectedItem))
    localStorage.setItem("MTOData",JSON.stringify(jsonData));
    props.history.push(`/WorkspacePID/Upload/${sheetName}/OverallMTO`);
  };
  // localStorage.setItem("change",true);
  const handleChange = (e) => {
    let value = "";
    // if(localStorage.getItem("prevRoute") === "overallMTO")  {
    //   value = UserStore.accuracyPID;
    // }
    // else {
      value = e.target.value;
    // }
     
    if (value[value.length - 1] === "all") {
      setSelected(selected.length === options.length ? [] : options);
      UserStore.setAccuracyPID(selected.length === options.length ? [] : options);
      accurateSymbols();
      return;
    }
    setSelected(value);
    UserStore.setAccuracyPID(value);
    // localStorage.setItem("accuracy",e.target.value);
    accurateSymbols();
  }
  const docList = useSelector((state) => state.documentData.documentList);
  // const classes = useStyles();
  const options = ["high","mid","low"]
  const [selected, setSelected] = useState(UserStore.accuracyPID);
  const isAllSelected =
    options.length > 0 && selected.length === options.length;

  
  const accurateSymbols = () => {
    // if(docList !== undefined)
      const mtoDats =JSON.parse(JSON.stringify(docList)) ;
      const mtoDat = Object.entries(mtoDats);
      let accuracyData = [];
      let symbolsDat = {};
     let km = {};
      if(mtoDat.length > 0) {
        const cScoreArray = UserStore.accuracyPID;
        let cScoreValid = "";
          let val1 = 100;
          let val2 = 0;
          if(cScoreArray.length === 1) {
            cScoreValid = cScoreArray[0];
            if(cScoreValid === "low") {
            val1 = 60;
            val2 = 0;
          }
          else if(cScoreValid === "mid") {
            val1 = 80;
            val2 = 61;
          }
          else if(cScoreValid === "high") {
            val1 = 100;
            val2 = 81;
          }
          else {
            val1 = 100;
            val2 = 0;
          }
          }
          else if(cScoreArray.length === 2) {
            if(cScoreArray.includes("low") && cScoreArray.includes("mid")) {
              val1 = 80;
              val2 = 0;
            }
            else if(cScoreArray.includes("high") && cScoreArray.includes("mid")) {
              val1 = 100;
              val2 = 61;
            }
          }
          else if(cScoreArray.length === 3) {
            val1 = 100;
            val2 = 0;
          }
        for(let index in mtoDat) {
          const lgNumber = mtoDat[index][0];
          accuracyData = [];
           symbolsDat = Object.entries(mtoDat[index][1].Symbols).map((row) => ( { [row[0]]: row[1] } ));
          km = {};
           for(let k in symbolsDat) {
             const rowValue = Object.entries(symbolsDat[k]);
             let cScore = rowValue[0][1].ConfidenceScore;
             if(cScore === "NaN") {
               cScore = 100;
             }
             if(cScoreArray.includes("high") && cScoreArray.includes("low") && cScoreArray.length === 2) {
              if(!(cScore <= 80 && cScore >= 61)) {
                accuracyData.push(symbolsDat[k])
                Object.assign(km,symbolsDat[k]);
              }
            }
            else {
              if(cScore <= val1 && cScore >= val2) {
                accuracyData.push(symbolsDat[k])
                Object.assign(km,symbolsDat[k]);
              }
            }
             
           }
           mtoDats[lgNumber].Symbols = km;
        }
      }
      const assetsDats = Object.entries(mtoDats);
      let rows = [];
      let key = 1;
      rows = [...setRows(assetsDats,rows,assetsDats.length,key,0)];
      setNewRows([...rows])
      setFilterRows([...rows]);
  }
  const saveDetails = () => {
    const extractedJson = {
      Size: imageData,
      linegroup: newAssets,
    };
    const body = {
      sheetId: sheetId,
      extractedJson: extractedJson,
    };
    axios.post(API_Endpoint + `/ModifyProcessedPid`, body);
    exitPage();
  }

  const exitPage = () => {
     const selectedItem = {
      id : undefined
    }
    dispatch(selectAsset(selectedItem))
    UserStore.getprocessGrid(1);
    props.history.push(`/WorkspacePID/Upload`);
  }
  return (
    <div className="Preview-screen-container">
      <AppHeader />
      <Breadcrumbs />

      <div className="back-btn-pid">
        <img src="/images/back.svg" className="back-icon" onClick={navigatetoProcessgrid}/>
        <span className="text" onClick={navigatetoProcessgrid}>Back</span>
      </div>

     {showElement ? <div
        className="accepted-message-wrapper"
        style={showMessage === true ? { display: "flex" } : { display: "none" }}
      >
        <span className="accepted-message">{message}</span>
      </div> : <></>}
        <div className="image-annotator-container">
          <PIDCanvas
            height={imageData.height}
            width={imageData.width}
            assets={jsonData}
            imageSrc={imagePath}
          />
          <div className="MTO-report-container">
            <div className="grid-wrapper">
              <div className="MTO-toolbar">
                <div className="view-MTO" onClick={overallMTO}>
                  <span className="text">View Overall MTO</span>
                  <img
                    src="/images/rightArrow.svg"
                    className="tool-icon"
                    style={{ width: "1vw" }}
                  />
                </div>
                <div  className = "select-all-preview-pid" style={{marginRight:"-13vw",paddingLeft:"3vw",width:"12vw",marginTop:"-2vw"}}>
                  {/* <span className="text">Select Range</span> */}
                  {/* <SelectField multiple = {true} onChange={handleChange} defaultValue="all" style={{fontSize:"1vw"}} label="Confidence Score Filter">
                  <MenuItem key="all"value={"all"}>All</MenuItem>
                    <MenuItem key = "high" value={"high"}>High Accuracy</MenuItem>
                    <MenuItem key = "mid" value={"mid"}>Mid Accuracy</MenuItem>
                    <MenuItem key="mid" value={"low"}>Low Accuracy</MenuItem>
                  </SelectField> */}
                  <FormControl 
                  // className={classes.formControl}
                  >
      <InputLabel id="mutiple-select-label">Filter</InputLabel>
      <Select
        labelId="mutiple-select-label"
        multiple
        value={selected}
        onChange={handleChange}
        renderValue={(selected) => selected.join(", ")}
        // MenuProps={MenuProps}
      >
        <MenuItem
          value="all"
          // classes={{
          //   root: isAllSelected ? classes.selectedAll : ""
          // }}
        >
          <ListItemIcon>
            <Checkbox
              // classes={{ indeterminate: classes.indeterminateColor }}
              checked={isAllSelected}
              indeterminate={
                selected.length > 0 && selected.length < options.length
              }
            />
          </ListItemIcon>
          <ListItemText
            // classes={{ primary: classes.selectAllText }}
            primary="Select All"
          />
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            <ListItemIcon>
              <Checkbox checked={selected.indexOf(option) > -1} />
            </ListItemIcon>
            <ListItemText primary={option} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
                </div>
                <div className="expand-PID" onClick={handleShow}>
                  <img
                    src="images/expand.svg"
                    className="expand-icon"
                    style={{ width: "1vw" }}
                  />
                  <span className="text">Expand Window</span>
                </div>
              </div>
              <div className="MTO-table">
                {newRows.length > 0 ? <FilterRenderer rowsData = {[...newRows]} selected={selectedAsset}/> : ''}
              </div>
              <div className="btns-pid">
                <Button
                  color="primary"
                  className="accept-btn-pid"
                  name="Accept"
                  onClick={saveBtn}
                  style={
                    {
                      display: "block",
                      background: "#0C629B 0% 0% no-repeat padding-box",
                      border: "1px solid #0C629B",
                      borderRadius: "4px",
                      color: "#FFFFFF",
                      width: "8.4vw",
                      height: "2.29vw",
                      marginRight: "1.3vw",
                      font: "normal normal normal 0.78vw Helvetica",
                      textTransform: "none",
                    }
                  }
                >
                  Save
                </Button>
                <Button
                  color="primary"
                  className="accept-btn-pid"
                  name="Accept"
                  onClick={handleAccept}
                  disabled={isAccepted || readonly}
                  style={
                    isAccepted || readonly
                      ? {
                          opacity: 0.5,
                          display: "block",
                          background: "#0C629B 0% 0% no-repeat padding-box",
                          border: "1px solid #0C629B",
                          borderRadius: "4px",
                          color: "#FFFFFF",
                          width: "8.4vw",
                          height: "2.29vw",
                          marginRight: "1.3vw",
                          font: "normal normal normal 0.78vw Helvetica",
                          textTransform: "none",
                        }
                      : {
                          display: "block",
                          background: "#0C629B 0% 0% no-repeat padding-box",
                          border: "1px solid #0C629B",
                          borderRadius: "4px",
                          color: "#FFFFFF",
                          width: "8.4vw",
                          height: "2.29vw",
                          marginRight: "1.3vw",
                          font: "normal normal normal 0.78vw Helvetica",
                          textTransform: "none",
                        }
                  }
                >
                  Approve
                </Button>
                <Button
                  color="primary"
                  className="reject-btn-pid"
                  onClick={handleReject}
                  disabled={isAccepted || readonly}
                  style={
                    isAccepted || readonly
                      ? {
                          opacity: 0.5,
                          display: "block",
                          background: "#0C629B 0% 0% no-repeat padding-box",
                          border: "1px solid #0C629B",
                          borderRadius: "4px",
                          color: "#FFFFFF",
                          width: "8.4vw",
                          height: "2.29vw",
                          marginRight: "1.3vw",
                          font: "normal normal normal 0.78vw Helvetica",
                          textTransform: "none",
                        }
                      : {
                          display: "block",
                          background: "#0C629B 0% 0% no-repeat padding-box",
                          border: "1px solid #0C629B",
                          borderRadius: "4px",
                          color: "#FFFFFF",
                          width: "8.4vw",
                          height: "2.29vw",
                          marginRight: "1.3vw",
                          font: "normal normal normal 0.78vw Helvetica",
                          textTransform: "none",
                        }
                  }
                >
                  Reject
                </Button>
              </div>
            </div>
          </div>
        </div>
      
      <Modal
        className="rejectRemarks"
        show={show1}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="fullmodal"
        size="l"
        centered
      >
        <Modal.Body>
          <label>Enter Remarks</label>
          <input type="textarea" name="textValue" id="remarks-field" />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={handleCloseAPI}
            className="next-btn"
            style={{
              font: "normal normal normal 0.78vw Helvetica",
              textTransform: "capitalize",
            }}
          >
            Submit
          </Button>
          <Button
            variant="secondary"
            className="cancel-btn"
            onClick={handleCloseBtn}
            style={{
              width: "6em !important",
              background: "#FFFFFF !important",
              border: "1px solid #0C629B !important",
              borderRadius: "4px",
              color: "#0C629B !important",
              font: "normal normal normal 0.78vw Helvetica",
              textTransform: "capitalize",
            }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <AppFooter />
      <Modal
        className="expand-window-MTO"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="fullmodal-mto"
        size="xl"
        centered
        style={{paddingRight:"18vw", minWidth:"50vw !important",maxWidth:"86vw !important"}}
      >
        <Modal.Header>
          <CloseIcon onClick={handleClose} className="close-icon-mto" />
        </Modal.Header>
        <Modal.Body>
          <div className="MTO-table">
            {newRows.length > 0 ? <FilterRenderer rowsData = {newRows} selected={selectedAsset}/> : ''}
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        className="user-select-popup"
        show={duplicateMsg}
        onHide={handleDuplicate}
        backdrop="static"
        keyboard={false}
        dialogClassName="fullmodal"
        size="l"
        centered
      >
        <Modal.Body>
          <label
            style={{
              font: "normal normal bold 1vw Helvetica",
              textAlign: "left",
            }}
          >
            Please delete duplicate file.
            <IconButton
              onClick={handleDuplicate}
              style={{
                padding: "3px",
                position: "absolute",
                top: "0",
                right: "0",
                background: "",
                borderRadius: "0",
                color: "#0C629B",
              }}
            >
              {" "}
              <CloseIcon style={{ width: "1.5vw", height: "1.5vw" }} />{" "}
            </IconButton>
          </label>
        </Modal.Body>
      </Modal>
      {
        save ? <SaveDetails exitPage={exitPage} saveDetails={saveDetails}></SaveDetails> : ""
      }
    </div>
  );
}
