import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import AppHeader from "../../components/AppHeader/AppHeader";
import AppFooter from "../../components/AppFooter/AppFooter";
import ErrorBoundary from "../../components/Errorlog/ErrorBoundary";
import UserStore from "../../components/UserStore";
import { observer } from "mobx-react-lite";
import { loginRequest } from "../../authConfig";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal} from "@azure/msal-react";
import "./Home.scss";

export function Home() {
  const history = useHistory();
  // create msal instace 
  const { instance } = useMsal();

  let name = UserStore.accountName;

  function navigateToWorkspaceSR() {
    history.push("/Workspace");
  }

  function navigateToWorkspacePID() {
    localStorage.setItem("perwsdPageData","10");
    UserStore.setPerwsdPageData("10");
    localStorage.setItem("perPageData","10");
    history.push("/WorkspacePID");
  }

  // msal login function
  const Login = () => {
    instance.loginRedirect(loginRequest).catch((e) => {
    });
  };


  return (
    <div>
      <AuthenticatedTemplate>
        <ErrorBoundary>
          <div className="home-page">
            <AppHeader />
            <main className="main main--home">
              <div className="home-page-wrapper">
                <div className="welcome-msg">
                  <h6 style={{ fontSize: "1.25vw" }}>
                    Hi <b>{name},</b> please select the use case you would like
                    to visit!
                  </h6>
                </div>
                <div className="usecase-links-row">
                  <div
                    className="usecase-link StampRecognition"
                    onClick={navigateToWorkspaceSR}
                  >
                    <svg
                      className="SR-svg"
                      xmlns="http://www.w3.org/2000/svg"
                      width="59.129"
                      height="50.682"
                      viewBox="0 0 59.129 50.682"
                    >
                      <defs>
                        <style>.a</style>
                      </defs>
                      <g transform="translate(76.17 -339.335)">
                        <g transform="translate(-76.17 339.335)">
                          <path
                            className="a"
                            d="M59.017,18.782a2.112,2.112,0,0,0,2.112-2.112V6.112A2.112,2.112,0,0,0,59.017,4H50.57a2.112,2.112,0,0,0-2.112,2.112,2.112,2.112,0,0,1-4.223,0A2.112,2.112,0,0,0,42.123,4H35.788a2.112,2.112,0,0,0-2.112,2.112,2.112,2.112,0,0,1-4.223,0A2.112,2.112,0,0,0,27.341,4H21.006a2.112,2.112,0,0,0-2.112,2.112,2.112,2.112,0,0,1-4.223,0A2.112,2.112,0,0,0,12.559,4H4.112A2.112,2.112,0,0,0,2,6.112V16.67a2.112,2.112,0,0,0,2.112,2.112,2.112,2.112,0,1,1,0,4.223A2.112,2.112,0,0,0,2,25.117v8.447a2.112,2.112,0,0,0,2.112,2.112,2.112,2.112,0,1,1,0,4.223A2.112,2.112,0,0,0,2,42.011V52.57a2.112,2.112,0,0,0,2.112,2.112h8.447A2.112,2.112,0,0,0,14.67,52.57a2.112,2.112,0,0,1,4.223,0,2.112,2.112,0,0,0,2.112,2.112h6.335a2.112,2.112,0,0,0,2.112-2.112,2.112,2.112,0,1,1,4.223,0,2.112,2.112,0,0,0,2.112,2.112h6.335a2.112,2.112,0,0,0,2.112-2.112,2.112,2.112,0,1,1,4.223,0,2.112,2.112,0,0,0,2.112,2.112h8.447a2.112,2.112,0,0,0,2.112-2.112V42.011A2.112,2.112,0,0,0,59.017,39.9a2.112,2.112,0,1,1,0-4.223,2.112,2.112,0,0,0,2.112-2.112V25.117a2.112,2.112,0,0,0-2.112-2.112,2.112,2.112,0,1,1,0-4.223Zm-2.112-3.864a6.335,6.335,0,0,0,0,11.952v4.941a6.335,6.335,0,0,0,0,11.952v6.694H52.323a6.335,6.335,0,0,0-11.952,0h-2.83a6.335,6.335,0,0,0-11.952,0h-2.83a6.335,6.335,0,0,0-11.952,0H6.223V43.764a6.335,6.335,0,0,0,0-11.952V26.87a6.335,6.335,0,0,0,0-11.952V8.223h4.582a6.335,6.335,0,0,0,11.952,0h2.83a6.335,6.335,0,0,0,11.952,0h2.83a6.335,6.335,0,0,0,11.952,0h4.582Z"
                            transform="translate(-2 -4)"
                            fill="#0c629b"
                          />
                          <path
                            className="a"
                            d="M39.676,10H10.112A2.112,2.112,0,0,0,8,12.112V33.229a2.112,2.112,0,0,0,2.112,2.112H39.676a2.112,2.112,0,0,0,2.112-2.112V12.112A2.112,2.112,0,0,0,39.676,10ZM12.223,31.117V26.894h6.335a2.112,2.112,0,1,0,0-4.223H12.223V20.559H22.782a2.112,2.112,0,0,0,0-4.223H12.223V14.223H37.564v2.112H31.229a2.112,2.112,0,0,0,0,4.223h6.335V22.67H27.006a2.112,2.112,0,0,0,0,4.223H37.564v4.223Z"
                            transform="translate(4.67 2.67)"
                            fill="#0c629b"
                          />
                        </g>
                      </g>
                    </svg>
                    <p>Stamp Recognition</p>
                  </div>
                  <div
                    className="usecase-link PID"
                    onClick={navigateToWorkspacePID}
                  >
                    <svg
                      className="PID-svg"
                      xmlns="http://www.w3.org/2000/svg"
                      width="57.052"
                      height="49.552"
                      viewBox="0 0 57.052 49.552"
                    >
                      <defs>
                        <style>.a</style>
                      </defs>
                      <g transform="translate(-59.5 -269.5)">
                        <path
                          className="a"
                          d="M52.708,53.552c-2.6,0-4.671-1.672-4.671-3.776v-41a2.047,2.047,0,0,0-4,0v41c0,2.1-2.069,3.776-4.671,3.776s-4.671-1.672-4.671-3.776v-41a1.807,1.807,0,0,0-2-1.618,1.807,1.807,0,0,0-2,1.618v41c0,2.1-2.069,3.776-4.671,3.776s-4.671-1.672-4.671-3.776v-41a1.807,1.807,0,0,0-2-1.618,1.807,1.807,0,0,0-2,1.618v41c0,2.1-2.069,3.776-4.671,3.776S8,51.88,8,49.776V5h2.669V49.776a1.807,1.807,0,0,0,2,1.618,1.807,1.807,0,0,0,2-1.618v-41c0-2.1,2.069-3.776,4.671-3.776s4.671,1.672,4.671,3.776v41a1.807,1.807,0,0,0,2,1.618,1.807,1.807,0,0,0,2-1.618v-41C28.019,6.672,30.087,5,32.69,5s4.671,1.672,4.671,3.776v41a1.807,1.807,0,0,0,2,1.618,1.807,1.807,0,0,0,2-1.618v-41c0-2.1,2.069-3.776,4.671-3.776s4.671,1.672,4.671,3.776v41a2.047,2.047,0,0,0,4,0v-41C54.71,6.672,56.779,5,59.381,5s4.671,1.672,4.671,3.776V53.552H61.383V8.776a2.047,2.047,0,0,0-4,0v41C57.379,51.88,55.311,53.552,52.708,53.552Z"
                          transform="translate(52 265)"
                          fill="#0c629b"
                          stroke="#0c629b"
                        />
                      </g>
                    </svg>
                    <p>P & ID</p>
                  </div>
                </div>
              </div>
            </main>
            <AppFooter />
          </div>
        </ErrorBoundary>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <Login />
      </UnauthenticatedTemplate>
    </div>
  );
}

export default observer(Home);
