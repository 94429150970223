import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import cellEditFactory from 'react-bootstrap-table2-editor';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import axios from 'axios';
import AppHeader from '../AppHeader/AppHeader';
import AppFooter from '../AppFooter/AppFooter';
import BreadCrumbs from '../Upload/BreadCrumbs/BreadCrumbs';
import TextFieldMui from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";

const API_Endpoint = process.env.REACT_APP_API_ENDPOINT;

const styles = muiTheme => ({
    label: {
        "&$erroredLabel": {
            color: "black"
        }
    },
    erroredLabel: {},
});
const TextField = withStyles(styles)(function TextField({ classes, ...props }) {
    return (
        <TextFieldMui
            InputLabelProps={{
                classes: {
                    root: classes.label,
                    error: classes.erroredLabel
                }
            }}
            {...props}
        />
    );
});
export class Buisnessrule extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modalId: "",
            myrow: [],
            deleteId: [],
            deletedRow: [],
            columns: [
                { dataField: 'id', text: 'ProjectId' },
                { dataField: 'Wname', text: 'ProjectName' },
                { dataField: 'Pname', text: 'Phrase1' },
                { dataField: 'Reports', text: 'Phrase2' },
                { dataField: 'users', text: 'Phrase3' },
                { dataField: 'upload', text: 'Phrase4' },
                { dataField: 'ChangeOutcome', text: 'ChangeOutcome' },
            ],
            products: [],
            reportDataexcel: [],
            newRow: {
                id: "",
                Wname: "",
                Pname: "",
                users: "",
                More: <div classname="more-actions-wrapper" style={{ display: "inline-flex" }}>
                    <div classname="edit-icon" onClick={this.handleEdit} style={{ marginLeft: "-40%", cursor: "pointer" }}>
                        <img src="/images/icon_edit.svg" />
                    </div>
                    <div classname="delete-icon" style={{ marginLeft: "70%", cursor: "pointer" }} onClick={(e) => { if (window.confirm('Are you sure you wish to delete this item?')) this.handlePurge(e) }}>
                        <img src="/images/icon_delete.svg" />
                    </div>
                </div>
            }
        }
    }

    async componentDidMount() {
        try {

            axios.get
                (API_Endpoint + `/GetWorkSpaces`)
                .then(res => {
                    const rows1 = res.data;
                    {
                        rows1.map((items, index) => {
                            const row2 = {
                                id: items.workSpaceId,
                                Wname: items.workSpaceName,
                                Pname: items.projectName,
                                users: items.members.join(","),
                                More: <div classname="more-actions-wrapper" style={{ display: "inline-flex" }}>
                                    <div classname="edit-icon" onClick={this.handleEdit} style={{ marginLeft: "-40%", cursor: "pointer" }}>
                                        <img id={items.workSpaceId} src="/images/icon_edit.svg" />
                                    </div>
                                    <div classname="delete-icon" style={{ marginLeft: "70%", cursor: "pointer" }} onClick={this.state.userFlag ? (e) => { if (window.confirm('Are you sure you wish to delete this item?')) this.handlePurge(e) } : ""}>
                                        <img id={items.workSpaceId} src="/images/icon_delete.svg" />
                                    </div>
                                </div>
                            }
                            this.state.myrow.push(row2)
                        }
                        )
                        this.setState({
                            count: 1
                        })
                    }
                    this.setState({
                        products: this.state.myrow
                    })
                })
        }
        catch (error) {
            if (error.response) {
                // console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
            } else if (error.request) {
                // console.log(error.request);
            } else {
                // console.log('Error', error.message);
            }
        }
    }

    handleEdit = (e) => {
        let id = e.target.id;
        this.setState({
            setShow: true,
            modalId: id
        });
        const data = this.state.products.filter((item) => item.id === id).map(({ id, Wname, users, Pname }) => ({ id, Wname, users, Pname }));
        this.setState({
            editProducts: data
        })
    }

    handleDelete = (e) => {
        let id = e.target.id;
    }

    handleClose = () => {
        this.setState({ setShow: false });
    };

    handleShow = () => {
        this.setState({ setShow: true });
    }

    handleSave = () => {
        // send the output data to the service
        const result = this.state.products.filter((row) => row.id === this.state.modalId)
        const data = {
            "workSpaceId": result[0].id,
            "workSpaceName": result[0].Wname,
            "projectName": result[0].Pname,
            "areaId": 1
        }

        let value = 0;
        // input validations	
        if (data.projectName === "") {
            value = value + 1;
            this.setState({ projectNameError: true, projectNameMessage: "Project Name should not be empty" });
        }
        if (data.workSpaceName === "") {
            value = value + 1;
            this.setState({ workSpaceError: true, workSpaceMessage: "Work Space should not be empty" });
        }
        if ((/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(data.workSpaceName))) {
            value = value + 1;
            this.setState({ workSpaceError: true, workSpaceMessage: "work space name should not contain special characters" });
        }
        if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(data.projectName)) {
            value = value + 1;
            this.setState({ projectNameError: true, projectNameMessage: "project name should not contain special characters" });
        }
        if (value === 0) {
            axios.post(API_Endpoint + `/EditWorkSpace`, data)
                .then(res => {
                    if (res.statusText === 'OK') {
                        this.FetchData();
                    }
                })
            this.setState({
                products: [],
                setShow: false
            });

        }
    }

    // open the new Create Workspace page
    Openworkspace = () => {
        this.setState({ showForm: true });
    }

    handleChange1 = (event) => {
        if (event.target.name === "Wname") {
            this.state.newRow.Wname = event.target.value;
        } else if (event.target.name === "users") {
            this.state.newRow.users = event.target.value;
        } else {
            this.state.newRow.Pname = event.target.value;
        }
    };

    handleOnSelect = (row, isSelect) => {
        this.props.history.push(`${this.props.match.path}/Upload`)
    }

    handlePurge = () => {
    };

    render() {

        const selectRow = {
            mode: 'checkbox',
            bgColor: 'blue',
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.deletedRow = row;
                if(isSelect){
                this.state.deleteId.push(row.id)
                }
                else{
                    const check = this.state.deleteId.filter((rows) => rows != row.id)
                    this.state.deleteId = [];
                    this.state.deleteId = [...check]
                    var array = [...this.state.deleteId];
                    var index = array.indexOf(row.id)
                    if (index !== -1) {
                        array.splice(index, 1);
                        this.setState({deleteId: array});
                      }
                }
            },

            onSelectAll: (isSelect, rows, e) => {
                this.setState({
                })
            }

        };

        const cellEdit = cellEditFactory({
            mode: 'click',
            blurToSave: true

        });

        return (
            <div className="workspace-container">
                <AppHeader />
                <BreadCrumbs />
                <div className="workspace-wrapper">
                    <div className="worspace-titlebar">
                        <div className="workspace-title">BuisnessRules</div>
                        <div className="create-new-workspace-wrapper" style={{display:"flex",marginRight:"-3vw"}}>
                            <Button variant="contained" align="right" class="next-btn" onClick={this.handleEdit}>Add row</Button>
                            <Button variant="contained" align="right" class="next-btn" onClick={this.handlePurge}>Delete row</Button>
                            <Button variant="contained" align="right" class="next-btn" onClick={this.handleSave}>Save</Button>
                        </div>

                    </div>
                    <div className="workspace-data-table-wrapper">
                        <BootstrapTable className="workspace-data-table" keyField='id' data={this.state.products} columns={this.state.columns} cellEdit={cellEdit} selectRow={selectRow} />
                    </div>



                    <Dialog open={this.state.setShow} onClose={this.handleClose} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">Edit Workspace</DialogTitle>
                        <DialogContent>
                            <TextField error={this.state.workSpaceError} autoFocus margin="dense" id={this.state.modalId} name="Wname" label="ProjectId" type="text" onChange={(e) => this.handleChange1(e)} fullWidth helperText={this.state.workSpaceMessage} />
                            <TextField error={this.state.projectNameError} margin="dense" id={this.state.modalId} name="name" label="projectname" onChange={(e) => this.handleChange1(e)} fullWidth helperText={this.state.projectNameMessage} />
                            <TextField margin="dense" id={this.state.modalId} name="users" label="Pharese1" onChange={(e) => this.handleChange1(e)} fullWidth />
                            <TextField margin="dense" id={this.state.modalId} name="users" label="Pharese2" onChange={(e) => this.handleChange1(e)} fullWidth />
                            <TextField margin="dense" id={this.state.modalId} name="users" label="Pharese3" onChange={(e) => this.handleChange1(e)} fullWidth />
                            <TextField margin="dense" id={this.state.modalId} name="users" label="Pharese4" onChange={(e) => this.handleChange1(e)} fullWidth />
                            <TextField margin="dense" id={this.state.modalId} name="users" label="changeOutcome" onChange={(e) => this.handleChange1(e)} fullWidth />
                        </DialogContent>
                        <DialogActions>
                            <Button style={{ border: "1px solid #4B4A55", background: '#0C629B', color: '#fff', width: '6em' }} onClick={this.handleSave} color="primary">Save</Button>
                            <Button style={{ border: "1px solid #4B4A55" }} onClick={this.handleClose} color="primary">Cancel</Button>
                        </DialogActions>
                    </Dialog>
                </div>
                <AppFooter />
            </div>
        );
    }

}

export default Buisnessrule;
