import { graphConfig } from "./authConfig";

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken 
 */
export async function profilePic(accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");

    const options = {
        method: "GET",
        headers: headers
    };

    //graph profile pic API
    return fetch(graphConfig.graphProfiePicEndpoint, options)
        .then(response => response)
        .catch(error => console.log(error));
}
