import React, { Component } from 'react';
import BreadCrumbs from '../../Upload/BreadCrumbs/BreadCrumbs';
import AppHeader from '../../AppHeader/AppHeader';
import Upload from '../Upload/Upload';

export default function UploadFiles() {

  return (
    <div className="Upload-PID-main-container">
      <AppHeader />
      <BreadCrumbs />
      <div>
        <Upload />
      </div>
    </div>
  );
}