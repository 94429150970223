import React, { useEffect, useRef } from "react";
import { Rect, Transformer } from "react-konva";
import {Transform}  from "../../../redux/actions/documentActions";
export default function BoundingBox(props) {
  const isSelected = props.isSelected;
  const shapeRef = useRef();
  const transformerRef = useRef();
  const dispatch = props.dispatcher;
  useEffect(() => {
    if (isSelected) {
      transformerRef.current.nodes([shapeRef.current]);
      transformerRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  const mouseoverCursor = (e) => {
    const container = e.target.getStage().container();
    container.style.cursor = "pointer";
  };

  const onMouseOutCursor = (e) => {
    const container = e.target.getStage().container();
    container.style.cursor = "default";
  };

  function handleChange(event) {
    const properties = event.target.attrs;
    const scaleX = shapeRef.current.scaleX();
    const scaleY = shapeRef.current.scaleY();
    let prevWidth = shapeRef.current.width();
    let prevHeight = shapeRef.current.height();
    if(properties.shortCode === "VBF") {
      prevWidth = properties.oldWidth;
    }
    if(properties.shortCode === "SB") {
      prevHeight = properties.oldHeight;
    }
    const width = prevWidth * scaleX;
    const height = prevHeight * scaleY;

    const coords = {
      XMin: Math.floor(shapeRef.current.x() * props.width),
      YMin: Math.floor(shapeRef.current.y() * props.height),
      XMax: Math.floor((shapeRef.current.x() + width) * props.width),
      YMax: Math.floor((shapeRef.current.y() + height) * props.height),
    };
   
    const transformRectData = {
      Coords: coords,
      lineNumber: properties.lineNumber,
      assetType: "Symbols",
      id: properties.id,
      ShortCode: properties.shortCode,
      assetComponentType: properties.assetComponentType,
      IsJacketedLine:properties.IsJacketedLine,
      Size1: properties.Size1,
      Size2: properties.Size2,
      InsulationType:properties.InsulationType,
      AgUg:properties.AgUg,
      Seat: properties.Seat,
      ValveBody: properties.ValveBody ,
      ExtendedBonet:properties.ExtendedBonet,
      LimitSwich:properties.LimitSwich,
      Hold: properties.Hold,
      Spec: properties.Spec,
      SymbolId: properties.assetID,
      Fluid: properties.Fluid,
      HdrSize: properties.HdrSize,
      Quantity: properties.Quantity,
      SequenceNumber: properties.SequenceNumber,
    };
    dispatch(Transform(transformRectData));
  }

  return (
    <>
      <Rect
        {...props.shape}
        onMouseEnter={mouseoverCursor}
        onMouseOut={onMouseOutCursor}
        ref={shapeRef}
        draggable={true}
        onTransformEnd={handleChange}
        onDragEnd={handleChange}
      />
      {isSelected && (
        <Transformer
          anchorSize={5}
          enabledAnchors={[
            "middle-left",
            "middle-right",
            "top-center",
            "bottom-center",
          ]}
          borderStroke="#6699cc"
          borderStrokeWidth={3}
          rotateEnabled={true}
          keepRatio={false}
          ref={transformerRef}
        />
      )}
    </>
  );
}
