import {
  action,
  computed,
  makeObservable,
  autorun,
  runInAction,
  observable,
} from "mobx";

class UserStore {
  fileSucceded = [];
  fileSuccededPID = [];
  proposalno = 0;
  percentage = 0;
  droppedFiles = [];
  droppedPIDFiles = [];
  selectedFiles = [];
  selectedfilesforupload = [];
  rejectedfilesforupload = [];
  reportData = [];
  createWorkspacedata = {};
  editWorkspacedata = {};
  UploadDateForProcess = "";
  UploadRromDateForProcess = "";
  PIDToDate = "";
  editRow = [];
  editRowPID = [];
  PIDselectedRowID = [];
  selectedRowID = [];
  userGroup = [];
  addUserforWorkspace = [];
  addUserforWorkspacePID = [];
  splitedFileId = 0;
  sheetId = 0;
  workspaceId = 0;
  regExpEditID="";
  listOfCodesEditID="";
  columnsortFlag = false;
  createworkspaceflag = false;
  reportstamp = false;
  stampFilterRetain = false;
  PIDFilterRetain = false;
  accountName = "";
  showprocessgrid = 0;
  showprocessgridPID = 0;
  countforProgress = 0;
  countforProgressPID = 0;
  accesstoken = "";
  AccountInfoValue = "";
  PIDworkspaceID = 0;
  editworkspace = false;
  editworkspacePID = false;
  inBusinessrule = false;
  percentagePID = 0;
  workspaceName = "";
  workspaceNamePID = "";
  NewworkspaceName = "";
  NewProjectName = "";
  NewModelName = "";
  setDetectionQuery = "";
  newWorkspaceId = 0;
  userEmail = "";
  readOnly = false;
  readOnlyPID = false;
  inBusinessrule = false;
  isTickBasedIsometric = false;
  accepteddoc = false;
  accepteddocpid = false;
  PIDworkspaceData = [];
  PIDProcessData = [];
  processData = [];
  PIDwsData = [];
  filterProcessConfiguration = {};
  PIDfilterProcessConfiguration = {};
  stampSearchData = [];
  stampFilterData = [];
  PIDFilterData = [];
  PIDSearchData = [];
  PIDGlobalAdmin = false;
  confidId = 1;
  editConfigData = [];
  perPageData = localStorage.getItem("perPageData") == null ? "10" : localStorage.getItem("perPageData");;
  perwsdPageData = localStorage.getItem("perwsdPageData") == null ? "10" : localStorage.getItem("perwsdPageData");
  skipData = 0;
  skipwsdData = 0;
  afterSearch = "";
  IsTickBasedCreateWS = false;
  isRegExp = false;
  isExclusionRule = false;
  isGlobalListofCodes = true;
  regExpFields = [];
  accuracyPID = [];
  filesFilterPID = "All";
  currentPage = (this.skipData / this.perPageData) + 1;
  loader = false;
  processGridData = [];
  constructor() {
    makeObservable(this, {
      processGridData: observable,
      loader: observable,
      fileSucceded: observable,
      selectedfilesforupload: observable,
      rejectedfilesforupload: observable,
      reportstamp: observable,
      createWorkspacedata: observable,
      editWorkspacedata: observable,
      IsTickBasedCreateWS: observable,
      isRegExp: observable,
      selectedFiles: observable,
      UploadDateForProcess: observable,
      UploadRromDateForProcess: observable,
      PIDToDate: observable,
      fileSuccededPID: observable,
      editworkspace: observable,
      editworkspacePID: observable,
      proposalno: observable,
      inBusinessrule: observable,
      newWorkspaceId: observable,
      stampFilterRetain: observable,
      PIDFilterRetain: observable,
      NewworkspaceName: observable,
      NewProjectName : observable,
      NewModelName:observable,
      droppedFiles: observable,
      droppedPIDFiles: observable,
      reportData: observable,
      editRow: observable,
      editRowPID: observable,
      userGroup: observable,
      percentage: observable,
      createworkspaceflag: observable,
      columnsortFlag: observable,
      splitedFileId: observable,
      sheetId: observable,
      workspaceId: observable,
      accountName: observable,
      PIDselectedRowID: observable,
      selectedRowID: observable,
      showprocessgrid: observable,
      showprocessgridPID: observable,
      countforProgress: observable,
      countforProgressPID: observable,
      accesstoken: observable,
      AccountInfoValue: observable,
      PIDworkspaceID: observable,
      percentagePID: observable,
      workspaceName: observable,
      workspaceNamePID: observable,
      addUserforWorkspace: observable,
      addUserforWorkspacePID: observable,
      setDetectionQuery: observable,
      setUserforWorkspace: observable,
      setUserforWorkspacePID: observable,
      userEmail: observable,
      readOnly: observable,
      readOnlyPID: observable,
      inBusinessrule: observable,
      isTickBasedIsometric: observable,
      accepteddoc: observable,
      accepteddocpid: observable,
      PIDGlobalAdmin: observable,
      PIDworkspaceData: observable,
      PIDProcessData: observable,
      processData: observable,
      PIDwsData: observable,
      filterProcessConfiguration: observable,
      PIDfilterProcessConfiguration: observable,
      stampSearchData: observable,
      stampFilterData: observable,
      PIDFilterData: observable,
      PIDSearchData: observable,
      editConfigData: observable,
      perPageData: observable,
      perwsdPageData: observable,
      skipData: observable,
      skipwsdData: observable,
      afterSearch: observable,
      isExclusionRule: observable,
      regExpEditID: observable,
      isGlobalListofCodes: observable,
      listOfCodesEditID: observable,
      regExpFields: observable,
      currentPage: observable,
      // all the desired actions
      setCurrentPage: action,
      addUser: action,
      addFiles: action,
      SetDetectionQuery: action,      
      addFilesPID: action,
      dropfiles: action,
      dropPIDfiles: action,
      setnewWorkspaceId: action,
      selectedfiles: action,
      selectedFilesforupload: action,
      rejectedFilesforupload: action,
      createWorkspaceflag: action,
      addReportdata: action,
      addRowdata: action,
      addRowdataPID: action,
      setEditworkspace: action,
      setUploadDateForProcess: action,
      setUploadFromDateForProcess: action,
      setPIDToDate: action,
      setEditworkspacePID: action,
      setInbusinessrule: action,
      setSplitedFileId: action,
      setSheetId: action,
      getWorkspaceId: action,
      setaccountName: action,
      getprocessGrid: action,
      getprocessGridPID: action,
      setCount: action,
      setCountPID: action,
      setreportStamp: action,
      setToken: action,
      setAccountInfo: action,
      doColumnsort: action,
      gettheToken: action,
      setPIDWorkspaceId: action,
      getPercentage: action,
      getPercentagePID: action,
      getworkspaceName: action,
      getworkspaceNamePID: action,
      SetNewworkspaceName: action,
      SetNewProjectName: action,
      SetNewModelName:action,
      setUserEmail: action,
      isreadOnly: action,
      isreadOnlyPID: action,
      setInbusinessrule: action,
      setTickBasedIsometric: action,
      isaccepteddoc: action,
      isaccepteddocpid: action,
      changePIDGlobalAdmin: action,
      setPIDselectedRowID: action,
      setSelectedRowID: action,
      setPIDworkspaceData: action,
      setPIDProcessData: action,
      setProcessData: action,
      setPIDwsData: action,
      setStampFilterRetain: action,
      setPIDFilterRetain: action,
      setFilterProcessConfiguration: action,
      setPIDFilterProcessConfiguration: action,
      setStampSearchData: action,
      setStampFilterData: action,
      setPIDFilterData: action,
      setPIDSearchData: action,
      confidId: observable,
      setConfigId: action,
      setEditConfigData : action,
      SetPerPageData: action,
      setPerwsdPageData: action,
      setSkipData: action,
      setSkipwsdData: action,
      setAfterSearch: action,
      setIsRegExp: action,
      setIsTickBasedCreateWS: action,
      setIsExclusionRule: action,
      setRegExpEditID: action,
      setIsGlobalListofCodes: action,
      setListOfCodesEditID: action,
      setRegExpFields: action,
      setAccuracyPID: action,
      setFilesFilterPID: action,
      setLoader: action,
      setProcessGridData: action,
    });
  }

  setProcessGridData(data) {
    this.processGridData = data;
    return this.processGridData;
  }

  setPIDworkspaceData(data) {
    this.PIDworkspaceData = data;
    return this.PIDworkspaceData;
  }

  setAccuracyPID(data) {
    this.accuracyPID = data;
    return this.accuracyPID;
  }
  setFilesFilterPID(data) {
    this.filesFilterPID = data;
    return this.filesFilterPID;
  }

  setRegExpFields(data) {
    this.regExpFields = data;
    return this.regExpFields;
  }

  setListOfCodesEditID(data) {
    this.listOfCodesEditID = data;
    return this.listOfCodesEditID;
  }

  setRegExpEditID(data) {
    this.regExpEditID = data;
    return this.regExpEditID;
  }

  setLoader(value) {
    this.loader = value;
    return this.loader;
  }

  setIsGlobalListofCodes(data) {
    this.isGlobalListofCodes = data;
    return this.isGlobalListofCodes;
  }

  setIsRegExp(data) {
    this.isRegExp = data;
    return this.isRegExp;
  }

  setIsExclusionRule(data) {
    this.isExclusionRule = data;
    return this.isExclusionRule;
  }

  setIsTickBasedCreateWS(data) {
    this.isTickBasedCreateWS = data;
    return this.isTickBasedCreateWS;
  }

  setAfterSearch(data) {
    this.afterSearch = data;
    return this.afterSearch;
  }

  setStampFilterData(data) {
    this.stampFilterData = data;
    return this.stampFilterData;
  }

  setPIDFilterData(data) {
    this.PIDFilterData = data;
    return this.PIDFilterData;
  }

  setPIDSearchData(data) {
    this.PIDSearchData = data;
    return this.PIDSearchData;
  }

  setStampSearchData(data) {
    this.stampSearchData = data;
    return this.stampSearchData;
  }

  setPIDProcessData(data) {
    this.PIDProcessData = data;
    return this.PIDProcessData;
  }

  setProcessData(data) {
    this.processData = data;
    return this.processData;
  }

  setPIDwsData(data) {
    this.PIDwsData = data;
    return this.PIDwsData;
  }

  setFilterProcessConfiguration(data) {
    this.filterProcessConfiguration = data;
    return this.filterProcessConfiguration;
  }

  setPIDFilterProcessConfiguration(data) {
    this.PIDfilterProcessConfiguration = data;
    return this.PIDfilterProcessConfiguration;
  }

  setStampFilterRetain(data) {
    this.stampFilterRetain = data;
    return this.stampFilterRetain;
  }

  setPIDFilterRetain(data) {
    this.PIDFilterRetain = data;
    return this.PIDFilterRetain;
  }
  

  setPIDselectedRowID(data) {
    this.PIDselectedRowID.push(data);
    //this.PIDselectedRowID = data;
    return this.PIDselectedRowID;
  }

  setSelectedRowID(data) {
    this.selectedRowID.push(data);
    //this.PIDselectedRowID = data;
    return this.selectedRowID;
  }

  isaccepteddoc(accepted) {
    this.accepteddoc = accepted;
    return this.accepteddoc;
  }
  isaccepteddocpid(acceptedpid) {
    this.accepteddocpid = acceptedpid;
    return this.accepteddocpid;
  }
  
  changePIDGlobalAdmin(dataField) {
    this.PIDGlobalAdmin = dataField;
    return this.PIDGlobalAdmin;
  }
  getPercentage(percent) {
    this.percentage = percent;
    return this.percentage;
  }

  setProprsal(data){
    this.proposalno = data;
    return this.proposalno;
  }

  getworkspaceName(name) {
    this.workspaceName = name;
    return this.workspaceName;
  }

  getworkspaceNamePID(name) {
    this.workspaceNamePID = name;
    return this.workspaceNamePID;
  }

  setEditworkspace(data){
    this.editworkspace = data;
    return this.editworkspace;
  }

  setUploadDateForProcess(date){
    this.UploadDateForProcess = date;    
    return this.UploadDateForProcess;
  }

  setUploadFromDateForProcess(date){
    this.UploadRromDateForProcess = date;    
    return this.UploadRromDateForProcess;
  }
  setPIDToDate(date){
    this.PIDToDate = date;    
    return this.PIDToDate;
  }

  setEditworkspacePID(data){
    this.editworkspacePID = data;
    return this.editworkspacePID;
  }

  setInbusinessrule(data){
    this.inBusinessrule = data;
    return this.inBusinessrule;
  }

  setnewWorkspaceId(id) {
    this.newWorkspaceId = id;
    return this.newWorkspaceId;
  }

  setworkspaceuploadID(id){
    this.workspaceuploadID = id;
    return this.workspaceuploadID;
  }

  isreadOnly(roleid) {
    this.readOnly = roleid;
    return this.readOnly;
  }

  isreadOnlyPID(roleid) {
    this.readOnlyPID = roleid;
    return this.readOnlyPID;
  }

  SetDetectionQuery(query) {
    this.setDetectionQuery = query;
    return this.setDetectionQuery;
  }

  SetNewworkspaceName(name) {
    this.NewworkspaceName = name;
    return this.NewworkspaceName;
  }

  setEditWorkspacedata(data){
     this.editWorkspacedata = data;
  }

  setCreateWorkspacedata(data){
    this.createWorkspacedata = data;
 }

  SetNewProjectName(name) {
    this.NewProjectName = name;
    return this.NewProjectName;
  }

  SetNewModelName(name) {
    this.NewModelName = name;
    return this.NewModelName;
  }

  getPercentagePID(percent) {
    this.percentagePID = percent;
    return this.percentagePID;
  }

  doColumnsort(val) {
    this.columnsortFlag = val;
    return this.columnsortFlag;
  }

  setToken(token) {
    this.accesstoken = token;
    return this.accesstoken;
  }
  setAccountInfo(value) {
    this.AccountInfoValue = value;
    return this.AccountInfoValue;
  }

  createWorkspaceflag(val) {
    this.createworkspaceflag = val;
  }

  gettheToken() {
    return this.accesstoken;
  }

  setaccountName(name) {
    this.accountName = name;
    return this.accountName;
  }

  setUserEmail(email) {
    this.userEmail = email;
    return this.userEmail;
  }

  getprocessGrid(name) {
    this.showprocessgrid = name;
    return this.showprocessgrid;
  }

  getprocessGridPID(name) {
    this.showprocessgridPID = name;
    return this.showprocessgridPID;
  }

  getWorkspaceId(id) {
    this.workspaceId = id;
    return this.workspaceId;
  }

  setCount(count) {
    this.countforProgress = count;
    return this.countforProgress;
  }

  setCountPID(count) {
    this.countforProgressPID = count;
    return this.countforProgresspid;
  }

  addFiles(newfile) {
    this.fileSucceded.push(newfile);
  }

  emptySuccededFiles() {
    this.fileSucceded = [];
    this.selectedFiles = [];
    this.droppedFiles = [];
    this.selectedfilesforupload = [];
    this.rejectedfilesforupload = [];
    
  }

  emptyRejectedFiles(){
    this.rejectedfilesforupload = [];
    
  }

  emptySuccededFilesPID() {
    this.fileSucceded = [];
    this.selectedFiles = [];
    this.droppedPIDFiles = [];
    this.selectedfilesforupload = [];
  }

  selectedFilesforupload(file) {
    this.selectedfilesforupload = file;
  }

  rejectedFilesforupload(file) {
    this.rejectedfilesforupload = file;
  }

  addFilesPID(newfile) {
    this.fileSuccededPID.push(newfile);
  }

  addReportdata(data) {
    this.reportData = data;
    return this.reportData;
  }
  cleareReportdata () {
    this.reportData = [];
    return this.reportData;
  }

  setreportStamp(data) {
    this.reportstamp = data;
  }

  setUserforWorkspace(data) {
    this.addUserforWorkspace = data;
  }

  setUserforWorkspacePID(data) {
    this.addUserforWorkspacePID = data;
  }

  addUser(data) {
    this.userGroup = data;
    return this.userGroup;
  }

  addRowdata(data) {
    this.editRow = [];
    this.editRow.push(data);    
    return this.editRow;
  }

  addRowdataPID(data) {
    
    this.editRowPID = [];
    this.editRowPID.push(data);
    return this.editRowPID;
  }

  dropfiles(file) {
    this.droppedFiles = file;
  }

  dropPIDfiles(file) {
    this.droppedPIDFiles = file;
  }

  selectedfiles(file) {
    this.selectedFiles = file;
  }

  setSplitedFileId(id) {
    this.splitedFileId = id;
  }

  setSheetId(id) {
    this.sheetId = id;
  }

  setPIDWorkspaceId(id) {
    this.PIDworkspaceID = id;
    return this.PIDworkspaceID;
  }
  setInbusinessrule(data){
    this.inBusinessrule = data;
    return this.inBusinessrule;
  }

  setTickBasedIsometric(value) {
    this.isTickBasedIsometric = value;
    return this.isTickBasedIsometric;
  }

  setConfigId(value) {
    this.confidId = value;
    return this.confidId;
  }

  setEditConfigData(value) {
    this.editConfigData = value;
    return this.editConfigData;
  }

  SetPerPageData(value) {
    this.perPageData = value;
    return this.perPageData;
  }

  setPerwsdPageData(value) {
    this.perwsdPageData = value;
    return this.perwsdPageData;
  }
  setSkipData(value) {
    this.skipData = value;
    return this.skipData;
  }

  setSkipwsdData(value) {
    this.skipwsdData = value;
    return this.skipwsdData;
  }
  setCurrentPage(value) {
    this.currentPage = value;
    return this.currentPage;
  }
}

const myStore = new UserStore();

export default myStore;
