import * as React from "react";
import { withRouter } from "react-router-dom";
import { GridOverlay } from "@material-ui/data-grid";
import "./StampGrid.scss";
import axios from "axios";
import "antd/dist/antd.css";
import UserStore from "../../UserStore";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingOverlay from "react-loading-overlay";
import "react-day-picker/lib/style.css";
import "moment/locale/en-gb";
import moment from "moment";
import { toJS } from "mobx";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Tooltip from "@material-ui/core/Tooltip";
import { ExportToExcel } from "../../UploadPID/Process/ExportToExcel";

var rowget = [];
var filename = "";

const API_Endpoint = process.env.REACT_APP_API_ENDPOINT;
class FileLog extends React.Component {
  
  constructor(props) {
    
    const editWorkSpaceData1 = toJS(UserStore.editRow)[0];
   
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      loading: true,
      responseIsometrics:[],      
      columns: [
        { dataField: "File Name", text: "File Name", width: "300"},
        { dataField: "Status", text: "Status", width: "200" },
        { dataField: "Uploaded By", text: "Uploaded By", width: "200" },
        { dataField: "Upload Date & Time", text: "Upload Date & Time", width: "200" },
        { dataField: "File Uploaded Successfully", text: "File Uploaded Successfully", width: "200"}
      ],
      selectedrowforDeletion: [],
      noOfRowSelected: 0,
      rows1: [],   
      id: "",
      wsId: UserStore.workspaceId,
    };
    localStorage.removeItem('setreportStamp');
    UserStore.setreportStamp(false);
  }


  async componentDidMount() {  
    
    
    UserStore.PIDselectedRowID = [];
    UserStore.selectedRowID = []
    const workspaceId = UserStore.workspaceId;
    const workspaceIdPid = UserStore.PIDworkspaceID;
    
    let res;
    // checking whether the call is from Sr or PId Application
    if(this.props.frompid !== "pid")
    {
      filename = "LogData_" + UserStore.workspaceName;
    res = await axios.get(
      API_Endpoint +
        `/GetFileUploadLogs?workSpaceId=${workspaceId}`
    );
    }
    else{
      filename = "LogData_" + UserStore.workspaceNamePID;
      res = await axios.get(
        API_Endpoint +
          `/GetPidFileUploadLogs?workSpaceId=${workspaceIdPid}`
      );
    }

    this.setState({ responseIsometrics: res.data });

    if (res.status === 400) {
      toast.error("Request error:" + res.data.map((row) => row.error), {
        // Set to 5sec
        position: toast.POSITION.TOP_LEFT,
        autoClose: 5000,
      });
      this.setState({ loading: false });
    } else {
      this.setState({ loading: false });
      let myrows = res.data;
      
      rowget = [];
      if (myrows) {
        const url = window.location.href;
        const splitUrl = url.split("/");
        myrows =(splitUrl[3] === 'WorkspacePID') ? myrows.filter((item) => item.isFileUploadedSuccessfully === false) : myrows;
        myrows.map((items, index) => {
          const row2 = this.dataRows(items, index);
          this.state.rows1.push(row2);
          rowget.push(row2);
        });
      }
      this.setState({
        initarr: rowget,
        fillarr: this.state.rows1,
      });
    }
  }

  CustomNoRowsOverlay() {
    return (
      <GridOverlay>
        <div>No Rows</div>
      </GridOverlay>
    );
  }  

  dataRows = (items, index) => {   
     var row = {      
      "File Name": items.fileName != null ? items.fileName : "",
      Status: items.status != null ? items.status : "",
      "Uploaded By": items.uploadedBy != null ? items.uploadedBy : "",
      "Upload Date & Time":
        items.uploadDateTime != null ? moment.utc(items.uploadDateTime, 'DD/MM/YYYY hh:mm:ss a').local().format('DD/MM/YYYY hh:mm:ss a'): "",
        "File Uploaded Successfully": items.isFileUploadedSuccessfully != null ? (items.isFileUploadedSuccessfully ? 'Yes': 'No' ) : ''
    };  
    return row;
  };

  render() {
        const localurl = window.location.href;
        const splitUrllocal = localurl.split("/");
        return (
      <LoadingOverlay
        className="process-page-loader"
        active={this.state.loading}
        spinner
        text="Loading your content..."
      >
        <div style={{ width: "100%" }} className="fileUploadFaile"> 
          {splitUrllocal[3] === 'WorkspacePID' ? 
         <Tooltip title="Click to Export log data" arrow>
              <span
                
              >              
                  <ExportToExcel style={{marginLeft:"10vw"}} fileName={filename} fromfilelog="Yes" datafromfilelog={this.state.rows1} />  
              </span>
            </Tooltip>     : ''
  }
          <div className="workspace-data-table-wrapper">
                <BootstrapTable
                  className="workspace-data-table"
                  keyField="id"
                  data={this.state.rows1}
                  columns={this.state.columns}
                  pagination={paginationFactory()}
                />
              </div>
        </div>
      </LoadingOverlay>
    );
  }
}

export default withRouter(FileLog);
