import * as React from "react";
import { withRouter } from "react-router-dom";
import { GridOverlay } from "@material-ui/data-grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Modal from "react-bootstrap/Modal";
import Select from "@material-ui/core/Select";
import "./StampGrid.scss";
import axios from "axios";
import "antd/dist/antd.css";
import ExportExcel from "../../ExportExcel";
import UserStore from "../../UserStore";
import Moment from "moment";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingOverlay from "react-loading-overlay";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import "react-day-picker/lib/style.css";
import "moment/locale/en-gb";
import Cancel from "@material-ui/icons/Cancel";
import { toJS } from "mobx";
import { DatePicker } from "antd";
import CircleChecked from "@material-ui/icons/CheckCircleRounded";
import Assessment from "@material-ui/icons/Assessment";
import DataGridPopup from "./DataGridPopup";
import StampGridTable from "../StampGrid/StampGridTable";
import Tooltip from "@material-ui/core/Tooltip";
import BatchTable from "../BatchTable";
import { isUndefined } from "@microsoft/applicationinsights-core-js";

var rowget = [];

// ant datepicker variables
const dateFormat = "DD/MMM/YYYY";
// ends here

// setting start date
const customContentStyle = {
  width: "100%",
  maxWidth: "none",
};
var startdate = "";
var enddate = "";
var startdate1 = "";
var enddate1 = "";
let searchrows = false;
var today = new Date();
today.setDate(today.getDate() - 15);
//var daysToMove = -8;
const APIEndpoint = process.env.REACT_APP_API_ENDPOINT;
startdate1 = Moment(
  today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate()
).format("YYYY-MM-DD");
startdate1 = Moment(
  today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate()
).format("DD/MMM/YYYY");
startdate = startdate1.toString();
const editWorkSpaceData = toJS(UserStore.editRow)[0];
Moment(startdate).format("YYYY-MM-DD");

// setting end date
today.setDate(today.getDate() + 15);
enddate1 = Moment(
  today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate()
).format("DD/MMM/YYYY");

enddate = enddate1.toString();
Moment(enddate).format("YYYY-MM-DD");
const API_Endpoint = process.env.REACT_APP_API_ENDPOINT;
let intervalID;
class StampGrid extends React.Component {
  constructor(props) {
    const editWorkSpaceData1 = toJS(UserStore.editRow)[0];
    if (!editWorkSpaceData1) {
      if (localStorage.getItem("startdateforProcess") === null) {
        startdate = Moment(localStorage.getItem("startdate")).format(
          "DD/MMM/YYYY"
        );
      } else {
        startdate = Moment(localStorage.getItem("startdateforProcess")).format(
          "DD/MMM/YYYY"
        );
      }
      enddate =
        localStorage.getItem("enddateforProcess") === null
          ? Moment(localStorage.getItem("enddate")).format("DD/MMM/YYYY")
          : Moment(localStorage.getItem("enddateforProcess")).format(
              "DD/MMM/YYYY"
            );
    } else {
      const createdOn1 = editWorkSpaceData1.createdOn.toString();
      var stdate = Moment(createdOn1, "DD/MM/YYYY");
      stdate = Moment(stdate).format("DD/MMM/YYYY");
      startdate = stdate;
      enddate1 = Moment(
        today.getFullYear() +
          "-" +
          (today.getMonth() + 1) +
          "-" +
          today.getDate()
      ).format("DD/MMM/YYYY");
      enddate = enddate1.toString();
      Moment(enddate).format("YYYY-MM-DD");
    }
    if (UserStore.UploadDateForProcess) {
      enddate = Moment(UserStore.UploadDateForProcess).format("DD/MMM/YYYY");
    }
    if (UserStore.UploadRromDateForProcess) {
      startdate = Moment(UserStore.UploadRromDateForProcess).format(
        "DD/MMM/YYYY"
      );
    }

    super(props);
    this.tableRef = React.createRef();
    this.state = {
      initvalue: "All",
      deleteshow: false,
      dateValidation: false,
      dateValidationValue: "",
      pdfUri: "",
      selectedrowforDeletion: [],
      noOfRowSelected: 0,
      rows1: [],
      loading: true,
      columns: [
        {
          accessor: "transmittalNumber",
          header: "Transmittal No.",
          width: 170,
        },
        {
          accessor: "documentName",
          header: "Document Name",
          width: 200,
        },
        {
          accessor: "isProcessed",
          header: "Processed",
          width: 150,
          renderCell: this.IsProcessed,
        },
        { accessor: "processedPercentage", header: "Percentage", width: 160 },
        {
          accessor: "isDetected",
          header: "Detected",
          width: 150,
          renderCell: this.isDetected,
        },
        {
          accessor: "lineNumber",
          header: "Line No.",
          width: 150,
          renderCell: this.lineNumber,
        },
        {
          accessor: "isometricNumber",
          header: "Isometric No.",
          width: 170,
        },
        { accessor: "sheetNumber", header: "Sheet No.", width: 140 },
        { accessor: "revisionNumber", header: "Rev. No.", width: 150 },
        {
          accessor: "phrase1",
          header: "Phrase 1",
          width: 140,
          renderCell: this.Phrase1,
        },
        {
          accessor: "phrase2",
          header: "Phrase 2",
          width: 140,
          renderCell: this.Phrase2,
        },
        {
          accessor: "phrase3",
          header: "Phrase 3",
          width: 140,
          renderCell: this.Phrase3,
        },
        {
          accessor: "phrase4",
          header: "Phrase 4",
          width: 140,
          renderCell: this.Phrase4,
        },
        {
          accessor: "isChange",
          header: "Change",
          width: 140,
          renderCell: this.isChange,
        },
        {
          accessor: "isDuplicate",
          header: "Duplicate",
          width: 150,
          renderCell: this.isDuplicate,
        },
        { accessor: "modifiedDoc", header: "Modified Doc.", width: 170 },
        {
          accessor: "processingDate",
          header: "Processing Date Time",
          width: 200,
        },
        {
          accessor: "modiffiedDate",
          header: "Modified Date Time",
          width: 210,
        },
        { accessor: "modifiedBy", header: "Modified By", width: 170 },
        {
          accessor: "acceptanceStatus",
          header: "Acceptance Status",
          width: 200,
        },
        {
          accessor: "confidenceLevel",
          header: "Confidence Level",
          width: 220,
        },
        {
          accessor: "rejectionRemark",
          header: "Rejection Remark",
          width: 220,
        },
      ],
      deletedRows: [],
      selectedRows: [],
      CountAll: 0,
      CountProcessfailed: 0,
      searchvalue: "Search by document name",
      id: "",
      show: false,
      initarr: [],
      fillarr: [],
      startDate: startdate,
      endDate: enddate,
      oldEndDate: "",
      oldStartDate: "",
      showuser: true,
      showDownload: false,
      showBatchStatusDialog: false,
      previewValue: false,
      wsId: UserStore.workspaceId,
      showBatchStatus: false,
      disablePreview: false,
      responseIsometrics: {},
      showBulkAccept: false,
      fileprocessedStatus: true,
      accepteddoc: false,
      errorMEssage: "",
      dropArr: [],
      dropChange: false,
    };
    localStorage.removeItem("setreportStamp");
    UserStore.setreportStamp(false);
  }

  handleCloseBatchStatus = () => {
    this.setState({ showBatchStatusDialog: false });
  };

  // ---------------------start: Function: Show batch details -------------------
  showBatchStatus = async () => {
    let val = 0;
    // API: GET batch details
    const res = axios
      .get(APIEndpoint + `/GetBatchStatus?workSpaceId=${this.state.wsId}`)
      .then((res) => {
        if (res.data.length !== 0) {
          this.setState({ showBatchStatusDialog: true });
        } else {
          this.setState({ showBatchStatus: true });
        }
      });
  };
  // ---------------------End: Function: Show batch details -------------------

  handleCloseBatch = () => {
    this.setState({ showBatchStatus: false });
  };

  handleDateChange1 = (day, datestring) => {
    this.state.oldStartDate = "";
    this.state.oldStartDate = "";
    let date1 = Moment(day).format("DD-MMM-YYYY");
    localStorage.setItem(
      "startdateforProcess",
      Moment(date1).format("DD/MMM/YYYY")
    );
    let date = Moment(day).format("YYYY-MM-DD");
    UserStore.setUploadFromDateForProcess(date);
    this.state.oldStartDate = this.state.startDate;
    this.state.startDate = date;
    this.handleDatechange("start");
  };

  handleDateChange2 = (day, datestring) => {
    this.state.oldStartDate = "";
    this.state.oldStartDate = "";
    let date1 = Moment(day).format("DD-MMM-YYYY");
    localStorage.setItem(
      "enddateforProcess",
      Moment(date1).format("DD/MMM/YYYY")
    );
    let date = Moment(day).format("YYYY-MM-DD");
    UserStore.setUploadDateForProcess(date);
    this.state.oldEndDate = this.state.endDate;
    this.state.endDate = date;
    this.handleDatechange("end");
  };

  handleClose = () => {
    this.setState({ show: false, previewValue: false, errorMEssage: "" });
  };

  handledeleteClose = () => {
    this.setState({ deleteshow: false });
  };

  handleDownloadClose = () => {
    this.setState({ showDownload: false });
  };

  handleBulkAcceptClose = () => {
    this.setState({ showBulkAccept: false });
  };

  handleDateeClose = () => {
    this.setState({ dateValidation: false });
  };

  handleShow = () => {
    let selectedrowstodelete = [];
    selectedrowstodelete = toJS(UserStore.PIDselectedRowID);
    this.state.selectedRows = selectedrowstodelete;
    if (
      this.state.selectedRows[0] === undefined ||
      this.state.selectedRows.length > 1
    ) {
      const messageToSet =
        selectedrowstodelete.length > 1
          ? "Please select only one file to preview."
          : "Please select a file to preview.";
      this.setState({
        show: true,
        previewValue: true,
        errorMEssage: messageToSet,
      });
    }
    if (this.state.selectedRows.length === 1) {
      if (this.state.selectedRows[0].isProcessed === "No") {
        this.setState({
          show: true,
          previewValue: true,
          errorMEssage: "Please select a file which is processed.",
          disablePreview: true,
        });
      } else this.navigateToPreviewStamp();
    }
  };

  // ---------------------------------- Start: Cell styling in table-----------------------
  IsProcessed(params) {
    const isProcessed = params.row.isProcessed;
    return (
      <div className="reports-wrapper">
        {isProcessed === "" ? (
          ""
        ) : isProcessed == "Yes" ? (
          <p>
            <span class="dot"></span> Yes
          </p>
        ) : (
          <p>
            <span class="dot1"></span> No
          </p>
        )}
      </div>
    );
  }

  lineNumber(params) {
    return (
      <Tooltip title={params.row.lineNumber} arrow>
        <div className="linenumber">{params.row.lineNumber}</div>
      </Tooltip>
    );
  }

  isDetected(params) {
    const isDetected = params.row.isDetected;
    return (
      <div className="reports-wrapper">
        {isDetected === "" ? (
          ""
        ) : isDetected == "Yes" ? (
          <p>
            <span class="dot"></span> Yes
          </p>
        ) : (
          <p>
            <span class="dot1"></span> No
          </p>
        )}
      </div>
    );
  }

  isDuplicate(params) {
    const isDuplicate = params.row.isDuplicate;
    return (
      <div className="reports-wrapper">
        {isDuplicate === "" ? (
          ""
        ) : isDuplicate == "Yes" ? (
          <p>
            <span class="dot"></span> Yes
          </p>
        ) : (
          <p>
            <span class="dot1"></span> No
          </p>
        )}
      </div>
    );
  }

  Phrase1(params) {
    const phrase1 = params.row.phrase1;
    return (
      <div className="reports-wrapper">
        {phrase1 === "" ? (
          ""
        ) : phrase1 == "Found" ? (
          "Found"
        ) : (
          <font color="red">Not Found</font>
        )}
      </div>
    );
  }

  Phrase2(params) {
    const phrase2 = params.row.phrase2;
    return (
      <div className="reports-wrapper">
        {phrase2 === "" ? (
          ""
        ) : phrase2 == "Found" ? (
          "Found"
        ) : (
          <font color="red">Not Found</font>
        )}
      </div>
    );
  }

  Phrase3(params) {
    const phrase3 = params.row.phrase3;
    return (
      <div className="reports-wrapper">
        {phrase3 === "" ? (
          ""
        ) : phrase3 == "Found" ? (
          "Found"
        ) : (
          <font color="red">Not Found</font>
        )}
      </div>
    );
  }

  Phrase4(params) {
    const phrase4 = params.row.phrase4;
    return (
      <div className="reports-wrapper">
        {phrase4 === "" ? (
          ""
        ) : phrase4 == "Found" ? (
          "Found"
        ) : (
          <font color="red">Not Found</font>
        )}
      </div>
    );
  }

  checkBox01(params) {
    let cbVal = params.row.labelCheckbox01;
    let label = params.row.checkbox01;
    var splitStr = label.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    label = splitStr.join(" ");

    return (
      <div>
        {label !== "" || cbVal !== "" ? (
          <p>
            <span style={{ padding: "0 0 0 0.3vw" }}>{label}</span>
          </p>
        ) : (
          ""
        )}
      </div>
    );
  }
  checkBox02(params) {
    let cbVal = params.row.labelCheckbox02;
    let label = params.row.checkbox02;
    var splitStr = label.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    label = splitStr.join(" ");
    return (
      <div>
        {label !== "" || cbVal !== "" ? (
          <p>
            <span style={{ padding: "0 0 0 0.3vw" }}>{label}</span>
          </p>
        ) : (
          ""
        )}
      </div>
    );
  }
  checkBox03(params) {
    let cbVal = params.row.labelCheckbox03;
    let label = params.row.checkbox03;
    var splitStr = label.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    label = splitStr.join(" ");
    return (
      <div>
        {label !== "" || cbVal !== "" ? (
          <p>
            <span style={{ padding: "0 0 0 0.3vw" }}>{label}</span>
          </p>
        ) : (
          ""
        )}
      </div>
    );
  }
  checkBox04(params) {
    let cbVal = params.row.labelCheckbox04;
    let label = params.row.checkbox04;
    var splitStr = label.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    label = splitStr.join(" ");
    return (
      <div>
        {label !== "" || cbVal !== "" ? (
          <p>
            <span style={{ padding: "0 0 0 0.3vw" }}>{label}</span>
          </p>
        ) : (
          ""
        )}
      </div>
    );
  }
  // ---------------------------------- End: Cell styling in table-----------------------

  changeHeader() {
    let column = [
      {
        accessor: "transmittalNumber",
        header: "Transmittal No.",
        width: 170,
      },
      {
        accessor: "documentName",
        header: "Document Name",
        width: 200,
      },
      {
        accessor: "isProcessed",
        header: "Processed",
        width: 150,
        renderCell: this.IsProcessed,
      },
      { accessor: "processedPercentage", header: "Percentage", width: 160 },
      {
        accessor: "isDetected",
        header: "Detected",
        width: 150,
        renderCell: this.isDetected,
      },
      {
        accessor: "lineNumber",
        header: "Line No.",
        width: 150,
        renderCell: this.lineNumber,
      },
      {
        accessor: "isometricNumber",
        header: "Isometric No.",
        width: 170,
      },
      { accessor: "sheetNumber", header: "Sheet No.", width: 140 },
      { accessor: "revisionNumber", header: "Rev. No.", width: 150 },
      {
        accessor: "checkbox01",
        header: "Checkbox01 Label",
        width: 200,
        renderCell: this.checkBox01,
      },
      {
        accessor: "labelCheckbox01",
        header: "Checkbox01 Selection",
        width: 200,
      },
      {
        accessor: "checkbox02",
        header: "Checkbox02 Label",
        width: 200,
        renderCell: this.checkBox02,
      },
      {
        accessor: "labelCheckbox02",
        header: "Checkbox02 Selection",
        width: 200,
      },
      {
        accessor: "checkbox03",
        header: "Checkbox03 Label",
        width: 200,
        renderCell: this.checkBox03,
      },
      {
        accessor: "labelCheckbox03",
        header: "Checkbox03 Selection",
        width: 200,
      },
      {
        accessor: "checkbox04",
        header: "Checkbox04 Label",
        width: 200,
        renderCell: this.checkBox04,
      },
      {
        accessor: "labelCheckbox04",
        header: "Checkbox04 Selection",
        width: 200,
      },

      {
        accessor: "isChange",
        header: "Change",
        width: 140,
        renderCell: this.isChange,
      },
      {
        accessor: "isDuplicate",
        header: "Duplicate",
        width: 150,
        renderCell: this.isDuplicate,
      },
      { accessor: "modifiedDoc", header: "Modified Doc.", width: 170 },
      { accessor: "processingDate", header: "Processing Date", width: 200 },
      {
        accessor: "modiffiedDate",
        header: "Modified Date Time",
        width: 210,
      },
      { accessor: "modifiedBy", header: "Modified By", width: 170 },
      {
        accessor: "acceptanceStatus",
        header: "Acceptance Status",
        width: 200,
      },
      { accessor: "confidenceLevel", header: "Confidence Level", width: 220 },
      { accessor: "rejectionRemark", header: "Rejection Remark", width: 220 },
    ];
    this.setState({ columns: column });
  }

  isChange(params) {
    const isChange = params.row.isChange;
    return (
      <div className="reports-wrapper">
        {isChange === "" ? (
          ""
        ) : isChange == "Yes" ? (
          <p>
            <span class="dot"></span> Yes
          </p>
        ) : (
          <p>
            <span class="dot1"></span> No
          </p>
        )}
      </div>
    );
  }

  async componentDidMount() {
    localStorage.setItem(
      "startdateforProcess",
      Moment(this.state.startDate).format("DD/MMM/YYYY")
    );
    localStorage.setItem(
      "enddateforProcess",
      Moment(this.state.endDate).format("DD/MMM/YYYY")
    );
    const user = "Admin";
    if (user === "read") {
      this.setState({
        showuser: false,
      });
    }
    localStorage.removeItem("itemChange");
    const workspaceId = UserStore.workspaceId;
    const editWorkSpaceData = toJS(UserStore.editRow)[0];

    if (!editWorkSpaceData) {
      startdate = localStorage.getItem("startdateforProcess");
      let date = Moment(startdate).format("YYYY-MM-DD");
      var stdate = date;
    } else {
      const createdOn = editWorkSpaceData.createdOn.toString();
      var stdate = Moment(createdOn, "DD-MM-YYYY");
      stdate = Moment(this.state.startDate).format("YYYY-MM-DD");
    }
    this.setState({
      startdate: stdate,
    });

    var etdate = "";
    if (UserStore.UploadDateForProcess) {
      etdate = UserStore.UploadDateForProcess;
    } else {
      etdate = Moment(enddate).format("YYYY-MM-DD");
    }

    if (UserStore.processGridData.length === 0) {
      localStorage.setItem("startDateSR", stdate);
      localStorage.setItem("endDateSR", etdate);
      localStorage.setItem("workspaceIDSR", workspaceId);
      let res = await axios.get(
        API_Endpoint +
          `/GetProcessedIsometrics?fromdate=${stdate}&todate=${etdate}&workSpaceId=${workspaceId}`
      );

      UserStore.setProcessGridData(res.data);
    } else {
      await new Promise((resolve) => setTimeout(resolve, 1));
    }
    var myrows;
    this.setState({ responseIsometrics: UserStore.processGridData });
    myrows = UserStore.processGridData;
    rowget = [];
    let tickBased = false;
    this.setState({ rows1: myrows });
    // if (myrows.length) {
    //   myrows.forEach((items, index) => {
    //     tickBased = items.isTickBasedIsometric;
    //     const row2 = this.dataRows(items);
    //     this.state.rows1.push(row2);
    //     rowget.push(row2);
    //   });
    //   UserStore.setPIDProcessData(rowget);
    // }

    if (tickBased) {
      this.changeHeader();
    }
    this.setState({
      initarr: this.state.rows1,
      fillarr: this.state.rows1,
      loading: false,
    });
    //}
  }
  // Start: Function => To change the grid data when Grid data is refreshed
  async refreshProcessedStatus() {
    let res;
    //var stdate = Moment(startdate).format("YYYY-MM-DD");
    var stdate = Moment(this.state.startDate).format("YYYY-MM-DD");
    var etdate = Moment(enddate).format("YYYY-MM-DD");
    const workspaceId = UserStore.workspaceId;
    this.setState({ rows1: [], loading: true });
    UserStore.setStampFilterRetain(false);
    UserStore.PIDselectedRowID = [];
    UserStore.reportData = [];
    // API: Get processed isometrics cased on selected date range
    res = await axios.get(
      API_Endpoint +
        `/GetProcessedIsometrics?fromdate=${stdate}&todate=${etdate}&workSpaceId=${workspaceId}`
    );

    this.setState({ responseIsometrics: res.data });
    // localStorage.setItem("responseIsometrics",JSON.stringify(res.data));
    UserStore.setProcessGridData(res.data);

    if (res.status === 400) {
      toast.error("Request error:" + res.data.map((row) => row.error), {
        // Set to 5sec
        position: toast.POSITION.TOP_LEFT,
        autoClose: 5000,
      });
    } else {
      const myrows = this.state.responseIsometrics;
      rowget = [];
      let tickBased = false;
      this.setState({ rows1: [] });
      if (myrows.length) {
        this.setState({ rows1: myrows });
      }
      if (tickBased) {
        this.changeHeader();
      }
      this.setState({
        initarr: this.state.rows1,
        fillarr: this.state.rows1,
        deletedRows: [],
        selectedRows: [],
        initvalue: "All",
        loading: false,
      });
      UserStore.setreportStamp(false);
      localStorage.setItem("setreportStamp", false);
      UserStore.setSkipData(0);
    }
  }

  CustomNoRowsOverlay() {
    return (
      <GridOverlay>
        <div>No Rows</div>
      </GridOverlay>
    );
  }

  ShowbatchTable() {
    return <BatchTable></BatchTable>;
  }

  //handling dropdown change for the files
  handleDropdownChange = async (e) => {
    let res;
    //var stdate = Moment(startdate).format("YYYY-MM-DD");
    var stdate = Moment(this.state.startDate).format("YYYY-MM-DD");
    var etdate = Moment(enddate).format("YYYY-MM-DD");
    const workspaceId = UserStore.workspaceId;
    this.setState({ rows1: [], loading: true });
    UserStore.setStampFilterRetain(false);
    UserStore.PIDselectedRowID = [];
    UserStore.reportData = [];
    localStorage.setItem("perPageData", "10");
    UserStore.SetPerPageData("10");
    UserStore.setSkipData(0);
    // API: Get processed isometrics cased on selected date range
    res = await axios.get(
      API_Endpoint +
        `/GetProcessedIsometrics?fromdate=${stdate}&todate=${etdate}&workSpaceId=${workspaceId}`
    );
    this.setState({ responseIsometrics: res.data });
    UserStore.setProcessGridData(res.data);

    if (res.status === 400) {
      toast.error("Request error:" + res.data.map((row) => row.error), {
        // Set to 5sec
        position: toast.POSITION.TOP_LEFT,
        autoClose: 5000,
      });
      this.setState({ loading: false });
    } else {
      this.setState({
        loading: false,
      });
      let myrows = this.state.responseIsometrics;

      if (e.target.value === "Processed") {
        myrows = myrows.filter((row) => {
          return row.isProcessed === "Yes";
        });
      } else if (e.target.value === "Processing Failed") {
        myrows = myrows.filter((row) => {
          return row.isProcessed === "No";
        });
      }
      rowget = [];
      let tickBased = false;
      if (myrows.length) {
        this.setState({ rows1: myrows });
      }
      if (tickBased) {
        this.changeHeader();
      }
      this.setState({
        initarr: rowget,
        fillarr: this.state.responseIsometrics,
        // deletedRows: [],
        // selectedRows: [],
        initvalue: e.target.value,
      });
    }
    console.log(this.state.fillarr);
  };

  dataRows = (items) => {
    let processingDate =
      items.ProcessingDate !== null && items.ProcessingDate !== undefined
        ? items.ProcessingDate.includes("+")
          ? items.ProcessingDate.split("+")
          : items.ProcessingDate
        : "";
    processingDate =
      items.ProcessingDate !== null && items.ProcessingDate !== undefined
        ? items.ProcessingDate.includes("+")
          ? processingDate[0].trim().replaceAll("-", "/")
          : items.ProcessingDate
        : "";
    let modifiedDate = items.ModiffiedDate
      ? items.ModiffiedDate.includes("+")
        ? items.ModiffiedDate.split("+")
        : items.ModiffiedDate
      : "";
    modifiedDate = items.ModiffiedDate
      ? items.ModiffiedDate.includes("+")
        ? modifiedDate[0].trim().replaceAll("-", "/")
        : items.ModiffiedDate
      : "";
    const addPer = UserStore.stampFilterRetain ? "" : "%";
    const row = {
      splitedFileId: items.SplitedFileId != null ? items.SplitedFileId : "",
      transmittalNumber:
        items.TransmittalNumber != null ? items.TransmittalNumber : "",
      documentName: items.DocumentName != null ? items.DocumentName : "",
      isProcessed:
        items.IsProcessed != null
          ? items.IsProcessed === true || items.IsProcessed === "Yes"
            ? "Yes"
            : "No"
          : "",
      processedPercentage: items.ProcessedPercentage
        ? items.ProcessedPercentage + addPer
        : "",
      isDetected:
        items.isDetected != null
          ? items.isDetected === true || items.isDetected === "Yes"
            ? "Yes"
            : "No"
          : "",
      lineNumber: items.LineNumber != null ? items.LineNumber : "",
      isometricNumber:
        items.IsometricNumber != null ? items.IsometricNumber : "",
      sheetNumber: items.SheetNumber != null ? items.SheetNumber : "",
      revisionNumber: items.RevisionNumber != null ? items.RevisionNumber : "",
      phrase1:
        items.phrase1 != null
          ? items.phrase1 === true || items.phrase1 === "Found"
            ? "Found"
            : "Not Found"
          : "",
      phrase2:
        items.phrase2 != null
          ? items.phrase2 === true || items.phrase2 === "Found"
            ? "Found"
            : "Not Found"
          : "",
      phrase3:
        items.phrase3 != null
          ? items.phrase3 === true || items.phrase3 === "Found"
            ? "Found"
            : "Not Found"
          : "",
      phrase4:
        items.phrase4 != null
          ? items.phrase4 === true || items.phrase4 === "Found"
            ? "Found"
            : "Not Found"
          : "",
      isChange:
        items.IsChange != null
          ? items.IsChange === true || items.IsChange === "Yes"
            ? "Yes"
            : "No"
          : "",
      isDuplicate:
        items.IsDuplicate != null
          ? items.IsDuplicate === true || items.IsDuplicate === "Yes"
            ? "Yes"
            : "No"
          : "",
      processingDate:
        items.ProcessingDate != ""
          ? Moment.utc(processingDate, "YYYY/MM/DD hh:mm:ss a")
              .local()
              .format("DD/MM/YYYY hh:mm:ss a")
          : "",
      modiffiedDate:
        items.ModiffiedDate != ""
          ? Moment.utc(modifiedDate, "YYYY/MM/DD hh:mm:ss a")
              .local()
              .format("DD/MM/YYYY hh:mm:ss a")
          : "",
      modifiedBy: items.ModifiedBy != null ? items.ModifiedBy : "",
      modifiedDoc: items.ModifiedDoc != null ? items.ModifiedDoc : "",
      acceptanceStatus:
        items.AcceptanceStatus != null
          ? items.AcceptanceStatus === "Accepted" ||
            items.AcceptanceStatus === "Yes"
            ? "Yes"
            : items.AcceptanceStatus === "Rejected" ||
              items.AcceptanceStatus === "No"
            ? "No"
            : ""
          : "",
      confidenceLevel:
        items.ConfidenceLevel != null ? items.ConfidenceLevel : "",
      checkbox01: items.LabelCheckbox01 != null ? items.LabelCheckbox01 : "",
      checkbox02: items.LabelCheckbox02 != null ? items.LabelCheckbox02 : "",
      checkbox03: items.LabelCheckbox03 != null ? items.LabelCheckbox03 : "",
      checkbox04: items.LabelCheckbox04 != null ? items.LabelCheckbox04 : "",
      labelCheckbox01:
        items.Checkbox01 != null
          ? items.Checkbox01 === true || items.Checkbox01 === "Yes"
            ? "Yes"
            : "No"
          : "",
      labelCheckbox02:
        items.Checkbox02 != null
          ? items.Checkbox02 === true || items.Checkbox02 === "Yes"
            ? "Yes"
            : "No"
          : "",
      labelCheckbox03:
        items.Checkbox03 != null
          ? items.Checkbox03 === true || items.Checkbox03 === "Yes"
            ? "Yes"
            : "No"
          : "",
      labelCheckbox04:
        items.Checkbox04 != null
          ? items.Checkbox04 === true || items.Checkbox04 === "Yes"
            ? "Yes"
            : "No"
          : "",
      rejectionRemark:
        items.RejectionRemark != null ? items.RejectionRemark : "",
      isTickBasedIsometric: items.isTickBasedIsometric,
    };
    return row;
  };

  // For handling the data when date is changed

  handleDatechange = async (findDateChange) => {
    this.setState({
      fillarr: [...this.state.rows1],
    });

    if (
      this.state.startDate === "Invalid date" ||
      this.state.endDate === "Invalid date"
    ) {
      this.setState({
        dateValidation: true,
        dateValidationValue: "Upload Date or To Date cannot be empty",
      });
    } else {
      var fromDate = this.state.startDate;
      fromDate = Moment(fromDate).format("YYYY-MM-DD");
      var toDate = this.state.endDate;
      toDate = Moment(toDate).format("YYYY-MM-DD");
      this.setState({
        endDate: toDate,
      });

      const x = new Date(this.state.startDate);
      const y = new Date(toDate);
      var z = new Date();
      z.setDate(today.getDate());
      Moment(z).format("YYYY/MM/DD");
      if (Moment(x).format("YYYY/MM/DD") > Moment(y).format("YYYY/MM/DD")) {
        if (findDateChange === "start") {
          this.state.startDate = this.state.oldStartDate;
          startdate = Moment(this.state.oldStartDate).format("DD/MMM/YYYY");
          UserStore.setUploadFromDateForProcess(this.state.oldStartDate);
          localStorage.setItem(
            "startdateforProcess",
            Moment(this.state.oldStartDate).format("DD/MMM/YYYY")
          );
          this.state.oldStartDate = "";
        } else {
          this.state.endDate = Moment(this.state.oldEndDate).format(
            "DD/MMM/YYYY"
          );
          UserStore.setUploadDateForProcess(this.state.oldEndDate);
          localStorage.setItem(
            "enddateforProcess",
            Moment(this.state.oldEndDate).format("DD/MMM/YYYY")
          );
          enddate = Moment(this.state.oldEndDate).format("DD/MMM/YYYY");
          this.setState({
            endDate: Moment(this.state.oldEndDate).format("DD/MMM/YYYY"),
          });
          this.state.oldEndDate = "";
        }
        this.setState({
          dateValidation: true,
          dateValidationValue: "To Date cannot be earlier than Upload Date",
        });
      } else if (
        Moment(x).format("YYYY/MM/DD") > Moment(z).format("YYYY/MM/DD")
      ) {
        if (findDateChange === "start") {
          this.state.startDate = this.state.oldStartDate;
          localStorage.setItem(
            "startdateforProcess",
            Moment(this.state.oldStartDate).format("DD/MMM/YYYY")
          );
          startdate = Moment(this.state.oldStartDate).format("DD/MMM/YYYY");
          UserStore.setUploadFromDateForProcess(this.state.oldStartDate);
          this.state.oldStartDate = "";
        } else {
          this.state.endDate = Moment(this.state.oldEndDate).format(
            "DD/MMM/YYYY"
          );
          UserStore.setUploadDateForProcess(this.state.oldEndDate);
          localStorage.setItem(
            "enddateforProcess",
            Moment(this.state.oldEndDate).format("DD/MMM/YYYY")
          );
          enddate = Moment(this.state.oldEndDate).format("DD/MMM/YYYY");
          this.setState({
            endDate: Moment(this.state.oldEndDate).format("DD/MMM/YYYY"),
          });
          this.state.oldEndDate = "";
        }
        this.setState({
          dateValidation: true,
          dateValidationValue: "Upload Date cannot be beyond To Date",
        });
      } else {
        rowget = [];
        this.state.oldStartDate = "";
        this.state.oldStartDate = "";
        const workspaceIdfordropdown = UserStore.workspaceId;
        const workspaceIdDate = UserStore.workspaceId;
        let res;
        enddate = Moment(this.state.endDate).format("DD/MMM/YYYY");
        startdate = Moment(this.state.startDate).format("DD/MMM/YYYY");
        res = await axios.get(
          API_Endpoint +
            `/GetProcessedIsometrics?fromdate=${fromDate}&todate=${toDate}&workSpaceId=${workspaceIdfordropdown}`
        );

        UserStore.setProcessGridData(res.data);
        if (res.status === 200) {
          this.setState({
            rows1: [],
          });
        }

        if (res.data.length != 0) {
          let myrows = res.data;
          rowget = myrows;
          // myrows.map((items, index) => {
          //   const row2 = this.dataRows(items);
          //   rowget.push(row2);
          // });

          this.setState({
            rows1: rowget,
            fillarr: rowget,
          });
        } else {
          const feed = [];
          this.setState({
            rows1: feed,
            fillarr: rowget,
          });
        }
      }
    }
    this.setState({ loading: false });
  };

  OnChangeHandler = () => {
    var searchTerm1 = document.getElementById("searchbox").value;
    if (this.state.dropArr.length > 0 && searchTerm1 === "") {
      this.setState({
        rows1: this.state.dropArr,
      });
    } else if (searchTerm1 === "") {
      this.setState({
        rows1: this.state.initarr,
      });
      this.doSomethingWith();
    }
  };

  keyDownSearch = (e) => {
    if (e.charCode === 13 || e.keyCode === 13) {
      this.doSomethingWith();
    }
  };

  doSomethingWith = () => {
    //const fillarr = [...this.state.rows1];
    let searchTerm = document.getElementById("searchbox").value.trim();

    if (this.state.dropChange === true) {
      let searchData1 = [];
      const dataFromDropdown =
        this.state.dropArr.length > 0 ? this.state.dropArr : this.state.initarr;
      if (
        searchTerm === "Search by document name" ||
        searchTerm === "" ||
        searchTerm.length === 0
      ) {
        searchData1 = dataFromDropdown;
        searchrows = false;
        this.setState({
          rows1: searchData1,
        });
        UserStore.setPIDProcessData(searchData1);
      } else {
        searchrows = true;
        searchData1 = dataFromDropdown.filter((row) => {
          return row.documentName
            .toLowerCase()
            .includes(searchTerm.toLowerCase());
        });

        this.setState({
          rows1: searchData1,
        });
        UserStore.setStampSearchData(searchData1);
        UserStore.setPIDProcessData(searchData1);
      }
      setTimeout(() => {
        searchrows = false;
      }, 1000);
    }

    let searchData = [];
    const dataFromFiltering =
      toJS(UserStore.stampFilterData).length > 0
        ? toJS(UserStore.stampFilterData)
        : this.state.initarr;
    if (
      searchTerm === "Search by document name" ||
      searchTerm === "" ||
      searchTerm.length === 0
    ) {
      searchData = dataFromFiltering;
      searchrows = false;
      this.setState({
        rows1: searchData,
      });
      UserStore.setPIDProcessData(searchData);
    } else {
      searchrows = true;

      searchData = dataFromFiltering.filter((row) => {
        if(row.documentName.toLowerCase().includes(searchTerm.toLowerCase()) || 
          (!isUndefined(row.lineNumber) && row.lineNumber.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (!isUndefined(row.isometricNumber) && row.isometricNumber.toLowerCase().includes(searchTerm.toLowerCase())))
        {
          return row;
        }
      });

      this.setState({
        rows1: searchData,
      });
      UserStore.setStampSearchData(searchData);
      UserStore.setPIDProcessData(searchData);
    }
    setTimeout(() => {
      searchrows = false;
    }, 1000);
  };

  checkallSelected = (e) => {
    let selectedValue = e.rowIds.map((item) => {
      return parseInt(item);
    });
    let itemChange = this.state.rows1.filter((val) =>
      selectedValue.includes(val.id)
    );
    if (
      this.state.fillarr.length === e.rowIds.length ||
      e.rowIds.length === 0
    ) {
      UserStore.setreportStamp(false);
      localStorage.setItem("setreportStamp", false);
    } else {
      UserStore.setreportStamp(true);
      localStorage.setItem("setreportStamp", true);
    }

    UserStore.addReportdata(itemChange);
    localStorage.setItem("itemChange", JSON.stringify(itemChange));
    this.setState({
      noOfRowSelected: e.rowIds.length,
      deletedRows: itemChange,
      selectedRows: itemChange,
    });
  };

  handleRowSelection = async (item) => {
    if (item === undefined || item === null) {
      this.setState({ disablePreview: false });
    } else {
      UserStore.setreportStamp(true);
      localStorage.setItem("setreportStamp", true);
      if (item) {
        this.setState({ id: item.splitedFileId });
        this.state.id = item.splitedFileId;
        if (item.isProcessed === "No") {
          this.state.disablePreview = false;
        } else {
          this.setState({ disablePreview: true });
          UserStore.setreportStamp(true);
          localStorage.setItem("setreportStamp", true);

          this.setState({ id: item.splitedFileId });
        }
      }
    }
  };

  async handleBulkAccept() {
    let ids = [];
    let processedStatus = [];
    const acceptedRows = toJS(UserStore.PIDselectedRowID);
    this.setState({ selectedRows: acceptedRows });
    acceptedRows.map((row) => {
      ids.push(row.splitedFileId);
      processedStatus.push(row.isProcessed);
    });

    if (processedStatus.includes("No")) {
      this.setState({ fileprocessedStatus: false });
    }
    if (ids.length === 0 || processedStatus.includes("No")) {
      this.setState({ showBulkAccept: true });
      return;
    }

    let body = {
      splitedFileIds: ids,
    };

    await axios.post(APIEndpoint + `/AcceptMultipleIsometrics`, body);
    this.refreshProcessedStatus();
  }

  handleDownloadIsometrics = async () => {
    let ids = [];
    const acceptedRows = toJS(UserStore.PIDselectedRowID);
    this.setState({ selectedRows: acceptedRows });
    acceptedRows.map((row) => {
      ids.push(row.splitedFileId);
    });

    if (ids.length === 0) {
      this.setState({ showDownload: true });
      return;
    }

    let body = {
      splitedFileIds: ids,
    };

    // API: Download selected isometrics in zip
    fetch(APIEndpoint + `/DownloadProcessedIsometricsZip`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${UserStore.accesstoken}`,
      },
      body: JSON.stringify(body),
    }).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = `Isometrics_${UserStore.workspaceName}`;
        a.click();
      });
    });
  };

  handleDeleteFiles = () => {
    let selectedrowstodelete = [];
    selectedrowstodelete = toJS(UserStore.PIDselectedRowID);
    this.state.deletedRows = selectedrowstodelete;
    // this.setState({
    //   selectedRows:selectedrowstodelete,
    // })
    if (this.state.deletedRows.length === 0) {
      this.setState({
        deleteshow: true,
      });
    } else {
      this.setState({ deletePopup: true });
    }
  };

  handleDeleteFilesClose = () => {
    this.setState({ deletePopup: false });
  };

  handlePurge = async () => {
    this.setState({ deletePopup: false });
    if (this.state.deletedRows.length >= 1) {
      this.setState({
        rows1: this.state.rows1.filter(
          (r) =>
            this.state.deletedRows.filter(
              (sr) => sr.splitedFileId === r.splitedFileId
            ).length < 1
        ),
      });
      let ids = [];
      this.state.deletedRows.map((row) => {
        ids.push(row.splitedFileId);
      });
      const delrow = ids;
      const data = delrow;

      var res = await axios.post(
        API_Endpoint + `//DeleteProcessedIsometric`,
        data
      );
      this.setState({
        deletedRows: [],
        selectedRows: [],
      });
      UserStore.PIDselectedRowID = [];
      if (res.status === 200) {
        this.refreshProcessedStatus();
      }
    } else {
      this.setState({
        deleteshow: true,
      });
    }
  };
  navigateToPreviewStamp = () => {
    UserStore.setUploadDateForProcess(this.state.endDate);
    UserStore.setUploadFromDateForProcess(this.state.startDate);
    UserStore.setSplitedFileId(this.state.selectedRows[0].splitedFileId);
    if (
      this.state.selectedRows[0].acceptanceStatus === "Accepted" ||
      this.state.selectedRows[0].acceptanceStatus === "Rejected"
    ) {
      UserStore.isaccepteddoc(true);
      localStorage.setItem("accepteddoc", true);
    } else {
      UserStore.isaccepteddoc(false);
      localStorage.setItem("accepteddoc", false);
    }
    localStorage.setItem(
      "splitedFileId",
      this.state.selectedRows[0].splitedFileId
    );
    this.props.history.push(
      `/Workspace/UploadFiles/${this.state.selectedRows[0].documentName}`
    );
  };

  disabledDate = (current) => {
    return current && current > Moment().endOf("day");
  };

  render() {
    const readonly = localStorage.getItem("isreadOnly")
      ? JSON.parse(localStorage.getItem("isreadOnly"))
      : UserStore.readOnly;
    const returnData =
      UserStore.stampFilterRetain === true
        ? toJS(UserStore.PIDProcessData)
        : this.state.rows1;

    UserStore.setPIDProcessData(returnData);
    UserStore.setLoader(false);
    UserStore.addReportdata([]);
    return (
      <LoadingOverlay
        className="process-page-loader"
        active={this.state.loading}
        spinner
        text="Loading your content..."
      >
        <div style={{ width: "100%" }} className="StampGrid">
          <div className="searchbar-modal">
            <input
              autoComplete="off"
              type="text"
              className="search-text-bar"
              id="searchbox"
              placeholder="Search by Document Name or Line No. or Isometric No."
              style={{ fontSize: "1vw", marginLeft: "1vw" }}
              onChange={this.OnChangeHandler}
              onKeyPress={this.keyDownSearch}
            ></input>
            <div className="">
              <button
                className="search-button"
                type="submit"
                onClick={this.doSomethingWith}
              >
                <Tooltip title="Search" arrow>
                  <img src="/images/Search-Icon.svg" className="search-icon" />
                </Tooltip>
              </button>
            </div>

            <span class="date-label-text">Upload Date:</span>
            <DatePicker
              allowClear={false}
              // defaultValue={Moment(startdate, dateFormat)}
              value={Moment(startdate, dateFormat)}
              id="from"
              format={dateFormat}
              disabledDate={this.disabledDate}
              onChange={this.handleDateChange1}
            />

            <span class="date-label-text">To:</span>
            <DatePicker
              // defaultValue={Moment(enddate, dateFormat)}
              value={Moment(enddate, dateFormat)}
              allowClear={false}
              id="to"
              format={dateFormat}
              disabledDate={this.disabledDate}
              onChange={this.handleDateChange2}
            />

            <span class="label-text" style={{ marginLeft: "8vw" }}>
              File Status:
            </span>
            <FormControl style={{ marginRight: "1vw" }}>
              <InputLabel id="demo-simple-select-filled-label"></InputLabel>
              <Select
                value={this.state.initvalue}
                labelId="demo-simple-select-filled-label"
                defaultValue={"All"}
                id="dropdown"
                onChange={this.handleDropdownChange}
              >
                <MenuItem value={"All"}>
                  All({this.state.fillarr.length})
                </MenuItem>
                <MenuItem value={"Processed"}>
                  Processed(
                  {
                    this.state.fillarr.filter(
                      (item) => item.isProcessed === "Yes"
                    ).length
                  }
                  ) &nbsp;<span class="dot"></span>
                </MenuItem>
                <MenuItem value={"Processing Failed"}>
                  Processing Failed(
                  {
                    this.state.fillarr.filter(
                      (item) => item.isProcessed === "No"
                    ).length
                  }
                  ) &nbsp;<span class="dot1"></span>
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          <div class="grid-toolbar-wrapper">
            {toJS(UserStore.PIDselectedRowID).length === 1 ? (
              <Tooltip title="Click to Preview Files" arrow>
                <span
                  onClick={this.handleShow}
                  style={
                    this.state.disablePreview
                      ? { opacity: "1" }
                      : {
                          opacity: "0.5",
                          cursor: "not-allowed",
                          pointerEvents: "none",
                        }
                  }
                >
                  <img src="/images/icon_preview.svg" class="toolbar-icon" />
                  <span id="preview-files-text" class="preview-text">
                    Preview
                  </span>
                </span>
              </Tooltip>
            ) : (
              <Tooltip title="Click to Preview Files" arrow>
                <span onClick={this.handleShow}>
                  <img src="/images/icon_preview.svg" class="toolbar-icon" />
                  <span class="preview-text">Preview</span>
                </span>
              </Tooltip>
            )}
            <Tooltip title="Click to Delete Files" arrow>
              <span
                style={
                  readonly
                    ? { opacity: "0.3", pointerEvents: "none" }
                    : { opacity: "1" }
                }
                onClick={this.handleDeleteFiles}
              >
                <img
                  src="/images/icon_delete_black.svg"
                  class="toolbar-icon"
                  onClick={this.handleDeleteFiles}
                />
                <span class="preview-text" onClick={this.handleDeleteFiles}>
                  Delete
                </span>
              </span>
            </Tooltip>

            <ExportExcel
              className="preview-text"
              id={UserStore.workspaceId}
              fileName={"ProcessedIsometricReport"}
              flag={1}
              fromPreview={false}
              frompid={"sr"}
            >
              <span
                className="upload_text"
                style={{
                  color: "#0C629B",
                  textDecoration: "none",
                  fontSize: "1vw",
                  marginRight: "2vw",
                }}
              >
                Download Report
              </span>
            </ExportExcel>
            <Tooltip title="Click to Download Isometrics" arrow>
              <span
                className="preview-text"
                onClick={this.handleDownloadIsometrics}
              >
                <img
                  src="/images/icon_download_report.svg"
                  class="toolbar-icon"
                  alt="download-icon"
                  style={{ paddingRight: "10px", marginLeft: "2vw" }}
                />
                <span
                  className="upload_text"
                  style={{
                    color: "#0C629B",
                    textDecoration: "none",
                    fontSize: "1vw",
                  }}
                >
                  Download Isometric
                </span>
              </span>
            </Tooltip>
            <Tooltip title="Click to Bulk Accept the processed files" arrow>
              <span
                className="preview-text"
                onClick={this.handleBulkAccept.bind(this)}
                style={
                  readonly
                    ? { opacity: "0.3", pointerEvents: "none" }
                    : { opacity: "1", marginLeft: "-0.8vw" }
                }
              >
                <svg
                  style={{ marginRight: "0.5vw", height: "1.5vw" }}
                  xmlns="http://www.w3.org/2000/svg"
                  enable-background="new 0 0 24 24"
                  height="24"
                  viewBox="0 0 24 24"
                  width="24"
                >
                  <g>
                    <rect fill="none" height="24" width="24" />
                  </g>
                  <g>
                    <g>
                      <path
                        d="M20,3H4C2.9,3,2,3.9,2,5v14c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V5 C22,3.9,21.1,3,20,3z M10,17H5v-2h5V17z M10,13H5v-2h5V13z M10,9H5V7h5V9z M14.82,15L12,12.16l1.41-1.41l1.41,1.42L17.99,9 l1.42,1.42L14.82,15z"
                        fill-rule="evenodd"
                      />
                    </g>
                  </g>
                </svg>
                Bulk Accept
              </span>
            </Tooltip>
            <Tooltip title="Click to view the Batch Status" arrow>
              <span className="preview-text" onClick={this.showBatchStatus}>
                <img
                  class="toolbar-icon"
                  style={{ paddingRight: "10px", marginLeft: "-1vw" }}
                />
                <span
                  className="upload_text"
                  style={{
                    color: "#0C629B",
                    textDecoration: "none",
                    fontSize: "1vw",
                  }}
                >
                  <Assessment
                    style={{
                      color: "#454545",
                      marginRight: "0.5vw",
                      height: "1.5vw",
                    }}
                  />
                  Batch Status
                </span>
              </span>
            </Tooltip>
            <Tooltip title="Click to refresh the Processed Status" arrow>
              <span
                className="preview-text"
                onClick={this.refreshProcessedStatus.bind(this)}
                style={{ marginLeft: "-1vw" }}
              >
                <svg
                  style={{ height: "1vw", marginRight: "0.5vw" }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="24.515"
                  height="23.902"
                  viewBox="0 0 24.515 23.902"
                >
                  <g
                    id="noun_reset_3324862_1_"
                    data-name="noun_reset_3324862 (1)"
                    transform="translate(-140.708 -276.75)"
                  >
                    <g
                      id="Group_5865"
                      data-name="Group 5865"
                      transform="translate(140.973 277)"
                    >
                      <path
                        id="Path_10989"
                        data-name="Path 10989"
                        d="M31.972,12.166a.726.726,0,0,1-.721.633h-.1l-2.266-.3A11.693,11.693,0,1,1,19.68,8a.731.731,0,1,1,0,1.462,10.232,10.232,0,1,0,7.942,3.786L27.5,16.073a.731.731,0,0,1-.726.7h-.034a.721.721,0,0,1-.7-.76l.195-4.536a.073.073,0,0,1,0-.034.375.375,0,0,1,0-.073v-.1a.068.068,0,0,1,0-.029.736.736,0,0,1,.044-.088h0a.648.648,0,0,1,.068-.093h0a.706.706,0,0,1,.151-.132h0a.7.7,0,0,1,.1-.058h0l.1-.034h.287l4.283.57a.731.731,0,0,1,.7.765Z"
                        transform="translate(-7.973 -8)"
                        fill="#000"
                        stroke="#000"
                        stroke-width="0.5"
                      />
                    </g>
                  </g>
                </svg>
                Refresh Grid
              </span>
            </Tooltip>
          </div>
          <Dialog
            className="batchStatusList"
            fullWidth={true}
            maxWidth={"lg"}
            open={this.state.showBatchStatusDialog}
            onClose={this.handleCloseBatchStatus}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              Batch Status
              <IconButton
                onClick={this.handleCloseBatchStatus}
                style={{
                  padding: "0",
                  float: "right",
                  background: "#0C629B",
                  borderRadius: "0",
                  color: "#fff",
                }}
              >
                {" "}
                <CloseIcon />{" "}
              </IconButton>
            </DialogTitle>
            <DialogContent style={{ height: "65vh", width: "100%" }}>
              <div>
                <BatchTable wsId={this.state.wsId} />
              </div>
            </DialogContent>
          </Dialog>

          <Modal
            className="user-select-popup"
            show={this.state.show}
            onHide={this.handleClose}
            backdrop="static"
            keyboard={false}
            dialogClassName="fullmodal"
            size="l"
            centered
          >
            <Modal.Body>
              <label
                style={{
                  font: "normal normal bold 1vw Helvetica",
                  textAlign: "left",
                }}
              >
                {this.state.previewValue
                  ? this.state.errorMEssage
                  : "Please select only one file to preview."}
                <IconButton
                  onClick={this.handleClose}
                  style={{
                    padding: "3px",
                    position: "absolute",
                    top: "0",
                    right: "0",
                    background: "",
                    borderRadius: "0",
                    color: "#0C629B",
                  }}
                >
                  {" "}
                  <CloseIcon style={{ width: "1.5vw", height: "1.5vw" }} />{" "}
                </IconButton>
              </label>
            </Modal.Body>
          </Modal>

          <Dialog
            className="delete-confirm"
            open={this.state.deletePopup}
            onClose={this.handleDeleteFilesClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle
              id="form-dialog-title"
              style={{ height: "0.625em" }}
            ></DialogTitle>
            <DialogContent>
              <div style={{ fontSize: "1vw" }}>Are you sure to delete?</div>
              <IconButton
                onClick={this.handleDeleteFilesClose}
                style={{
                  padding: "0",
                  float: "right",
                  background: "#0C629B",
                  color: "#fff",
                  position: "absolute",
                  right: "15%",
                }}
              >
                {" "}
                <Cancel />{" "}
              </IconButton>
              <IconButton
                onClick={(e) => this.handlePurge(e)}
                style={{
                  padding: "0",
                  background: "#0C629B",
                  color: "white",
                  position: "absolute",
                  right: "2%",
                }}
              >
                {" "}
                <CircleChecked />{" "}
              </IconButton>
            </DialogContent>
          </Dialog>

          <Modal
            className="user-select-popup"
            show={this.state.showBatchStatus}
            onHide={this.handleCloseBatch}
            backdrop="static"
            keyboard={false}
            dialogClassName="fullmodal"
            size="l"
            centered
          >
            <Modal.Body>
              <label
                style={{
                  font: "normal normal bold 1vw Helvetica",
                  textAlign: "left",
                }}
              >
                No batches are created for this workspace.
                <IconButton
                  onClick={this.handleCloseBatch}
                  style={{
                    padding: "3px",
                    position: "absolute",
                    top: "0",
                    right: "0",
                    background: "",
                    borderRadius: "0",
                    color: "#0C629B",
                  }}
                >
                  {" "}
                  <CloseIcon style={{ width: "1.5vw", height: "1.5vw" }} />{" "}
                </IconButton>
              </label>
            </Modal.Body>
          </Modal>

          <Modal
            className="user-select-popup"
            show={this.state.deleteshow}
            onHide={this.handledeleteClose}
            backdrop="static"
            keyboard={false}
            dialogClassName="fullmodal"
            size="l"
            centered
          >
            <Modal.Body>
              <label
                style={{
                  font: "normal normal bold 1vw Helvetica",
                  textAlign: "left",
                }}
              >
                Please select atleast one file to delete.
                <IconButton
                  onClick={this.handledeleteClose}
                  style={{
                    padding: "3px",
                    position: "absolute",
                    top: "0",
                    right: "0",
                    background: "",
                    borderRadius: "0",
                    color: "#0C629B",
                  }}
                >
                  {" "}
                  <CloseIcon style={{ width: "1.5vw", height: "1.5vw" }} />{" "}
                </IconButton>
              </label>
            </Modal.Body>
          </Modal>

          <Modal
            className="user-select-popup"
            show={this.state.showDownload}
            onHide={this.handleDownloadClose}
            backdrop="static"
            keyboard={false}
            dialogClassName="fullmodal"
            size="l"
            centered
          >
            <Modal.Body>
              <label
                style={{
                  font: "normal normal bold 1vw Helvetica",
                  textAlign: "left",
                }}
              >
                Please select atleast one file to download isometric.
                <IconButton
                  onClick={this.handleDownloadClose}
                  style={{
                    padding: "3px",
                    position: "absolute",
                    top: "0",
                    right: "0",
                    background: "",
                    borderRadius: "0",
                    color: "#0C629B",
                  }}
                >
                  {" "}
                  <CloseIcon style={{ width: "1.5vw", height: "1.5vw" }} />{" "}
                </IconButton>
              </label>
            </Modal.Body>
          </Modal>

          <Modal
            className="user-select-popup"
            show={this.state.showBulkAccept}
            onHide={this.handleBulkAcceptClose}
            backdrop="static"
            keyboard={false}
            dialogClassName="fullmodal"
            size="l"
            centered
          >
            <Modal.Body>
              <label
                style={{
                  font: "normal normal bold 1vw Helvetica",
                  textAlign: "left",
                }}
              >
                {this.state.fileprocessedStatus ? (
                  <span>
                    Please select atleast one file to accept isometric.
                  </span>
                ) : (
                  <span>
                    Please select only processed file to accept isometric.
                  </span>
                )}
                <IconButton
                  onClick={this.handleBulkAcceptClose}
                  style={{
                    padding: "3px",
                    position: "absolute",
                    top: "0",
                    right: "0",
                    background: "",
                    borderRadius: "0",
                    color: "#0C629B",
                  }}
                >
                  {" "}
                  <CloseIcon style={{ width: "1.5vw", height: "1.5vw" }} />{" "}
                </IconButton>
              </label>
            </Modal.Body>
          </Modal>

          <Modal
            className="user-select-popup"
            show={this.state.dateValidation}
            onHide={this.handleDateeClose}
            backdrop="static"
            keyboard={false}
            dialogClassName="fullmodal"
            size="l"
            centered
          >
            <Modal.Body>
              <label
                style={{
                  font: "normal normal bold 1vw Helvetica",
                  textAlign: "left",
                }}
              >
                {" "}
                {this.state.dateValidationValue}
                <IconButton
                  onClick={this.handleDateeClose}
                  style={{
                    padding: "3px",
                    position: "absolute",
                    top: "0",
                    right: "0",
                    background: "",
                    borderRadius: "0",
                    color: "#0C629B",
                  }}
                >
                  {" "}
                  <CloseIcon style={{ width: "1.5vw", height: "1.5vw" }} />{" "}
                </IconButton>
              </label>
            </Modal.Body>
          </Modal>
          <div style={{ width: "100%" }}>
            {this.state.rows1.length > 0 ? (
              <StampGridTable
                loading={this.state.rows1 === 0}
                data={this.state.rows1}
                columns={this.state.columns}
                onRowSelected={this.handleRowSelection}
                searchrows={searchrows}
                dropdownChange={this.state.initvalue}
              ></StampGridTable>
            ) : (
              ""
            )}
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}

export default withRouter(StampGrid);
