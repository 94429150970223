import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import UserStore from '../../UserStore';
import { observer } from "mobx-react-lite";
import './Progress.scss';

var files = [];

function Progress() {
  
  // const count = UserStore.countforProgressPID;
  UserStore.setCountPID(1);
  const percent = UserStore.percentagePID;

  files = UserStore.fileSuccededPID.map((number) =>            
          <p style={{font:'1.2vw Helvetica'}}>{number}<LinearProgress variant="determinate" value={100} />{percent}% completed</p>              
        );
  
  return (
    <div>
      <div className="log-files">
        <img src="/images/logfiles.svg" className="log-icon" />
        <span classNmae="log-text">Log Files</span>
      </div>
      <paper>
        <Grid container spacing={1} justify="center" alignItems="center">
          <Grid item xs={2}>
            <p></p>
          </Grid>
          <Grid item xs={8} spacing={8} justify="center">           
            {files}            
          </Grid>
          <Grid item xs={2}>
            <p></p>
          </Grid>
        </Grid>
      </paper>
    </div>
  );
}

export default observer(Progress);