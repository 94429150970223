import React, { Component } from 'react'
import { BrowserRouter as Router, Switch, Route, browserHistory } from 'react-router-dom';
import  { withRouter } from 'react-router-dom';
import { TextField, MuiThemeProvider, Button } from '@material-ui/core';
import AppHeader from '../../AppHeader/AppHeader';
import AppFooter from '../../AppFooter/AppFooter';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import UploadFiles from '../../Upload/UploadFiles/UploadFiles';
import StampUpload from '../../Upload/StampUpload/StampUpload';
import BreadCrumbs from '../../Upload/BreadCrumbs/BreadCrumbs';
import './ProjectSetupdetails.scss';
import axios from 'axios';

const API_Endpoint = process.env.REACT_APP_API_ENDPOINT;
export class ProjectSetupdetails extends Component {

  constructor(props) {
    super(props);
    
    this.state = {
      setShow: false,
    }
  }
  
  back = e => {
    e.preventDefault();
    this.props.prevStep();
  }

  Save = e => {
    const userlist = [];
    userlist.push(this.props.values.userlist)

    const data = {
      "projectName": this.props.values.PetrofacProjectName,
      "workSpaceName": this.props.values.WorkspaceName,
      "memberEnterpriseIds": userlist,
      "areaId": 1
    }

    axios.post(API_Endpoint +`//CreateWorkSpace`, data)
    this.props.history.push(`${this.props.match.path}/UploadFiles`)
  }

  render() {
    if (!this.state.setShow) {
      return (
        <div class="project-setup-container">
          <MuiThemeProvider>
            <React.Fragment>
              <AppHeader />
              <BreadCrumbs />
              <div class="project-setup-form-wrapper">
                <div class="project-setup-user-formblank" style={{"background-color": "#0C629B"}}>
                  <p class="user-text">Create new Workspace</p>
                  <img src="/images/icon_workspace.svg" class="icon-workspace" />
                </div>
                <div class="project-setup-user-form">
                  <div class="no-field-wrapper">
                    <div class="line-no-field">
                      <TextField autoFocus margin="dense" id="1" label="Add key for Line no designation" type="text" onChange={this.props.handleChange('Workspacename')} />
                      <p class="add-users-icon" align="right"> 
                        <img src="/images/icon_add_blue.svg"/>
                      </p>
                    </div>
                    <div class="doc-no-field">
                      <TextField margin="dense" id="name2" label="Add Key for Document no designation" onChange={this.props.handleChange('PetrofacProjectname')} />
                      <p class="add-users-icon" align="right"> 
                        <img src="/images/icon_add_blue.svg"/>
                      </p>
                    </div>
                  </div>
                  <div class="select-dropdown-container">
                    <div class="line-no-select-dropdown-wrapper">
                      <InputLabel id="demo-simple-select-label">Line no designation</InputLabel>
                        <Select labelId="demo-simple-select-label" id="demo-simple-select" onChange={this.props.handleChange}>
                          <MenuItem value={10}>Ten</MenuItem>
                          <MenuItem value={20}>Twenty</MenuItem>
                          <MenuItem value={30}>Thirty</MenuItem>
                        </Select>
                    </div>
                    <div class="doc-no-select-dropdown-wrapper">
                      <InputLabel id="demo-simple-select-label">Document no designation</InputLabel>
                        <Select labelId="demo-simple-select-label" id="demo-simple-select" onChange={this.props.handleChange}>
                          <MenuItem value={10}>Ten</MenuItem>
                          <MenuItem value={20}>Twenty</MenuItem>
                          <MenuItem value={30}>Thirty</MenuItem>
                        </Select>
                    </div>
                  </div>
                  <div class="project-setup-fields">
                    <span class="proejct-setup-title">Project Setup</span>
                  </div>
                  <TextField margin="dense" id="name3" label="Enter Phrase 1" onChange={this.props.handleChange('Phrase1')} />
                  <TextField margin="dense" id="name3" label="Enter Phrase 2" onChange={this.props.handleChange('Phrase2')} />
                  <TextField margin="dense" id="name3" label="Enter Phrase 3" onChange={this.props.handleChange('Phrase3')} />
                  <TextField margin="dense" id="name3" label="Enter Phrase 4" onChange={this.props.handleChange('Phrase4')} />
                  <TextField margin="dense" id="name3" label="Upload Project data" onChange={this.props.handleChange('Userslist')} />
                  <div class="btn-fields" Align="center">
                    <Button variant="contained" class="save-btn" onClick={this.Save}>SAVE</Button>
                    <Button variant="contained" class="cancel-btn" onClick={this.back}>CANCEL</Button>
                  </div>
                </div>
              </div>
              <AppFooter />
            </React.Fragment>
          </MuiThemeProvider>
        </div>
      )
    }
    else
      return <UploadFiles />
  }
}

const styles = {
  button: {
    margin: 15
  }
}

const ShowTheLocationWithRouter = withRouter(ProjectSetupdetails);
export default ProjectSetupdetails;
