import {
  filterData,
  getLineGroup,
  addNewAsset,
  updateJsonData,
  editJsonData,
  addNewLineGroup,
  transform,
  editDataWindow1,
  getShortCode,
  editLineDataWindow1,
  deleteSymbolData,
  deleteLineData,
  symbolsRanking
} from "../CanvasActions";

export default function documentReducer(state = {}, action) {
  switch (action.type) {
    case "GET_JSON_DATA":
      const lineGroupSpecification = getLineGroup(Object.entries(action.data));
      const shortCode = getShortCode(Object.entries(action.data));
      return {
        ...state,
        initialList: action.data,
        documentList: action.data,
        lineGroupSpec: lineGroupSpecification,
        assetsList: action.data,
        shortCodeList: shortCode,
      };
    case "SYMBOLS_DETAILS":
      // console.log(action.data);
    //   const confidenceScore = [];
    //   let k = action.data;
    //   console.log(k);
    // if(k.length > 0)
    //   k.filter(row => (row.ConfidenceScore > 90 && row.ConfidenceScore <= 100 ? confidenceScore.push(row) : ""));
    // console.log(confidenceScore);
      return {
        ...state,
        symbolsDetails: action.data,
      }
    case "FILTER_LINE_GROUP":
      // console.log(state.documentList)
      const assetsData = action.data[0];
      let symbolsList = action.data[1];
      if(Object.keys(symbolsList).length === 0) {
        symbolsList = state.documentList;
      }
      if (assetsData.assetID) {
        const filteredAssets = filterData(
          assetsData.lineNumber,
          Object.entries(symbolsList),
          assetsData.assetType
        );
        state.filteredList = filteredAssets;
      } else {
        state.filteredList = symbolsList;
      }
      return {
        ...state,
        selectedAsset: assetsData,
      };
    case "ADD_NEW_ASSET":
      const assets = filterData(
        action.data.lineNumber,
        Object.entries(state.documentList)
      );
      const newAsset = addNewAsset(assets, action.data);
      const updatedJson = updateJsonData(
        newAsset,
        state.documentList,
        action.data.assetType,
        assets[0]
      );
      const shortCodeL = getShortCode(Object.entries(updatedJson));
      return {
        ...state,
        newAddedAsset: newAsset,
        documentList: updatedJson,
        shortCodeList: shortCodeL,
      };
    case "EDIT_ASSET":
      const oldValue = action.data[0];
      const newValue = action.data[1];
      const asset = filterData(
        oldValue["LineNumber"],
        Object.entries(state.documentList)
      );
      const editedJson = editJsonData(
        oldValue,
        newValue,
        state.documentList,
        asset[0]
      );
      return {
        ...state,
        documentList: editedJson,
      };
    case "NEW_LINE_GROUP":
      const updatedJsonData = addNewLineGroup(action.data, state.documentList);
      const lineGSpec = getLineGroup(Object.entries(updatedJsonData));
      return {
        ...state,
        documentList: updatedJsonData,
        lineGroupSpec: lineGSpec,
      };
    case "NEW_LINE_NUMBER":
      return {
        ...state,
        newLineNumber: action.data,
      };
    case "TRANSFORM":
      const lineGroup = filterData(
        action.data.lineNumber,
        Object.entries(state.documentList)
      );
      const transformData = transform(
        action.data,
        state.documentList,
        lineGroup[0]
      );
      return {
        ...state,
        documentList: transformData,
      };
    case "EDIT_DATA":
      const lineGroup1 = filterData(
        action.data.lineNumber,
        Object.entries(state.documentList)
      );
      const oldLineGroup2 = filterData(
        action.data.oldLineNumber,
        Object.entries(state.documentList)
      );
      let editedData;
      if (lineGroup1 === undefined) {
        const updatedJson1 = addNewLineGroup(action.data, state.documentList);
        editedData = updatedJson1;
        delete editedData[oldLineGroup2[0]]["Symbols"][action.data.id];
      }

      else {
        editedData = editDataWindow1(
          action.data,
          state.documentList,
          lineGroup1[0]
        );
      }
      return {
        ...state,
        documentList: editedData,
      };
    case "EDIT_LINE_DATA":
      const lineGroup2 = filterData(
        action.data.lineNumber,
        Object.entries(state.documentList)
      );

      const oldLineGroup = filterData(
        action.data.oldSpecification,
        Object.entries(state.documentList)
      );
      let modifiedData1;
      if (lineGroup2 === undefined) {
        const updatedJsonData1 = addNewLineGroup(
          action.data,
          state.documentList
        );
        modifiedData1 = updatedJsonData1;
        delete modifiedData1[oldLineGroup[0]]["Lines"][action.data.id];
      } else {
        const editedData1 = editLineDataWindow1(
          action.data,
          state.documentList,
          lineGroup2[0],
          oldLineGroup[0]
        );
        modifiedData1 = editedData1[1];
      }
      
      const editedShortCodeList = getShortCode(Object.entries(modifiedData1));
      return {
        ...state,
        documentList: modifiedData1,
        shortCodeList: editedShortCodeList,
      };
    case "DELETE_SYMBOL":
      const lineGroupDelete = filterData(
        action.data.LineNumber,
        Object.entries(state.documentList)
      );
      
      const deleteModify = deleteSymbolData(
        action.data,
        state.documentList,
        lineGroupDelete[0]
      );
      return {
        ...state,
        documentList: deleteModify,
      };
      case "DELETE_LINE":
      const lineGroupDeleteLine = filterData(
        action.data.LineNumber,
        Object.entries(state.documentList)
      );
      const deleteLine = deleteLineData(
        action.data,
        state.documentList,
        lineGroupDeleteLine[0]
      );
      return {
        ...state,
        documentList: deleteLine,
      };
    case "EDIT_LINE_GROUP":
      let jsonMod = "";
      const oldLg = action.data[0];
      const rowData = action.data[1];
      rowData.assetType = "symbols";
      const lg = filterData(
        rowData.LineNumber,
        Object.entries(state.documentList)
      );

      const tobeModified = {
        AgUg: rowData.ugag,
        Coords: rowData.coords,
        ExtendedBonet: rowData.extendedBonet,
        Fluid: rowData.fluid,
        FromLine: rowData.fromLine,
        HdrSize: rowData.hdr,
        Hold: rowData.hold,
        InsulationType: rowData.insulationType,
        IsJacketedLine: rowData.isJacketed,
        ItemOrder: rowData.itemOrder,
        LimitSwich: rowData.limitSwich,
        Quantity: rowData.quantity,
        Remarks: rowData.remarks,
        Seat: rowData.seat,
        SequenceNumber: rowData.Seq,
        ShortCode: rowData.shortCode,
        Size1: rowData.size1,
        Size2: rowData.size2,
        Spec: rowData.Spec,
        SymbolId: rowData.id,
        ToLine: rowData.toLine,
        ValveBody: rowData.valveBody,
        assetComponentType: rowData.assetCompType,
        assetType: "symbols",
        id: rowData.assetId,
        lineNumber: rowData.LineNumber,
        oldLineNumber: oldLg,
        specification: rowData.LineNumber,
        SymbolRank: rowData.SymbolRank,
        ConfidenceScore: rowData.ConfidenceScore,
      };
      if (lg === undefined) {
        jsonMod = addNewLineGroup(tobeModified, state.documentList);
      const lgFilter = filterData(
        oldLg,
        Object.entries(jsonMod)
      );
      delete jsonMod[lgFilter[0]]["Symbols"][tobeModified.id];
    }
    else {
      jsonMod = editDataWindow1(tobeModified,state.documentList,lg[0]);
    }
      return {
        ...state,
        documentList: jsonMod,
      };
    case "SELECT_ASSET" : 
     let item = "";
    if(action.data.id === undefined) {
      item =  undefined;
    }
    else {
      item = action.data;
    }
      return {
        ...state,
        selectedAssetData : action.data.id,
        selectedItem : item
      }
    case "SYMBOLS_RANKING":
      let ranking = "";
      if(action.data !== undefined) 
         ranking = symbolsRanking(action.data.properties,action.data.symbolsData);
      return {
        ...state,
        rankingOrder : ranking,
      }
    default:
      return state;
  }
}
