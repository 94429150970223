import React from "react";
import Modal from "react-bootstrap/Modal";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const ModalValidation = ({
  handleCloseEmptyUser,
  message,
  userEmpty,
  emptyUser,
  duplicateData,
  workSpacePopupError,
  clientPopupError,
}) => {
  return (
    <div>
      <Modal
        className="user-select-popup"
        show={userEmpty}
        onHide={handleCloseEmptyUser}
        backdrop="static"
        keyboard={false}
        dialogClassName="fullmodal"
        size="l"
        centered
      >
        <Modal.Body>
          
          <label
            style={{
              font: "normal normal bold 1vw Helvetica",
              textAlign: "left",
              padding: "1vw",
              overflow: "auto",
              wordWrap: "break-word"
            }}
          >
            {message}
            {duplicateData ? (
              <p>Project workspace name already exists.</p>
            ) : (
              ""
            )}
            {workSpacePopupError || clientPopupError ? (
              <p>select at least one user to create new Project Workspace</p>
            ) : (
              ""
            )}
            {emptyUser && !(workSpacePopupError || clientPopupError) ? (
              <p>
                Please select at least one user to create new Project Workspace{" "}
              </p>
            ) : (
              ""
            )}
            {/* <Button variant="primary" onClick={this.handleCloseEmptyUser} className="close-btn">Close</Button> */}
            <IconButton
              onClick={handleCloseEmptyUser}
              style={{
                padding: "0.156vw",
                position: "absolute",
                top: "0",
                right: "0",
                background: "",
                borderRadius: "0",
                color: "#0C629B",
              }}
            >
              {" "}
              <CloseIcon style={{ width: "1.5vw", height: "1.5vw" }} />{" "}
            </IconButton>
          </label>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ModalValidation;
