import React, { useEffect, useState } from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import axios from "axios";
import { toJS } from "mobx";
import Modal from "react-bootstrap/Modal";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import UserStore from "../../UserStore";

export const ExportToExcel = ({ fileName, datafromfilelog, fromfilelog }) => {
  var checkname = false;
  const [MTOShow, setMTOShow] = useState(false);
  const [exportMTOValue, setExportMTOValue] = useState(false);
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const API_Endpoint = process.env.REACT_APP_API_ENDPOINT;

  const handleClose = () => {
    setMTOShow(false);
  };

//Used to update the document title using the browser API
  useEffect(() => {
    checkname = true;
  }, []);

//Used to change the raw data format to the below specified one
  const changeDataFormat = (mtoResult) => {
    let k = [];
    mtoResult.map((data) => {
      let mtoData = {
        "LINE NUMBER": data.lineNumber,
        SEQ: data.seq,
        PID: data.pidNumber,
        SPEC: data.spec,
        "SHORT CODE": data.shortCode,
        "IS JACKETED LINE": data.isJacketedLine === true ? "Y" : "N",
        "HDR SIZE":
          data.hdrSize === null ||
          data.hdrSize === undefined ||
          data.hdrSize === ""
            ? ""
            : data.hdrSize.split(/[" ']+/)[0],
        "SIZE-1":
          data.size1 === null || data.size1 === undefined || data.size1 === ""
            ? ""
            : parseInt(data.size1),
        "SIZE-2":
          data.size2 === null || data.size2 === undefined || data.size2 === ""
            ? ""
            : parseInt(data.size2),
        QTY: data.qty,
        "INSUL TYPE": data.insulType,
        "AG/UG": data.ugAg,
        FLUID: data.fluid,
        PIPINGCOMPTYPE:
          data.pipingCompType === null ||
          data.pipingCompType === undefined ||
          data.pipingCompType === ""
            ? ""
            : data.pipingCompType.toUpperCase(),
        SEAT: data.seat,
        VALVEBODY: data.valveBody,
        "EXTENDED BONNET": data.extendedBonnet,
        "LIMIT SWITCH": data.limitSwitch,
        HOLD: data.hold === true ? "Y" : "N",
        SUPPLY: data.supply,
        FROM: data.fromLine,
        TO: data.toLine,
        "ITEM ORDER": data.itemOrder,
        REMARKS: data.remarks,
        RATING: data.rating,
      };
      k.push(mtoData);
    });
    return k;
  };

//Used to export all the data to an excel sheet
  const exportToCSV = async (fileName) => {
    const selectedIdList = toJS(UserStore.selectedRowID).map((item) => {
      return item.sheetId;
    });
    if (fromfilelog === "Yes") {
      if (datafromfilelog.length !== 0) {
        const ws = XLSX.utils.json_to_sheet(datafromfilelog);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
      } else {
        setMTOShow(true);
        setExportMTOValue(true);
      }
    } else {
      if (selectedIdList.length >= 1) {
        let res = await axios.post(
          API_Endpoint + `/ExportPidMto`,
          JSON.stringify({ sheetIds: selectedIdList })
        );
        let mtoResult = "";
        mtoResult = changeDataFormat(res.data);

        const ws = XLSX.utils.json_to_sheet(mtoResult);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
      } else {
        setMTOShow(true);
        setExportMTOValue(true);
      }
    }
  };

  return (
    <>
      <img
        src="/images/export.svg"
        class="toolbar-icon"
        style={{ marginLeft: "3.5vw" }}
      />
      <span className="preview-text" onClick={(e) => exportToCSV(fileName)}>
        {fromfilelog === "Yes" ? "Export Log" : "Export MTO"}
      </span>

      <Modal
        className="user-select-popup"
        show={MTOShow}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="fullmodal"
        size="l"
        centered
      >
        <Modal.Body>
          <label
            style={{
              font: "normal normal bold 1vw Helvetica",
              textAlign: "left",
            }}
          >
            {exportMTOValue && fromfilelog !== "Yes"
              ? "Please select atleast one processed row to export the MTO"
              : "File log not available"}
            <IconButton
              onClick={handleClose}
              style={{
                padding: "3px",
                position: "absolute",
                top: "0",
                right: "0",
                background: "",
                borderRadius: "0",
                color: "#0C629B",
              }}
            >
              {" "}
              <CloseIcon style={{ width: "1.5vw", height: "1.5vw" }} />{" "}
            </IconButton>
          </label>
        </Modal.Body>
      </Modal>
    </>
  );
};
