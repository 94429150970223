import React from "react";
import BoundingBox from "./BoundingBox/BoundingBox";
import Line from "./Line/Line";

export default function AssetsShape(props) {
  const isSelectedId = props.shape.assetID === props.selectedId ? true : false;
  switch (props.shape.assetType) {
    case "symbols":
      return (
        // props.shape.SymbolRank === 0 || props.shape.SymbolRank === 1 || props.shape.SymbolRank === undefined || props.shape.SymbolRank === null ? 
        <BoundingBox
          shape={props.shape}
          isSelected={isSelectedId}
          dispatcher={props.dispatcher}
          width={props.width}
          height={props.height}
        /> 
        // : <></>
      );
    case "lines":
      return (
        <Line
          shape={props.shape}
          isSelected={isSelectedId}
          dispatcher={props.dispatcher}
          width={props.width}
          height={props.height}
        />
      );
    default:
      return null;
  }

}
