import React, { Component } from "react";
import "./DragDrop.scss";
import ProgressBar from "./ProgressBar";
import UserStore from "../../UserStore";
import { toJS } from "mobx";
export default class DragAndDrop extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    drag: false,
    renderView: 0,
    filelist: [],
    show: false,
  };

  handleClick = () => {
    this.setState({ renderView: 10 });
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  dropRef = React.createRef();
  handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({ drag: true });
    }
  };

  handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter--;
    if (this.dragCounter === 0) {
      this.setState({ drag: false });
    }
  };

  handlePIDbrowseFile = (e) => {
    this.props.handleDropPID(e.target.files);
  };

  handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ drag: false });
    this.props.handleDropPID(e.dataTransfer.files);
  };

  componentDidMount() {
    let div = this.dropRef.current;
    div.addEventListener("dragenter", this.handleDragIn);
    div.addEventListener("dragleave", this.handleDragOut);
    div.addEventListener("dragover", this.handleDrag);
    div.addEventListener("drop", this.handleDrop);
  }

  componentWillUnmount() {
    let div = this.dropRef.current;
    div.removeEventListener("dragenter", this.handleDragIn);
    div.removeEventListener("dragleave", this.handleDragOut);
    div.removeEventListener("dragover", this.handleDrag);
    div.removeEventListener("drop", this.handleDrop);
  }

  render() {
    
    const { handleDropPID, files, succeded, tempFiles, handleCheckboxCheck } =
    this.props;
      let valueofdropfiles = toJS(UserStore.droppedPIDFiles);
      let completeFiles = toJS(UserStore.fileSucceded);
      let progressFile = toJS(UserStore.selectedfilesforupload);
      const displayHide =
    valueofdropfiles.length === 0 && completeFiles.length === 0 &&  progressFile.length === 0
      ? true
      : false;

    return (
      <div>
        <div
          className="drag-drop-box-container"
          ref={this.dropRef}
          style={
            displayHide
              ? {
                  backgroundColor: "aliceblue",
                  margin: "4vh",
                  height: "55vh",
                  width: "95%",
                }
              : {
                  backgroundColor: "transparent",
                  overflowX: "hidden",
                  overflowY: "auto",
                  height: "100%",
                }
          }
        >
          <div class="drag-drop-box-wrapper">
            <div class="drag-drop-box">
              <div class="empty-state-container">
                <img
                  src="/images/cloud-icon.svg"
                  className="cloud-icon"
                  style={
                    displayHide ? { display: "grid" } : { display: "none" }
                  }
                />
                <span
                  class="supported-format-text"
                  style={
                    displayHide ? { display: "grid" } : { display: "none" }
                  }
                >
                  Please upload pdf & zip files
                </span>
                <div
                  class="dragdrop-text"
                  style={
                    displayHide
                      ? { marginTop: "3vh" }
                      : {
                          top: "84vh",
                          position: "absolute",
                          width: "75vw",
                          height: "9vh",
                          backgroundColor: "aliceblue",
                          borderTop: "0.1vw dashed",
                          position: "fixed",
                        }
                  }
                >
                  Drag and drop files here <br /> or{" "}
                </div>
                <label
                  for="choose-files"
                  className="choose-files-btn"
                  style={
                    displayHide
                      ? { background: "#0C629B", color: "#FFFFFF" }
                      : {
                          top: "87vh",
                          position: "fixed",
                          paddingTop: "0.6vw",
                          color: "#0C629B",
                          fontWeight: "bold",
                        }
                  }
                >
                  {displayHide ? (
                    <span>Choose Files</span>
                  ) : (
                    <span>Browse</span>
                  )}
                </label>
                <input
                  type="file"
                  id="choose-files"
                  multiple
                  ref={ref=> this.fileInput = ref}
                  onChange={this.handlePIDbrowseFile}
                />
              </div>
            </div>
          </div>
          <div className="dropContainer"style={displayHide ? {display: 'none'} : {display: "block"} }>
            {files.map((file, index) => (
              <div style={{ padding: "10" }} className="dropFileList">
                <input
                  className="file-checkbox"
                  name={file}
                  id="chk"
                  onClick={handleCheckboxCheck}
                  type="checkbox"
                />
                {file}
                <img
                  src="/images/tagged_icon.svg"
                  class="tagged-icon"
                  style={
                    tempFiles.includes(file)
                      ? { display: "inline" }
                      : { display: "none" }
                  }
                />
              </div>
            ))}
            <ProgressBar />
          </div>
        </div>
      </div>
    );
  }

  
}
