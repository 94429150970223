import React, { Component } from "react";
import { MuiThemeProvider, Button } from "@material-ui/core";
import AppHeader from "../../AppHeader/AppHeader";
import AppFooter from "../../AppFooter/AppFooter";
import ErrorBoundary from "../../Errorlog/ErrorBoundary";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Suggestions from "../ProjectDetails/Suggestions";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { toJS, observer } from "mobx";
import UserStore from "../../UserStore";
import BreadCrumbs from "../../Upload/BreadCrumbs/BreadCrumbs";
import { withStyles } from "@material-ui/core/styles";
import TextFieldMui from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";
import CircleChecked from "@material-ui/icons/CheckCircleRounded";
import Cancel from "@material-ui/icons/Cancel";
import "./EditWorkSpaceStamp.scss";
import LoadingOverlay from "react-loading-overlay";

// Importing toastify module
import { toast } from "react-toastify";

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";

// toast-configuration method, it is compulsory method.
toast.configure();

const styles = (muiTheme) => ({
  label: {
    "&$erroredLabel": {
      color: "black",
    },
  },
  erroredLabel: {},
});

const API_Endpoint = process.env.REACT_APP_API_ENDPOINT;
var result;
const TextField = withStyles(styles)(function TextField({ classes, ...props }) {
  return (
    <TextFieldMui
      InputLabelProps={{
        classes: {
          root: classes.label,
          error: classes.erroredLabel,
        },
      }}
      {...props}
    />
  );
});
export default class EditWorkSpaceStamp extends Component {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.suggestionOutClickHandler = this.suggestionOutClickHandler.bind(this);
    const editWorkSpaceData = toJS(UserStore.editRow).length
      ? toJS(UserStore.editRow)[0]
      : localStorage.getItem("EditWSData")
      ? JSON.parse(localStorage.getItem("EditWSData"))
      : [];
    if (!toJS(UserStore.editRow).length && localStorage.getItem("EditWSData")) {
      UserStore.addRowdata(JSON.parse(localStorage.getItem("EditWSData")));
    }
    this.state = {
      list: [],
      open: false,
      workspace: false,
      projectname: false,
      projectNameNew: "",
      modelNameNew: "",
      WorkspaceNameNew: "",
      WorkspaceIdNew: "",
      workSpaceMessage: "",
      projectNameMessage: "",
      modelNameMessage: "",
      usersMessage: "",
      workSpaceError: false,
      projectNameError: false,
      modelNameError: false,
      usersError: false,
      count: -1,
      notification: [],
      role: "Admin",
      roles: [],
      rolearray: [],
      dropdownvalue: "Admin",
      suggestions: [],
      modifysuggestion: [],
      showSuggestions: false,
      q: "",
      personName: [],
      useradd: [],
      show: false,
      OCRshow: false,
      ocrweightage: [],
      lineNum: "",
      lineNumThreshold: "",
      isoNum: "",
      isoNumThreshold: "",
      sheetNum: "",
      sheetNumThreshold: "",
      revNum: "",
      revNumThreshold: "",
      tootlTipErrorMessage: "",
      tooltipError: false,
      userEmpty: false,
      delete: false,
      deleteUsers: "",
      editRow: editWorkSpaceData,
      workSpacePopupError: false,
      clientPopupError: false,
      modelPopupError: false,
      emptyUser: false,
      loading: true,
    };
  }

  handleCloseEmptyUser = () => {
    this.setState({ workSpacePopupError: false });
    this.setState({ clientPopupError: false });
    this.setState({ modelPopupError: false });
    this.setState({ userEmpty: false, duplicateData: false, emptyUser: false });
  };

  componentDidMount() {
    this.fetchUserTypes();
    this.fetchOCRWeightages();

    document.addEventListener("mousedown", this.suggestionOutClickHandler);
    this.setState({
      WorkspaceNameNew: toJS(this.state.editRow.workSpaceName),
      projectNameNew: toJS(this.state.editRow.projectName),
      modelNameNew: toJS(this.state.editRow.aiModelId),
    });
    let rolesedit = [];
    
    if (UserStore.setDetectionQuery == "") {
      UserStore.SetDetectionQuery(toJS(this.state.editRow.detectionExpression));
      localStorage.setItem(
        "SetDetectionQuery",
        toJS(this.state.editRow.detectionExpression)
      );
    }

    rolesedit = toJS(this.state.editRow.workSpaceUsers);

    for (var i = 0; i < rolesedit.length; i++) {
      if (rolesedit[i].roleId == 1) {
        rolesedit[i].roleId = "Admin";
      } else if (rolesedit[i].roleId == 2) {
        rolesedit[i].roleId = "Read / Write";
      } else {
        rolesedit[i].roleId = "Read Only";
      }
    }
    this.setState({
      useradd: rolesedit,
    });
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.suggestionOutClickHandler);
  }

  fetchOCRWeightages = async () => {
    let thresholdArray = [];
    await axios
      .get(
        API_Endpoint +
          `/GetIsometricFieldRules?workSpaceId=${toJS(
            UserStore.editRow[0].workSpaceId
          )}`
      )
      .then((res) => {
        const rows1 = res.data;
        thresholdArray = [...rows1.isometricFieldThreshold];
        this.setState({ ocrweightage: thresholdArray });
        thresholdArray.map((items) => {
          if (items.fieldId === 1) {
            this.setState({
              lineNum: items.ocrWeightage,
              lineNumThreshold: items.confidenceThreshold,
            });
          } else if (items.fieldId === 2) {
            this.setState({
              isoNum: items.ocrWeightage,
              isoNumThreshold: items.confidenceThreshold,
            });
          } else if (items.fieldId === 3) {
            this.setState({
              sheetNum: items.ocrWeightage,
              sheetNumThreshold: items.confidenceThreshold,
            });
          } else {
            this.setState({
              revNum: items.ocrWeightage,
              revNumThreshold: items.confidenceThreshold,
            });
          }
        });
      });
  };

  fetchUserTypes = async () => {
    const responsecheck = await axios.get(API_Endpoint + `/GetWorkSpaces`);
    const wID = this.state.editRow.workSpaceId;
    result = responsecheck.data.filter(function (v, i) {
      return v.workSpaceId !== wID;
    });
    if (responsecheck.data) {
      this.setState({
        loading: false,
      });
    }
    const dropDownValues = await axios
      .get(`${API_Endpoint}/GetApplicationRoles`)
      .then((res) => {
        if (res.status === 200) {
          this.state.roles = res.data;
          this.setState({
            roles: res.data,
          });
        } else if (
          res.status === 400 ||
          res.status === 401 ||
          res.status === 404 ||
          res.status === 408
        ) {
          toast.error("service error", {
            // Set to 5sec
            position: toast.POSITION.TOP_LEFT,
            autoClose: 5000,
          });
        }
      });
  };

  handleusers = (e) => {
    this.setState({
      list: this.state.list.push(e.target.value),
    });
  };

  createUseroptions = (e) => {
    return this.state.roles.map((option) => (
      <option key={option.roleId} value={option.roleName}>
        {option.roleName}
      </option>
    ));
  };

  savedata = async () => {
    for (var i in this.state.useradd) {
      if (this.state.useradd[i].roleId == "Admin") {
        this.state.useradd[i].roleId = 1;
      } else if (this.state.useradd[i].roleId == "Read / Write") {
        this.state.useradd[i].roleId = 2;
      } else {
        this.state.useradd[i].roleId = 3;
      }
    }
    const data = {
      workSpaceId: this.state.editRow.workSpaceId,
      projectName: this.state.projectNameNew,
      workSpaceName: this.state.WorkspaceNameNew,
      aiModelId: this.state.modelNameNew,
      workSpaceUsers: this.state.useradd,
      detectionExpression: UserStore.setDetectionQuery,
    };

    // API: Send edited details for workspace
    // await axios.post(API_Endpoint + `/EditWorkSpace`, data).then((res) => {
    //   if (res.status === 200) {
    //     this.props.history.push(`/Workspace`);
    //   }
    // });
  };

  handleSave = (e) => {
    const allValue = [
      this.state.lineNum,
      this.state.lineNumThreshold,
      this.state.isoNum,
      this.state.isoNumThreshold,
      this.state.sheetNum,
      this.state.sheetNumThreshold,
      this.state.revNum,
      this.state.revNumThreshold,
    ];
    if (
      allValue.every((value) => {
        return value !== "";
      })
    ) {
      const OCRapiData = [];
      this.setState({
        OCRshow: false,
        OCRmessage: false,
        ocrweightage: [
          {
            fieldId: 1,
            ConfidenceThreshold: Number(this.state.lineNumThreshold).toFixed(1),
            OCRWeightage: Number(this.state.lineNum).toFixed(1),
          },
          {
            fieldId: 2,
            ConfidenceThreshold: Number(this.state.isoNumThreshold).toFixed(1),
            OCRWeightage: Number(this.state.isoNum).toFixed(1),
          },
          {
            fieldId: 3,
            ConfidenceThreshold: Number(this.state.sheetNumThreshold).toFixed(
              1
            ),
            OCRWeightage: Number(this.state.sheetNum).toFixed(1),
          },
          {
            fieldId: 4,
            ConfidenceThreshold: Number(this.state.revNumThreshold).toFixed(1),
            OCRWeightage: Number(this.state.revNum).toFixed(1),
          },
        ],
      });
    } else {
      this.setState({
        OCRmessage: true,
      });
    }
  };

  continue = async (e) => {
    e.preventDefault();
    const data = {
      workSpaceId: this.state.editRow.workSpaceId,
      projectName: this.state.projectNameNew,
      workSpaceName: this.state.WorkspaceNameNew,
      aiModelId: this.state.modelNameNew,
      workSpaceUsers: this.state.useradd,
      detectionExpression: UserStore.setDetectionQuery,
    };

    let value = 0;

    // input validations
    if (
      data.projectName === "" &&
      data.workSpaceName === "" &&
      data.modelName === "" &&
      this.state.useradd.length === 0 &&
      this.state.ocrweightage.length === 0 &&
      value === 0
    ) {
      value = value + 1;
      this.setState({
        userEmpty: true,
        message:
          "Workspace Name, Client name and Model Name are required. Please provide the details and select at least one user and add OCR Weightage to create new Project Workspace",
      });
    } else if (
      data.workSpaceName === "" &&
      data.projectName === "" &&
      data.modelName === "" &&
      this.state.useradd.length !== 0 &&
      this.state.ocrweightage.length !== 0
    ) {
      value = value + 1;
      this.setState({
        userEmpty: true,
        message: "Workspace Name, Client name and Model Name are required.",
      });
    } else if (
      data.workSpaceName === "" &&
      data.projectName !== "" &&
      data.modelName !== "" &&
      this.state.useradd.length !== 0 &&
      this.state.ocrweightage.length !== 0
    ) {
      value = value + 1;
      this.setState({
        userEmpty: true,
        message: "Workspace Name is required.",
      });
    } else if (
      data.workSpaceName !== "" &&
      data.projectName === "" &&
      data.modelName !== "" &&
      this.state.useradd.length !== 0 &&
      this.state.ocrweightage.length !== 0
    ) {
      value = value + 1;
      this.setState({
        userEmpty: true,
        message: "Client Name is required.",
      });
    } else if (
      data.workSpaceName !== "" &&
      data.projectName !== "" &&
      data.modelName === "" &&
      this.state.useradd.length !== 0 &&
      this.state.ocrweightage.length !== 0
    ) {
      value = value + 1;
      this.setState({
        userEmpty: true,
        message: "Model Name is required.",
      });
    } else if (
      data.workSpaceName !== "" &&
      data.projectName !== "" &&
      data.modelName !== "" &&
      this.state.useradd.length === 0 &&
      this.state.ocrweightage.length !== 0
    ) {
      value = value + 1;
      this.setState({
        userEmpty: true,
        message: "select at least one user to Edit Project Workspace",
      });
    }
    // } else if (
    //   data.workSpaceName !== "" &&
    //   data.projectName !== "" &&
    //   data.modelName !== "" &&
    //   this.state.useradd.length !== 0 &&
    //   this.state.ocrweightage.length === 0
    // ) {
    //   value = value + 1;
    //   this.setState({
    //     userEmpty: true,
    //     message: "Add OCR Weightage to Edit Project Workspace",
    //   });
    // } else if (
    else if (
      data.workSpaceName !== "" &&
      data.projectName === "" &&
      data.modelName !== "" &&
      this.state.useradd.length === 0 &&
      this.state.ocrweightage.length !== 0
    ) {
      value = value + 1;
      this.setState({
        userEmpty: true,
        message:
          "Client name and one user is required to create new Project Workspace",
      });
    } else if (
      data.workSpaceName === "" &&
      data.projectName !== "" &&
      data.modelName !== "" &&
      this.state.useradd.length === 0 &&
      this.state.ocrweightage.length !== 0
    ) {
      value = value + 1;
      this.setState({
        userEmpty: true,
        message:
          "Project Workspace name and one user is required to create new Project Workspace",
      });
    } else if (
      data.workSpaceName !== "" &&
      data.projectName !== "" &&
      data.modelName === "" &&
      this.state.useradd.length === 0 &&
      this.state.ocrweightage.length !== 0
    ) {
      value = value + 1;
      this.setState({
        userEmpty: true,
        message:
          "Model Name and one user is required to create new Project Workspace",
      });
    } else if (
      data.workSpaceName !== "" &&
      data.projectName === "" &&
      data.modelName !== "" &&
      this.state.useradd.length !== 0 &&
      this.state.ocrweightage.length === 0
    ) {
      value = value + 1;
      this.setState({
        userEmpty: true,
        message:
          "Client name and OCR Weightage is required to create new Project Workspace",
      });
    } else if (
      data.workSpaceName === "" &&
      data.projectName !== "" &&
      data.modelName !== "" &&
      this.state.useradd.length !== 0 &&
      this.state.ocrweightage.length === 0
    ) {
      value = value + 1;
      this.setState({
        userEmpty: true,
        message:
          "Project Workspace name and OCR Weightage is required to create new Project Workspace",
      });
    } else if (
      data.workSpaceName !== "" &&
      data.projectName !== "" &&
      data.modelName === "" &&
      this.state.useradd.length !== 0 &&
      this.state.ocrweightage.length === 0
    ) {
      value = value + 1;
      this.setState({
        userEmpty: true,
        message:
          "Model Name and OCR Weightage is required to create new Project Workspace",
      });
    } else {
      const checkworkspacename = this.state.WorkspaceNameNew;
      const checkprojectname = this.state.projectNameNew;
      const checkmodelname = this.state.modelNameNew;
      const data1 = result.filter(
        (item) =>
          item.projectName == checkprojectname &&
          item.workSpaceName == checkworkspacename &&
          item.modelName == checkmodelname
      );

      if (data1.length > 0) {
        this.setState({
          userEmpty: true,
          duplicateData: true,
        });
      } else {
        this.setState({
          workSpaceError: false,
          projectNameError: false,
          modelNameError: false,
          workSpaceMessage: "",
          projectNameMessage: "",
          modelNameMessage: "",
        });
        if (this.state.WorkspaceNameNew === "") {
          this.setState({ workSpacePopupError: true });
        }
        if (this.state.projectNameNew === "") {
          this.setState({ clientPopupError: true });
        }
        if (this.state.modelNameNew === "") {
          this.setState({ modelPopupError: true });
        }

        if (this.state.useradd.length === 0) {
          this.setState({ userEmpty: true, emptyUser: true });
        } else {
          for (var i in this.state.useradd) {
            if (this.state.useradd[i].roleId == "Admin") {
              this.state.useradd[i].roleId = 1;
            } else if (this.state.useradd[i].roleId == "Read / Write") {
              this.state.useradd[i].roleId = 2;
            } else {
              this.state.useradd[i].roleId = 3;
            }
          }
          if (this.state.WorkspaceNameNew == "") {
            this.state.WorkspaceNameNew = this.state.editRow.workSpaceName;
          } else if (this.state.projectNameNew == "") {
            this.state.projectNameNew = this.state.editRow.projectName;
          } else if (this.state.modelNameNew == "") {
            this.state.modelNameNew = this.state.editRow.aiModelId;
          }
          const data = {
            workSpaceId: this.state.editRow.workSpaceId,
            projectName: this.state.projectNameNew,
            workSpaceName: this.state.WorkspaceNameNew,
            aiModelId: this.state.modelNameNew,
            workSpaceUsers: this.state.useradd,
            detectionExpression: UserStore.setDetectionQuery,
          };

          UserStore.setEditWorkspacedata(data);
          localStorage.setItem("setEditWorkspacedata", JSON.stringify(data));
        }
        if (value === 0) {
          if (this.state.editRow.isIsometricTickBased) {
            this.props.history.push(
              `${this.props.match.path}/RegularExpression`
            );
            // const data = toJS(UserStore.editWorkspacedata);
            // await axios.post(API_Endpoint + `/EditWorkSpace`, data);
          } else {
            this.props.history.push(
              `${this.props.match.path}/SetDetectionlogic`
            );
          }
        }
      }
    }
  };

  handleClose = () => {
    this.setState({ open: false, projectname: false, workspace: false });
  };

  // Function: Add new user to the workspace
  AddUser = () => {
    this.setState({ tooltipError: false, tootlTipErrorMessage: "" });
    if (this.state.personName.length === 0) {
      this.setState({
        tooltipError: true,
        tootlTipErrorMessage: "Kindly add a valid email address",
      });
    } else {
      for (var i = 0; i < this.state.personName.length; i++) {
        const userdata = {
          enterpriseId: this.state.personName[i],
          roleId: this.state.role,
        };
        this.state.useradd.push(userdata);
        UserStore.editRow[0].workSpaceUsers.push(userdata);
        this.setState({
          role: this.state.dropdownvalue,
          personName: [],
          suggestions: [],
        });
      }

      function getUnique(arr, index) {
        const unique = arr
          .map((e) => e[index])
          // store the keys of the unique objects
          .map((e, i, final) => final.indexOf(e) === i && i)

          // eliminate the dead keys & store unique objects
          .filter((e) => arr[e])
          .map((e) => arr[e]);
        return unique;
      }
      this.state.useradd.reverse();
      this.state.useradd = getUnique(this.state.useradd, "enterpriseId");
      document.getElementById("search-box1").value = "";
      this.setState({
        show: true,
        q: "",
      });
    }
  };

  //-----------------------------------Dropdown for role change------------------------------

  handleRoleChange = (e) => {
    if (e.target.value === "Admin") {
      this.state.role = "Admin";
      this.setState({
        dropdownvalue: e.target.value,
        role: "Admin",
      });
      this.state.dropdownvalue = e.target.value;
    } else if (e.target.value === "Read / Write") {
      this.state.role = "Read / Write";
      this.setState({
        dropdownvalue: e.target.value,
        role: "Read / Write",
      });
    } else if (e.target.value === "Read Only") {
      this.state.role = "Read Only";
      this.setState({
        dropdownvalue: e.target.value,
        role: "Read Only",
      });
    }
    this.state.rolearray.push(e.target.value);
  };

  //---------------------get the suggestions----------------------------------------

  suggestionsCall = async (q) => {
    const res = await axios.get(
      `${process.env.REACT_APP_API_GETUSERLIST}/GetUsersList?username=${q}`
    );
    this.setState({
      suggestions: res.data,
    });
    this.state.modifysuggestion = this.state.suggestions;
    this.filterSearchText();
  };

  onSearchHandler = () => {
    this.setState({ showSuggestions: false });
  };

  onChangeHandler1 = () => {
    let searchTerm = "";
    searchTerm = document.getElementById("search-box1").value.toLowerCase();
    this.setState({
      q: searchTerm,
    });
    if (searchTerm.length === 3) {
      this.suggestionsCall(searchTerm);
      this.setState({
        showSuggestions: true,
      });
    }
    this.filterSearchText();
  };

  pasteFunction = (e) => {
    let searchTerm = "";
    searchTerm = e.clipboardData.getData("text").toLowerCase();
    this.setState({
      q: searchTerm,
      personName: [],
    });
    let substring = searchTerm.substring(0, 3);
    this.suggestionsCall(substring);
    this.filterSearchText();
  };

  filterSearchText = () => {
    let searchTerm = "";
    searchTerm = document.getElementById("search-box1").value.toLowerCase();
    for (var i = 0; i < this.state.useradd.length; i++) {
      if (this.state.useradd[i].enterpriseId === searchTerm) {
        this.setState({
          tooltipError: true,
          tootlTipErrorMessage: "override the user role",
        });
      }
    }
    this.state.suggestions = this.state.modifysuggestion;
    const updatedRows = this.state.suggestions.filter(
      (row) =>
        row.mail.toLowerCase().includes(searchTerm) ||
        row.displayName.toLowerCase().includes(searchTerm)
    );

    this.setState({ suggestions: updatedRows });
    if (updatedRows.length == 1 && searchTerm.includes("@petrofac.com")) {
      if (Object.values(updatedRows[0]).includes(searchTerm)) {
        document.getElementById("search-box1").focus();
        this.state.personName[0] = searchTerm;
        return true;
      }
    }
  };
  onEnterButton = (event) => {
    if (event.keyCode === 13) {
      this.onSearchHandler();
    }
  };
  //--------------------------close dialog----------------------------
  handleClose = () => {
    this.setState({
      show: false,
      OCRshow: false,
    });
  };

  inputValidation = () => {
    let value = 0;
    const data = {
      workSpaceId: this.state.editRow.workSpaceId,
      projectName: this.state.projectNameNew,
      workSpaceName: this.state.WorkspaceNameNew,
      aiModelId: this.state.modelNameNew,
    };

    if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(data.workSpaceName)) {
      value = value + 1;
      this.setState({
        workSpaceError: true,
        workSpaceMessage:
          "Workspace name should not contain special characters",
      });
    }
    if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(data.projectName)) {
      value = value + 1;
      this.setState({
        projectNameError: true,
        projectNameMessage:
          "Project name should not contain special characters",
      });
    }
    if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(data.aiModelId)) {
      value = value + 1;
      this.setState({
        modelNameError: true,
        modelNameMessage: "Model name should not contain special characters",
      });
    }
    if (value === 0) {
      this.setState({
        workSpaceError: false,
        workSpaceMessage: "",
        projectNameError: false,
        projectNameMessage: "",
        modelNameError: false,
        modelNameMessage: "",
      });
    }
  };

  handleChange = (e) => {
    if (e.target.id === "1") {
      this.setState({
        WorkspaceNameNew: e.target.value,
      });
      if (e.target.value !== "") {
        this.setState({
          message: "",
        });
      }
    } else if (e.target.id === "2") {
      this.setState({
        projectNameNew: e.target.value,
      });
      if (e.target.value !== "") {
        this.setState({
          message: "",
        });
      }
    } else {
      this.setState({
        modelNameNew: e.target.value,
      });
      if (e.target.value !== "") {
        this.setState({
          message: "",
        });
      }
    }
  };

  // -------------------------Start:Function: Suggestions select------------------
  suggestionClickHandler = (s) => {
    document.getElementById("search-box1").value = s;

    for (var i = 0; i < this.state.useradd.length; i++) {
      if (this.state.useradd[i].enterpriseId === s) {
        this.setState({
          tooltipError: true,
          tootlTipErrorMessage: "override the user role",
        });
      }
    }
    this.state.personName[0] = s;
    this.setState({
      showSuggestions: false,
    });
  };
  // -------------------------End:Function: Suggestions select------------------

  suggestionOutClickHandler = (event) => {
    if (this.wrapperRef.current) {
      if (!this.wrapperRef.current.contains(event.target)) {
        this.setState({
          showSuggestions: false,
        });
      }
    }
  };

  // ------------------------Start:Function: Delete user in workspace------------------
  handlePurge = (e, user) => {
    const updatedRows = this.state.useradd.filter(
      (row) => row.enterpriseId !== user.enterpriseId
    );
    this.setState({
      useradd: updatedRows,
      delete: false,
    });
  };
  // ------------------------End:Function: Delete user in workspace------------------

  handleCloseDialog = () => {
    this.setState({ delete: false });
  };

  handleDelete = (e, user) => {
    this.setState({ delete: true, deleteUsers: user });
  };

  render() {
    let suggestionDiv;
    if (this.state.showSuggestions) {
      suggestionDiv = (
        <Suggestions
          suggestions={this.state.suggestions}
          suggestionClickHandler={(s) => this.suggestionClickHandler(s)}
        ></Suggestions>
      );
    } else {
      suggestionDiv = <div></div>;
    }

    return (
      <ErrorBoundary>
        <div class="create-workspace-container">
          <AppHeader />
          <BreadCrumbs />
          <LoadingOverlay
            active={this.state.loading}
            spinner
            text="Loading your content..."
          ></LoadingOverlay>
          <MuiThemeProvider>
            <div class="user-form-wrapper">
              <div class="user-formblank">
                <p class="create-text">Edit Project Workspace</p>
                <img src="/images/EditWK.svg" class="icon-workspace" />
              </div>
              <div className="userform-wrapper">
                <div class="user-form">
                  <TextField
                    error={this.state.workSpaceError}
                    autoFocus
                    margin="dense"
                    id="1"
                    label="Project Workspace Name"
                    type="text"
                    onChange={this.handleChange}
                    defaultValue={this.state.editRow.workSpaceName}
                    fullWidth
                    disabled
                    helperText={this.state.workSpaceMessage}
                  />

                  <TextField
                    error={this.state.projectNameError}
                    margin="dense"
                    id="2"
                    label="Client Name"
                    onChange={this.handleChange}
                    defaultValue={this.state.editRow.projectName}
                    fullWidth
                    disabled
                    helperText={this.state.projectNameMessage}
                  />

                  <TextField
                    error={this.state.modelNameError}
                    margin="dense"
                    id="3"
                    label="Model Name"
                    onChange={this.handleChange}
                    defaultValue={this.state.editRow.aiModelId}
                    fullWidth
                    disabled
                    helperText={this.state.modelNameMessage}
                  />

                  <div className="users-bar">
                    <div
                      className="input-group"
                      onKeyDown={(e) => this.onEnterButton(e)}
                    ></div>
                  </div>

                  <div
                    className="tick-phrase"
                    style={{
                      font: "normal normal normal 1vw Helvetica",
                      marginLeft: "3%",
                      paddingTop: "0.5vw",
                    }}
                  >
                    <input
                      type="radio"
                      name="phrase"
                      id="phrase"
                      value="Phrase"
                      defaultChecked={!this.state.editRow.isIsometricTickBased}
                      disabled
                    />
                    <label for="phrase" style={{ padding: "0 1.5vw 0 0.3vw" }}>
                      Phrase Based
                    </label>
                    <input
                      type="radio"
                      name="phrase"
                      id="tickregular"
                      value="TickRegular"
                      defaultChecked={this.state.editRow.isIsometricTickBased}
                      disabled
                    />
                    <label
                      for="tickregular"
                      style={{ padding: "0 0.5vw 0 0.3vw" }}
                    >
                      Tick Based{" "}
                    </label>
                  </div>
                  {this.state.editRow.isIsometricTickBased ? 
                  <div
                    className="custom"
                    style={{
                      font: "normal normal normal 1vw Helvetica",
                      marginLeft: "3%",
                      paddingTop: "0.5vw",
                      color: "#0c629b",
                    }} 
                  >
                    <span style={{marginTop:"1vw"}}>Custom Vision processing required ?</span>
                    <span style ={{paddingLeft:"1vw"}}>
                    <input
                      type="radio"
                      name="customVisionData"
                      id="no"
                      value="no"
                      defaultChecked = {!this.state.editRow.customVisionProcessingRequired}
                      disabled
                    />
                    <label for="no" style={{ padding: "0 1.5vw 0 0.3vw" }}>
                     No
                    </label>
                    <input
                      type="radio"
                      name="customVisionData"
                      id="yes"
                      value="yes"
                      defaultChecked = {this.state.editRow.customVisionProcessingRequired}
                      disabled
                    />
                    <label
                      for="yes"
                      style={{ padding: "0 0.5vw 0 0.3vw" }}
                    >
                      Yes{" "}
                    </label>
                    </span>
                  </div>
                  : <></>}

                  {this.state.editRow.customVisionProcessingRequired ? 
                  <div style={!this.state.editRow.isIsometricTickBased ? {display : "none"} : {} }>
                    <TextField className="modelNameCV" id="modelNameCV" disabled defaultValue = {this.state.editRow.customVisionPredictionKey} label = "Custom Vision Prediction Key" style = {{paddingLeft: "1.5vw",width:"15vw"}}></TextField>
                    <TextField className="modelIdCV" id="modelIdCV" disabled defaultValue = {this.state.editRow.customVisionModelName}  label = "Custom Vision Model Name" style = {{paddingLeft: "4vw",width:"17vw"}}></TextField>
                  </div> 
                   : <></>} 

                  <br></br>
                  <div className="Link">
                    <span
                      onClick={() => {
                        this.setState({ show: true });
                      }}
                    >
                      Edit User
                    </span>
                  </div>

                  <div className="Link">
                    <span
                      onClick={() => {
                        this.setState({ OCRshow: true });
                      }}
                    >
                      Edit OCR Weightage
                    </span>
                  </div>

                  <div className="users-list">
                    {this.state.list.join(",")}
                    {this.val}
                  </div>

                  <div className="action-btns">
                    {/* {!this.state.editRow.isIsometricTickBased &&
                  <Button
                      variant="contained"
                      class="next-btn"
                      onClick={this.savedata}
                    >                     
                         Save                         
                    </Button>} */}
                    <Button
                      variant="contained"
                      class="next-btn"
                      onClick={this.continue}
                      style={{
                        margin: "-2vw 1.9vw 0 1vw",
                        width: "8.4vw",
                        height: "2.29vw",
                        fontSize: "0.9vw",
                        background: "#0c629b",
                        color: "#ffffff",
                        textTransform: "none",
                      }}
                    >
                      Next
                    </Button>
                    <Button
                      variant="contained"
                      class="cancel-btn"
                      onClick={() => this.props.history.push("/Workspace")}
                      style={{
                        background: "#ffffff",
                        border: "0.05vw solid #0c629b",
                        borderRadius: "0.15vw",
                        width: "8.4vw",
                        height: "2.29vw",
                        marginTop: "-2vw",
                        padding: "0.15vw 0",
                        fontSize: "0.9vw",
                        color: "#0c629b",
                        textTransform: "none",
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                  <Modal
                    className="user-select-popup"
                    show={this.state.userEmpty}
                    onHide={this.handleCloseEmptyUser}
                    backdrop="static"
                    keyboard={false}
                    dialogClassName="fullmodal"
                    size="l"
                    centered
                  >
                    <Modal.Body>
                      <label
                        style={{
                          font: "normal normal bold 1vw Helvetica",
                          textAlign: "left",
                        }}
                      >
                        {this.state.message}
                        {this.state.duplicateData ? (
                          <p>Project workspace name already exists. exists.</p>
                        ) : (
                          ""
                        )}
                        {this.state.emptyUser &&
                        !(
                          this.state.workSpacePopupError ||
                          this.state.clientPopupError ||
                          this.state.modelPopupError
                        ) ? (
                          <p>
                            Please select at least one user to Edit Project
                            Workspace{" "}
                          </p>
                        ) : (
                          ""
                        )}
                        <IconButton
                          onClick={this.handleCloseEmptyUser}
                          style={{
                            padding: "3px",
                            position: "absolute",
                            top: "0",
                            right: "0",
                            background: "",
                            borderRadius: "0",
                            color: "#0C629B",
                          }}
                        >
                          {" "}
                          <CloseIcon
                            style={{ width: "1.5vw", height: "1.5vw" }}
                          />{" "}
                        </IconButton>
                      </label>
                    </Modal.Body>
                  </Modal>
                </div>
              </div>

              <Dialog
                className="addUserTable"
                open={this.state.show}
                onClose={this.handleClose}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle id="form-dialog-title">
                  User Details
                  <IconButton onClick={this.handleClose}>
                    {" "}
                    <CloseIcon />{" "}
                  </IconButton>
                </DialogTitle>
                <DialogContent>
                  {
                    <table width="100px">
                      <tr>
                        <td>
                          <div
                            className="input-group"
                            onKeyDown={(e) => this.onEnterButton(e)}
                            style={{ marginTop: "1%" }}
                          >
                            <div className="suggestions" ref={this.wrapperRef}>
                              <Tooltip
                                title="Please type 3 characters to search for user"
                                arrow
                              >
                                <TextField
                                  autoComplete="off" // setting for browsers; not the app
                                  type="text"
                                  id="search-box1"
                                  className="form-control"
                                  placeholder="Add users"
                                  title="Please type 3 characters to search for user"
                                  onChange={this.onChangeHandler1}
                                  onPaste={(e) => {
                                    this.pasteFunction(e);
                                  }}
                                  onBlur={(e) => {}}
                                  onClick={() => {
                                    if (this.state.suggestions.length > 0)
                                      this.setState({ showSuggestions: true });
                                  }}
                                  error={this.state.tooltipError}
                                  helperText={this.state.tootlTipErrorMessage}
                                  style={{ marginTop: "5%" }}
                                />
                              </Tooltip>
                              {suggestionDiv}
                            </div>
                          </div>
                        </td>
                        <td>
                          <TextField
                            autoFocus
                            margin="dense"
                            id="assignRole"
                            select
                            className="assign-role-dropdown"
                            label="Assign User Group"
                            style={{ marginRight: "2% !important" }}
                            onChange={this.handleRoleChange}
                            value={this.state.dropdownvalue}
                            SelectProps={{ native: true }}
                          >
                            {this.state.roles.map((option) => (
                              <option
                                key={option.roleId}
                                value={option.roleName}
                              >
                                {option.roleName}
                              </option>
                            ))}
                          </TextField>
                        </td>
                        <td>
                          <p class="add-users-icon" onClick={this.AddUser}>
                            <Tooltip title="Add users to workspace" arrow>
                              <img
                                src="/images/icon_add_blue.svg"
                                style={{
                                  paddingLeft: "10",
                                  color: "#0C629B",
                                  textDecoration: "underline",
                                  height: "1.5vw",
                                  width: "1.5vw",
                                  marginTop: "1vw",
                                }}
                                alt="addButton"
                              />
                            </Tooltip>
                          </p>
                        </td>
                      </tr>
                      {this.state.useradd.map((items) => (
                        <tr style={{ marginTop: "1%" }}>
                          <td>{items.enterpriseId}</td>
                          <td>{items.roleId}</td>
                          <td>
                            <Tooltip title="Delete User" arrow>
                              <img
                                id={items.enterpriseId}
                                onClick={(e) => {
                                  this.handleDelete(e, items);
                                }}
                                src="/images/icon_delete.svg"
                                style={{ height: "1.3vw" }}
                              />
                            </Tooltip>
                          </td>
                        </tr>
                      ))}
                    </table>
                  }
                </DialogContent>
                <DialogActions style={{ marginRight: "1vw" }}>
                  <Button
                    variant="contained"
                    class="next-btn"
                    onClick={this.handleClose}
                    style={{ height: "4vh", width: "8vw" }}
                  >
                    Ok
                  </Button>
                </DialogActions>
              </Dialog>

              <Dialog
                className="addUserTable OCRWeightage"
                open={this.state.OCRshow}
                onClose={this.handleClose}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle id="form-dialog-title">
                  OCR Weightage
                  <IconButton onClick={this.handleClose}>
                    {" "}
                    <CloseIcon />{" "}
                  </IconButton>
                </DialogTitle>
                <DialogContent>
                  <div>
                    <label
                      for="linenum"
                      style={{ padding: "1vw 10.1vw 0 0.3vw" }}
                    >
                      Line No
                    </label>
                    <TextField
                      type="number"
                      name="linenum"
                      label="Linenum"
                      inputProps={{
                        min: 0,
                        max: 1,
                        step: "0.1",
                      }}
                      id="linenum"
                      disabled
                      defaultValue={this.state.lineNum}
                      //onChange= {(e) => {this.setState({lineNum: e.target.value})}}
                      style={{ paddingRight: "1vw" }}
                    />
                    <TextField
                      type="number"
                      name="linenumThreshold"
                      label="Linenum Threshold"
                      inputProps={{
                        min: 0,
                        max: 1,
                        step: "0.1",
                      }}
                      id="linenumThreshold"
                      disabled
                      defaultValue={this.state.lineNumThreshold}
                      //onChange= {(e) => {this.setState({lineNumThreshold: e.target.value})}}
                    />
                  </div>
                  <div>
                    <label
                      for="isonum"
                      style={{ padding: "1vw 7.6vw 0 0.3vw" }}
                    >
                      Isometric No
                    </label>
                    <TextField
                      type="number"
                      name="isonum"
                      label="Isonum"
                      inputProps={{
                        min: 0,
                        max: 1,
                        step: "0.1",
                      }}
                      id="isonum"
                      disabled
                      defaultValue={this.state.isoNum}
                      //onChange= {(e) => {this.setState({isoNum: e.target.value})}}
                      style={{ paddingRight: "1vw" }}
                    />
                    <TextField
                      type="number"
                      name="isonumThreshold"
                      label="Isonum Threshold"
                      inputProps={{
                        min: 0,
                        max: 1,
                        step: "0.1",
                      }}
                      id="isonum"
                      disabled
                      defaultValue={this.state.isoNumThreshold}
                      //onChange= {(e) => {this.setState({isoNumThreshold: e.target.value})}}
                    />
                  </div>

                  <div>
                    <label
                      for="sheetnum"
                      style={{ padding: "1vw 9.1vw 0 0.3vw" }}
                    >
                      Sheet No
                    </label>
                    <TextField
                      type="number"
                      name="sheetnum"
                      label="Sheetnum"
                      inputProps={{
                        min: 0,
                        max: 1,
                        step: "0.1",
                      }}
                      id="sheetnum"
                      disabled
                      defaultValue={this.state.sheetNum}
                      //onChange= {(e) => {this.setState({sheetNum: e.target.value})}}
                      style={{ paddingRight: "1vw" }}
                    />
                    <TextField
                      type="number"
                      name="sheetnumThreshold"
                      label="Sheetnum Threshold"
                      inputProps={{
                        min: 0,
                        max: 1,
                        step: "0.1",
                      }}
                      id="sheetnum"
                      disabled
                      defaultValue={this.state.sheetNumThreshold}
                      //onChange= {(e) => {this.setState({sheetNumThreshold: e.target.value})}}
                    />
                  </div>
                  <div>
                    <label
                      for="revnum"
                      style={{ padding: "1vw 10.1vw 0 0.3vw" }}
                    >
                      Rev No
                    </label>
                    <TextField
                      type="number"
                      name="revnum"
                      label="Revnum"
                      inputProps={{
                        min: 0,
                        max: 1,
                        step: "0.1",
                      }}
                      id="revnum"
                      defaultValue={this.state.revNum}
                      disabled
                      //onChange= {(e) => {this.setState({revNum: e.target.value})}}
                      style={{ paddingRight: "1vw" }}
                    />
                    <TextField
                      type="number"
                      name="revnumThreshold"
                      label="Revnum Threshold"
                      inputProps={{
                        min: 0,
                        max: 1,
                        step: "0.1",
                      }}
                      id="revnum"
                      defaultValue={this.state.revNumThreshold}
                      disabled
                      //onChange= {(e) => {this.setState({revNumThreshold: e.target.value})}}
                    />
                  </div>
                  <div
                    style={
                      this.state.OCRmessage
                        ? { display: "block", color: "red" }
                        : { display: "none" }
                    }
                  >
                    Add OCR Weightage and Threshold before Save
                  </div>
                </DialogContent>
                <DialogActions style={{ padding: "0 13vw 0 0" }}>
                  <Button
                    variant="contained"
                    class="next-btn"
                    onClick={this.handleSave}
                    style={{
                      height: "5vh",
                      width: "8vw",
                      cursor: "not-allowed",
                      opacity: "0.5",
                      pointerEvents: "none",
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    variant="contained"
                    class="cancel-btn"
                    onClick={this.handleClose}
                  >
                    Cancel
                  </Button>
                </DialogActions>
              </Dialog>

              <Dialog
                className="delete-confirm"
                open={this.state.delete}
                onClose={this.handleCloseDialog}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle
                  id="form-dialog-title"
                  style={{ height: "0.625em" }}
                ></DialogTitle>
                <DialogContent>
                  <div style={{ fontSize: "1vw" }}>
                    Do you want to delete the user?
                  </div>
                </DialogContent>
                <DialogActions>
                  <IconButton
                    onClick={this.handleCloseDialog}
                    style={{
                      padding: "0",
                      float: "right",
                      background: "#0C629B",
                      color: "#fff",
                      position: "absolute",
                      right: "15%",
                    }}
                  >
                    {" "}
                    <Cancel />{" "}
                  </IconButton>
                  <IconButton
                    onClick={(e) => this.handlePurge(e, this.state.deleteUsers)}
                    style={{
                      padding: "0",
                      background: "#0C629B",
                      color: "white",
                      position: "absolute",
                      right: "2%",
                    }}
                  >
                    {" "}
                    <CircleChecked />{" "}
                  </IconButton>
                </DialogActions>
              </Dialog>

              <Dialog
                className="addUserTable"
                onClose={this.handleClose}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle id="form-dialog-title">
                  Add Users
                  <IconButton onClick={this.handleClose}>
                    {" "}
                    <CloseIcon />{" "}
                  </IconButton>
                </DialogTitle>
                <DialogContent>
                  {
                    <table border="1">
                      <thead align="center" valign="bottom" char="M">
                        <tr>
                          <th align="center">User</th>
                          <th align="center">Role</th>
                          <th align="center">Delete</th>
                        </tr>
                      </thead>
                      {this.state.useradd.map((items) => (
                        <tr>
                          <td>{items.enterpriseId}</td>
                          <td>{items.roleId}</td>
                          <td>
                            <img
                              id={items.enterpriseId}
                              onClick={(e) => {
                                if (
                                  window.confirm(
                                    "Are you sure you wish to remove this user"
                                  )
                                )
                                  this.handlePurge(e, items);
                              }}
                              src="/images/icon_delete.svg"
                              style={{ height: "1.3vw" }}
                            />
                          </td>
                        </tr>
                      ))}
                    </table>
                  }
                </DialogContent>
              </Dialog>
            </div>
          </MuiThemeProvider>
          <AppFooter />
        </div>
      </ErrorBoundary>
    );
  }
}
