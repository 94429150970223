let deletedData = [];

/*Function for filtering linenumbers */
export function filterData(data ,jsonData, assetType) {
  let lineGroup = "";
  lineGroup = jsonData.filter((row) => row[1].specification === data);
  return lineGroup[0];
}

export function getLineGroup(jsonData) {
  let lineGroup = [];
  jsonData.filter((row) => lineGroup.push(row[1].specification));
  return lineGroup;
}

export function getShortCode(jsonData) {
  let shortCode = [];
  jsonData.filter((row) => Object.entries(row[1].Symbols).filter((data) => shortCode.push(data[1].ShortCode)));
  /* for removing duplicates */
  shortCode = [...new Set(shortCode)]; 
  return shortCode;
}

/* Function for saving details of newly added asset */
export function addNewAsset(assetDetails, assetData) {
  let newAssetId = "";
  let newAsset = {};
  if (assetData.assetType === "symbols") {
    const symbols = Object.entries(assetDetails[1].Symbols);
    if (symbols.length !== 0) {
      let symbolsArray = [];
      for(let index in symbols) {
        symbolsArray.push(parseInt(symbols[index][0].split("_")[1]));
      }
      symbolsArray = symbolsArray.sort(function(key1, key2) {
        return key1 - key2;
      });
      const id = symbolsArray[symbolsArray.length - 1];
      newAssetId = "SYM_" + (id + 1);
    } else {
      newAssetId = "SYM_0";
    }
    const properties = {
      ShortCode: assetData.ShortCode,
      assetComponentType: assetData.assetComponentType,
      Coords: assetData.Coords,
      SymbolId: assetData.SymbolId,
      Size1: assetData.Size1,
     Size2: assetData.Size2,
    IsJacketedLine : assetData.IsJacketedLine,
     InsulationType: assetData.InsulationType,
     AgUg : assetData.AgUg,
     Seat : assetData.Seat,
     ValveBody : assetData.ValveBody,
     ExtendedBonet : assetData.ExtendedBonet,
     LimitSwich:assetData.LimitSwich,
     Hold :assetData.Hold,
     Quantity: assetData.Quantity,
     SequenceNumber: assetData.SequenceNumber,
     Spec: assetData.Spec,
     HdrSize: assetData.HdrSize,
     ToLine: assetData.ToLine,
     FromLine:assetData.FromLine,
     Remarks:assetData.Remarks,
     ItemOrder:assetData.ItemOrder,
     Fluid: assetData.Fluid,
     Rating: assetData.Rating,
     ConfidenceScore: assetData.ConfidenceScore,
     SymbolRank: assetData.SymbolRank,
    };
    newAsset  = [newAssetId, properties];
  } else {
    const lines = Object.entries(assetDetails[1].Lines);
    if (lines.length !== 0) {
      const id = lines[lines.length - 1][0].split("_");
      newAssetId = "LS_" + (parseInt(id[1]) + 1);
    } else {
      newAssetId = "LS_0";
    }
    const properties = {
      Coords: assetData.Coords,
      orientation: assetData.orientation,
      LineSegmentId: assetData.LineSegmentId,
    };
    newAsset = [newAssetId, properties];
  }
  return newAsset;
}
/* Function for updating details of  asset */
export function updateJsonData(data, jsonData, assetType, lineGroup) {
  // let newValue = data[1];
  if (assetType === "symbols") {
    jsonData[lineGroup]["Symbols"][data[0]] = data[1];
  } else {
    jsonData[lineGroup]["Lines"][data[0]] = data[1];
  }
  return jsonData;
}
/* Function for saving details of edited  asset */
export function editJsonData(oldValue, newValue, jsonData, lineGroup) {
  const assetType = oldValue.assetId.split("_");
  if (assetType[0] === "SYM") {
    const properties = {
      assetComponentType: newValue.assetCompType,
      ShortCode: newValue.shortCode,
      Coords: jsonData[lineGroup]["Symbols"][oldValue.assetId].Coords,
      SymbolId: newValue.id,
      SequenceNumber: newValue.Seq,
      Spec: newValue.Spec,
      IsJacketedLine: newValue.isJacketed,
      HdrSize: newValue.hdr,
      Size1: newValue.size1,
      Size2: newValue.size2,
      // Quantity: newValue.quantity,
      InsulationType: newValue.insulationType,
      AgUg: newValue.ugag,
      Fluid: newValue.fluid,
      Seat: newValue.seat,
      ValveBody: newValue.valveBody,
      ExtendedBonet: newValue.extendedBonet,
      LimitSwich: newValue.limitSwich,
      Hold: newValue.hold,
      Quantity: newValue.Quantity === undefined ? newValue.quantity : newValue.Quantity,
      Rating: newValue.Rating === undefined ? newValue.rating : newValue.Rating,
      SymbolRank: newValue.SymbolRank,
      ConfidenceScore: newValue.ConfidenceScore,
      FromLine: newValue.fromLine,
      ToLine: newValue.toLine,
      CorrectedRow: newValue.CorrectedRow,
      ItemOrder: null,
      Remarks: newValue.remarks,
    };
    jsonData[lineGroup]["Symbols"][oldValue.assetId] = properties;
  } else {
    const properties = {
      Coords: jsonData[lineGroup]["Lines"][oldValue.assetId].Coords,
      orientation: jsonData[lineGroup]["Lines"][oldValue.assetId].orientation,
      LineSegmentId: newValue.LineSegmentId,
    };
    jsonData[lineGroup]["Lines"][oldValue.assetId] = properties;
  }
  return jsonData;
}

/* Function for adding new line group */
export function addNewLineGroup(data, jsonData) {
  let convertedJson = Object.entries(jsonData);
  const id = convertedJson[convertedJson.length - 1][0].split("_");
  const newId = "LG_" + (parseInt(id[1]) + 1);
  if (data.assetType === "symbols") {
    const shapeProperties = {
      ShortCode: data.ShortCode,
      assetComponentType: data.assetComponentType,
      Coords: data.Coords,
      SymbolId: data.SymbolId,
      Size1: data.Size1,
     Size2: data.Size2,
     IsJacketedLine : data.isJacketedLine,
    //  ShortCode : data.ShortCode,
     InsulationType: data.InsulationType,
     AgUg : data.AgUg,
     Seat : data.Seat,
     ValveBody : data.ValveBody,
     ExtendedBonet : data.ExtendedBonet,
     LimitSwich:data.LimitSwich,
     Hold :data.Hold,
     Quantity: data.Quantity,
     SequenceNumber: data.SequenceNumber,
     Spec: data.Spec,
     HdrSize: data.HdrSize,
     ToLine: data.ToLine,
     FromLine:data.FromLine,
     Remarks:data.Remarks,
     ItemOrder:data.ItemOrder,
     Fluid: data.Fluid,
     Rating: data.Rating === undefined ? data.rating : data.Rating,
      SymbolRank: data.SymbolRank,
      ConfidenceScore: data.ConfidenceScore,
    };
    const symbolData = {
      SYM_0: shapeProperties,
    };
    const properties = {
      specification: data.specification,
      Symbols: symbolData,
      Lines:{},
    };
    jsonData[newId] = properties;
  } else {
    const shapeProperties = {
      Coords: data.Coords,
      orientation: data.orientation,
      LineSegmentId: data.LineSegmentId,
    };
    const symbolData = {
      LS_0: shapeProperties,
    };
    const properties = {
      specification: data.specification,
      Lines: symbolData,
      Symbols:{}
    };
    jsonData[newId] = properties;
  }
  return jsonData;
}

export function transform(data, jsonData, lineGroup) {
  jsonData[lineGroup][data.assetType][data.id].Coords = data.Coords;
  jsonData[lineGroup][data.assetType][data.id].orientation = data.orientation;
  return jsonData;
}

/* check if this is used or not if not remove this function******************** */
export function editAssetWindow1(
  oldValue,
  newValue,
  jsonData,
  lineGroup1,
  lineGroup2
) {
  
  const lines = Object.entries(jsonData[lineGroup2][oldValue.assetType]);
  let newAssetId = "";
  if (lines.length !== 0) {
    const id = lines[lines.length - 1][0].split("_");
    newAssetId = "LS_" + (parseInt(id[1]) + 1);
  } else {
    newAssetId = "LS_0";
  }
  const properties = {
    coords: newValue.coords,
    orientation: newValue.orientation,
  };
  
}
/* Function for editing the properties of symbol through window1*/
export function editDataWindow1(data,jsonData,lineGroup) {
  if(data.lineNumber !== data.oldLineNumber) {
    const oldLineGroup = filterData(
      data.oldLineNumber,
       Object.entries(jsonData)
     );
    delete data["oldLineNumber"];
    const assets = filterData(data.lineNumber,Object.entries(jsonData));
  const modifiedData = addNewAsset(assets,data);
  const updatedJson = updateJsonData(
    modifiedData,
   jsonData,
    "symbols",
    assets[0]
  );
  delete updatedJson[oldLineGroup[0]]["Symbols"][data.id];
  return updatedJson;
  }
  else {
    const properties = {
      assetComponentType: data.assetComponentType,
      ShortCode: data.ShortCode,
      Coords: data.Coords,
      SymbolId: data.SymbolId,
      SequenceNumber: data.SequenceNumber,
      Spec: data.Spec,
      IsJacketedLine: data.IsJacketedLine,
      HdrSize: data.HdrSize,
      Size1: data.Size1,
      Size2: data.Size2,
      InsulationType: data.InsulationType,
      AgUg: data.AgUg,
      Fluid: data.Fluid,
      Seat: data.Seat,
      ValveBody: data.ValveBody,
      ExtendedBonet: data.ExtendedBonet,
      LimitSwich: data.LimitSwich,
      Hold: data.Hold,
      Quantity: data.Quantity === undefined ? data.Quantity : data.Quantity,
      Rating: data.Rating === undefined ? data.rating : data.Rating,
      SymbolRank: data.SymbolRank,
      ConfidenceScore: data.ConfidenceScore,
      FromLine: data.FromLine,
      ToLine: data.ToLine,
      CorrectedRow: data.CorrectedRow,
      ItemOrder: data.ItemOrder,
      Remarks: data.Remarks,
    };
    jsonData[lineGroup]["Symbols"][data.id] = properties;
    return jsonData;
  }
  
  
}

/* Function for editing the properties of line through window1*/
export function editLineDataWindow1(data,jsonData,lineGroup,oldLineGroup) {
  const assets = filterData(data.lineNumber,Object.entries(jsonData));
  const modifiedData = addNewAsset(assets,data);
  const updatedJson = updateJsonData(
    modifiedData,
   jsonData,
    "lines",
    assets[0]
  );
  /* to remove key value pair from json */
 delete updatedJson[oldLineGroup]["Lines"][data.id];
  return [modifiedData,updatedJson];
}
/* Function for deleting a symbol */
export function deleteSymbolData(data,jsonData,lineGroup) {
   delete jsonData[lineGroup]["Symbols"][data.assetId];
  return jsonData;
}
/* Function for deleting a line */
export function deleteLineData(data,jsonData,lineGroup) {
  delete jsonData[lineGroup]["Lines"][data.assetId];
  return jsonData;
}

/* Function for getting the linegroup details based on symbol ranking */

export function symbolsRanking(properties,symbolsData) {
  let shortCodeResult = [];
  deletedData = [];
  if(symbolsData !== undefined)
    shortCodeResult = symbolsData.filter((row) => row.shortCode === properties.shortCode);
  let rankingData = [];
  if(shortCodeResult.length > 0) {
    rankingData = shortCodeResult.filter((row) => row.SymbolRank > 0 && row.SymbolRank !== "NaN");
  }
  let coordinateData = [];
  if(rankingData.length > 0) {
    coordinateData = rankingData.filter((row) => row.coords.XMin === properties.Coords.XMin && row.coords.YMin === properties.Coords.YMin && row.coords.XMax === properties.Coords.XMax && row.coords.YMax === properties.Coords.YMax);
  }
  let sortedData = [];
  if(coordinateData.length > 0) {
    for(let data in coordinateData) {
      sortedData.push({lineNumber: coordinateData[data].LineNumber,SymbolRank: coordinateData[data].SymbolRank});
      deletedData.push({LineNumber: coordinateData[data].LineNumber,SymbolRank: coordinateData[data].SymbolRank,assetId: coordinateData[data].assetId,SymbolId: coordinateData[data].id,properties: coordinateData[data]})
    }
  }
  let sorted = [];
  if(sortedData.length  > 0) {
    sorted = sortedData.sort((a,b) => (a.SymbolRank > b.SymbolRank) ? 1 : ((b.SymbolRank > a.SymbolRank) ? -1 : 0))
  }
 let sortedLineGroups = [];
 if(sorted.length > 0) {
    for(let data in sorted) {
      sortedLineGroups.push(sorted[data].lineNumber);
    }
 }

 return sortedLineGroups;
}

export function deleteData() {
  return deletedData;
}