import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import cellEditFactory from "react-bootstrap-table2-editor";
import IconButton from "@material-ui/core/IconButton";
import Cancel from "@material-ui/icons/Cancel";
import CircleChecked from "@material-ui/icons/CheckCircleRounded";
import DialogContent from "@material-ui/core/DialogContent";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import DialogTitle from "@material-ui/core/DialogTitle";
import paginationFactory from "react-bootstrap-table2-paginator";
import axios from "axios";
import AppHeader from "../../../components/AppHeader/AppHeader";
import Tooltip from "@material-ui/core/Tooltip";
import AppFooter from "../../../components/AppFooter/AppFooter";
import BreadCrumbs from "../../Upload/BreadCrumbs/BreadCrumbs";
import UserForm from "../CreatenewWorkspace/UserForm";
import "./DataGrid.scss";
import UserStore from "../../UserStore";
import { toJS, observer } from "mobx";
import Moment from "moment";
import TextFieldMui from "@material-ui/core/TextField";
import { withStyles, createStyles } from "@material-ui/core/styles";
import FormHelperText from '@material-ui/core/FormHelperText';
import LoadingOverlay from "react-loading-overlay";
// Importing toastify module
import { toast } from "react-toastify";

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";
import Buisnessrule from "../Buisnessrule";
import "./Businessrulesetup.scss";
import SelectEditChange from "./SelectEditChange";
import SelectChange from "./SelectChange";

// toast-configuration method,
// it is compulsory method.
toast.configure();

const { BlockBlobClient } = require("@azure/storage-blob");
var today = new Date();
var startdate = Moment(
  today.getFullYear() +
    "-" +
    (today.getMonth() + 1) +
    "-" +
    (today.getDate() - 8)
).format("YYYY-MM-DD");
var enddate = Moment(
  today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate()
).format("YYYY-MM-DD");
const API_Endpoint_Workspace = process.env.REACT_APP_API_ENDPOINT_WORKSPACE;
const API_Endpoint = process.env.REACT_APP_API_ENDPOINT;

const styles = (muiTheme) => ({
  label: {
    "&$erroredLabel": {
      color: "black",
    },
  },
  erroredLabel: {},
});

const TextField = withStyles(styles)(function TextField({ classes, ...props }) {
  return (
    <TextFieldMui
      InputLabelProps={{
        classes: {
          root: classes.label,
          error: classes.erroredLabel,
        },
      }}
      {...props}
    />
  );
});
export class Buisnessrulessetup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
      clickCount: 0,
      result: {},
      loading:false,
      myrow: [],
      myrowedit: [],
      delete: false,
      setShow: false,
      setShow1: false,
      deleteid: "",
      showForm: false,
      userFlag: true,
      editProducts: [],
      phraseMessage: "",
      phraseError: false,
      modalId: "",
      columns: [
        { dataField: "phrase1", text: "Phrase 1" },
        { dataField: "phrase2", text: "Phrase 2" },
        { dataField: "phrase3", text: "Phrase 3" },
        { dataField: "phrase4", text: "Phrase 4" },
        { dataField: "Change", text: "Change Outcome" },
        { dataField: "More", text: "More" },
      ],
      newrow: {
        id: "",
        workSpaceName: "",
        projectName: "",
        phrase1: "",
        phrase2: "",
        phrase3: "",
        phrase4: "",
        ChangeOutcome: '',
      },
      products: [],
      reportDataexcel: [],
      newWorkspaceId: UserStore.newWorkspaceId !== 0  ? UserStore.newWorkspaceId : localStorage.getItem("newWorkspaceId") ? JSON.parse(localStorage.getItem("newWorkspaceId")) : 0,
      editworkspace: localStorage.getItem("Editworkspace") ? JSON.parse(localStorage.getItem("Editworkspace")): UserStore.editworkspace
    };
    if(!toJS(UserStore.editRow).length && localStorage.getItem("EditWSData")){
      UserStore.addRowdata(JSON.parse(localStorage.getItem("EditWSData")));
    }
    if(UserStore.NewworkspaceName === ""  && localStorage.getItem("SetNewworkspaceName")) {
      UserStore.SetNewworkspaceName(localStorage.getItem("SetNewworkspaceName"));      
    }
    if(UserStore.NewProjectName === ""  && localStorage.getItem("SetNewProjectName")) {
      UserStore.SetNewProjectName(localStorage.getItem("SetNewProjectName"));      
    }
  }

  async componentDidMount() {    
    try {
      UserStore.inBusinessrule = true;
      if (this.state.newWorkspaceId !== 0 && this.state.editworkspace == false) {
        const data = {
          ProjectName: UserStore.NewProjectName,
          WorkSpaceName: UserStore.NewworkspaceName,
          WorkSpaceId: this.state.newWorkspaceId,
        };
        this.setState({loading: true});
        // ----------------API: Get business rules for workspace-------------
        axios
          .post(
            API_Endpoint + `/GetBusinessRules`,
            data
          )
          .then((res) => {
            const rows1 = res.data;
            if (res.status === 200) {
              this.setState({loading: false});
            }

            if (rows1) {
              rows1.businessRules.map((items, index) => {
                const row2 = {
                  phrase1: items.phrase1,
                  phrase2: items.phrase2,
                  phrase3: items.phrase3,
                  phrase4: items.phrase4,
                  Change: items.change ? "Change" : "No Change",
                  More: (
                    <div
                      classname="more-actions-wrapper"
                      style={{ display: "inline-flex" }}
                    >
                      <Tooltip title=" Edit Business Rule" arrow>
                      <div
                        classname="edit-icon"
                        onClick={this.state.userFlag ? this.handleEdit : ""}
                        style={{ marginLeft: "-40%", cursor: "pointer" }}
                      >
                        <img
                          id={items.businessRuleId}
                          src="/images/icon_edit.svg"
                        />
                      </div>
                      </Tooltip>
                      <Tooltip title=" Delete Business Rule" arrow>
                      <div
                        classname="delete-icon"
                        style={{ marginLeft: "70%", cursor: "pointer" }}
                        onClick={
                          this.state.userFlag
                  ? (e) => {
                      this.setState({
                        delete:true,
                        deleteid:items.businessRuleId,
                      })   
                    }
                  : ""
                        }
                      >
                        <img
                          id={items.businessRuleId}
                          src="/images/icon_delete.svg"
                        />
                      </div>
                      </Tooltip>
                    </div>
                  ),
                };
                this.state.myrow.push(row2);
              });
              this.setState({
                count: 1,
              });
            }

            this.setState({
              products: this.state.myrow,
            });
          });
      } else {
        const data1 = {
          WorkSpaceName: toJS(UserStore.editRow[0].workSpaceName),
          projectName: toJS(UserStore.editRow[0].projectName),
          WorkSpaceId: toJS(UserStore.editRow[0].workSpaceId),
        };
        this.setState({loading: true});
        // -----------------API: Get business rules for workspace----------------
        axios
          .post(
            API_Endpoint + `/GetBusinessRules`,
            data1
          )
          .then((res) => {
            const rows1 = res.data;
            if (res.status === 200) {
              this.setState({loading: false});
            }

            if (rows1) {
              rows1.businessRules.map((items, index) => {
                const row2 = {
                  businessRuleId: items.businessRuleId,
                  phrase1: items.phrase1,
                  phrase2: items.phrase2,
                  phrase3: items.phrase3,
                  phrase4: items.phrase4,
                  Change: items.change ? "Change" : "No Change",
                  More: (
                    <div
                      classname="more-actions-wrapper"
                      style={{ display: "inline-flex" }}
                    >
                       <Tooltip title=" Edit Business Rule" arrow>
                      <div
                        classname="edit-icon"
                        onClick={this.state.userFlag ? this.handleEdit : ""}
                        style={{ marginLeft: "-40%", cursor: "pointer" }}
                      >
                        <img
                          id={items.businessRuleId}
                          src="/images/icon_edit.svg"
                        />
                      </div>
                      </Tooltip>
                      <Tooltip title=" Delete Business Rule" arrow>
                      <div
                        classname="delete-icon"
                        style={{ marginLeft: "70%", cursor: "pointer" }}
                        onClick={
                          this.state.userFlag
                  ? (e) => {
                      this.setState({
                        delete:true,
                        deleteid:items.businessRuleId,
                      })
                    }
                  : ""
                        }
                      >
                        <img
                          id={items.businessRuleId}
                          src="/images/icon_delete.svg"
                        />
                      </div>
                      </Tooltip>
                    </div>
                  ),
                };
                this.state.myrow.push(row2);
              });
              this.setState({
                count: 1,
              });
            }

            this.setState({
              products: this.state.myrow,
            });
          });
      }
    } catch (error) {
      if (error.response) {
        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
      } else if (error.request) {
        // console.log(error.request);
      } else {
        // console.log("Error", error.message);
      }
    }
  }

  handleEdit = (e) => {
    let id = e.target.id;
    this.setState({
      setShow: true,
      modalId: id,
    });
    
    const data = this.state.products
      .filter((item) => item.businessRuleId == id)
      .map(
        ({
          id,
          businessRuleId,
          phrase1,
          phrase2,
          phrase3,
          phrase4,
          Change,
        }) => ({
          id,
          businessRuleId,
          phrase1,
          phrase2,
          phrase3,
          phrase4,
          Change,
        })
      );
      data[0]['Change'] = (data[0].Change === 'Change') ? true : false;
    this.setState({
      editProducts: data,
    });
  };

  addRow1 = (e) => {
    this.setState({
      newrow: {
        id: "",
        workSpaceName: "",
        projectName: "",
        phrase1: "",
        phrase2: "",
        phrase3: "",
        phrase4: "",
        ChangeOutcome: '',
      }
    });
    this.setState({
      setShow1: true,
    });
  };

  // ---------------Start:Function: Add new business rule-----------------------------
  addRow = (event) => {
    let name = event.target.name;

    if (event.target.name === "Wname") {
      this.state.newrow.workSpaceName = event.target.value;
    } else if (event.target.name == "Phrase1") {
      this.state.newrow.phrase1 = event.target.value;
    } else if (event.target.name == "Phrase2") {
      this.state.newrow.phrase2 = event.target.value;
    } else if (event.target.name == "Phrase3") {
      this.state.newrow.phrase3 = event.target.value;
    } else if (event.target.name == "Phrase4") {
      this.state.newrow.phrase4 = event.target.value;
    } else {
      this.state.newrow.Pname = event.target.value;
    }
    this.setState({phraseError: false,phraseMessage: ""});
  };
  // ---------------End:Function: Add new business rule-----------------------------

  // ----------------------Start:Function: Save newly added business rule---------------
  disableAddButton = (event) => { 
    
    if(this.state.clickCount === 0) {
      this.setState({clickCount : this.state.clickCount + 1});
      this.rowSave();
    }    
  }

  rowSave = async () => {   
        
    if (this.state.editworkspace === false) {
      var data = {
        ProjectName: UserStore.NewProjectName,
        WorkSpaceName: UserStore.NewworkspaceName,
        WorkSpaceId: this.state.newWorkspaceId,
        phrase1: this.state.newrow.phrase1,
        phrase2: this.state.newrow.phrase2,
        phrase3: this.state.newrow.phrase3,
        phrase4: this.state.newrow.phrase4,

        Change: this.state.newrow.ChangeOutcome,
      };
    } else {
      var data = {
        WorkSpaceName: toJS(UserStore.editRow[0].workSpaceName),
        projectName: toJS(UserStore.editRow[0].projectName),
        WorkSpaceId: toJS(UserStore.editRow[0].workSpaceId),
        phrase1: this.state.newrow.phrase1,
        phrase2: this.state.newrow.phrase2,
        phrase3: this.state.newrow.phrase3,
        phrase4: this.state.newrow.phrase4,

        Change: this.state.newrow.ChangeOutcome,
      };
    }
    if(this.state.newrow.phrase1 === "" || this.state.newrow.ChangeOutcome === "") {
      if(this.state.newrow.phrase1 === "")
        this.setState({phraseError: true,phraseMessage: "Phrase1 should not be Empty"});
      if(this.state.newrow.ChangeOutcome === '') {
        this.setState({outcomeError: true,outcomeErrorMessage: "Outcome should not be Empty"});
      }
      this.setState({loading: false, clickCount: 0});
    }
    else {
      this.setState({setShow1: false, loading: true});
      // API: Get business rules for workspace
     const res = await axios.post(
      API_Endpoint + `/AddBusinessRule`,
      data
    );
    if(res.status === 200) {
      this.setState({loading: false, clickCount: 0});
    }
    if (res.data.businessRules.length != 0) {
      this.setState({
        products: []
      });
      res.data.businessRules.map((items, index) => {
        const row2 = {
          businessRuleId: items.businessRuleId,
          id: items.workSpaceId,
          workSpaceName: items.workSpaceName,
          projectName: items.projectName,
          phrase1: items.phrase1,
          phrase2: items.phrase2,
          phrase3: items.phrase3,
          phrase4: items.phrase4,
          Change: items.change ? "Change" : "No Change",
          More: (
            <div
              classname="more-actions-wrapper"
              style={{ display: "inline-flex" }}
            >
              <Tooltip title="Edit Business Rule" arrow>
              <div
                classname="edit-icon"
                onClick={this.state.userFlag ? this.handleEdit : ""}
                style={{ marginLeft: "-40%", cursor: "pointer" }}
              >
                <img id={items.businessRuleId} src="/images/icon_edit.svg" />
              </div>
              </Tooltip>
              <Tooltip title="Delete Business Rule" arrow>
              <div
                classname="delete-icon"
                style={{ marginLeft: "70%", cursor: "pointer" }}
                onClick={
                  this.state.userFlag
                  ? (e) => {
                      this.setState({
                        delete:true,
                        deleteid:items.businessRuleId,
                      })                     

                    }
                  : ""
                }
              >
                <img id={items.businessRuleId} src="/images/icon_delete.svg" />
              </div>
              </Tooltip>
            </div>
          ),
        };
        this.state.products.push(row2);
      });

      this.setState({
        newrow: {}
      });
    }
    this.setState({phraseError: false,phraseMessage: ""});
  }
  };
  // ----------------------End:Function: Save newly added business rule---------------

  handleDelete = (e) => {
    let id = e.target.id;
  };

  handleClose = () => {
    this.setState({ setShow: false });
  };
  

  handleCloserow = () => {
    this.setState({
      setShow1: false,
      newrow: {
        id: "",
        workSpaceName: "",
        projectName: "",
        phrase1: "",
        phrase2: "",
        phrase3: "",
        phrase4: "",
        ChangeOutcome: '',
      },
    });
  };

  handleShow = () => {
    this.setState({ setShow: true });
  };

  disableAddButtonEdit = (event) => { 
    if(this.state.clickCount === 0) {
      this.setState({clickCount : this.state.clickCount + 1});
      this.handleEditSave();
    }    
  }
  // -----------------------Start:Function: Save newly edited business rule------------------
  handleEditSave = async () => {
    // send the output data to the service
    const productsList = this.state.products;
    if (this.state.editworkspace === false) {
      var data = {
        ProjectName: UserStore.NewProjectName,
        WorkSpaceName: UserStore.NewworkspaceName,
        WorkSpaceId: this.state.newWorkspaceId,
        BusinessRuleId: this.state.editProducts[0].businessRuleId,
        phrase1: this.state.editProducts[0].phrase1,
        phrase2: this.state.editProducts[0].phrase2,
        phrase3: this.state.editProducts[0].phrase3,
        phrase4: this.state.editProducts[0].phrase4,

        Change: this.state.editProducts[0].Change,
      };
    }
    else{
    var data = {
      WorkSpaceName: toJS(UserStore.editRow[0].workSpaceName),
      projectName: toJS(UserStore.editRow[0].projectName),
      WorkSpaceId: toJS(UserStore.editRow[0].workSpaceId),
      BusinessRuleId: this.state.editProducts[0].businessRuleId,
      phrase1: this.state.editProducts[0].phrase1,
      phrase2: this.state.editProducts[0].phrase2,
      phrase3: this.state.editProducts[0].phrase3,
      phrase4: this.state.editProducts[0].phrase4,
      Change: this.state.editProducts[0].Change,
    };
  }
  
    if(data.phrase1 === "" || data.Change === "") {
      if(data.phrase1 === "")
        this.setState({phraseError: true,phraseMessage: "Phrase1 should not be Empty"});
      if(data.Change === '') {
        this.setState({outcomeError: true,outcomeErrorMessage: "Outcome should not be Empty"});
      }
    }
    else {
      this.setState({
        products: [],
        phraseError: false,
        setShow: false,
        phraseMessage: "",
        loading: true
      });
      
      // API: Update the existing business rule
      let res = await axios.post(
        API_Endpoint + `/UpdateBusinessRule`,
        data
      );
      if(res.status === 200){
        this.setState({loading: false, clickCount: 0});
      }
      res.data.businessRules.map((items, index) => {
        const row2 = {
          businessRuleId: items.businessRuleId,
          id: items.workSpaceId,
          workSpaceName: items.workSpaceName,
          projectName: items.projectName,
          phrase1: items.phrase1,
          phrase2: items.phrase2,
          phrase3: items.phrase3,
          phrase4: items.phrase4,
          Change: items.change ? "Change" : "No Change",
          More: (
            <div
              classname="more-actions-wrapper"
              style={{ display: "inline-flex" }}
            >
               <Tooltip title="Edit Business Rule" arrow>
              <div
                classname="edit-icon"
                onClick={this.state.userFlag ? this.handleEdit : ""}
                style={{ marginLeft: "-40%", cursor: "pointer" }}
              >
                <img id={items.businessRuleId} src="/images/icon_edit.svg" />
              </div>
              </Tooltip>
              <Tooltip title="Delete Business Rule" arrow>
              <div
                classname="delete-icon"
                style={{ marginLeft: "70%", cursor: "pointer" }}
                onClick={
                  this.state.userFlag
                  ? (e) => {
                      this.setState({
                        delete:true,
                        deleteid:items.businessRuleId,
                      })                     

                    }
                  : ""
                }
              >
                <img id={items.businessRuleId} src="/images/icon_delete.svg" />
              </div>
              </Tooltip>
            </div>
          ),
        };
        this.state.products.push(row2);
      });

      this.setState({
        newrow: {},
        setShow: false,
      });

    }
  };
  // -----------------------End:Function: Save newly edited business rule------------------

  // open the new Create Workspace page
  Openworkspace = () => {
    this.setState({ showForm: true });
  };

  handleChange1 = (event) => {
    let id = event.target.id;
    const initArray = this.state.products;
    const rowneeded = initArray.findIndex((obj) => obj.businessRuleId == id);
    if (event.target.name === "Phrase1") {
      initArray[rowneeded].phrase1 = event.target.value;
    } else if (event.target.name === "Phrase2") {
      initArray[rowneeded].phrase2 = event.target.value;
    } else if (event.target.name === "Phrase3") {
      initArray[rowneeded].phrase3 = event.target.value;
    } else if (event.target.name === "Phrase4") {
      initArray[rowneeded].phrase4 = event.target.value;
    } else {
      initArray[rowneeded].Change = event.target.value;
    }
    this.setState({
      editProducts: [initArray[rowneeded]],
      products: initArray,
      phraseError: false,
      phraseMessage: ""
    });
  };

  handleCloseDialog = () => {
    this.setState({ delete: false,
    deleteid:""
   });
  };

  // ----------------Start: Function: Delete business rule------------------------
  handlePurge = (e) => {
    let id = this.state.deleteid;

    const rowneeded = this.state.products.filter(
      (row) => row.businessRuleId != id
    );

    var  feedWorkSpaceId = 0;
    if(this.state.editworkspace === false)    
    {
      feedWorkSpaceId = this.state.newWorkspaceId;
    }
    else{
       feedWorkSpaceId = toJS(UserStore.editRow[0].workSpaceId);
    }
    const data = {
      BusinessRuleId: id,
      WorkSpaceId: feedWorkSpaceId ,
    };
    //this.setState({ products: rowneeded });
    axios.post(API_Endpoint + `/DeleteBusinessRule`, data);
    this.setState({ products: rowneeded,
      deleteid:"",
      delete: false,
    
    });
    
  };
  // ----------------End: Function: Delete business rule------------------------

  handleOnSelect = (row, isSelect) => {
    this.props.history.push(`${this.props.match.path}/Upload`);
  };

  handleDropdownchangerowEdit = (event, id) => {
    let outcome = "";
    if (event.target.value === 10) {
      outcome = true;
    } else {
      outcome = false;
    }
    const initArray = this.state.products;
    const rowneeded = initArray.findIndex((obj) => obj.businessRuleId == id);
    initArray[rowneeded].Change = outcome;
    this.setState({
      editProducts: [initArray[rowneeded]],      
      outcomeError: false,
      outcomeErrorMessage: "",
    });
  };

  handleDropdownchangerow = (event, id) => {
    let outcome = "";
    if (event.target.value === 10) {
      outcome = true;
    } else {
      outcome = false;
    }
    
    this.setState(prevState => ({
      outcomeError: false,
      outcomeErrorMessage: "",
      newrow: {
        ...prevState.newrow,
        ChangeOutcome: outcome
      }
    }));
  };

  render() {

    UserStore.setIsRegExp(false)
    const selectRow = {
      mode: "radio",
      clickToSelect: true,
      onSelect: this.handleOnSelect,
    };

    const cellEdit = cellEditFactory({
      mode: "click",
      blurToSave: true,
    });
    if (!this.state.showForm) {
      return (
        <div className="businessrule-container">
          {this.showForm && <UserForm />}
          <AppHeader />
          <BreadCrumbs />
          <LoadingOverlay
            className="process-page-loader"
            active={this.state.loading}
            spinner
            text="Loading your content..."
          ></LoadingOverlay>
          <div className="workspace-wrapper">
            <div className="worspace-titlebar">
              <div className="workspace-title">Business Rule</div>
              <div
                className="create-new-workspace-wrapper"
                style={
                  ({ display: "flex" },
                  { marginRight: "-3vw" },
                  { marginLeft: "81.5%" })
                }
              >
                <Button
                  variant="contained"
                  align="right"
                  class="next-btn"
                  onClick={this.addRow1}
                >
                  Add row
                </Button>
              </div>
            </div>
            {this.state.userFlag && (
              <div className="workspace-data-table-wrapper">
                <BootstrapTable
                  className="workspace-data-table"
                  keyField="id"
                  data={this.state.products}
                  columns={this.state.columns}
                  pagination={paginationFactory()}
                />
              </div>
            )}
            {!this.state.userFlag && (
              <div className="workspace-data-table-wrapper">
                <BootstrapTable
                  className="workspace-data-table"
                  keyField="id"
                  data={this.state.products}
                  columns={this.state.columns}
                  selectRow={selectRow}
                  pagination={paginationFactory()}
                />
              </div>
            )}
            <div className="btn-fields" Align="center">
              <Button
                onClick={() => {
                  this.props.history.push(`/Workspace`);
                }}
                variant="contained"
                class="save-btn"
              >
                Save
              </Button>
              <Button
                variant="contained"
                class="cancel-btn"
                onClick={() => {
                  this.props.history.push(`/Workspace`);
                }}
              >
                Cancel
              </Button>
            </div>
            {this.state.setShow && this.state.editProducts && (
              <Dialog
                open={this.state.setShow}
                onClose={this.handleClose}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle id="form-dialog-title">
                  <span style={{fontSize: "1.2vw", color: "#fff"}}>Edit Business Rule</span>
                </DialogTitle>
                <DialogContent>
                  <InputLabel id="demo-simple-select-label"></InputLabel>

                  
                  <TextField
                    margin="dense"
                    id={this.state.modalId}
                    name="Phrase1"
                    label="Phrase1"
                    onChange={(e) => this.handleChange1(e)}
                    fullWidth
                    value={this.state.editProducts[0].phrase1}
                    style={{ fontSize: "1.04vw", width: "95%" }}
                    error={this.state.phraseError}
                    helperText={this.state.phraseMessage}
                  />
                  <TextField
                    margin="dense"
                    id={this.state.modalId}
                    name="Phrase2"
                    label="Phrase2"
                    onChange={(e) => this.handleChange1(e)}
                    fullWidth
                    value={this.state.editProducts[0].phrase2}
                    style={{ fontSize: "1.04vw", width: "95%" }}
                  />
                  <TextField
                    margin="dense"
                    id={this.state.modalId}
                    name="Phrase3"
                    label="Phrase3"
                    onChange={(e) => this.handleChange1(e)}
                    fullWidth
                    value={this.state.editProducts[0].phrase3}
                    style={{ fontSize: "1.04vw", width: "95%" }}
                  />
                  <TextField
                    margin="dense"
                    id={this.state.modalId}
                    name="Phrase4"
                    label="Phrase4"
                    onChange={(e) => this.handleChange1(e)}
                    fullWidth
                    value={this.state.editProducts[0].phrase4}
                    style={{ fontSize: "1.04vw", width: "95%" }}
                  />

                  <InputLabel
                    id="demo-simple-select-label"
                    style={{ marginLeft: "1vw" }}
                  >
                    Outcome
                  </InputLabel>
                  <SelectEditChange idValue= {this.state.modalId} handleDropdownchangerowEdit = {this.handleDropdownchangerowEdit} editProducts= {this.state.editProducts[0]}></SelectEditChange>
                </DialogContent>
                <DialogActions style={{ padding: "0 12vw" }}>
                <Button
                  style={{
                    background: "#0C629B",
                    color: "#fff",
                    width: "8.4vw",
                    height: "2.29vw",
                    fontSize: "0.9vw",
                    textTransform: "none",
                  }}
                  onClick={this.disableAddButtonEdit}
                  color="primary"
                >
                  Save
                </Button>
                <Button
                  //   style={{ border: "1px solid #4B4A55" }}
                  onClick={this.handleClose}
                  color="primary"
                  style={{
                    background: "#ffffff",
                    border: "0.05vw solid #0c629b",
                    borderRadius: "0.15vw",
                    width: "8.4vw",
                    height: "2.29vw",
                    // padding: 0.15vw 0;
                    fontSize: "0.9vw",
                    color: "#0c629b",
                    textTransform: "none",
                  }}
                >
                  Cancel
                </Button>
              </DialogActions>
              </Dialog>
            )}
              
              <Dialog
              className="delete-confirm"
              open={this.state.delete}
              onClose={this.handleCloseDialog}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle
                id="form-dialog-title"
                style={{ height: "0.625em" }}
              ></DialogTitle>
              <DialogContent>
                <div style={{ fontSize: "1vw" }}>Are you sure to delete?</div>
                <IconButton
                  onClick={this.handleCloseDialog}
                  style={{
                    padding: "0",
                    float: "right",
                    background: "#0C629B",
                    color: "#fff",
                    position: "absolute",
                    right: "15%",
                  }}
                >
                  {" "}
                  <Cancel />{" "}
                </IconButton>
                <IconButton
                  onClick={(e) => this.handlePurge(e)}
                  style={{
                    padding: "0",
                    background: "#0C629B",
                    color: "white",
                    position: "absolute",
                    right: "2%",
                  }}
                >
                  {" "}
                  <CircleChecked />{" "}
                </IconButton>
              </DialogContent>
            </Dialog>

            <Dialog
              open={this.state.setShow1}
              onClose={this.handleClose}
              aria-labelledby="form-dialog-title"
              className="businessrule-popup"
            >
              <DialogTitle id="form-dialog-title" style={{ height: "4vw" }}>
                <span style={{ fontSize: "1.2vw", color: "#fff" }}>Add Business Rule</span>
              </DialogTitle>
              <DialogContent>
                <InputLabel
                  id="demo-simple-select-label"
                  style={{ marginLeft: "1vw", fontSize: "1.05vw" }}
                ></InputLabel>
                
                <TextField
                  margin="dense"
                  id={this.state.modalId}
                  name="Phrase1"
                  label="Phrase 1"
                  onChange={(e) => this.addRow(e)}
                  fullWidth
                  style={{ font: "normal normal Helvetica 1.04vw", width: "95%" }}
                  error={this.state.phraseError}
                  helperText={this.state.phraseMessage}
                />
                <TextField
                  margin="dense"
                  id={this.state.modalId}
                  name="Phrase2"
                  label="Phrase 2"
                  onChange={(e) => this.addRow(e)}
                  fullWidth
                  style={{ font: "normal normal Helvetica 1.04vw", width: "95%" }}
                />
                <TextField
                  margin="dense"
                  id={this.state.modalId}
                  name="Phrase3"
                  label="Phrase 3"
                  onChange={(e) => this.addRow(e)}
                  fullWidth
                  style={{ font: "normal normal Helvetica 1.04vw", width: "95%" }}
                />
                <TextField
                  margin="dense"
                  id={this.state.modalId}
                  name="Phrase4"
                  label="Phrase 4"
                  onChange={(e) => this.addRow(e)}
                  fullWidth
                  style={{ font: "normal normal Helvetica 1.04vw", width: "95%" }}
                />
                <InputLabel
                  id="demo-simple-select-label"
                  style={{ marginLeft: "3%", font: "normal normal Helvetica 1.04vw"}}
                >
                  Outcome
                </InputLabel>
                <SelectChange idValue= {this.state.modalId} handleDropdownchangerowEdit = {this.handleDropdownchangerow} editProducts= {this.state.newrow}></SelectChange>
                
                <FormHelperText error={this.state.outcomeError} style={{marginLeft: "1.2vw",font: "normal normal Helvetica 1.04vw"}}>{this.state.outcomeErrorMessage} </FormHelperText>
              </DialogContent>
              <DialogActions style={{ padding: "0 12vw" }}>
                <Button
                  style={{
                    background: "#0C629B",
                    color: "#fff",
                    width: "8.4vw",
                    height: "2.29vw",
                    fontSize: "0.9vw",
                    textTransform: "none",
                  }}
                  onClick={this.disableAddButton}
                  color="primary"
                >
                  Add
                </Button>
                <Button
                  //   style={{ border: "1px solid #4B4A55" }}
                  onClick={this.handleCloserow}
                  color="primary"
                  style={{
                    background: "#ffffff",
                    border: "0.05vw solid #0c629b",
                    borderRadius: "0.15vw",
                    width: "8.4vw",
                    height: "2.29vw",
                    // padding: 0.15vw 0;
                    fontSize: "0.9vw",
                    color: "#0c629b",
                    textTransform: "none",
                  }}
                >
                  Cancel
                </Button>
              </DialogActions>
              {/* </Grid> */}
            </Dialog>
            
          </div>
          <AppFooter />
        </div>
      );
    } else {
      return <UserForm />;
    }
  }
}
export default Buisnessrulessetup;


