import React, {useState}  from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import Cancel from "@material-ui/icons/Cancel";
import CircleChecked from "@material-ui/icons/CheckCircleRounded";

export default function ConfirmationDialog(props) {

    return (
        <Dialog className="delete-confirm" open={props.delete} onClose={props.handleCloseDialog} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title" style={{ height: "0.625em" }}></DialogTitle>
            <DialogContent>
                <div style={{ fontSize: "1vw" }}>Are you sure to delete?</div>
                <IconButton onClick={props.handleCloseDialog} style={{
                    padding: "0",
                    float: "right",
                    background: "#0C629B",
                    color: "#fff",
                    position: "absolute",
                    right: "15%",
                  }}
                >
                  {" "}
                  <Cancel />{" "}
                </IconButton>
                <IconButton onClick={(e) => props.handlePurge(e)} style={{
                    padding: "0",
                    background: "#0C629B",
                    color: "white",
                    position: "absolute",
                    right: "2%",
                  }}
                >
                  {" "}
                  <CircleChecked />{" "}
                </IconButton>
            </DialogContent>
        </Dialog>
    )
}