import React, {  useEffect, useRef } from "react";
import { Line as KonvaLine, Transformer } from "react-konva";
import {Transform}  from "../../../redux/actions/documentActions";
export default function Line(props) {
  const isSelected = props.isSelected;
  const shapeRef = useRef();
  const transformerRef = useRef();
  const dispatch = props.dispatcher;
  useEffect(() => {
    if (isSelected) {
      transformerRef.current.nodes([shapeRef.current]);
      transformerRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  const mouseoverCursor = (e) => {
    const container = e.target.getStage().container();
    container.style.cursor = "pointer";
  };

  const onMouseOutCursor = (e) => {
    const container = e.target.getStage().container();
    container.style.cursor = "default";
  };
  function handleChange(event) {
    const scaleX = shapeRef.current.scaleX();
    const scaleY = shapeRef.current.scaleY();
    const width = shapeRef.current.width() * scaleX;
    const height = shapeRef.current.height() * scaleY;
    const coords = {
      XMin: Math.floor(shapeRef.current.x() * props.width),
      YMin: Math.floor(shapeRef.current.y() * props.height),
      XMax: Math.floor((shapeRef.current.x() + width) * props.width),
      YMax: Math.floor((shapeRef.current.y() + height) * props.height),
    };
    const properties = event.target.attrs;
    const transformLineData = {
      Coords: coords,
      orientation: shapeRef.current.rotation(),
      lineNumber: properties.lineNumber,
      assetType: "Lines",
      id: properties.id,
      LineSegmentId: properties.assetID,
    };
    dispatch(Transform(transformLineData));
  }

  return (
    <>
      <KonvaLine
        {...props.shape}
        onMouseEnter={mouseoverCursor}
        onMouseOut={onMouseOutCursor}
        ref={shapeRef}
        draggable={true}
        onTransformEnd={handleChange}
        onDragEnd={handleChange}
      />
      {isSelected && (
        <Transformer
          anchorSize={5}
          borderStroke="#6699cc"
          enabledAnchors={[
            "top-left",
            "bottom-right",
            "left-top",
            "right-bottom",
          ]}
          borderStrokeWidth={3}
          rotateEnabled={true}
          keepRatio={false}
          ref={transformerRef}
        />
      )}
    </>
  );
}