import React, {useState}  from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import Cancel from "@material-ui/icons/Cancel";
import CircleChecked from "@material-ui/icons/CheckCircleRounded";
export default function SaveDetails(props) {
    return(
        <Dialog className="save-confirm" 
        open={true}
        //  onClose={} 
        // aria-labelledby="form-dialog-title"
        >
        <DialogTitle id="form-dialog-title" style={{ height: "0.625em" }}></DialogTitle>
        <DialogContent style={{height:"5vw"}}>
            <div style={{ fontSize: "1vw" }}>Do you want to save the changes?</div>
            <div>
              <IconButton 
              onClick={props.exitPage} 
              style={{
                  padding: "0",
                  float: "right",
                  background: "#0C629B",
                  color: "#fff",
                  position: "absolute",
                  right: "51%",
                  marginTop:"1%"
                }}
              >
                {" "}
                <Cancel />{" "}
              </IconButton>
              <IconButton 
              onClick={props.saveDetails}
              style={{
                  padding: "0",
                  background: "#0C629B",
                  color: "white",
                  position: "absolute",
                  right: "36%",
                  marginTop:"1%"
                }}
              >
                {" "}
                <CircleChecked />{" "}
              </IconButton>
            </div>
        </DialogContent>
    </Dialog>
    )
}