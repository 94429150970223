export default function AssetProperties(assets, resWidth, resHeight , selectedLineNumber = undefined) {
  let assetData = null;
  let asset = [];
  const colorCodes = require('./colorCodes.json');
  var result = [];

for(var i in colorCodes)
    result.push([i, colorCodes[i]]);

  function createAsset(symbols, type, color, lineno) {
    let width = 0;
    let height = 0;
    assetData = symbols.map((symbols, index) => {
      width = Math.abs(symbols[1].Coords.XMax - symbols[1].Coords.XMin) / resWidth;
      const old_width = width;
      if(symbols[1].ShortCode === "VBF") {
        width = width + 1;
      }
      height = (symbols[1].Coords.YMax - symbols[1].Coords.YMin) / resHeight;
      const old_height = height;
      if(symbols[1].ShortCode === "SB") {
        height = height + 1;
      }
      let symbolId =
        type === "lines" ? symbols[1].LineSegmentId : symbols[1].SymbolId; 
      let otherProperties = {};
      if (type === "symbols") {
        otherProperties = {
          SequenceNumber: symbols[1].SequenceNumber,
          Spec: symbols[1].Spec,
          IsJacketedLine: symbols[1].IsJacketedLine === null ? "" : symbols[1].IsJacketedLine,
          HdrSize: symbols[1].HdrSize,
          Size1: symbols[1].Size1 === null ? "" : symbols[1].Size1,
          Size2: symbols[1].Size2 === null ? "" : symbols[1].Size2,
          Quantity: symbols[1].Quantity,
          InsulationType: symbols[1].InsulationType,
          AgUg: symbols[1].AgUg,
          Fluid: symbols[1].Fluid,
          Seat: symbols[1].Seat,
          ValveBody: symbols[1].ValveBody,
          ExtendedBonet: symbols[1].ExtendedBonet,
          LimitSwich: symbols[1].LimitSwich,
          Hold: symbols[1].Hold,
          CorrectedRow: symbols[1].CorrectedRow,
          ToLine: symbols[1].ToLine,
          FromLine: symbols[1].FromLine,
          Remarks: symbols[1].Remarks,
          ItemOrder: symbols[1].ItemOrder === null || symbols[1].ItemOrder === undefined ? "" : symbols[1].ItemOrder,
          ConfidenceScore: symbols[1].ConfidenceScore,
          Rating: symbols[1].Rating,
          SymbolRank: symbols[1].SymbolRank,

        };
      }
      return {
        x: symbols[1].Coords.XMin / resWidth,
        y: symbols[1].Coords.YMin / resHeight,
        width: width,
        height: height < 0 ? 0 - height : height,
        points: type === "lines" ? [0, 0 ,width, height] : "",
        oldPoints: type === "lines" ? [0, 0 ,old_width, old_height] : "",
        stroke: symbols[1].SymbolRank !== 0 && symbols[1].SymbolRank !== "NaN" && symbols[1].SymbolRank !== undefined && symbols[1].SymbolRank !== null ? "yellow" :color,
        strokeWidth: 1.8,
        assetID: symbolId, 
        assetType: type,
        lineNumber: lineno,
        shortCode: symbols[1].ShortCode ? symbols[1].ShortCode : "",
        assetComponentType: symbols[1].assetComponentType
          ? symbols[1].assetComponentType
          : "",
        symbolName: symbols[1].symbolName ? symbols[1].symbolName : "",
        strokeScaleEnabled: false,
        id: symbols[0],
        coords: symbols[1].Coords,
        oldWidth: old_width,
        oldHeight: old_height,
        ...otherProperties,
      };
    });
    if (assetData.length > 0) {
      asset = [...asset, ...assetData];
    }
  }
  if (assets.length > 0) {
    for (var index = 0; index < assets.length; index++) {
      let color = "";
      color = result[index][0];
      if(assets[index][1].specification === "UNKNOWN") {
        color = "rgb(128,0,0)";
      }
      if(selectedLineNumber === assets[index][1].specification) {
        color = "#FF5349";
      }
     
      if (assets[index][1].hasOwnProperty("Symbols")) {
        let symbols = Object.entries(assets[index][1].Symbols);
        let lineno = assets[index][1].specification;
       
        if (symbols) {
          createAsset(symbols, "symbols", color, lineno);
        }
      }

      if (assets[index][1].hasOwnProperty("Lines")) {
        let symbols = Object.entries(assets[index][1].Lines);
        let lineno = assets[index][1].specification;
        if (symbols) {
          createAsset(symbols, "lines", color, lineno);
        }
      }
    }
  }
  return asset;
}
