import React, { useRef, useState, useEffect } from "react";
import { Stage, Layer, Image } from "react-konva";
import { useDispatch, useSelector } from "react-redux";
import {
  FilterLineGroup,
  AddNewAsset,
  newLineGroup,
  selectAsset,
} from "../../redux/actions/documentActions";
import {symbolsRanking} from "../../redux/CanvasActions"
import AddLine from "../PIDCanvas/AddLine/AddLine";
import AddSymbol from "../PIDCanvas/AddSymbol/AddSymbol";
import LoadingOverlay from "react-loading-overlay";
import AssetProperties from "../AssetShapes/AssetProperties";
import AssetsShape from "../AssetShapes/AssetsShape";
import { useReactToPrint } from "react-to-print";
import "./PIDCanvas.scss";
import Tooltip from "@material-ui/core/Tooltip";
import axios from 'axios';
import ExpandWindow from "../ExpandWindow/ExpandWindow";
import UserStore from "../../UserStore";
let index = 0;
export let imageLoading = true;
export default function PIDCanvas(props) {

  const comp = useRef();
  const API_Endpoint = process.env.REACT_APP_API_ENDPOINT;
  let stageRef = useRef();
  // const url = window.location.href;
  const sheetId = JSON.parse(localStorage.getItem("sheetId"));
  const draw = useRef(false);
  const edit = useRef(false);
  const [width, setWidth] = React.useState(props.expand === undefined ? ((window.innerWidth) * 0.45) : ((window.innerWidth) * 0.8));
  const [height, setHeight] = React.useState(props.expand === undefined ? ((window.innerHeight) * 0.7) : ((window.innerHeight) * 0.8));
  const [resWidth,setResWidth] = useState(props.width / width);
  const [resHeight,setResHeight] = useState(props.height / height);
  const [inputData,setInputData] = useState({});
  const [imageData,setImageData] = useState(props.imageSrc);
  const [loading,setLoading] = useState(true);
  const symbolDat = useSelector(state => state.documentData.symbolsDetails);
  const [accuracy,setAccuracy] = useState("all");
 
/* Function to call PreviewProcessedPid API */
  async function getAPICall() {
    const result = await axios.get(API_Endpoint + `/PreviewProcessedPid?sheetId=${sheetId}`);
    setResWidth(result.data.extractedJson.Size.width/width);
    setResHeight(result.data.extractedJson.Size.height/height);
    setImageData(result.data.pidUri);
    setInputData(result.data.extractedJson.linegroup);
    if(localStorage.getItem("prevRoute") === "overallMTO") {
      localStorage.setItem("MTOData",JSON.stringify(result.data.extractedJson.linegroup));
      accurateSymbols1(JSON.parse(localStorage.getItem("MTOData")),result.data.extractedJson.Size.width/width,result.data.extractedJson.Size.height/height);
    }
    if(localStorage.getItem("prevRoute") === "process") {
      localStorage.setItem("MTOData",JSON.stringify(result.data.extractedJson.linegroup));
    setShapes(AssetProperties(Object.entries(JSON.parse(localStorage.getItem("MTOData"))),result.data.extractedJson.Size.width/width,result.data.extractedJson.Size.height/height));
    }
    if(result.status === 200) {
      setLoading(false);
    }
  }


  const accurateSymbols1 = (data,width,height,lineNumberValidate=undefined) => {
    const mtoDats =JSON.parse(JSON.stringify(data)) ;
    const mtoDat = Object.entries(mtoDats);
    let accuracyData = [];
    let symbolsDat = {};
   let km = {};
    if(mtoDat.length > 0) {
      const cScoreArray = UserStore.accuracyPID;
      let cScoreValid = "";
        let val1 = 100;
        let val2 = 0;
        if(cScoreArray.length === 1) {
          cScoreValid = cScoreArray[0];
          if(cScoreValid === "low") {
          val1 = 60;
          val2 = 0;
        }
        else if(cScoreValid === "mid") {
          val1 = 80;
          val2 = 61;
        }
        else if(cScoreValid === "high") {
          val1 = 100;
          val2 = 81;
        }
        else {
          val1 = 100;
          val2 = 0;
        }
        }
        else if(cScoreArray.length === 2) {
          if(cScoreArray.includes("low") && cScoreArray.includes("mid")) {
            val1 = 80;
            val2 = 0;
          }
          else if(cScoreArray.includes("high") && cScoreArray.includes("mid")) {
            val1 = 100;
            val2 = 61;
          }
        }
        else if(cScoreArray.length === 3) {
          val1 = 100;
          val2 = 0;
        }
      for(let index in mtoDat) {
        const lgNumber = mtoDat[index][0];
        accuracyData = [];
         symbolsDat = Object.entries(mtoDat[index][1].Symbols).map((row) => ( { [row[0]]: row[1] } ));
        km = {};
         for(let k in symbolsDat) {
           const rowValue = Object.entries(symbolsDat[k]);
           let cScore = rowValue[0][1].ConfidenceScore;
           if(cScore === "NaN") {
             cScore = 100;
           }
           if(cScoreArray.includes("high") && cScoreArray.includes("low") && cScoreArray.length === 2) {
            if(!(cScore <= 80 && cScore >= 61)) {
              accuracyData.push(symbolsDat[k])
              Object.assign(km,symbolsDat[k]);
            }
          }
          else {
            if(cScore <= val1 && cScore >= val2) {
              accuracyData.push(symbolsDat[k])
              Object.assign(km,symbolsDat[k]);
            }
          }
            
           
         }
         mtoDats[lgNumber].Symbols = km;
      }
    // }
    }
    let newShape = AssetProperties(Object.entries(mtoDats),width,height,lineNumberValidate);
  setShapes(newShape);
  mtoAccurateData = mtoDats;
  return newShape;
}

  const storeData = useSelector((state) => state.documentData.documentList);
  const docList = useSelector((state) => state.documentData.documentList);
  const lineGroups = useSelector((state) => state.documentData.lineGroupSpec);
  const shortCodeList = useSelector((state) => state.documentData.shortCodeList);
  const [assetComponent,setAssetComponent] = useState("");
  useEffect(() => {
    let assetComp = "";
    if(shortCodeList !== undefined) {
      axios.get(API_Endpoint + `/GetPidAssetComponentType?projectWorkSpaceId=${UserStore.PIDworkspaceID}&shortCode=${shortCodeList[0]}`).then((result) => {
        assetComp = result.data.toUpperCase();
        setAssetComponent(assetComp);
    });
    }
  },[shortCodeList])
  const [sortedLineGroupsData,setLineGroup] = useState([]);
  const dispatch = useDispatch();
  const [shapes, setShapes] = useState([]);

  /* change the state of the shapes if there is a change in AssetsJson */
  useEffect(() => {
    // if(storeData !== undefined)
    accurateSymbols();
      // setShapes(AssetProperties(Object.entries(storeData),resWidth,resHeight));
  },[storeData])

  let mtoAccurateData = JSON.parse(JSON.stringify(docList));
  /* Filter Symbols depending on the confidence score of each symbol */
  const accurateSymbols = (lineNumberValidate = undefined) => {
      const mtoDats =JSON.parse(JSON.stringify(storeData)) ;
      const mtoDat = Object.entries(mtoDats);
      let accuracyData = [];
      let symbolsDat = {};
     let km = {};
      if(mtoDat.length > 0) {
        const cScoreArray = UserStore.accuracyPID;
        let cScoreValid = "";
          let val1 = 100;
          let val2 = 0;
          if(cScoreArray.length === 1) {
            cScoreValid = cScoreArray[0];
            if(cScoreValid === "low") {
            val1 = 60;
            val2 = 0;
          }
          else if(cScoreValid === "mid") {
            val1 = 80;
            val2 = 61;
          }
          else if(cScoreValid === "high") {
            val1 = 100;
            val2 = 81;
          }
          else {
            val1 = 100;
            val2 = 0;
          }
          }
          else if(cScoreArray.length === 2) {
            if(cScoreArray.includes("low") && cScoreArray.includes("mid")) {
              val1 = 80;
              val2 = 0;
            }
            else if(cScoreArray.includes("high") && cScoreArray.includes("mid")) {
              val1 = 100;
              val2 = 61;
            }
          }
          else if(cScoreArray.length === 3) {
            val1 = 100;
            val2 = 0;
          }
        for(let index in mtoDat) {
          const lgNumber = mtoDat[index][0];
          accuracyData = [];
           symbolsDat = Object.entries(mtoDat[index][1].Symbols).map((row) => ( { [row[0]]: row[1] } ));
          km = {};
           for(let k in symbolsDat) {
             const rowValue = Object.entries(symbolsDat[k]);
             let cScore = rowValue[0][1].ConfidenceScore;
             if(cScore === "NaN") {
               cScore = 100;
             }
             if(cScoreArray.includes("high") && cScoreArray.includes("low") && cScoreArray.length === 2) {
              if(!(cScore <= 80 && cScore >= 61)) {
                accuracyData.push(symbolsDat[k])
                Object.assign(km,symbolsDat[k]);
              }
            }
            else {
              if(cScore <= val1 && cScore >= val2) {
                accuracyData.push(symbolsDat[k])
                Object.assign(km,symbolsDat[k]);
              }
            }
              
             
           }
           mtoDats[lgNumber].Symbols = km;
        }
      // }
      }
      let newShape = AssetProperties(Object.entries(mtoDats),resWidth,resHeight,lineNumberValidate);
    setShapes(newShape);
    mtoAccurateData = mtoDats;
  }

  useEffect(() => {
    accurateSymbols();
  },[UserStore.accuracyPID])

  useEffect(() => {
      getAPICall();
  },[]);

  let shapesData = shapes;
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [drag, setDrag] = useState(true);
  const [shape, setShape] = useState();
  const [stageScale, setStageScale] = useState(1);
  const [stageX, setStageX] = useState(0);
  const [stageY, setStageY] = useState(0);
  const [editData, setEdit] = useState(false);
  const [selectedAssetId, setSelectedAssetId] = useState("123");
  const [selectedLine, setSelectedLine] = useState("");
  let selectedId = "";
  const [drawnShape, setDrawnShape] = React.useState();

/* selected asset state change*/
  const selectedAssetData = useSelector((state) => state.documentData.selectedAssetData);
  const selectedAsset = useSelector((state) => state.documentData.selectedItem);
  useEffect(() => {
    setSelectedAssetId(selectedAssetData);
    selectedId = selectedAssetId;
    if(selectAsset !== undefined)
      handleMultiWaySelect()
  },[selectedAssetData,selectedAsset]);

  const dummyShape = {
    coords: null,
    assetType: null,
    orientation: null,
    stroke: null,
    strokeWidth: null,
    assetId: null,
    x: null,
    y: null,
    width: null,
    height: null,
    points: null,
  };
  const [editAssetData, setEditAssetData] = useState([]);
  const [editLineData, setEditLineData] = useState([]);
  const [editedData, setEditedData] = useState([]);
 const [load,setLoad] = useState(true);
 
  const image = new window.Image();
  image.setAttribute('crossOrigin', 'anonymous');
  image.src = imageData;
  image.onload = () => {
    setLoad(false);
    imageLoading = false;
  }
  const updateWidthAndHeight = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
    setLoading(false);
  };

  /* Function for storing the newly added line details*/
  const handleClose1 = () => {
    edit.current = false;
    if (editLineData.edit === true) {
      editLineData.edit = false;
    } else {
      drawnShape.Coords.XMin = Math.floor(drawnShape.Coords.XMin * resWidth);
      drawnShape.Coords.XMax = Math.floor(drawnShape.Coords.XMax * resWidth);
      drawnShape.Coords.YMin = Math.floor(drawnShape.Coords.YMin * resHeight);
      drawnShape.Coords.YMax = Math.floor(drawnShape.Coords.YMax * resHeight);
      if (newLineNumber) {
        drawnShape.specification = newLineNumber;
        dispatch(newLineGroup(drawnShape));
        setNewLineNumber("");
      } else {
        dispatch(AddNewAsset(drawnShape));
      }
    }
    setShow1(false);
   
    let newShape = AssetProperties(Object.entries(storeData),resWidth,resHeight); /******checkkkkk thisssssss */
    setShapes(newShape);
  };

 
  const handleCloseEditLine = () => {
    edit.current = false;
    setShow1(false);
  }

  /* Function for storing the newly added symbol details*/
  const handleClose2 = () => {
    edit.current = false;
    if(editAssetData.edit === true) {
      editAssetData.edit = false;
    }
    else {
      drawnShape.Coords.XMin = Math.floor(drawnShape.Coords.XMin * resWidth);
      drawnShape.Coords.XMax = Math.floor(drawnShape.Coords.XMax * resWidth);
      drawnShape.Coords.YMin = Math.floor(drawnShape.Coords.YMin * resHeight);
      drawnShape.Coords.YMax = Math.floor(drawnShape.Coords.YMax * resHeight);
    if (newLineNumber) {
      drawnShape.specification = newLineNumber;
      dispatch(newLineGroup(drawnShape));
      setNewLineNumber("");
    } else {
      dispatch(AddNewAsset(drawnShape));
    }
    }
    setShow2(false);
    let newShape = AssetProperties(Object.entries(storeData),resWidth,resHeight);
    setShapes(newShape);
  };

  const handleCloseAddSymbol = () => {
    edit.current = false;
    const selectedItem = {
          id : undefined
    }
    dispatch(selectAsset(selectedItem));
    if(editAssetData.edit === true) {
      editAssetData.edit = false;
    }
    else {
    //   shapesData.pop();
    //    shapesData.pop();
    // shapesData.pop();
    
    shapesData.push(dummyShape);
    // accurateSymbols();
    setShapes(shapesData);
    // drawnShape.lineNumber = undefined;
    }
    accurateSymbols();
    setShow2(false);
  };

  const handleCloseAddLine = () => {
    edit.current = false;
    if(editLineData.edit === true) {
      editLineData.edit = false;
    }
    else {
    // shapesData.pop();
    // shapesData.pop();
    // shapesData.pop();
    // accurateSymbols();
    shapesData.push(dummyShape);
    setShapes(shapesData);
    }
    accurateSymbols();
    setShow1(false);
  };

  const handleClose3 = () => {
    setShow3(false);
    accurateSymbols();
    // setShapes(AssetProperties(Object.entries(storeData),resWidth,resHeight)); /***** change this */
  };

  const handleCloseEdit = () => {
    setEdit(false);
  }

  
  const handleShow1 = () => {
    setShape({ assetType: "lines" });
    draw.current = true;
    edit.current = false;
  };

  const handleShow2 = () => {
    setShape({ assetType: "symbols" });
    draw.current = true;
    
  };

  const handleShow3 = () => {
    setShow3(true);
  };
 
  /* Function to handle zoomIn */
  const zoomIn = () => {
    let scaleBy = 1.3;
    var oldScale = stageScale;
    var center = {
      x: width / 2,
      y: height / 2,
    };
  
    var relatedTo = {
      x: (center.x - stageX) / oldScale,
      y: (center.y - stageY) / oldScale,
    };
  
    var newScale = oldScale * scaleBy ;
    setStageScale(newScale);
    var newPos = {
      x: center.x - relatedTo.x * newScale,
      y: center.y - relatedTo.y * newScale,
    };
    setStageX(newPos.x)
    setStageY(newPos.y);
  };

  
  /* Function to handle zoomOut */
  const zoomOut = (e) => {
    let scaleBy = 1.3;
    if(stageScale > 1.01) {
    var oldScale = stageScale;
    var center = {
      x: width / 2,
      y: height / 2,
    };
  
    var relatedTo = {
      x: (center.x - stageX) / oldScale,
      y: (center.y - stageY) / oldScale,
    };
  
    var newScale = oldScale / scaleBy ;
    setStageScale(newScale);
    var newPos = {
      x: center.x - relatedTo.x * newScale,
      y: center.y - relatedTo.y * newScale,
    };
    setStageX(newPos.x)
    setStageY(newPos.y);
  }
  };
  const [newLineNumber, setNewLineNumber] = useState("");
  const getNewLineNumber = (e) => {
    setNewLineNumber(e.target.value);
    drawnShape.lineNumber = newLineNumber;
  };

  
  const setAdditionalProperties = (data) => {
    drawnShape.Size1 = data.Size1;
    drawnShape.Size2 = data.Size2;
    drawnShape.IsJacketedLine = data.IsJacketedLine;
    drawnShape.ShortCode = data.ShortCode;
    drawnShape.InsulationType= data.InsulationType;
    drawnShape.AgUg = data.AgUg;
    drawnShape.Seat = data.Seat;
    drawnShape.ValveBody = data.ValveBody;
    drawnShape.ExtendedBonet = data.ExtendedBonet;
    drawnShape.LimitSwich=data.LimitSwich;
    drawnShape.Hold=data.Hold;
    drawnShape.Quantity = data.Quantity;
    drawnShape.HdrSize = data.HdrSize;
    drawnShape.assetComponentType = data.assetComponentType;
    drawnShape.Spec = data.Spec;
    drawnShape.SequenceNumber = data.SequenceNumber;
    drawnShape.FromLine = data.FromLine;
    drawnShape.ToLine = data.ToLine;
    drawnShape.Remarks = data.Remarks;
    drawnShape.ItemOrder = data.ItemOrder;
    drawnShape.Fluid = data.Fluid;
    drawnShape.Rating = data.Rating;
    drawnShape.SymbolRank = data.SymbolRank;
  }

  /* Function to capture the newly drawn line or symbol initial coordinates */
  const handleMouseDown = (e) => {
    setSelectedAssetId(undefined)
    if (!draw.current) {
      return;
    }
    // orientation = 0;
    setDrag(false);
    const scale = e.target.getStage().scaleX();
    const x = Math.abs(e.target.getStage().x());
    const y = Math.abs(e.target.getStage().y());
    const position = e.target.getStage().getPointerPosition();
    setShape({
      ...shape,
      x: Math.round((x + position.x) / scale),
      y: Math.round((y + position.y) / scale),
      position: position.y,
    });
  };

  /* Function to capture the newly drawn line or symbol  width and height */
  const handleMouseMove = (e) => {
    let orientation = 0;
    if (!draw.current) {
      return;
    }
    const point = e.target.getStage().getPointerPosition();
    if(shape.position > point.y) {
      orientation = -90;
    }
    const scale = e.target.getStage().scaleX();
    const x = Math.abs(e.target.getStage().x());
    const y = Math.abs(e.target.getStage().y());

    /*add point*/
    
    setShape({
      ...shape,
      height: Math.round((y + point.y) / scale),
      width: Math.round((x + point.x) / scale),
      orientation: orientation,
    });
    const assetType = shape.assetType;

    let coords = {
      xmin: Math.min(shape.x, shape.width),
      ymin: Math.min(shape.y, shape.height),
      xmax: Math.max(shape.x, shape.width),
      ymax: Math.max(shape.y, shape.height),
    };

    // let orientation = 90;
    let new_width = coords.xmax - coords.xmin;
    let new_height = coords.ymax - coords.ymin;
    let assetId = (assetType === "lines") ? {"LineSegmentId":"A"+index}: {"SymbolId":"A"+index}
    let _drawn_shape_ = {
      coords: coords,
      assetType: assetType,
      orientation: orientation,
      stroke: assetType === "lines" ? "maroon" : "blue",
      strokeWidth: 0.5,
       ...assetId,
      x: coords.xmin,
      y: coords.ymin,
      width: new_width,
      height: new_height,
      points: assetType === "lines" ? [0, 0, new_width, new_height] : [],
      rotation : orientation,
    };
   
    if(shapesData[shapesData.length - 1].id == undefined)
      shapesData.pop();
    shapesData.push(_drawn_shape_);
  };

  /* Function to capture the newly drawn line or symbol final coordinates */
  const handleMouseUp = (e) => {
    if (draw.current === true) {
      draw.current = false;
      const assetType = shape.assetType;
      let coords = {
        XMin: Math.floor(Math.min(shape.x, shape.width)),
        YMin: Math.floor(Math.min(shape.y, shape.height)),
        XMax: Math.floor(Math.max(shape.x, shape.width)),
        YMax: Math.floor(Math.max(shape.y, shape.height)),
      };
      let new_width = coords.XMax - coords.XMin;
      let new_height = coords.YMax - coords.YMin;
      let assetId = (assetType === "lines") ? {LineSegmentId:"A"+index}: {SymbolId:"A"+index}
      let _drawn_shape_ = {
        ...assetId,
        Coords: coords,
        assetType: assetType,
        rotation: shape.orientation,
        orientation: shape.orientation,
        stroke: assetType === "lines" ? "maroon" : "blue",
        strokeWidth: 3,
        x: coords.XMin,
        y: coords.YMin,
        width: new_width,
        height: new_height,
        points: assetType === "lines" ? [0, 0, new_width, new_height] : [],
        strokeScaleEnabled: false,
        lineNumber: lineGroups[0],
        ConfidenceScore: 100,
      };
      index = index + 1;
      shapesData.push(_drawn_shape_);

      shapesData.push(dummyShape);
      setShapes(shapesData);
      if (assetType === "lines") {

        setShow1(true);
      } else if (assetType === "symbols") {
        setShow2(true);
      }
      setDrawnShape(_drawn_shape_);
      setDrag(true);
    }
  };

  /* Print the PID */
  const handlePrint = useReactToPrint({
    content: () => comp.current,
   
    copyStyles: false,
    pageStyle: "@page {size: A3 landscape;  object-fit: cover;  } @media print { canvas { width: 100vw !important; height: 68vw !important;image-resolution: 300dpi;}}",
  });

  
  /* ******** Export canvas data ***************
  const printPID = () => {
    // console.log(comp.toDataURL({ pixelRatio: 3 }))
    // console.log(comp.current.getStage());
    let dataURL = stageRef.current.getStage().toDataURL({ 
    quality: 4,
    pixelRatio: 3});
    // console.log(dataURL);
    downloadURI(dataURL, 'stage.png');
  }

  let k = "";
  function downloadURI(uri, name) {
    let link = document.createElement('a');
    link.download = name;
    link.href = uri;
    link.id = "printID"
    // console.log(link.href)
    document.body.appendChild(link);
    console.log(document.body);
    // link.click();
    k = document.getElementById("printID");
    console.log(k);
    handlePrint();
    // document.body.removeChild(link);
    // delete link;
  }
  */
 

  const handleDelete = () => {
    setShapes(AssetProperties(Object.entries(storeData),resWidth,resHeight))
  }

  const symbolsData = useSelector(state => state.documentData.symbolsDetails);
  let lineGroupData = useSelector(state => state.documentData.lineGroupSpec);
  const [lgLength,setLgLength] = useState(0);

  /* MultiWay Selection (from window 2 to window 1) */
  const handleMultiWaySelect = () => {
    if(selectedAsset !== undefined) {
    setShapes(AssetProperties(Object.entries(storeData),resWidth,resHeight))
      edit.current = true;
      const properties = {
        AgUg: selectedAsset.ugag,
        ExtendedBonet: selectedAsset.extendedBonet,
        Fluid: selectedAsset.fluid,
        HdrSize: selectedAsset.hdr,
        Hold: selectedAsset.hold === "N" ? false : true,
        InsulationType: selectedAsset.insulationType,
        IsJacketedLine: selectedAsset.isJacketed === "N" ? false : true,
        LimitSwich: selectedAsset.limitSwich,
        Quantity:selectedAsset.quantity,
        Seat: selectedAsset.seat,
        SequenceNumber: selectedAsset.Seq,
        Size1: selectedAsset.size1,
        Size2: selectedAsset.size2,
        Spec: selectedAsset.Spec,
        ValveBody:selectedAsset.valveBody,
        assetComponentType: selectedAsset.assetCompType,
        assetID: selectedAsset.id,
        assetId: selectedAsset.assetId,
        assetType: "symbols",
        lineNumber:selectedAsset.LineNumber,
        shortCode: selectedAsset.shortCode,
        id: selectedAsset.assetId,
        Coords: selectedAsset.coords,
        edit: true,
        ToLine: selectedAsset.toLine,
        FromLine: selectedAsset.fromLine,
        Remarks:selectedAsset.remarks,
        ItemOrder: selectedAsset.itemOrder,
        Rating: selectedAsset.rating,
        SymbolRank: selectedAsset.SymbolRank,
        ConfidenceScore: selectedAsset.ConfidenceScore,
        CorrectedRow: selectedAsset.CorrectedRow,
        select: "multiWay",
      }
      const rankingData = {
        properties: properties,
        symbolsData: symbolsData,
    }
    let data = (symbolsRanking(rankingData.properties,rankingData.symbolsData));
    for(let lg in lineGroupData) {
      if(!data.includes(lineGroupData[lg])) {
          data.push(lineGroupData[lg])
      }
    }
    setLineGroup(data);
      setEditAssetData(properties);
      setShow2(true);
  }
}

/* Function for getting the properties of a line or symbol on click(window1) */
  const handleSelect = (e) => {
    accurateSymbols();
    // setShapes(AssetProperties(Object.entries(storeData),resWidth,resHeight))
    if (e.target.attrs.assetType === "lines") {
      edit.current = true;
      const editedData = {
        lineNumber: e.target.attrs.lineNumber,
        other: e.target.attrs,
        id: e.target.attrs.id,
        coords: e.target.attrs.coords,
        assetType:"lines",
        edit: edit.current,
        LineSegmentId: e.target.attrs.assetID,
      };
      accurateSymbols(editedData.lineNumber);
      // setShapes(AssetProperties(Object.entries(storeData),resWidth,resHeight,editedData.lineNumber))
      setEditLineData(editedData);
      setShow1(true);
    }
    if (e.target.attrs.assetType === "symbols") {
      edit.current = true;
      const properties = {
        AgUg: e.target.attrs.AgUg,
        ExtendedBonet: e.target.attrs.ExtendedBonet,
        Fluid: e.target.attrs.Fluid,
        HdrSize: e.target.attrs.HdrSize,
        Hold: e.target.attrs.Hold === "Y" || e.target.attrs.Hold === true ? true : false,
        InsulationType: e.target.attrs.InsulationType,
        IsJacketedLine: e.target.attrs.IsJacketedLine === "Y" || e.target.attrs.IsJacketedLine === true ? true : false,
        LimitSwich: e.target.attrs.LimitSwich,
        Quantity: e.target.attrs.Quantity,
        Seat: e.target.attrs.Seat === null || e.target.attrs.Seat === undefined? "" : e.target.attrs.Seat,
        SequenceNumber: e.target.attrs.SequenceNumber === null || e.target.attrs.SequenceNumber === undefined ? "" : e.target.attrs.SequenceNumber,
        Size1: e.target.attrs.Size1,
        Size2: e.target.attrs.Size2,
        Spec: e.target.attrs.Spec,
        ValveBody: e.target.attrs.ValveBody,
        assetComponentType: e.target.attrs.assetComponentType,
        assetID: e.target.attrs.assetID,
        assetType: e.target.attrs.assetType,
        lineNumber: e.target.attrs.lineNumber,
        shortCode: e.target.attrs.shortCode,
        assetID: e.target.attrs.assetID,
        id: e.target.attrs.id,
        Coords: e.target.attrs.coords,
        edit: edit.current,
        ToLine: e.target.attrs.ToLine,
        FromLine: e.target.attrs.FromLine,
        Remarks: e.target.attrs.Remarks,
        ItemOrder: e.target.attrs.ItemOrder,
        Rating: e.target.attrs.Rating,
        SymbolRank: e.target.attrs.SymbolRank,
        ConfidenceScore: e.target.attrs.ConfidenceScore,
        CorrectedRow: e.target.attrs.CorrectedRow,
        select: "",
      }
      // setShapes(AssetProperties(Object.entries(storeData),resWidth,resHeight,properties.lineNumber))
      accurateSymbols(properties.lineNumber);
      const rankingData = {
          properties: properties,
          symbolsData: symbolsData,
      }
      let data = (symbolsRanking(rankingData.properties,rankingData.symbolsData));
      setLgLength(data.length);
      for(let lg in lineGroupData) {
        if(!data.includes(lineGroupData[lg])) {
            data.push(lineGroupData[lg])
        }
      }
     
      setLineGroup(data);
      setEditAssetData(properties);
      setShow2(true);
    }
    if (e.target.attrs.image) {
      setSelectedAssetId("undefined");
      setSelectedLine("undefined");
    } else {
      setSelectedAssetId(e.target.attrs.assetID);
      setSelectedLine(e.target.attrs.lineNumber);
    }
    selectedId = selectedAssetId;
    dispatch(FilterLineGroup(e.target.attrs,mtoAccurateData));
  };

  const setSpec = (linenumber) => {
    if (edit.current === false) {
      drawnShape.lineNumber = linenumber;
    }
   
  };

  /* ZoomIn and zoomOut using mouse scroll */
  const handleWheel = (e) => {
    e.evt.preventDefault();

    const scaleBy = 1.3;
    const stage = e.target.getStage();
    const oldScale = stage.scaleX();
    const mousePointTo = {
      x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
      y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale
    };

    const newScale = e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;

    stage.scale({ x: newScale, y: newScale });
    setStageX(-(mousePointTo.x - stage.getPointerPosition().x / newScale) * newScale);
    setStageY(-(mousePointTo.y - stage.getPointerPosition().y / newScale) * newScale);
    setStageScale(newScale);
  };


  /* Function to get the image position details when image is moved or dragged from it's initial position*/
  const boundFunc = (pos, scale) => {

    const x = Math.min(0, Math.max(pos.x, width * (1 - scale)));
    const y = Math.min(0, Math.max(pos.y, height * (0.5 - scale)));

    return {
      x,
      y
    };
  }


  const dragBoundFunc = pos => {
    return boundFunc(pos, stageScale);
  };

  return (
    <LoadingOverlay
    active={load}
    spinner
    text="Loading PID Image..."
    classNamePrefix = "myloader"
  >
    <div className="image-container" style={{width: "50vw",
    height: "80vh"}}>
      <div className="image-toolbar">
      <Tooltip title="Click to zoom in" arrow>
        <div className="tool"  onClick={zoomIn}>
          <img src="/images/zoomin.svg" className="tool-icon" />
        </div>
        </Tooltip>
        <Tooltip title="Click to zoom out" arrow>
        <div className="tool"  onClick={zoomOut}>
          <img src="/images/zoomout.svg" className="tool-icon" />
        </div>
        </Tooltip>
        <Tooltip title="Click to add line" arrow>
        <div className="tool"  onClick={handleShow1}>
          <img src="/images/Line.svg" className="tool-icon" />
        </div>
        </Tooltip>
        <Tooltip title="Click to add symbol" arrow>
        <div className="tool"  onClick={handleShow2}>
          <img src="/images/Rectangle.svg" className="tool-icon" />
        </div>
        </Tooltip>
      </div>

      <div className="image-wrapper">
        
        <div className="image-options">
          <p style={{marginLeft: "-1.8vw"}}>
            <b>NOTE:</b> Select Lines or Components from the diagram to view the line wise MTO report.
          </p>
          <div className="expand-PID" onClick={handleShow3}>
            <img src="images/expand.svg" className="expand-icon" style={{width:"1vw"}}/>
            <span className="text">Expand Window</span>
          </div>
          <div className="print-PID" onClick={handlePrint}>
            <img src="/images/print.svg" className="download-icon" style={{width:"1.3vw"}}/>
            <span className="text">Print PID</span>
          </div>
        </div>
        
        <div className="PID-wrapper" ref={comp}>
         
          <Stage
            width={width}
            height={height}
            scaleX={stageScale}
            scaleY={stageScale}
            x={stageX}
            y={stageY}
            onMouseDown={handleMouseDown}
            onMousemove={handleMouseMove}
            onMouseup={handleMouseUp}
            draggable={drag}
            ref={stageRef}
            onClick={handleSelect}
            onWheel={handleWheel}
            dragBoundFunc={dragBoundFunc}
            id="stage"
            pixelRatio={3}
          >
            <Layer>
              <Image
                image={image}
                width={width}
                height={height}
               
              ></Image>
              { shapes.map((shape, key) => (
                <AssetsShape
                  key={key}
                  shape={shape}
                  selectedId={selectedAssetId}
                  dispatcher={dispatch}
                  width={resWidth}
                  height={resHeight}
                  selectedLineNumber = {selectedLine}
                />
              )) }
            </Layer>
          </Stage>
        </div>
      </div>

      
      <AddLine
        show={show1}
        onHide={handleClose1}
        onChange={getNewLineNumber}
        onSelectChange={setSpec}
        onClose={handleCloseAddLine}
        onCloseEditLine={handleCloseEditLine}
        assetProperties = {editLineData}
        delete = {handleDelete}
      />
      <AddSymbol
        show={show2}
        onHide={handleClose2}
        onChange={getNewLineNumber}
        onSelectChange={setSpec}
        onClose={handleCloseAddSymbol}
        properties={setAdditionalProperties}
        assetProperties = {editAssetData}
        delete = {handleDelete}
        lineGroups = {editAssetData.edit === true ? sortedLineGroupsData : lineGroups}
        maxRank = {lgLength}
        mtoData = {Object.entries(storeData)}
        assetComponent = {assetComponent}
      />
      
      <ExpandWindow data = {storeData} isOpen={show3} toggle={handleClose3} />
    </div>
    </LoadingOverlay>
  );
}
