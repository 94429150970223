import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Suggestions from "../../CreateWorkspace/ProjectDetails/Suggestions";
import { TextField, MuiThemeProvider, Button } from '@material-ui/core';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";

export default function AddUser(props) {
    let suggestionDiv;
    if (props.showSuggestions) {
      suggestionDiv = (
        <Suggestions suggestions={props.suggestions} suggestionClickHandler={(s) => props.suggestionClickHandler(s)}></Suggestions>
      );
    } else {
      suggestionDiv = <div></div>;
    }
    function handlerole(role) {
      switch (role) {
        case 1:
          return "Admin";
        case 2:
          return "Read / Write";
        case 3:
          return "Read Only";
        default: 
          return role;
      }
    };

return(
        <Dialog className="addUserTable" open={props.show} onClose={props.handleClose}>
            <DialogTitle id="form-dialog-title"> 
                User Details
                <IconButton onClick={props.handleClose}>{" "}
                    <CloseIcon />{" "}
                </IconButton>
            </DialogTitle>
            <DialogContent>
                {
                    <table width="100px">
                      <tr>
                        <td>
                          <div className="input-group" onKeyDown={props.onEnterButton} style={{ marginTop: "1%" }}>
                            <div className="suggestions" ref={props.wrapperRef}>
                              <Tooltip title="Please type 3 characters to search for user" aria-rowcount>
                                <TextField
                                  autoComplete="off"
                                  type="text"
                                  id="search-box1"
                                  ref={props.addUserRef}
                                  className="form-control"
                                  placeholder="Add users"
                                  onChange={props.onChangeHandler1}
                                  onPaste={props.pasteFunction}
                                  onBlur={(e) => {}}
                                  onClick={() => {
                                    if (props.suggestions.length > 0)
                                      props.setshowSuggestions(true);
                                  }}
                                  error={props.tooltipError}
                                  helperText={props.tootlTipErrorMessage}
                                  style={{ marginTop: "5%" }}
                                />
                              </Tooltip>
                              {suggestionDiv}
                            </div>
                          </div>
                        </td>
                        <td>
                          <TextField
                            autoFocus
                            margin="dense"
                            id="assignRole"
                            select
                            className="assign-role-dropdown"
                            label="Assign User Group"
                            style={{ marginRight: "2% !important" }}
                            onChange={props.handleRoleChange}
                            value={props.dropdownvalue}
                            SelectProps={{ native: true }}
                          >
                            {props.roles.map((option) => (
                              <option
                                key={option.roleId}
                                value={option.roleName}
                              >
                                {option.roleName}
                              </option>
                            ))}
                          </TextField>
                        </td>
                        <td>
                          <p class="add-users-icon" onClick={props.AddUser}>
                            <Tooltip title="Add users to workspace" arrow>
                              <img
                                src="/images/icon_add_blue.svg"
                                tabIndex={0}
                                style={{
                                  paddingLeft: "10",
                                  color: "#0C629B",
                                  textDecoration: "underline",
                                  height: "1.5vw",
                                  width: "1.5vw",
                                  marginTop: "1vw",
                                }}
                                alt="addButton"
                              />
                            </Tooltip>
                          </p>
                        </td>
                      </tr>
                      {props.useradd.map((items) => (
                        <tr style={{ marginTop: "1%" }}>
                          <td>{items.enterpriseId}</td>
                          <td>{handlerole(items.roleId)}</td>
                          <td>
                            <Tooltip title="Delete user" arrow>
                              <img
                                id={items.enterpriseId}
                                onClick={(e) => {
                                  props.handleDelete(e, items);
                                }}
                                src="/images/icon_delete.svg"
                                style={{ height: "1.3vw" }}
                              />
                            </Tooltip>
                          </td>
                        </tr>
                      ))}
                    </table>
                  }
            </DialogContent>
            <DialogActions style={{marginRight: "1vw"}}>
            <Button
                      variant="contained"
                      class="next-btn"
                      onClick={props.handleClose}
                      style={{ height: "4vh", width: "8vw",
                      background: "#0c629b",
                      border: "1px solid #0c629b",
                      borderRadius: "4px",
                      color: "#ffffff",
                      textTransform:"none",
                      // margin: "0 1.9vw 0 1vw",
                      // fontSize: "0.9vw",
                     }}
                    >                     
                         Ok                         
                    </Button>
            </DialogActions>
        </Dialog>
)}