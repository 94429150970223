import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const customContentStyle = {
    width: "70%",
    maxWidth: "none",
};

export default function ShowUsers(props) {
    return (
        <Dialog className="users-dialog userListDialog" fullWidth maxWidth="md" contentStyle={customContentStyle} open={props.showUsers} onClose={props.handleClose} >
            <DialogTitle id="form-dialog-title">
                <span style={{color: "#fff"}}>User Details</span>
                <IconButton onClick={props.handleClose}
                    style={{
                      padding: "0",
                      float: "right",
                      background: "#0C629B",
                      borderRadius: "0",
                      color: "#fff",
                    }}>
                    {" "}
                    <CloseIcon />{" "}
                </IconButton>
            </DialogTitle>
            <DialogContent style={{ height: "auto", overflow: "hidden" }}>
                {
                    <table bordered className="table1" border="0">
                      <thead align="center" valign="bottom" char="M">
                        <tr>
                          <th align="left">Users</th>
                          <th>User Group</th>
                        </tr>
                      </thead>
                      <tbody>
                      {props.filtereduserData[0].workSpaceUsers.map(
                        (items) => (
                          <tr>
                            <td>{items.enterpriseId}</td>
                            <td>{props.handlerole(items.roleId)}</td>
                          </tr>
                        )
                      )}
                      </tbody>
                    </table>
                }
            </DialogContent>
        </Dialog>
    )
}