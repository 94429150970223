import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App/App";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import { toast } from "react-toastify";
import { Provider } from "react-redux";
import CanvasState from "../src/components/redux/CanvasState";
import "react-toastify/dist/ReactToastify.css";

// toast-configuration method,
// it is compulsory method.
toast.configure();
//create reduxStore
const store = CanvasState();
// For GET requests
/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
const msalInstance = new PublicClientApplication(msalConfig);
//  async function getToken() {
//    const account = msalInstance.getActiveAccount();
//    const response = await msalInstance.acquireTokenSilent({
//      ...loginRequest,
//      account: account,
//    });
//    return response;
//  }

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <MsalProvider instance={msalInstance}>
        <App />
        {/* <GetToken /> */}
      </MsalProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
