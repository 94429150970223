import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Grid from "@material-ui/core/Grid";
import UserStore from "../UserStore";
import { observer } from "mobx-react-lite";
import "./Progress.scss";
import { toJS } from "mobx";

const Progress = () => {
  const count = UserStore.countforProgress;
  let files = [];
  let files1 = [];
  let files2 = []; 
  let selectedfiles = toJS(UserStore.selectedfilesforupload);
  let rejectedfiles = toJS(UserStore.rejectedfilesforupload);  
  const [progress, setProgress] = React.useState(0);
  const [buffer, setBuffer] = React.useState(10);

  UserStore.setCount(1);
  const percent = UserStore.percentage;
  files = UserStore.fileSucceded.map((number) => (
    <div className="completedFile">
    <div
      style={{ font: "1vw Helvetica", padding: "5px", width: '60%' }}      
    >
      {number}
      <LinearProgress variant="determinate" value={100} />      
    </div>
    <div className="showLable"  style={{ font: "1vw Helvetica", padding: "5px 10px", marginTop: '10px' }}> File Uploaded Successfully</div>
    </div>
  ));

  files1 = selectedfiles.map((number) => (
    
    <div
      style={{ font: "1vw Helvetica", padding: "5px" }}
      className="processFile"
    >
      {number}
      <LinearProgress variant="buffer" value={progress} valueBuffer={buffer} />
    </div>   
    
  ));

  if(rejectedfiles.length > 0){    
    files2 = UserStore.rejectedfilesforupload.map((number) => (
      
      <div className="completedFile">
    <div
      style={{ font: "1vw Helvetica", padding: "5px", width: '60%' }}
      className="MuiLinearProgress-barColorPrimary1"
    >
      {number}
      <LinearProgress variant="determinate" value={40} />
    </div>
    <div className="showLable"  style={{ font: "1vw Helvetica", padding: "5px 10px", marginTop: '10px', color:"red",width: '60%' }}> File Upload Failed</div>
    </div> 
    ));
    }

  return (
    <div>
      <paper>
        <Grid container spacing={1} justify="left" alignItems="center">
          <Grid item xs={10} spacing={8} justify="left">
            {files}
            {files1}
            {files2}
          </Grid>
        </Grid>
      </paper>
    </div>
  );
};

export default observer(Progress);
