import React, { Component } from "react";
import { toJS } from "mobx";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import UserStore from "../../UserStore";
import axios from "axios";

const API_Endpoint = process.env.REACT_APP_API_ENDPOINT;

class SelectChange extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: [],
    };
  }

  fetchIsometricFields = async () => {
    await axios.get(API_Endpoint + `/GetIsometricFields`).then((res) => {
      if (res.status === 200) {
        this.setState({
          fields: res.data,
        });
      }
    });
  };

  componentDidMount() {
    this.fetchIsometricFields();
  }

  render() {
    const isRegExp = toJS(UserStore.isRegExp);
    return isRegExp ? (
      <Select
        style={
          ({ display: "flex" },
          {
            marginLeft: "3%",
            font: "normal normal Helvetica 1.04vw",
            width: "95%",
          })
        }
        margin="dense"
        labelId="demo-simple-select-label"
        id={this.props.modalId}
        fullWidth
        value={this.props.editProducts.key}
        onChange={(e) => {
          this.props.handleDropdownchangerowEdit(e, this.props.idValue);
        }}
      >
        {this.state.fields.map((field) => {
          return (
            <MenuItem key={field.fieldId} value={field.fieldName}>
              {field.fieldName}
            </MenuItem>
          );
        })}
      </Select>
    ) : (
      <Select
        style={
          ({ display: "flex" },
          {
            marginLeft: "3%",
            font: "normal normal Helvetica 1.04vw",
            width: "95%",
          })
        }
        margin="dense"
        labelId="demo-simple-select-label"
        id={this.props.modalId}
        fullWidth
        value={
          this.props.editProducts.ChangeOutcome === ""
            ? ""
            : this.props.editProducts.ChangeOutcome
            ? 10
            : 20
        }
        onChange={(e) => {
          this.props.handleDropdownchangerowEdit(e, this.props.idValue);
        }}
      >
        <MenuItem value={10}>Change</MenuItem>
        <MenuItem value={20}>No Change</MenuItem>
      </Select>
    );
  }
}
export default SelectChange;
