import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { MuiThemeProvider, Button, MenuItem, Select } from "@material-ui/core";
import AppHeader from "../../AppHeader/AppHeader";
import FormControl from "@material-ui/core/FormControl";
import AppFooter from "../../AppFooter/AppFooter";
import BreadCrumbs from "../../Upload/BreadCrumbs/BreadCrumbs";
import "./setDetectionlogic.scss";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import UserStore from "../../UserStore";
import Modal from "react-bootstrap/Modal";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CloseIcon from "@material-ui/icons/Close";
import { toJS } from "mobx";
import { styled } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";

const API_Endpoint = process.env.REACT_APP_API_ENDPOINT;

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function SetDetectionlogic() {
  const history = useHistory();

  useEffect(() => {
    const isEditWs = localStorage.getItem("Editworkspace")
      ? JSON.parse(localStorage.getItem("Editworkspace"))
      : UserStore.editworkspace;
    if (isEditWs && UserStore.editRow.length) {
      let initdata = toJS(UserStore.DetectionData);
      testtdata = toJS(UserStore.editRow[0].detectionExpressionArray);
      setData(testtdata);
    }
  }, []);

  const isEditWorkspace = localStorage.getItem("Editworkspace")
    ? JSON.parse(localStorage.getItem("Editworkspace"))
    : UserStore.editworkspace;
  var [detectionQuery, setdetectionquery] = React.useState([]);
  useEffect(() => {
    const sdQuery = UserStore.setDetectionQuery
      ? UserStore.setDetectionQuery
      : localStorage.getItem("SetDetectionQuery")
      ? localStorage.getItem("SetDetectionQuery")
      : "";
    setdetectionquery(sdQuery);
  }, []);

  let [attribute, setattribute] = React.useState([]);
  let [operator, setoperator] = React.useState([]);

  let [arr, setarr] = useState([]);
  let [data, setData] = useState([]);
  let [testtdata, settesttdata] = useState([]);
  let [opendialog, setopendialog] = useState(false);
  let [index, setindex] = useState(0);
  let [messageselect, setmessageselect] = useState("");
  let [getoperand, setgetoperand] = useState(false);
  let [changechipvalue, setchangechipvalue] = useState("");

  let [name, setName] = useState("");
  let [userEmpty, setuserEmpty] = useState(false);

  let [valueAtt, setValueAttribute] = useState("");
  let [valueOpt, setValueOperator] = useState("");
  let [dailogText, setDailogtext] = useState("");

  //----------------Chip implementation for edit------------------------------------

  const ListItem = styled("li")(({ theme }) => ({
    margin: theme.spacing(0.5),
  }));
  const [chipData, setChipData] = React.useState([
    { key: 0, label: "Angular" },
    { key: 1, label: "jQuery" },
    { key: 2, label: "Polymer" },
    { key: 3, label: "React" },
    { key: 4, label: "Vue.js" },
  ]);

  const handleDelete = (chipToDelete) => () => {
    setData((chips) =>
      chips.filter((chip) => chip.index !== chipToDelete.index)
    );
  };

  const handleClick = (chipToDelete) => () => {
    setchangechipvalue(chipToDelete.index);

    if (
      chipToDelete.value === "AND" ||
      chipToDelete.value === "OR" ||
      chipToDelete.value === "(" ||
      chipToDelete.value === ")"
    ) {
      setmessageselect("Operator");
      setgetoperand(true);
    } else {
      setmessageselect("Attribute");
      setgetoperand(false);
    }
    setopendialog(true);
  };

  //------------------------end of chip implementation---------------------------------

  const classes = useStyles();
  const attributes = [
    "Line Number",
    "Document Number",
    "Sheet Number",
    "Revision Number",
    "Phrase1",
    "Phrase2",
    "Phrase3",
    "Phrase4",
  ];

  const operators = ["AND", "OR", "(", ")"];

  const handleChangeDailogDropdown = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    let index = data.findIndex((obj) => obj.index === changechipvalue);
    data[index].value = value;
    setValueAttribute(value);
  };

  const handleChangeDropdownAttribute = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    setattribute(value);
    setName(name);
    setValueAttribute(value);
  };

  const handleChangeDropdownOperator = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    setoperator(value);
    setName(name);
    setValueOperator(value);
  };

  // ---------------Start: Function to add new operator------------------
  const AddOperator = () => {
    setdetectionquery([]);
    const indexer = data.length;
    if (valueOpt !== "") {
      let addSelectedValue = [
        ...data,
        { id: name, value: valueOpt, index: indexer },
      ];
      setData(addSelectedValue);

      if (name == 1) {
        setattribute(valueAtt);
      } else if (name == 2) {
        setoperator(valueOpt);
      }
    }
  };
  // ---------------End: Function to add new operator------------------

  // ---------------Start: Function to add new attribute------------------
  const AddAttribute = () => {
    setdetectionquery([]);
    if (isEditWorkspace) {
      const index = data.length;
      setindex(index + 1);
    } else {
      setindex(index + 1);
    }

    if (valueAtt.length != 0) {
      let attributeValue = valueAtt.toLowerCase().split(" ");
      let attribute =
        attributeValue[0].charAt(0).toUpperCase() + attributeValue[0].slice(1);
      for (let i = 1; i < attributeValue.length; i++) {
        attribute =
          attribute +
          " " +
          attributeValue[i].charAt(0).toUpperCase() +
          attributeValue[i].slice(1);
      }
      valueAtt = attribute;
    }
    if (valueAtt !== "") {
      const indexer = data.length;
      let addSelectedValue = [
        ...data,
        { id: name, value: valueAtt, index: indexer },
      ];

      setData(addSelectedValue);
      if (name == 1) {
        setattribute(valueAtt);
      } else if (name == 2) {
        setoperator(valueOpt);
      }
    }
  };
  // ---------------End: Function to add new attribute------------------

  // ---------------Start: Function: Submit detection logic --------------------
  const handleSubmit = async (event) => {
    event.preventDefault();
    let expArray = [];

    expArray = data.map((a) => {
      return { ...a };
    });

    let res = "";

    for (var i = 0; i < expArray.length; i++) {
      if (
        expArray[i].value === "Line Number" ||
        expArray[i].value === "Document Number" ||
        expArray[i].value === "Phrase1" ||
        expArray[i].value === "Sheet Number" ||
        expArray[i].value === "Revision Number" ||
        expArray[i].value === "Phrase2" ||
        expArray[i].value === "Phrase3" ||
        expArray[i].value === "Phrase4"
      ) {
        expArray[i].value = true;

        res = res + "true";
      } else if (expArray[i].value === "AND") {
        expArray[i].value = "&&";
        res = res + "&&";
      } else if (expArray[i].value === "OR") {
        expArray[i].value = "||";
        res = res + "||";
      } else if (expArray[i].value === "(") {
        res = res + "(";
      } else if (expArray[i].value === ")") {
        res = res + ")";
      } else {
        res = res + expArray[i].value;
      }
    }
    // checking the result for detection logic
    try {
      var c = eval(res);
      const isEditWorkspaceFlag = localStorage.getItem("Editworkspace")
        ? JSON.parse(localStorage.getItem("Editworkspace"))
        : UserStore.editworkspace;
      if (
        isEditWorkspaceFlag === false &&
        res == "" &&
        !detectionQuery.length
      ) {
        setDailogtext("Please select a valid query");
        setuserEmpty(true);
      } else if (
        isEditWorkspaceFlag === false &&
        res == "" &&
        UserStore.inBusinessrule === true &&
        !detectionQuery.length
      ) {
        setDailogtext("Please select a valid query");
        setuserEmpty(true);
      } else if (expArray.length == 1) {
        setDailogtext("Please select a valid query");
        setuserEmpty(true);
      } else if (c == true) {
        const resultQuery = data.map((item) => item.value).join(" ");
        UserStore.SetDetectionQuery(resultQuery);
        localStorage.setItem("SetDetectionQuery", toJS(resultQuery));
        if (isEditWorkspaceFlag === false) {
          const datatofeed = Object.values(toJS(UserStore.createWorkspacedata))
            .length
            ? toJS(UserStore.createWorkspacedata)
            : localStorage.getItem("createWorkspacedata")
            ? JSON.parse(localStorage.getItem("createWorkspacedata"))
            : {};
          datatofeed["detectionExpression"] = resultQuery;
          datatofeed["detectionExpressionArray"] = data;

          UserStore.setCreateWorkspacedata(datatofeed);
          localStorage.setItem(
            "createWorkspacedata",
            JSON.stringify(datatofeed)
          );
        } else {
          const datatofeed = Object.values(toJS(UserStore.editWorkspacedata))
            .length
            ? toJS(UserStore.editWorkspacedata)
            : localStorage.getItem("setEditWorkspacedata")
            ? JSON.parse(localStorage.getItem("setEditWorkspacedata"))
            : {};
          datatofeed["detectionExpression"] = resultQuery;
          datatofeed["detectionExpressionArray"] = data;

          UserStore.setEditWorkspacedata(datatofeed);
          localStorage.setItem(
            "setEditWorkspacedata",
            JSON.stringify(datatofeed)
          );
        }
        navigatetoWorkSpace();
      } else {
        const resultQuery =
          data.length != 0
            ? data.map((item) => item.value).join(" ")
            : detectionQuery;
        UserStore.SetDetectionQuery(resultQuery);
        localStorage.setItem("SetDetectionQuery", toJS(resultQuery));
        if (resultQuery == "") {
          setDailogtext("Please select a detection query");
          setuserEmpty(true);
        } else {
          if (isEditWorkspaceFlag === false) {
            const datatofeed = Object.values(
              toJS(UserStore.createWorkspacedata)
            ).length
              ? toJS(UserStore.createWorkspacedata)
              : localStorage.getItem("createWorkspacedata")
              ? JSON.parse(localStorage.getItem("createWorkspacedata"))
              : {};
            datatofeed["detectionExpression"] = resultQuery;

            UserStore.setCreateWorkspacedata(datatofeed);
            localStorage.setItem(
              "createWorkspacedata",
              JSON.stringify(datatofeed)
            );
          } else {
            const datatofeed = Object.values(toJS(UserStore.editWorkspacedata))
              .length
              ? toJS(UserStore.editWorkspacedata)
              : localStorage.getItem("setEditWorkspacedata")
              ? JSON.parse(localStorage.getItem("setEditWorkspacedata"))
              : {};
            if (resultQuery === "") {
              const sdQuery = UserStore.setDetectionQuery
                ? UserStore.setDetectionQuery
                : localStorage.getItem("SetDetectionQuery")
                ? localStorage.getItem("SetDetectionQuery")
                : "";
              datatofeed["detectionExpression"] = sdQuery;
            } else {
              datatofeed["detectionExpression"] = resultQuery;
            }

            UserStore.setEditWorkspacedata(datatofeed);
            localStorage.setItem(
              "setEditWorkspacedata",
              JSON.stringify(datatofeed)
            );
          }
          navigatetoWorkSpace();
        }
      }
    } catch (error) {
      setDailogtext("Please Select a valid Expression");
      setuserEmpty(true);
    }
  };
  // ---------------End: Function: Submit detection logic --------------------

  const navigatetoWorkSpace = () => {
    if (isEditWorkspace === true) {
      history.push(
        `/Workspace/EditWorkSpaceStamp/SetDetectionlogic/RegularExpression`
      );
    } else
      history.push(
        `/Workspace/CreateNewWorkspace/SetDetectionlogic/RegularExpression`
      );
  };

  const navigatetoWorkSpaceAgain = () => {
    if (isEditWorkspace === true) {
      history.push(`/Workspace/EditWorkSpaceStamp`);
    } else history.push("/Workspace/CreateNewWorkspace");
  };

  const handleCloseEmptyUser = () => {
    setuserEmpty(false);
  };

  const handledailogClose = () => {
    setopendialog(false);
  };

  // -------------------Start:Function: Reset the detection logic-------------------
  const doReset = () => {
    setData([]);
    setarr([]);
    setattribute([]);
    setoperator([]);
    setValueAttribute([]);
    setValueOperator([]);
    setdetectionquery("");
  };
  // -------------------End:Function: Reset the detection logic-------------------

  return (
    <div className="createWorkspace-container-PID">
      <AppHeader />
      <BreadCrumbs />
      <div className="createWorkspace-wrapper-PID">
        <MuiThemeProvider>
          <div className="createWorkspace-form-wrapper-PID ">
            <div className="createWorkspace-formblank-PID">
              <p class="create-text-SDL">
                {isEditWorkspace ? "Edit" : "Create"} Project Workspace
              </p>
              <img src="/images/icon_workspace.svg" class="icon-workspace" />
            </div>

            <form onSubmit={handleSubmit} className="setDetection">
              <div className="Detection-form-PID">
                <div
                  className="add-users-field-PID"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div
                    className="back"
                    onClick={navigatetoWorkSpaceAgain}
                    style={{ cursor: "pointer", display: "flex" }}
                  >
                    <img src="/images/back.svg" className="back-icon" />
                    <span
                      className="preview-text"
                      style={{
                        width: "15vw",
                        fontSize: "1.05vw",
                        color: "#0c629b",
                      }}
                    >
                      Set Detection Logic
                    </span>
                  </div>
                  <div
                    className="back"
                    style={{ position: "relative" }}
                    onClick={doReset}
                  >
                    <img src="/images/Reset.svg" className="back-icon" />
                    <span
                      className="preview-text"
                      style={{
                        fontSize: "1.05vw",
                        color: "#0c629b",
                        marginTop: "10%",
                      }}
                    >
                      Reset
                    </span>
                  </div>
                </div>

                <div
                  className="add-users-field-PID selectBox"
                  style={{ right: "0.7vw", position: "relative" }}
                >
                  <div
                    className="select-attribute"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <FormControl
                      className={classes.formControl}
                      style={{
                        width: "18.1vw",
                        minWidth: "18.1vw",
                        maxWidth: "18.1vw",
                      }}
                    >
                      <InputLabel
                        id="demo-simple-select-label"
                        style={{ fontSize: "0.9vw" }}
                      >
                        Select Attribute
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        name={1}
                        value={valueAtt}
                        onChange={handleChangeDropdownAttribute}
                      >
                        {attributes.map((name) => (
                          <MenuItem
                            key={name}
                            value={name}
                            className="selectBoxLi"
                          >
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Tooltip title="Add Attribute" arrow>
                      <img
                        className="add-icon"
                        src="/images/icon_add_blue.svg"
                        id="workSpaceUsers"
                        onClick={AddAttribute}
                        style={{
                          height: "1.5vw",
                          width: "1.5vw",
                          marginTop: "2vw",
                          cursor: "pointer",
                        }}
                      />
                    </Tooltip>
                  </div>

                  <div
                    className="select-operator"
                    style={{
                      position: "relative",
                      left: "1vw",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <FormControl
                      className={classes.formControl}
                      style={{
                        width: "18.1vw",
                        minWidth: "18.1vw",
                        maxWidth: "18.1vw",
                      }}
                    >
                      <InputLabel
                        id="demo-simple-select-label"
                        style={{ fontSize: "0.9vw" }}
                      >
                        Select Operator
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        name={2}
                        value={valueOpt}
                        onChange={handleChangeDropdownOperator}
                      >
                        {operators.map((name) => (
                          <MenuItem
                            key={name}
                            value={name}
                            className="selectBoxLi"
                          >
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Tooltip title="Add Operator" arrow>
                      <img
                        className="add-icon"
                        src="/images/icon_add_blue.svg"
                        id="workSpaceUsers"
                        onClick={AddOperator}
                        style={{
                          height: "1.5vw",
                          width: "1.5vw",
                          marginTop: "2vw",
                          cursor: "pointer",
                        }}
                      />
                    </Tooltip>
                  </div>
                </div>
                {arr.map((name) => (
                  <div style={{ marginLeft: "-1%" }}>{name}</div>
                ))}
                <p
                  style={{
                    borderTop: "0.2vw dotted lightgrey",
                    marginTop: "6vh",
                    marginLeft: "1%",
                    marginRight: "4%",
                    fontFamily: "Helvetica",
                    fontSize: "1vw",
                    width: "46.5vw",
                  }}
                >
                  <b>Preview</b>
                </p>
                <div
                  className="preview-data"
                  style={{
                    fontSize: "0.9vw",
                    marginLeft: "1%",
                    paddingTop: "0.3vw",
                  }}
                >
                  {data.map((data) => {
                    return (
                      <Chip
                        label={data.value}
                        onDelete={
                          data.label === "React"
                            ? undefined
                            : handleDelete(data)
                        }
                        onClick={handleClick(data)}
                        className="selectedListOfOption"
                      />
                    );
                  })}
                </div>
                <div className="btn-fields">
                  <Button
                    type="submit"
                    variant="contained"
                    class="save-btn"
                    style={{
                      margin: "0 1.9vw 0 1vw",
                      width: "8.4vw",
                      height: "2.29vw",
                      fontSize: "0.9vw",
                      background: "#0c629b",
                      color: "#ffffff",
                      textTransform: "none",
                    }}
                  >
                    Next
                  </Button>
                  <Button
                    variant="contained"
                    class="cancel-btn"
                    onClick={() => {
                      isEditWorkspace
                        ? history.push(`/Workspace/EditWorkSpaceStamp`)
                        : history.goBack(-1);
                    }}
                    style={{
                      background: "#ffffff",
                      border: "0.05vw solid #0c629b",
                      borderRadius: "0.15vw",
                      width: "8.4vw",
                      height: "2.29vw",
                      padding: "0.15vw 0",
                      fontSize: "0.9vw",
                      color: "#0c629b",
                      textTransform: "none",
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </form>
          </div>
          <Modal
            className="user-select-popup"
            show={userEmpty}
            onHide={handleCloseEmptyUser}
            backdrop="static"
            keyboard={false}
            dialogClassName="fullmodal"
            size="l"
            centered
          >
            <Modal.Body>
              <label
                style={{
                  font: "normal normal bold 1vw Helvetica",
                  textAlign: "left",
                }}
              >
                {dailogText}

                <IconButton
                  onClick={handleCloseEmptyUser}
                  style={{
                    padding: "0.156vw",
                    position: "absolute",
                    top: "0",
                    right: "0",
                    background: "",
                    borderRadius: "0",
                    color: "#0C629B",
                  }}
                >
                  {" "}
                  <CloseIcon style={{ width: "1.5vw", height: "1.5vw" }} />{" "}
                </IconButton>
              </label>
            </Modal.Body>
          </Modal>
          <Modal
            className="user-select-popup sdlSelectAttribut"
            show={opendialog}
            backdrop="static"
            keyboard={false}
            dialogClassName="fullmodal"
            size="l"
            centered
            onHide={handledailogClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <div>Select {messageselect}</div>
                <div>
                  <IconButton
                    onClick={handledailogClose}
                    className="popupClose"
                  >
                    {" "}
                    <CloseIcon />{" "}
                  </IconButton>
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <FormControl
                className={classes.formControl}
                style={{
                  width: "18.1vw",
                  minWidth: "18.1vw",
                  maxWidth: "18.1vw",
                }}
              >
                <InputLabel
                  id="demo-simple-select-label"
                  style={{ fontSize: "0.9vw" }}
                >
                  Select Attribute
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  name={3}
                  onChange={handleChangeDailogDropdown}
                >
                  {getoperand
                    ? operators.map((name) => (
                        <MenuItem
                          key={name}
                          value={name}
                          className="selectBoxLi"
                        >
                          {name}
                        </MenuItem>
                      ))
                    : attributes.map((name) => (
                        <MenuItem
                          key={name}
                          value={name}
                          className="selectBoxLi"
                        >
                          {name}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
            </Modal.Body>
          </Modal>
        </MuiThemeProvider>
      </div>
      <AppFooter />
    </div>
  );
}
