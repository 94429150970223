import React from 'react';
import { BrowserRouter as Router, Switch, Route, browserHistory } from 'react-router-dom';
import { getAppInsights } from '../../TelemetryService';
import TelemetryProvider from '../../telemetry-provider';

//SR imports
import Home from '../../pages/Home/Home';
import { DataGrid } from '../CreateWorkspace/DataGrid/DataGrid';
import UserForm from '../CreateWorkspace/CreatenewWorkspace/UserForm';
import ProjectSetupdetails from '../CreateWorkspace/ProjectSetupdetails/ProjectSetupdetails';
import StampUpload from '../Upload/UploadFiles/UploadFiles';
import Preview from '../Preview/Preview';
import EditWorkSpaceStamp from '../CreateWorkspace/EditWorkSpaceStamp/EditWorkSpaceStamp';

//PID imports
import WorkspaceDashboard from '../workspacePID/workspaceDashboard/WorkspaceDashboard';
import CreateNewWorkspace from '../workspacePID/createNewWorkspace/CreateNewWorkspace';
import EditWorkSpace from '../workspacePID/EditWorkspace/EditWorkspace';
import PIDUpload from '../UploadPID/UploadFiles/UploadFiles';
import SetDetectionlogic from '../CreateWorkspace/Setdetectionlogic/setDetectionlogic'
import OverallMTO from '../PreviewPID/OverallMTO/OverallMTO';
import PreviewPID from '../PreviewPID/Preview/Preview';
import EditConfiguration from '../workspacePID/createNewWorkspace/EditConfiguration';

// Bootstrap styles, optionally with jQuery and Popper
import 'bootstrap/dist/css/bootstrap.min.css';

// Custom app styles
import './App.scss';
import Buisnessrule from '../CreateWorkspace/Buisnessrule';
import Buisnessrulessetup from '../CreateWorkspace/DataGrid/Buisnessrulessetup';
import RegularExp from '../CreateWorkspace/DataGrid/RegularExp';

export default function App() {   

  const routes = [
    {path: '/', Component: Home},
    {path: '/Workspace', Component: DataGrid}, 
    {path: '/Workspace/CreateNewWorkspace', Component: UserForm},
    {path: '/Workspace/CreateNewWorkspace/SetDetectionlogic', Component: SetDetectionlogic},
    {path: '/Workspace/EditWorkSpaceStamp/SetDetectionlogic', Component: SetDetectionlogic},
    {path: '/Workspace/CreateNewWorkspace/Businessrule', Component: Buisnessrule},
    {path: '/Workspace/CreateNewWorkspace/SetDetectionlogic/RegularExpression/Businessrulessetup', Component: Buisnessrulessetup},
    {path: '/Workspace/EditWorkSpaceStamp/SetDetectionlogic/RegularExpression/Businessrulessetup', Component: Buisnessrulessetup},
    {path: '/Workspace/CreateNewWorkspace/SetDetectionlogic/RegularExpression', Component: RegularExp},
    {path: '/Workspace/EditWorkSpaceStamp/SetDetectionlogic/RegularExpression', Component: RegularExp},
    {path: '/Workspace/CreateNewWorkspace/RegularExpression', Component: RegularExp},
    {path: '/Workspace/EditWorkSpaceStamp/RegularExpression', Component: RegularExp},
    {path: '/Workspace/CreateNewWorkspace/ProjectSetup', Component: ProjectSetupdetails},
    {path: '/Workspace/CreateNewWorkspace/UploadFiles', Component: StampUpload},
    {path: '/Workspace/UploadFiles', Component: StampUpload},
    {path: '/Workspace/CreateNewWorkspace/Upload', Component: StampUpload},
    {path: '/Workspace/EditWorkSpaceStamp', Component: EditWorkSpaceStamp},
    {path: '/Workspace/Upload', Component: StampUpload},
    {path: '/WorkspacePID/EditConfiguration', Component: EditConfiguration},
    {path: '/Workspace/Upload/Preview', Component: Preview},
    {path: '/Workspace/UploadFiles/:id', Component: Preview},
    {path: '/WorkspacePID', Component: WorkspaceDashboard},
    {path: '/WorkspacePID/CreateNewWorkspace', Component: CreateNewWorkspace},
    {path: '/WorkspacePID/Upload/:id/OverallMTO', Component: OverallMTO},
    {path: '/WorkspacePID/Upload/:id', Component: PreviewPID},
    {path: '/WorkspacePID/EditWorkspace', Component: EditWorkSpace},
    {path: '/WorkspacePID/Upload', Component: PIDUpload}
  ];
  let appInsights = null;

  const instrumentationKey  = process.env.REACT_APP_INSTRUMENTATION_KEY;

  return (
      <div className="container-fluid app">
        <Router history="browserHistory">
        <TelemetryProvider instrumentationKey={instrumentationKey} after={() => { appInsights = getAppInsights() }}>
          <Switch>
            {routes.map(route => (
              <Route exact key={route.path} path={route.path} component={route.Component} />
            ))}
          </Switch>
          </TelemetryProvider>
        </Router>
      </div>
  );
}

