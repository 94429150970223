import React, { useState, useRef, useEffect } from "react";
import TableFilter from "react-table-filter";
import {
  EditAsset,
  EditAssetLineGroup,
  selectAsset,
} from "../../redux/actions/documentActions";
import { useDispatch, useSelector } from "react-redux";
import "react-table-filter/lib/styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {} from "./lib/styles.css";
import "./FilterRenderer.scss";
import UserStore from "../../UserStore";
export default function FilterRenderer(props) {
  let rows = props.rowsData;
  let accurateData = [];
  const accurateSymbols1 = (data) => {
    // if(docList !== undefined)
      const mtoDats ={...data};
      const mtoDat = Object.entries(mtoDats);
     let km = {};
      if(mtoDat.length > 0) {
        const cScoreArray = UserStore.accuracyPID;
        let cScoreValid = "";
          let val1 = 100;
          let val2 = 0;
          if(cScoreArray.length === 1) {
            cScoreValid = cScoreArray[0];
            if(cScoreValid === "low") {
            val1 = 60;
            val2 = 0;
          }
          else if(cScoreValid === "mid") {
            val1 = 80;
            val2 = 61;
          }
          else if(cScoreValid === "high") {
            val1 = 100;
            val2 = 81;
          }
          else {
            val1 = 100;
            val2 = 0;
          }
          }
          else if(cScoreArray.length === 2) {
            if(cScoreArray.includes("low") && cScoreArray.includes("mid")) {
              val1 = 80;
              val2 = 0;
            }
            else if(cScoreArray.includes("high") && cScoreArray.includes("mid")) {
              val1 = 100;
              val2 = 61;
            }
          }
          else if(cScoreArray.length === 3) {
            val1 = 100;
            val2 = 0;
          }
        for(let index in mtoDat) {
          let cScore = mtoDat[index][1].ConfidenceScore;
          if(cScore === "NaN") {
            cScore = 100;
          }
          km = {};
          if(cScoreArray.includes("high") && cScoreArray.includes("low") && cScoreArray.length === 2) {
            if(!(cScore <= 80 && cScore >= 61)) {
              accurateData.push(mtoDat[index][1])
            }
          }
          else {
            if(cScore <= val1 && cScore >= val2) {
              accurateData.push(mtoDat[index][1])
            }
          }
          
        }
      }
      rows = accurateData;
  }
  useEffect(() => {
    accurateSymbols1(rows);
  },[])
 
  
  const changeDataFormat = (mtoResult) => {
    let k = [];
    if(mtoResult !== undefined) {
    mtoResult.map((data) => {
      let mtoData = {
        id: data.id,
        SymbolRank: data.SymbolRank,
        Key: data.Key,
        assetId: data.assetId,
        coords: data.coords,
        LineNumber: data.LineNumber === "UNKNOWN" ? "Not Detected" :data.LineNumber ,
        Seq: data.Seq.length === 0 || data.Seq === "NaN" ? "Not Detected" : data.Seq,
        pid: data.pid.length === 0 ? "Not Detected" : data.pid,
        Spec: data.Spec.length === 0 ? "Not Detected" : data.Spec,
        shortCode: data.shortCode.length === 0 ? "Not Detected" : data.shortCode,
        isJacketed: data.isJacketed.length === 0 ? "Not Detected" : data.isJacketed,
        hdr:
          data.hdr === null ||
          data.hdr === undefined ||
          data.hdr === ""
            ? "Not Detected"
            : 
            data.hdr.split(/[" ']+/)[0],
            size1:
          data.size1 === null || data.size1 === undefined || data.size1 === ""
            ? "Not Detected"
            : 
            (data.size1),
            size2:
          (data.shortCode === "T" || data.shortCode === "RX") && (data.size2 === null || data.size2 === undefined || data.size2 === "")
            ? "Not Detected"
            : (data.size2 === null || data.size2 === undefined || data.size2 === "") ? "" : (data.size2) ,
            quantity: data.quantity,
            insulationType: data.insulationType.length === 0 ? "Not Detected" : data.insulationType,
            ugag: data.ugag.length === 0 ? "Not Detected" : data.ugag,
            fluid: data.fluid.length === 0 ? "Not Detected" : data.fluid,
            assetCompType:
          data.assetCompType === null ||
          data.assetCompType === undefined ||
          data.assetCompType === ""
            ? "Not Detected"
            : data.assetCompType.toUpperCase(),
            seat: data.seat.length === 0 ? "Not Detected" : data.seat,
            valveBody: data.valveBody.length === 0 ? "Not Detected" : data.valveBody,
            extendedBonet: data.extendedBonet.length === 0 ? "Not Detected" : data.extendedBonet,
            limitSwich: data.limitSwich.length === 0 ? "Not Detected" : data.limitSwich,
        hold: data.hold.length === 0 ? "Not Detected" : data.hold,
        supply: data.supply,
        fromLine: data.fromLine.length === 0 ? "Not Detected" : data.fromLine,
        toLine: data.toLine.length === 0 ? "Not Detected" : data.toLine,
        itemOrder: data.itemOrder,
        remarks: (data.shortCode === "I" || data.shortCode === "SC" || data.shortCode === "SI" || data.assetCompType === "Control Valve") && data.remarks.length === 0 ? "Not Detected" : data.remarks,
        rating: data.rating.length === 0 ? "Not Detected" : data.rating,
        ConfidenceScore: data.ConfidenceScore,
      };
        k.push(mtoData);
    });
  }
    return k;
  };
  const [newRows, setNewRows] = useState([...rows]);
  const [rowHeaders, setRowHeaders] = useState((changeDataFormat(rows)));
  
 
  useEffect(() => {
    let mtoResult = changeDataFormat(rows);
    setNewRows([...rows]);
    setRowHeaders([...mtoResult]);
  }, [rows]);
  const dispatch = useDispatch();
 
  
  const selectedAsset = useSelector(
    (state) => state.documentData.selectedAsset
  );
  let tableRef = useRef();
  const handleMouseLeave = (e, oldValue, index) => {
    const header = e.target.id.split("_")[0];
    const value = e.target.innerHTML;
    var newValue = JSON.parse(JSON.stringify(oldValue));
    newValue[header] = value;

    if (header === "LineNumber") {
      dispatch(EditAssetLineGroup(oldValue.LineNumber, newValue));
    } else {
      dispatch(EditAsset(newValue, oldValue));
    }
    
    if (
      newValue[header] !== oldValue[header] + "" &&
      oldValue[header] !== null &&
      oldValue[header] !== undefined &&
      oldValue[header] !== "UNKNOWN" &&
      oldValue[header] !== "NaN" && newValue[header] !== "Not Detected"
    )
      document.getElementById(e.target.id).style.backgroundColor = "yellow";
  };

  const retainDataFormat = (mtoResult) => {
    let k = [];
    if(mtoResult !== undefined) {
    mtoResult.map((data) => {
        let mtoData = {
          id: data.id,
          SymbolRank: data.SymbolRank,
          Key: data.Key,
          assetId: data.assetId,
          coords: data.coords,
          LineNumber: data.LineNumber === "Not Detected" ? "UNKNOWN" :data.LineNumber ,
          Seq: data.Seq === "Not Detected" ? "" : data.Seq,
          pid: data.pid=== "Not Detected" ? "" : data.pid,
          Spec: data.Spec === "Not Detected" ? "" : data.Spec,
          shortCode: data.shortCode === "Not Detected" ? "" : data.shortCode,
          isJacketed: data.isJacketed,
          hdr:data.hdr=== "Not Detected" ? "" : data.hdr ,
              size1:data.size1 === "Not Detected" ? "" : data.size1,
              size2:data.size2=== "Not Detected" ? "" : data.size2,
              quantity: data.quantity,
              insulationType: data.insulationType=== "Not Detected" ? "" : data.insulationType,
              ugag: data.ugag,
              fluid: data.fluid=== "Not Detected" ? "" : data.fluid,
              assetCompType:data.assetCompType=== "Not Detected" ? "" : data.assetCompType ,
              seat: data.seat=== "Not Detected" ? "" : data.seat,
              valveBody: data.valveBody=== "Not Detected" ? "" : data.valveBody,
              extendedBonet: data.extendedBonet=== "Not Detected" ? "" : data.extendedBonet,
              limitSwich: data.limitSwich=== "Not Detected" ? "" : data.limitSwich,
          hold: data.hold,
          supply: data.supply,
          fromLine: data.fromLine.length ===  "Not Detected" ? "UNKNOWN" : data.fromLine,
          toLine: data.toLine.length ===   "Not Detected" ? "UNKNOWN": data.toLine,
          itemOrder: data.itemOrder,
          remarks: data.remarks=== "Not Detected" ? "" : data.remarks,
          rating: data.rating=== "Not Detected" ? "" : data.rating,
          ConfidenceScore: data.ConfidenceScore,
        };
          k.push(mtoData);
    });
  }
    return k;
  };

  const filterUpdated = (newData, filterConfiguration) => {
    let retainedData = retainDataFormat(newData);
    setNewRows([...retainedData]);
    let mtoResult = changeDataFormat(newData);
    setRowHeaders([...mtoResult]);
  };

  const handleClick = (e, item) => {
    dispatch(selectAsset(item));
  };

 
 
  const filterReset = () => {
    tableRef.reset(rows, true);
    let mtoResult = changeDataFormat(rows);
    setNewRows(rows);
    setRowHeaders([...mtoResult]);
  }
  
  useEffect(() => {
    filterReset();
  },[rows])
  return (
    <div>
      <table className="table-pid-mto" id="resize-pid" ref={tableRef}>
        <thead className="table-head-pid" style={{ height: "3vw" }}>
          <TableFilter
            rows={JSON.parse(JSON.stringify(rowHeaders))}
            onFilterUpdate={filterUpdated}
            rowClass={"table-filter"}
            ref={ (node) => tableRef = node}
          >
            <th
              key="LineNumber"
              filterkey="LineNumber"
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">LINE NUMBER</div>
            </th>
            <th
              key="Seq"
              filterkey="Seq"
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">SEQ</div>
            </th>
            <th
              key="pid"
              filterkey="pid"
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">PID</div>
            </th>
            <th
              key="Spec"
              filterkey="Spec"
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">SPEC</div>
            </th>
            <th
              key="shortCode"
              filterkey="shortCode"
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">SHORT CODE</div>
            </th>
            <th
              key="isJacketed"
              filterkey="isJacketed"
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">IS JACKETED LINE</div>
            </th>
            <th
              key="hdr"
              filterkey="hdr"
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">HDR</div>
            </th>
            <th
              key="size1"
              filterkey="size1"
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">SIZE - 1</div>
            </th>
            <th
              key="size2"
              filterkey="size2"
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">SIZE - 2</div>
            </th>
            <th
              key="quantity"
              filterkey="quantity"
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">QTY</div>
            </th>
            <th
              key="insulationType"
              filterkey="insulationType"
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">INSUL TYPE</div>
            </th>
            <th
              key="ugag"
              filterkey="ugag"
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">AG/UG</div>
            </th>
            <th
              key="fluid"
              filterkey="fluid"
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">FLUID</div>
            </th>
            <th
              key="assetCompType"
              filterkey="assetCompType"
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">PIPINGCOMPTYPE</div>
            </th>
            <th
              key="seat"
              filterkey="seat"
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">SEAT</div>
            </th>
            <th
              key="valveBody"
              filterkey="valveBody"
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">VALVE BODY</div>
            </th>
            <th
              key="extendedBonet"
              filterkey="extendedBonet"
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">EXTENDED BONNET</div>
            </th>
            <th
              key="limitSwich"
              filterkey="limitSwich"
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">LIMIT SWITCH</div>
            </th>
            <th
              key="hold"
              filterkey="hold"
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">HOLD</div>
            </th>
            <th
              key="supply"
              filterkey="supply"
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">SUPPLY</div>
            </th>
            <th
              key="fromLine"
              filterkey="fromLine"
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">FROM</div>
            </th>
            <th
              key="toLine"
              filterkey="toLine"
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">TO</div>
            </th>
            <th
              key="itemOrder"
              filterkey="itemOrder"
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">ITEM ORDER</div>
            </th>
            <th
              key="remarks"
              filterkey="remarks"
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">REMARKS</div>
            </th>
            <th
              key="rating"
              filterkey="rating"
              casesensitive={"true"}
              showsearch={"true"}
            >
              <div className="resize-div">RATING</div>
            </th>
          </TableFilter>
        </thead>
        <tbody>
          {" "}
          {newRows.map((item, index) => (
            <tr
              key={item.key}
              style={
                selectedAsset &&
                item.LineNumber === selectedAsset["lineNumber"] &&
                item.id === selectedAsset["assetID"]
                  ? { backgroundColor: "#4C95FC4E" }
                  : {}
              }
            >
              <td
                contenteditable="true"
                // style={{ textAlign: "center" }}
                id={"LineNumber_" + item.Key}
                onBlur={(e) => handleMouseLeave(e, item)}
                onClick={(e) => handleClick(e, item)}
                style={item.LineNumber === "UNKNOWN" ? {color : "deeppink",textAlign: "center"} : {textAlign: "center"}} 
              >
                
                {item.LineNumber === "UNKNOWN"
                  ? "Not Detected"
                  : item.LineNumber}
              </td>
              <td
                contenteditable="true"
                style={item.Seq === "NaN" || item.Seq.length === 0 ? {color : "deeppink",textAlign: "center"} : {textAlign: "center"}} 
                id={"Seq_" + item.Key}
                className={"Seq_" + item.Key}
                onClick={(e) => handleClick(e, item)}
                onBlur={(e) => handleMouseLeave(e, item)}
              >
                {item.Seq === "NaN" || item.Seq.length === 0
                  ? "Not Detected"
                  : item.Seq}
              </td>
              <td
                contenteditable="true"
                style={item.pid.length === 0 ? {color : "deeppink",textAlign: "center"} : {textAlign: "center"}} 
                id={"pid_" + item.Key}
                onBlur={(e) => handleMouseLeave(e, item)}
                onClick={(e) => handleClick(e, item)}
              >
                {item.pid.length === 0 ? "Not Detected" : item.pid}
              </td>
              <td
                contenteditable="true"
                style={item.Spec.length === 0 ? {color : "deeppink",textAlign: "center"} : {textAlign: "center"}} 
                id={"Spec_" + item.Key}
                onBlur={(e) => handleMouseLeave(e, item)}
                onClick={(e) => handleClick(e, item)}
              >
                {item.Spec.length === 0 ? "Not Detected" : item.Spec}
              </td>
              <td
                contenteditable="true"
                style={item.shortCode.length === 0 ? {color : "deeppink",textAlign: "center"} : {textAlign: "center"}} 
                id={"shortCode_" + item.Key}
                onBlur={(e) => handleMouseLeave(e, item)}
                onClick={(e) => handleClick(e, item)}
              >
                {item.shortCode.length === 0 ? "Not Detected" : item.shortCode}
              </td>
              <td
                contenteditable="true"
                style={item.isJacketed.length === 0 ? {color : "deeppink",textAlign: "center"} : {textAlign: "center"}} 
                id={"isJacketed_" + item.Key}
                onBlur={(e) => handleMouseLeave(e, item)}
                onClick={(e) => handleClick(e, item)}
              >
                {item.isJacketed.length === 0
                  ? "Not Detected"
                  : item.isJacketed}
              </td>
              <td
                contenteditable="true"
                style={item.hdr === "NaN" || item.hdr.length === 0 ? {color : "deeppink",textAlign: "center"} : {textAlign: "center"}} 
                id={"hdr_" + item.Key}
                onBlur={(e) => handleMouseLeave(e, item)}
                onClick={(e) => handleClick(e, item)}
              >
                {item.hdr === "NaN" || item.hdr.length === 0
                  ? "Not Detected"
                  : item.hdr}
              </td>
              <td
                contenteditable="true"
                style={item.size1 === "NaN" || item.size1.length === 0 ? {color : "deeppink",textAlign: "center"} : {textAlign: "center"}} 
                id={"size1_" + item.Key}
                onBlur={(e) => handleMouseLeave(e, item)}
                onClick={(e) => handleClick(e, item)}
              >
                {item.size1 === "NaN" || item.size1.length === 0
                  ? "Not Detected"
                  : item.size1}
              </td>
              <td
                contenteditable="true"
                style={item.size2 === "NaN" || ((item.shortCode === "T" || item.shortCode === "RX") &&
                item.size2.length === 0) ? {color : "deeppink",textAlign: "center"} : {textAlign: "center"}} 
                id={"size2_" + item.Key}
                onBlur={(e) => handleMouseLeave(e, item)}
                onClick={(e) => handleClick(e, item)}
              >
                {item.size2 === "NaN"
                  ? "Not Detected"
                  : (item.shortCode === "T" || item.shortCode === "RX") &&
                    item.size2.length === 0
                  ? "Not Detected"
                  : item.size2}
              </td>
              <td
                contenteditable="true"
                style={{ textAlign: "center" }}
                id={"quantity_" + item.Key}
                onBlur={(e) => handleMouseLeave(e, item)}
                onClick={(e) => handleClick(e, item)}
              >
                {item.quantity}
              </td>
              <td
                contenteditable="true"
                style={item.insulationType.length === 0 ? {color : "deeppink",textAlign: "center"} : {textAlign: "center"}} 
                id={"insulationType_" + item.Key}
                onBlur={(e) => handleMouseLeave(e, item)}
                onClick={(e) => handleClick(e, item)}
              >
                {item.insulationType.length === 0
                  ? "Not Detected"
                  : item.insulationType}
              </td>
              <td
                contenteditable="true"
                style={item.ugag.length === 0 ? {color : "deeppink",textAlign: "center"} : {textAlign: "center"}} 
                id={"ugag_" + item.Key}
                onBlur={(e) => handleMouseLeave(e, item)}
                onClick={(e) => handleClick(e, item)}
              >
                {item.ugag.length === 0 ? "Not Detected" : item.ugag}
              </td>
              <td
                contenteditable="true"
                style={item.fluid.length === 0 ? {color : "deeppink",textAlign: "center"} : {textAlign: "center"}} 
                id={"fluid_" + item.Key}
                onBlur={(e) => handleMouseLeave(e, item)}
                onClick={(e) => handleClick(e, item)}
              >
                {item.fluid.length === 0 ? "Not Detected" : item.fluid}
              </td>
              <td
                contenteditable="true"
                style={item.assetCompType.length === 0 ? {color : "deeppink",textAlign: "center"} : {textAlign: "center"}} 
                id={"assetCompType_" + item.Key}
                onBlur={(e) => handleMouseLeave(e, item)}
                onClick={(e) => handleClick(e, item)}
              >
                {item.assetCompType.length === 0
                  ? "Not Detected"
                  : item.assetCompType}
              </td>
              <td
                contenteditable="true"
                style={item.seat.length === 0 ? {color : "deeppink",textAlign: "center"} : {textAlign: "center"}} 
                id={"seat_" + item.Key}
                onBlur={(e) => handleMouseLeave(e, item)}
                onClick={(e) => handleClick(e, item)}
              >
                {item.seat.length === 0 ? "Not Detected" : item.seat}
              </td>
              <td
                contenteditable="true"
                style={item.valveBody.length === 0 ? {color : "deeppink",textAlign: "center"} : {textAlign: "center"}} 
                id={"valveBody_" + item.Key}
                onBlur={(e) => handleMouseLeave(e, item)}
                onClick={(e) => handleClick(e, item)}
              >
                {item.valveBody.length === 0 ? "Not Detected" : item.valveBody}
              </td>
              <td
                contenteditable="true"
                style={item.limitSwich.length === 0 ? {color : "deeppink",textAlign: "center"} : {textAlign: "center"}} 
                id={"limitSwich_" + item.Key}
                onBlur={(e) => handleMouseLeave(e, item)}
                onClick={(e) => handleClick(e, item)}
              >
                {item.limitSwich.length === 0
                  ? "Not Detected"
                  : item.limitSwich}
              </td>
              <td
                contenteditable="true"
                style={item.extendedBonet.length === 0 ? {color : "deeppink",textAlign: "center"} : {textAlign: "center"}} 
                id={"extendedBonet_" + item.Key}
                onBlur={(e) => handleMouseLeave(e, item)}
                onClick={(e) => handleClick(e, item)}
              >
                {item.extendedBonet.length === 0
                  ? "Not Detected"
                  : item.extendedBonet}
              </td>
              <td
                contenteditable="true"
                style={item.hold.length === 0 ? {color : "deeppink",textAlign: "center"} : {textAlign: "center"}} 
                id={"hold_" + item.Key}
                onBlur={(e) => handleMouseLeave(e, item)}
                onClick={(e) => handleClick(e, item)}
              >
                {item.hold.length === 0 ? "Not Detected" : item.hold}
              </td>
              <td
                contenteditable="true"
                style={{ textAlign: "center" }}
                id={"supply_" + item.Key}
                onBlur={(e) => handleMouseLeave(e, item)}
                onClick={(e) => handleClick(e, item)}
              >
                {item.supply}
              </td>
              <td
                contenteditable="true"
                style={item.fromLine.length === 0 ? {color : "deeppink",textAlign: "center"} : {textAlign: "center"}} 
                id={"fromLine_" + item.Key}
                onBlur={(e) => handleMouseLeave(e, item)}
                onClick={(e) => handleClick(e, item)}
              >
                {item.fromLine.length === 0 ? "Not Detected" : item.fromLine}
              </td>
              <td
                contenteditable="true"
                style={item.toLine.length === 0 ? {color : "deeppink",textAlign: "center"} : {textAlign: "center"}} 
                id={"toLine_" + item.Key}
                onBlur={(e) => handleMouseLeave(e, item)}
                onClick={(e) => handleClick(e, item)}
              >
                {item.toLine.length === 0 ? "Not Detected" : item.toLine}
              </td>
              <td
                contenteditable="true"
                style={{ textAlign: "center" }}
                id={"itemOrder_" + item.Key}
                onBlur={(e) => handleMouseLeave(e, item)}
                onClick={(e) => handleClick(e, item)}
              >
                {item.itemOrder}
              </td>
              <td
                contenteditable="true"
                style={(item.shortCode === "I" ||
                item.shortCode === "SC" ||
                item.shortCode === "SI" ||
                item.assetCompType === "Control Valve") &&
              item.remarks.length === 0 ? {color : "deeppink",textAlign: "center"} : {textAlign: "center"}} 
                id={"remarks_" + item.Key}
                onBlur={(e) => handleMouseLeave(e, item)}
                onClick={(e) => handleClick(e, item)}
              >
                {(item.shortCode === "I" ||
                  item.shortCode === "SC" ||
                  item.shortCode === "SI" ||
                  item.assetCompType === "Control Valve") &&
                item.remarks.length === 0
                  ? "Not Detected"
                  : item.remarks}
              </td>
              <td
                contenteditable="true"
                style={item.rating === "NaN" || item.rating.length === 0 ? {color : "deeppink",textAlign: "center"} : {textAlign: "center"}} 
                id={"rating_" + item.Key}
                onBlur={(e) => handleMouseLeave(e, item)}
                onClick={(e) => handleClick(e, item)}
              >
                {item.rating === "NaN" || item.rating.length === 0
                  ? "Not Detected"
                  : item.rating}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
