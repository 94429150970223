import React, { useState, useEffect, useReducer } from "react";
import { Router, useHistory, withRouter } from "react-router-dom";
import axios from "axios";
import { TextField, MuiThemeProvider, Button } from "@material-ui/core";
import AppHeader from "../../AppHeader/AppHeader";
import AppFooter from "../../AppFooter/AppFooter";
import BreadCrumbs from "../../Upload/BreadCrumbs/BreadCrumbs";
import UserStore from "../../UserStore";
import ModalValidation from "../createNewWorkspace/ModalValidation";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import AddUser from "../AddUser/AddUser";
import DeleteUser from "../AddUser/DeleteUser";
import "./CreateNewWorkspace.scss";
import { toJS, observer } from "mobx";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "moment";

export default function CreateNewWorkspace() {
  const API_Endpoint = process.env.REACT_APP_API_ENDPOINT;
  const history = useHistory();
  var wrapperRef = React.createRef();
  var addUserRef = React.createRef();
  let graphDataforUsers;

  const [formData, setFormData] = useState({
    projectNumber: "",
    projectName: "",
    clientName: "",
    location: "",
    plantDescription: "",
    mainOffice: "",
    satelliteOffice: "",
    fromDate: "",
    toDate: "",
    workSpaceUsers: [],
    copyConfigFromWorkSpaceId: 0,
  });
  const [user, setUser] = useState([]);
  const [show, setShow] = useState(false);
  const [list, setList] = useState([]);
  const [useradd, setuseradd] = useState([]);
  const [userEmpty, setUserEmpty] = useState(false);
  const [duplicateData, setDuplicateData] = useState(false);
  const [emptyUser, setEmptyUser] = useState(false);
  const [message, setMessage] = useState("");
  const [dropdownvalue, setdropdownvalue] = useState("");
  //const [proposalnumber, setproposalnumber] = useState(0);
  const [proposalnumber, setproposalnumber] = useState([]);
  // const [proposalnumber, setproposalnumber] = useState([
  //   {
  //     projectWorkSpaceId: 123,
  //     proposalNumber: 34
  //   },
  //   { projectWorkSpaceId: 753, proposalNumber: 38},
  //   { projectWorkSpaceId: 274, proposalNumber: 35 }
  // ]);
  const [rolearray, setroleArray] = useState([]);
  const [personName, setPersonName] = React.useState([]);
  const [errorProjectNumber, setErrorProjectNumber] = useState(false);
  const [errorProjectName, setErrorProjectName] = useState(false);
  const [errorClientName, setErrorClientName] = useState(false);
  const [errorLocation, setErrorLocation] = useState(false);
  const [errorDescription, setErrorDescription] = useState(false);
  const [errorMainOffice, setErrorMainOffice] = useState(false);
  const [errorSatelliteOffcie, setErrorSatelliteOffice] = useState(false);
  const [errorFromDate, setErrorFromDate] = useState(false);
  const [errorToDate, setErrorToDate] = useState(false);
  const [errorUsers, setErrorUsers] = useState(false);
  const [errorMsgProjectNumber, setErrorMsgProjectNumber] = useState("");
  const [errorMsgProjectName, setErrorMsgProjectName] = useState("");
  const [errorMsgClientName, setErrorMsgClientName] = useState("");
  const [errorMsgLocation, setErrorMsgLocation] = useState("");
  const [errorMsgDescription, setErrorMsgDescription] = useState("");
  const [errorMsgMainOffice, setErrorMsgMainOffice] = useState("");
  const [errorMsgSatelliteOffcie, setErrorMsgSatelliteOffice] = useState("");
  const [errorMsgFromDate, setErrorMsgFromDate] = useState("");
  const [errorMsgToDate, setErrorMsgToDate] = useState("");
  const [errorMsgUsers, setErrorMsgUsers] = useState("");
  const [loading, setLoading] = useState(true);
  const [deleteUsers, setdeleteUsers] = useState("");
  const [Delete, setdelete] = useState(false);
  const [tooltipError, settooltipError] = useState(false);
  const [tootlTipErrorMessage, settootlTipErrorMessage] = useState("");
  const [role, setrole] = useState(1);
  const [Proposalno, setproposal] = useState(1);
  const [suggestions, setsuggestions] = useState([]);
  const [q, setq] = useState("");
  const [modifysuggestion, setmodifysuggestion] = useState([]);
  const [showSuggestions, setshowSuggestions] = useState(false);
  const [roles, setroles] = useState([]);

  useEffect(() => {
    fetchUserTypes();
    document.addEventListener("mousedown", suggestionOutClickHandler);

    return () => {
      document.removeEventListener("mousedown", suggestionOutClickHandler);
    };
  }, []);

  async function fetchUserTypes() {
    const addedUser = toJS(UserStore.addUserforWorkspacePID).length
      ? toJS(UserStore.addUserforWorkspacePID)
      : localStorage.getItem("setUserforWorkspacePID")
      ? JSON.parse(localStorage.getItem("setUserforWorkspacePID"))
      : [];
    setuseradd(addedUser);
    const dropDownValues = await axios
      .get(`${API_Endpoint}/GetApplicationRoles`)
      .then((res) => {
        if (res.status === 200) {
          setroles(res.data);
        } else if (
          res.status === 400 ||
          res.status === 401 ||
          res.status === 404 ||
          res.status === 408
        ) {
          toast.error("service error", {
            // Set to 5sec
            position: toast.POSITION.TOP_LEFT,
            autoClose: 5000,
          });
        }
      });

    const dropDownValuesproposal = await axios
      .get(`${API_Endpoint}/GetPidProposalNumbers`)
      .then((res) => {
        //proposalnumber.push({projectWorkSpaceId:23,proposalNumber:78})

        if (res.status === 200) {
          proposalnumber.push(res.data.result);
          //proposalnumber.push({"result":[{projectWorkSpaceId:23,proposalNumber:78}]})

          setproposalnumber(res.data.result);
          setLoading(false);
        } else if (
          res.status === 400 ||
          res.status === 401 ||
          res.status === 404 ||
          res.status === 408
        ) {
          toast.error("service error", {
            // Set to 5sec
            position: toast.POSITION.TOP_LEFT,
            autoClose: 5000,
          });
        }
      });
  }

  function handleRoleChange(e) {
    if (e.target.value === "Admin") {
      setdropdownvalue(e.target.value);
      setrole(1);
    } else if (e.target.value === "Read / Write") {
      setdropdownvalue(e.target.value);
      setrole(2);
    } else if (e.target.value === "Read Only") {
      setdropdownvalue(e.target.value);
      setrole(3);
    }
    rolearray.push(e.target.value);
  }

  const handleCloseEmptyUser = () => {
    setUserEmpty(false);
    setDuplicateData(false);
    setEmptyUser(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  function handleCloseDialog() {
    setdelete(false);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const fields = Object.entries(formData);
    const FieldsName = [
      "Project Number",
      "Project Name",
      "Client Name",
      "Location",
      "Plant Description",
      "Main Office",
      "Satellite Office",
      "From Date",
      "To Date",
      "Work Space Users",
    ];
    let fieldsArray = fields.map((keys, index) => {
      if (keys[1] === "" || !keys[1].length) {
        return FieldsName[index];
      }
    });
    const fieldsCase = fieldsArray.filter((item) => item);
    if (useradd.length === 0 && fieldsCase.length !== 0) {
      setUserEmpty(true);
      setMessage(
        `Please fill the details in the ${fieldsCase.join(", ")} field(s)`
      );
    } else if (useradd.length === 0 && fieldsCase.length === 0) {
      setUserEmpty(true);

      setMessage("Please select atleast one user to proceed");
    } else if (fieldsCase.length !== 0 && useradd.length !== 0) {
      setUserEmpty(true);

      setMessage(
        `Please fill the details in the ${fieldsCase.join(", ")} field(s)`
      );
    } else if (formData.copyConfigFromWorkSpaceId === 0) {
      setUserEmpty(true);

      setMessage(`Please select Copy Configuration From`);
    } else if (new Date(fromDate) > new Date(toDate)) {
      setUserEmpty(true);

      setMessage("From Date cannot be beyond To Date");
    } else if (fieldsCase.length === 0 && useradd.length !== 0) {
      await axios.post(API_Endpoint + "/CreatePidWorkSpace", formData);
      history.goBack();
    }
  };

  const {
    projectNumber,
    projectName,
    clientName,
    location,
    plantDescription,
    mainOffice,
    satelliteOffice,
    fromDate,
    toDate,
    copyConfigFromWorkSpaceId,
  } = formData;

  function addUser() {
    settooltipError(false);
    settootlTipErrorMessage("");
    const searchTerm = document
      .getElementById("search-box1")
      .value.toLowerCase();
    if (
      suggestions.length == 1 &&
      suggestions.some((e) => e.mail === searchTerm)
    ) {
      document.getElementById("search-box1").focus();
      personName[0] = searchTerm;
    }
    if (personName.length === 0) {
      settooltipError(true);
      settootlTipErrorMessage("Kindly add a valid email address");
    } else {
      const personNameList = personName;
      for (var i = 0; i < personNameList.length; i++) {
        const userdata = {
          enterpriseId: personNameList[i],
          roleId: role,
        };
        useradd.push(userdata);
        let userArray = useradd;
        for (var i in userArray) {
          if (userArray[i].roleId === "Admin") {
            userArray[i].roleId = 1;
          } else if (userArray[i].roleId === "Read / Write") {
            userArray[i].roleId = 2;
          } else if (userArray[i].roleId === "Read Only") {
            userArray[i].roleId = 3;
          }
        }
        setFormData({ ...formData, workSpaceUsers: userArray });
        // setrole(dropdownvalue);
        setPersonName([]);
        setsuggestions([]);
      }

      function getUnique(arr, index) {
        const unique = arr
          .map((e) => e[index])
          .map((e, i, final) => final.indexOf(e) === i && i)
          .filter((e) => arr[e])
          .map((e) => arr[e]);
        return unique;
      }
      useradd.reverse();
      setuseradd(getUnique(useradd, "enterpriseId"));
      UserStore.setUserforWorkspacePID(useradd);
      localStorage.setItem("setUserforWorkspacePID", JSON.stringify(useradd));
      document.getElementById("search-box1").value = "";
      setShow(true);
      setq("");
    }
  }

  async function suggestionsCall(q) {
    const res = await axios.get(
      `${process.env.REACT_APP_API_GETUSERLIST}/GetUsersList?username=${q}`
    );
    setsuggestions(res.data);
    setmodifysuggestion(res.data);
    filterSearchText(res.data, q);
  }

  function onSearchHandler() {
    setshowSuggestions(false);
  }

  const handleDropdownChange = (e) => {
    setFormData({ ...formData, copyConfigFromWorkSpaceId: e.target.value });
  };

  function onChangeHandler1(e) {
    let searchTerm = "";
    searchTerm = e.target.value.toLowerCase();
    setq(searchTerm);
    setPersonName([]);
    if (searchTerm.length === 3) {
      suggestionsCall(searchTerm);
      setshowSuggestions(true);
    }
    if (searchTerm.length < 3) {
      setsuggestions([]);
      setshowSuggestions(false);
    }
    if (searchTerm.length > 3) {
      filterSearchText(suggestions, searchTerm);
    }
  }

  function pasteFunction(e) {
    let searchTerm = "";
    searchTerm = e.clipboardData.getData("text").toLowerCase();
    setq(searchTerm);
    setPersonName([]);
    let substring = searchTerm.substring(0, 3);
    suggestionsCall(searchTerm);
    setshowSuggestions(true);
  }

  function filterSearchText(suggestionData, q) {
    let searchTerm = "";
    searchTerm = q.toLowerCase();
    const updatedRowsforsearch = suggestionData.filter(
      (row) =>
        row.mail.toLowerCase().includes(searchTerm) ||
        row.displayName.toLowerCase().includes(searchTerm)
    );
    setsuggestions(updatedRowsforsearch);
    setshowSuggestions(true);
    for (var i = 0; i < useradd.length; i++) {
      if (useradd[i].enterpriseId.toLowerCase() === searchTerm) {
        settooltipError(true);
        settootlTipErrorMessage("Override the user role");
      }
    }
    const updatedRows = suggestionData.filter(
      (row) =>
        row.mail.toLowerCase().includes(searchTerm) ||
        row.displayName.toLowerCase().includes(searchTerm)
    );
    setsuggestions(updatedRows);
    if (updatedRows.length == 1 && searchTerm.includes("@petrofac.com")) {
      if (Object.values(updatedRows[0]).includes(searchTerm)) {
        document.getElementById("search-box1").focus();
        personName[0] = searchTerm;
        return true;
      }
    }
  }

  function onEnterButton(event) {
    if (event.keyCode === 13) {
      onSearchHandler();
    }
  }

  function handleClose() {
    setShow(false);
  }

  function suggestionClickHandler(s) {
    document.getElementById("search-box1").value = s;
    for (var i = 0; i < useradd.length; i++) {
      if (useradd[i].enterpriseId === s) {
        settooltipError(true);
        settootlTipErrorMessage("Override the user role");
      }
    }
    personName[0] = s;
    setshowSuggestions(false);
  }

  function suggestionOutClickHandler(event) {
    if (wrapperRef.current) {
      if (!wrapperRef.current.contains(event.target)) {
        setshowSuggestions(false);
      }
    }
  }

  function handleDelete(e, user) {
    setdelete(true);
    setdeleteUsers(user);
  }

  function handlePurge(e, user) {
    const updatedRows = useradd.filter(
      (row) =>
        row.enterpriseId.toLowerCase() !== user.enterpriseId.toLowerCase()
    );
    setuseradd(updatedRows);
    setdelete(false);
  }
  return (
    <div className="createWorkspace-container-PID">
      <AppHeader />
      <BreadCrumbs />
      <MuiThemeProvider>
        <div className="createWorkspace-form-wrapper-PID">
          <div className="createWorkspace-formblank-PID">
            <p className="create-text-PID">Create Project Workspace</p>
            <img src="/images/icon_workspace.svg" className="icon-workspace" />
          </div>
          <div className="createWorkspace-wrapper">
            <form>
              <div className="createWorkspace-form-PID">
                <TextField
                  autoFocus
                  margin="dense"
                  id="projectNumber"
                  name="projectNumber"
                  label="Proposal/Project No."
                  type="text"
                  onChange={handleChange}
                  value={projectNumber}
                  error={errorProjectNumber}
                  helperText={errorMsgProjectNumber}
                  required
                />
                <TextField
                  margin="dense"
                  id="projectName"
                  name="projectName"
                  label="Project Name"
                  type="text"
                  onChange={handleChange}
                  value={projectName}
                  error={errorProjectName}
                  helperText={errorMsgProjectName}
                  required
                />
                <TextField
                  margin="dense"
                  id="clientName"
                  name="clientName"
                  label="Client Name"
                  type="text"
                  onChange={handleChange}
                  value={clientName}
                  error={errorClientName}
                  helperText={errorMsgClientName}
                  required
                />
                <TextField
                  margin="dense"
                  id="location"
                  name="location"
                  label="Location"
                  type="text"
                  onChange={handleChange}
                  value={location}
                  error={errorLocation}
                  helperText={errorMsgLocation}
                  required
                />
                <TextField
                  margin="dense"
                  id="plantDescription"
                  name="plantDescription"
                  label="Plant Description"
                  type="text"
                  onChange={handleChange}
                  value={plantDescription}
                  error={errorDescription}
                  helperText={errorMsgDescription}
                  required
                />
                <TextField
                  margin="dense"
                  id="mainOffice"
                  name="mainOffice"
                  label="Main Office"
                  type="text"
                  onChange={handleChange}
                  value={mainOffice}
                  error={errorMainOffice}
                  helperText={errorMsgMainOffice}
                  required
                />
                <TextField
                  margin="dense"
                  id="satelliteOffice"
                  name="satelliteOffice"
                  label="Satellite Office"
                  type="text"
                  onChange={handleChange}
                  value={satelliteOffice}
                  error={errorSatelliteOffcie}
                  helperText={errorMsgSatelliteOffcie}
                  required
                />

                <TextField
                  margin="dense"
                  name="fromDate"
                  defaultValue={Moment(fromDate).format("YYYY-MM-DD")}
                  className="date"
                  id="fromDate"
                  label="From"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  onChange={handleChange}
                  error={errorFromDate}
                  helperText={errorMsgFromDate}
                />
                <TextField
                  margin="dense"
                  name="toDate"
                  defaultValue={Moment(toDate).format("YYYY-MM-DD")}
                  className="date"
                  id="toDate"
                  label="To"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  onChange={handleChange}
                  error={errorFromDate}
                  helperText={errorMsgFromDate}
                />
                <br></br>
                <div className="userNameAndConfig">
                  <div className="Link" style={{ width: "50%" }}>
                    <span
                      onClick={() => {
                        setShow(true);
                      }}
                      style={{ marginLeft: "8.1%" }}
                    >
                      Add User *
                    </span>
                  </div>

                  <div
                    className="Link"
                    style={{ width: "50%", display: "flex" }}
                  >
                    <span
                      style={{
                        textDecoration: "none",
                        lineHeight: "1",
                        cursor: "default",
                        width: "9vw",
                        fontSize: "1vw",
                      }}
                    >
                      Copy Configuration From *
                    </span>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="copyConfigFromWorkSpaceId"
                      name="copyConfigFromWorkSpaceId"
                      select
                      className="assign-role-dropdown"
                      disabled={loading}
                      label="Assign CopyConfiguration"
                      style={{ marginRight: "2% !important" }}
                      onChange={handleDropdownChange}
                      required
                      error={errorFromDate}
                      helperText={errorMsgFromDate}
                    >
                      {proposalnumber.length > 0 &&
                        proposalnumber.map((option) => (
                          <option
                            key={option.projectWorkSpaceId}
                            value={option.projectWorkSpaceId}
                          >
                            {option.proposalNumber} {option.projectName}
                          </option>
                        ))}
                    </TextField>
                  </div>
                </div>

                <div
                  className="btn-fields"
                  align="center"
                  style={{ marginTop: "1vw !important" }}
                >
                  <Button
                    type="button"
                    variant="contained"
                    class="save-btn"
                    onClick={handleSubmit}
                    style= {{
                      font: "normal normal 1vw Helvetica",
                    background: "#0c629b",
                    border: "1px solid #0c629b",
                    borderRadius: "4px",
                    color: "#ffffff",
                    width: "8vw",
                    height: "4.8vh",
                    marginRight: "2vw",
                    textTransform:"none",
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    variant="contained"
                    class="cancel-btn"
                    onClick={() => history.goBack(-1)}
                    style= {{
                      font: "normal normal 1vw Helvetica",
                      background: "#ffffff",
                      border: "1px solid #0c629b",
                      borderRadius: "4px",
                      color: "#0c629b",
                      width: "8vw",
                      height: "4.8vh",
                      textTransform:"none",
                    }}
                  >
                    Cancel
                  </Button>
                </div>
                <ModalValidation
                  handleCloseEmptyUser={handleCloseEmptyUser}
                  message={message}
                  userEmpty={userEmpty}
                  emptyUser={emptyUser}
                  duplicateData={duplicateData}
                />
                <AddUser
                  show={show}
                  useradd={useradd}
                  suggestions={suggestions}
                  suggestionClickHandler={suggestionClickHandler}
                  handleRoleChange={handleRoleChange}
                  dropdownvalue={dropdownvalue}
                  AddUser={addUser}
                  showSuggestions={showSuggestions}
                  handleClose={handleClose}
                  onEnterButton={onEnterButton}
                  wrapperRef={wrapperRef}
                  addUserRef={addUserRef}
                  pasteFunction={pasteFunction}
                  tooltipError={tooltipError}
                  tootlTipErrorMessage={tootlTipErrorMessage}
                  handleDelete={handleDelete}
                  setshowSuggestions={setshowSuggestions}
                  roles={roles}
                  onChangeHandler1={onChangeHandler1}
                />
                <DeleteUser
                  delete={Delete}
                  handleCloseDialog={handleCloseDialog}
                  handlePurge={handlePurge}
                  deleteUsers={deleteUsers}
                />
              </div>
            </form>
          </div>
        </div>
      </MuiThemeProvider>
      <AppFooter />
    </div>
  );
}
//}
