import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import FileList from '../FileList/FileList';
import './StampUpload.scss';
import StampGrid from '../StampGrid/StampGrid'
import FileLog from '../StampGrid/FileLog'
import AppFooter from '../../AppFooter/AppFooter';
import UserStore from '../../UserStore';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`, 
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 0,    
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function StampUpload() {
  
  const processgridvalue = localStorage.getItem("getprocessGrid") ? Number(localStorage.getItem("getprocessGrid")): UserStore.showprocessgrid;
  const [value, setValue] = useState(processgridvalue);
  let user = false;
  UserStore.PIDselectedRowID = [];
  let isTickBased = localStorage.getItem("isIsometricTickBased") ? JSON.parse(localStorage.getItem("isIsometricTickBased")): UserStore.isTickBasedIsometric;
  useEffect(() => {
    
    let userType = "Admin"
    if(userType === "read"){
      user = true;
    }
    else{
    user = false;
    }
    
}, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);    
    UserStore.getprocessGrid(newValue);
    localStorage.setItem('getprocessGrid', newValue);
  };

  const handleTabChange = (indexValue) => {
    setValue(indexValue);
    UserStore.getprocessGrid(indexValue);
    localStorage.setItem('getprocessGrid', indexValue);
  };

  if(!user)
  {
    
const readonly = localStorage.getItem("isreadOnly") ? JSON.parse(localStorage.getItem("isreadOnly")): UserStore.readOnly;
  return (
    <paper className="upload-process-container">
      <div class="uploadpage-wrapper">
      <AppBar class="tabs-bar" position="static">
          <Tabs className="tab-upload" value={value} onChange={handleChange} aria-label="simple tabs example" centered>
            <Tab label="Upload Files" className="dragdrop-tab" {...a11yProps(0)} style={readonly ? {textTransform:"none", pointerEvents: "none", opacity: "0.3"} : {textTransform:"none"}}/>
            <Tab label="Processed Files" className="process-tab" {...a11yProps(1)} style={{textTransform:"none"}}/>
            <Tab label="File Logs" className="process-tab" {...a11yProps(2)} style={{textTransform:"none"}}/>
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <div><div className='uploadNote'><span>Note:</span> Please click on Processed Files tab to view the processed files after the<b>{isTickBased ? " Tick Based " : " Phrase Based "}</b>Isometric documents are uploaded</div>
            <Grid container spacing={3} justify="center" alignItems="center">
              <Grid item xs={9}>   
                <Paper class="draganddrop" style={{width: "100%", marginLeft: "0", overflow: "hidden", height: "63vh"}}>
                  <FileList moveToUpload = {handleTabChange}/>
                </Paper>        
              </Grid>
            </Grid>      
          </div>
        </TabPanel>
       
        <TabPanel className="process-page" value={value} index={1}>
          <div>
          <Grid container spacing={3} justify="center" alignItems="center">
              <Grid item xs={12}>   
                <Paper class="draganddrop" style={{width: "100%", marginLeft: "0", marginTop: '0.52vw'}}>
                  <StampGrid />
                </Paper>        
              </Grid>
            </Grid>
          </div>
        </TabPanel>
        <TabPanel className="process-page" value={value} index={2} >
          <div>
          <Grid container spacing={3} justify="center" alignItems="center">
              <Grid item xs={12}>   
                <Paper class="fileLogTab" style={{width: "100%", marginLeft: "0",  marginTop: '0.52vw'}}>
                  <FileLog />
                </Paper>        
              </Grid>
            </Grid>
          </div>
        </TabPanel>           
      </div>
      <AppFooter /> 
    </paper>
  );
  }
  else  
  return(
  <div>
    <StampGrid />
  <AppFooter />
  </div>)
                
  
  
}
