import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Cancel from "@material-ui/icons/Cancel";
import IconButton from "@material-ui/core/IconButton";
import CircleChecked from "@material-ui/icons/CheckCircleRounded";

export default function AddUser(props) {
    return (
        <Dialog className="delete-confirm" open={props.delete} onClose={props.handleCloseDialog}>
            <DialogTitle id="form-dialog-title" style={{ height: "0.625em" }}></DialogTitle>
            <DialogContent>
                <div style={{ fontSize: "1vw" }}>
                    Do you want to delete the user?
                </div>
            </DialogContent>
            <DialogActions>
                <IconButton onClick={props.handleCloseDialog}
                    style={{
                      padding: "0",
                      float: "right",
                      background: "#0C629B",
                      color: "#fff",
                      position: "absolute",
                      right: "15%",
                    }}
                >
                    {" "}
                    <Cancel />{" "}
                </IconButton>
                <IconButton onClick={(e) => props.handlePurge(e, props.deleteUsers)}
                    style={{
                      padding: "0",
                      background: "#0C629B",
                      color: "white",
                      position: "absolute",
                      right: "2%",
                    }}
                >
                    {" "}
                    <CircleChecked />{" "}
                </IconButton>
            </DialogActions>
        </Dialog>
    )
}